import { FC, useState } from 'react';
import { Box } from '@mui/material';
import { COLORS, fileDownloadByLink, notify } from 'utils';
import { Button } from 'UI';
import { AttachIcon, DownloadIcon } from 'assets';
import { getShowcaseUnusedPromocodesFile } from 'api';
import { useSearchParams } from 'hooks';
import { StyledFileUploadContainer } from '../styledComponents';

interface IUploadDiscountsProps {
  id: string | number;
  handleFileUpload: (_file: File, _discId: string | number) => void;
}

export const UploadDiscounts: FC<IUploadDiscountsProps> = ({
  id,
  handleFileUpload,
}) => {
  const { getParam } = useSearchParams();
  const [fileName, setFileName] = useState<string>('');

  // const { data: sampleShowcaseFileLink } = useGetShowcaseFileSample();

  const openFileUploadModal = () => {
    document.getElementById(`upload-file-${id}`)?.click();
  };

  // const downloadSample = () => {
  //   fileDownloadByLink(sampleShowcaseFileLink, 'sample.csv');
  // };

  const downloadNotUsedPromoCodes = () => {
    getShowcaseUnusedPromocodesFile({
      item_id: getParam('edit'),
      item_discount_id: id,
      category_id: getParam('productCategory'),
    })
      .then(res => {
        fileDownloadByLink(res, `file.xlsx`);
      })
      .catch(err => {
        notify(err?.response?.data?.error?.message, 'error');
      });
  };

  const uploadFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }
    const uploadedFile = e.target.files[0];
    handleFileUpload(uploadedFile, id);
    setFileName(uploadedFile.name);
  };

  return (
    <StyledFileUploadContainer>
      <h2>Загрузка файла</h2>
      <Box className='buttons'>
        {!!+getParam('edit') && (
          <Button
            variant='contained'
            color='customGray'
            size='large'
            startIcon={<DownloadIcon color={COLORS.BLUE} />}
            onClick={downloadNotUsedPromoCodes}
          >
            Скачать
          </Button>
        )}
        <Button
          variant='contained'
          color='customLightBlue'
          size='large'
          startIcon={<AttachIcon color={COLORS.BLUE} />}
          onClick={openFileUploadModal}
        >
          {fileName || 'Загрузить'}
        </Button>
      </Box>
      <input
        id={`upload-file-${id}`}
        type='file'
        style={{ display: 'none' }}
        onChange={e => uploadFile(e)}
        accept='.xlsx, .xls, .csv'
      />
    </StyledFileUploadContainer>
  );
};
