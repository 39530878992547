import { FC, useMemo } from 'react';
import { Box, TextField } from '@mui/material';
import { useGetWorkoutTraningTypesList } from 'hooks';
import { Button, FormItem, SelectInput } from 'UI';
import { UpIcon, DownIcon } from 'assets';
import { IWorkoutCalendarItem } from 'types';

import {
  StyledContainer,
  StyledDayFooter,
  StyledDayHeader,
} from './styledComponents';

const TARGET_TYPE_OPTIONS = [
  { value: 1, label: 'Дистанция' },
  { value: 3, label: 'Калории' },
  { value: 4, label: 'Шаги' },
];

interface IWorkoutsCalendarItemProps extends IWorkoutCalendarItem {
  moveUp: () => void;
  moveDown: () => void;
  handleChangeParameter: <T extends keyof IWorkoutCalendarItem>(
    _parameter: T,
    _value: IWorkoutCalendarItem[T],
  ) => void;
}

export const WorkoutsCalendarItem: FC<IWorkoutsCalendarItemProps> = ({
  day_index,
  name,
  description,
  type_id,
  needle_type,
  needle_value,
  needle_time,
  moveUp,
  moveDown,
  handleChangeParameter,
}) => {
  const { data: workouts } = useGetWorkoutTraningTypesList();
  const workoutOptions = useMemo(() => {
    return [
      {
        value: 0,
        label: 'Тренировка',
      },
      ...(workouts?.map(workout => ({
        value: workout.id,
        label: workout.name,
      })) || []),
    ];
  }, [workouts]);

  return (
    <StyledContainer>
      <StyledDayHeader>
        <h3 className='title'>День {day_index}</h3>
        <Box className='iconButtons'>
          <Button variant='text' onClick={moveUp}>
            <UpIcon />
          </Button>
          <Button variant='text' onClick={moveDown}>
            <DownIcon />
          </Button>
        </Box>
      </StyledDayHeader>
      <FormItem label='Название задания'>
        <TextField
          variant='standard'
          color='primary'
          placeholder='Впишите название'
          name='name'
          value={name}
          onChange={e => handleChangeParameter('name', e.target.value)}
        />
      </FormItem>
      <FormItem label='Описание задания'>
        <TextField
          variant='standard'
          color='primary'
          placeholder='Впишите описание'
          name='description'
          value={description}
          onChange={e => handleChangeParameter('description', e.target.value)}
        />
      </FormItem>
      <StyledDayFooter>
        <SelectInput
          options={workoutOptions}
          value={type_id}
          onChange={newVal =>
            handleChangeParameter('type_id', newVal as unknown as number)
          }
          variant='outlined'
          placeholder='Тренировка'
          size='small'
        />
        <SelectInput
          options={TARGET_TYPE_OPTIONS}
          value={needle_type}
          onChange={newVal =>
            handleChangeParameter('needle_type', newVal as unknown as number)
          }
          variant='outlined'
          placeholder='Шаги'
          size='small'
        />
        <TextField
          variant='outlined'
          color='primary'
          placeholder='10 000'
          size='small'
          value={needle_value}
          onChange={e =>
            handleChangeParameter(
              'needle_value',
              e.target.value as unknown as number,
            )
          }
        />
        <TextField
          variant='outlined'
          color='primary'
          placeholder='Время'
          size='small'
          value={needle_time}
          onChange={e =>
            handleChangeParameter(
              'needle_time',
              e.target.value as unknown as number,
            )
          }
        />
      </StyledDayFooter>
    </StyledContainer>
  );
};
