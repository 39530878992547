import { createContext, FC, ReactNode, useState, useMemo } from 'react';

interface FilterContextType {
  startDate: string;
  endDate: string;
  updateDays: (_startDate: string, _endDate: string) => void;
}

interface IFilterProviderProps {
  children: ReactNode;
}

export const FilterContext = createContext<FilterContextType>({
  startDate: '',
  endDate: '',
  updateDays: () => {},
});

export const FilterDatesProvider: FC<IFilterProviderProps> = ({ children }) => {
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);
  const [startDate, setStartDate] = useState<string>(
    today.toISOString()?.split('T')[0],
  );
  const [endDate, setEndDate] = useState<string>(
    tomorrow.toISOString()?.split('T')[0],
  );

  const updateDays = (start: string, end: string) => {
    setStartDate(start);
    setEndDate(end);
  };

  const contextValue = useMemo(() => {
    return { startDate, endDate, updateDays };
  }, [startDate, endDate]);

  return (
    <FilterContext.Provider value={contextValue}>
      {children}
    </FilterContext.Provider>
  );
};
