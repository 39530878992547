import { FC } from 'react';
import {
  AddEditUserDrawer,
  Layout,
  UsersFooter,
  UsersHeader,
  UsersTable,
  ViewUserDrawer,
} from 'components';
import { useDrawer } from 'hooks';

export const Users: FC = () => {
  const {
    openAddDrawer,
    viewDrawerId,
    showViewDrawer,
    hideViewDrawer,
    editDrawerId,
    purpose,
    hideAddEditDrawer,
    showAddEditDrawer,
  } = useDrawer();

  return (
    <Layout>
      <AddEditUserDrawer
        open={showAddEditDrawer}
        onClose={hideAddEditDrawer}
        purpose={purpose}
        id={editDrawerId}
      />
      <ViewUserDrawer
        open={showViewDrawer}
        id={viewDrawerId}
        onClose={hideViewDrawer}
      />
      <UsersHeader openAddUserModal={openAddDrawer} />
      <UsersTable />
      <UsersFooter stars={0} />
    </Layout>
  );
};
