import { useQuery, useMutation, useQueryClient } from 'react-query';
import {
  getShopAttributes,
  getShopAttribute,
  createShopAttribute,
  updateShopAttribute,
  deleteShopAttribute,
  getShopAttributesList,
} from 'api';
import {
  IShopAttributeRes,
  IPaginatedReqParams,
  IShopAttribute,
  IShopAttributeListRes,
} from 'types';

export const useGetShopAttributes = (params: IPaginatedReqParams) => {
  const { page, search } = params;
  return useQuery<IShopAttributeRes, unknown>(
    ['shopAttributes', { page, search }],
    async () => {
      const res = await getShopAttributes(params);
      return res;
    },
    {
      retry: false,
      staleTime: Infinity,
    },
  );
};

export const useGetShopAttributesList = () => {
  return useQuery<IShopAttributeListRes>(
    ['shopAttributes', 'list'],
    async () => {
      const res = await getShopAttributesList();
      return res;
    },
    {
      retry: false,
      staleTime: Infinity,
    },
  );
};

export const useGetShopAttribute = (id: string | number) => {
  if (!id || id === '0')
    return useQuery(['shopAttributes', 'attributeId', id], () => {
      return Promise.resolve(null);
    });
  return useQuery<IShopAttribute, unknown>(
    ['shopAttributes', 'attributeId', id],
    async () => {
      const res = await getShopAttribute(id);
      return res;
    },
    {
      retry: false,
      staleTime: 60 * 60 * 1000,
    },
  );
};

export const useCreateShopAttribute = () => {
  const queryClient = useQueryClient();
  return useMutation(createShopAttribute, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['shopAttributes'] });
    },
    onError: () => {},
  });
};

export const useUpdateShopAttribute = () => {
  const queryClient = useQueryClient();
  return useMutation(updateShopAttribute, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['shopAttributes'] });
    },
    onError: () => {},
  });
};

export const useDeleteShopAttribute = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteShopAttribute, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['shopAttributes'] });
    },
    onError: () => {},
  });
};
