import { FC, useMemo } from 'react';
import { Grid } from '@mui/material';
import { useGetMarathons, useSearchParams } from 'hooks';
import { ChallengeCard } from 'components';
import { Pagination, PaginationContainer, CardsContainer } from 'UI';

export const ChallengesBody: FC = () => {
  const { getParam, searchParams } = useSearchParams();
  const params = useMemo(() => {
    return {
      page: getParam('page'),
      search: getParam('search'),
    };
  }, [searchParams]);

  const {
    data: { data: marathons, last_page: pageCount } = {
      data: [],
      last_page: 1,
    },
  } = useGetMarathons(params);
  return (
    <>
      <CardsContainer container spacing={2}>
        {marathons?.map(marathon => (
          <Grid item xs={4} key={marathon.id}>
            <ChallengeCard marathon={marathon} />
          </Grid>
        ))}
      </CardsContainer>
      <PaginationContainer item xs={12}>
        <Pagination count={pageCount} />
      </PaginationContainer>
    </>
  );
};
