import { useMutation, useQueryClient } from 'react-query';
import { createPrize, updatePrize } from 'api';

export const useCreatePrize = () => {
  const queryClient = useQueryClient();
  return useMutation(createPrize, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['marathons'] });
    },
    onError: () => {},
  });
};

export const useUpdatePrize = () => {
  const queryClient = useQueryClient();
  return useMutation(updatePrize, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['marathons'] });
    },
    onError: () => {},
  });
};
