export const createLink = (
  path: string,
  replaceValue: string | number,
  replaceField: string,
  params?: Record<string, string | number>,
) => {
  if (params) {
    const queryParams = Object.keys(params)
      .map(key => `${key}=${params[key]}`)
      .join('&');
    return `${path.replace(
      `:${replaceField}`,
      replaceValue.toString(),
    )}?${queryParams}`;
  }
  return path.replace(`:${replaceField}`, replaceValue.toString());
};

export const getErrorMessage = (error: any) => {
  if (!error) {
    return null;
  }
  if (error.response) {
    return error.response.data?.error?.message;
  }
  return error.message;
};

export const formatPhoneNumber = (
  phoneNumber: string | undefined,
): string | null => {
  if (!phoneNumber) return null;
  const cleaned = `${phoneNumber}`.replace(/\D/g, '');
  const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})$/);
  if (match) {
    return `+${match[1]} ${match[2]} ${match[3]}-${match[4]}-${match[5]}`;
  }
  return phoneNumber;
};

export const formatBirthdayToShowAtProfile = (
  birthday: string | null | undefined,
): string | null => {
  if (!birthday) return null;
  // converts this 1990-01-01 to this 01/01/1990
  const date = new Date(birthday);
  const options: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  };
  return date.toLocaleDateString('en-GB', options);
};

export const checkDeepEquality = (a: any, b: any) => {
  return JSON.stringify(a) === JSON.stringify(b);
};

export const isStringNumber = (value: any) => {
  return !Number.isNaN(Number(value));
};

export const isStringValidRussianPhoneNumber = (value: string) => {
  const regex =
    /^(\+7|7|8)?[\s-]?\(?[489][0-9]{2}\)?[\s-]?[0-9]{3}[\s-]?[0-9]{2}[\s-]?[0-9]{2}$/;
  return regex.test(value);
};

export const formatNumber = (value?: number) => {
  if (value === undefined || value === null) return '';
  const formatter = new Intl.NumberFormat('ru');
  return formatter.format(value);
};
