import { FC } from 'react';
import { Button, PageHeader, GlobalSearch } from 'UI';
import { PlusIcon } from 'assets';

interface ITeamsHeaderProps {
  openAddTeamDrawer: () => void;
}

export const TeamsHeader: FC<ITeamsHeaderProps> = ({ openAddTeamDrawer }) => {
  return (
    <PageHeader title='Управление  командами'>
      <GlobalSearch size='medium' />
      <Button
        variant='contained'
        onClick={openAddTeamDrawer}
        startIcon={<PlusIcon />}
      >
        Добавить
      </Button>
    </PageHeader>
  );
};
