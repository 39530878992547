import { FC, useState, useEffect } from 'react';
import { TextField, Box } from '@mui/material';
import {
  Drawer,
  DrawerHeader,
  ModalTitle,
  StyledFlexBox,
  Button,
  ImageInput,
  DrawerFormBody,
  FormItem,
} from 'UI';
import { IPrizeItem } from 'types';
import { LeftArrowIcon } from 'assets';
import { MEDIA_TYPES } from 'utils';

const DEFAULT_VALUES: IPrizeItem = {
  id: 0,
  name: '',
  description: '',
  cover: '',
  type: 1,
};

const TRANSLATIONS = {
  add: {
    title: 'Добавить приз',
  },
  edit: {
    title: 'Редактировать приз',
  },
};

interface ICreatePrizeForChallengeProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (_prize: IPrizeItem) => void;
  editingItem?: IPrizeItem;
  purpose?: 'add' | 'edit';
}

export const CreatePrizeForChallenge: FC<ICreatePrizeForChallengeProps> = ({
  open,
  onClose,
  onSubmit,
  editingItem = undefined,
  purpose = 'add',
}) => {
  const [prizeInfo, setPrizeInfo] = useState<IPrizeItem>(DEFAULT_VALUES);

  useEffect(() => {
    if (editingItem) {
      setPrizeInfo(editingItem);
    } else {
      setPrizeInfo(DEFAULT_VALUES);
    }
  }, [editingItem]);

  const handleFieldChange = <T extends keyof IPrizeItem>(
    fieldName: T,
    newValue: IPrizeItem[T],
  ) => {
    setPrizeInfo(prevPrizeInfo => ({
      ...prevPrizeInfo,
      [fieldName]: newValue,
    }));
  };

  const saveChanges = () => {
    if (!prizeInfo.name || !prizeInfo.description || !prizeInfo.cover) return;
    onSubmit(prizeInfo);
    setPrizeInfo(DEFAULT_VALUES);
  };

  const ignoreChanges = () => {
    setPrizeInfo(DEFAULT_VALUES);
    onClose();
  };

  return (
    <Drawer open={open} onClose={ignoreChanges}>
      <DrawerHeader>
        <ModalTitle title={TRANSLATIONS[purpose].title} />
        <LeftArrowIcon onClick={ignoreChanges} />
      </DrawerHeader>
      <DrawerFormBody>
        <StyledFlexBox>
          <ImageInput
            inputId='prizeCover'
            title='Добавьте обложку'
            descriptionWithoutImage='Загрузите изображение (1080x1080 px, до 1 мб)'
            withDescription
            image={prizeInfo.cover}
            setImage={newImg => handleFieldChange('cover', newImg)}
            style={{ width: '60%' }}
            mediaType={MEDIA_TYPES.TROPHY_ITEM_COVER}
            imageFixedHeight={1080}
            imageFixedWidth={1080}
          />
        </StyledFlexBox>
        <FormItem label='Название'>
          <TextField
            variant='standard'
            color='primary'
            placeholder='Введите название'
            name='name'
            value={prizeInfo.name}
            onChange={e => handleFieldChange('name', e.target.value)}
          />
        </FormItem>
        <FormItem label='Описание'>
          <TextField
            variant='standard'
            color='primary'
            placeholder='Введите описание'
            name='description'
            value={prizeInfo.description}
            onChange={e => handleFieldChange('description', e.target.value)}
          />
        </FormItem>
      </DrawerFormBody>
      <StyledFlexBox className='buttons fullDivWidth'>
        <Box />
        <Button
          variant='contained'
          size='large'
          color='primary'
          onClick={saveChanges}
        >
          Добавить
        </Button>
      </StyledFlexBox>
    </Drawer>
  );
};
