import { FC, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { ShowNewsBody } from 'components';
import { useSearchParams, useGetNewsItem } from 'hooks';

export const ShowNewsItem: FC = () => {
  const { id } = useParams();
  const { getParam, searchParams } = useSearchParams();
  if (!id) return null;
  const params = useMemo(() => {
    return {
      id,
      category_id: getParam('categoryId'),
    };
  }, [searchParams]);

  const { data: newsData } = useGetNewsItem(params);

  return <ShowNewsBody data={newsData} />;
};
