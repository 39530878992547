import { FC, ChangeEvent, useState, CSSProperties, useContext } from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { UserContext, UserContextType } from 'app';
import ImagePlaceholder from 'assets/images/input_image_placeholder.png';
import ImagePlaceholderBig from 'assets/images/input_image_placeholder_big.png';
import ImagePlaceholderVertical from 'assets/images/input_image_placeholder_vertical.png';
import { DeleteIcon } from 'assets';
import { COLORS, notify } from 'utils';
import { useUploadMedia } from 'hooks';
import { MediaType } from 'types';

const StyledContainer = styled(Box)(
  ({
    style,
    disabled,
    placeholderSize,
  }: {
    style: any;
    disabled: boolean;
    placeholderSize: 'big' | 'small' | 'vertical';
  }) => ({
    ...style,
    display: 'flex',
    gap: '0.625rem',
    '& .imgContainer': {
      position: 'relative',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        '& .removeBox': {
          visibility: 'visible',
        },
      },
      '& .removeBox': {
        width: '100%',
        height: '100%',
        backgroundColor: `rgba(206, 53, 72, 0.70)`,
        position: 'absolute',
        visibility: 'hidden',
        top: 0,
      },
      '& .deleteIcon': {
        position: 'absolute',
        top: '50%',
        left: '50%',
        cursor: 'pointer',
        transform: 'translate(-50%, -50%)',
      },
    },
    '& img': {
      width: placeholderSize === 'big' ? '9rem' : '5rem',
      height: placeholderSize === 'vertical' ? '9rem' : '5rem',
      objectFit:
        placeholderSize === 'small'
          ? 'cover'
          : placeholderSize === 'vertical'
          ? ''
          : 'contain',
      objectPosition: 'center',
      cursor: disabled ? 'not-allowed' : 'pointer',
    },
  }),
);

const StyledContentBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '0.25rem',
  width: '100%',
  justifyContent: 'center',
  '& .title': {
    fontWeight: 600,
    fontFamily: '"SF Pro Display", sans-serif',
    fontSize: '1rem',
    lineHeight: '1.5rem',
  },
  '& .description': {
    fontFamily: '"SF Pro Display", sans-serif',
    fontSize: '0.875rem',
    lineHeight: '1.375rem',
    fontWeight: 400,
    opacity: 0.5,
  },
});

export interface ImageInputProps {
  image: string;
  setImage: (_image: string) => void;
  inputId: string;
  title?: string;
  style?: CSSProperties;
  descriptionWithImage?: string;
  descriptionWithoutImage?: string;
  withDescription?: boolean;
  imageSize?: number;
  imageFixedWidth?: number;
  imageFixedHeight?: number;
  mediaType: MediaType;
  disabled?: boolean;
  placeholderSize?: 'small' | 'big' | 'vertical';
}

export const ImageInput: FC<ImageInputProps> = ({
  image,
  setImage,
  inputId,
  title,
  style,
  descriptionWithoutImage,
  descriptionWithImage = descriptionWithoutImage,
  withDescription = false,
  imageSize = 1 * 1024 * 1024,
  imageFixedWidth = 512,
  imageFixedHeight = 512,
  disabled = false,
  placeholderSize = 'small',
  mediaType,
}) => {
  const { space_id } = useContext(UserContext) as UserContextType;
  const { mutateAsync: uploadMedia } = useUploadMedia();
  const [imageName, setImageName] = useState<string>('');

  const handleOpenFileUploadModal = () => {
    if (disabled) {
      notify('Элемент заблокирован', 'error');
      return;
    }
    document.getElementById(inputId)?.click();
  };

  const convertImageSizeToText = (): string => {
    if (imageSize >= 1000000) {
      const sizeInMB = imageSize / 1000000;
      return `${sizeInMB.toFixed(1)} МБ`;
    }
    const sizeInKB = imageSize / 1000;
    return `${sizeInKB.toFixed(0)} КБ`;
  };

  const handleUploadAvatar = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }
    const file = e.target.files[0];
    if (!file) {
      return;
    }
    // max limit 1mb
    if (file.size > imageSize) {
      notify(
        `Размер файла превышает максимальный предел в ${convertImageSizeToText()}.`,
        'error',
      );
      return;
    }
    const reader = new FileReader();
    reader.onloadend = () => {
      const img = new Image();
      img.src = reader.result as string;

      img.onload = () => {
        // Check the image dimensions
        if (imageFixedWidth > 0) {
          if (
            img.width !== imageFixedWidth ||
            img.height !== imageFixedHeight
          ) {
            notify(
              `Высота или ширина картинки не соответствуют требуемому стандарту (${imageFixedWidth}x${imageFixedHeight})`,
              'error',
            );
            return;
          }
        } else if (imageFixedWidth === -1) {
          if (img.height !== imageFixedHeight) {
            notify(
              `Высота картинки не соответствуют требуемому стандарту ${imageFixedHeight}px`,
              'error',
            );
            return;
          }
        }

        const formData = new FormData();
        formData.append('image', file, file.name);
        formData.append('type', mediaType);
        formData.append('space_id', (space_id || 1).toString());
        uploadMedia({ data: formData })
          .then(res => {
            setImage(res);
            setImageName(file.name);
          })
          .catch(() => {
            document.getElementById(inputId)?.setAttribute('value', '');
          });
      };
    };
    reader.readAsDataURL(file);
  };

  const handleRemoveAvatar = () => {
    setImage('');
    setImageName('');
    const input = document.getElementById(inputId) as HTMLInputElement;
    if (input) {
      input.value = '';
    }
  };

  return (
    <>
      <StyledContainer
        style={style}
        disabled={disabled}
        placeholderSize={placeholderSize}
      >
        {image ? (
          <Box className='imgContainer'>
            <img
              src={image}
              alt={imageName}
              onClick={handleOpenFileUploadModal}
            />
            <Box className='removeBox'>
              <DeleteIcon
                color={COLORS.WHITE}
                className='deleteIcon'
                onClick={handleRemoveAvatar}
              />
            </Box>
          </Box>
        ) : (
          <img
            src={
              placeholderSize === 'small'
                ? ImagePlaceholder
                : placeholderSize === 'vertical'
                ? ImagePlaceholderVertical
                : ImagePlaceholderBig
            }
            alt='placeholder'
            onClick={handleOpenFileUploadModal}
          />
        )}
        <StyledContentBox>
          <p className='title'>{title}</p>
          {withDescription && (
            <div className='description'>
              {imageName ? descriptionWithImage : descriptionWithoutImage}
            </div>
          )}
        </StyledContentBox>
      </StyledContainer>
      <input
        id={inputId}
        type='file'
        style={{ display: 'none' }}
        onChange={e => handleUploadAvatar(e)}
      />
    </>
  );
};
