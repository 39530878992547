import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  getWorkoutsCalendars,
  getWorkoutsCalendar,
  createWorkoutsCalendar,
  updateWorkoutsCalendar,
  deleteWorkoutsCalendar,
  getWorkoutCalendarTrainingTypesList,
} from 'api';
import {
  IPaginatedReqParams,
  IWorkoutsCalendarRes,
  IWorkoutsCalendar,
  IWorkoutCalendarTraniningListRes,
} from 'types';

export const useGetWorkoutsCalendars = (params: IPaginatedReqParams) => {
  const { page, search } = params;
  return useQuery<IWorkoutsCalendarRes, unknown>(
    ['workoutsCalendar', { page, search }],
    async () => {
      const res = await getWorkoutsCalendars(params);
      return res;
    },
    {
      staleTime: Infinity,
      retry: false,
    },
  );
};

export const useGetWorkoutsCalendar = (workoutsCalendarId: string | number) => {
  if (!workoutsCalendarId)
    return useQuery(
      ['workoutsCalendar', 'workoutsCalendarId', workoutsCalendarId],
      () => {
        return Promise.resolve(null);
      },
    );
  return useQuery<IWorkoutsCalendar, unknown>(
    ['workoutsCalendar', 'workoutsCalendarId', workoutsCalendarId],
    async () => {
      const res = await getWorkoutsCalendar(workoutsCalendarId);
      return res;
    },
    {
      staleTime: Infinity,
      retry: false,
    },
  );
};

export const useCreateWorkoutsCalendar = () => {
  const queryClient = useQueryClient();
  return useMutation(createWorkoutsCalendar, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['workoutsCalendar'] });
    },
    onError: () => {},
  });
};

export const useUpdateWorkoutsCalendar = () => {
  const queryClient = useQueryClient();
  return useMutation(updateWorkoutsCalendar, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['workoutsCalendar'] });
    },
    onError: () => {},
  });
};

export const useDeleteWorkoutsCalendar = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteWorkoutsCalendar, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['workoutsCalendar'] });
    },
    onError: () => {},
  });
};

export const useGetWorkoutTraningTypesList = () => {
  return useQuery<IWorkoutCalendarTraniningListRes, unknown>(
    'customWorkouts',
    async () => {
      const res = await getWorkoutCalendarTrainingTypesList();
      return res;
    },
    {
      staleTime: Infinity,
      retry: false,
    },
  );
};
