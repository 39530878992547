import { FC } from 'react';
import { styled } from '@mui/system';
import { Box } from '@mui/material';
import { DeleteModal } from 'components';
import { useDeleteAppUser, useDeleteWorker } from 'hooks';
import { COLORS } from 'utils';
import { UserImage } from 'UI';

export const StyledUserInfoAtModal = styled(Box)({
  display: 'flex',
  gap: '0.625rem',
  border: `1px solid ${COLORS.GRAY}`,
  padding: '0.625rem',
  borderRadius: '0.5rem',
});

export const StyledAvatar = styled(Box)({
  width: '3.125rem',
  height: '3.125rem',
  borderRadius: '50%',
  overflow: 'hidden',
  cursor: 'pointer',
  '& img': {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
  },
});

export const StyledUserMeta = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  cursor: 'pointer',
  '& div': {
    fontSize: '0.875rem',
    fontFamily: '"SF Pro Display", sans-serif',
  },
  '& .name': {
    fontWeight: 600,
    width: 'max-content',
  },
  '& .phone': {
    opacity: 0.5,
    fontWeight: 400,
  },
});

const TRANSLATIONS = {
  worker: {
    title: 'Удалить сотрудника',
    description: 'Вы действительно хотите удалить сотрудника',
  },
  user: {
    title: 'Удалить пользователя',
    description: 'Вы действительно хотите удалить пользователя',
  },
};

interface IDeleteUserModalProps {
  open: boolean;
  onClose: () => void;
  userType: 'worker' | 'user';
  id?: number;
  firstname?: string;
  lastname?: string;
  photo?: string;
  phone?: string;
}

export const DeleteUserModal: FC<IDeleteUserModalProps> = ({
  open,
  onClose,
  userType = 'worker',
  id = 0,
  firstname = '',
  lastname = '',
  photo = '',
  phone = '',
}) => {
  const { mutateAsync: deleteAppUser } = useDeleteAppUser();
  const { mutateAsync: deleteWorker } = useDeleteWorker();

  const handleDelete = () => {
    if (userType === 'worker') {
      deleteWorker(id)
        .then(() => {
          onClose();
        })
        .catch(() => {});
      return;
    }
    deleteAppUser(id)
      .then(() => {
        onClose();
      })
      .catch(() => {});
  };

  return (
    <DeleteModal
      open={open}
      title={TRANSLATIONS[userType].title}
      description={TRANSLATIONS[userType].description}
      onConfirm={handleDelete}
      onClose={onClose}
    >
      <StyledUserInfoAtModal>
        <StyledAvatar>
          <UserImage
            src={photo as string | undefined}
            alt={firstname}
            firstName={firstname}
            lastName={lastname}
            width='3.125rem'
            height='3.125rem'
          />
        </StyledAvatar>
        <StyledUserMeta>
          <Box className='name'>{firstname} {lastname}</Box>
          <Box className='phone'>{phone}</Box>
        </StyledUserMeta>
      </StyledUserInfoAtModal>
    </DeleteModal>
  );
};
