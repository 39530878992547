export interface IReportDownloadProps {
  showPopover: boolean;
  anchorEl: HTMLButtonElement | null;
  handleClose: () => void;
}

export type FileRoutes =
  | 'news'
  | 'users'
  | 'showcasePartners'
  | 'showcaseClients';

type FileRoutesKeys =
  | 'NEWS'
  | 'USERS'
  | 'SHOWCASE_PARTNERS'
  | 'SHOWCASE_CLIENTS';

export const FILE_ROUTES: Record<FileRoutesKeys, FileRoutes> = {
  NEWS: 'news',
  USERS: 'users',
  SHOWCASE_PARTNERS: 'showcasePartners',
  SHOWCASE_CLIENTS: 'showcaseClients',
};

export interface IFiles extends Record<FileRoutes, string> {}

export const DEFAULT_FILES: IFiles = {
  news: '',
  users: '',
  showcasePartners: '',
  showcaseClients: '',
};
