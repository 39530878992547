import { FC } from 'react';
import { IIconProps } from './types';

export const ShopIcon: FC<IIconProps> = ({ isActive, activeColor }) => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g>
        <path
          d='M15 8.5V6C15 4.34315 13.6569 3 12 3C10.3431 3 9 4.34315 9 6V8.5M5.93939 21H18.0606C19.1317 21 20 20.1478 20 19.0967L18.7576 8.99997C18.7576 7.94878 17.8893 7.09663 16.8182 7.09663H6.93939C5.8683 7.09663 5 7.94878 5 8.99997L4 19.0967C4 20.1478 4.8683 21 5.93939 21Z'
          stroke={isActive ? activeColor : '#C4C4C4'}
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
    </svg>
  );
};
