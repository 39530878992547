import { FC } from 'react';

export const CaloryIcon: FC = () => {
  return (
    <svg
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        x='0.730469'
        y='0.798828'
        width='17.152'
        height='17.152'
        rx='8.57601'
        fill='url(#paint0_linear_4432_84664)'
      />
      <g clipPath='url(#clip0_4432_84664)'>
        <path
          d='M10.3756 3.65747C7.93878 5.06154 8.23162 9.01748 8.23162 9.01748C8.23162 9.01748 7.15963 8.66014 7.15963 7.05215C5.88066 7.79377 5.01562 9.21922 5.01562 10.8041C5.01562 13.1724 6.93543 15.0922 9.30364 15.0922C11.6718 15.0922 13.5916 13.1724 13.5916 10.8041C13.5916 7.32015 10.3756 6.60547 10.3756 3.65747V3.65747ZM9.68029 13.6146C8.81863 13.8295 7.94592 13.3051 7.73104 12.4434C7.51621 11.5818 8.04056 10.709 8.90227 10.4942C10.9826 9.97548 11.2433 8.80563 11.2433 8.80563C11.2433 8.80563 12.2807 12.9663 9.68029 13.6146V13.6146Z'
          fill='white'
        />
      </g>
      <defs>
        <linearGradient
          id='paint0_linear_4432_84664'
          x1='0.730469'
          y1='0.798828'
          x2='17.8825'
          y2='17.9509'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FF0054' />
          <stop offset='1' stopColor='#FF8E00' />
        </linearGradient>
        <clipPath id='clip0_4432_84664'>
          <rect
            width='11.4347'
            height='11.4347'
            fill='white'
            transform='translate(3.58984 3.65747)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};
