import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { COLORS } from 'utils';

export const StyledHeader = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  gap: '1rem',
  '& .logo': {
    width: '5rem',
    height: '5rem',
    borderRadius: '50%',
    overflow: 'hidden',
    backgroundColor: COLORS.WHITE,
    objectFit: 'contain',
  },
  '& .name': {
    fontSize: '1.25rem',
    lineHeight: '1.625rem',
    fontWeight: 600,
    fontFamily: '"SF Pro Display", sans-serif',
    textAlign: 'center',
  },
  '& .description': {
    fontSize: '1rem',
    lineHeight: '1.5rem',
    fontWeight: 500,
    fontFamily: '"SF Pro Display", sans-serif',
    textAlign: 'center',
    opacity: 0.5,
  },
});

export const StyledBody = styled(Box)({
  padding: '1.25rem 0',
  display: 'flex',
  flexDirection: 'column',
  gap: '1.25rem',
  '& .editButton': {
    position: 'absolute',
    bottom: '1.875rem',
    right: '1.875rem',
  },
});

export const StyledInfoBlock = styled(Box)({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  '& .title': {
    opacity: '0.5',
    fontFamily: '"SF Pro Display", sans-serif',
    fontSize: '0.75rem',
    lineHeight: '1.25rem',
  },
  '& .info': {
    fontFamily: '"SF Pro Display", sans-serif',
    fontSize: '1rem',
    lineHeight: '1.625rem',
    padding: '0.75rem 1rem',
    backgroundColor: COLORS.WHITE,
    borderRadius: '0.5rem',
  },
});

export const StyledUsersBlock = styled(Box)({
  border: '1px solid rgba(0, 0, 0, 0.10)',
  borderRadius: '0.5rem',
  backgroundColor: COLORS.WHITE,
  '& .header': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '1rem 1.25rem',
    fontSize: '1.25rem',
    fontWeight: 600,
    fontFamily: '"SF Pro Display", sans-serif',
    borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
  },
  '& .body': {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 1.25rem',
    maxHeight: '20rem',
    overflowY: 'auto',
  },
  '& .item': {
    display: 'flex',
    alignItems: 'center',
    gap: '0.625rem',
    padding: '0.75rem 0',
    borderTop: '1px solid rgba(0, 0, 0, 0.05)',
    '& .image': {
      width: '3.125rem',
      height: '3.125rem',
      borderRadius: '50%',
      overflow: 'hidden',
      '& img': {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        objectPosition: 'center',
      },
    },
    '& .name': {
      fontSize: '0.875rem',
      lineHeight: '1.25rem',
      fontWeight: 500,
      fontFamily: '"SF Pro Display", sans-serif',
    },
  },
});
