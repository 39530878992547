import { FC } from 'react';
import { IIconProps } from './types';

export const AchievementsIcon: FC<IIconProps> = ({ isActive, activeColor }) => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g>
        <path
          d='M14.272 10.445L18 2M9.316 10.632L5 2M12.761 10.048L8.835 2M14.36 2L13.32 4.5M6 16C6 17.5913 6.63214 19.1174 7.75736 20.2426C8.88258 21.3679 10.4087 22 12 22C13.5913 22 15.1174 21.3679 16.2426 20.2426C17.3679 19.1174 18 17.5913 18 16C18 14.4087 17.3679 12.8826 16.2426 11.7574C15.1174 10.6321 13.5913 10 12 10C10.4087 10 8.88258 10.6321 7.75736 11.7574C6.63214 12.8826 6 14.4087 6 16Z'
          stroke={isActive ? activeColor : '#C4C4C4'}
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M10.5 15L12.5 13.5V18.5'
          stroke={isActive ? activeColor : '#C4C4C4'}
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
    </svg>
  );
};
