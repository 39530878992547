import { FC, useState } from 'react';
import { styled } from '@mui/system';
import { Box, Chip, TextField } from '@mui/material';
import { FormItem } from 'UI';
import { COLORS } from 'utils';

export const ChipsBox = styled(Box)({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '0.5rem',
  margin: '0.5rem 0',
});

// Color will be handled by props in the future
export const StyledChip = styled(Chip)({
  padding: '0.25rem 0.5rem',
  backgroundColor: COLORS.BLACK,
  color: COLORS.WHITE,
  '& .MuiChip-deleteIcon': {
    color: COLORS.WHITE,
    opacity: 0.5,
    transition: 'all 0.3s ease',
    '&:hover': {
      color: COLORS.WHITE,
      opacity: 1,
    },
  },
});

interface ITagAdderProps {
  tags: string[];
  updateTags: (_tags: string[]) => void;
  label?: string;
  placeholder?: string;
}

export const TagAdder: FC<ITagAdderProps> = ({
  tags,
  updateTags,
  label = 'Теги',
  placeholder = 'Пишите тег и нажмите Enter',
}) => {
  const [tagText, setTagText] = useState<string>('');

  const addTag = (key: string) => {
    if (key === 'Enter') {
      updateTags([...tags, `#${tagText}`]);
      setTagText('');
    }
  };

  const removeTag = (tag: string) => {
    updateTags(tags.filter(t => t !== tag));
  };

  return (
    <FormItem label={label}>
      <TextField
        variant='standard'
        color='primary'
        placeholder={placeholder}
        name='tag'
        value={tagText}
        onChange={e => setTagText(e.target.value)}
        onKeyUp={e => addTag(e.key)}
      />
      <ChipsBox>
        {tags?.map(tag => (
          <StyledChip key={tag} label={tag} onDelete={() => removeTag(tag)} />
        ))}
      </ChipsBox>
    </FormItem>
  );
};
