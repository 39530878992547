import { FC } from 'react';
import { styled } from '@mui/system';
import {
  Paper,
  Box,
  List,
  ListItem,
  ListItemText,
  LinearProgress,
} from '@mui/material';
import { formatNumber } from 'utils';

const StyledContainer = styled(Paper)({
  width: '100%',
  borderRadius: '0.75rem',
  '& .header': {
    padding: '0.75rem 1.25rem',
    fontSize: '1.25rem',
    fontWeight: 500,
    fontFamily: '"SF Pro Display", sans-serif',
    lineHeight: '1.5rem',
    borderBottom: `1px solid rgba(0, 0, 0, 0.1)`,
  },

  '& .list': {
    padding: '1rem 1.25rem',
    '& .listItem': {
      display: 'block',
      padding: '0 0 0.25rem',
      '& .linearProgress': {
        backgroundColor: 'transparent',
        width: '100%',
        borderRadius: '0.3125rem',
        height: '0.5rem',
        '& .MuiLinearProgress-bar': {
          borderRadius: '0.3125rem',
        },
      },
      '& .info': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        fontFamily: '"SF Pro Display", sans-serif',
        '& .title': {
          '& span': {
            fontSize: '0.875rem',
            lineHeight: '1.375rem',
            fontWeight: 500,
          },
        },
        '& .value': {
          textAlign: 'right',
          '& span': {
            fontWeight: 600,
            fontSize: '1rem',
            lineHeight: '1.5rem',
          },
        },
      },
    },
  },

  '& .footer': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0.75rem 1.25rem',
    fontSize: '1rem',
    fontFamily: '"SF Pro Display", sans-serif',
    lineHeight: '1.375rem',
    borderTop: `1px solid rgba(0, 0, 0, 0.1)`,
    '& .label': {
      fontWeight: 500,
    },
    '& .value': {
      fontWeight: 600,
    },
  },
});

interface IAnalyticsHorizontalBarChartProps {
  title: string;
  sections: Array<IInfoSection>;
  sum: number;
}

interface IInfoSection {
  label: string;
  value: number;
  color: string;
}

export const AnalyticsHorizontalBarChart: FC<
  IAnalyticsHorizontalBarChartProps
> = ({ title, sections, sum }) => {
  return (
    <StyledContainer>
      <Box className='header'>{title}</Box>
      <List className='list'>
        {sections?.map(item => {
          const percent = sum === 0 ? 0 : (item.value / sum) * 100;
          return (
            <ListItem key={item.label} className='listItem'>
              <LinearProgress
                variant='determinate'
                value={percent}
                className='linearProgress'
                sx={{
                  '& .MuiLinearProgress-bar': {
                    backgroundColor: item.color,
                  },
                }}
              />
              <Box className='info'>
                <ListItemText className='title'>{item.label}</ListItemText>
                <ListItemText className='value'>
                  {formatNumber(item.value)}
                </ListItemText>
              </Box>
            </ListItem>
          );
        })}
      </List>

      <Box className='footer'>
        <Box className='label'>Итого</Box>
        <Box className='value'>{sum}</Box>
      </Box>
    </StyledContainer>
  );
};
