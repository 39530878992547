import { createTheme } from '@mui/material/styles';

export const muiTheme = createTheme({
  palette: {
    primary: {
      main: '#0080ff',
      dark: '#0059b2',
      light: '#3399ff',
    },
    secondary: {
      main: '#EB5858',
      dark: '#a43d3d',
      light: '#ef7979',
    },
    secondaryRed2: {
      main: '#CE3548',
      dark: '#a43d3d',
      light: '#ef7979',
      contrastText: '#ffffff',
    },
    customGray: {
      main: '#F5F5F5',
      dark: '#d5d5d5',
      light: '#f7f7f7',
      contrastText: '#000000',
    },
    customBlack: {
      main: '#000000',
      contrastText: '#ffffff',
      light: '#333333',
    },
    customWhite: {
      main: '#ffffff',
      contrastText: '#000000',
      dark: '#e5e5e5',
    },
    customDarkerGray: {
      main: '#0000000D',
      dark: '#0000003D',
      contrastText: '#000000',
    },
    customLightBlue: {
      main: 'rgba(0, 128, 255, 0.05)',
      dark: 'rgba(0, 128, 255, 0.15)',
      contrastText: '#0080ff',
    },
  },
  breakpoints: {
    values: {
      xs: 320,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
    },
  },
  typography: {
    fontFamily: 'Montserrat, Arial, sans-serif',
  },
  components: {
    MuiPopover: {
      styleOverrides: {
        paper: {
          borderRadius: '0.5rem',
        },
      },
    },
  },
});
