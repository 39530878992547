import { FC, useState, useMemo, useEffect } from 'react';
import { Box, TextField } from '@mui/material';
import {
  Drawer,
  DrawerHeader,
  DrawerFormBody,
  ModalTitle,
  Button,
  StyledFlexBox,
  ImageInput,
  FormItem,
  SearchSelectInput,
} from 'UI';
import { AddUserToTeam } from 'components';
import { CloseIcon } from 'assets';
import { ITeamReq, ITeamMemberShort, ITeamMember } from 'types';
import {
  useGetSpacesList,
  useGetTeam,
  useCreateTeam,
  useUpdateTeam,
} from 'hooks';
import { MEDIA_TYPES } from 'utils';

const TRANSLATIONS = {
  add: {
    title: 'Создать команду',
  },
  edit: {
    title: 'Редактировать команду',
  },
};

const DEFAULT_VALUES = {
  name: '',
  description: '',
  logo: '',
  is_active: true,
  space_id: 0,
  members: [],
};

interface IAddEditTeamDrawerProps {
  open: boolean;
  onClose: () => void;
  purpose: 'add' | 'edit';
  id: string;
}

export const AddEditTeamDrawer: FC<IAddEditTeamDrawerProps> = ({
  open,
  onClose,
  purpose = 'add',
  id = '',
}) => {
  const { data: teamInfoData } = useGetTeam(id);
  const [teamInfo, setTeamInfo] = useState<ITeamReq>(DEFAULT_VALUES);
  const [fullMembers, setFullMembers] = useState<ITeamMember[]>([]);

  const { data: spacesList } = useGetSpacesList();
  const { mutateAsync: createTeam } = useCreateTeam();
  const { mutateAsync: updateTeam } = useUpdateTeam();

  const options = useMemo(() => {
    return (
      spacesList?.map(space => ({
        label: space.name,
        value: space.id,
      })) || []
    );
  }, [spacesList]);

  useEffect(() => {
    if (purpose === 'edit') {
      setTeamInfo({
        id: teamInfoData?.id,
        name: teamInfoData?.name || '',
        logo: teamInfoData?.logo || '',
        description: teamInfoData?.description || '',
        is_active: !!teamInfoData?.is_active,
        space_id: teamInfoData?.space?.id || 0,
        members:
          teamInfoData?.members?.map(user => ({
            id: user.id,
            is_leader: user.is_leader,
          })) || [],
      });
      setFullMembers(teamInfoData?.members || []);
    } else {
      setTeamInfo(DEFAULT_VALUES);
      setFullMembers([]);
    }
  }, [purpose, teamInfoData]);

  const handleFieldChange = <T extends keyof ITeamReq>(
    fieldName: T,
    newValue: ITeamReq[T],
  ) => {
    setTeamInfo(prevTeamInfo => ({
      ...prevTeamInfo,
      [fieldName]: newValue,
    }));
  };

  const changeMembers = (newMembers: ITeamMember[]) => {
    setFullMembers(newMembers);
    const newMembersShort = newMembers.map(member => ({
      id: member.id,
      is_leader: member.is_leader,
    }));
    handleFieldChange('members', newMembersShort);
  };

  const changeCaptain = (newCaptain: ITeamMemberShort) => {
    setFullMembers(prevMembers =>
      prevMembers.map(member => ({
        ...member,
        is_leader: member.id === newCaptain.id,
      })),
    );
    handleFieldChange(
      'members',
      teamInfo.members.map(member => ({
        ...member,
        is_leader: member.id === newCaptain.id,
      })),
    );
  };

  const ignoreChanges = () => {
    setTeamInfo(DEFAULT_VALUES);
    setFullMembers([]);
    onClose();
  };
  const saveChanges = () => {
    if (purpose === 'add') {
      createTeam(teamInfo)
        .then(() => {
          onClose();
        })
        .catch(() => {});
    } else {
      updateTeam(teamInfo)
        .then(() => {
          onClose();
        })
        .catch(() => {});
    }
  };

  return (
    <Drawer open={open} onClose={ignoreChanges}>
      <DrawerHeader>
        <ModalTitle title={TRANSLATIONS[purpose].title} />
        <CloseIcon onClick={ignoreChanges} />
      </DrawerHeader>
      <DrawerFormBody>
        <StyledFlexBox>
          <ImageInput
            inputId='spaceLogo'
            title='Добавьте аватарку'
            descriptionWithoutImage='Загрузите изображение
            (512x512 px, до 1 мб)'
            withDescription
            image={teamInfo.logo}
            setImage={newImg => handleFieldChange('logo', newImg)}
            style={{ width: '60%' }}
            mediaType={MEDIA_TYPES.TEAM_LOGO}
            imageFixedWidth={512}
            imageFixedHeight={512}
          />
        </StyledFlexBox>
        <h2>О команде</h2>
        <FormItem label='Название'>
          <TextField
            variant='standard'
            color='primary'
            placeholder='Введите текст'
            name='name'
            value={teamInfo.name}
            onChange={e => handleFieldChange('name', e.target.value)}
          />
        </FormItem>
        <FormItem label='Описание'>
          <TextField
            variant='standard'
            color='primary'
            placeholder='Введите описание'
            name='description'
            value={teamInfo.description}
            onChange={e => handleFieldChange('description', e.target.value)}
          />
        </FormItem>
        <FormItem label='Пространство'>
          <SearchSelectInput
            options={options}
            value={teamInfo.space_id}
            onChange={newValue =>
              handleFieldChange('space_id', newValue as number)
            }
            placeholder='Выберите пространство'
            variant='standard'
          />
        </FormItem>
        <AddUserToTeam
          spaceId={teamInfo.space_id}
          members={fullMembers}
          changeMembers={changeMembers}
          changeCaptain={changeCaptain}
        />
      </DrawerFormBody>
      <StyledFlexBox className='buttons fullDivWidth'>
        <Box />
        <Button variant='contained' size='large' onClick={saveChanges}>
          Сохранить
        </Button>
      </StyledFlexBox>
    </Drawer>
  );
};
