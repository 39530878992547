import { FC, useContext, useState, useRef, useEffect } from 'react';
import { styled } from '@mui/system';
import { ButtonGroup, Box } from '@mui/material';
import { Button } from 'UI';
import { COLORS, FilterContext } from 'utils';
import { LeftIcon, RightIcon } from 'assets';
import { useGetFilterDate } from 'hooks';
import { IFilterDate } from 'types';
import { useLocation } from 'react-router-dom';

const StyledContainer = styled(Box)({
  width: '30rem',
});

const StyledButtonGroup = styled(ButtonGroup)({
  padding: '0.25rem',
  borderRadius: '0.5rem',
  width: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.05)',
  '& button': {
    width: '100%',
    backgroundColor: 'transparent ',
    border: 'none !important',
    '&.active': {
      border: '1px solid transparent',
      backgroundColor: COLORS.WHITE,
      borderRadius: '0.25rem',
    },
  },
});

const StyledSubFilterButtonsContainer = styled(Box)({
  display: 'flex',
  width: '100%',
  gap: '0.25rem',
  padding: '0.625rem 0',
  '& .paginationButton': {
    backgroundColor: COLORS.WHITE,
    padding: '0.375rem',
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.12)',
    },
  },
  '& .buttonsList': {
    display: 'flex',
    gap: '0.25rem',
    overflow: 'auto',
    width: '100%',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '& button': {
      padding: '0.375rem 0.75rem',
      backgroundColor: 'transparent',
      '&.active': {
        backgroundColor: COLORS.WHITE,
      },
    },
  },
});

type ButtonValue = 'today' | 'week' | 'month' | 'year';

interface IFilterButton {
  title: string;
  value: ButtonValue;
}

const FILTER_BUTTONS: IFilterButton[] = [
  {
    title: 'Сегодня',
    value: 'today',
  },
  // {
  //   title: 'Неделя',
  //   value: 'week',
  // },
  {
    title: 'Месяц',
    value: 'month',
  },
  {
    title: 'Год',
    value: 'year',
  },
];

const getActiveFilterValue = (difference: number) => {
  if (difference <= 1) {
    return 'today';
  }
  // if (difference <= 7) {
  //   return 'week';
  // }
  if (difference <= 31) {
    return 'month';
  }
  return 'year';
};

const differenceBetweenDates = (date1?: string, date2?: string): number => {
  if (!date1 || !date2) return 0;
  const firstDate = new Date(date1);
  const secondDate = new Date(date2);
  const difference = Math.abs(firstDate.getTime() - secondDate.getTime());
  return Math.ceil(difference / (1000 * 3600 * 24));
};

export const AnalyticsFilter: FC = () => {
  const buttonsListRef = useRef<HTMLDivElement | null>(null);
  const {
    startDate: filterStartDate,
    endDate: filterEndDate,
    updateDays,
  } = useContext(FilterContext);
  const { pathname } = useLocation();

  const [activeFilter, setActiveFilter] = useState<ButtonValue>(
    getActiveFilterValue(
      differenceBetweenDates(filterStartDate, filterEndDate),
    ),
  );
  const [showSubFilter, setShowSubFilter] = useState(false);
  const [activeSubFilterName, setActiveSubFilterName] = useState<string>('');
  const [rightPgntBtnDisabled, setRightPgntBtnDisabled] = useState(false);
  const [leftPgntBtnDisabled, setLeftPgntBtnDisabled] = useState(true);
  const [activeSubFilterButtons, setActiveSubFilterButtons] = useState<
    IFilterDate[]
  >([]);

  const {
    data: { months, years } = {
      months: [],
      years: [],
    },
  } = useGetFilterDate();

  const setFilterAsToday = () => {
    setShowSubFilter(false);
    const today = new Date();
    // TODO: after GTO-1303 is done tomorrow will be removed and only today will be send as start and end dates
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    const todayString = today.toISOString().split('T')[0];
    const tomorrowString = tomorrow.toISOString().split('T')[0];
    updateDays(todayString, tomorrowString);
  };

  const onFilterButtonClick = (value: ButtonValue) => {
    setActiveFilter(value);
    switch (value) {
      case 'today':
        setFilterAsToday();
        break;
      // case 'week':
      //   setShowSubFilter(true);
      //   setActiveSubFilterButtons(weeks);
      //   break;
      case 'month':
        setShowSubFilter(true);
        setActiveSubFilterButtons(months);
        break;
      case 'year':
        setShowSubFilter(true);
        setActiveSubFilterButtons(years);
        break;
      default:
    }
  };

  useEffect(() => {
    const innerContainer =
      document.getElementById('innerPageContainer') ||
      document.getElementById('fullAnalyticsTableContainer');
    if (!innerContainer) return;
    if (showSubFilter) {
      innerContainer.classList.add('subFilterActive');
    } else {
      innerContainer.classList.remove('subFilterActive');
    }
  }, [showSubFilter, pathname]);

  const onSubFilterButtonClick = (
    title: string,
    startDate: string,
    endDate: string,
  ) => {
    setActiveSubFilterName(title);
    updateDays(startDate, endDate);
    // setShowSubFilter(false);
  };

  const onLeftPaginationBtnClick = () => {
    if (!buttonsListRef.current) return;
    buttonsListRef.current.scrollBy({
      left: -100,
      behavior: 'smooth',
    });
    changePgntBtnsState();
  };

  const onRightPaginationBtnClick = () => {
    if (!buttonsListRef.current) return;
    buttonsListRef.current.scrollBy({
      left: 100,
      behavior: 'smooth',
    });
    changePgntBtnsState();
  };

  const changePgntBtnsState = () => {
    if (!buttonsListRef.current) return;
    setLeftPgntBtnDisabled(buttonsListRef.current.scrollLeft === 0);
    setRightPgntBtnDisabled(
      buttonsListRef.current.scrollLeft >=
        buttonsListRef.current.scrollWidth -
          buttonsListRef.current.clientWidth -
          1,
    );
  };

  return (
    <StyledContainer>
      <StyledButtonGroup>
        {FILTER_BUTTONS.map(({ title, value }) => (
          <Button
            color='customDarkerGray'
            className={activeFilter === value ? 'active' : ''}
            onClick={_e => onFilterButtonClick(value)}
            key={title}
          >
            {title}
          </Button>
        ))}
      </StyledButtonGroup>
      {showSubFilter && (
        <StyledSubFilterButtonsContainer>
          <Button
            color='customDarkerGray'
            className='paginationButton'
            onClick={onLeftPaginationBtnClick}
            disabled={leftPgntBtnDisabled}
          >
            <LeftIcon
              style={{
                opacity: leftPgntBtnDisabled ? 0.5 : 1,
              }}
            />
          </Button>
          <Box className='buttonsList' ref={buttonsListRef}>
            {activeSubFilterButtons?.map(({ name, start_date, end_date }) => (
              <Button
                color='customDarkerGray'
                onClick={_e =>
                  onSubFilterButtonClick(name, start_date, end_date)
                }
                key={name}
                className={activeSubFilterName === name ? 'active' : ''}
              >
                {name}
              </Button>
            ))}
          </Box>
          <Button
            color='customDarkerGray'
            className='paginationButton'
            onClick={onRightPaginationBtnClick}
            disabled={rightPgntBtnDisabled}
          >
            <RightIcon
              style={{
                opacity: rightPgntBtnDisabled ? 0.5 : 1,
              }}
            />
          </Button>
        </StyledSubFilterButtonsContainer>
      )}
    </StyledContainer>
  );
};
