import { FC } from 'react';
import { styled } from '@mui/system';
import { CircularProgress, Box } from '@mui/material';

const StyledContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '50vh',
});

export const Loader: FC = () => {
  return (
    <StyledContainer>
      <CircularProgress />
    </StyledContainer>
  );
};
