import { IWorkerGetParams, IWorkerReq } from 'types';
import { http, methods } from './config';

export const getWorkers = (params: IWorkerGetParams) => {
  const config = {
    method: methods.get,
    url: `/user/dashboard`,
    params,
  };
  return http(config);
};

export const getWorker = (userId: number | string) => {
  const config = {
    method: methods.get,
    url: `/user/dashboard/${userId}`,
  };
  return http(config);
};

export const createWorker = (data: IWorkerReq) => {
  const config = {
    method: methods.post,
    url: `/user/dashboard`,
    data,
  };
  return http(config);
};

export const updateWorker = (data: IWorkerReq) => {
  const config = {
    method: methods.put,
    url: `/user/dashboard/${data.id}`,
    data,
  };
  return http(config);
};

export const deleteWorker = (userId: number | string) => {
  const config = {
    method: methods.delete,
    url: `/user/dashboard/${userId}`,
  };
  return http(config);
};

export const getRolesList = () => {
  const config = {
    method: methods.get,
    url: `/type/role`,
  };
  return http(config);
};
