import { FC, useState, MouseEvent } from 'react';
import { Box, Popover, TextField } from '@mui/material';
import { FormItem, ColorPicker } from 'UI';
import { PaletteIcon } from 'assets';

interface IFullColorPickerProps {
  color: string;
  changeColor: (_newColor: string) => void;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
}

export const FullColorPicker: FC<IFullColorPickerProps> = ({
  color,
  changeColor,
  disabled = 'false',
  label = 'Цвет',
  placeholder = '#000000',
}) => {
  const [showColorPicker, setShowColorPicker] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<SVGElement | null>(null);

  const openColorPicker = (event: MouseEvent<SVGElement>) => {
    if (disabled) return;
    setShowColorPicker(true);
    setAnchorEl(event.currentTarget);
  };

  const closeColorPicker = () => {
    setShowColorPicker(false);
    setAnchorEl(null);
  };

  const saveColorFromPicker = (newColor: string) => {
    changeColor(newColor);
    closeColorPicker();
  };

  return (
    <>
      <Popover
        id={showColorPicker ? 'colorPickerPopover' : undefined}
        open={showColorPicker}
        anchorEl={anchorEl}
        onClose={closeColorPicker}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <ColorPicker color={color} saveColor={saveColorFromPicker} />
      </Popover>
      <FormItem label={label}>
        <TextField
          variant='standard'
          color='primary'
          placeholder={placeholder}
          name='color'
          value={color}
          onChange={e => changeColor(e.target.value)}
          InputProps={{
            startAdornment: (
              <Box
                style={{
                  minWidth: '1.125rem',
                  maxWidth: '1.125rem',
                  height: '1.125rem',
                  backgroundColor: color,
                  borderRadius: '50%',
                  border: '1px solid #E0E0E0',
                  marginRight: '0.625rem',
                }}
              />
            ),
            endAdornment: (
              <PaletteIcon
                onClick={e => openColorPicker(e)}
                className='paletteIcon'
              />
            ),
          }}
          disabled={!!disabled}
        />
      </FormItem>
    </>
  );
};
