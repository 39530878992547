import { FC, useMemo } from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/system';

import { SearchSelectInput } from 'UI';
import { useSearchParams, useGetSpacesList } from 'hooks';
import { COLORS } from 'utils';

const StyledBox = styled(Box)({
  minWidth: '15rem',
  '& .MuiAutocomplete-root': {
    width: '100%',
    backgroundColor: COLORS.WHITE,
    borderRadius: '0.5rem',
  },
  '& .MuiInputBase-root': {
    height: '2.75rem',
    borderRadius: '0.5rem',
    '& fieldset': {
      border: `1px solid ${COLORS.WHITE}`,
    },
    '&:hover fieldset': {
      border: `1px solid ${COLORS.WHITE}`,
    },
    '&.Mui-focused fieldset': {
      border: `1px solid ${COLORS.BLUE}`,
    },
  },
  '&.filterAtTable': {
    '& .MuiAutocomplete-root': {
      backgroundColor: COLORS.GRAY,
      height: '1.75rem',
    },
    '& .MuiInputBase-root': {
      height: '1.75rem',
      fontSize: '0.875rem',
    },
    '& .MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
      padding: '0.125rem 0.5rem',
    },
  },
});

interface IFilterSpaceProps {
  size?: 'small' | 'medium';
  className?: string;
}

export const FilterSpace: FC<IFilterSpaceProps> = ({
  size = 'small',
  className,
}) => {
  const { searchParams, setParam, getParam } = useSearchParams();
  const spcId = useMemo(() => +getParam('space_id'), [searchParams]);
  const { data: spacesList, isLoading: isLoadingSpaces } = useGetSpacesList();

  const options = useMemo(() => {
    return (
      spacesList?.map(space => ({
        label: space.name,
        value: space.id,
      })) || []
    );
  }, [isLoadingSpaces, spacesList]);

  const changeSpaceHandler = (newValue: number) => {
    setParam('space_id', newValue);
  };

  return (
    <StyledBox className={className}>
      <SearchSelectInput
        options={options}
        onChange={newValue => changeSpaceHandler(newValue as number)}
        value={spcId}
        size={size}
        placeholder='Выберите пространство'
        variant='outlined'
        disableClearable
      />
    </StyledBox>
  );
};
