import { styled } from '@mui/system';
import { Box } from '@mui/material';
import { COLORS } from 'utils';

export const StyledRoleBox = styled(Box)({
  padding: '1.25rem',
  borderRadius: '0.5rem',
  display: 'flex',
  flexDirection: 'column',
  gap: '0.9375rem',
  border: `1px solid ${COLORS.GRAY}`,
  '& h3': {
    margin: 0,
    fontSize: '1.125rem',
    fontWeight: 600,
    fontFamily: '"SF Pro Display", sans-serif',
    lineHeight: '1.625rem',
  },
});
