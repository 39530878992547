import { FC, useMemo } from 'react';
import { TableBody, TableCell } from '@mui/material';

import { Pagination } from 'UI';
import { useGetCheckboxLogs, useSearchParams } from 'hooks';

import {
  StyledBox,
  StyledPaper,
  StyledTableContainer,
  StyledTable,
  StyledTableRow,
} from './styledComponents';
import { EventLogsTableHead } from './EventLogTableHead';

export const EventLogsTable: FC = () => {
  const { getParam, searchParams, setParam } = useSearchParams();

  const params = useMemo(() => {
    return {
      page: getParam('page'),
      search: getParam('search'),
    };
  }, [searchParams]);

  const {
    data: { data: events, last_page: pageCount } = {
      data: [],
      last_page: 1,
    },
  } = useGetCheckboxLogs(params);

  const openViewDrawer = (userId: string | number) => {
    setParam('view', userId.toString());
  };

  return (
    <StyledBox>
      <StyledPaper>
        <StyledTableContainer>
          <StyledTable
            aria-labelledby='achievementsTable'
            size='medium'
            stickyHeader
          >
            <EventLogsTableHead />
            <TableBody>
              {events.map(row => {
                return (
                  <StyledTableRow hover key={row.id}>
                    <TableCell
                      component='th'
                      align='center'
                      id={row.id.toString()}
                      scope='row'
                    >
                      {row.id}
                    </TableCell>
                    <TableCell
                      align='center'
                      className='user'
                      onClick={() => openViewDrawer(row.user_id)}
                    >
                      <span>
                        {row.firstname} {row.lastname}
                      </span>
                    </TableCell>
                    <TableCell align='center'>
                      <span>{row.type}</span>
                    </TableCell>
                    <TableCell align='center'>
                      <span>{row.created_at}</span>
                    </TableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </StyledTable>
        </StyledTableContainer>
        <Pagination count={pageCount} />
      </StyledPaper>
    </StyledBox>
  );
};
