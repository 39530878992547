import { FC } from 'react';
import { Button, PageHeader, GlobalSearch } from 'UI';
import { PlusIcon } from 'assets';

interface IMeAndGtoHeaderProps {
  openAddMeAndGtoDrawer: () => void;
}

export const MeAndGtoHeader: FC<IMeAndGtoHeaderProps> = ({
  openAddMeAndGtoDrawer,
}) => {
  return (
    <PageHeader title='Как подготовиться к нормативам'>
      <GlobalSearch size='medium' />
      <Button
        variant='contained'
        onClick={openAddMeAndGtoDrawer}
        startIcon={<PlusIcon />}
      >
        Создать
      </Button>
    </PageHeader>
  );
};
