import { FC, useMemo } from 'react';
import { CreateProductForm, Layout } from 'components';
import { PageTitle } from 'UI';
import { useParams } from 'react-router-dom';

const TRANSLATIONS = {
  add: 'Новый товар',
  edit: 'Редактировать товар',
};

export const CreateEditProduct: FC = () => {
  const { id } = useParams();

  const title = useMemo(() => {
    const purpose = id ? 'edit' : 'add';
    return TRANSLATIONS[purpose];
  }, [id]);

  return (
    <Layout withoutPB>
      <PageTitle title={title} />
      <CreateProductForm />
    </Layout>
  );
};
