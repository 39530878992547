import { FC, ChangeEvent, useState, useContext } from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { UserContext, UserContextType } from 'app';
import { MediaType } from 'types';
import { useUploadMedia } from 'hooks';
import { notify } from 'utils';
import AvatarPlaceholder from 'assets/images/avatar_input_placeholder.png';
import { ImageUploadIcon } from 'assets';

const StyledContainer = styled(Box)({
  borderRadius: '0.5rem',
  overflow: 'hidden',
  minWidth: '5rem',
  height: '5rem',
  position: 'relative',
  '& .photo': {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    objectPosition: 'center',
    cursor: 'pointer',
  },
  '& .uploadIcon': {
    position: 'absolute',
    right: '0.625rem',
    bottom: '0.625rem',
    cursor: 'pointer',
  },
});

export interface IImageUploadProps {
  image: string | null;
  setImage: (_image: string) => void;
  inputId: string;
  imageSize?: number;
  imageFixedWidth?: number;
  imageFixedHeight?: number;
  mediaType: MediaType;
}

export const ImageUpload: FC<IImageUploadProps> = ({
  image,
  setImage,
  inputId,
  imageSize = 1 * 1024 * 1024,
  imageFixedWidth = 512,
  imageFixedHeight = 512,
  mediaType,
}) => {
  const { space_id } = useContext(UserContext) as UserContextType;
  const { mutateAsync: uploadMedia } = useUploadMedia();
  const [imageName, setImageName] = useState<string>('');

  const handleOpenFileUploadModal = () => {
    document.getElementById(inputId)?.click();
  };

  const convertImageSizeToText = (): string => {
    if (imageSize >= 1000000) {
      const sizeInMB = imageSize / 1000000;
      return `${sizeInMB.toFixed(1)} МБ`;
    }
    const sizeInKB = imageSize / 1000;
    return `${sizeInKB.toFixed(0)} КБ`;
  };

  const handleUploadAvatar = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }
    const file = e.target.files[0];
    if (!file) {
      return;
    }
    // max limit
    if (file.size > imageSize) {
      notify(
        `Размер файла превышает максимальный предел в ${convertImageSizeToText()}.`,
        'error',
      );
      return;
    }
    const reader = new FileReader();
    reader.onloadend = () => {
      const img = new Image();
      img.src = reader.result as string;

      img.onload = () => {
        // Check the image dimensions
        if (img.width !== imageFixedWidth || img.height !== imageFixedHeight) {
          notify(
            `Высота или ширина картинки не соответствуют требуемому стандарту (${imageFixedWidth}x${imageFixedHeight})`,
            'error',
          );
          return;
        }
        const formData = new FormData();
        formData.append('image', file, file.name);
        formData.append('type', mediaType);
        formData.append('space_id', (space_id || 1).toString());
        uploadMedia({ data: formData })
          .then(res => {
            setImage(res);
            setImageName(file.name);
          })
          .catch(() => {});
      };
    };
    reader.readAsDataURL(file);
  };

  return (
    <StyledContainer>
      {image ? (
        <>
          <ImageUploadIcon
            className='uploadIcon'
            onClick={handleOpenFileUploadModal}
          />
          <img
            src={image}
            alt={imageName}
            onClick={handleOpenFileUploadModal}
            className='photo'
          />
        </>
      ) : (
        <img
          src={AvatarPlaceholder}
          alt='avatar placeholder'
          onClick={handleOpenFileUploadModal}
          className='photo'
        />
      )}
      <input
        id={inputId}
        type='file'
        style={{ display: 'none' }}
        onChange={e => handleUploadAvatar(e)}
      />
    </StyledContainer>
  );
};
