import { FC, useMemo, useState, useEffect, useContext } from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/system';

import { SearchSelectInput } from 'UI';
import { useGetCategoriesList, useSearchParams } from 'hooks';
import { COLORS } from 'utils';
import { UserContext, UserContextType } from 'app';

const StyledBox = styled(Box)({
  minWidth: '12rem',
  '& .MuiAutocomplete-root': {
    width: '100%',
    backgroundColor: COLORS.WHITE,
    borderRadius: '0.5rem',
  },
  '& .MuiInputBase-root': {
    height: '2.75rem',
    borderRadius: '0.5rem',
    '& fieldset': {
      border: `1px solid ${COLORS.WHITE}`,
    },
    '&:hover fieldset': {
      border: `1px solid ${COLORS.WHITE}`,
    },
    '&.Mui-focused fieldset': {
      border: `1px solid ${COLORS.BLUE}`,
    },
  },
});

interface IFilterCategoryProps {
  size?: 'small' | 'medium';
  className?: string;
}

export const FilterCategory: FC<IFilterCategoryProps> = ({
  size = 'small',
  className,
}) => {
  const { space_id: spaceId } = useContext(UserContext) as UserContextType;
  const { setParams } = useSearchParams();
  const [categoryId, setCategoryId] = useState<number>(0);

  const { data: categoriesList, isLoading: isLoadingCategories } =
    useGetCategoriesList(spaceId!);

  const options = useMemo(() => {
    return [
      { label: 'Все категории', value: 0 },
      ...(categoriesList?.map(category => ({
        label: category.name,
        value: +category.id,
      })) || []),
    ];
  }, [categoriesList, isLoadingCategories]);

  const changeCategoryHandler = (newValue: number) => {
    setCategoryId(newValue);
  };

  useEffect(() => {
    setParams({ category_id: categoryId.toString(), page: 1 });
  }, [categoryId]);

  return (
    <StyledBox className={className}>
      <SearchSelectInput
        options={options}
        onChange={newValue => changeCategoryHandler(newValue as number)}
        value={categoryId}
        size={size}
        placeholder='Все категории'
        variant='outlined'
        disableClearable
      />
    </StyledBox>
  );
};
