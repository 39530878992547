import { useQuery } from 'react-query';
import {
  getAnalyticsShowcaseData,
  getAnalyticsShowcaseGender,
  getAnalyticsShowcaseTopCategories,
  getAnalyticsShowcaseTopDiscounts,
} from 'api';
import {
  IAnalyticsCommonGetParams,
  IAnalyticsGenderRes,
  IAnalyticsShowcaseCardRes,
  IAnalyticsTopListGetParams,
  IAnalyticsTopListRes,
  IAnalyticsShowcaseTopDiscountCard,
} from 'types';

export const useGetAnalyticsShowcaseData = (
  params: IAnalyticsCommonGetParams,
) => {
  const { start_date, end_date } = params;
  return useQuery<IAnalyticsShowcaseCardRes, unknown>(
    ['analyticsShowcase', 'data', { start_date, end_date }],
    async () => {
      const res = await getAnalyticsShowcaseData(params);
      return res;
    },
    {
      staleTime: 24 * 60 * 60 * 1000,
      retry: false,
    },
  );
};

export const useGetAnalyticsShowcaseGender = (
  params: IAnalyticsCommonGetParams,
) => {
  const { start_date, end_date } = params;
  return useQuery<IAnalyticsGenderRes, unknown>(
    ['analyticsShowcase', 'gender', { start_date, end_date }],
    async () => {
      const res = await getAnalyticsShowcaseGender(params);
      return res;
    },
    {
      staleTime: 24 * 60 * 60 * 1000,
      retry: false,
    },
  );
};

export const useGetAnalyticsShowcaseTopDiscounts = (
  params: IAnalyticsTopListGetParams,
) => {
  const { start_date, end_date, limit, order_type, order, page, search } =
    params;
  return useQuery<IAnalyticsTopListRes, unknown>(
    [
      'analyticsShowcase',
      'topDiscounts',
      {
        start_date,
        end_date,
        limit,
        order_type,
        order,
        page,
        search,
      },
    ],
    async () => {
      const res = await getAnalyticsShowcaseTopDiscounts(params);
      return {
        ...res,
        data: res.data?.map((item: IAnalyticsShowcaseTopDiscountCard) => {
          return {
            id: item.id,
            name: item.title,
            space_name: '',
            photo: '',
            value: item.count,
          };
        }),
      };
    },
    {
      staleTime: 24 * 60 * 60 * 1000,
      retry: false,
    },
  );
};

export const useGetAnalyticsShowcaseTopCategories = (
  params: IAnalyticsTopListGetParams,
) => {
  const { start_date, end_date, limit, order_type, order, page, search } =
    params;
  return useQuery<IAnalyticsTopListRes, unknown>(
    [
      'analyticsShowcase',
      'topCategories',
      {
        start_date,
        end_date,
        limit,
        order_type,
        order,
        page,
        search,
      },
    ],
    async () => {
      const res = await getAnalyticsShowcaseTopCategories(params);
      return {
        ...res,
        data: res.data?.map((item: IAnalyticsShowcaseTopDiscountCard) => {
          return {
            id: item.id,
            name: item.title,
            space_name: '',
            photo: '',
            value: item.count,
          };
        }),
      };
    },
    {
      staleTime: 24 * 60 * 60 * 1000,
      retry: false,
    },
  );
};
