import { ITeamGetParams, ITeamReq } from 'types';
import { http, methods } from './config';

export const getTeams = (params: ITeamGetParams) => {
  const config = {
    method: methods.get,
    url: `/team`,
    params,
  };
  return http(config);
};

export const getTeam = (teamId: number | string) => {
  const config = {
    method: methods.get,
    url: `/team/${teamId}`,
  };
  return http(config);
};

export const createTeam = (data: ITeamReq) => {
  const config = {
    method: methods.post,
    url: `/team`,
    data,
  };
  return http(config);
};

export const updateTeam = (data: ITeamReq) => {
  const config = {
    method: methods.put,
    url: `/team/${data.id}`,
    data,
  };
  return http(config);
};

export const deleteTeam = (teamId: number | string) => {
  const config = {
    method: methods.delete,
    url: `/team/${teamId}`,
  };
  return http(config);
};
