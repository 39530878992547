export const IPhoneIcon = () => {
  return (
    <svg
      width='31'
      height='30'
      viewBox='0 0 31 30'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M20.6562 1.25C19.215 1.33375 17.5604 2.13668 16.5767 3.15918C15.6879 4.08918 14.9867 5.47039 15.2729 6.80664C16.8392 6.84789 18.4113 6.02416 19.3525 4.98291C20.2313 4.00916 20.8975 2.65 20.6562 1.25ZM20.7417 6.8042C18.4804 6.8042 17.535 8.19336 15.9688 8.19336C14.3575 8.19336 12.8846 6.89209 10.9346 6.89209C8.28207 6.89334 4.25 9.35041 4.25 15.1392C4.25 20.4054 9.02207 26.25 11.7158 26.25C13.3521 26.2662 13.7487 25.2212 15.9688 25.21C18.1912 25.1937 18.6708 26.2637 20.3096 26.25C22.1546 26.2362 23.5954 24.2088 24.6479 22.6025C25.4029 21.4525 25.7133 20.8654 26.2983 19.5654C21.9571 18.4654 21.0933 11.4646 26.2983 10.0171C25.3158 8.34084 22.4479 6.8042 20.7417 6.8042Z'
        fill='#0080FF'
      />
    </svg>
  );
};
