import * as Pages from 'pages';
import { ROLES } from 'utils';
import { IRoutes } from '../types';

export const EventLog: IRoutes = {
  eventLog: {
    path: '/eventLog',
    title: 'eventLog',
    element: <Pages.EventLog />,
    roles: [ROLES.ADMINSTRATOR],
  },
};
