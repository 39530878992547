import { FC, useState, useMemo, MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, TableBody, TableCell } from '@mui/material';

import { ROUTES } from 'app';
import { EditIcon, DeleteIcon, CommentIconNews } from 'assets';
import { createLink } from 'utils';
import { Pagination, StyledSwitch } from 'UI';
import {
  useDeleteNewsItem,
  useGetNews,
  useSearchParams,
  useChangeStatus,
} from 'hooks';
import { INewsShort, NewsSortParams, TableRowsOrder } from 'types';
import { DeleteModal } from 'components';

import {
  StyledBox,
  StyledPaper,
  StyledTableContainer,
  StyledTable,
  StyledTableRow,
  StyledNewsInfo,
  StyledAvatar,
  StyledNewsName,
  StyledNewsInfoAtModal,
} from './styledComponents';
import { NewsTableHead } from './NewsTableHead';
import { StatusBadge } from './StatusBadge';

export const NewsTable: FC = () => {
  const navigate = useNavigate();
  const [order, setOrder] = useState<TableRowsOrder>('desc');
  const [orderBy, setOrderBy] = useState<NewsSortParams>('created_at');
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [selectedNewsItem, setSelectedNewsItem] = useState<
    INewsShort | undefined
  >(undefined);
  const { getParam, searchParams } = useSearchParams();

  const params = useMemo(() => {
    return {
      page: getParam('page'),
      search: getParam('search'),
      category_id: getParam('category_id') || 0,
      group_id: getParam('group_id') || 0,
      column: orderBy,
      sort_type: order,
    };
  }, [searchParams, order, orderBy]);

  const {
    data: { data: newsList, last_page: pageCount } = { data: [], last_page: 1 },
  } = useGetNews(params);
  const { mutateAsync: deleteNewsItem } = useDeleteNewsItem();
  const { mutateAsync: changeStatus } = useChangeStatus('news');

  const updateRow = (row: INewsShort) => {
    changeStatus({
      space_id: row.space.id!,
      model_name: 'news',
      model_id: row.id,
      state: !row.is_active,
    })
      .then(() => {})
      .catch(() => {});
  };

  const deleteButtonHandler = (row: INewsShort) => {
    setSelectedNewsItem(row);
    setOpenDeleteModal(true);
  };

  const deleteRow = async () => {
    if (!selectedNewsItem?.category || !selectedNewsItem.id) return;
    await deleteNewsItem({
      id: selectedNewsItem.id,
      category_id: selectedNewsItem.category.id,
    }).catch(() => {});
    setOpenDeleteModal(false);
  };

  const cancelDeleteRow = () => {
    setOpenDeleteModal(false);
    setSelectedNewsItem(undefined);
  };

  const handleRequestSort = (
    _event: MouseEvent<unknown>,
    property: NewsSortParams,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const openViewNewsPage = (
    id: string | number,
    categoryId: string | number,
  ) => {
    navigate(
      createLink(ROUTES.showNewsItem, id, 'id', {
        categoryId,
      }),
    );
  };

  const openCommentsPage = (
    id: string | number,
    categoryId: string | number,
  ) => {
    navigate(
      createLink(ROUTES.showNewsComments, id, 'id', {
        categoryId,
      }),
    );
  };

  const openEditNewsPage = (
    id: string | number,
    categoryId: string | number,
  ) => {
    navigate(
      createLink(ROUTES.editNewsItem, id, 'id', {
        categoryId,
      }),
    );
  };

  return (
    <StyledBox>
      <DeleteModal
        open={openDeleteModal}
        title='Удалить новость'
        description='Вы действительно хотите удалить новость?'
        onConfirm={deleteRow}
        onClose={cancelDeleteRow}
      >
        <StyledNewsInfoAtModal>
          <StyledAvatar>
            <img
              src={selectedNewsItem?.cover as string | undefined}
              alt={selectedNewsItem?.name}
            />
          </StyledAvatar>
          <StyledNewsName>{selectedNewsItem?.name}</StyledNewsName>
        </StyledNewsInfoAtModal>
      </DeleteModal>
      <StyledPaper>
        <StyledTableContainer>
          <StyledTable aria-labelledby='newsTable' size='medium' stickyHeader>
            <NewsTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {newsList.map(row => {
                return (
                  <StyledTableRow hover key={row.id}>
                    <TableCell
                      component='th'
                      id={row.id.toString()}
                      scope='row'
                    >
                      <StyledNewsInfo>
                        <StyledAvatar
                          onClick={() =>
                            openViewNewsPage(row.id, row.category.id)
                          }
                        >
                          <img
                            src={row.cover as string | undefined}
                            alt={row.name}
                          />
                        </StyledAvatar>
                        <StyledNewsName
                          onClick={() =>
                            openViewNewsPage(row.id, row.category.id)
                          }
                        >
                          {row.name}
                        </StyledNewsName>
                      </StyledNewsInfo>
                    </TableCell>
                    <TableCell>
                      <span>{row.created_at}</span>
                    </TableCell>
                    <TableCell>
                      <span>{row.category?.name}</span>
                    </TableCell>
                    <TableCell>
                      <span>{row.views_count}</span>
                    </TableCell>
                    <TableCell>
                      <Box
                        className='comments'
                        onClick={() =>
                          openCommentsPage(row.id, row.category.id)
                        }
                      >
                        <CommentIconNews />
                        <span>{row.comments_count || 0}</span>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <StatusBadge
                        is_active={row.is_active}
                        publishTime={row.published_at}
                      />
                    </TableCell>
                    <TableCell>
                      <div className='editDeleteIcons'>
                        <StyledSwitch
                          checked={row.is_active}
                          variant='customGray'
                          onChange={() => updateRow(row)}
                          style={{ opacity: 1 }}
                        />
                        <EditIcon
                          onClick={() =>
                            openEditNewsPage(row.id, row.category.id)
                          }
                        />
                        <DeleteIcon onClick={() => deleteButtonHandler(row)} />
                      </div>
                    </TableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </StyledTable>
        </StyledTableContainer>
        <Pagination count={pageCount} />
      </StyledPaper>
    </StyledBox>
  );
};
