import {
  useQuery,
  useMutation,
  useQueryClient,
  useInfiniteQuery,
} from 'react-query';
import {
  getBanners,
  getBanner,
  createBanner,
  updateBanner,
  deleteBanner,
  updateBannerPosition,
  searchProductsForBanners,
  searchMarathonSpacesForBanners,
  searchNewsForBanners,
} from 'api';
import {
  IBannerGetParams,
  IBannerRes,
  IBanner,
  ISearchSomethingForBannersGetParams,
  ISearchShowcaseProductsForBannersRes,
  ISearchMarathonSpacesForBannersRes,
  ISearchNewsForBannersRes,
} from 'types';

export const useGetBanners = (params: IBannerGetParams) => {
  const { page, search, column, sort_type } = params;
  return useQuery<IBannerRes, unknown>(
    ['banners', { page, search, column, sort_type }],
    async () => {
      const res = await getBanners(params);
      return res;
    },
    {
      staleTime: 10 * 60 * 1000,
      retry: false,
    },
  );
};

export const useGetBanner = (bannerId: string | number) => {
  if (!bannerId || bannerId === '0')
    return useQuery(['banners', 'bannerId', bannerId], () => {
      return Promise.resolve(null);
    });
  return useQuery<IBanner, unknown>(
    ['banners', 'bannerId', bannerId],
    async () => {
      const res = await getBanner(bannerId);
      return res;
    },
    {
      staleTime: 1 * 60 * 1000,
      retry: false,
    },
  );
};

export const useCreateBanner = () => {
  const queryClient = useQueryClient();
  return useMutation(createBanner, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['banners'] });
    },
    onError: () => {},
  });
};

export const useUpdateBanner = () => {
  const queryClient = useQueryClient();
  return useMutation(updateBanner, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['banners'] });
    },
    onError: () => {},
  });
};

export const useDeleteBanner = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteBanner, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['banners'] });
    },
    onError: () => {},
  });
};

export const useUpdateBannerPosition = () => {
  const queryClient = useQueryClient();
  return useMutation(updateBannerPosition, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['banners'] });
    },
    onError: () => {},
  });
};

export const useSearchProductsForBanners = (
  params: ISearchSomethingForBannersGetParams,
) => {
  const { search } = params;

  return useInfiniteQuery<ISearchShowcaseProductsForBannersRes, unknown>(
    ['banners', 'showcase', { search }],
    async ({ pageParam = 1 }) => {
      const res = await searchProductsForBanners({
        ...params,
        page: pageParam,
      });
      return res;
    },
    {
      getNextPageParam: res => {
        const { last_page } = res;
        if (res.data.length && res.current_page < last_page) {
          return res.current_page + 1;
        }
        return undefined;
      },
      staleTime: 1 * 60 * 1000,
      retry: false,
    },
  );
};

export const useSearchMarathonSpacesForBanners = (
  params: ISearchSomethingForBannersGetParams,
) => {
  const { search, type } = params;

  return useInfiniteQuery<ISearchMarathonSpacesForBannersRes, unknown>(
    ['banners', 'marathon_space', { search, type }],
    async ({ pageParam = 1 }) => {
      const res = await searchMarathonSpacesForBanners({
        ...params,
        page: pageParam,
      });
      return res;
    },
    {
      getNextPageParam: res => {
        const { last_page } = res;
        if (res.data.length && res.current_page < last_page) {
          return res.current_page + 1;
        }
        return undefined;
      },
      staleTime: 1 * 60 * 1000,
      retry: false,
    },
  );
};

export const useSearchNewsForBanners = (
  params: ISearchSomethingForBannersGetParams,
) => {
  const { search } = params;

  return useInfiniteQuery<ISearchNewsForBannersRes, unknown>(
    ['banners', 'news', { search }],
    async ({ pageParam = 1 }) => {
      const res = await searchNewsForBanners({
        ...params,
        page: pageParam,
      });
      return res;
    },
    {
      getNextPageParam: res => {
        const { last_page } = res;
        if (res.data.length && res.current_page < last_page) {
          return res.current_page + 1;
        }
        return undefined;
      },
      staleTime: 1 * 60 * 1000,
      retry: false,
    },
  );
};
