import { FC, useEffect, useMemo } from 'react';
import { useNavigate, useLocation, Outlet } from 'react-router-dom';

import { Layout, ShowcasesHeader, ShowcasesInnerNavbar } from 'components';
import { ROUTES } from 'app';

export const Showcases: FC = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const SHOWCASE_INNER_NAVBAR_MENU_ITEMS = useMemo(
    () => [
      {
        title: 'Все',
        path: ROUTES.showcases.offerAndDiscounts,
      },
      {
        title: 'Скидки',
        path: ROUTES.showcases.discounts,
      },
      {
        title: 'Предложения',
        path: ROUTES.showcases.offers,
      },
      {
        title: 'Категории',
        path: ROUTES.showcases.categories,
      },
    ],
    [],
  );

  useEffect(() => {
    if (pathname === ROUTES.showcases.get) {
      navigate(ROUTES.showcases.offerAndDiscounts);
    }
  });
  return (
    <Layout>
      <ShowcasesHeader />
      <ShowcasesInnerNavbar menuItems={SHOWCASE_INNER_NAVBAR_MENU_ITEMS} />
      <Outlet />
    </Layout>
  );
};
