import { IPaginatedReqParams, ISpaceReq, IChangeSpaceSettingsReq } from 'types';
import { http, methods } from './config';

export const getSpaces = (params: IPaginatedReqParams) => {
  const config = {
    method: methods.get,
    url: `/space`,
    params,
  };
  return http(config);
};

export const getSpace = (spaceId: number | string) => {
  const config = {
    method: methods.get,
    url: `/space/${spaceId}`,
  };
  return http(config);
};

export const createSpace = (data: ISpaceReq) => {
  const config = {
    method: methods.post,
    url: `/space`,
    data,
  };
  return http(config);
};

export const updateSpace = (data: ISpaceReq) => {
  const config = {
    method: methods.put,
    url: `/space/${data.id}`,
    data,
  };
  return http(config);
};

export const deleteSpace = (spaceId: string | number) => {
  const config = {
    method: methods.delete,
    url: `/space/${spaceId}`,
  };
  return http(config);
};

export const getSpacesList = () => {
  const config = {
    method: methods.get,
    url: `/list/space`,
  };
  return http(config);
};

export const getUsersFileSample = () => {
  const config = {
    method: methods.get,
    url: `/upload/user`,
  };
  return http(config);
};

export const uploadUsersFile = (data: FormData) => {
  const config = {
    method: methods.post,
    url: `/upload/user`,
    data,
  };
  return http(config);
};

export const changeSpaceSettings = (data: IChangeSpaceSettingsReq) => {
  const config = {
    method: methods.put,
    url: `/space/${data.space_id}/settings`,
    data,
  };
  return http(config);
};
