import { FC } from 'react';
import { TableRow, TableCell } from '@mui/material';
import { ICheckboxLog, IHeadCell } from 'types';
import { StyledTableHead } from './styledComponents';

const headCells: readonly IHeadCell<ICheckboxLog>[] = [
  {
    id: 'id',
    label: 'ID',
    sortable: false,
  },
  {
    id: 'user_id',
    label: 'Пользователь',
    sortable: false,
  },
  {
    id: 'type',
    label: 'Тип',
    sortable: false,
  },
  {
    id: 'created_at',
    label: 'Дата и время',
    sortable: false,
  },
];

interface ITableHeadProps {}

export const EventLogsTableHead: FC<ITableHeadProps> = () => {
  return (
    <StyledTableHead>
      <TableRow>
        {headCells.map(headCell => {
          return (
            <TableCell key={headCell.id} align='center' padding='normal'>
              {headCell.label}
            </TableCell>
          );
        })}
      </TableRow>
    </StyledTableHead>
  );
};
