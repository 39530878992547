import { FC, useState } from 'react';
import { Box } from '@mui/material';
import { CommentIconNews, DeleteIcon } from 'assets';
import {
  DeleteModal,
  NewsCommentsTable,
  NewsLayout,
  NewsPreviewComponent,
  NewsSubPagesHeader,
} from 'components';
import { useDeleteNewsAllComments } from 'hooks';
import { INews } from 'types';
import { Button } from 'UI';
import { COLORS, notify } from 'utils';

import {
  StyledCommentsTableHeader,
  StyledNewsCommentsLayout,
  StyledNewsCommentsTable,
} from './styledComponents';

interface IShowNewsCommentsBodyProps {
  data?: INews;
}

export const ShowNewsCommentsBody: FC<IShowNewsCommentsBodyProps> = ({
  data,
}) => {
  const [showDeleteAllCommentsModal, setShowDeleteAllCommentsModal] =
    useState<boolean>(false);
  const { mutateAsync: deleteAllComments } = useDeleteNewsAllComments();

  const openDeleteModal = () => {
    setShowDeleteAllCommentsModal(true);
  };

  const closeDeleteModal = () => {
    setShowDeleteAllCommentsModal(false);
  };

  const handleDeleteAllComments = () => {
    if (!data?.id) {
      notify('Не удалось удалить комментарии', 'error');
      return;
    }
    deleteAllComments(data.id)
      .then(() => {})
      .catch()
      .finally(() => {
        setShowDeleteAllCommentsModal(false);
      });
  };

  if (!data?.id) return null;

  return (
    <NewsLayout>
      <DeleteModal
        open={showDeleteAllCommentsModal}
        onConfirm={handleDeleteAllComments}
        onClose={closeDeleteModal}
        title='Удалить все комментарии?'
        description='Вы действительно хотите удалить все комментарии?'
      />
      <NewsSubPagesHeader title='Комментарии' withSearch />
      <StyledNewsCommentsLayout>
        <Box className='newsPreviewBox'>
          <NewsPreviewComponent data={data} />
        </Box>

        <StyledNewsCommentsTable>
          <StyledCommentsTableHeader>
            <Box className='data'>
              <Box className='title'>Список комментариев</Box>
              <Box className='commentsCount'>
                <CommentIconNews opacity={1} />
                <p>{data.comments_count}</p>
              </Box>
            </Box>
            <Button
              startIcon={<DeleteIcon color={COLORS.RED_2} />}
              onClick={openDeleteModal}
              variant='text'
              className='deleteAllCommentsButton'
            >
              Удалить все комментарии
            </Button>
          </StyledCommentsTableHeader>
          <NewsCommentsTable />
        </StyledNewsCommentsTable>
      </StyledNewsCommentsLayout>
    </NewsLayout>
  );
};
