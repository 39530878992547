import { styled } from '@mui/system';
import { Box, Chip } from '@mui/material';
import { COLORS } from 'utils';

export const StyledContainer = styled(Box)({
  backgroundColor: COLORS.WHITE,
  borderRadius: '1rem',
  width: '100%',
  minHeight: 'calc(100vh - 12rem)',
  maxHeight: 'calc(100vh - 12rem)',
  overflow: 'auto',
  padding: '1.25rem',
});

export const StyledHeader = styled(Box)({
  display: 'flex',
  gap: '0.75rem',
  alignItems: 'center',
  marginBottom: '1.25rem',
});

export const StyledTitle = styled(Box)({
  fontWeight: 700,
  fontSize: '1.125rem',
  lineHeight: '1.625rem',
  fontFamily: '"SF Pro Display", sans-serif',
  color: COLORS.BLACK,
});

export const StyledQuantity = styled(Box)({
  fontWeight: 500,
  fontSize: '1rem',
  lineHeight: '1.125rem',
  fontFamily: '"SF Pro Display", sans-serif',
  color: COLORS.BLACK,
});

export const StyledBody = styled(Box)({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  height: 'calc(100vh - 12rem - 6rem)',
  '& .MuiFormControl-root': {
    width: '100%',
  },
});

export const StyledTagsBox = styled(Box)({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '0.5rem',
  margin: '0.5rem 0',
  overflowY: 'auto',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
});

export const StyledTag = styled(Chip)({
  padding: '0.25rem 0.5rem',
  backgroundColor: COLORS.BLUE,
  color: COLORS.WHITE,
  '& .MuiChip-deleteIcon': {
    color: COLORS.WHITE,
    transition: 'all 0.3s ease',
    '&:hover': {
      transform: 'scale(1.5)',
    },
  },
});

export const StyledPaginationBox = styled(Box)({
  marginTop: 'auto',
});
