import { FC, useState, useEffect } from 'react';
import { TextField } from '@mui/material';
import { DeletePartnerModal } from 'components';
import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  ModalTitle,
  ImageUpload,
  DrawerFormBody,
  FormItem,
  Button,
  StyledFlexBox,
} from 'UI';
import { CloseIcon, DeleteIcon } from 'assets';
import { MEDIA_TYPES, COLORS } from 'utils';
import { IPartnerReq } from 'types';
import { useCreatePartner, useUpdatePartner, useGetPartner } from 'hooks';

const TRANSLATIONS = {
  add: {
    title: 'Добавление партнера',
  },
  edit: {
    title: 'Редактировать партнера',
  },
};

const DEFAULT_VALUES: IPartnerReq = {
  name: '',
  website: '',
  logo: '',
  contract: '',
  category: '',
  is_active: true,
  locations: [],
};

interface IAddEditPartnerDrawerProps {
  open: boolean;
  onClose: () => void;
  purpose?: 'add' | 'edit';
  id?: string | number;
}

export const AddEditPartnerDrawer: FC<IAddEditPartnerDrawerProps> = ({
  open,
  onClose,
  purpose = 'add',
  id = '',
}) => {
  const { data: partnerInfoData } = useGetPartner(id);
  const [partnerInfo, setPartnerInfo] = useState<IPartnerReq>(DEFAULT_VALUES);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const { mutateAsync: createPartner } = useCreatePartner();
  const { mutateAsync: updatePartner } = useUpdatePartner();

  useEffect(() => {
    if (purpose === 'edit') {
      setPartnerInfo({
        id: partnerInfoData?.id,
        name: partnerInfoData?.name || '',
        website: partnerInfoData?.website || '',
        logo: partnerInfoData?.logo || '',
        contract: partnerInfoData?.contract || '',
        category: partnerInfoData?.category || '',
        is_active: !!partnerInfoData?.is_active,
        locations: partnerInfoData?.locations || [],
      });
    } else {
      setPartnerInfo(DEFAULT_VALUES);
    }
  }, [purpose, partnerInfoData]);

  const handleFieldChange = <T extends keyof IPartnerReq>(
    fieldName: T,
    newValue: IPartnerReq[T],
  ) => {
    setPartnerInfo(prevPartnerInfo => ({
      ...prevPartnerInfo,
      [fieldName]: newValue,
    }));
  };

  const openDeleteModal = () => {
    setShowDeleteModal(true);
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const saveChanges = () => {
    if (purpose === 'add') {
      createPartner(partnerInfo)
        .then(() => {
          onClose();
        })
        .catch(() => {});
    } else {
      updatePartner(partnerInfo)
        .then(() => {
          onClose();
        })
        .catch(() => {});
    }
  };

  const ignoreChanges = () => {
    setPartnerInfo(DEFAULT_VALUES);
    onClose();
  };

  return (
    <Drawer open={open} onClose={ignoreChanges}>
      <DeletePartnerModal
        open={showDeleteModal}
        onClose={closeDeleteModal}
        id={id}
        name={partnerInfo.name}
        logo={partnerInfo.logo}
      />
      <DrawerHeader>
        <ModalTitle title={TRANSLATIONS[purpose].title} />
        <CloseIcon onClick={ignoreChanges} />
      </DrawerHeader>
      <DrawerBody>
        <ImageUpload
          image={partnerInfo.logo}
          setImage={(newPhoto: string) => handleFieldChange('logo', newPhoto)}
          inputId='partnerPhoto'
          // FIXME: FIX THIS LATER
          mediaType={MEDIA_TYPES.MARATHON}
        />
        <DrawerFormBody>
          <h2>О партнере</h2>
          <FormItem label='Название партнера'>
            <TextField
              variant='standard'
              color='primary'
              placeholder='Введите название'
              name='name'
              value={partnerInfo.name}
              onChange={e => handleFieldChange('name', e.target.value)}
            />
          </FormItem>
          <FormItem label='Сайт партнера'>
            <TextField
              variant='standard'
              color='primary'
              placeholder='Введите адрес сайта'
              name='link'
              value={partnerInfo.website}
              onChange={e => handleFieldChange('website', e.target.value)}
            />
          </FormItem>
          {/* FIXME: there will be select option there */}
          <FormItem label='Категория'>
            <TextField
              variant='standard'
              color='primary'
              placeholder='Выбрать категорию'
              name='category'
              value={partnerInfo.category}
              onChange={e => handleFieldChange('category', e.target.value)}
            />
          </FormItem>
          {/* TODO: add other fields next to these */}
        </DrawerFormBody>
        <StyledFlexBox className='buttons'>
          {purpose === 'edit' && (
            <Button
              onClick={openDeleteModal}
              variant='contained'
              size='large'
              color='customGray'
              startIcon={<DeleteIcon color={COLORS.RED} />}
              sx={{
                color: COLORS.RED,
              }}
            >
              Удалить
            </Button>
          )}
          <Button
            variant='contained'
            size='large'
            color='primary'
            onClick={saveChanges}
            sx={{
              marginLeft: 'auto',
              maxWidth: '50%',
            }}
          >
            Добавить
          </Button>
        </StyledFlexBox>
      </DrawerBody>
    </Drawer>
  );
};
