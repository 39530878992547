import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { COLORS } from 'utils';

export const StyledHeader = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  gap: '1rem',
  '& img': {
    width: '5rem',
    height: '5rem',
    borderRadius: '50%',
    overflow: 'hidden',
    backgroundColor: COLORS.WHITE,
    objectFit: 'cover',
  },
  '& .name': {
    fontSize: '1.25rem',
    lineHeight: '1.625rem',
    fontWeight: 600,
    fontFamily: '"SF Pro Display", sans-serif',
    textAlign: 'center',
  },
  '& .role': {
    fontSize: '1rem',
    lineHeight: '1.5rem',
    fontWeight: 500,
    fontFamily: '"SF Pro Display", sans-serif',
    textAlign: 'center',
    opacity: 0.5,
  },
  '& .coinsBox': {
    display: 'flex',
    alignItems: 'center',
    gap: '0.375rem',
  },
  '& .coins': {
    padding: '0.25rem 0.625rem',
    borderRadius: '6.25rem',
    backgroundColor: 'rgba(0, 128, 255, 0.05)',
    display: 'flex',
    alignItems: 'center',
    gap: '0.375rem',
  },
  '& .value': {
    fontSize: '0.875rem',
    fontFamily: '"SF Pro Display", sans-serif',
    lineHeight: '1.25rem',
    fontWeight: 700,
  },
});

export const StyledUserActivity = styled(Box)({
  backgroundColor: COLORS.WHITE,
  padding: '1rem 1.25rem',
  borderRadius: '0.5rem',
  display: 'flex',
  marginTop: '1.25rem',
  '& .levelBox': {
    paddingRight: '1.25rem',
    fontFamily: '"SF Pro Display", sans-serif',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    '& svg': {
      margin: '0 auto 0.25rem',
    },
    '& .badge': {
      backgroundColor: COLORS.RED_2,
      color: COLORS.WHITE,
      fontSize: '0.875rem',
      lineHeight: '1.125rem',
      fontWeight: 600,
      width: 'max-content',
      padding: '0.125rem 0.5rem',
      borderRadius: '2rem',
    },
    '& .level': {
      fontSize: '0.625rem',
      lineHeight: '0.625rem',
      width: 'max-content',
      margin: '0.25rem auto',
    },
  },
  '& .activityBox': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    paddingLeft: '1.25rem',
    borderLeft: `1px solid ${COLORS.GRAY}`,
    gap: '1rem',
    '& .activity': {
      display: 'flex',
      flexDirection: 'column',
      gap: '0.25rem',
      '& .title': {
        display: 'flex',
        alignItems: 'center',
        gap: '0.25rem',
        '& span': {
          fontSize: '0.875rem',
          lineHeight: '1.375rem',
          opacity: 0.5,
          fontFamily: '"SF Pro Display", sans-serif',
        },
      },
      '& .value': {
        fontSize: '2rem',
        lineHeight: '2.5rem',
        fontWeight: 500,
        fontFamily: '"SF Pro Display", sans-serif',
      },
    },
  },
});

export const StyledBody = styled(Box)({
  padding: '1.25rem 0',
  display: 'flex',
  flexWrap: 'wrap',
  gap: '1.25rem',
  '& .column': {
    width: 'calc((100% - 1.25rem) / 2)',
    display: 'flex',
    flexDirection: 'column',
    gap: '1.25rem',
  },
  '& .editButton': {
    position: 'absolute',
    bottom: '1.875rem',
    right: '1.875rem',
    width: '40%',
  },
});

export const StyledLabel = styled(Box)({
  fontSize: '0.75rem',
  fontFamily: '"SF Pro Display", sans-serif',
  lineHeight: '1.25rem',
  opacity: 0.5,
});

export const StyledMiniCard = styled(Box)({
  padding: '0.75rem 1rem',
  backgroundColor: COLORS.WHITE,
  borderRadius: '0.5rem',
  width: '100%',
  fontSize: '1rem',
  fontFamily: '"SF Pro Display", sans-serif',
  lineHeight: '1.625rem',
  fontWeight: 500,
  display: 'flex',
  alignItems: 'center',
  gap: '0.5rem',
  minHeight: '3.125rem',
});
