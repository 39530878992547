import { FC, ChangeEvent } from 'react';
import InputMask from 'react-input-mask';
import { TextField, Box } from '@mui/material';

interface ICodeInput {
  value: string;
  onChange: (_e: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  isError?: boolean;
  errorMessage?: string;
}

export const CodeInput: FC<ICodeInput> = ({
  value,
  onChange,
  disabled,
  isError,
  errorMessage,
}) => {
  const renderTextField = () => (
    <TextField
      label='Код СМС'
      variant='outlined'
      name='code'
      fullWidth
      error={isError}
      helperText={errorMessage}
      disabled={disabled}
      inputProps={{
        style: { textAlign: 'center', letterSpacing: '1rem' },
      }}
    />
  );
  return (
    <Box sx={{ marginBottom: '1.25rem' }}>
      <InputMask
        mask='9 9 9 9 9 9'
        maskPlaceholder='Введите код с СМС'
        disabled={disabled}
        value={value}
        onChange={onChange}
      >
        {/* @ts-ignore */}
        {() => renderTextField()}
      </InputMask>
    </Box>
  );
};
