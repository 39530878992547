export const PhoneIcon = () => {
  return (
    <svg
      width='31'
      height='30'
      viewBox='0 0 31 30'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M24 25.625V4.375C24 2.65 22.6 1.25 20.875 1.25H9.625C7.9 1.25 6.5 2.65 6.5 4.375V25.625C6.5 27.35 7.9 28.75 9.625 28.75H20.875C22.6 28.75 24 27.35 24 25.625ZM21.5 26.25H9V3.75H11.0312L11.9362 5.55875C12.1475 5.9825 12.58 6.25 13.0538 6.25H17.3938C17.8675 6.25 18.3 5.9825 18.5113 5.55875L19.4163 3.75H21.5V26.25Z'
        fill='#0080FF'
      />
    </svg>
  );
};
