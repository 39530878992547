import { ISponsorReq, ISponsorGetParams } from 'types';
import { http, methods } from './config';

export const getSponsors = (params: ISponsorGetParams) => {
  const config = {
    method: methods.get,
    url: `/marathon/sponsor`,
    params,
  };
  return http(config);
};

export const getSponsor = (sponsorId: number | string) => {
  const config = {
    method: methods.get,
    url: `/marathon/sponsor/${sponsorId}`,
  };
  return http(config);
};

export const createSponsor = (data: ISponsorReq) => {
  const config = {
    method: methods.post,
    url: `/marathon/sponsor`,
    data,
  };
  return http(config);
};

export const updateSponsor = (data: ISponsorReq) => {
  const config = {
    method: methods.put,
    url: `/marathon/sponsor/${data.id}`,
    data,
  };
  return http(config);
};

export const deleteSponsor = (sponsorId: number | string) => {
  const config = {
    method: methods.delete,
    url: `/marathon/sponsor/${sponsorId}`,
  };
  return http(config);
};
