import * as Pages from 'pages';
import { ROLES } from 'utils';
import { IRoutes } from '../types';

export const Applications: IRoutes = {
  applications: {
    path: '/applications',
    title: 'applications',
    element: <Pages.Applications />,
    roles: [ROLES.ADMINSTRATOR],
  },
};
