import { FC } from 'react';
import { useDrawer } from 'hooks';
import {
  AddEditBannerDrawer,
  BannersHeader,
  Layout,
  BannersTable,
} from 'components';

export const Banners: FC = () => {
  const { showAddEditDrawer, hideAddEditDrawer, editDrawerId } = useDrawer();

  return (
    <Layout withoutPB>
      <AddEditBannerDrawer
        open={showAddEditDrawer}
        onClose={hideAddEditDrawer}
        purpose={editDrawerId === '0' ? 'add' : 'edit'}
        id={editDrawerId}
      />
      <BannersHeader />
      <BannersTable />
    </Layout>
  );
};
