import { FC, useContext, useMemo, useState } from 'react';
import { TableBody, TableCell } from '@mui/material';

import { UserContext, UserContextType } from 'app';
import { DeleteModal } from 'components';
import { Pagination, StyledSwitch } from 'UI';
import { EditIcon, DeleteIcon } from 'assets';
import {
  useGetShopCategories,
  useDeleteShopCategory,
  useSearchParams,
  useChangeStatus,
} from 'hooks';
import { IShopCategory } from 'types';

import {
  StyledBox,
  StyledPaper,
  StyledTableContainer,
  StyledTable,
  StyledTableRow,
} from './styledComponents';
import { ShopCategoriesTableHead } from './ShopCategoriesTableHead';

export const ShopCategoriesTable: FC = () => {
  const { space_id } = useContext(UserContext) as UserContextType;
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [selectedCategory, setSelectedCategory] = useState<
    IShopCategory | undefined
  >(undefined);

  const { getParam, setParam, searchParams } = useSearchParams();
  const { mutateAsync: deleteShopCategory } = useDeleteShopCategory();
  const { mutateAsync: changeStatus } = useChangeStatus('product_category');

  const params = useMemo(() => {
    return {
      page: getParam('page'),
      search: getParam('search'),
    };
  }, [searchParams]);

  const {
    data: { data: shopCategories, last_page: pageCount } = {
      data: [],
      last_page: 1,
    },
  } = useGetShopCategories(params);

  const updateRow = (row: IShopCategory) => {
    if (!row.id) return;
    changeStatus({
      model_name: 'product_category',
      model_id: row.id,
      state: !row.is_active,
      space_id: space_id!,
    })
      .then(() => {})
      .catch(() => {});
  };

  const openEditDrawer = (id: string | number) => {
    setParam('edit', id.toString());
  };

  const deleteButtonHandler = (row: IShopCategory) => {
    setSelectedCategory(row);
    setOpenDeleteModal(true);
  };

  const deleteRow = () => {
    deleteShopCategory(selectedCategory!.id)
      .then(() => {})
      .catch(() => {})
      .finally(() => {
        setOpenDeleteModal(false);
      });
  };

  const cancelDeleteRow = () => {
    setOpenDeleteModal(false);
    setSelectedCategory(undefined);
  };

  return (
    <>
      <DeleteModal
        open={openDeleteModal}
        title='Удалить категорию'
        description='Вы действительно хотите удалить категорию?'
        onConfirm={deleteRow}
        onClose={cancelDeleteRow}
      />
      <StyledBox>
        <StyledPaper>
          <StyledTableContainer>
            <StyledTable
              aria-labelledby='shopCategoriesTable'
              size='medium'
              stickyHeader
            >
              <ShopCategoriesTableHead />
              <TableBody>
                {shopCategories.map(row => {
                  return (
                    <StyledTableRow hover key={row.id}>
                      <TableCell
                        component='th'
                        id={row.id.toString()}
                        scope='row'
                        align='center'
                      >
                        {row.id}
                      </TableCell>
                      <TableCell onClick={() => openEditDrawer(row.id)}>
                        <span>{row.name}</span>
                      </TableCell>
                      <TableCell>
                        <div className='editDeleteIcons'>
                          <StyledSwitch
                            checked={row.is_active}
                            variant='customGray'
                            onChange={() => updateRow(row)}
                            style={{ opacity: 1 }}
                            className='switch'
                          />
                          <EditIcon onClick={() => openEditDrawer(row.id)} />
                          <DeleteIcon
                            onClick={() => deleteButtonHandler(row)}
                          />
                        </div>
                      </TableCell>
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </StyledTable>
          </StyledTableContainer>
          <Pagination count={pageCount} />
        </StyledPaper>
      </StyledBox>
    </>
  );
};
