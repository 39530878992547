export const WinnerCupIcon = () => {
  return (
    <svg
      width='30'
      height='31'
      viewBox='0 0 30 31'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8.43164 5.5H21.6416C21.6416 5.5 20.5416 22.0713 15.0366 22.0713C12.3491 22.0713 10.7104 18.1163 9.73789 14.0713C8.72039 9.835 8.43164 5.5 8.43164 5.5Z'
        stroke='#0080FF'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M21.6414 5.50005C21.6414 5.50005 22.7926 4.2713 23.7501 4.25005C25.6251 4.20755 25.9714 5.50005 25.9714 5.50005C26.3389 6.26255 26.6326 8.24255 24.8714 10.0713C23.1089 11.9 21.1376 13.5 20.3351 14.0713M8.43136 5.50005C8.43136 5.50005 7.23136 4.25755 6.25011 4.25005C4.37511 4.23505 4.02886 5.50005 4.02886 5.50005C3.66136 6.26255 3.36761 8.24255 5.12886 10.0713C6.5535 11.528 8.09488 12.8658 9.73761 14.0713M10.6339 25.5C10.6339 23.2138 15.0364 22.0713 15.0364 22.0713C15.0364 22.0713 19.4401 23.2138 19.4401 25.5H10.6339Z'
        stroke='#0080FF'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
