import { FC, useState, MouseEvent, useMemo, useEffect } from 'react';
import { Box } from '@mui/material';
import {
  Drawer,
  DrawerHeader,
  ModalTitle,
  SearchInput,
  Button,
} from 'UI';
import { LeftArrowIcon } from 'assets';
import {
  IMarathonParticipantTeam,
  IMarathonParticipantUser,
  IGivePrizesCommonReq,
  IWinner,
} from 'types';
import {
  useGetMarathon,
  useGetMarathonParticipants,
  // useGivePrizes,
} from 'hooks';
import { notify } from 'utils';

import {
  StyledContainer,
  StyledHeader,
  StyledUsersList,
} from './styledComponents';
import {
  SectionUsers,
  SectionTeams,
  WinnerUsers,
  WinnerTeams,
  PlacePopover,
} from './components';

interface IViewChallengeUsersDrawerProps {
  open: boolean;
  onClose: () => void;
  id: string;
}

type WinnersListType = IGivePrizesCommonReq['winners'];

export const ViewChallengeUsersDrawer: FC<IViewChallengeUsersDrawerProps> = ({
  open,
  onClose,
  id,
}) => {
  // if (!id) return null;
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchText, setSearchText] = useState<string>('');
  const [showPopover, setShowPopover] = useState<boolean>(false);
  const [selectedParticipantId, setSelectedParticipantId] = useState<
    string | number | null
  >(null);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  // Array which will be sent to backend
  const [winnersList, setWinnersList] = useState<WinnersListType>([]);
  // Array of places which are available to give
  const [availablePlaces, setAvailablePlaces] = useState<number[]>([]);
  // Array which will be shown in the drawer temporarily
  const [winnerParticipants, setWinnerParticipants] = useState<
    Array<IMarathonParticipantUser | IMarathonParticipantTeam>
  >([]);
  const [allParticipants, setAllParticipants] = useState<
    Array<IMarathonParticipantUser | IMarathonParticipantTeam>
  >([]);

  const { data: marathonInfo } = useGetMarathon(id);

  const isIndividualChallenge = useMemo(
    () => marathonInfo?.type === 2,
    [marathonInfo?.type, id],
  );
  const params = useMemo(() => {
    return {
      marathon_id: id,
      page: currentPage,
      search: searchText,
      individual: isIndividualChallenge,
    };
  }, [currentPage, searchText, isIndividualChallenge, id]);

  useEffect(() => {
    if (marathonInfo && marathonInfo.winners?.length === 0) {
      const places = marathonInfo.trophies.map(trophy => {
        return trophy.place_in_the_ladder;
      });

      setAvailablePlaces(places);
    }
  }, [marathonInfo]);

  useEffect(() => {
    return () => {
      setWinnersList([]);
      setAvailablePlaces([]);
      setWinnerParticipants([]);
      setAllParticipants([]);
      setCurrentPage(1);
    };
  }, []);

  const {
    data: { data: participants, last_page } = {
      data: [],
      last_page: 1,
    },
  } = useGetMarathonParticipants(params);

  // const { mutateAsync: givePrizes } = useGivePrizes(isIndividualChallenge);

  useEffect(() => {
    setAllParticipants([...allParticipants, ...participants]);
  }, [participants]);

  const openWinPopover = (
    event: MouseEvent<HTMLButtonElement>,
    userId: string | number,
  ) => {
    setSelectedParticipantId(userId);
    setAnchorEl(event.currentTarget);
    setShowPopover(true);
  };

  const hideWinPopover = () => {
    setSelectedParticipantId(null);
    setShowPopover(false);
    setAnchorEl(null);
  };

  const addToWinners = (place: number) => {
    if (!selectedParticipantId) {
      hideWinPopover();
      return;
    }
    const isAlreadyWinner = winnersList.some(
      winner => winner.id === selectedParticipantId,
    );
    if (isAlreadyWinner) {
      notify('Участник уже победил', 'error');
      hideWinPopover();
      return;
    }
    const winner: IWinner = {
      id: selectedParticipantId,
      winning_place: place,
    };

    // @ts-ignore
    const winnerParticipant = participants.find((p: any) => {
      if (isIndividualChallenge) {
        return p.user_id === selectedParticipantId;
      }
      return p.id === selectedParticipantId;
    });
    if (winnerParticipant) {
      setWinnerParticipants([
        ...winnerParticipants,
        { ...winnerParticipant, winning_place: place },
      ]);
    }
    setWinnersList([...winnersList, winner]);
    setAvailablePlaces(prev => prev.filter(p => p !== place));
    hideWinPopover();
  };

  // const giveAllPrizes = () => {
  //   if (availablePlaces.length > 0) {
  //     notify('Не все призы разыграны', 'error');
  //     return;
  //   }
  //   givePrizes({
  //     marathon_id: id,
  //     winners: winnersList,
  //   })
  //     .then(() => {
  //       onClose();
  //     })
  //     .catch(() => {});
  // };

  const removeFromWinners = (participantId: string | number) => {
    if (!participantId) return;
    const removedWinner = winnersList.find(
      winner => winner.id === participantId,
    );
    const updatedWinnersList = winnersList.filter(
      winner => winner.id !== participantId,
    );
    const updatedWinnerParticipants = winnerParticipants.filter(
      (winner: any) => {
        if (isIndividualChallenge) {
          return winner.user_id !== participantId;
        }
        return winner.team_id !== participantId;
      },
    );
    setWinnersList(updatedWinnersList);
    setWinnerParticipants(updatedWinnerParticipants);
    setAvailablePlaces(prev => [...prev, removedWinner?.winning_place || 0]);
  };

  const handleSearch = (value: string) => {
    if (value.length < 3 && value) return;
    setSearchText(value);
  };

  const loadNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  return (
    <Drawer open={open} onClose={onClose} type='gray' width='60vw'>
      <DrawerHeader>
        <ModalTitle title='Список участников' />
        <LeftArrowIcon onClick={onClose} />
      </DrawerHeader>
      <StyledContainer>
        <PlacePopover
          showPopover={showPopover}
          anchorEl={anchorEl}
          availablePlaces={availablePlaces}
          hideWinPopover={hideWinPopover}
          addToWinners={addToWinners}
        />
        <SearchInput handleSearch={handleSearch} size='large' />

        <Box className='lists'>
          {marathonInfo && marathonInfo.winners?.length > 0 && (
            <>
              <StyledHeader>Победители</StyledHeader>
              {isIndividualChallenge ? (
                <WinnerUsers
                  winners={marathonInfo.winners as IMarathonParticipantUser[]}
                />
              ) : (
                <WinnerTeams
                  winners={marathonInfo.winners as IMarathonParticipantTeam[]}
                />
              )}
            </>
          )}
          {winnersList?.length > 0 && (
            <>
              <StyledHeader>Победители</StyledHeader>
              {isIndividualChallenge ? (
                <WinnerUsers
                  winners={winnerParticipants as IMarathonParticipantUser[]}
                  removeFromWinners={removeFromWinners}
                />
              ) : (
                <WinnerTeams
                  winners={winnerParticipants as IMarathonParticipantTeam[]}
                  removeFromWinners={removeFromWinners}
                />
              )}
            </>
          )}
          <StyledHeader>Участники</StyledHeader>

          <StyledUsersList>
            {isIndividualChallenge ? (
              <SectionUsers
                openWinPopover={openWinPopover}
                participants={allParticipants as IMarathonParticipantUser[]}
              />
            ) : (
              <SectionTeams
                openWinPopover={openWinPopover}
                participants={allParticipants as IMarathonParticipantTeam[]}
              />
            )}
            {last_page > currentPage && (
              <Button
                className='participantsPaginationButton'
                color='customLightBlue'
                onClick={loadNextPage}
              >
                Загрузить ещё
              </Button>
            )}
          </StyledUsersList>
        </Box>
        {/* {marathonInfo?.winners.length === 0 && (
          <Box className='bottomButton'>
            <Button size='large' onClick={giveAllPrizes}>
              Отдать все призы
            </Button>
          </Box>
        )} */}
      </StyledContainer>
    </Drawer>
  );
};
