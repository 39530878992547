import { FC, useMemo } from 'react';
import { useDrawer, useSearchParams } from 'hooks';
import {
  AddEditAttributeDrawer,
  AttributeValuesTable,
  AttributesTable,
} from 'components';

export const Attributes: FC = () => {
  const { showAddEditDrawer, hideAddEditDrawer, editDrawerId } = useDrawer();
  const { getParam, searchParams } = useSearchParams();

  const showValuesTable = useMemo(() => {
    return Boolean(+getParam('attribute_id'));
  }, [searchParams]);

  return (
    <>
      <AddEditAttributeDrawer
        open={showAddEditDrawer}
        onClose={hideAddEditDrawer}
        id={editDrawerId}
        purpose={editDrawerId === '0' ? 'add' : 'edit'}
      />
      {showValuesTable ? <AttributeValuesTable /> : <AttributesTable />}
    </>
  );
};
