import { FC } from 'react';
import { COLORS } from 'utils';

interface IPercentIcon {
  color?: string;
  className?: string;
  style?: React.CSSProperties;
  opacity?: string;
  onClick?: () => void;
}

export const PercentIcon2: FC<IPercentIcon> = ({
  className,
  color = COLORS.BLACK,
  onClick,
  opacity = '0.5',
  ...rest
}) => {
  return (
    <svg
      width='13'
      height='13'
      viewBox='0 0 13 13'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      onClick={onClick}
      {...rest}
    >
      <path
        opacity={opacity}
        d='M2.59375 6.375C1.10156 6.375 0.1875 5.27344 0.1875 3.5V3.49219C0.1875 1.73438 1.10156 0.632812 2.59375 0.632812C4.08594 0.632812 4.99219 1.73438 4.99219 3.49219V3.5C4.99219 5.27344 4.08594 6.375 2.59375 6.375ZM3.33594 12H1.89062L9.08594 0.726562H10.5234L3.33594 12ZM2.59375 5.3125C3.30469 5.3125 3.71094 4.64844 3.71094 3.50781V3.5C3.71094 2.35156 3.30469 1.69531 2.59375 1.69531C1.88281 1.69531 1.47656 2.35156 1.47656 3.5V3.50781C1.47656 4.64844 1.88281 5.3125 2.59375 5.3125ZM9.8125 12.0938C8.32031 12.0938 7.41406 10.9922 7.41406 9.22656V9.21875C7.41406 7.45312 8.32031 6.35156 9.8125 6.35156C11.3047 6.35156 12.2109 7.45312 12.2109 9.21875V9.22656C12.2109 10.9922 11.3047 12.0938 9.8125 12.0938ZM9.8125 11.0312C10.5234 11.0312 10.9297 10.3672 10.9297 9.22656V9.21875C10.9297 8.07031 10.5234 7.42188 9.8125 7.42188C9.10156 7.42188 8.69531 8.07031 8.69531 9.21875V9.22656C8.69531 10.3672 9.10156 11.0312 9.8125 11.0312Z'
        fill={color}
      />
    </svg>
  );
};
