import { styled } from '@mui/system';
import { Box } from '@mui/material';

export const StyledRightSideContainer = styled(Box)({
  display: 'flex',
  gap: '1.5rem',
  alignItems: 'center',
});

export const StyledPopoverContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  '& button': {
    padding: '0.75rem 1rem',
    minWidth: '15rem',
    justifyContent: 'left',
  },
});
