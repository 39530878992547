import { FC, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { COLORS } from 'utils';
import { ILevel } from 'types';
import { DeleteIcon, EditIcon } from 'assets';
import { StyledSwitch } from 'UI';
import { DeleteModal } from 'components';
import { CardBody, CardHeader, CardContainer } from './styledComponents';

interface ILevelCardProps {
  level: ILevel;
  position: number;
}

export const LevelCard: FC<ILevelCardProps> = ({ level, position }) => {
  const { id, name, cover, balls, is_active } = level;
  const location = useLocation();
  const navigate = useNavigate();

  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const changeStatus = () => {};

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const openDeleteModal = () => {
    setShowDeleteModal(true);
  };

  const handleDeleteLevel = () => {};

  const openEditDrawer = () => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('edit', id.toString());
    navigate(`?${searchParams.toString()}`);
  };

  return (
    <CardContainer>
      <DeleteModal
        open={showDeleteModal}
        onClose={closeDeleteModal}
        onConfirm={handleDeleteLevel}
        title='Удалить уровень?'
        description='Вы действительно хотите удалить уровень'
      />
      <CardHeader>
        <Box className='metaInfoBox'>
          <Box className='imageBox'>
            <img src={cover} alt={name} />
          </Box>
          <Box className='infoBox'>
            <Box className='minorInfo'>Уровень {position}</Box>
            <Box className='majorInfo'>{name}</Box>
          </Box>
        </Box>
        <Box className='infoBox ballInfoBox'>
          <Box className='majorInfo'>от {balls}</Box>
          <Box className='minorInfo'>баллов</Box>
        </Box>
      </CardHeader>
      <CardBody className='body'>
        <Box className='buttons'>
          <EditIcon onClick={openEditDrawer} color={COLORS.BLACK} />
          <DeleteIcon onClick={openDeleteModal} color={COLORS.BLACK} />
        </Box>
        <StyledSwitch
          checked={is_active}
          onChange={changeStatus}
          className='switch'
          variant='customGray'
        />
      </CardBody>
    </CardContainer>
  );
};
