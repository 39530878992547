import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export const useSearchParams = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useState<URLSearchParams>(
    new URLSearchParams(location.search),
  );

  useEffect(() => {
    setSearchParams(new URLSearchParams(location.search));
  }, [location.search]);

  const setParam = (key: string, value: string | number) => {
    searchParams.set(key, value.toString());
    navigate(`?${searchParams.toString()}`);
  };

  const setParams = (
    paramsToSet: Record<string, string | number>,
    paramsToRemove?: string[],
  ) => {
    Object.entries(paramsToSet).forEach(([key, value]) => {
      searchParams.set(key, value.toString());
    });
    paramsToRemove?.forEach(key => {
      searchParams.delete(key);
    });
    navigate(`?${searchParams.toString()}`);
  };

  const removeParam = (key: string) => {
    searchParams.delete(key);
    navigate(`?${searchParams.toString()}`);
  };

  const getParam = (key: string): string => {
    const value = searchParams.get(key);
    if (key === 'page' && value === null) return '1';
    if (key === 'space_id' && value === null) return '1';
    return value || '';
  };

  const hasParam = (key: string) => {
    return searchParams.has(key);
  };

  return {
    searchParams,
    setParam,
    setParams,
    removeParam,
    getParam,
    hasParam,
  };
};
