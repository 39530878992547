import { FC } from 'react';
import { Button, PageHeader } from 'UI';
import { PlusIcon } from 'assets';

interface ILevelsHeaderProps {
  openAddLevelModal: () => void;
}

export const LevelsHeader: FC<ILevelsHeaderProps> = ({ openAddLevelModal }) => {
  return (
    <PageHeader title='Уровни'>
      <Button
        variant='contained'
        startIcon={<PlusIcon />}
        onClick={openAddLevelModal}
        size='large'
      >
        Создать
      </Button>
    </PageHeader>
  );
};
