import { FC } from 'react';
import { useDrawer } from 'hooks';
import {
  SpacesHeader,
  SpacesBody,
  AddEditSpaceDrawer,
  Layout,
  ViewSpaceDrawer,
} from 'components';

export const Spaces: FC = () => {
  const {
    openAddDrawer,
    viewDrawerId,
    showViewDrawer,
    hideViewDrawer,
    editDrawerId,
    purpose,
    hideAddEditDrawer,
    showAddEditDrawer,
  } = useDrawer();

  return (
    <Layout withoutPB>
      <AddEditSpaceDrawer
        open={showAddEditDrawer}
        onClose={hideAddEditDrawer}
        purpose={purpose}
        id={editDrawerId}
      />
      <ViewSpaceDrawer
        open={showViewDrawer}
        id={viewDrawerId}
        onClose={hideViewDrawer}
      />
      <SpacesHeader openAddSpaceModal={openAddDrawer} />
      <SpacesBody />
    </Layout>
  );
};
