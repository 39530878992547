import { FC, useState, useEffect, useContext } from 'react';
import { TextField, Box, FormControlLabel, Radio } from '@mui/material';
import { UserContext, UserContextType } from 'app';
import {
  Drawer,
  ModalTitle,
  ImageInput,
  Button,
  DrawerHeader,
  FormItem,
  DrawerFormBody,
  StyledFlexBox,
  StyledRadioGroup,
  DateInput,
  StyledSwitch,
  HtmlEditor,
} from 'UI';
import {
  useCreateMarathon,
  useGetMarathon,
  useGetMarathonAllTeamParticipants,
  useUpdateMarathon,
  useUpdateMarathonTeamsList,
} from 'hooks';
import { IMarathonReq, IMarathonTeamsListUpdateReq } from 'types';
import { CloseIcon } from 'assets';
import { AddTeamToChallenge } from 'components';
import { MEDIA_TYPES, notify } from 'utils';
import { StyledSwitchFormControl } from './styledComponents';

const TRANSLATIONS = {
  add: {
    title: 'Новый вызов',
  },
  edit: {
    title: 'Редактирование вызова',
  },
};

const DEFAULT_VALUES: IMarathonReq = {
  name: '',
  // description: '',
  is_active: true,
  is_shareable: false,
  show_on_main: false,
  show_in_slider: false,
  body: '',
  cover: '',
  type: 2,
  min_age: undefined,
  max_age: undefined,
  start_date: null,
  end_date: null,
};

interface ICreateChallengeMainInfoProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (_id: number) => void;
  purpose: 'add' | 'edit';
  id: number | string;
}

export const CreateChallengeMainInfo: FC<ICreateChallengeMainInfoProps> = ({
  open,
  onClose,
  onSubmit,
  purpose = 'add',
  id = '',
}) => {
  const { space_id } = useContext(UserContext) as UserContextType;
  const { data: marathonInfoData } = useGetMarathon(id);
  const [challengeInfo, setChallengeInfo] =
    useState<IMarathonReq>(DEFAULT_VALUES);

  const { mutateAsync: createChallenge } = useCreateMarathon();
  const { mutateAsync: updateChallenge } = useUpdateMarathon();
  const { mutateAsync: updateTeams } = useUpdateMarathonTeamsList();
  const [teamsInfo, setTeamsInfo] = useState<IMarathonTeamsListUpdateReq>({
    marathon_id: id,
    invited_teams: [],
    removed_teams: [],
  });

  const { data: allTeamsOfMarathon } = useGetMarathonAllTeamParticipants(id);

  useEffect(() => {
    if (purpose === 'edit') {
      setChallengeInfo({
        id: marathonInfoData?.id,
        name: marathonInfoData?.name || '',
        cover: marathonInfoData?.cover || '',
        body: marathonInfoData?.body || '',
        // description: marathonInfoData?.description || '',
        is_active: !!marathonInfoData?.is_active,
        is_shareable: !!marathonInfoData?.is_shareable,
        show_on_main: !!marathonInfoData?.show_on_main,
        show_in_slider: !!marathonInfoData?.show_in_slider,
        type: marathonInfoData?.type || 2,
        min_age: marathonInfoData?.min_age,
        max_age: marathonInfoData?.max_age,
        start_date: marathonInfoData?.start_date || '',
        end_date: marathonInfoData?.end_date || '',
      });
    } else {
      setChallengeInfo(DEFAULT_VALUES);
    }
  }, [purpose, marathonInfoData]);

  const handleFieldChange = <T extends keyof IMarathonReq>(
    fieldName: T,
    newValue: IMarathonReq[T],
  ) => {
    if (fieldName === 'start_date') {
      setChallengeInfo(prevChallengeInfo => ({
        ...prevChallengeInfo,
        [fieldName]: `${newValue} 12:00:00`,
      }));
      return;
    }
    if (fieldName === 'end_date') {
      setChallengeInfo(prevChallengeInfo => ({
        ...prevChallengeInfo,
        [fieldName]: `${newValue} 23:59:59`,
      }));
      return;
    }
    if (fieldName === 'show_in_slider' && !challengeInfo.show_on_main) {
      notify('Для отображения в слайдере, вызов должен быть активен', 'error');
      return;
    }
    if (fieldName === 'show_on_main' && !newValue) {
      setChallengeInfo(prevChallengeInfo => ({
        ...prevChallengeInfo,
        [fieldName]: newValue,
        show_in_slider: false,
      }));
      return;
    }

    setChallengeInfo(prevChallengeInfo => ({
      ...prevChallengeInfo,
      [fieldName]: newValue,
    }));
  };

  const addTeamToChallenge = (teamId: number) => {
    setTeamsInfo(prevTeamsInfo => ({
      ...prevTeamsInfo,
      invited_teams: [...prevTeamsInfo.invited_teams, teamId],
    }));
  };

  const removeTeamFromChallenge = (teamId: number) => {
    setTeamsInfo(prevTeamsInfo => ({
      ...prevTeamsInfo,
      removed_teams: [...prevTeamsInfo.removed_teams, teamId],
    }));
  };

  const saveChanges = () => {
    const submittedInfo = {
      ...challengeInfo,
      start_date:
        challengeInfo.start_date?.replace('12:00:00', '00:00:00') || '',
    };
    if (purpose === 'edit') {
      updateChallenge(submittedInfo)
        .then(() => {
          updateTeams({
            ...teamsInfo,
            marathon_id: challengeInfo.id!,
          })
            .then(() => {
              onSubmit(challengeInfo.id!);
            })
            .catch(() => {});
        })
        .catch(() => {});
      return;
    }
    createChallenge(submittedInfo)
      .then(res => {
        updateTeams({
          ...teamsInfo,
          marathon_id: res.item.id,
        })
          .then(() => {
            onSubmit(res.item.id);
          })
          .catch(() => {});
      })
      .catch(() => {});
  };

  const ignoreChanges = () => {
    setChallengeInfo(DEFAULT_VALUES);
    onClose();
  };

  return (
    <Drawer maxWidth='50vw' open={open} onClose={ignoreChanges}>
      <DrawerHeader>
        <StyledFlexBox>
          <ModalTitle title={TRANSLATIONS[purpose].title} />
          <StyledSwitch
            checked={challengeInfo.is_active}
            onChange={() =>
              handleFieldChange('is_active', !challengeInfo.is_active)
            }
            className='switch'
          />
        </StyledFlexBox>
        <CloseIcon onClick={ignoreChanges} />
      </DrawerHeader>
      <DrawerFormBody>
        <StyledFlexBox>
          <ImageInput
            inputId='spaceLogo'
            title='Добавьте обложку'
            descriptionWithoutImage='Загрузите изображение (1125x675 px, до 1 мб)'
            withDescription
            image={challengeInfo.cover}
            setImage={newImg => handleFieldChange('cover', newImg)}
            style={{ width: '60%' }}
            mediaType={MEDIA_TYPES.MARATHON}
            imageFixedHeight={675}
            imageFixedWidth={1125}
          />
        </StyledFlexBox>
        <h2>О вызове</h2>
        <FormItem label='Название'>
          <TextField
            variant='standard'
            color='primary'
            placeholder='Впишите название'
            name='name'
            value={challengeInfo.name}
            onChange={e => handleFieldChange('name', e.target.value)}
          />
        </FormItem>
        {/* <FormItem label='Краткое описание'>
          <TextField
            variant='standard'
            color='primary'
            placeholder='Введите описание'
            name='description'
            value={challengeInfo.description}
            onChange={e => handleFieldChange('description', e.target.value)}
          />
        </FormItem> */}
        <FormItem label='Полное описание'>
          {/* <TextField
            variant='standard'
            color='primary'
            placeholder='Введите описание'
            name='body'
            value={challengeInfo.body}
            onChange={e => handleFieldChange('body', e.target.value)}
            multiline
          /> */}
          <HtmlEditor
            value={challengeInfo.body}
            onChange={newValue => handleFieldChange('body', newValue)}
            mediaType={MEDIA_TYPES.MARATHON_CONTENT}
          />
        </FormItem>
        <StyledSwitchFormControl
          label='Вызов Активен'
          labelPlacement='start'
          control={
            <StyledSwitch
              checked={challengeInfo.show_on_main}
              onChange={() =>
                handleFieldChange('show_on_main', !challengeInfo.show_on_main)
              }
            />
          }
        />
        <StyledSwitchFormControl
          label='Отображать на главной странице'
          labelPlacement='start'
          control={
            <StyledSwitch
              checked={challengeInfo.show_in_slider}
              onChange={() =>
                handleFieldChange(
                  'show_in_slider',
                  !challengeInfo.show_in_slider,
                )
              }
            />
          }
        />
        <StyledSwitchFormControl
          label='Поделиться'
          labelPlacement='start'
          control={
            <StyledSwitch
              checked={challengeInfo.is_shareable}
              onChange={() =>
                handleFieldChange('is_shareable', !challengeInfo.is_shareable)
              }
            />
          }
        />
        <h2>Параметры</h2>
        <StyledFlexBox className='fullDivWidth'>
          <FormItem label='Минимальный возраст для участия'>
            <TextField
              variant='standard'
              color='primary'
              placeholder='Укажите возраст'
              name='min_age'
              value={challengeInfo.min_age}
              onChange={e => handleFieldChange('min_age', e.target.value)}
            />
          </FormItem>
          <FormItem label='Максимальный возраст для участия'>
            <TextField
              variant='standard'
              color='primary'
              placeholder='Укажите возраст'
              name='max_age'
              value={challengeInfo.max_age}
              onChange={e => handleFieldChange('max_age', e.target.value)}
            />
          </FormItem>
        </StyledFlexBox>
        <StyledFlexBox className='fullDivWidth'>
          <FormItem label='Дата начала'>
            <DateInput
              key={challengeInfo.start_date}
              stringValue={challengeInfo.start_date}
              onChange={newValue => handleFieldChange('start_date', newValue)}
              disabled={purpose === 'edit'}
            />
          </FormItem>
          <FormItem label='Дата завершения'>
            <DateInput
              key={challengeInfo.end_date}
              stringValue={challengeInfo.end_date}
              onChange={newValue => handleFieldChange('end_date', newValue)}
              disabled={purpose === 'edit'}
            />
          </FormItem>
        </StyledFlexBox>
        <FormItem label='Тип вызова'>
          <StyledRadioGroup
            row
            value={challengeInfo.type}
            onChange={e => handleFieldChange('type', Number(e.target.value))}
          >
            <FormControlLabel
              value={1}
              control={<Radio />}
              label='Командный'
              labelPlacement='start'
            />
            <FormControlLabel
              value={2}
              control={<Radio />}
              label='Общедоступный'
              labelPlacement='start'
            />
          </StyledRadioGroup>
        </FormItem>
        {challengeInfo.type === 1 && (
          <AddTeamToChallenge
            allTeams={allTeamsOfMarathon}
            spaceId={space_id as number}
            addTeamToChallenge={addTeamToChallenge}
            removeTeamFromChallenge={removeTeamFromChallenge}
          />
        )}
      </DrawerFormBody>
      <StyledFlexBox className='buttons fullDivWidth'>
        <Box />
        <Button
          variant='contained'
          size='large'
          color='primary'
          onClick={saveChanges}
        >
          Далее
        </Button>
      </StyledFlexBox>
    </Drawer>
  );
};
