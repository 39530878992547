import { FC } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box } from '@mui/material';
import {
  Button,
  Drawer,
  DrawerHeader,
  ModalTitle,
  StyledBadge,
  StyledFlexBox,
  UserImage,
} from 'UI';
import { ROUTES } from 'app';
import { useGetAllWorkers, useGetSpace } from 'hooks';
import { COLORS } from 'utils';
import { CloseIcon, EditIcon, RightIcon } from 'assets';
import { StyledBody, StyledCard, StyledHeader } from './styledComponents';

interface IViewSpaceDrawerProps {
  open: boolean;
  onClose: () => void;
  id: number | string;
}

export const ViewSpaceDrawer: FC<IViewSpaceDrawerProps> = ({
  open,
  onClose,
  id,
}) => {
  if (!id) return null;
  const location = useLocation();
  const navigate = useNavigate();
  const { data: spaceInfo } = useGetSpace(id);
  const { total, data: workers } = useGetAllWorkers();

  const openEditDrawer = () => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('edit', id.toString());
    searchParams.delete('view');
    navigate(`?${searchParams.toString()}`);
  };

  const goToLink = (link: string) => {
    navigate(`${link}?space_id=${id}`);
  };

  return (
    <Drawer open={open} onClose={onClose} maxWidth='100%' type='gray'>
      <DrawerHeader>
        <StyledFlexBox>
          <ModalTitle title='Просмотр пространства' />
          <StyledBadge
            is_active={spaceInfo?.is_active || false}
            style={{ marginBottom: 0 }}
            variant='secondary'
          />
        </StyledFlexBox>
        <CloseIcon onClick={onClose} />
      </DrawerHeader>
      <StyledHeader>
        <img src={spaceInfo?.cover} alt={spaceInfo?.name} className='cover' />
        <Box className='meta'>
          <Box className='info'>
            <img src={spaceInfo?.logo} alt={spaceInfo?.name} className='logo' />
            <Box>
              <Box className='name'>{spaceInfo?.name}</Box>
              <Box className='description'>{spaceInfo?.description}</Box>
            </Box>
          </Box>

          <Button
            color='customBlack'
            startIcon={<EditIcon color={COLORS.WHITE} />}
            size='large'
            onClick={openEditDrawer}
          >
            Редактировать
          </Button>
        </Box>
      </StyledHeader>
      <StyledBody>
        <Box className='column'>
          <StyledCard>
            <Box className='title'>{spaceInfo?.city?.name}</Box>
            <Box className=''>{spaceInfo?.description}</Box>
          </StyledCard>
          <StyledCard>
            <Box className='title'>{spaceInfo?.marathons_count}</Box>
            <div>
              <Box className='value'>Активные вызовы</Box>
              <Button
                variant='text'
                endIcon={<RightIcon />}
                onClick={_e => goToLink(ROUTES.challenges)}
              >
                Перейти
              </Button>
            </div>
          </StyledCard>
          <StyledCard>
            <Box className='title'>{spaceInfo?.news_count}</Box>
            <div>
              <Box className='value'>Новости</Box>
              <Button
                variant='text'
                endIcon={<RightIcon />}
                onClick={_e => goToLink(ROUTES.news)}
              >
                Перейти
              </Button>
            </div>
          </StyledCard>

          <StyledCard>
            <Box className='title'>{spaceInfo?.users_count}</Box>
            <div>
              <Box className='value'>Пользователи</Box>
              <Button
                variant='text'
                endIcon={<RightIcon />}
                onClick={_e => goToLink(ROUTES.users)}
              >
                Перейти
              </Button>
            </div>
          </StyledCard>
        </Box>
        <Box className='column'>
          <StyledCard>
            <Box className='title'>{spaceInfo?.groups_count}</Box>
            <div>
              <Box className='value'>Команды</Box>
              <Button
                variant='text'
                endIcon={<RightIcon />}
                onClick={_e => goToLink(ROUTES.teams)}
              >
                Перейти
              </Button>
            </div>
          </StyledCard>
          <StyledCard className='admins'>
            <Box className='adminsTitle'>Администраторы</Box>
            <Box className='value'>{total} человека</Box>
            <Box className='adminsList'>
              {workers.map(worker => (
                <Box className='userItem' key={worker.id}>
                  <Box className='userData'>
                    <Box className='photo'>
                      <UserImage
                        src={worker.photo as string | undefined}
                        alt={worker.firstname}
                        firstName={worker.firstname}
                        lastName={worker.lastname}
                        width='2.5rem'
                        height='2.5rem'
                      />
                    </Box>
                    <Box className='meta'>
                      <Box className='name'>
                        {worker.firstname} {worker.lastname}
                      </Box>
                      <Box className='phone'>{worker.phone}</Box>
                    </Box>
                  </Box>
                  <Box className='userRole'>{worker.role?.text}</Box>
                </Box>
              ))}
            </Box>
          </StyledCard>
        </Box>
      </StyledBody>
    </Drawer>
  );
};
