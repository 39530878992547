import { INotificationReq, IPaginatedReqParams } from 'types';
import { http, methods } from './config';

export const getNotifications = (params: IPaginatedReqParams) => {
  const config = {
    method: methods.get,
    url: `/notification`,
    params,
  };
  return http(config);
};

export const getNotification = (notificationId: string | number) => {
  const config = {
    method: methods.get,
    url: `/notification/${notificationId}`,
  };
  return http(config);
};

export const createNotification = (data: INotificationReq) => {
  const config = {
    method: methods.post,
    url: `/notification`,
    data,
  };
  return http(config);
};

export const updateNotification = (data: INotificationReq) => {
  const config = {
    method: methods.put,
    url: `/notification/${data.id}`,
    data,
  };
  return http(config);
};

export const deleteNotification = (notificationId: number | string) => {
  const config = {
    method: methods.delete,
    url: `/notification/${notificationId}`,
  };
  return http(config);
};
