import { FC } from 'react';
import { styled } from '@mui/system';
import {
  Box,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
} from '@mui/material';
import { COLORS, formatNumber } from 'utils';
import { Button, UserImage } from 'UI';

const StyledContainer = styled(Paper)({
  backgroundColor: COLORS.WHITE,
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  '& .header': {
    padding: '0.8125rem 1.25rem',
    fontSize: '1.25rem',
    fontFamily: '"SF Pro Display", sans-serif',
    fontWeight: 600,
    borderBottom: `1px solid rgba(0,0,0,0.1)`,
  },
  '& .list': {
    padding: '0 1.25rem',
  },
  '& .listItem': {
    padding: '0.75rem',
    justifyContent: 'space-between',
    '&:not(:last-of-type)': {
      borderBottom: '1px solid rgba(0,0,0,0.05)',
    },
    '& .avatar': {
      position: 'relative',
      '& img': {
        maxWidth: '2.5rem',
        minWidth: '2.5rem',
        height: '2.5rem',
        borderRadius: '50%',
        overflow: 'hidden',
        objectFit: 'cover',
      },
    },
    '& .place': {
      position: 'absolute',
      top: '20%',
      left: '-15%',
      borderRadius: '50%',
      width: '1.375rem',
      height: '1.375rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: '0.75rem',
      fontWeight: 600,
      fontFamily: '"SF Pro Display", sans-serif',
      '&-1': {
        backgroundColor: COLORS.GOLDEN,
      },
      '&-2': {
        backgroundColor: COLORS.SILVER,
      },
      '&-3': {
        backgroundColor: COLORS.BRONZE,
      },
      '&-4': {
        backgroundColor: COLORS.GRAY,
      },
      '&-5': {
        backgroundColor: COLORS.GRAY,
      },
    },
    '& .value': {
      textAlign: 'right',
    },
  },
  '& .bottomButton': {
    marginTop: 'auto',
    '& a': {
      padding: '0.8125rem',
      width: '100%',
      display: 'flex',
    },
  },
});

interface IAnalyticsListCardProps {
  title: string;
  listItems: Array<ITopListItem>;
  link: string;
}

interface ITopListItem {
  id: string | number;
  name: string;
  space_name: string;
  photo: string;
  value: number;
}

export const AnalyticsListCard: FC<IAnalyticsListCardProps> = ({
  title,
  listItems,
  link,
}) => {
  return (
    <StyledContainer>
      <Box className='header'>{title}</Box>
      <List className='list'>
        {listItems.map((item, place) => (
          <ListItem key={item.id} className='listItem'>
            <ListItemAvatar className='avatar'>
              <UserImage
                firstName={item.name}
                src={item.photo}
                alt={item.name}
                width='2.5rem'
                height='2.5rem'
              />
              <Box className={`place place-${place + 1}`}>{place + 1}</Box>
            </ListItemAvatar>
            <ListItemText>{item.name}</ListItemText>
            <ListItemText className='value'>
              {formatNumber(item.value)}
            </ListItemText>
          </ListItem>
        ))}
      </List>
      <Box className='bottomButton'>
        <Button href={link} variant='text' color='primary'>
          Посмотреть все
        </Button>
      </Box>
    </StyledContainer>
  );
};
