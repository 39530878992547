import { FC, ChangeEvent } from 'react';
import InputMask from 'react-input-mask';
import { TextField, Box, InputAdornment } from '@mui/material';
import { Timer } from './Timer';

interface IPhoneInput {
  value: string;
  onChange: (_e: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  isError?: boolean;
  errorMessage?: string;
  showTimer?: boolean;
  onTimerComplete: () => void;
}

export const PhoneInput: FC<IPhoneInput> = ({
  value,
  onChange,
  disabled,
  isError,
  errorMessage,
  showTimer,
  onTimerComplete,
}) => {
  const renderTextField = () => (
    <TextField
      label='Номер телефона'
      variant='outlined'
      name='phone'
      fullWidth
      error={isError}
      helperText={errorMessage}
      disabled={disabled}
      InputProps={{
        endAdornment: showTimer && (
          <InputAdornment position='start'>
            <Timer onTimerComplete={onTimerComplete} />
          </InputAdornment>
        ),
      }}
    />
  );
  return (
    <Box sx={{ marginBottom: '1.25rem' }}>
      <InputMask
        mask='+9 999 999 99 99'
        maskPlaceholder=''
        disabled={disabled}
        value={value}
        onChange={onChange}
      >
        {/* @ts-ignore */}
        {() => renderTextField()}
      </InputMask>
    </Box>
  );
};
