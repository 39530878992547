import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { COLORS } from 'utils';

export const StyledHeader = styled(Box)(({ bgImg }: { bgImg: string }) => ({
  width: '100%',
  height: '18.5rem',
  borderRadius: '0.5rem',
  position: 'relative',
  overflow: 'hidden',
  marginBottom: '1.25rem',
  background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.4)), url(${bgImg}) center/cover no-repeat`,
  '& .meta': {
    position: 'absolute',
    left: '1.25rem',
    bottom: '1.25rem',
    width: 'calc(100% - 2.5rem)',
    display: 'flex',
    flexDirection: 'column',
    gap: '0.625rem',
    color: COLORS.WHITE,
    fontFamily: '"SF Pro Text", sans-serif',
  },
  '& .row': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'end',
  },
  '& .name': {
    fontSize: '1.25rem',
    lineHeight: '1.5rem',
    fontWeight: 600,
  },
  '& .description': {
    fontSize: '0.75rem',
    lineHeight: '1.25rem',
    fontWeight: 400,
    maxWidth: '80%',
  },
}));

export const StyledBody = styled(Box)({
  padding: '1.25rem 0 0',
  display: 'flex',
  flexWrap: 'wrap',
  gap: '1.25rem',
  '& .column': {
    width: 'calc((100% - 1.25rem) / 2)',
    display: 'flex',
    flexDirection: 'column',
    gap: '0.25rem',
  },
  '& .row': {
    width: '100%',
    display: 'flex',
    gap: '1.25rem',
  },
});

export const StyledCard = styled(Box)({
  width: '100%',
  borderRadius: '0.75rem',
  padding: '1rem 1.25rem',
  backgroundColor: COLORS.WHITE,
  border: `1px solid ${COLORS.WHITE}`,
  display: 'flex',
  flexDirection: 'column',
  gap: '0.25rem',
  '& .value': {
    fontSize: '2.125rem',
    lineHeight: '2.5rem',
    fontWeight: 600,
    fontFamily: '"SF Pro Display", sans-serif',
    opacity: 0.75,
  },
  '& .title': {
    fontSize: '0.875rem',
    lineHeight: '1.5rem',
    fontWeight: 400,
    fontFamily: '"SF Pro Display", sans-serif',
    opacity: 0.5,
  },
  '& .flex': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  '& .bigCardTitle': {
    fontSize: '1.25rem',
    fontWeight: 600,
    fontFamily: '"SF Pro Display", sans-serif',
    paddingBottom: '0.75rem',
  },
  '& .winnersList': {
    display: 'flex',
    flexDirection: 'column',
  },
  '& .winner': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderTop: `1px solid ${COLORS.GRAY}`,
    padding: '0.75rem 1.25rem',
    width: 'calc(100% + 2.5rem)',
    marginLeft: '-1.25rem',
  },
  '& .winnerInfo': {
    display: 'flex',
    alignItems: 'center',
    gap: '0.625rem',
  },
  '& .photo': {
    width: '2.5rem',
    height: '2.5rem',
    borderRadius: '50%',
    overflow: 'hidden',
    backgroundColor: COLORS.WHITE,
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
  },
  '& .name': {
    fontSize: '0.875rem',
    fontWeight: 600,
    fontFamily: '"SF Pro Text", sans-serif',
  },
  '& .place': {
    fontSize: '0.75rem',
    lineHeight: '1.125rem',
    fontFamily: '"SF Pro Display", sans-serif',
    fontWeight: 500,
    padding: '0.1875rem 0.5rem',
    borderRadius: '0.75rem',
    '&-1': {
      backgroundColor: COLORS.GOLDEN,
    },
    '&-2': {
      backgroundColor: COLORS.SILVER,
    },
    '&-3': {
      backgroundColor: COLORS.BRONZE,
    },
  },
  '& .noWinners': {
    width: '100%',
    minHeight: '12rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '0.875rem',
    fontWeight: 500,
    opacity: 0.5,
  },
  '& .prizesList': {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.25rem',
    '& .prize': {
      height: '4.5rem',
      width: '100%',
      borderRadius: '0.5rem',
      overflow: 'hidden',
      '& img': {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        objectPosition: 'center',
      },
    },
  },
});

export const StyledLabel = styled(Box)({
  fontSize: '0.75rem',
  fontFamily: '"SF Pro Display", sans-serif',
  lineHeight: '1.25rem',
  opacity: 0.5,
});

export const StyledMiniCard = styled(Box)({
  padding: '0.75rem 1rem',
  backgroundColor: COLORS.WHITE,
  borderRadius: '0.5rem',
  width: '100%',
  fontSize: '1rem',
  fontFamily: '"SF Pro Display", sans-serif',
  lineHeight: '1.625rem',
  fontWeight: 500,
  display: 'flex',
  alignItems: 'center',
  gap: '0.5rem',
  minHeight: '3.125rem',
});
