import { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { Drawer, DrawerHeader, ModalTitle, Button, UserImage } from 'UI';
import { CloseIcon, EditIcon } from 'assets';
import { useGetTeam } from 'hooks';
import { COLORS } from 'utils';
import {
  StyledBody,
  StyledHeader,
  StyledInfoBlock,
  StyledUsersBlock,
} from './styledComponents';

interface IViewTeamDrawerProps {
  open: boolean;
  onClose: () => void;
  id: string;
}

export const ViewTeamDrawer: FC<IViewTeamDrawerProps> = ({
  open,
  onClose,
  id,
}) => {
  if (!id) return null;
  const location = useLocation();
  const navigate = useNavigate();
  const { data: teamInfo } = useGetTeam(id);

  const openEditDrawer = () => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('edit', id.toString());
    searchParams.delete('view');
    navigate(`?${searchParams.toString()}`);
  };

  return (
    <Drawer open={open} onClose={onClose} type='gray'>
      <DrawerHeader>
        <ModalTitle title='Команда' />
        <CloseIcon onClick={onClose} />
      </DrawerHeader>
      <StyledHeader>
        <img src={teamInfo?.logo} alt={teamInfo?.name} className='logo' />
        <Box>
          <Box className='name'>{teamInfo?.name}</Box>
          <Box className='description'>{teamInfo?.description}</Box>
        </Box>
      </StyledHeader>
      <StyledBody>
        <StyledInfoBlock>
          <Box className='title'>Описание</Box>
          <Box className='info'>{teamInfo?.description}</Box>
        </StyledInfoBlock>
        <StyledUsersBlock>
          <Box className='header'>
            <Box>Участники</Box>
            <Box>{teamInfo?.members_count}</Box>
          </Box>
          <Box className='body'>
            {teamInfo?.members?.map(member => (
              <Box className='item' key={member.id}>
                <Box className='image'>
                  <UserImage
                    src={member.photo as string | undefined}
                    alt={member.firstname}
                    width='3.125rem'
                    height='3.125rem'
                    firstName={member.firstname}
                    lastName={member.lastname}
                    className='image'
                  />
                </Box>
                <Box className='name'>
                  {member.firstname} {member.lastname}
                </Box>
              </Box>
            ))}
          </Box>
        </StyledUsersBlock>
        <Button
          color='customBlack'
          startIcon={<EditIcon color={COLORS.WHITE} />}
          size='large'
          onClick={openEditDrawer}
          className='editButton'
        >
          Редактировать
        </Button>
      </StyledBody>
    </Drawer>
  );
};
