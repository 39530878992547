import { IAnalyticsCommonGetParams, IAnalyticsTopListGetParams } from 'types';
import { http, methods } from '../config';

export const getAnalyticsShowcaseData = (params: IAnalyticsCommonGetParams) => {
  return http({
    method: methods.GET,
    url: '/statistics/showcase/data',
    params,
  });
};

export const getAnalyticsShowcaseGender = (
  params: IAnalyticsCommonGetParams,
) => {
  return http({
    method: methods.GET,
    url: '/statistics/showcase/gender',
    params,
  });
};

export const getAnalyticsShowcaseTopDiscounts = (
  params: IAnalyticsTopListGetParams,
) => {
  return http({
    method: methods.GET,
    url: '/statistics/showcase/top_discounts',
    params,
  });
};

export const getAnalyticsShowcaseTopCategories = (
  params: IAnalyticsTopListGetParams,
) => {
  return http({
    method: methods.GET,
    url: '/statistics/showcase/top_categories',
    params,
  });
};

export const getAnalyticsShowcasePartnersDownloadFile = (
  params: IAnalyticsCommonGetParams,
) => {
  return http({
    method: methods.GET,
    url: '/statistics/showcase/get_partners_xls',
    params,
  });
};

export const getAnalyticsShowcaseClientsDownloadFile = (
  params: IAnalyticsCommonGetParams,
) => {
  return http({
    method: methods.GET,
    url: '/statistics/showcase/get_clients_xls',
    params,
  });
};
