import { Box, Paper, Table, TableContainer, TableRow } from '@mui/material';
import { styled } from '@mui/system';
import { COLORS } from 'utils';

export const StyledBox = styled(Box)({
  width: '100%',
  overflow: 'auto',
});

export const StyledPaper = styled(Paper)({
  width: '100%',
  marginBottom: 2,
});

export const StyledTableContainer = styled(TableContainer)({
  maxHeight: 'calc(100vh - 12rem)',
  minHeight: 'calc(100vh - 12rem)',
  '& .MuiTableHead-root': {
    '& .MuiTableCell-root': {
      '&:first-child': {
        paddingLeft: '2.5rem',
      },
    },
  },
});

export const StyledTable = styled(Table)({
  minWidth: '1040px',
});

export const StyledTableRow = styled(TableRow)({
  '& span': {
    opacity: 0.5,
    fontSize: '0.875rem',
    fontWeight: 400,
    lineHeight: '1.375rem',
    fontFamily: '"SF Pro Display", sans-serif',
  },
  '& .centered': {
    textAlign: 'center',
  },
  '& .editDeleteIcons': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '0.9375rem',
    width: '100%',
    maxWidth: '9rem',
    '& svg': {
      cursor: 'pointer',
      marginTop: '0.5rem',
    },
  },
});

export const StyledStoryInfoAtModal = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  fontWeight: 600,
  gap: '0.625rem',
  border: `1px solid ${COLORS.GRAY}`,
  padding: '0.625rem',
  borderRadius: '0.5rem',
});

export const StyledStoryInfo = styled(Box)({
  display: 'flex',
  gap: '0.625rem',
  alignItems: 'center',
  '& .name': {
    fontWeight: 600,
    width: 'max-content',
    fontSize: '0.875rem',
    lineHeight: '1.375rem',
    fontFamily: '"SF Pro Display", sans-serif',
  },
});

export const StyledAvatar = styled(Box)({
  width: '3rem',
  height: '5rem',
  borderRadius: '0.25rem',
  overflow: 'hidden',
  cursor: 'pointer',
  '& img': {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
  },
});
