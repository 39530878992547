import { styled } from '@mui/system';
import { Box } from '@mui/material';

export const StyledContainer = styled(Box)({
  marginTop: '0.25rem',
  '& h2': {
    fontSize: '1.125rem',
    fontWeight: 600,
    fontFamily: '"SF Pro Display", sans-serif',
    lineHeight: '1.625rem',
    margin: '0 0 0.625rem',
  },
});

export const StyledHeader = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: '0.625rem',
  '& h2': {
    fontSize: '1.125rem',
    fontWeight: 600,
    fontFamily: '"SF Pro Display", sans-serif',
    lineHeight: '1.625rem',
    margin: 0,
  },
});

export const StyledMainContent = styled(Box)({
  border: `1px solid rgba(0,0,0,0.1)`,
  borderRadius: '0.5rem',
  padding: '0.625rem',
});

export const StyledUserOptions = styled(Box)({
  maxHeight: '20rem',
  overflowY: 'auto',
});

export const StyledUserOption = styled(Box)({
  display: 'flex',
  padding: '0.75rem 1rem',
  gap: '0.625rem',
  alignItems: 'center',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
  },
  '& .imageBox': {
    width: '3.125rem',
    height: '3.125rem',
    overflow: 'hidden',
    borderRadius: '50%',
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
  },
  '& p': {
    fontSize: '1rem',
    fontWeight: '500',
    fontFamily: '"SF Pro Display", sans-serif',
    lineHeight: '1.5rem',
    margin: 0,
  },
});

export const StyledUsersList = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  maxHeight: '20rem',
  overflowY: 'auto',
});

export const StyledButtonBox = styled(Box)({
  paddingTop: '0.625rem',
  '& button': {
    width: '100%',
  },
});

export const StyledUserItem = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderBottom: `1px solid rgba(0,0,0 ,0.1)`,
  padding: '0.75rem 1rem',
  position: 'relative',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    '& .button': {
      visibility: 'visible',
    },
  },
  '& .userMeta': {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      position: 'absolute',
      left: '0.375rem',
    },
  },
  '& .button': {
    visibility: 'hidden',
  },
  '& .imageBox': {
    width: '3.125rem',
    height: '3.125rem',
    overflow: 'hidden',
    borderRadius: '50%',
    marginRight: '0.625rem',
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
  },
  '& .deleteIcon': {
    cursor: 'pointer',
    padding: '0.25rem',
  },
});
