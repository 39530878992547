import * as Pages from 'pages';
import { ROLES } from 'utils';
import { IRoutes } from '../types';

export const Services: IRoutes = {
  services: {
    path: '/services',
    title: 'services',
    element: <Pages.Services />,
    roles: [ROLES.ADMINSTRATOR],
  },
  serviceInstructions: {
    path: '/services/instructions',
    title: 'service Instructions',
    element: <Pages.Instructions />,
    roles: [ROLES.ADMINSTRATOR],
  },
  serviceQAs: {
    path: '/services/qa',
    title: 'service QAs',
    element: <Pages.QA />,
    roles: [ROLES.ADMINSTRATOR],
  },
  serviceMeAndGto: {
    path: '/services/me-and-gto',
    title: 'service Me And GTO',
    element: <Pages.MeAndGto />,
    roles: [ROLES.ADMINSTRATOR],
  },
};
