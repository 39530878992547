import { styled } from '@mui/system';
import { FormControlLabel, Box } from '@mui/material';
import { COLORS } from 'utils';

export const StyledFileUploadContainer = styled(Box)({
  padding: '0.25rem 0',
  '& h2': {
    fontSize: '1.125rem',
    fontWeight: 600,
    lineHeight: '1.625rem',
    margin: '0 0 0.625rem',
  },
  '& .buttons': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '1rem',
    width: '100%',
    overflow: 'auto',
    '& button': {
      width: '100%',
    },
  },
});

export const StyledSwitchFormControl = styled(FormControlLabel)({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  borderBottom: `1px solid ${COLORS.BLACK}`,
  margin: 0,
  paddingBottom: '0.375rem',
  '& .MuiFormControlLabel-label': {
    marginTop: '0.375rem',
    fontSize: '1rem',
    lineHeight: '1.5rem',
  },
});
