import { FC } from 'react';
import { IIconProps } from './types';

export const ChallengesIcon: FC<IIconProps> = ({ isActive, activeColor }) => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g>
        <path
          d='M2 3V4C2 6.76667 3.13952 8.76202 4.16797 10.3047C5.19641 11.8474 6 12.9444 6 14H8C8 13.9838 7.99626 13.9692 7.99609 13.9531C8.97458 16.1662 10 17 10 17H14C14 17 15.0254 16.1662 16.0039 13.9531C16.0037 13.9692 16 13.9838 16 14H18C18 12.9444 18.8036 11.8474 19.832 10.3047C20.8605 8.76202 22 6.76667 22 4V3H2ZM4.19141 5H6.27344C6.33728 7.40807 6.61876 9.38024 7.00781 10.9746C6.63657 10.3668 6.22309 9.7819 5.83203 9.19531C5.02867 7.99027 4.41919 6.65498 4.19141 5ZM8.30273 5H15.6973V6H15.6934C15.3804 11.398 13.843 14.041 13.123 15H10.8828C10.1428 14.016 8.61764 11.371 8.30664 6H8.30273V5ZM17.7266 5H19.8086C19.5808 6.65498 18.9713 7.99027 18.168 9.19531C17.7769 9.7819 17.3634 10.3668 16.9922 10.9746C17.3812 9.38024 17.6627 7.40807 17.7266 5ZM7 19V21H17V19H7Z'
          fill={isActive ? activeColor : '#C4C4C4'}
        />
      </g>
    </svg>
  );
};
