import * as Pages from 'pages';
import { ROLES } from 'utils';
import { IRoutes } from '../types';

export const WorkoutsCalendar: IRoutes = {
  workoutsCalendar: {
    path: '/calendarWorkouts',
    title: 'workoutsCalendar',
    element: <Pages.WorkoutsCalendar />,
    roles: [ROLES.ADMINSTRATOR],
  },
};
