import { FC, RefObject, MouseEvent } from 'react';
import { CircularProgressProps, Button as MuiButton } from '@mui/material';
import { styled } from '@mui/system';
import { Link } from 'react-router-dom';
import { MiniLoader } from 'UI';

const StyledButton = styled(MuiButton)({
  fontFamily: '"SF Pro Display", sans-serif',
  minWidth: 'max-content',
  boxShadow: 'none',
  textTransform: 'none',
  '&:hover': {
    boxShadow: 'none',
  },
  '&.withFixedWidth': {
    width: '12.25rem',
  },
});

// Define a type for the color prop
export type ButtonColor =
  | 'inherit'
  | 'primary'
  | 'secondary'
  | 'success'
  | 'error'
  | 'info'
  | 'warning'
  | 'customBlack'
  | 'customGray'
  | 'customWhite'
  | 'customDarkerGray'
  | 'customLightBlue'
  | 'secondaryRed2';

interface ICustomButtonProps {
  color?: ButtonColor;
  variant?: 'text' | 'outlined' | 'contained';
  size?: 'small' | 'medium' | 'large';
  href?: string;
  onClick?: (_e: MouseEvent<HTMLButtonElement>) => void;
  children: React.ReactNode;
  disabled?: boolean;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  sx?: any;
  ref?: RefObject<HTMLButtonElement>;
  className?: string;
  disableRipple?: boolean;
  isLoading?: boolean;
  loaderColor?: CircularProgressProps['color'];
}

export const Button: FC<ICustomButtonProps> = ({
  variant = 'contained',
  size = 'medium',
  color = 'primary',
  href,
  onClick,
  children,
  disabled,
  startIcon,
  endIcon,
  sx,
  ref,
  className,
  disableRipple,
  isLoading,
  loaderColor,
}) => {
  return (
    <StyledButton
      color={color}
      variant={variant}
      size={size}
      onClick={onClick}
      disabled={disabled || isLoading}
      startIcon={
        isLoading ? <MiniLoader size={12} color={loaderColor} /> : startIcon
      }
      endIcon={endIcon}
      {...(href ? { component: Link, to: href } : {})}
      sx={sx}
      ref={ref}
      className={className}
      disableRipple={disableRipple}
    >
      {children}
    </StyledButton>
  );
};
