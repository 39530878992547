import { IAchievementReq } from 'types';

export const TRANSLATIONS = {
  add: {
    title: 'Создать достижение',
  },
  edit: {
    title: 'Редактировать достижение',
  },
};

export const DEFAULT_VALUES: IAchievementReq = {
  name: '',
  description: '',
  cover: '',
  is_active: true,
  congrats_title: '',
  congrats_text: '',
  needle_type: 0,
  needle_value: '',
  needle_period: 0,
};

export const ACHIEVEMENT_TYPE_OPTIONS = [
  { value: 1, label: 'За дистанцию' },
  { value: 2, label: 'За калории' },
  { value: 3, label: 'За шаги' },
  { value: 4, label: 'За длительность' },
  { value: 5, label: 'За победу в вызовы' },
  { value: 6, label: 'Уровень новичок' },
  { value: 7, label: 'Уровень физкультурник' },
  { value: 8, label: 'Уровень физкультурник ПРО' },
  { value: 9, label: 'Уровень эксперт' },
  { value: 10, label: 'Подключен аккаунт ГТО' },
  { value: 11, label: 'За завершение тренировку' },
];

export const ACHIEVEMENT_PERIOD_OPTIONS = [
  { value: 1, label: 'За день' },
  { value: 2, label: 'За неделя' },
  { value: 3, label: 'За месяц' },
  { value: 4, label: 'За год' },
  { value: 5, label: 'За всё время' },
];

export const ACHIEVEMENT_VALUE_LABELS: Record<number, string> = {
  1: 'Дистанция (км)',
  2: 'Калории',
  3: 'Шаги',
  4: 'Длительность (мин)',
  5: 'Количество вызовов',
  6: 'Количество тренировок',
};
