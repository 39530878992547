import { FC } from 'react';
import { Box } from '@mui/material';
import { useGetMarathon } from 'hooks';
import { Drawer, DrawerHeader, ModalTitle } from 'UI';
import { LeftArrowIcon } from 'assets';
import { StyledBody, StyledDayCard, StyledMetaInfo } from './styledComponents';

const TYPE_TEXT = ['', 'Км', 'Мин', 'Кал', 'Шт'];

interface IViewChallengeTrainingsDrawerProps {
  open: boolean;
  onClose: () => void;
  id: number | string;
}

export const ViewChallengeTrainingsDrawer: FC<
  IViewChallengeTrainingsDrawerProps
> = ({ open, onClose, id }) => {
  // if (!id) return null;
  const { data: marathonInfo } = useGetMarathon(id);
  if (!marathonInfo) return null;

  const calculateBalls = () => {
    return marathonInfo.calendar.reduce((acc, item) => {
      return acc + item.points;
    }, 0);
  };

  return (
    <Drawer open={open} onClose={onClose} type='gray' width='60vw'>
      <DrawerHeader>
        <ModalTitle title='Задания по дням' />
        <LeftArrowIcon onClick={onClose} />
      </DrawerHeader>
      <StyledBody>
        <StyledMetaInfo>
          <Box className='column'>
            <Box className='label'>Всего</Box>
            <Box className='value'>{marathonInfo.calendar.length} дней</Box>
          </Box>
          <Box className='column'>
            <Box className='label'>Максимальное количество</Box>
            <Box className='value'>{calculateBalls()} очков</Box>
          </Box>
        </StyledMetaInfo>
        {marathonInfo.calendar.map((item, index) => {
          return (
            <StyledDayCard key={item.id}>
              <Box className='header'>
                <Box className='title'>День {index + 1}</Box>
                <Box className='prize'>Всего {item.points} очков</Box>
              </Box>
              {item.items.map(dayItem => {
                return (
                  <Box className='item' key={dayItem.id}>
                    <Box className='meta'>
                      <Box className='icon'>
                        <img src={dayItem.training.icon} alt='Icon' />
                      </Box>
                      <Box>{dayItem.training.name}</Box>
                      <Box>-</Box>
                      <Box>
                        {dayItem.value} {TYPE_TEXT[dayItem.type]}
                      </Box>
                    </Box>
                    <Box className='price'>{dayItem.points}</Box>
                  </Box>
                );
              })}
            </StyledDayCard>
          );
        })}
      </StyledBody>
    </Drawer>
  );
};
