import { FC, CSSProperties } from 'react';
import { COLORS } from 'utils';

interface IAppleIcon {
  color?: string;
  className?: string;
  style?: CSSProperties;
  onClick?: () => void;
}

export const AppleIcon: FC<IAppleIcon> = ({
  color = COLORS.BLACK,
  className,
  style,
  onClick,
}) => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      style={style}
      onClick={onClick}
    >
      <path
        d='M16.125 1C14.972 1.067 13.6483 1.70934 12.8613 2.52734C12.1503 3.27134 11.5894 4.37631 11.8184 5.44531C13.0714 5.47831 14.329 4.81933 15.082 3.98633C15.785 3.20733 16.318 2.12 16.125 1ZM16.1934 5.44336C14.3844 5.44336 13.628 6.55469 12.375 6.55469C11.086 6.55469 9.90766 5.51367 8.34766 5.51367C6.22566 5.51467 3 7.48033 3 12.1113C3 16.3243 6.81766 21 8.97266 21C10.2817 21.013 10.599 20.177 12.375 20.168C14.153 20.155 14.5367 21.011 15.8477 21C17.3237 20.989 18.4764 19.367 19.3184 18.082C19.9224 17.162 20.1707 16.6923 20.6387 15.6523C17.1657 14.7723 16.4747 9.17167 20.6387 8.01367C19.8527 6.67267 17.5584 5.44336 16.1934 5.44336Z'
        fill={color}
      />
    </svg>
  );
};
