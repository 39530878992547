import { FC, MouseEvent } from 'react';
import { TableHead, TableRow, TableCell, TableSortLabel } from '@mui/material';
import { COLORS, OfferAndDiscountsSortColumnValues } from 'utils';
import {
  IShowcaseOfferDiscount,
  IHeadCell,
  OfferAndDiscountsSortParams,
  ITableHeadPropsWithSort,
} from 'types';
import { FilterShowcaseCategory } from 'components';

const headCells: readonly IHeadCell<IShowcaseOfferDiscount>[] = [
  {
    id: 'title',
    label: 'Заголовок',
    sortable: true,
  },
  {
    id: 'category_id',
    label: 'Категория',
    sortable: false,
  },
  // {
  //   id: 'show_in_slider',
  //   label: 'Показывать в слайдере',
  //   sortable: false,
  // },
  {
    id: 'position_in_list',
    label: 'Место',
    sortable: true,
  },
  {
    id: 'created_at',
    label: 'Дата создания',
    sortable: true,
  },
  {
    id: 'actions',
    label: 'Управление',
    sortable: false,
  },
];

export const OfferAndDiscountTableHead: FC<
  ITableHeadPropsWithSort<OfferAndDiscountsSortParams>
> = ({ onRequestSort, order, orderBy }) => {
  const isValidSortParam = (
    param: string,
  ): param is OfferAndDiscountsSortParams => {
    return OfferAndDiscountsSortColumnValues.includes(
      param as OfferAndDiscountsSortParams,
    );
  };

  const createSortHandler =
    (property: keyof IShowcaseOfferDiscount | 'actions') =>
    (event: MouseEvent<unknown>) => {
      if (isValidSortParam(property)) {
        onRequestSort(event, property);
      }
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => {
          if (headCell.id === 'category_id') {
            return (
              <TableCell key={headCell.id} align='center' padding='normal'>
                <FilterShowcaseCategory />
              </TableCell>
            );
          }
          if (!headCell.sortable) {
            return (
              <TableCell key={headCell.id} align='center' padding='normal'>
                {headCell.label}
              </TableCell>
            );
          }
          return (
            <TableCell
              key={headCell.id}
              align='center'
              padding='normal'
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
                sx={{
                  '&.Mui-active': {
                    '& .MuiTableSortLabel-icon': {
                      color: orderBy === headCell.id ? COLORS.RED : undefined,
                    },
                  },
                  '& .MuiTableSortLabel-icon': {
                    opacity: 0.3,
                  },
                }}
              >
                {headCell.label}
              </TableSortLabel>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};
