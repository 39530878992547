import { FC, useState, ChangeEvent, useEffect } from 'react';
import { FormControl, InputAdornment, TextField } from '@mui/material';
import { styled } from '@mui/system';
import { COLORS } from 'utils';
import { SearchIcon } from 'assets';

const StyledFormControl = styled(FormControl)(
  ({ customSize }: { customSize: 'small' | 'medium' | 'large' }) => ({
    borderRadius: '8px',
    backgroundColor: COLORS.WHITE,
    width:
      customSize === 'medium'
        ? '20rem'
        : customSize === 'small'
        ? '15rem'
        : '100%',
    border: `1px solid ${COLORS.GRAY}`,
  }),
);

const StyledTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    borderRadius: '8px',
    backgroundColor: COLORS.WHITE,
    '& fieldset': {
      border: `1px solid ${COLORS.GRAY}`,
    },
    '&:hover fieldset': {
      border: `1px solid ${COLORS.GRAY}`,
    },
    '&.Mui-focused fieldset': {
      border: `1px solid ${COLORS.BLUE}`,
    },
  },
  '& .MuiInputBase-root': {
    height: 44,
  },
});

interface ISearchInput {
  size?: 'small' | 'medium' | 'large';
  handleSearch: (_search: string) => void;
  searchOnChange?: boolean;
  placeholder?: string;
  defaultValue?: string;
}

export const SearchInput: FC<ISearchInput> = ({
  handleSearch,
  size = 'medium',
  searchOnChange = true,
  placeholder = 'Поиск',
  defaultValue = '',
}) => {
  const [search, setSearch] = useState<string>(defaultValue);

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setSearch(event.target.value);
  };

  useEffect(() => {
    let delayDebounceFn: any;
    if ((searchOnChange && search.length > 2) || !search.length) {
      delayDebounceFn = setTimeout(() => handleSearch(search), 500);
    }
    return () => clearTimeout(delayDebounceFn);
  }, [search]);

  return (
    <StyledFormControl customSize={size}>
      <StyledTextField
        size='small'
        variant='outlined'
        onChange={handleChange}
        value={search}
        placeholder={placeholder}
        InputProps={{
          startAdornment: (
            <InputAdornment
              position='start'
              sx={{ cursor: 'pointer' }}
              onClick={() => handleSearch(search)}
            >
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
    </StyledFormControl>
  );
};
