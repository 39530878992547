import { FC } from 'react';

import { useDrawer } from 'hooks';
import {
  AchievementsHeader,
  AddEditAchievementDrawer,
  AchievementsTable,
  Layout,
} from 'components';

export const Achievements: FC = () => {
  const {
    openAddDrawer,
    showAddEditDrawer,
    hideAddEditDrawer,
    editDrawerId,
    purpose,
  } = useDrawer();

  return (
    <Layout withoutPB>
      <AddEditAchievementDrawer
        open={showAddEditDrawer}
        onClose={hideAddEditDrawer}
        purpose={purpose}
        id={editDrawerId}
      />
      <AchievementsHeader openAddAchievementModal={openAddDrawer} />
      <AchievementsTable />
    </Layout>
  );
};
