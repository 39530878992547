import { FC, useContext } from 'react';
import { Box } from '@mui/material';
import { UserContext, UserContextType } from 'app';
import { CreateSponsorForChallenge } from 'components';
import { useDrawer, useDeleteSponsor, useGetSponsors } from 'hooks';
import { Drawer, Button, DrawerHeader, ModalTitle, StyledFlexBox } from 'UI';
import { CloseIcon, DeleteIcon, EditIcon, PlusIcon } from 'assets';
import { COLORS, CREATE_CHALLENGE_DRAWER_STEPS } from 'utils';
import { StyledSponsor, StyledSponsorsContainer } from './styledComponents';

interface ICreateChallengAddSponsorsProps {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
  goPreviousStep: () => void;
  marathonId: string;
}

export const CreateChallengAddSponsors: FC<ICreateChallengAddSponsorsProps> = ({
  open,
  onClose,
  onSubmit,
  goPreviousStep,
  marathonId,
}) => {
  if (!marathonId) return null;
  const { space_id } = useContext(UserContext) as UserContextType;
  const { data: sponsors = [] } = useGetSponsors({
    marathon_id: marathonId,
    space_id: space_id!,
  });

  const {
    changeSubDrawer,
    hideSubDrawer,
    subDrawer,
    showSubEditDrawer,
    subPurpose,
    subEditDrawerId,
  } = useDrawer();
  const { mutateAsync: deleteSponsor } = useDeleteSponsor();

  const deleteSponsorHandler = (id: string) => {
    deleteSponsor(id)
      .then(() => {})
      .catch(() => {});
  };
  const editSponsorHandler = (id: string) => {
    changeSubDrawer(CREATE_CHALLENGE_DRAWER_STEPS.CREATE_SPONSOR, id);
  };

  const redirectToCreate = () => {
    changeSubDrawer(CREATE_CHALLENGE_DRAWER_STEPS.CREATE_SPONSOR);
  };

  const ignoreChanges = () => {
    onClose();
  };

  const saveChanges = () => {
    onSubmit();
  };

  return (
    <>
      <CreateSponsorForChallenge
        open={
          subDrawer === CREATE_CHALLENGE_DRAWER_STEPS.CREATE_SPONSOR ||
          (showSubEditDrawer &&
            subDrawer === CREATE_CHALLENGE_DRAWER_STEPS.CREATE_SPONSOR)
        }
        onClose={hideSubDrawer}
        purpose={subPurpose}
        marathonId={marathonId}
        id={subEditDrawerId}
      />
      <Drawer maxWidth='50vw' open={open} onClose={ignoreChanges}>
        <DrawerHeader>
          <ModalTitle title='Спонсоры' />
          <CloseIcon onClick={ignoreChanges} />
        </DrawerHeader>
        <StyledSponsorsContainer>
          {sponsors.map(sponsor => (
            <StyledSponsor key={sponsor.id}>
              <StyledFlexBox>
                <img src={sponsor.logo} alt={sponsor.name} className='photo' />
                <Box className='name'>{sponsor.name}</Box>
              </StyledFlexBox>
              <StyledFlexBox className='editDeleteIcons'>
                <EditIcon onClick={() => editSponsorHandler(sponsor.id)} />
                <DeleteIcon onClick={() => deleteSponsorHandler(sponsor.id)} />
              </StyledFlexBox>
            </StyledSponsor>
          ))}
          <Button
            startIcon={<PlusIcon color={COLORS.BLUE} />}
            onClick={redirectToCreate}
            color='customLightBlue'
            className='addButton'
            size='large'
          >
            Добавить спонсора
          </Button>
        </StyledSponsorsContainer>
        <StyledFlexBox className='buttons fullDivWidth'>
          <Button
            variant='contained'
            size='large'
            color='customGray'
            onClick={goPreviousStep}
          >
            Назад
          </Button>
          <Button
            variant='contained'
            size='large'
            color='primary'
            onClick={saveChanges}
          >
            Создать
          </Button>
        </StyledFlexBox>
      </Drawer>
    </>
  );
};
