import { FC, useContext, useMemo } from 'react';
import { Grid } from '@mui/material';
import {
  AnalyticsCard,
  AnalyticsCardsContainer,
  AnalyticsListCard,
  AnalyticsGenderCard,
  AnalyticsAgeCard,
  AnalyticsInnerPageContainer,
  AnalyticsLineChartCard,
} from 'components';
import { IPhoneIcon, AndroidIcon, FinishIcon, TimerIcon } from 'assets';
import {
  COLORS,
  FilterContext,
  DEFAULT_WORKOUTS_CARD,
  DEFAULT_GENDER_CARD,
} from 'utils';
import { ROUTES } from 'app';
import {
  useGetAnalyticsWorkoutsData,
  useGetAnalyticsWorkoutsTopListTraining,
  useGetAnalyticsWorkoutsGender,
  useGetAnalyticsWorkoutsAge,
  useGetAnalyticsWorkoutsGraph,
} from 'hooks';

export const WorkoutsAnalyticsBody: FC = () => {
  const { startDate, endDate } = useContext(FilterContext);

  const paramsToGetAnalytics = useMemo(() => {
    return {
      start_date: startDate,
      end_date: endDate,
    };
  }, [startDate, endDate]);

  const {
    data: {
      trainings: {
        current_period: trainings_current,
        previous_period: trainings_previous,
        difference: trainings_difference,
        percentage: trainings_percent,
      },
      devices: {
        ios: {
          current_period: ios_devices_current,
          // previous_period: ios_devices_previous,
          difference: ios_devices_difference,
          percentage: ios_devices_percent,
        },
        android: {
          current_period: android_devices_current,
          // previous_period: android_devices_previous,
          difference: android_devices_difference,
          percentage: android_devices_percent,
        },
      },
    } = {
      trainings: {
        current_period: DEFAULT_WORKOUTS_CARD,
        previous_period: DEFAULT_WORKOUTS_CARD,
        difference: 0,
        percentage: {
          for_total: 0,
          for_success: 0,
        },
      },
      devices: {
        ios: {
          current_period: DEFAULT_WORKOUTS_CARD,
          previous_period: DEFAULT_WORKOUTS_CARD,
          difference: 0,
          percentage: {
            for_total: 0,
            for_success: 0,
          },
        },
        android: {
          current_period: DEFAULT_WORKOUTS_CARD,
          previous_period: DEFAULT_WORKOUTS_CARD,
          difference: 0,
          percentage: {
            for_total: 0,
            for_success: 0,
          },
        },
      },
    },
  } = useGetAnalyticsWorkoutsData(paramsToGetAnalytics);

  const {
    data: { male, female } = {
      male: DEFAULT_GENDER_CARD,
      female: DEFAULT_GENDER_CARD,
    },
  } = useGetAnalyticsWorkoutsGender(paramsToGetAnalytics);

  const { data: { data: topListWorkouts } = { data: [] } } =
    useGetAnalyticsWorkoutsTopListTraining(paramsToGetAnalytics);

  const { data: ageCardItems = [] } =
    useGetAnalyticsWorkoutsAge(paramsToGetAnalytics);
  const {
    data: {
      data: graphData,
      count: graphTotal,
      difference: graphDifference,
    } = {
      data: [],
      count: 0,
      difference: 0,
    },
  } = useGetAnalyticsWorkoutsGraph(paramsToGetAnalytics);

  return (
    <AnalyticsInnerPageContainer>
      <AnalyticsCardsContainer>
        <Grid xs={6} lg={4} xl={3} item>
          <AnalyticsCard
            icon={TimerIcon}
            title='Всего тренировок'
            current_data={trainings_current?.total_count}
            difference={
              trainings_current.total_count - trainings_previous.total_count
            }
            percent={trainings_percent?.for_total}
          />
        </Grid>
        <Grid xs={6} lg={4} xl={3} item>
          <AnalyticsCard
            icon={FinishIcon}
            title='Завершенные тренировки'
            current_data={trainings_current?.success_count}
            difference={trainings_difference}
            percent={trainings_percent?.for_success}
          />
        </Grid>
        <Grid xs={6} lg={4} xl={3} item>
          <AnalyticsCard
            icon={IPhoneIcon}
            title='iOS устройств'
            current_data={ios_devices_current?.success_count}
            difference={ios_devices_difference}
            percent={ios_devices_percent?.for_success}
          />
        </Grid>
        <Grid xs={6} lg={4} xl={3} item>
          <AnalyticsCard
            icon={AndroidIcon}
            title='Android устройств'
            current_data={android_devices_current?.success_count}
            difference={android_devices_difference}
            percent={android_devices_percent?.for_success}
          />
        </Grid>
      </AnalyticsCardsContainer>
      <AnalyticsCardsContainer>
        <Grid xs={6} item>
          <AnalyticsListCard
            title='Топ запущеных'
            link={`${ROUTES.analytics.full}?sub=workouts&tab=training`}
            listItems={topListWorkouts}
          />
        </Grid>
        <Grid xs={6} item>
          {/* <AnalyticsListCard
            title='Топ по пространствам'
            link={`${ROUTES.analytics.full}?sub=workouts&tab=space`}
            listItems={topListSpaces}
          /> */}
        </Grid>
      </AnalyticsCardsContainer>
      <AnalyticsCardsContainer>
        <Grid xs={4} item>
          <AnalyticsGenderCard male={male} female={female} />
        </Grid>
        <Grid xs={4} item>
          <AnalyticsLineChartCard
            title='Поделились'
            value={graphTotal}
            changeInValue={graphDifference}
            xAxisData={graphData?.map(item => item.date) || []}
            yAxisData={graphData?.map(item => item.share_count) || []}
            color={COLORS.RED}
          />
        </Grid>
        <Grid xs={4} item>
          <AnalyticsAgeCard listItems={ageCardItems} />
        </Grid>
      </AnalyticsCardsContainer>
    </AnalyticsInnerPageContainer>
  );
};
