import { Box } from '@mui/material';
import { styled } from '@mui/system';

export const StyledBody = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '0.625rem',
  width: '100%',
  position: 'relative',
  height: 'calc(100vh - 11rem)',
  overflowY: 'auto',
});

export const AddButtonContainer = styled(Box)({
  width: '100%',
  '& button': {
    width: '100%',
  },
});


