import { FC } from 'react';
import { Button, PageHeader, GlobalSearch } from 'UI';
import { PlusIcon } from 'assets';

interface ISpaceHeaderProps {
  openAddSpaceModal: () => void;
}

export const SpacesHeader: FC<ISpaceHeaderProps> = ({ openAddSpaceModal }) => {
  return (
    <PageHeader title='Пространства'>
      <GlobalSearch size='medium' />
      <Button
        variant='contained'
        onClick={openAddSpaceModal}
        startIcon={<PlusIcon />}
      >
        Добавить
      </Button>
    </PageHeader>
  );
};
