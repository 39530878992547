import { FC, useState, useMemo, useContext, MouseEvent } from 'react';
import { TableBody } from '@mui/material';

import { Pagination } from 'UI';
import {
  useSearchParams,
  useChangeStatus,
  useGetBanners,
  useDeleteBanner,
  useUpdateBannerPosition,
} from 'hooks';
import { BannerSortParams, TableRowsOrder, IBanner } from 'types';
import { DeleteModal } from 'components';
import { UserContextType, UserContext } from 'app';

import {
  StyledBox,
  StyledPaper,
  StyledTableContainer,
  StyledTable,
  StyledAvatar,
  StyledProductName,
  StyledProductInfoAtModal,
} from './styledComponents';
import { BannersTableHead } from './BannersTableHead';
import { BannersTableRow } from './BannersTableRow';

export const BannersTable: FC = () => {
  const { space_id } = useContext(UserContext) as UserContextType;
  const [order, setOrder] = useState<TableRowsOrder>('desc');
  const [orderBy, setOrderBy] = useState<BannerSortParams>('created_at');
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [selectedProduct, setSelectedProduct] = useState<IBanner | undefined>(
    undefined,
  );
  const { getParam, setParam, searchParams } = useSearchParams();

  const params = useMemo(() => {
    return {
      page: getParam('page') || 1,
      search: getParam('search'),
      column: orderBy,
      sort_type: order,
    };
  }, [searchParams, order, orderBy]);

  const {
    data: { data: bannersList, last_page: pageCount } = {
      data: [],
      last_page: 1,
    },
  } = useGetBanners(params);
  const { mutateAsync: deleteBanner } = useDeleteBanner();
  const { mutateAsync: changeStatus } = useChangeStatus('banner');
  const { mutateAsync: updateBannersPosition } = useUpdateBannerPosition();

  const updateRow = (row: IBanner) => {
    if (!row.id) return;
    changeStatus({
      space_id: space_id!,
      model_name: 'banner',
      model_id: row.id,
      state: !row.is_active,
    })
      .then(() => {})
      .catch(() => {});
  };

  const deleteButtonHandler = (row: IBanner) => {
    setSelectedProduct(row);
    setOpenDeleteModal(true);
  };

  const deleteRow = () => {
    if (!selectedProduct) return;
    deleteBanner(selectedProduct.id)
      .then(() => {})
      .catch(() => {});
    setOpenDeleteModal(false);
  };

  const cancelDeleteRow = () => {
    setOpenDeleteModal(false);
    setSelectedProduct(undefined);
  };

  const handleRequestSort = (
    _event: MouseEvent<unknown>,
    property: BannerSortParams,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const openEditDrawer = (id: string | number) => {
    setParam('edit', id);
  };

  const moveRow = (dragPosition: number, dropPosition: number) => {
    const banners = bannersList.map(banner => ({
      id: banner.id,
      position: banner.position,
    }));
    const draggedBanner = banners.find(
      banner => banner.position === dragPosition,
    );
    const droppedBanner = banners.find(
      banner => banner.position === dropPosition,
    );

    if (!draggedBanner || !droppedBanner) return;

    const filteredBanners = banners.filter(
      banner =>
        +banner.id !== +draggedBanner.id && +banner.id !== +droppedBanner.id,
    );

    updateBannersPosition({
      banners: [
        ...filteredBanners,
        { id: draggedBanner!.id, position: dropPosition },
        { id: droppedBanner!.id, position: dragPosition },
      ],
    })
      .then(() => {})
      .catch(() => {});
  };

  return (
    <StyledBox>
      <DeleteModal
        open={openDeleteModal}
        title='Удалить баннер'
        description='Вы действительно хотите удалить баннер?'
        onConfirm={deleteRow}
        onClose={cancelDeleteRow}
      >
        <StyledProductInfoAtModal>
          <StyledAvatar>
            <img
              src={selectedProduct?.cover as string | undefined}
              alt={selectedProduct?.title}
            />
          </StyledAvatar>
          <StyledProductName>{selectedProduct?.title}</StyledProductName>
        </StyledProductInfoAtModal>
      </DeleteModal>
      <StyledPaper>
        <StyledTableContainer>
          <StyledTable
            aria-labelledby='bannersTable'
            size='medium'
            stickyHeader
          >
            <BannersTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {bannersList.map(row => {
                return (
                  <BannersTableRow
                    key={row.id}
                    row={row}
                    openEditDrawer={openEditDrawer}
                    deleteButtonHandler={deleteButtonHandler}
                    updateRow={updateRow}
                    moveRow={moveRow}
                  />
                );
              })}
            </TableBody>
          </StyledTable>
        </StyledTableContainer>
        <Pagination count={pageCount} />
      </StyledPaper>
    </StyledBox>
  );
};
