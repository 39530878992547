import { FC, ReactNode } from 'react';
import { styled } from '@mui/system';
import { Box } from '@mui/material';

const StyledNewsLayout = styled(Box)({
  width: '100%',
  minHeight: '100vh',
  padding: '2.5rem 3.75rem',
});

interface INewsLayoutProps {
  children: ReactNode;
}

export const NewsLayout: FC<INewsLayoutProps> = ({ children }) => {
  return <StyledNewsLayout>{children}</StyledNewsLayout>;
};
