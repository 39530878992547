import { FC } from 'react';
import { COLORS } from 'utils';

interface IWifiIconProps {
  color?: string;
}

export const WifiIcon: FC<IWifiIconProps> = ({ color = COLORS.WHITE }) => {
  return (
    <svg
      width='12'
      height='9'
      viewBox='0 0 12 9'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.23331 2.47615C7.82326 2.47622 9.3524 3.08662 10.5047 4.1812C10.5915 4.26571 10.7302 4.26464 10.8156 4.17881L11.6451 3.34242C11.6883 3.29888 11.7125 3.23992 11.7121 3.17856C11.7118 3.11721 11.6869 3.05853 11.6432 3.01551C8.61875 0.119506 3.84739 0.119506 0.822984 3.01551C0.779173 3.0585 0.754315 3.11716 0.753911 3.17851C0.753507 3.23987 0.77759 3.29885 0.820831 3.34242L1.65052 4.17881C1.73593 4.26477 1.87473 4.26584 1.96145 4.1812C3.11389 3.08655 4.64321 2.47614 6.23331 2.47615ZM6.23447 5.19674C7.10804 5.19668 7.95044 5.52111 8.59798 6.10697C8.68556 6.19012 8.82352 6.18832 8.9089 6.10291L9.73739 5.26652C9.78102 5.22264 9.80523 5.16313 9.80461 5.10128C9.80398 5.03944 9.77856 4.98043 9.73405 4.93745C7.76216 3.10475 4.70845 3.10475 2.73657 4.93745C2.69202 4.98043 2.66661 5.03947 2.66603 5.10133C2.66544 5.1632 2.68973 5.22271 2.73346 5.26652L3.56171 6.10291C3.64709 6.18832 3.78505 6.19012 3.87264 6.10697C4.51975 5.52149 5.36147 5.1971 6.23447 5.19674ZM7.89265 7.02799C7.89392 7.09001 7.86951 7.1498 7.8252 7.19325L6.39208 8.6383C6.35007 8.68077 6.29279 8.70467 6.23303 8.70467C6.17327 8.70467 6.11599 8.68077 6.07398 8.6383L4.64062 7.19325C4.59634 7.14977 4.57198 7.08996 4.57329 7.02794C4.57461 6.96592 4.60147 6.90719 4.64755 6.86562C5.5628 6.09216 6.90326 6.09216 7.81851 6.86562C7.86456 6.90723 7.89138 6.96597 7.89265 7.02799Z'
        fill={color}
      />
    </svg>
  );
};
