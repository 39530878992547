import { FC } from 'react';
import { Box } from '@mui/material';
import { UserImage } from 'UI';
import { IMarathonParticipantUser } from 'types';

interface IWinnerInfoUserProps {
  winner: IMarathonParticipantUser;
}

export const WinnerInfoUser: FC<IWinnerInfoUserProps> = ({ winner }) => {
  return (
    <Box className='winnerInfo'>
      <Box className='photo'>
        <UserImage
          src={winner.photo}
          alt={winner.firstname}
          firstName={winner.firstname}
          lastName={winner.lastname}
          width='2.5rem'
          height='2.5rem'
        />
      </Box>
      <Box className='name'>
        {winner.firstname} {winner.lastname}
      </Box>
    </Box>
  );
};
