import { FC, useState, useEffect } from 'react';
import { styled } from '@mui/system';
import { TextField, FormControlLabel } from '@mui/material';
import {
  Drawer,
  DrawerHeader,
  DrawerFormBody,
  ModalTitle,
  Button,
  StyledFlexBox,
  FormItem,
  StyledSwitch,
  HtmlEditor,
} from 'UI';
import { CloseIcon } from 'assets';
import { COLORS } from 'utils';
import { IQuestionAnswerReq } from 'types';
import { useCreateQA, useUpdateQA, useGetQA } from 'hooks';

// TODO: this is duplicate
const StyledSwitchFormControl = styled(FormControlLabel)({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  borderBottom: `1px solid ${COLORS.BLACK}`,
  margin: 0,
  paddingBottom: '0.375rem',
  '& .MuiFormControlLabel-label': {
    marginTop: '0.375rem',
    fontSize: '1rem',
    lineHeight: '1.5rem',
  },
});

const TRANSLATIONS = {
  add: {
    title: 'Новый вопрос-ответ',
  },
  edit: {
    title: 'Редактировать вопрос-ответ',
  },
};

const DEFAULT_VALUES = {
  position: 0,
  question: '',
  answer_html: '',
  answer_json: '',
  is_active: true,
};

interface IAddEditQADrawerProps {
  open: boolean;
  onClose: () => void;
  purpose: 'add' | 'edit';
  id: string;
}

export const AddEditQADrawer: FC<IAddEditQADrawerProps> = ({
  open,
  onClose,
  purpose = 'add',
  id = '',
}) => {
  const { data: qaInfoData } = useGetQA(id);
  const [qaInfo, setQAInfo] = useState<IQuestionAnswerReq>(DEFAULT_VALUES);

  const { mutateAsync: createQA } = useCreateQA();
  const { mutateAsync: updateQA } = useUpdateQA();

  useEffect(() => {
    if (purpose === 'edit') {
      setQAInfo({
        id: qaInfoData?.id,
        is_active: !!qaInfoData?.is_active,
        position: qaInfoData?.position || 0,
        question: qaInfoData?.question || '',
        answer_html: qaInfoData?.answer_html || '',
        answer_json: qaInfoData?.answer_json || '',
      });
    } else {
      setQAInfo(DEFAULT_VALUES);
    }
  }, [purpose, qaInfoData]);

  const handleFieldChange = <T extends keyof IQuestionAnswerReq>(
    fieldName: T,
    newValue: IQuestionAnswerReq[T],
  ) => {
    setQAInfo(prevqaInfo => ({
      ...prevqaInfo,
      [fieldName]: newValue,
    }));
  };

  const ignoreChanges = () => {
    setQAInfo(DEFAULT_VALUES);
    onClose();
  };

  const saveChanges = () => {
    const submittedInfo = {
      ...qaInfo,
      answer_json: qaInfo.answer_html,
    };
    if (purpose === 'add') {
      createQA(submittedInfo)
        .then(() => {
          onClose();
        })
        .catch(() => {});
    } else {
      updateQA(submittedInfo)
        .then(() => {
          onClose();
        })
        .catch(() => {});
    }
  };

  return (
    <Drawer open={open} onClose={ignoreChanges}>
      <DrawerHeader>
        <ModalTitle title={TRANSLATIONS[purpose].title} />
        <CloseIcon onClick={ignoreChanges} />
      </DrawerHeader>
      <DrawerFormBody>
        <h2>О вопросе</h2>
        <FormItem label='Вопрос'>
          <TextField
            name='question'
            variant='standard'
            color='primary'
            placeholder='Введите вопрос'
            value={qaInfo.question}
            onChange={e => handleFieldChange('question', e.target.value)}
          />
        </FormItem>
        <FormItem label='Ответ'>
          <HtmlEditor
            value={qaInfo.answer_html}
            onChange={newValue => handleFieldChange('answer_html', newValue)}
          />
        </FormItem>
        <FormItem label='Позиция'>
          <TextField
            variant='standard'
            color='primary'
            placeholder='Укажите позицию'
            name='position'
            value={qaInfo.position}
            type='number'
            onChange={e =>
              handleFieldChange('position', e.target.value as unknown as number)
            }
          />
        </FormItem>
        <StyledSwitchFormControl
          label='Статус'
          labelPlacement='start'
          sx={{ marginBottom: '1.25rem' }}
          control={
            <StyledSwitch
              checked={qaInfo.is_active}
              onChange={() => handleFieldChange('is_active', !qaInfo.is_active)}
            />
          }
        />
      </DrawerFormBody>
      <StyledFlexBox className='buttons fullDivWidth'>
        <Button
          onClick={ignoreChanges}
          variant='contained'
          size='large'
          color='customGray'
        >
          Отмена
        </Button>
        <Button variant='contained' size='large' onClick={saveChanges}>
          Сохранить
        </Button>
      </StyledFlexBox>
    </Drawer>
  );
};
