import { FC, MouseEvent } from 'react';
import { TableHead, TableRow, TableCell, TableSortLabel } from '@mui/material';
import { COLORS, BannerSortColumnValues } from 'utils';
import {
  IBanner,
  IHeadCell,
  BannerSortParams,
  ITableHeadPropsWithSort,
} from 'types';

const headCells: readonly IHeadCell<IBanner>[] = [
  {
    id: 'position',
    label: 'Порядок',
    sortable: true,
  },
  {
    id: 'title',
    label: 'Заголовок',
    sortable: true,
  },
  {
    id: 'link',
    label: 'Ссылка',
    sortable: false,
  },
  {
    id: 'created_at',
    label: 'Дата создания',
    sortable: true,
  },
  {
    id: 'actions',
    label: 'Управление',
    sortable: false,
  },
];

export const BannersTableHead: FC<
  ITableHeadPropsWithSort<BannerSortParams>
> = ({ onRequestSort, order, orderBy }) => {
  const isValidSortParam = (param: string): param is BannerSortParams => {
    return BannerSortColumnValues.includes(param as BannerSortParams);
  };

  const createSortHandler =
    (property: keyof IBanner | 'actions') => (event: MouseEvent<unknown>) => {
      if (isValidSortParam(property)) {
        onRequestSort(event, property);
      }
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => {
          if (!headCell.sortable) {
            return (
              <TableCell key={headCell.id} align='center' padding='normal'>
                {headCell.label}
              </TableCell>
            );
          }
          return (
            <TableCell
              key={headCell.id}
              align='center'
              padding='normal'
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
                sx={{
                  '&.Mui-active': {
                    '& .MuiTableSortLabel-icon': {
                      color: orderBy === headCell.id ? COLORS.RED : undefined,
                    },
                  },
                  '& .MuiTableSortLabel-icon': {
                    opacity: 0.3,
                  },
                }}
              >
                {headCell.label}
              </TableSortLabel>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};
