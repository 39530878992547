import * as Pages from 'pages';
import { ROLES } from 'utils';
import { IRoutes } from '../types';

export const Teams: IRoutes = {
  teams: {
    path: '/teams',
    title: 'teams',
    element: <Pages.Teams />,
    roles: [ROLES.ADMINSTRATOR],
  },
};
