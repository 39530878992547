import { FC } from 'react';
import { COLORS } from 'utils';

interface IDropdownIconProps {
  color?: string;
  size?: 'big' | 'small';
}

export const DropdownIcon: FC<IDropdownIconProps> = ({
  color = COLORS.BLACK,
  size = 'small',
}) => {
  if (size === 'big') {
    return (
      <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M6 9L12 15L18 9'
          stroke={color}
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    );
  }
  return (
    <svg
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12.8691 6.60986L8.61803 10.861L4.36693 6.60986'
        stroke={color}
        strokeWidth='1.41704'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
