import { IWorkoutGetParams, IWorkoutReq, IReplaceWorkoutsReq } from 'types';
import { http, methods } from './config';

export const getDefaultWorkouts = () => {
  const config = {
    method: methods.get,
    url: `/type/training/custom`,
  };
  return http(config);
};

export const getCustomWorkouts = (params: IWorkoutGetParams) => {
  const config = {
    method: methods.get,
    url: `/training/custom/type`,
    params,
  };
  return http(config);
};

export const getCustomWorkout = (id: string | number) => {
  const config = {
    method: methods.get,
    url: `/training/custom/type/${id}`,
  };
  return http(config);
};

export const createCustomWorkout = (data: IWorkoutReq) => {
  const config = {
    method: methods.post,
    url: `/training/custom/type`,
    data,
  };
  return http(config);
};

export const updateCustomWorkout = (data: IWorkoutReq) => {
  const config = {
    method: methods.put,
    url: `/training/custom/type/${data.id}`,
    data,
  };
  return http(config);
};

export const deleteCustomWorkout = (workoutId: number) => {
  const config = {
    method: methods.delete,
    url: `/training/custom/type/${workoutId}`,
  };
  return http(config);
};

export const replaceWorkouts = (data: IReplaceWorkoutsReq) => {
  const config = {
    method: methods.patch,
    url: `/update_positions/training_custom`,
    data,
  };
  return http(config);
};
