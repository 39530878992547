import { IPartnerGetParams, IPartnerReq } from 'types';
import { http, methods } from './config';

export const getPartners = (params: IPartnerGetParams) => {
  const config = {
    method: methods.get,
    url: `/partner`,
    params,
  };
  return http(config);
};

export const getPartner = (partnerId: string | number) => {
  const config = {
    method: methods.get,
    url: `/partner/${partnerId}`,
  };
  return http(config);
};

export const createPartner = (data: IPartnerReq) => {
  const config = {
    method: methods.post,
    url: `/partner`,
    data,
  };
  return http(config);
};

export const updatePartner = (data: IPartnerReq) => {
  const config = {
    method: methods.put,
    url: `/partner/${data.id}`,
    data,
  };
  return http(config);
};

export const deletePartner = (partnerId: number | string) => {
  const config = {
    method: methods.delete,
    url: `/partner/${partnerId}`,
  };
  return http(config);
};
