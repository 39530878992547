import { FC, useState } from 'react';

import { useDrawer } from 'hooks';
import {
  Layout,
  StandardsHeader,
  StandardsTable,
  AddEditStandardDrawer,
  ParametersDrawer,
} from 'components';

export const Standards: FC = () => {
  const {
    openAddDrawer,
    showAddEditDrawer,
    hideAddEditDrawer,
    editDrawerId,
    purpose,
  } = useDrawer();
  const [openParametersDrawer, setOpenParametersDrawer] =
    useState<boolean>(false);

  const showParametersDrawer = () => {
    setOpenParametersDrawer(true);
  };

  const hideParametersDrawer = () => {
    setOpenParametersDrawer(false);
  };

  return (
    <Layout>
      <AddEditStandardDrawer
        open={showAddEditDrawer}
        onClose={hideAddEditDrawer}
        id={editDrawerId}
        purpose={purpose}
      />
      <ParametersDrawer
        open={openParametersDrawer}
        onClose={hideParametersDrawer}
      />
      <StandardsHeader
        openAddStandardDrawer={openAddDrawer}
        openParametersDrawer={showParametersDrawer}
      />
      <StandardsTable />
    </Layout>
  );
};
