import React, { FC, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { COLORS, setItem, getItem, removeItem } from 'utils';

interface ITimer {
  onTimerComplete: () => void;
}

export const Timer: FC<ITimer> = ({ onTimerComplete }) => {
  const [timeRemaining, setTimeRemaining] = useState(60);

  useEffect(() => {
    const storedTime = getItem('timer');
    const savedTimestamp = getItem('timestamp');
    if (storedTime && savedTimestamp) {
      const elapsedTime = Math.floor((Date.now() - +savedTimestamp) / 1000);
      const remainingTime = +storedTime - elapsedTime;
      if (remainingTime > 0) {
        setTimeRemaining(remainingTime);
      } else {
        setTimeRemaining(0);
      }
    }

    const timerId = setInterval(() => {
      setTimeRemaining(prevTime => {
        if (prevTime > 0) {
          setItem('timer', (prevTime - 1).toString());
          return prevTime - 1;
        }
        removeItem('timer');
        clearInterval(timerId);
        return 0;
      });
    }, 1000);

    const handleBeforeUnload = () => {
      setItem('timestamp', Date.now().toString());
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      clearInterval(timerId);
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    if (timeRemaining === 0) {
      removeItem('timer');
      removeItem('timestamp');
      onTimerComplete();
    }
  }, [timeRemaining, onTimerComplete]);

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60)
      .toString()
      .padStart(2, '0');
    const seconds = (time % 60).toString().padStart(2, '0');
    return `${minutes}:${seconds}`;
  };

  return <Box sx={{ color: COLORS.BLUE }}>{formatTime(timeRemaining)}</Box>;
};
