import { FC, useState, useEffect } from 'react';
import { Box, TextField } from '@mui/material';
import { BannerType, BannerPlaceType, IBannerReq } from 'types';
import { DeleteModal } from 'components';
import {
  useGetBanner,
  useCreateBanner,
  useUpdateBanner,
  useDeleteBanner,
} from 'hooks';
import { CloseIcon, DeleteIcon, LinkIcon2 as LinkIcon } from 'assets';
import {
  Drawer,
  DrawerHeader,
  ModalTitle,
  Button,
  ImageInput,
  StyledSwitch,
  FormItem,
  DrawerFormBody,
  StyledFlexBox,
  SearchSelectInput,
} from 'UI';
import { MEDIA_TYPES, COLORS } from 'utils';
import { ChoosePlace } from './ChoosePlace';
import {
  StyledProductInfoAtModal,
  StyledAvatar,
  StyledProductName,
} from './styledComponents';

const TRANSLATIONS = {
  add: {
    title: 'Новый баннер',
  },
  edit: {
    title: 'Редактировать баннер',
  },
};

const TYPE_OPTIONS = [
  {
    value: 0,
    label: 'На главную',
  },
  {
    value: 1,
    label: 'В вызов',
  },
  {
    value: 2,
    label: 'Витрина',
  },
];

const PLACE_TYPE_OPTIONS = [
  {
    value: 0,
    label: 'Витрина',
  },
  {
    value: 1,
    label: 'Вызов',
  },
  {
    value: 2,
    label: 'Ссылка',
  },
  {
    value: 3,
    label: 'Новость',
  },
];

const DEFAULT_VALUES: IBannerReq = {
  title: '',
  cover: '',
  type: undefined,
  place_type: undefined,
  showcase_id: null,
  marathon_space_id: null,
  news_id: null,
  link: '',
  is_active: true,
  position: 0,
};

interface IAddEditBannerDrawerProps {
  open: boolean;
  onClose: () => void;
  purpose?: 'add' | 'edit';
  id?: number | string;
}

export const AddEditBannerDrawer: FC<IAddEditBannerDrawerProps> = ({
  open,
  onClose,
  purpose = 'add',
  id = '',
}) => {
  const { data: bannerInfoData } = useGetBanner(id);
  const [bannerInfo, setBannerInfo] = useState<IBannerReq>(DEFAULT_VALUES);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const { mutateAsync: createBanner } = useCreateBanner();
  const { mutateAsync: updateBanner } = useUpdateBanner();
  const { mutateAsync: deleteBanner } = useDeleteBanner();

  useEffect(() => {
    if (purpose === 'edit') {
      if (bannerInfoData) {
        setBannerInfo(bannerInfoData);
      }
    } else {
      setBannerInfo(DEFAULT_VALUES);
    }
  }, [bannerInfoData, purpose]);

  const handleFieldChange = <T extends keyof IBannerReq>(
    fieldName: T,
    newValue: IBannerReq[T],
  ) => {
    setBannerInfo(prevBannerInfo => ({
      ...prevBannerInfo,
      [fieldName]: newValue,
    }));
  };

  const saveChanges = () => {
    if (purpose === 'add') {
      createBanner(bannerInfo)
        .then(() => {
          onClose();
        })
        .catch(() => {});
    } else {
      updateBanner(bannerInfo)
        .then(() => {
          onClose();
        })
        .catch(() => {});
    }
  };

  const ignoreChanges = () => {
    setBannerInfo(DEFAULT_VALUES);
    onClose();
  };

  const openDeleteModal = () => {
    setShowDeleteModal(true);
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const handleDeleteBanner = () => {
    deleteBanner(id)
      .then(() => {
        closeDeleteModal();
        onClose();
      })
      .catch(() => {});
  };

  const choosePlace = (placeId: string | number) => {
    if (bannerInfo.place_type === 0) {
      handleFieldChange('showcase_id', placeId);
    }
    if (bannerInfo.place_type === 1) {
      handleFieldChange('marathon_space_id', placeId);
    }
    if (bannerInfo.place_type === 3) {
      handleFieldChange('news_id', placeId);
    }
  };

  return (
    <Drawer open={open} onClose={onClose}>
      <DeleteModal
        open={showDeleteModal}
        onConfirm={handleDeleteBanner}
        onClose={closeDeleteModal}
        title='Удалить баннер'
        description='Вы действительно хотите удалить баннер?'
      >
        <StyledProductInfoAtModal>
          <StyledAvatar>
            <img
              src={bannerInfo?.cover as string | undefined}
              alt={bannerInfo?.title}
            />
          </StyledAvatar>
          <StyledProductName>{bannerInfo?.title}</StyledProductName>
        </StyledProductInfoAtModal>
      </DeleteModal>
      <DrawerHeader>
        <ModalTitle title={TRANSLATIONS[purpose].title} />
        <StyledFlexBox>
          <StyledSwitch
            checked={bannerInfo.is_active}
            onChange={() =>
              handleFieldChange('is_active', !bannerInfo.is_active)
            }
            className='switch'
          />
          <CloseIcon onClick={ignoreChanges} />
        </StyledFlexBox>
      </DrawerHeader>
      <DrawerFormBody>
        <StyledFlexBox>
          <ImageInput
            inputId='bannerCover'
            title='Добавьте обложку'
            descriptionWithoutImage='Загрузите изображение
            (1125x575 px, до 1 мб)'
            withDescription
            image={bannerInfo.cover}
            setImage={newImg => handleFieldChange('cover', newImg)}
            style={{ width: '70%' }}
            mediaType={MEDIA_TYPES.BANNERS}
            imageFixedWidth={1125}
            imageFixedHeight={575}
            placeholderSize='big'
          />
        </StyledFlexBox>
        <h2>О баннере</h2>
        <FormItem label='Название'>
          <TextField
            variant='standard'
            color='primary'
            placeholder='Введите текст'
            name='title'
            value={bannerInfo.title}
            onChange={e => handleFieldChange('title', e.target.value)}
          />
        </FormItem>
        <FormItem label='Тип баннера'>
          <SearchSelectInput
            options={TYPE_OPTIONS}
            value={bannerInfo.type}
            onChange={newValue =>
              handleFieldChange('type', newValue as BannerType)
            }
            placeholder='Не выбран'
            variant='standard'
          />
        </FormItem>
        <FormItem label='Позиция'>
          <TextField
            variant='standard'
            color='primary'
            placeholder='Введите позицию'
            name='position'
            value={bannerInfo.position}
            onChange={e =>
              handleFieldChange('position', e.target.value as unknown as number)
            }
          />
        </FormItem>
        <FormItem label='Переход по клику'>
          <SearchSelectInput
            options={PLACE_TYPE_OPTIONS}
            value={bannerInfo.place_type}
            onChange={newValue =>
              handleFieldChange('place_type', newValue as BannerPlaceType)
            }
            placeholder='Не выбран'
            variant='standard'
          />
        </FormItem>
        {(bannerInfo.place_type === 0 ||
          bannerInfo.place_type === 1 ||
          bannerInfo.place_type === 3) && (
          <ChoosePlace
            placeType={bannerInfo.place_type}
            choosePlace={choosePlace}
            defaultSelectedPlace={
              bannerInfo.place_type === 0
                ? bannerInfo.showcase
                : bannerInfo.place_type === 1
                ? bannerInfo.marathon_space
                : bannerInfo.news
            }
          />
        )}
        {bannerInfo.place_type === 2 && (
          <FormItem label='Ссылка'>
            <TextField
              variant='standard'
              color='primary'
              placeholder='Введите ссылку'
              name='link'
              value={bannerInfo.link}
              InputProps={{
                startAdornment: <LinkIcon style={{ marginRight: '0.75rem' }} />,
              }}
              onChange={e => handleFieldChange('link', e.target.value)}
            />
          </FormItem>
        )}
      </DrawerFormBody>
      <StyledFlexBox className='buttons fullDivWidth'>
        {purpose === 'edit' ? (
          <Button
            onClick={openDeleteModal}
            variant='contained'
            size='large'
            color='customGray'
            startIcon={<DeleteIcon color={COLORS.RED} />}
            sx={{
              color: COLORS.RED,
            }}
          >
            Удалить
          </Button>
        ) : (
          <Box />
        )}
        <Button variant='contained' size='large' onClick={saveChanges}>
          Сохранить
        </Button>
      </StyledFlexBox>
    </Drawer>
  );
};
