import { FC, useMemo } from 'react';
import { TextField, Box } from '@mui/material';
import {
  FormItem,
  HtmlEditor,
  SearchSelectInput,
  DateInput,
  StyledFlexBox,
  StyledSwitch,
} from 'UI';
import { useGetLevelsList, useGetShowcaseCategoriesList } from 'hooks';
import { IShowcaseOfferDiscountReq } from 'types';
import { StarIcon } from 'assets';

import { MEDIA_TYPES } from 'utils';
import { TRANSLATIONS, TYPE_OPTIONS } from '../constants';
import { StyledSwitchFormControl } from '../styledComponents';

interface IDrawerFormFieldsProps {
  productInfo: IShowcaseOfferDiscountReq;
  handleFieldChange: <T extends keyof IShowcaseOfferDiscountReq>(
    _field: T,
    _value: IShowcaseOfferDiscountReq[T],
  ) => void;
}

export const DrawerFormFields: FC<IDrawerFormFieldsProps> = ({
  productInfo,
  handleFieldChange,
}) => {
  const { data: categoriesList } = useGetShowcaseCategoriesList();
  const { data: levelsList } = useGetLevelsList();

  const categoryOptions = useMemo(() => {
    return (
      categoriesList?.map(category => ({
        label: category.title,
        value: +category.id,
      })) || []
    );
  }, [categoriesList]);

  const levelOptions = useMemo(() => {
    return (
      levelsList?.map(level => ({
        label: level.text,
        value: level.id,
      })) || []
    );
  }, [levelsList]);
  return (
    <>
      <h2>{TRANSLATIONS[productInfo.type].subtitle.title}</h2>
      <FormItem label='Название'>
        <TextField
          variant='standard'
          color='primary'
          placeholder='Введите название'
          name='title'
          value={productInfo.title}
          onChange={e => handleFieldChange('title', e.target.value)}
        />
      </FormItem>
      <FormItem label='Категория'>
        <SearchSelectInput
          options={categoryOptions}
          value={productInfo.category_id as number}
          onChange={newVal =>
            handleFieldChange('category_id', newVal as number)
          }
          placeholder='Выбрать категорию'
          variant='standard'
        />
      </FormItem>
      <FormItem label='Уровень'>
        <SearchSelectInput
          options={levelOptions}
          value={productInfo.required_level as number}
          onChange={newVal =>
            handleFieldChange('required_level', newVal as number)
          }
          placeholder='Выбрать уровень'
          variant='standard'
        />
      </FormItem>
      <FormItem label='Описание'>
        <HtmlEditor
          value={productInfo.description_html}
          onChange={newValue => handleFieldChange('description_html', newValue)}
          disabledToolbarOptions={[]}
          mediaType={MEDIA_TYPES.SHOWCASE_CONTENT}
        />
      </FormItem>
      {/* <TagAdder
          tags={productInfo.tags}
          updateTags={newTags => handleFieldChange('tags', newTags)}
          label='Ключевые слова'
          placeholder='Введите ключевое слово и нажмите Enter'
        /> */}
      {/* <StyledSwitchFormControl
        label='Показывать в слайдере'
        labelPlacement='start'
        sx={{
          opacity: productInfo.show_in_slider ? 1 : 0.5,
        }}
        control={
          <StyledSwitch
            checked={productInfo.show_in_slider}
            onChange={() =>
              handleFieldChange('show_in_slider', !productInfo.show_in_slider)
            }
          />
        }
      /> */}
      {/* {productInfo.show_in_slider && (
        <FormItem label='Позиция в слайдере'>
          <TextField
            variant='standard'
            color='primary'
            placeholder='Введите позицию'
            name='position_in_slider'
            value={productInfo.position_in_slider}
            onChange={e =>
              handleFieldChange(
                'position_in_slider',
                e.target.value as unknown as number,
              )
            }
          />
        </FormItem>
      )} */}
      <FormItem label='Позиция в листе'>
        <TextField
          variant='standard'
          color='primary'
          placeholder='Введите позицию'
          name='position_in_list'
          value={productInfo.position_in_list}
          onChange={e =>
            handleFieldChange(
              'position_in_list',
              e.target.value as unknown as number,
            )
          }
        />
      </FormItem>
      <FormItem label='Ссылка на сайт партнера'>
        <TextField
          variant='standard'
          color='primary'
          placeholder='Введите ссылку'
          name='link'
          value={productInfo.uri}
          onChange={e => handleFieldChange('uri', e.target.value)}
        />
      </FormItem>
      <FormItem label='Тип'>
        <SearchSelectInput
          options={TYPE_OPTIONS}
          value={productInfo.type as number}
          onChange={newVal => handleFieldChange('type', newVal as number)}
          placeholder='Выбрать тип'
          variant='standard'
        />
      </FormItem>
      <StyledFlexBox className='fullDivWidth'>
        <FormItem label='Цена'>
          <TextField
            variant='standard'
            color='primary'
            placeholder='Введите цену'
            name='price'
            value={productInfo.price}
            onChange={e =>
              handleFieldChange('price', e.target.value as unknown as number)
            }
            InputProps={{
              startAdornment: <StarIcon style={{ marginRight: '0.75rem' }} />,
            }}
          />
        </FormItem>
      </StyledFlexBox>
      <FormItem label='Произвольная скидка'>
        <TextField
          variant='standard'
          color='primary'
          placeholder='Введите текст'
          name='description'
          value={productInfo.discount_description}
          onChange={e =>
            handleFieldChange('discount_description', e.target.value)
          }
        />
      </FormItem>
      <StyledFlexBox className='fullDivWidth'>
        <FormItem label='Дата начала'>
          <DateInput
            key={productInfo.started_at}
            stringValue={productInfo.started_at}
            onChange={newValue => handleFieldChange('started_at', newValue)}
          />
        </FormItem>
        <Box />
      </StyledFlexBox>
      <StyledFlexBox
        className='fullDivWidth'
        style={{ alignItems: 'flex-end' }}
      >
        <FormItem label='Дата завершения'>
          <DateInput
            key={productInfo.expired_at}
            stringValue={productInfo.expired_at}
            onChange={newValue => handleFieldChange('expired_at', newValue)}
            disabled={productInfo.is_indefinite}
          />
        </FormItem>
        <StyledSwitchFormControl
          label='Бессрочно'
          labelPlacement='start'
          sx={{
            opacity: productInfo.is_indefinite ? 1 : 0.5,
            borderBottom: 'none',
            padding: 0,
          }}
          control={
            <StyledSwitch
              checked={productInfo.is_indefinite}
              onChange={() =>
                handleFieldChange('is_indefinite', !productInfo.is_indefinite)
              }
            />
          }
        />
      </StyledFlexBox>
    </>
  );
};
