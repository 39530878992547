import { FC, MouseEvent } from 'react';
import { TableRow, TableCell, TableSortLabel } from '@mui/material';
import {
  IShowcaseCategory,
  IHeadCell,
  ITableHeadPropsWithSort,
  ShowcaseCategorySortParams,
} from 'types';
import { ShowcaseCategorySortColumnValues, COLORS } from 'utils';

import { StyledTableHead } from './styledComponents';

const headCells: readonly IHeadCell<IShowcaseCategory>[] = [
  {
    id: 'id',
    label: 'ID',
    sortable: false,
  },
  {
    id: 'title',
    label: 'Название',
    sortable: true,
  },
  {
    id: 'position',
    label: 'Место',
    sortable: true,
  },
  {
    id: 'actions',
    label: 'Управление',
    sortable: false,
  },
];

export const ShowcaseCategoriesTableHead: FC<
  ITableHeadPropsWithSort<ShowcaseCategorySortParams>
> = ({ onRequestSort, order, orderBy }) => {
  const isValidSortParam = (
    param: string,
  ): param is ShowcaseCategorySortParams => {
    return ShowcaseCategorySortColumnValues.includes(
      param as ShowcaseCategorySortParams,
    );
  };

  const createSortHandler =
    (property: keyof IShowcaseCategory | 'actions') =>
    (event: MouseEvent<unknown>) => {
      if (isValidSortParam(property)) {
        onRequestSort(event, property);
      }
    };
  return (
    <StyledTableHead>
      <TableRow>
        {headCells.map(headCell => {
          if (!headCell.sortable) {
            return (
              <TableCell key={headCell.id} align='center' padding='normal'>
                {headCell.label}
              </TableCell>
            );
          }
          return (
            <TableCell
              key={headCell.id}
              align='center'
              padding='normal'
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
                sx={{
                  '&.Mui-active': {
                    '& .MuiTableSortLabel-icon': {
                      color: orderBy === headCell.id ? COLORS.RED : undefined,
                    },
                  },
                  '& .MuiTableSortLabel-icon': {
                    opacity: 0.3,
                  },
                }}
              >
                {headCell.label}
              </TableSortLabel>
            </TableCell>
          );
        })}
      </TableRow>
    </StyledTableHead>
  );
};
