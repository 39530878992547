import { useContext, useMemo } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { UserContext, UserContextType } from 'app';
import { getItem } from 'utils';
import { pageRouting } from './pageRouting';
import { IRoute } from './types';
import { ROUTES } from './routes';

export const Router = () => {
  const { role_id } = useContext(UserContext) as UserContextType;
  const token = getItem('token');

  const routesMap = Object.values(pageRouting);
  const isAuthenticated = useMemo(() => {
    return !!token;
  }, [token]);
  const getRouteElement = (
    element: JSX.Element,
    openRoute: boolean,
    roles: number[],
  ) => {
    // if user is not authenticated and route is blocked redirect to auth
    if (!openRoute && !isAuthenticated) {
      return <Navigate replace to={ROUTES.auth} />;
    }
    // if user is authenticated and route is open or user is not admin and route is admin only redirect to home
    if (
      (!openRoute && !roles.includes(role_id || 0)) ||
      (openRoute && isAuthenticated)
    ) {
      if (role_id === 2) {
        return <Navigate replace to={ROUTES.news} />;
      }
      return <Navigate replace to={ROUTES.home} />;
    }

    return element;
  };

  const generateRoute = ({
    title,
    path,
    element,
    index,
    childRoute,
    openRoute = false,
    roles = [],
  }: IRoute) => {
    if (childRoute) {
      return (
        <Route
          key={title}
          path={path}
          element={getRouteElement(element, openRoute, roles)}
        >
          {Object.values(childRoute).map(child => generateRoute(child))}
        </Route>
      );
    }
    return (
      <Route
        key={title}
        index={index}
        path={path}
        element={getRouteElement(element, openRoute, roles)}
      />
    );
  };

  const pageRoutes = routesMap.map(generateRoute);

  return <Routes>{pageRoutes}</Routes>;
};
