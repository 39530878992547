import { FC, useState, useMemo, MouseEvent, useContext } from 'react';
import { TableBody, TableCell } from '@mui/material';

import { UserContext, UserContextType } from 'app';
import { EditIcon, DeleteIcon } from 'assets';
import { Pagination, StyledSwitch } from 'UI';
import {
  useDeleteQA,
  useSearchParams,
  useChangeStatus,
  useGetQAs,
} from 'hooks';
import { TableRowsOrder, QASortParams, IQuestionAnswer } from 'types';
import { DeleteModal } from 'components';

import {
  StyledBox,
  StyledPaper,
  StyledTableContainer,
  StyledTable,
  StyledTableRow,
} from './styledComponents';
import { QATableHead } from './QATableHead';

export const QATable: FC = () => {
  const { space_id } = useContext(UserContext) as UserContextType;
  const [order, setOrder] = useState<TableRowsOrder>('asc');
  const [orderBy, setOrderBy] = useState<QASortParams>('question');
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [selectedQA, setSelectedQA] = useState<IQuestionAnswer | undefined>(
    undefined,
  );
  const { setParam, getParam, searchParams } = useSearchParams();

  const params = useMemo(() => {
    return {
      page: getParam('page'),
      search: getParam('search'),
      column: orderBy,
      sort_type: order,
    };
  }, [searchParams, orderBy, order]);

  const {
    data: { data: questionAnswers, last_page: pageCount } = {
      data: [],
      last_page: 1,
    },
  } = useGetQAs(params);
  const { mutateAsync: deleteQA } = useDeleteQA();
  const { mutateAsync: changeStatus } = useChangeStatus('faq');

  const updateRow = (row: IQuestionAnswer) => {
    changeStatus({
      space_id: space_id!,
      model_name: 'faq',
      model_id: row.id,
      state: !row.is_active,
    })
      .then(() => {})
      .catch(() => {});
  };

  const deleteButtonHandler = (row: IQuestionAnswer) => {
    setSelectedQA(row);
    setOpenDeleteModal(true);
  };

  const deleteRow = async () => {
    await deleteQA(selectedQA!.id).catch(() => {});
    setOpenDeleteModal(false);
  };

  const cancelDeleteRow = () => {
    setOpenDeleteModal(false);
    setSelectedQA(undefined);
  };

  const handleRequestSort = (
    _event: MouseEvent<unknown>,
    property: QASortParams,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const openEditDrawer = (id: string | number) => {
    setParam('edit', id.toString());
  };

  return (
    <StyledBox>
      <DeleteModal
        open={openDeleteModal}
        title='Удалить вопрос'
        description='Вы действительно хотите удалить вопрос?'
        onConfirm={deleteRow}
        onClose={cancelDeleteRow}
      />
      <StyledPaper>
        <StyledTableContainer>
          <StyledTable aria-labelledby='qaTable' size='medium' stickyHeader>
            <QATableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {questionAnswers.map(row => {
                return (
                  <StyledTableRow hover key={row.id}>
                    <TableCell
                      component='th'
                      id={row.id.toString()}
                      scope='row'
                      className='faqQuestion'
                      onClick={() => openEditDrawer(row.id)}
                    >
                      {row.question}
                    </TableCell>
                    <TableCell>
                      <div className='editDeleteIcons'>
                        <StyledSwitch
                          checked={row.is_active}
                          variant='customGray'
                          onChange={() => updateRow(row)}
                          style={{ opacity: 1 }}
                          className='switch'
                        />
                        <EditIcon onClick={() => openEditDrawer(row.id)} />
                        <DeleteIcon onClick={() => deleteButtonHandler(row)} />
                      </div>
                    </TableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </StyledTable>
        </StyledTableContainer>
        <Pagination count={pageCount} />
      </StyledPaper>
    </StyledBox>
  );
};
