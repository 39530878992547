import { FC, useMemo, useState, MouseEvent } from 'react';
import { useLocation } from 'react-router-dom';
import { ROUTES } from 'app';
import { PageHeader, Button } from 'UI';
import { AnalyticsFilter, CustomDateFilter } from 'components';

import { DropdownUpIcon, DropdownIcon } from 'assets';
import { StyledRightSideContainer } from './styledComponents';
import { ReportDownload } from './ReportDownload';

interface IAnalyticsHeaderProps {}

export const AnalyticsHeader: FC<IAnalyticsHeaderProps> = () => {
  const { pathname } = useLocation();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [showPopover, setShowPopover] = useState<boolean>(false);

  const showDownloadButton = useMemo(() => {
    return (
      pathname === ROUTES.analytics.news ||
      pathname === ROUTES.analytics.showcases ||
      pathname === ROUTES.analytics.users
    );
  }, [pathname]);

  const downloadBtnClickHandler = (event: MouseEvent<HTMLButtonElement>) => {
    handleOpen(event);
  };

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setShowPopover(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setShowPopover(false);
  };

  return (
    <PageHeader
      title='Аналитика'
      hasMiddleComponent
      middleComponent={<AnalyticsFilter />}
      style={{
        alignItems: 'flex-start',
        gap: '2rem',
      }}
    >
      <ReportDownload
        showPopover={showPopover}
        anchorEl={anchorEl}
        handleClose={handleClose}
      />
      <StyledRightSideContainer>
        <CustomDateFilter />
        {showDownloadButton && (
          <Button
            variant='contained'
            color='customDarkerGray'
            onClick={downloadBtnClickHandler}
            endIcon={
              !showPopover ? <DropdownIcon size='big' /> : <DropdownUpIcon />
            }
          >
            Отчет
          </Button>
        )}
      </StyledRightSideContainer>
    </PageHeader>
  );
};
