import { useState, useEffect, useMemo, useContext } from 'react';
import { useQuery } from 'react-query';
import {
  useSearchParams,
  useGetAnalyticsUsersTopLists,
  useGetAnalyticsWorkoutsTopListTraining,
  useGetAnalyticsWorkoutsTopListSpace,
  useGetAnalyticsChallengesTopList,
  useGetAnalyticsShowcaseTopDiscounts,
  useGetAnalyticsShowcaseTopCategories,
} from 'hooks';
import {
  TableRowsOrder,
  IFilterDateRes,
  AnalyticsUsersTopFilterParams,
  IAnalyticsUsersTopGetParams,
  IAnalyticsChallengesTopGetParams,
  AnalyticsChallengesTopFilterParamsAsString,
  AnalyticsChallengesTopFilterParams,
  IAnalyticsTopListGetParams,
  AnalyticsTopListOrderParams,
  IHeadCell,
  IAnalyticsFullTableRow,
} from 'types';
import { FilterContext } from 'utils';
import { getFilterDate } from 'api';

export const useAnalytics = () => {
  const [title, setTitle] = useState('');

  return {
    fullAnalyticsTitle: title,
    changeFullAnalyticsTitle: setTitle,
  };
};

export const useFullAnalytics = () => {
  const { startDate, endDate } = useContext(FilterContext);
  const { getParam, searchParams } = useSearchParams();
  const sub = useMemo(() => getParam('sub'), [searchParams]);
  const tab = useMemo(() => getParam('tab'), [searchParams]);

  if (sub === 'users') {
    const [params, setParams] = useState<IAnalyticsUsersTopGetParams>({
      start_date: startDate,
      end_date: endDate,
      page: +getParam('page') || 1,
      filter: tab as AnalyticsUsersTopFilterParams,
      order_type: 'desc' as TableRowsOrder,
      order: 'value' as AnalyticsTopListOrderParams,
      limit: 15,
      search: getParam('search'),
    });
    const columnsConfig: Array<IHeadCell<IAnalyticsFullTableRow>> =
      useMemo(() => {
        return [
          {
            id: 'id',
            label: 'Место',
            sortable: false,
          },
          {
            id: 'name',
            label: 'Пользователь',
            sortable: true,
          },
          {
            id: 'space_name',
            label: 'Пространство',
            sortable: true,
          },
          {
            id: 'value',
            label: 'Количество',
            sortable: true,
          },
        ];
      }, []);

    useEffect(() => {
      if (
        +getParam('page') !== params.page ||
        getParam('search') !== params.search
      ) {
        setParams(prev => ({
          ...prev,
          page: +getParam('page'),
          search: getParam('search'),
        }));
      }
    }, [searchParams]);

    useEffect(() => {
      setParams(prev => ({
        ...prev,
        start_date: startDate,
        end_date: endDate,
      }));
    }, [startDate, endDate]);

    const changeParams = <T extends keyof IAnalyticsUsersTopGetParams>(
      fieldName: T,
      value: IAnalyticsUsersTopGetParams[T],
    ) => {
      setParams(prev => ({ ...prev, [fieldName]: value }));
    };

    const { data: { data, last_page } = { data: [], last_page: 1 } } =
      useGetAnalyticsUsersTopLists(params);
    return {
      data,
      pageCount: last_page,
      changeParams,
      columns: columnsConfig,
    };
  }

  if (sub === 'workouts') {
    const [params, setParams] = useState<IAnalyticsTopListGetParams>({
      start_date: startDate,
      end_date: endDate,
      page: +getParam('page') || 1,
      order_type: 'desc' as TableRowsOrder,
      order: 'value' as AnalyticsTopListOrderParams,
      limit: 15,
      search: getParam('search'),
    });
    const columnsConfig: Array<IHeadCell<IAnalyticsFullTableRow>> =
      useMemo(() => {
        return [
          {
            id: 'id',
            label: 'Место',
            sortable: false,
          },
          {
            id: 'name',
            label: 'Тренировка',
            sortable: true,
          },
          {
            id: 'value',
            label: 'Количество',
            sortable: true,
          },
        ];
      }, []);

    useEffect(() => {
      if (
        +getParam('page') !== params.page ||
        getParam('search') !== params.search
      ) {
        setParams(prev => ({
          ...prev,
          page: +getParam('page'),
          search: getParam('search'),
        }));
      }
    }, [searchParams]);

    useEffect(() => {
      setParams(prev => ({
        ...prev,
        start_date: startDate,
        end_date: endDate,
      }));
    }, [startDate, endDate]);

    const changeParams = <T extends keyof IAnalyticsTopListGetParams>(
      fieldName: T,
      value: IAnalyticsTopListGetParams[T],
    ) => {
      setParams(prev => ({ ...prev, [fieldName]: value }));
    };
    if (tab === 'training') {
      const { data: { data, last_page } = { data: [], last_page: 1 } } =
        useGetAnalyticsWorkoutsTopListTraining(params);
      return {
        data,
        pageCount: last_page,
        changeParams,
        columns: columnsConfig,
      };
    }
    const { data: { data, last_page } = { data: [], last_page: 1 } } =
      useGetAnalyticsWorkoutsTopListSpace(params);

    return {
      data,
      pageCount: last_page,
      changeParams,
      columns: columnsConfig,
    };
  }

  if (sub === 'challenges') {
    const filterType = useMemo(() => {
      const filt = {
        km: 1,
        kcal: 3,
        steps: 4,
      };
      return filt[tab as AnalyticsChallengesTopFilterParamsAsString];
    }, [tab]);

    const [params, setParams] = useState<IAnalyticsChallengesTopGetParams>({
      start_date: startDate,
      end_date: endDate,
      page: +getParam('page') || 1,
      order_type: 'desc' as TableRowsOrder,
      order: 'value' as AnalyticsTopListOrderParams,
      limit: 15,
      type: filterType as AnalyticsChallengesTopFilterParams,
      search: getParam('search'),
    });
    const columnsConfig: Array<IHeadCell<IAnalyticsFullTableRow>> =
      useMemo(() => {
        return [
          {
            id: 'id',
            label: 'Место',
            sortable: false,
          },
          {
            id: 'name',
            label: 'Пользователь',
            sortable: true,
          },
          {
            id: 'space_name',
            label: 'Пространство',
            sortable: true,
          },
          {
            id: 'value',
            label: 'Количество',
            sortable: true,
          },
        ];
      }, []);

    useEffect(() => {
      if (
        +getParam('page') !== params.page ||
        getParam('search') !== params.search
      ) {
        setParams(prev => ({
          ...prev,
          page: +getParam('page'),
          search: getParam('search'),
        }));
      }
    }, [searchParams]);

    useEffect(() => {
      setParams(prev => ({
        ...prev,
        start_date: startDate,
        end_date: endDate,
      }));
    }, [startDate, endDate]);

    const changeParams = <T extends keyof IAnalyticsChallengesTopGetParams>(
      fieldName: T,
      value: IAnalyticsChallengesTopGetParams[T],
    ) => {
      setParams(prev => ({ ...prev, [fieldName]: value }));
    };

    const {
      data: { data: { data, last_page } } = {
        data: {
          data: [],
          last_page: 1,
        },
      },
    } = useGetAnalyticsChallengesTopList(params);

    return {
      data,
      pageCount: last_page,
      changeParams,
      columns: columnsConfig,
    };
  }

  if (sub === 'showcase') {
    const [params, setParams] = useState<IAnalyticsTopListGetParams>({
      start_date: startDate,
      end_date: endDate,
      page: +getParam('page') || 1,
      order_type: 'desc' as TableRowsOrder,
      order: 'value' as AnalyticsTopListOrderParams,
      limit: 15,
      search: getParam('search'),
    });

    useEffect(() => {
      if (
        +getParam('page') !== params.page ||
        getParam('search') !== params.search
      ) {
        setParams(prev => ({
          ...prev,
          page: +getParam('page'),
          search: getParam('search'),
        }));
      }
    }, [searchParams]);

    useEffect(() => {
      setParams(prev => ({
        ...prev,
        start_date: startDate,
        end_date: endDate,
      }));
    }, [startDate, endDate]);

    const changeParams = <T extends keyof IAnalyticsTopListGetParams>(
      fieldName: T,
      value: IAnalyticsTopListGetParams[T],
    ) => {
      setParams(prev => ({ ...prev, [fieldName]: value }));
    };
    if (tab === 'discounts') {
      const { data: { data, last_page } = { data: [], last_page: 1 } } =
        useGetAnalyticsShowcaseTopDiscounts(params);
      const columnsConfig: Array<IHeadCell<IAnalyticsFullTableRow>> =
        useMemo(() => {
          return [
            {
              id: 'id',
              label: 'Место',
              sortable: false,
            },
            {
              id: 'name',
              label: 'Скидка',
              sortable: true,
            },
            {
              id: 'value',
              label: 'Количество',
              sortable: true,
            },
          ];
        }, []);
      return {
        data,
        pageCount: last_page,
        changeParams,
        columns: columnsConfig,
      };
    }
    const { data: { data, last_page } = { data: [], last_page: 1 } } =
      useGetAnalyticsShowcaseTopCategories(params);
    const columnsConfig: Array<IHeadCell<IAnalyticsFullTableRow>> =
      useMemo(() => {
        return [
          {
            id: 'id',
            label: 'Место',
            sortable: false,
          },
          {
            id: 'name',
            label: 'Категория',
            sortable: true,
          },
          {
            id: 'value',
            label: 'Количество',
            sortable: true,
          },
        ];
      }, []);

    return {
      data,
      pageCount: last_page,
      changeParams,
      columns: columnsConfig,
    };
  }

  return {
    data: [],
    pageCount: 1,
    changeParams: () => {},
    columns: [],
  };
};

export const useGetFilterDate = () => {
  return useQuery<IFilterDateRes, unknown>(
    ['analyticsFilterDate'],
    async () => {
      const res = await getFilterDate();
      return res;
    },
    {
      staleTime: 24 * 60 * 60 * 1000,
      retry: false,
    },
  );
};
