import { FC } from 'react';

interface IDoneIconProps {
  color?: string;
}

export const DoneIcon: FC<IDoneIconProps> = ({ color = '#42C44F' }) => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12ZM12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4ZM15.8491 9.27589C16.2396 9.66641 16.2396 10.2996 15.8491 10.6901L11.5821 14.9571C11.1916 15.3476 10.5584 15.3476 10.1679 14.9571L8.71339 13.5026C8.32286 13.1121 8.32286 12.4789 8.71339 12.0884C9.10391 11.6979 9.73708 11.6979 10.1276 12.0884L10.875 12.8358L14.4349 9.27589C14.8254 8.88536 15.4586 8.88536 15.8491 9.27589Z'
        fill={color}
      />
    </svg>
  );
};
