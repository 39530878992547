import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { COLORS } from 'utils';

export const StyledContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '0.625rem',
  padding: '1.25rem',
  borderRadius: '0.5rem',
  border: `1px solid ${COLORS.BLACK}`,
  '& .subTitle': {
    fontFamily: '"SF Pro Display", sans-serif',
    fontSize: '1rem',
    fontWeight: 500,
    lineHeight: '1.5rem',
  },
  '& .addButtonBox': {
    paddingTop: '0.5rem',
    borderTop: '1px solid rgba(0, 0, 0, 0.1)',
    '& button': {
      width: '100%',
    },
  },
});

export const StyledDayHeader = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  fontFamily: '"SF Pro Display", sans-serif',
  '& > div': {
    display: 'flex',
    alignItems: 'center',
    gap: '0.625rem',
  },
  '& .title': {
    fontSize: '1.125rem',
    fontWeight: 600,
    lineHeight: '1.625rem',
  },
  '& .info': {
    fontSize: '0.75rem',
    fontWeight: 500,
    lineHeight: '1.25rem',
    opacity: 0.5,
  },
  '& .iconButtons': {
    gap: 0,
  },
});

export const StyledTrainingItem = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '0.9375rem',
  border: '1px solid rgba(0, 0, 0, 0.1)',
  borderRadius: '0.5rem',
  padding: '1.25rem',
  '& .mainInfo': {
    display: 'flex',
    alignItems: 'center',
    gap: '0.625rem',
    '& .icon': {
      minWidth: '2.75rem',
      height: '2.75rem',
      borderRadius: '0.5rem',
      padding: '0.25rem',
      backgroundColor: 'rgba(0, 0, 0, 0.05)',
      '& img': {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
      },
    },
    '& .title': {
      fontSize: '0.875rem',
      fontWeight: 600,
      lineHeight: '1.5rem',
      fontFamily: '"SF Pro Display", sans-serif',
      minWidth: '6rem',
    },
    '& .MuiTextField-root': {
      width: 'auto',
    },
  },
});

export const StyledPopoverBox = styled(Box)({
  width: '30rem',
  maxHeight: '20rem',
  overflowY: 'auto',
});

export const StyledWorkoutOption = styled(Box)({
  padding: '0.625rem 1.25rem',
  display: 'flex',
  alignItems: 'center',
  gap: '1.25rem',
  backgroundColor: COLORS.WHITE,
  '& .icon': {
    minWidth: '2.75rem',
    height: '2.75rem',
    borderRadius: '0.5rem',
    padding: '0.25rem',
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
  },
  '& .name': {
    fontSize: '0.875rem',
    fontWeight: 600,
    lineHeight: '1.375rem',
    fontFamily: '"SF Pro Display", sans-serif',
  },
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
  },
});
