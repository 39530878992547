import { FC, useMemo } from 'react';
import { Grid } from '@mui/material';
import { useGetGroups, useSearchParams } from 'hooks';
import { GroupCard } from 'components';
import { CardsContainer, Pagination, PaginationContainer } from 'UI';

export const GroupsBody: FC = () => {
  const { searchParams, getParam } = useSearchParams();
  const params = useMemo(() => {
    return {
      page: getParam('page'),
      search: getParam('search'),
    };
  }, [searchParams]);

  const {
    data: { data: groups, last_page: pageCount } = { data: [], last_page: 1 },
  } = useGetGroups(params);

  return (
    <>
      <CardsContainer container spacing={4}>
        {groups?.map(group => (
          <Grid item xs={4} key={group.id}>
            <GroupCard group={group} />
          </Grid>
        ))}
      </CardsContainer>
      <PaginationContainer item xs={12}>
        <Pagination count={pageCount} />
      </PaginationContainer>
    </>
  );
};
