import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import { getToken, notify, removeItem } from 'utils';

// Create a new Axios instance
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  timeout: 30000,
  headers: {},
});

// Request interceptor
axiosInstance.interceptors.request.use(
  // @ts-ignore
  (config: AxiosRequestConfig) => {
    // @ts-ignore
    config.headers.Authorization = `Bearer ${getToken()}`;
    return config;
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  },
);

// Response interceptor
axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => {
    // Process response data as needed
    return response.data;
  },
  (error: AxiosError) => {
    // Handle error responses
    if (error.response) {
      if (error.response.status === 401) {
        removeItem('token');
        removeItem('activeSpace');
        removeItem('lastRoute');
      }
      // Handle specific error status codes or conditions
    }
    console.error('Request error: ', error);
    return Promise.reject(error);
  },
);

export const http = (config: AxiosRequestConfig): Promise<any> => {
  return axiosInstance(config)
    .then(res => {
      if (!res.data) return res;
      return res.data;
    })
    .catch(err => {
      if (config.method !== methods.get) {
        const message =
          err?.response?.data?.message ||
          err?.response?.data?.error?.message ||
          'Что-то пошло не так';
        notify(message, 'error');
      }
      throw err;
    });
};

type Methods = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';

export const methods: Record<string, Methods> = {
  get: 'GET',
  post: 'POST',
  put: 'PUT',
  patch: 'PATCH',
  delete: 'DELETE',
};
