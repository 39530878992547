import { FC } from 'react';
import { COLORS } from 'utils';

interface IMoneyIcon {
  color?: string;
  className?: string;
  style?: React.CSSProperties;
  opacity?: string;
  onClick?: () => void;
}

export const MoneyIcon: FC<IMoneyIcon> = ({
  className,
  color = COLORS.BLACK,
  onClick,
  opacity = '0.2',
  ...rest
}) => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      onClick={onClick}
      {...rest}
    >
      <g opacity={opacity}>
        <path
          d='M9.16667 12.0837C9.16667 13.2337 11.0325 14.167 13.3333 14.167C15.6342 14.167 17.5 13.2337 17.5 12.0837M2.5 7.91699C2.5 9.06699 4.36583 10.0003 6.66667 10.0003C7.605 10.0003 8.47083 9.84533 9.16667 9.58366M2.5 10.8337C2.5 11.9837 4.36583 12.917 6.66667 12.917C7.605 12.917 8.47 12.762 9.16667 12.5003M13.3333 10.8337C11.0325 10.8337 9.16667 9.90033 9.16667 8.75033C9.16667 7.60033 11.0325 6.66699 13.3333 6.66699C15.6342 6.66699 17.5 7.60033 17.5 8.75033C17.5 9.90033 15.6342 10.8337 13.3333 10.8337Z'
          stroke={color}
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M2.5 4.58301V13.7497C2.5 14.8997 4.36583 15.833 6.66667 15.833C7.605 15.833 8.47 15.678 9.16667 15.4163M9.16667 15.4163V8.74967M9.16667 15.4163C9.16667 16.5663 11.0325 17.4997 13.3333 17.4997C15.6342 17.4997 17.5 16.5663 17.5 15.4163V8.74967M10.8333 7.08301V4.58301'
          stroke={color}
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M6.66667 6.66667C4.36583 6.66667 2.5 5.73333 2.5 4.58333C2.5 3.43333 4.36583 2.5 6.66667 2.5C8.9675 2.5 10.8333 3.43333 10.8333 4.58333C10.8333 5.73333 8.9675 6.66667 6.66667 6.66667Z'
          stroke={color}
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
    </svg>
  );
};
