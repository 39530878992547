import { useQuery, useMutation, useQueryClient } from 'react-query';
import {
  getGroups,
  getGroup,
  createGroup,
  updateGroup,
  deleteGroup,
  getGroupsList,
} from 'api';
import { IGroup, IGroupsRes, IGroupGetParams, IGroupShort } from 'types';

export const useGetGroups = (params: IGroupGetParams) => {
  const { page, search } = params;
  return useQuery<IGroupsRes, unknown>(
    ['groups', { page, search }],
    async () => {
      const res = await getGroups(params);
      return res;
    },
    {
      staleTime: Infinity,
      retry: false,
    },
  );
};

export const useGetGroup = (groupId: string | number) => {
  if (!groupId)
    return useQuery(['groups', 'groupId', groupId], () => {
      return Promise.resolve(null);
    });
  return useQuery<IGroup>(
    ['groups', 'groupId', groupId],
    async () => {
      const res = await getGroup(groupId);
      return res;
    },
    {
      staleTime: 1000 * 60 * 10, // 10 minutes
      retry: false,
    },
  );
};

export const useCreateGroup = () => {
  const queryClient = useQueryClient();
  return useMutation(createGroup, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['groups'] });
    },
    onError: () => {},
  });
};

export const useUpdateGroup = () => {
  const queryClient = useQueryClient();
  return useMutation(updateGroup, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['groups'] });
    },
    onError: () => {},
  });
};

export const useDeleteGroup = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteGroup, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['groups'] });
    },
    onError: () => {},
  });
};

export const useGetGroupsList = (space_id: string | number) => {
  if (!space_id)
    return useQuery(['groups', 'list', space_id], () => {
      return Promise.resolve(null);
    });
  return useQuery<IGroupShort[]>(
    ['groups', 'list', space_id],
    async () => {
      const res = await getGroupsList({ space_id });
      return res;
    },
    {
      retry: false,
      staleTime: Infinity,
    },
  );
};
