import { FC } from 'react';
import { IIconProps } from './types';

export const StoriesIcon: FC<IIconProps> = ({ isActive, activeColor }) => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <mask id='path-1-inside-1_22845_58923' fill='white'>
        <rect x='3' y='3' width='8' height='18' rx='1' />
      </mask>
      <rect
        x='3'
        y='3'
        width='8'
        height='18'
        rx='1'
        stroke={isActive ? activeColor : '#C4C4C4'}
        strokeWidth='4'
        mask='url(#path-1-inside-1_22845_58923)'
      />
      <mask id='path-2-inside-2_22845_58923' fill='white'>
        <rect x='13' y='3' width='8' height='18' rx='1' />
      </mask>
      <rect
        x='13'
        y='3'
        width='8'
        height='18'
        rx='1'
        stroke={isActive ? activeColor : '#C4C4C4'}
        strokeWidth='4'
        mask='url(#path-2-inside-2_22845_58923)'
      />
    </svg>
  );
};
