import { FC, useState } from 'react';
import { styled } from '@mui/system';
import { Box, TextField } from '@mui/material';
import { Modal, ModalFooter, ModalTitle } from 'UI';

const StyledModalContent = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '1.25rem',
  width: '100%',
});

interface IFillBalanceModal {
  open: boolean;
  onClose: () => void;
  onConfirm: (_balance: number) => void;
}

export const FillBalanceModal: FC<IFillBalanceModal> = ({
  open,
  onClose,
  onConfirm,
}) => {
  const [balance, setBalance] = useState<number>(0);

  const changeBalance = (value: string) => {
    // validate if it's a number with regex
    const regex = /^[0-9\b]+$/;
    if (regex.test(value) || value === '') {
      setBalance(Number(value));
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <StyledModalContent>
        <ModalTitle title='Пополнить баланс' />
        <TextField
          variant='standard'
          color='primary'
          label='Количество балов'
          placeholder='Введите сумму'
          name='balance'
          value={balance}
          onChange={e => changeBalance(e.target.value)}
        />
        <ModalFooter
          onCancel={() => onConfirm(balance)}
          onConfirm={onClose}
          confirmButtonText='Отменить'
          cancelButtonText='Пополнить'
          cancelButtonColor='primary'
          confirmButtonColor='customGray'
        />
      </StyledModalContent>
    </Modal>
  );
};
