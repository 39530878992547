import { FC } from 'react';
import { COLORS } from 'utils';

interface ICloseIcon {
  color?: string;
  className?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
}

export const CloseIcon: FC<ICloseIcon> = ({
  className,
  color = COLORS.BLACK,
  onClick,
  ...rest
}) => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      onClick={onClick}
      {...rest}
    >
      <path
        d='M7.09375 17.2438L12.3368 12.0008L17.5798 17.2438M17.5798 6.75781L12.3358 12.0008L7.09375 6.75781'
        stroke={color}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export const MiniCloseIcon: FC = () => {
  return (
    <svg
      width='10'
      height='10'
      viewBox='0 0 10 10'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M9 1L1 9M9 9L1 0.999998'
        stroke='white'
        strokeWidth='2'
        strokeLinecap='round'
      />
    </svg>
  );
};
