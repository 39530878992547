import { FC, useRef } from 'react';
// import { useDrag, useDrop } from 'react-dnd';
// import type { Identifier } from 'dnd-core';
import { TableCell } from '@mui/material';
import { StyledSwitch } from 'UI';
import { EditIcon, DeleteIcon } from 'assets';
import { IBanner } from 'types';

import {
  StyledTableRow,
  StyledProductInfo,
  StyledAvatar,
  StyledProductName,
} from './styledComponents';

interface IBannersTableRowProps {
  row: IBanner;
  openEditDrawer: (_id: string | number) => void;
  deleteButtonHandler: (_row: any) => void;
  updateRow: (_row: any) => void;
  moveRow: (_dragIndex: number, _dropIndex: number) => void;
}

// interface DragItem {
//   index: number;
//   id: string;
//   type: string;
// }

export const ItemTypes = {
  CARD: 'card',
};

export const BannersTableRow: FC<IBannersTableRowProps> = ({
  row,
  openEditDrawer,
  deleteButtonHandler,
  updateRow,
  moveRow,
}) => {
  // const ref = useRef<any>(null);
  // const [{ handlerId }, drop] = useDrop<
  //   DragItem,
  //   void,
  //   { handlerId: Identifier | null }
  // >({
  //   accept: ItemTypes.CARD,
  //   collect(monitor) {
  //     return {
  //       handlerId: monitor.getHandlerId(),
  //     };
  //   },
  //   drop(item) {
  //     if (!ref.current) {
  //       return;
  //     }
  //     const dragIndex = item.index;
  //     const dropIndex = row.position;

  //     // Don't replace items with themselves
  //     if (dragIndex === dropIndex) {
  //       return;
  //     }

  //     // Time to actually perform the action
  //     moveRow(dragIndex, dropIndex);
  //   },
  // });

  // const [{ isDragging }, drag] = useDrag({
  //   type: ItemTypes.CARD,
  //   item: () => {
  //     return { id: row.id, index: row.position };
  //   },
  //   collect: (monitor: any) => ({
  //     isDragging: monitor.isDragging(),
  //   }),
  // });

  // drag(drop(ref));
  // const opacity = isDragging ? 0.3 : 1;
  // const cursor = isDragging ? 'grabbing' : 'grab';

  return (
    <StyledTableRow
      hover
      key={row.id}
      sx={{
        backgroundColor: !row.is_active ? '#fff6f5' : '#ffffff',
        // opacity,
        // cursor,
      }}
      // ref={ref}
      // data-handler-id={handlerId}
    >
      <TableCell align='center'>
        <span>{row.position}</span>
      </TableCell>
      <TableCell
        component='th'
        id={row.id.toString()}
        scope='row'
        className='nameCell'
      >
        <StyledProductInfo onClick={() => openEditDrawer(row.id)}>
          <StyledAvatar>
            <img src={row.cover as string | undefined} alt={row.title} />
          </StyledAvatar>
          <StyledProductName>{row.title}</StyledProductName>
        </StyledProductInfo>
      </TableCell>
      <TableCell align='center'>
        <span>{row.link || 'Ссылка не указано'}</span>
      </TableCell>
      <TableCell align='center'>
        <span>{row.created_at}</span>
      </TableCell>
      <TableCell>
        <div className='editDeleteIcons'>
          <StyledSwitch
            checked={row.is_active}
            variant='customGray'
            onChange={() => updateRow(row)}
            style={{ opacity: 1 }}
            className='switch'
          />
          <EditIcon onClick={() => openEditDrawer(row.id)} />
          <DeleteIcon onClick={() => deleteButtonHandler(row)} />
        </div>
      </TableCell>
    </StyledTableRow>
  );
};
