import { FC, useState, MouseEvent, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { TableBody, TableCell, Box } from '@mui/material';

import { DeleteIcon } from 'assets';
import {
  IDeleteNewsCommentReq,
  INewsCommment,
  NewsCommentSortParams,
  TableRowsOrder,
} from 'types';
import { DeleteModal } from 'components';
import { COLORS } from 'utils';
import {
  useGetNewsComments,
  useSearchParams,
  useDeleteNewsComment,
} from 'hooks';
import { Pagination } from 'UI';

import {
  StyledBox,
  StyledTableContainer,
  StyledTable,
  StyledTableRow,
} from './styledComponents';
import { NewsCommentsTableHead } from './NewsCommentsTableHead';

export const NewsCommentsTable: FC = () => {
  const { pathname } = useLocation();
  const [order, setOrder] = useState<TableRowsOrder>('desc');
  const [orderBy, setOrderBy] = useState<NewsCommentSortParams>('author');
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [selectedComment, setSelectedComment] = useState<
    INewsCommment | undefined
  >(undefined);
  const { getParam, searchParams } = useSearchParams();

  const newsId = useMemo(() => {
    return pathname.split('/').pop() || 0;
  }, [pathname]);

  const params = useMemo(() => {
    return {
      page: getParam('page'),
      search: getParam('search'),
      news_id: newsId,
      column: orderBy,
      sort_type: order,
    };
  }, [searchParams, order, orderBy, newsId]);

  const {
    data: { data: newsComments, last_page: pageCount } = {
      data: [],
      last_page: 1,
    },
  } = useGetNewsComments(params);
  const { mutateAsync: deleteNewsComment } = useDeleteNewsComment();

  const deleteButtonHandler = (row: INewsCommment) => {
    setSelectedComment(row);
    setOpenDeleteModal(true);
  };

  const deleteRow = () => {
    if (!selectedComment?.id) return;
    const delParam: IDeleteNewsCommentReq = {
      news_id: newsId,
      comment_id: selectedComment.id,
    };

    deleteNewsComment(delParam)
      .then(() => {
        setOpenDeleteModal(false);
      })
      .catch(() => {});
  };

  const cancelDeleteRow = () => {
    setOpenDeleteModal(false);
    setSelectedComment(undefined);
  };

  const handleRequestSort = (
    _event: MouseEvent<unknown>,
    property: NewsCommentSortParams,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <StyledBox>
      <DeleteModal
        open={openDeleteModal}
        title='Удалить комментарий'
        description='Вы действительно хотите удалить комментарий?'
        onConfirm={deleteRow}
        onClose={cancelDeleteRow}
      />

      <StyledTableContainer>
        <StyledTable
          aria-labelledby='newsCommentsTable'
          size='medium'
          stickyHeader
        >
          <NewsCommentsTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {newsComments.map((row: any) => {
              return (
                <StyledTableRow
                  hover
                  key={row.id}
                  sx={{
                    backgroundColor: row.is_suspicious ? '#fff6f5' : '#ffffff',
                  }}
                >
                  <TableCell colSpan={2}>
                    <Box className='mainInfo'>
                      <Box className='meta'>
                        <p className='name'>
                          {row.author?.firstname} {row.author?.lastname}
                        </p>
                        <span className='publishDate'>{row.created_at}</span>
                      </Box>
                      <p className='comment'>{row.content}</p>
                    </Box>
                  </TableCell>
                  <TableCell className='buttonCell'>
                    <DeleteIcon
                      color={COLORS.RED_2}
                      onClick={() => deleteButtonHandler(row)}
                      className='deleteIcon'
                    />
                  </TableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </StyledTable>
      </StyledTableContainer>
      <Pagination count={pageCount} />
    </StyledBox>
  );
};
