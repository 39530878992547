import { FC, ReactNode, useContext } from 'react';
import { styled } from '@mui/system';
import { Box } from '@mui/material';
import { UserContext, UserContextType } from 'app';

const StyledContainer = styled(
  ({
    _role_id,
    children,
    ...otherProps
  }: {
    _role_id?: number;
    children: any;
  }) => <Box {...otherProps}>{children}</Box>,
)(() => ({
  overflowY: 'auto',
  maxHeight: 'calc(100vh - 14rem)',
  '&.subFilterActive': {
    maxHeight: 'calc(100vh - 17.5rem)',
  },
  '& .twoMiniCardsContainer': {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    '& > div': {
      height: '100%',
      justifyContent: 'space-between',
    },
  },
}));

interface IAnalyticsInnerPageContainerProps {
  children: ReactNode;
}

export const AnalyticsInnerPageContainer: FC<
  IAnalyticsInnerPageContainerProps
> = ({ children }) => {
  const { role_id } = useContext(UserContext) as UserContextType;
  return (
    // @ts-ignore
    <StyledContainer role_id={role_id} id='innerPageContainer'>
      {children}
    </StyledContainer>
  );
};
