import { FC, useContext, useState } from 'react';
import { styled } from '@mui/system';
import { Box } from '@mui/material';
import { MediaType } from 'types';
import { UserContext, UserContextType } from 'app';
import { COLORS, notify } from 'utils';
import { useUploadMedia } from 'hooks';
import { MiniCloseIcon, VideoIcon } from 'assets';
import { CircularProgress } from 'UI';

const StyledContainer = styled(Box)({
  display: 'flex',
  width: '100%',
  gap: '0.625rem',
  '& .videoContainer': {
    position: 'relative',
    '&:hover': {
      '& .deleteVideo': {
        visibility: 'visible',
      },
    },
    '& .deleteVideo': {
      position: 'absolute',
      top: '-0.5rem',
      right: '-0.5rem',
      backgroundColor: COLORS.RED_2,
      borderRadius: '50%',
      width: '1.5rem',
      height: '1.5rem',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      visibility: 'hidden',
    },
  },
});

const StyledPlaceholder = styled(Box)(({ width, height }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  border: `2px solid ${COLORS.BLUE}`,
  borderRadius: '0.5rem',
  width: `${width}px`,
  height: `${height}px`,
  '& svg': {
    cursor: 'pointer',
  },
}));

const StyledContentBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '0.625rem',
  justifyContent: 'center',
  '& .title': {
    fontWeight: 600,
    fontFamily: '"SF Pro Display", sans-serif',
    fontSize: '1rem',
    lineHeight: '1.5rem',
  },
  '& .description': {
    fontFamily: '"SF Pro Display", sans-serif',
    fontSize: '0.875rem',
    lineHeight: '1.375rem',
    fontWeight: 400,
    opacity: 0.5,
  },
});

interface IVideoUploadProps {
  mediaType: MediaType;
  saveVideoLink: (_link: string) => void;
  videoSrc?: string;
  inputId?: string;
  width?: number;
  height?: number;
  label?: string;
}

export const VideoUpload: FC<IVideoUploadProps> = ({
  mediaType,
  saveVideoLink,
  videoSrc,
  inputId = 'videoUpload',
  width = 276,
  height = 154,
  label = 'Добавьте видео-инструкция',
}) => {
  const { space_id } = useContext(UserContext) as UserContextType;
  const [progress, setProgress] = useState<number>(0);

  const { isLoading, mutateAsync: uploadMedia } = useUploadMedia();

  const handleOpenFileUploadModal = () => {
    document.getElementById(inputId)?.click();
  };

  const changeProgress = (val: number) => {
    setProgress(val);
  };

  const handleUploadVideo = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 20 * 1024 * 1024) {
        notify(`Размер файла превышает максимальный предел в 20мб.`, 'error');
        return;
      }
      const formData = new FormData();
      formData.append('video', file);
      formData.append('type', mediaType);
      formData.append('space_id', (space_id || 1).toString());
      uploadMedia({ data: formData, getPercentageValue: changeProgress })
        .then(res => {
          saveVideoLink(res);
        })
        .catch(() => {})
        .finally(() => {
          setProgress(0);
        });
    }
  };

  const deleteVideo = () => {
    saveVideoLink('');
  };

  return (
    <StyledContainer>
      {videoSrc ? (
        <Box className='videoContainer'>
          {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
          <video controls width={width} height={height}>
            <source src={videoSrc} type='video/mp4' />
            <source src={videoSrc} type='video/webm' />
            <source src={videoSrc} type='video/ogg' />
            Your browser does not support the video tag.
          </video>
          <Box className='deleteVideo' onClick={deleteVideo}>
            <MiniCloseIcon />
          </Box>
        </Box>
      ) : (
        <StyledPlaceholder width={width} height={height}>
          {isLoading ? (
            <CircularProgress value={progress} />
          ) : (
            <VideoIcon onClick={handleOpenFileUploadModal} />
          )}
        </StyledPlaceholder>
      )}
      <StyledContentBox>
        <p className='title'>{label}</p>
        <Box>
          <p className='description'>Загрузите видео </p>
          <p className='description'>(1080x1920 px, до 20 мб) </p>
        </Box>
      </StyledContentBox>
      <input
        id={inputId}
        type='file'
        style={{ display: 'none' }}
        onChange={e => handleUploadVideo(e)}
        accept='video/*'
      />
    </StyledContainer>
  );
};
