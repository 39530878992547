import { styled } from '@mui/system';
import { Box } from '@mui/material';

export const StyledContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '1.25rem',
  '& .controls': {
    display: 'flex',
    gap: '0.5rem',
    '& .MuiButton-containedPrimary': {
      width: '12rem',
      height: '2.75rem',
    },
  },
});
