import { useContext } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import { getSmsCode, login, logout, changeSpace, getCurrentUser } from 'api';
import { UserContext, UserContextType } from 'app';
import { setToken, removeItem } from 'utils';
import { IUser } from 'types';

export const useGetSmsCode = () => {
  return useMutation(getSmsCode, {
    mutationKey: 'getSmsCode',
    onSuccess: () => {},
    onError: () => {},
  });
};

export const useLogin = () => {
  const { updateUser, updateSpace } = useContext(
    UserContext,
  ) as UserContextType;

  return useMutation(login, {
    mutationKey: 'login',
    onSuccess: res => {
      const { body: token } = res.token;
      setToken(token);
      removeItem('timer');
      updateUser(res);
      if (res?.spaces[0]?.id) {
        updateSpace(res.spaces[0]);
        changeSpace({ space_id: res?.spaces[0]?.id })
          .then(() => {})
          .catch(() => {});
      }
    },
    onError: () => {},
  });
};

export const useLogout = () => {
  const queryClient = useQueryClient();

  return useMutation(logout, {
    onSuccess: () => {
      queryClient.invalidateQueries();
      removeItem('token');
      removeItem('userData');
      removeItem('activeSpace');
      removeItem('lastRoute');
      window.location.reload();
    },
    onError: () => {},
  });
};

export const useChangeSpace = () => {
  const queryClient = useQueryClient();
  return useMutation(changeSpace, {
    mutationKey: 'changeSpace',
    onSuccess: () => {
      queryClient.invalidateQueries();
    },
    onError: () => {},
  });
};

export const useGetCurrentUser = () => {
  const getMe = async () => {
    const res = await getCurrentUser();
    return res as Omit<IUser, 'token'>;
  };

  return { getCurrentUser: getMe };
};
