import { FC } from 'react';
import { Button, PageHeader } from 'UI';
import { PlusIcon } from 'assets';

interface IStoriesHeaderProps {
  openAddStoryModal: () => void;
}

export const StoriesHeader: FC<IStoriesHeaderProps> = ({
  openAddStoryModal,
}) => {
  return (
    <PageHeader title='Сторисы'>
      <Button
        variant='contained'
        startIcon={<PlusIcon />}
        onClick={openAddStoryModal}
      >
        Создать
      </Button>
    </PageHeader>
  );
};
