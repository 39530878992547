import { useQuery, useMutation, useQueryClient } from 'react-query';
import {
  getShopCategories,
  getShopCategory,
  createShopCategory,
  updateShopCategory,
  deleteShopCategory,
  getShopCategoriesList,
} from 'api';
import {
  IShopCategoryRes,
  IPaginatedReqParams,
  IShopCategory,
  IShopCategoryListRes,
} from 'types';

export const useGetShopCategories = (params: IPaginatedReqParams) => {
  const { page, search } = params;
  return useQuery<IShopCategoryRes, unknown>(
    ['shopCategories', { page, search }],
    async () => {
      const res = await getShopCategories(params);
      return res;
    },
    {
      retry: false,
      staleTime: 60 * 60 * 1000,
    },
  );
};

export const useGetShopCategoriesList = () => {
  return useQuery<IShopCategoryListRes>(
    ['shopCategories', 'list'],
    async () => {
      const res = await getShopCategoriesList();
      return res;
    },
    {
      retry: false,
      staleTime: Infinity,
    },
  );
};

export const useGetShopCategory = (id: string | number) => {
  if (!id || id === '0')
    return useQuery(['shopCategories', 'categoryId', id], () => {
      return Promise.resolve(null);
    });
  return useQuery<IShopCategory, unknown>(
    ['shopCategories', 'categoryId', id],
    async () => {
      const res = await getShopCategory(id);
      return res;
    },
    {
      retry: false,
      staleTime: 60 * 60 * 1000,
    },
  );
};

export const useCreateShopCategory = () => {
  const queryClient = useQueryClient();
  return useMutation(createShopCategory, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['shopCategories'] });
    },
    onError: () => {},
  });
};

export const useUpdateShopCategory = () => {
  const queryClient = useQueryClient();
  return useMutation(updateShopCategory, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['shopCategories'] });
    },
    onError: () => {},
  });
};

export const useDeleteShopCategory = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteShopCategory, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['shopCategories'] });
    },
    onError: () => {},
  });
};
