import { FC } from 'react';
import { useDrawer } from 'hooks';

import {
  AddEditWorkoutDrawer,
  Layout,
  WorkoutsHeader,
  WorkoutsTable,
} from 'components';

export const Workouts: FC = () => {
  const {
    openAddDrawer,
    showAddEditDrawer,
    hideAddEditDrawer,
    editDrawerId,
    purpose,
  } = useDrawer();

  return (
    <Layout withoutPB>
      <AddEditWorkoutDrawer
        open={showAddEditDrawer}
        onClose={hideAddEditDrawer}
        id={editDrawerId}
        purpose={purpose}
      />
      <WorkoutsHeader openAddWorkoutModal={openAddDrawer} />
      <WorkoutsTable />
    </Layout>
  );
};
