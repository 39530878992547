import { Switch } from '@mui/material';
import { styled } from '@mui/system';
import { FC, CSSProperties } from 'react';
import { COLORS } from 'utils';

const COLOR_PALETTE = {
  primary: {
    track: COLORS.WHITE,
    thumb: COLORS.BLUE,
  },
  customGray: {
    track: COLORS.WHITE,
    thumb: COLORS.BLACK,
  },
  customWhite: {
    track: 'transparent',
    thumb: COLORS.WHITE,
  },
};

const CustomSwitch = styled(Switch)(
  ({ variant, checked }: { variant: SwitchVariant; checked: boolean }) => ({
    '& .MuiSwitch-switchBase': {
      padding: 15,
      '&.Mui-checked': {
        '& + .MuiSwitch-track': {
          backgroundColor: COLOR_PALETTE[variant].track,
          opacity: 1,
        },
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 16,
      height: 16,
      backgroundColor: checked
        ? COLOR_PALETTE[variant].thumb
        : COLORS.DISABLED_GRAY,
      opacity: 1,
    },
    '& .MuiSwitch-track': {
      padding: '0.6rem 1.2rem',
      borderRadius: '1.5rem',
      backgroundColor: COLOR_PALETTE[variant].track,
      border: `2px solid ${
        checked ? COLOR_PALETTE[variant].thumb : COLORS.DISABLED_GRAY
      }`,
      opacity: 1,
    },
  }),
);

type SwitchVariant = 'primary' | 'customGray' | 'customWhite';

interface IStyledSwitchProps {
  checked: boolean;
  onChange: () => void;
  disabled?: boolean;
  variant?: SwitchVariant;
  style?: CSSProperties;
  className?: string;
}

export const StyledSwitch: FC<IStyledSwitchProps> = ({
  checked,
  onChange,
  variant = 'primary',
  disabled = false,
  style,
  className,
}) => {
  return (
    <CustomSwitch
      checked={checked}
      onChange={onChange}
      disabled={disabled}
      variant={variant}
      style={style}
      className={className}
    />
  );
};
