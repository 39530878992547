import { ICheckboxLogsGetParams } from 'types';
import { http, methods } from './config';

export const getCheckboxLogs = (params: ICheckboxLogsGetParams) => {
  const config = {
    method: methods.get,
    url: `/checkbox/logs`,
    params,
  };
  return http(config);
};
