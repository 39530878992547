import { Box, Paper, Table, TableContainer, TableRow } from '@mui/material';
import { styled } from '@mui/system';
import { COLORS } from 'utils';

export const StyledBox = styled(Box)({
  width: '100%',
  overflow: 'auto',
});

export const StyledPaper = styled(Paper)({
  width: '100%',
  marginBottom: 2,
});

export const StyledTableContainer = styled(TableContainer)({
  maxHeight: 'calc(100vh - 12rem)',
  minHeight: 'calc(100vh - 12rem)',
  '& .MuiTableHead-root': {
    '& .MuiTableCell-root': {
      '&:first-child': {
        paddingLeft: '5rem',
        textAlign: 'left',
      },
    },
  },
  '& .MuiTableSortLabel-icon': {
    position: 'absolute',
    right: '-1.5rem',
  },
});

export const StyledTable = styled(Table)({
  minWidth: '1040px',
});

export const StyledTableRow = styled(TableRow)({
  '& span': {
    opacity: 0.5,
    fontSize: '0.75rem',
    fontWeight: 400,
    lineHeight: '1rem',
    fontFamily: '"SF Pro Display", sans-serif',
  },
  '& .editDeleteIcons': {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '0 auto',
    width: '100%',
    maxWidth: '4rem',
    '& svg': {
      cursor: 'pointer',
    },
  },
});

export const StyledUserInfoAtModal = styled(Box)({
  display: 'flex',
  gap: '0.625rem',
  border: `1px solid ${COLORS.GRAY}`,
  padding: '0.625rem',
  borderRadius: '0.5rem',
});

export const StyledUserInfo = styled(Box)({
  display: 'flex',
  gap: '0.625rem',
});

export const StyledAvatar = styled(Box)({
  width: '3.125rem',
  height: '3.125rem',
  borderRadius: '50%',
  overflow: 'hidden',
  cursor: 'pointer',
  '& img': {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
  },
});

export const StyledUserMeta = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  cursor: 'pointer',
  '& div': {
    fontSize: '0.875rem',
    fontFamily: '"SF Pro Display", sans-serif',
  },
  '& .name': {
    fontWeight: 600,
    width: 'max-content',
  },
  '& .phone': {
    opacity: 0.5,
    fontWeight: 400,
  },
});
