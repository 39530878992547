import { FC } from 'react';
import { Button, PageHeader } from 'UI';
import { PlusIcon } from 'assets';

interface IWorkoutsCalendarHeaderProps {
  openAddWorkoutsCalendarModal: () => void;
}

export const WorkoutsCalendarHeader: FC<IWorkoutsCalendarHeaderProps> = ({
  openAddWorkoutsCalendarModal,
}) => {
  return (
    <PageHeader title='Цели'>
      <Button
        variant='contained'
        startIcon={<PlusIcon />}
        onClick={openAddWorkoutsCalendarModal}
        size='large'
      >
        Добавить
      </Button>
    </PageHeader>
  );
};
