import * as Pages from 'pages';
import { ROLES } from 'utils';

import { IRoutes } from '../types';
import { Analytics, FullAnalytics } from './analytics';
import { Auth } from './auth';
import { Spaces } from './spaces';
import { Groups } from './groups';
import { Users } from './users';
import { Achievements } from './achievements';
import { Challenges } from './challenges';
import { Teams } from './teams';
import { EventLog } from './eventlog';
import { News } from './news';
import { Partners } from './partners';
import { Notifications } from './notifications';
import { Services } from './services';
import { Showcases } from './showcases';
import { Applications } from './applications';
import { Workers } from './workers';
import { Workouts } from './workouts';
// import { Levels } from './levels';
import { Banners } from './banners';
import { Standards } from './standards';
import { WorkoutsCalendar } from './workoutsCalendar';
import { Attributes } from './attributes';
import { Shop } from './shop';
import { Regions } from './regions';
import { Moderation } from './moderation';
import { Stories } from './stories';

export const combinedSegments: IRoutes = {
  initialRoute: {
    path: '/',
    title: 'initialRoute',
    element: <Pages.Home />,
    roles: [],
    openRoute: true,
  },
  //   home: {
  //     path: '/home',
  //     title: 'home',
  //     element: <Pages.Auth />,
  //   },
  // * - * - * - * - * - * - * - *

  ...Auth,
  ...Analytics,
  ...FullAnalytics,
  ...Achievements,
  ...Applications,
  ...Banners,
  ...Challenges,
  ...Attributes,
  ...EventLog,
  ...Groups,
  // ...Levels,
  ...News,
  ...Moderation,
  ...Notifications,
  ...Partners,
  ...Regions,
  ...Services,
  ...Shop,
  ...Showcases,
  ...Spaces,
  ...Standards,
  ...Stories,
  ...Teams,
  ...Users,
  ...Workers,
  ...Workouts,
  ...WorkoutsCalendar,

  // * - * - * - * - * - * - * - *
  noMatch: {
    path: '*',
    title: 'noMatch',
    element: <Pages.NotFound404 />,
    roles: [ROLES.ADMINSTRATOR, ROLES.MODERATOR],
    openRoute: true,
  },
};
