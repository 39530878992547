import { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { IGroup } from 'types';
import { Button, StyledBadge } from 'UI';
import { EditIcon } from 'assets';

import {
  GroupCardWrapper,
  StyledHr,
  StyledGroupInfo,
} from './styledComponents';

interface IGroupCardProps {
  group: IGroup;
}

export const GroupCard: FC<IGroupCardProps> = ({ group }) => {
  const { id, name, logo, description, is_active, users_count, news_count } =
    group;
  const location = useLocation();
  const navigate = useNavigate();

  const openViewDrawer = () => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('view', id.toString());
    navigate(`?${searchParams.toString()}`);
  };

  const openEditDrawer = () => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('edit', id.toString());
    navigate(`?${searchParams.toString()}`);
  };

  return (
    <GroupCardWrapper>
      <div className='groupMetaInfo'>
        <div className='logoBox' onClick={openViewDrawer}>
          <img src={logo} alt='logo' />
        </div>
        <div className='groupInfo'>
          <h2 onClick={openViewDrawer}>{name}</h2>
          <p>{description}</p>
        </div>
      </div>
      <StyledBadge
        is_active={is_active}
        variant='secondary'
        activeText='Активна'
        inActiveText='Выключена'
      />
      <StyledHr />
      <StyledGroupInfo>
        <div className='spaceInfoItem'>
          <div className='label'>Участников</div>
          <div className='value'>{users_count}</div>
        </div>
        <div className='spaceInfoItem'>
          <div className='label'>Новости</div>
          <div className='value'>{news_count}</div>
        </div>
      </StyledGroupInfo>
      <Button
        variant='contained'
        color='customGray'
        size='large'
        className='editButton'
        startIcon={<EditIcon />}
        onClick={openEditDrawer}
      >
        Редактировать
      </Button>
    </GroupCardWrapper>
  );
};
