import { FC, useMemo } from 'react';
import { Grid } from '@mui/material';
import { useGetSpaces, useSearchParams } from 'hooks';
import { SpaceCard } from 'components';
import { Pagination, CardsContainer, PaginationContainer } from 'UI';

export const SpacesBody: FC = () => {
  const { searchParams, getParam } = useSearchParams();

  const params = useMemo(() => {
    return {
      page: getParam('page'),
      search: getParam('search'),
    };
  }, [searchParams]);
  const {
    data: { data: spaces, last_page: pageCount } = { data: [], last_page: 1 },
  } = useGetSpaces(params);

  return (
    <>
      <CardsContainer container spacing={3}>
        {spaces?.map(space => (
          <Grid item xs={4} key={space.id}>
            <SpaceCard space={space} />
          </Grid>
        ))}
      </CardsContainer>
      <PaginationContainer item xs={12}>
        <Pagination count={pageCount} />
      </PaginationContainer>
    </>
  );
};
