import { useQuery, useMutation, useQueryClient } from 'react-query';
import {
  getCities,
  getCity,
  createCity,
  updateCity,
  getCitiesList,
  getRegionsList,
} from 'api';
import { ICity, IRegion, IRegionGetParams, IRegionsRes } from 'types';

export const useGetCities = (params: IRegionGetParams) => {
  const { page, search, column, sort_type } = params;
  return useQuery<IRegionsRes, unknown>(
    ['cities', { page, search, column, sort_type }],
    async () => {
      const res = await getCities(params);
      return res;
    },
    {
      staleTime: Infinity,
      retry: false,
    },
  );
};

export const useGetCity = (cityId: number | string) => {
  if (!cityId)
    return useQuery(['cities', 'cityId', cityId], () => {
      return Promise.resolve(null);
    });
  return useQuery<IRegion>(
    ['cities', cityId],
    async () => {
      const res = await getCity(cityId);
      return res;
    },
    {
      staleTime: Infinity,
      retry: false,
    },
  );
};

export const useCreateCity = () => {
  const queryClient = useQueryClient();
  return useMutation(createCity, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['cities'] });
    },
    onError: () => {},
  });
};

export const useUpdateCity = () => {
  const queryClient = useQueryClient();
  return useMutation(updateCity, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['cities'] });
    },
    onError: () => {},
  });
};

export const useGetCitiesList = (params: { search: string }) => {
  const { search } = params;
  return useQuery<ICity[]>(
    ['cities', 'list', { search }],
    async () => {
      const res = await getCitiesList(params);
      return res;
    },
    {
      staleTime: Infinity,
      retry: false,
    },
  );
};

export const useGetRegionsList = (params: { search: string }) => {
  const { search } = params;
  return useQuery<ICity[]>(
    ['cities', 'list', { search }],
    async () => {
      const res = await getRegionsList(params);
      return res;
    },
    {
      staleTime: Infinity,
      retry: false,
    },
  );
};
