import { FC, useMemo } from 'react';
import { ROUTES } from 'app';

import {
  AddEditQADrawer,
  Layout,
  QAHeader,
  QATable,
  ShowcasesInnerNavbar,
} from 'components';
import { useDrawer } from 'hooks';

export const QA: FC = () => {
  const {
    openAddDrawer,
    editDrawerId,
    purpose,
    hideAddEditDrawer,
    showAddEditDrawer,
  } = useDrawer();

  const SERVICES_INNER_NAVBAR_MENU_ITEMS = useMemo(
    () => [
      {
        title: 'Как выполнять нормативы',
        path: ROUTES.services.instructions,
      },
      {
        title: 'Вопрос-ответ',
        path: ROUTES.services.qa,
      },
      {
        title: 'Как подготовиться к нормативам',
        path: ROUTES.services.meAndGto,
      },
    ],
    [],
  );

  return (
    <Layout withoutPB>
      <AddEditQADrawer
        open={showAddEditDrawer}
        onClose={hideAddEditDrawer}
        purpose={purpose}
        id={editDrawerId}
      />
      <QAHeader openAddQADrawer={openAddDrawer} />
      <ShowcasesInnerNavbar menuItems={SERVICES_INNER_NAVBAR_MENU_ITEMS} />
      <QATable />
    </Layout>
  );
};
