import { FC, useState, useMemo } from 'react';
import { styled } from '@mui/system';
import { Box, TextField } from '@mui/material';
import { useGetCitiesList } from 'hooks';
import { FormItem, Button, Map, SearchSelectInput } from 'UI';
import { COLORS } from 'utils';
import { IShowcaseOfferDiscountBranch } from 'types';

const StyledContainer = styled(Box)({
  padding: '1.25rem',
  borderRadius: '0.5rem',
  display: 'flex',
  flexDirection: 'column',
  gap: '0.625rem',
  border: `1px solid ${COLORS.BLACK}`,
  marginBottom: '1.25rem',
});

interface IBranchCardProps extends IShowcaseOfferDiscountBranch {
  handleFieldChange: <T extends keyof IShowcaseOfferDiscountBranch>(
    _id: IShowcaseOfferDiscountBranch['id'],
    _field: T,
    _value: IShowcaseOfferDiscountBranch[T],
  ) => void;
  deleteBranch: (_id: IShowcaseOfferDiscountBranch['id']) => void;
}

export const BranchCard: FC<IBranchCardProps> = ({
  id,
  city_name,
  location,
  address,
  phone,
  work_time,
  handleFieldChange,
  deleteBranch,
}) => {
  const [citySearchValue, setCitySearchValue] = useState<string>(city_name);

  const { data: citiesList } = useGetCitiesList({ search: citySearchValue });

  const cityOptions = useMemo(() => {
    if (!citiesList) return [];
    return citiesList.map(city => {
      return {
        label: city.name,
        value: city.name,
      };
    });
  }, [citiesList]);

  return (
    <StyledContainer>
      <FormItem label='Название города'>
        <SearchSelectInput
          options={cityOptions}
          value={city_name}
          onChange={newVal =>
            handleFieldChange(id, 'city_name', newVal as string)
          }
          inputValue={citySearchValue}
          onInputChange={newVal => setCitySearchValue(newVal)}
          placeholder='Выбрать город'
          variant='standard'
        />
      </FormItem>
      <FormItem label='Адрес'>
        <TextField
          variant='standard'
          color='primary'
          placeholder='Введите адрес'
          name='address'
          value={address}
          onChange={e => handleFieldChange(id, 'address', e.target.value)}
        />
      </FormItem>
      <FormItem label='Телефон номер'>
        <TextField
          variant='standard'
          color='primary'
          placeholder='Введите номер'
          name='phone'
          value={phone}
          onChange={e => handleFieldChange(id, 'phone', e.target.value)}
        />
      </FormItem>
      <FormItem label='Рабочая время'>
        <TextField
          variant='standard'
          color='primary'
          placeholder='Введите время'
          name='work_time'
          value={work_time}
          onChange={e => handleFieldChange(id, 'work_time', e.target.value)}
        />
      </FormItem>
      <Map
        location={location}
        changeLocation={newValue => handleFieldChange(id, 'location', newValue)}
      />
      <Button
        variant='contained'
        color='secondary'
        onClick={() => deleteBranch(id)}
      >
        Удалить
      </Button>
    </StyledContainer>
  );
};
