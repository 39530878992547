import { styled } from '@mui/system';
import { Box } from '@mui/material';
import { COLORS } from 'utils';

export const StyledBody = styled(Box)({
  width: '375px',
  margin: '0 auto',
  backgroundColor: COLORS.WHITE,
  borderRadius: '0.5rem',
  overflow: 'hidden',
  '& ul, ol': {
    paddingLeft: '1.25rem',
  },
});

export const StyledCover = styled(Box)({
  width: '100%',
  height: 'auto',
  position: 'relative',
  '& .backButton': {
    position: 'absolute',
    backgroundColor: 'transparent',
    top: '3rem',
    border: 'none',
    padding: '0 1.25rem',
  },
  '& img': {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    objectPosition: 'center',
    display: 'block',
  },
});

export const StyledTitle = styled(Box)({
  position: 'absolute',
  bottom: 0,
  fontSize: '1.5rem',
  fontWeight: 600,
  lineHeight: '2rem',
  fontFamily: '"SF Pro Display", sans-serif',
  margin: '1.25rem',
  textAlign: 'left',
  color: COLORS.WHITE,
});

export const UpperBlock = styled(Box)({
  position: 'absolute',
  top: 0,
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  color: COLORS.WHITE,
  padding: '1rem 1.25rem',
  fontSize: '1rem',
  fontFamily: '"SF Pro Text"',
  fontWeight: 600,
  letterSpacing: '-0.01338rem',
});

export const UpperBlockIcons = styled(Box)({
  display: 'flex',
  gap: '0.25rem',
  '& svg': {
    height: '0.75rem',
  },
});

export const StyledNewsBody = styled(Box)({
  backgroundColor: '#201c1c',
  padding: '1rem 1.25rem',
  color: COLORS.WHITE,
});

export const StyledMetaBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  width: '100%',
  fontFamily: '"SF Pro Display", sans-serif',
  lineHeight: '1.375rem',
  marginBottom: '1rem',
  gap: '0.5rem',
});

export const StyledCategoryBadge = styled(Box)({
  backgroundColor: '#870000',
  color: COLORS.WHITE,
  padding: '0.375rem 0.75rem',
  borderRadius: '6.25rem',
  fontWeight: 600,
  fontSize: '0.75rem',
  lineheight: '1.125rem',
});

export const StyledTagBadge = styled(Box)({
  color: 'rgba(255,255,255, 0.5)',
  backgroundColor: 'rgba(255,255,255,0.05)',
  padding: '0.375rem 0.75rem',
  borderRadius: '6.25rem',
  fontWeight: 500,
  fontSize: '0.75rem',
  lineheight: '1.125rem',
});

export const StyledStatisticsBox = styled(Box)({
  marginBottom: '1rem',
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  alignItems: 'center',
  '& p': {
    fontSize: '0.75rem',
    lineHeight: '1.125rem',
  },
  '& .publishTime': {
    display: 'flex',
    gap: '0.5rem',
    alignItems: 'center',
  },
  '& .stats': {
    display: 'flex',
    gap: '1rem',
  },
});

export const StyledStatistics = styled(Box)({
  display: 'flex',
  gap: '0.5rem',
  alignItems: 'center',
  width: '100%',
});

export const StyledNewsContent = styled(Box)({
  fontFamily: 'SF Pro Display, sans-serif',
  fontSize: '1rem',
  lineHeight: '1.25rem',
  color: COLORS.WHITE,
  '& blockquote + *': {
    marginBottom: '1.25rem',
  },
  '& h1, h2, h3, h4, h5, p, ol, ul, table, img, .columns, blockquote': {
    marginBottom: '1.25rem',
  },
  '& .table-container': {
    width: '100%',
    overflow: 'auto',
  },

  '& figure': {
    overflow: 'auto',
  },

  '& li, p': {
    '& a': {
      display: 'inline',
    },
  },
  '& table': {
    tableLayout: 'auto',
    width: '100%',
    height: '100%',
    overflow: 'auto',
  },
  '& .ck-editor__editable': {
    minWidth: 'auto',
  },
  '& .gallery': {
    display: 'flex',
    overflow: 'auto',
    margin: '0 -1rem 1rem',

    '&::-webkit-scrollbar': {
      height: '0.625rem',
    },

    '&-row': {
      margin: 0,
      minWidth: '750px',
      flexWrap: 'nowrap',

      '& a': {
        display: 'block',
        height: '100%',

        '& img': {
          maxWidth: '250px',
          minWidth: '250px',
          minHeight: '250px',
          maxHeight: '250px',
          display: 'block',
          objectFit: 'cover',
        },
      },
    },
  },
  '& a': {
    display: 'block',
    textDecoration: 'none',
    color: '#017BFF',
  },
  '& table td, th': {
    border: '1px solid #e8e8eb',
    borderRadius: '0.5rem',
    padding: '0.25rem',
  },
  '& blockquote': {
    padding: '0.25rem 0 0.25rem 1.25rem',
    borderLeft: `3px solid ${COLORS.BLUE}`,
  },
  '& .columns': {
    display: 'flex',

    '&-2': {
      '& > div': {
        width: '50%',
      },
    },

    '&-3': {
      '& > div': {
        width: '33.3333%',
      },
    },

    '&-4': {
      '& > div': {
        width: '25%',
      },
    },
  },
  '& .image': {
    width: '100%',

    '&.stretched': {
      maxWidth: '100%',
    },
    '&.big': {
      maxWidth: '75%',
    },
    '&.medium': {
      maxWidth: '50%',
    },
    '&.small': {
      maxWidth: '25%',
    },
    '&.with-border': {
      border: '1px solid #e8e8eb',
    },
    '&.with-background': {
      backgroundColor: COLORS.LIGHT_BLUE,
    },
  },
  '& .ck-widget.table td.ck-editor__nested-editable.ck-editor__nested-editable_focused, .ck-widget.table td.ck-editor__nested-editable:focus, .ck-widget.table th.ck-editor__nested-editable.ck-editor__nested-editable_focused, .ck-widget.table th.ck-editor__nested-editable:focus':
    {
      outline: 'none',
      backgroundColor: 'transparent',
      borderStyle: 'solid',
    },
  '& details': {
    '& summary::marker': {
      color: COLORS.LIGHT_BLUE,
    },

    '& p': {
      paddingLeft: '1.25rem',
    },
  },
  '& .cdx-attaches': {
    position: 'relative',
    marginBottom: '15px',

    '&--with-file': {
      display: 'flex',
      alignItems: 'center',
      padding: '10px 12px',
      border: '1px solid #EFF0F1',
      borderRadius: '7px',
      background: '#fff',

      '& .cdx-attaches__file-info': {
        display: 'grid',
        gridGap: '4px',
        maxWidth: 'calc(100% - 80px)',
        margin: 'auto 0',
        flexGrow: '2',
      },

      '& .cdx-attaches__title': {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        outline: 'none',
        maxWidth: '90%',
        fontSize: '14px',
        fontWeight: '500',
        lineHeight: '1em',
      },

      '& .cdx-attaches__size': {
        color: '#707684',
        fontSize: '12px',
        lineHeight: '1em',

        '&:after': {
          content: 'attr(data-size)',
          marginLeft: '0.2em',
        },
      },
    },

    '& .cdx-attaches__title': {
      maxWidth: '100%',
    },

    '&__file-icon': {
      padding: '9px 15px 9px 5px',
      backgroundColor: 'rgb(170, 34, 132)',
      borderRadius: '6px',
      marginRight: '12px',
      fontSize: '10px',
      fontWeight: '700',
      color: '#ffffff',
      textTransform: 'uppercase',
      lineHeight: '1em',

      '& p': {
        margin: 0,
      },
    },

    '& .cdx-attaches__download-button': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'transparent',

      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  },
});
