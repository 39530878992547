export const CommentIcon = () => {
  return (
    <svg
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g opacity='0.5' clipPath='url(#clip0_6169_132629)'>
        <path
          d='M6 7.5H12M6 10.5H9M9 16.5C13.1422 16.5 16.5 13.1422 16.5 9C16.5 4.85775 13.1422 1.5 9 1.5C4.85775 1.5 1.5 4.85775 1.5 9C1.5 10.3657 1.86525 11.6475 2.5035 12.75L1.875 16.125L5.25 15.4965C6.38966 16.1558 7.68338 16.502 9 16.5V16.5Z'
          stroke='black'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_6169_132629'>
          <rect width='18' height='18' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};
