import { IShowcaseOfferDiscountReq } from 'types';
import { IDiscountsFile } from './types';

export const preProcessDiscountsAndBranches = (
  productInfo: IShowcaseOfferDiscountReq,
) => {
  const product = { ...productInfo };
  product.branches = product.branches.map(branch => {
    if (+branch.id < 0) {
      return {
        ...branch,
        id: 0,
      };
    }
    return branch;
  });
  product.discounts = product.discounts.map(discount => {
    if (+discount.id < 0) {
      return {
        ...discount,
        id: 0,
      };
    }
    return discount;
  });
  return product;
};

export const preProcessDiscountFiles = (
  productInfo: IShowcaseOfferDiscountReq,
  discountsFiles: IDiscountsFile[],
  setDiscountsFiles: React.Dispatch<React.SetStateAction<IDiscountsFile[]>>,
) => {
  const newAddedDiscountsWithFiles = productInfo.discounts
    .filter(dis => +dis.id < 0)
    .filter(dis => dis.type === 'promocode_unique');
  const arrayOfIdIndexes = newAddedDiscountsWithFiles.map(discount => {
    const indexOfDiscount = productInfo.discounts.findIndex(
      dis => dis.id === discount.id,
    );
    return {
      id: discount.id,
      index: indexOfDiscount,
    };
  });
  const filesArray = [...discountsFiles];
  arrayOfIdIndexes.forEach(({ id: arrayId, index }) => {
    const el = filesArray.find(file => file.discount_id === arrayId);
    if (!el) return;
    el.index = index;
  });
  setDiscountsFiles(filesArray);
};

export const handleChange = <T extends keyof IShowcaseOfferDiscountReq>(
  fieldName: T,
  newValue: IShowcaseOfferDiscountReq[T],
  setProductInfo: React.Dispatch<
    React.SetStateAction<IShowcaseOfferDiscountReq>
  >,
  purpose: 'add' | 'edit',
) => {
  if (fieldName === 'category_id' && purpose === 'edit') {
    setProductInfo(prevProductInfo => ({
      ...prevProductInfo,
      new_category_id: newValue as string,
    }));
    return;
  }
  if (fieldName === 'description_html') {
    setProductInfo(prevProductInfo => ({
      ...prevProductInfo,
      [fieldName]: newValue,
      description_json: JSON.stringify({ value: newValue }),
    }));
    return;
  }
  if (fieldName === 'is_indefinite') {
    setProductInfo(prevProductInfo => ({
      ...prevProductInfo,
      expired_at: '',
      [fieldName]: newValue,
    }));
  }
  setProductInfo(prevProductInfo => ({
    ...prevProductInfo,
    [fieldName]: newValue,
  }));
};
