import { useRef, useEffect, useContext, useMemo } from 'react';
import {
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemIcon,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

import IconGto from 'assets/images/gto_spaces.jpg';

import { UserContext, UserContextType } from 'app';
import { useLogout } from 'hooks';
import { Button } from 'UI';
import { COLORS } from 'utils';
import { UserInfo } from './UserInfo';
import { SpacePicker } from './SpacePicker';
import { MenuItems } from './MenuItems';
import {
  StyledSidebarBox,
  StyledMenuBox,
  StyledLogoutBox,
} from './styledComponents';

export const Sidebar = () => {
  const menuRef = useRef<HTMLDivElement>(null);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { mutateAsync: logout } = useLogout();
  const {
    userData,
    space_name: companyName,
    space,
    role_name: userRole = '',
    role_id
  } = useContext(UserContext) as UserContextType;

  const spaceImageData = space?.id === 1 ? IconGto : space?.logo

  const userInfo = useMemo(() => {
    return {
      companyName,
      companyImageUrl: spaceImageData,
      userImageUrl: userData?.photo || undefined,
      userRole,
      firstName: userData?.firstname,
      lastName: userData?.lastname,
    };
  }, [userData, space?.id]);

  useEffect(() => {
    // Restore the scroll position when the component mounts
    const savedScrollPosition = sessionStorage.getItem('menuScrollPosition');
    const menuElement = menuRef.current;
    if (savedScrollPosition && menuElement) {
      menuElement.scrollTop = parseInt(savedScrollPosition, 10);
    }
  }, [pathname]);

  const menuItems = useMemo(() => {
    return MenuItems.filter(item => item.roles.includes(role_id || 0));
  }, [userData, role_id]);

  const redirectUser = (path: string) => {
    if (pathname === path) return;
    const menuElement = menuRef.current;
    if (menuElement) {
      sessionStorage.setItem(
        'menuScrollPosition',
        menuElement.scrollTop.toString(),
      );
    }
    navigate(path);
  };

  const logoutUser = () => {
    logout();
  };

  return (
    <StyledSidebarBox>
      <UserInfo {...userInfo} />
      <SpacePicker />
      <StyledMenuBox ref={menuRef}>
        <List>
          {menuItems.map(item => (
            <ListItem
              key={item.path}
              disablePadding
              onClick={() => redirectUser(item.path)}
            >
              <ListItemButton>
                <ListItemIcon>
                  <item.icon
                    activeColor={COLORS.RED}
                    isActive={pathname.startsWith(item.path)}
                  />
                </ListItemIcon>
                <ListItemText primary={item.title} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </StyledMenuBox>
      <StyledLogoutBox>
        <Button color='customWhite' size='large' onClick={logoutUser}>
          Выход
        </Button>
      </StyledLogoutBox>
    </StyledSidebarBox>
  );
};
