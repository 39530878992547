import { IPaginatedReqParams, IWorkoutsCalendarReq } from 'types';
import { http, methods } from './config';

export const getWorkoutsCalendars = (params: IPaginatedReqParams) => {
  const config = {
    method: methods.get,
    url: `/training/program`,
    params,
  };
  return http(config);
};

export const getWorkoutsCalendar = (id: string | number) => {
  const config = {
    method: methods.get,
    url: `/training/program/${id}`,
  };
  return http(config);
};

export const createWorkoutsCalendar = (data: IWorkoutsCalendarReq) => {
  const config = {
    method: methods.post,
    url: `/training/program`,
    data,
  };
  return http(config);
};

export const updateWorkoutsCalendar = (data: IWorkoutsCalendarReq) => {
  const config = {
    method: methods.put,
    url: `/training/program/${data.id}`,
    data,
  };
  return http(config);
};

export const deleteWorkoutsCalendar = (workoutCalendarId: string | number) => {
  const config = {
    method: methods.delete,
    url: `/training/program/${workoutCalendarId}`,
  };
  return http(config);
};

export const getWorkoutCalendarTrainingTypesList = () => {
  const config = {
    method: methods.get,
    url: `/list/training_types`,
  };
  return http(config);
};
