import { FC } from 'react';
import { Box } from '@mui/material';
import { UserImage } from 'UI';
import { IMarathonParticipantTeam } from 'types';

interface IWinnerInfoTeamProps {
  winner: IMarathonParticipantTeam;
}

export const WinnerInfoTeam: FC<IWinnerInfoTeamProps> = ({ winner }) => {
  const { logo, team_name } = winner;
  return (
    <Box className='winnerInfo'>
      <Box className='photo'>
        <UserImage
          src={logo}
          alt={team_name}
          firstName={team_name}
          width='2.5rem'
          height='2.5rem'
        />
      </Box>
      <Box className='name'>{team_name}</Box>
    </Box>
  );
};
