import { FC } from 'react';
import { Button, PageHeader, GlobalSearch } from 'UI';
import { PlusIcon } from 'assets';

interface IPartnerHeaderProps {
  openAddPartnerModal: () => void;
}

export const PartnersHeader: FC<IPartnerHeaderProps> = ({
  openAddPartnerModal,
}) => {
  return (
    <PageHeader title='Партнеры'>
      <GlobalSearch />
      <Button
        variant='contained'
        startIcon={<PlusIcon />}
        onClick={openAddPartnerModal}
      >
        Добавить
      </Button>
    </PageHeader>
  );
};
