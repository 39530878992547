import { styled } from '@mui/system';
import { Box } from '@mui/material';
import { COLORS } from 'utils';

export const SpaceCardWrapper = styled(Box)({
  borderRadius: '0.5rem',
  backgroundColor: COLORS.WHITE,
  padding: '1.875rem',
  width: '100%',
  height: '100%',
  maxHeight: '25rem',
  display: 'flex',
  flexDirection: 'column',
  '& .logoBox': {
    width: '100%',
    marginBottom: '1rem',
    display: 'flex',
    justifyContent: 'space-between',
    '& .logo': {
      width: '5rem',
      height: '5rem',
      borderRadius: '50%',
      overflow: 'hidden',
      backgroundColor: COLORS.RED,
      cursor: 'pointer',
      '& img': {
        width: '100%',
        height: '100%',
        objectFit: 'contain',
      },
    },
  },
  '& h2': {
    fontSize: '1.25rem',
    lineHeight: '1.75rem',
    fontWeight: 600,
    fontFamily: '"SF Pro Display", sans-serif',
    margin: '0 0 0.25rem',
    cursor: 'pointer',
  },
});

export const StyledHr = styled(Box)({
  width: '100%',
  height: '1px',
  backgroundColor: 'rgba(0,0,0,0.1)',
  marginBottom: '1rem',
  marginTop: 'auto',
});

export const StyledSpaceInfo = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '1rem',
  fontFamily: '"SF Pro Display", sans-serif',
  marginBottom: '1rem',
  '& .label': {
    fontSize: '0.625rem',
    lineHeight: '1rem',
    fontWeight: 400,
    color: COLORS.BLACK,
    opacity: 0.5,
  },
  '& .value': {
    fontSize: '1rem',
    lineHeight: '1.5rem',
    fontWeight: 600,
    color: COLORS.BLACK,
  },
});
