import * as Pages from 'pages';
import { ROLES } from 'utils';
import { IRoutes } from '../types';

export const Regions: IRoutes = {
  regions: {
    path: '/regions',
    title: 'regions',
    element: <Pages.Regions />,
    roles: [ROLES.ADMINSTRATOR],
  },
};
