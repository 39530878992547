import { FC, ChangeEvent, useContext, useEffect } from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { MediaType } from 'types';
import { useUploadMedia } from 'hooks';
import { notify, COLORS } from 'utils';
import { DeleteIcon, ImageUploadIcon } from 'assets';
import { UserContextType, UserContext } from 'app';

const StyledContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '0.25rem',
  '& .title': {
    fontSize: '0.75rem',
    fontFamily: '"SF Pro Display", sans-serif',
    fontWeight: 500,
    opacity: 0.5,
  },
  '& .content': {
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
  },
});

const StyledUploadButton = styled(Box)({
  padding: '1.25rem',
  border: `2px solid ${COLORS.RED_2}`,
  borderRadius: '0.5rem',
  display: 'flex',
  gap: '0.625rem',
  cursor: 'pointer',
  '& span': {
    fontSize: '1rem',
    fontFamily: '"SF Pro Display", sans-serif',
    lineHeight: '1.5rem',
    fontWeight: 600,
  },
});

const StyledImageBox = styled(Box)({
  border: `2px solid rgba(0, 0, 0, 0.2)`,
  borderRadius: '0.5rem',
  width: '4rem',
  height: '4rem',
  position: 'relative',
  overflow: 'hidden',
  '& img': {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    objectPosition: 'center',
    zIndex: -1,
  },
  '& .removeBox': {
    width: '100%',
    height: '100%',
    backgroundColor: `rgba(206, 53, 72, 0.70)`,
    position: 'absolute',
    visibility: 'hidden',
  },
  '& .deleteIcon': {
    position: 'absolute',
    top: '50%',
    left: '50%',
    cursor: 'pointer',
    transform: 'translate(-50%, -50%)',
  },
  '&:hover': {
    '& .removeBox': {
      visibility: 'visible',
    },
  },
});

interface IMultiImageUploaderProps {
  images: string[];
  addImage: (_images: string[]) => void;
  removeImage: (_image: string) => void;
  inputId: string;
  imageSize?: number;
  mediaType: MediaType;
}

export const MultiImageUploader: FC<IMultiImageUploaderProps> = ({
  images = [],
  addImage,
  removeImage,
  inputId,
  imageSize = 1 * 1024 * 1024,
  mediaType,
}) => {
  const { space_id } = useContext(UserContext) as UserContextType;
  const { mutateAsync: uploadMedia } = useUploadMedia();

  const handleOpenFileUploadModal = () => {
    document.getElementById(inputId)?.click();
  };

  const convertImageSizeToText = (): string => {
    if (imageSize >= 1000000) {
      const sizeInMB = imageSize / 1000000;
      return `${sizeInMB.toFixed(1)} МБ`;
    }
    const sizeInKB = imageSize / 1000;
    return `${sizeInKB.toFixed(0)} КБ`;
  };

  const handleUploadImage = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }
    const allFiles = Array.from(e.target.files);
    if (allFiles.length < 1) {
      return;
    }
    for (let i = 0; i < allFiles.length; i++) {
      const file = allFiles[i];
      if (file.size > imageSize) {
        notify(
          `Размер файла превышает максимальный предел в ${convertImageSizeToText()}.`,
          'error',
        );
        return;
      }
    }

    Promise.all(
      allFiles.map(file => {
        const formData = new FormData();
        formData.append('image', file, file.name);
        formData.append('type', mediaType);
        formData.append('space_id', space_id as string);
        return uploadMedia({ data: formData });
      }),
    )
      .then(res => {
        const newImages = res.map((r: string) => r);
        addImage(newImages);
      })
      .catch(() => {
        notify('Произошла ошибка при загрузке изображений', 'error');
      });
  };

  useEffect(() => {
    const input = document.getElementById(inputId) as HTMLInputElement;
    if (!input) {
      return;
    }
    // @ts-ignore
    input.addEventListener('change', handleUploadImage);
    return () => {
      // @ts-ignore
      input.removeEventListener('change', handleUploadImage);
    };
  }, [inputId]);

  return (
    <StyledContainer>
      <Box className='title'>Изображения</Box>
      <Box className='content'>
        {images?.map(image => (
          <StyledImageBox key={image}>
            <Box className='removeBox'>
              <DeleteIcon
                className='deleteIcon'
                color={COLORS.WHITE}
                onClick={() => removeImage(image)}
              />
            </Box>
            <img src={image} alt='product' />
          </StyledImageBox>
        ))}
        <StyledUploadButton onClick={handleOpenFileUploadModal}>
          <ImageUploadIcon className='uploadIcon' color={COLORS.RED_2} />
          <span>Загрузить файлы</span>
        </StyledUploadButton>

        <input id={inputId} type='file' style={{ display: 'none' }} multiple />
      </Box>
    </StyledContainer>
  );
};
