import * as Pages from 'pages';
import { ROLES } from 'utils';
import { IRoutes } from '../types';

export const Notifications: IRoutes = {
  notifications: {
    path: '/notifications',
    title: 'notifications',
    element: <Pages.Notifications />,
    roles: [ROLES.ADMINSTRATOR],
  },
};
