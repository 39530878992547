import { IPrizeReq } from 'types';
import { http, methods } from './config';

export const createPrize = (data: IPrizeReq) => {
  const config = {
    method: methods.post,
    url: `/marathon/trophy`,
    data,
  };
  return http(config);
};

export const updatePrize = (data: IPrizeReq) => {
  const config = {
    method: methods.put,
    url: `/marathon/trophy`,
    data,
  };
  return http(config);
};
