import { Box } from '@mui/material';
import { styled } from '@mui/system';

export const StyledBox = styled(Box)({
  padding: '0 1rem 0.9375rem',
});

export const StyledCompanyInfo = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  // justifyContent: 'center',
  gap: '1rem',
  marginBottom: '1.75rem',
  '& img': {
    maxWidth: '70%',
    maxHeight: '2rem',
    objectFit: 'cover',
    objectPosition: 'center',
    borderRadius: '50%'
  },
  '& p': {
    fontSize: '0.875rem',
    lineHeight: '1rem',
    fontWeight: 500,
    fontFamily: '"SF Pro Display", sans-serif',
  },
});

export const StyledUserInfo = styled(Box)({
  display: 'flex',
  // flexDirection: 'column',
  alignItems: 'center',
  gap: '1rem',
  marginBottom: '0.5rem',
  '& img': {
    width: '2.5rem',
    height: '2.5rem',
    borderRadius: '50%',
    // marginBottom: '0.5rem',
    objectFit: 'cover',
    objectPosition: 'center',
  },
  '& .image': {
    fontSize: '0.75rem'
  },
});

export const StyledUserName = styled(Box)({
  fontSize: '1rem',
  fontWeight: 600,
  lineHeight: '1.375rem',
});

export const StyledUserRole = styled(Box)({
  fontSize: '0.75rem',
  fontWeight: 400,
  lineHeight: '1.25rem',
  opacity: 0.5,
});
