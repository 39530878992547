import { FC, useState, useMemo, useEffect } from 'react';
import { TextField } from '@mui/material';
import {
  Drawer,
  DrawerHeader,
  DrawerFormBody,
  ModalTitle,
  Button,
  StyledFlexBox,
  FormItem,
  SearchSelectInput,
} from 'UI';
import { CloseIcon } from 'assets';
import { IRegionReq } from 'types';
import {
  useGetRegionsList,
  useGetCity,
  useCreateCity,
  useUpdateCity,
} from 'hooks';

const TRANSLATIONS = {
  add: {
    title: 'Создать город',
  },
  edit: {
    title: 'Редактировать город',
  },
};

const DEFAULT_VALUES = {
  name: '',
  region_id: 0,
};

interface IAddEditRegionDrawerProps {
  open: boolean;
  onClose: () => void;
  purpose: 'add' | 'edit';
  id: string;
}

export const AddEditRegionDrawer: FC<IAddEditRegionDrawerProps> = ({
  open,
  onClose,
  purpose = 'add',
  id = '',
}) => {
  const { data: cityInfoData } = useGetCity(id);
  const [cityInfo, setCityInfo] = useState<IRegionReq>(DEFAULT_VALUES);
  const [citySearchValue, setCitySearchValue] = useState<string>('');

  const { data: citiesList } = useGetRegionsList({ search: citySearchValue });

  const { mutateAsync: createCity } = useCreateCity();
  const { mutateAsync: updateCity } = useUpdateCity();

  const cityOptions = useMemo(() => {
    if (!citiesList) return [];
    return citiesList.map(city => {
      return {
        label: city.name,
        value: city.id,
      };
    });
  }, [citiesList]);

  useEffect(() => {
    if (purpose === 'edit') {
      setCityInfo({
        city_id: cityInfoData?.id,
        name: cityInfoData?.name || '',
        region_id: cityInfoData?.region.id || 0,
      });
      setCitySearchValue(cityInfoData?.region?.name || '');
    } else {
      setCityInfo(DEFAULT_VALUES);
    }
  }, [purpose, cityInfoData]);

  const handleFieldChange = <T extends keyof IRegionReq>(
    fieldName: T,
    newValue: IRegionReq[T],
  ) => {
    setCityInfo(prevcityInfo => ({
      ...prevcityInfo,
      [fieldName]: newValue,
    }));
  };

  const ignoreChanges = () => {
    setCityInfo(DEFAULT_VALUES);
    onClose();
  };

  const saveChanges = () => {
    if (purpose === 'add') {
      createCity(cityInfo)
        .then(() => {
          onClose();
        })
        .catch(() => {});
    } else {
      updateCity(cityInfo)
        .then(() => {
          onClose();
        })
        .catch(() => {});
    }
  };

  return (
    <Drawer open={open} onClose={ignoreChanges}>
      <DrawerHeader>
        <ModalTitle title={TRANSLATIONS[purpose].title} />
        <CloseIcon onClick={ignoreChanges} />
      </DrawerHeader>
      <DrawerFormBody>
        <h2>О регионе</h2>
        <FormItem label='Название'>
          <TextField
            variant='standard'
            color='primary'
            placeholder='Введите текст'
            name='name'
            value={cityInfo.name}
            onChange={e => handleFieldChange('name', e.target.value)}
          />
        </FormItem>
        <FormItem label='Город'>
          <SearchSelectInput
            options={cityOptions}
            value={cityInfo.region_id}
            onChange={newVal =>
              handleFieldChange('region_id', newVal as number)
            }
            inputValue={citySearchValue}
            onInputChange={newVal => setCitySearchValue(newVal)}
            placeholder='Выбрать регион'
            variant='standard'
          />
        </FormItem>
      </DrawerFormBody>
      <StyledFlexBox className='buttons fullDivWidth'>
        <Button
          onClick={ignoreChanges}
          variant='contained'
          size='large'
          color='customGray'
        >
          Отмена
        </Button>
        <Button
          variant='contained'
          size='large'
          color='primary'
          onClick={saveChanges}
        >
          Сохранить
        </Button>
      </StyledFlexBox>
    </Drawer>
  );
};
