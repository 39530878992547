import { FC, useState, useMemo, MouseEvent, useEffect, Fragment } from 'react';
import { Box, Popover } from '@mui/material';
import {
  useSearchMarathonSpacesForBanners,
  useSearchProductsForBanners,
  useSearchNewsForBanners,
} from 'hooks';
import { SearchInput, Button } from 'UI';
import { DeleteIcon } from 'assets';
import { COLORS } from 'utils';
import {
  IMarathonSpaceSingle,
  INews,
  INewsShort,
  IShowcaseOfferDiscount,
} from 'types';

import {
  StyledContainer,
  StyledSearchComponent,
  StyledSelectProductItem,
  StyledSelectedProduct,
} from './styledComponents';

interface IChoosePlaceProps {
  placeType: 0 | 1 | 3;
  choosePlace: (_id: string | number) => void;
  defaultSelectedPlace?:
    | IShowcaseOfferDiscount
    | IMarathonSpaceSingle
    | INewsShort;
}

export const ChoosePlace: FC<IChoosePlaceProps> = ({
  placeType,
  choosePlace,
  defaultSelectedPlace,
}) => {
  const [showSearchComponent, setShowSearchComponent] =
    useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [searchValue, setSearchValue] = useState<string>('');
  const [selectedPlace, setSelectedPlace] = useState<
    IShowcaseOfferDiscount | IMarathonSpaceSingle | INewsShort | undefined
  >(defaultSelectedPlace);

  useEffect(() => {
    setSelectedPlace(defaultSelectedPlace);
  }, [defaultSelectedPlace]);

  const paramsToGetProducts = useMemo(() => {
    return {
      search: searchValue,
    };
  }, [searchValue]);

  const paramsToGetMarathonSpaces = useMemo(() => {
    return {
      search: searchValue,
    };
  }, [searchValue]);

  const {
    data: products,
    fetchNextPage: fetchNextPageProducts,
    hasNextPage: hasNextPageProducts,
    isFetchingNextPage: isFetchingNextPageProducts,
  } = useSearchProductsForBanners(paramsToGetProducts);
  const {
    data: challenges,
    fetchNextPage: fetchNextPageChallenges,
    hasNextPage: hasNextPageChallenges,
    isFetchingNextPage: isFetchingNextPageChallenges,
  } = useSearchMarathonSpacesForBanners(paramsToGetMarathonSpaces);
  const {
    data: news,
    fetchNextPage: fetchNextPageNews,
    hasNextPage: hasNextPageNews,
    isFetchingNextPage: isFetchingNextPageNews,
  } = useSearchNewsForBanners(paramsToGetProducts);

  const openSearchComponent = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setShowSearchComponent(true);
  };

  const hideSearchComponent = () => {
    setShowSearchComponent(false);
    setAnchorEl(null);
  };

  const handleSearch = (search: string) => {
    setSearchValue(search);
  };

  const handlechoosePlace = (
    place: IMarathonSpaceSingle | IShowcaseOfferDiscount | INews,
  ) => {
    choosePlace(place.id);
    setSelectedPlace(place);
    hideSearchComponent();
  };

  const loadNextPage = () => {
    switch (placeType) {
      case 0:
        fetchNextPageProducts();
        break;
      case 1:
        fetchNextPageChallenges();
        break;
      case 3:
        fetchNextPageNews();
        break;
      default:
        break;
    }
  };

  const getHasNextPage = () => {
    switch (placeType) {
      case 0:
        return hasNextPageProducts;
      case 1:
        return hasNextPageChallenges;
      case 3:
        return hasNextPageNews;
      default:
        return false;
    }
  };

  const getIsLoadingNextPage = () => {
    switch (placeType) {
      case 0:
        return isFetchingNextPageProducts;
      case 1:
        return isFetchingNextPageChallenges;
      case 3:
        return isFetchingNextPageNews;
      default:
        return false;
    }
  };

  const getAttributesFromSelectedPlace = useMemo(() => {
    if (!selectedPlace) {
      return {
        title: '',
        cover: '',
      };
    }
    if (placeType === 0) {
      return {
        title: (selectedPlace as IShowcaseOfferDiscount).title,
        cover: (selectedPlace as IShowcaseOfferDiscount).cover_big,
      };
    }
    if (placeType === 3) {
      return {
        title: (selectedPlace as INews).name,
        cover: (selectedPlace as INews).cover,
      };
    }
    return {
      title: (selectedPlace as IMarathonSpaceSingle).name,
      cover: (selectedPlace as IMarathonSpaceSingle).cover,
    };
  }, [selectedPlace]);

  return (
    <StyledContainer>
      <Popover
        id={showSearchComponent ? 'selectPlace' : undefined}
        open={showSearchComponent}
        anchorEl={anchorEl}
        onClose={hideSearchComponent}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <StyledSearchComponent>
          <SearchInput
            handleSearch={search => handleSearch(search)}
            size='large'
          />
          {placeType === 0 &&
            products?.pages.map(productPage => (
              <Fragment key={productPage.current_page}>
                {productPage.data.map(product => (
                  <StyledSelectProductItem
                    key={product.id}
                    onClick={() => handlechoosePlace(product)}
                  >
                    <Box className='imageBox'>
                      <img src={product.cover_big} alt={product.title} />
                    </Box>
                    <span className='name'>{product.title}</span>
                  </StyledSelectProductItem>
                ))}
              </Fragment>
            ))}
          {placeType === 1 &&
            challenges?.pages.map(challengePage => (
              <Fragment key={challengePage.current_page}>
                {challengePage.data.map(challenge => (
                  <StyledSelectProductItem
                    key={challenge.id}
                    onClick={() => handlechoosePlace(challenge)}
                  >
                    <Box className='imageBox'>
                      <img src={challenge.cover} alt={challenge.name} />
                    </Box>
                    <span className='name'>{challenge.name}</span>
                  </StyledSelectProductItem>
                ))}
              </Fragment>
            ))}
          {placeType === 3 &&
            news?.pages.map(newsPage => (
              <Fragment key={newsPage.current_page}>
                {newsPage.data.map(newsEl => (
                  <StyledSelectProductItem
                    key={newsEl.id}
                    onClick={() => handlechoosePlace(newsEl)}
                  >
                    <Box className='imageBox'>
                      <img src={newsEl.cover} alt={newsEl.name} />
                    </Box>
                    <span className='name'>{newsEl.name}</span>
                  </StyledSelectProductItem>
                ))}
              </Fragment>
            ))}
          {getHasNextPage() && (
            <Button
              className='paginationButton'
              color='customLightBlue'
              onClick={loadNextPage}
            >
              {getIsLoadingNextPage() ? 'Загрузка' : 'Загрузить ещё'}
            </Button>
          )}
        </StyledSearchComponent>
      </Popover>
      {selectedPlace ? (
        <StyledSelectedProduct>
          <Box className='data'>
            <Box className='imageBox'>
              <img
                src={getAttributesFromSelectedPlace.cover}
                alt={getAttributesFromSelectedPlace.title}
              />
            </Box>
            <span className='name'>{getAttributesFromSelectedPlace.title}</span>
          </Box>
          <DeleteIcon
            onClick={() => setSelectedPlace(undefined)}
            color={COLORS.RED}
          />
        </StyledSelectedProduct>
      ) : (
        <Button
          onClick={openSearchComponent}
          className='selectButton'
          color='customLightBlue'
          size='large'
        >
          Выбрать{' '}
          {placeType === 0 ? 'товар' : placeType === 1 ? 'вызов' : 'новость'}
        </Button>
      )}
    </StyledContainer>
  );
};
