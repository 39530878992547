import { FC, MouseEvent } from 'react';
import { IMarathonParticipantUser } from 'types';
import { Box } from '@mui/material';
import { UserImage, Button } from 'UI';
import { StyledUserItem } from '../styledComponents';

interface ISectionUsersProps {
  participants: IMarathonParticipantUser[];
  openWinPopover: (
    _e: MouseEvent<HTMLButtonElement>,
    _id: string | number,
  ) => void;
}

export const SectionUsers: FC<ISectionUsersProps> = ({
  participants,
  openWinPopover,
}) => {
  return (
    <>
      {participants.map((participant, index) => (
        <StyledUserItem key={participant.id}>
          <Box className='userInfo'>
            <Box className='place'>{index + 1}</Box>
            <Box className='photo'>
              <UserImage
                src={participant.photo}
                alt={participant.firstname}
                firstName={participant.firstname}
                lastName={participant.lastname}
                width='3.125rem'
                height='3.125rem'
              />
            </Box>
            <Box className='meta'>
              <Box className='name'>
                {participant.firstname} {participant.lastname}
              </Box>
              <Box className='phone'>{participant.phone}</Box>
            </Box>
          </Box>
          <Box className='userStats'>
            <Box className='stats'>{participant.points} очков</Box>
            <Box className='stats'>{participant.ratings} баллов</Box>
            <Box className='stats'>{participant.steps} шагов</Box>
          </Box>
          {/* <Box className='buttonBox'>
            <Button
              color='customLightBlue'
              onClick={e => openWinPopover(e, participant.user_id)}
            >
              Победил
            </Button>
          </Box> */}
        </StyledUserItem>
      ))}
    </>
  );
};
