import { FC } from 'react';
import { styled } from '@mui/system';
import {
  CircularProgressProps,
  CircularProgress as MuiProgress,
  Box,
} from '@mui/material';
import { COLORS } from 'utils';

const StyledContainer = styled(Box)({
  position: 'relative',
  display: 'inline-flex',
});

const StyledContent = styled(Box)({
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: COLORS.BLUE,
  fontSize: '0.875rem',
  fontWeight: 600,
});

interface ICircularProgressProps extends CircularProgressProps {}

export const CircularProgress: FC<ICircularProgressProps> = ({
  value,
  size = 64,
  ...props
}) => {
  return (
    <StyledContainer>
      <MuiProgress variant='determinate' size={size} value={value} {...props} />
      ;<StyledContent>{value}%</StyledContent>
    </StyledContainer>
  );
};
