import {
  IAnalyticsCommonGetParams,
  IAnalyticsTopListGetParams,
} from 'types';

import { methods, http } from '../config';

export const getAnalyticsWorkoutsData = (
  params: IAnalyticsCommonGetParams,
) => {
  return http({
    method: methods.GET,
    url: '/statistics/training/data',
    params,
  });
};

export const getAnalyticsWorkoutsTopListTraining = (
  params: IAnalyticsTopListGetParams,
) => {
  return http({
    method: methods.GET,
    url: '/statistics/training/top_types',
    params,
  });
};

export const getAnalyticsWorkoutsTopListSpace = (
  params: IAnalyticsTopListGetParams,
) => {
  return http({
    method: methods.GET,
    url: '/statistics/training/top_spaces',
    params,
  });
};

export const getAnalyticsWorkoutsGender = (
  params: IAnalyticsCommonGetParams,
) => {
  return http({
    method: methods.GET,
    url: '/statistics/training/gender',
    params,
  });
};

export const getAnalyticsWorkoutsAge = (
  params: IAnalyticsCommonGetParams,
) => {
  return http({
    method: methods.GET,
    url: '/statistics/training/birth',
    params,
  });
};

export const getAnalyticsWorkoutsGraph = (
  params: IAnalyticsCommonGetParams,
) => {
  return http({
    method: methods.GET,
    url: '/statistics/training/shared_stats',
    params,
  });
};
