import { FC, useState, ChangeEvent } from 'react';
import { Pagination, Box, PaginationItem } from '@mui/material';
import { styled } from '@mui/system';
import { COLORS } from 'utils';
import { LeftArrowIcon, RightArrowIcon } from 'assets';
import { useSearchParams } from 'hooks';

const StyledPaginationContainer = styled(Box)({
  height: '3rem',
  padding: '0.5rem 1rem',
  borderTop: `1px solid ${COLORS.GRAY}`,
  position: 'relative',
  bottom: 0,
  backgroundColor: COLORS.WHITE,
  '& .MuiPagination-ul': {
    width: '100%',
    '& li:first-of-type': {
      marginRight: 'auto',
    },
    '& li:last-of-type': {
      marginLeft: 'auto',
    },
  },
});

const StyledPagination = styled(Pagination)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '0.5rem',
  width: '100%',
});

const StyledButtonBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '0.5rem',
  cursor: 'pointer',
});

const CustomPreviousButton: FC = () => {
  return (
    <StyledButtonBox className='left'>
      <LeftArrowIcon color={COLORS.BLUE} />
      Предыдущий
    </StyledButtonBox>
  );
};

const CustomNextButton: FC = () => {
  return (
    <StyledButtonBox className='right'>
      Следующий
      <RightArrowIcon />
    </StyledButtonBox>
  );
};

interface IPaginationComponentProps {
  count: number;
}

export const PaginationComponent: FC<IPaginationComponentProps> = ({
  count,
}) => {
  const { setParam, getParam } = useSearchParams();
  const [page, setPage] = useState<string>(getParam('page'));

  const handleChange = (_event: ChangeEvent<unknown>, value: number) => {
    setPage(value.toString());
    setParam('page', value.toString());
  };

  return (
    <StyledPaginationContainer>
      <StyledPagination
        count={count}
        page={+page}
        shape='rounded'
        boundaryCount={2}
        onChange={handleChange}
        renderItem={item => (
          <PaginationItem
            {...item}
            slots={{
              previous: CustomPreviousButton,
              next: CustomNextButton,
            }}
          />
        )}
      />
    </StyledPaginationContainer>
  );
};
