import { FC } from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { Button, ButtonColor } from 'UI';
import { DeleteIcon } from 'assets';
import { COLORS } from 'utils';

const StyledFooter = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '0.625rem',
  '& button': {
    width: '100%',
    height: '3.125rem',
  },
});

const StyledFooterButtons = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  gap: '0.625rem',
});

interface IModalFooter {
  onCancel?: () => void;
  onConfirm?: () => void;
  onDelete?: () => void;
  withDelete?: boolean;
  cancelButtonText?: string;
  confirmButtonText?: string;
  deleteButtonText?: string;
  confirmButtonColor?: ButtonColor;
  cancelButtonColor?: ButtonColor;
  buttonSize?: 'small' | 'medium' | 'large';
}

export const ModalFooter: FC<IModalFooter> = ({
  onCancel,
  onConfirm,
  onDelete,
  confirmButtonText = 'Создать',
  withDelete = false,
  deleteButtonText = 'Удалить',
  cancelButtonText = 'Отменить',
  confirmButtonColor = 'primary',
  cancelButtonColor = 'customGray',
  buttonSize = 'medium',
}) => {
  return (
    <StyledFooter>
      <StyledFooterButtons>
        <Button
          variant='contained'
          color={confirmButtonColor}
          onClick={onConfirm}
          size={buttonSize}
        >
          {confirmButtonText}
        </Button>
        <Button
          variant='contained'
          color={cancelButtonColor}
          onClick={onCancel}
          size={buttonSize}
        >
          {cancelButtonText}
        </Button>
      </StyledFooterButtons>
      {withDelete && (
        <Box>
          <Button
            variant='text'
            color='secondary'
            onClick={onDelete}
            startIcon={<DeleteIcon color={COLORS.RED} />}
          >
            {deleteButtonText}
          </Button>
        </Box>
      )}
    </StyledFooter>
  );
};
