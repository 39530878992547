import { FC } from 'react';
import { styled } from '@mui/system';
import { Box } from '@mui/material';
import { COLORS, formatNumber } from 'utils';
import {
  AntennaIcon,
  BatteryIcon,
  DropdownIcon,
  StarIcon,
  WifiIcon,
} from 'assets';

const StyledContainer = styled(Box)({
  width: '30%',
});

const StyledPhoneWrapper = styled(Box)({
  width: '18rem',
  height: '38rem',
  borderRadius: '1.25rem',
  border: `11px solid ${COLORS.BLACK}`,
  margin: '0 auto',
  display: 'flex',
  flexDirection: 'column',
  gap: '0.5rem',
  padding: '0 1rem',
});

const UpperBlock = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0.5rem 0',
  fontSize: '0.67rem',
  fontFamily: '"SF Pro Text"',
  fontWeight: 600,
  letterSpacing: '-0.01338rem',
});

const UpperBlockIcons = styled(Box)({
  display: 'flex',
  gap: '0.25rem',
});

const ProductCoverBlock = styled(Box)({
  width: '100%',
  height: '15rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: COLORS.WHITE,
  borderRadius: '0.5rem',
  overflow: 'hidden',
  '& img': {
    width: '100%',
    objectFit: 'cover',
  },
});

const DotsBlock = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '0.35rem',
  '& .dot': {
    width: '0.25rem',
    height: '0.25rem',
    borderRadius: '50%',
    backgroundColor: 'rgba(0, 0, 0, 0.20)',
  },
  '& .redDot': {
    width: '1rem',
    height: '0.25rem',
    borderRadius: '0.25rem',
    backgroundColor: COLORS.RED,
  },
});

const ProductNameBlock = styled(Box)({
  fontSize: '1rem',
  fontWeight: 700,
  color: COLORS.BLACK,
  lineHeight: '1.25rem',
  fontFamily: '"SF Pro Display", sans-serif',
});

const CategoryAndPriceBlock = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  '& .category': {
    backgroundColor: COLORS.BLACK,
    color: COLORS.WHITE,
    padding: '0.1rem 0.35rem',
    borderRadius: '0.5rem',
    fontSize: '0.5rem',
    lineHeight: '0.75rem',
    fontFamily: '"SF Pro Display", sans-serif',
    fontWeight: 400,
  },
  '& .price': {
    color: COLORS.RED,
    fontSize: '1rem',
    fontWeight: 500,
    lineHeight: '0.75rem',
    fontFamily: '"SF Pro Display", sans-serif',
    display: 'flex',
    gap: '0.125rem',
    alignItems: 'center',
  },
});

const DescriptionBlock = styled(Box)({
  padding: '0.5rem',
  backgroundColor: COLORS.WHITE,
  color: COLORS.BLACK,
  fontWeight: 700,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  fontSize: '0.75rem',
  lineHeight: '1.125rem',
  fontFamily: '"SF Pro Display", sans-serif',
  borderRadius: '0.35rem',
});

const ColorBlock = styled(Box)({
  backgroundColor: COLORS.WHITE,
  padding: '0.25rem 0.625rem',
  display: 'flex',
  gap: '0.25rem',
  alignItems: 'center',
  justifyContent: 'center',
  width: 'max-content',
  fontSize: '0.75rem',
  lineHeight: '1.125rem',
  fontFamily: '"SF Pro Display", sans-serif',
  borderRadius: '0.35rem',
  fontWeight: 500,
  '& .color': {
    width: '1rem',
    height: '1rem',
    borderRadius: '50%',
    backgroundColor: COLORS.BLACK,
  },
});

const GrayRectsBlock = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '0.35rem',
  overflowX: 'hidden',
  '& .rect': {
    width: '5rem',
    height: '5rem',
    borderRadius: '0.35rem',
    backgroundColor: '#DCDCDC',
  },
});

interface ICreateProductPhoneProps {
  cover?: string;
  name?: string;
  categoryName?: string;
  price?: number;
}

export const CreateProductPhone: FC<ICreateProductPhoneProps> = ({
  cover,
  name,
  categoryName,
  price,
}) => {
  return (
    <StyledContainer>
      <StyledPhoneWrapper>
        <UpperBlock>
          <Box>9:41</Box>
          <UpperBlockIcons>
            <AntennaIcon color={COLORS.BLACK} />
            <WifiIcon color={COLORS.BLACK} />
            <BatteryIcon color={COLORS.BLACK} />
          </UpperBlockIcons>
        </UpperBlock>
        <ProductCoverBlock>
          {cover && <img src={cover} alt='product cover' />}
        </ProductCoverBlock>
        <DotsBlock>
          <Box className='redDot' />
          <Box className='dot' />
          <Box className='dot' />
          <Box className='dot' />
          <Box className='dot' />
          <Box className='dot' />
        </DotsBlock>
        <ProductNameBlock>{name}</ProductNameBlock>
        <CategoryAndPriceBlock>
          <Box className='category'>{categoryName}</Box>
          <Box className='price'>
            <StarIcon opacity='1' color={COLORS.RED} />
            <Box>{formatNumber(price || 0)}</Box>
          </Box>
        </CategoryAndPriceBlock>
        <DescriptionBlock>
          <Box>Описание</Box>
          <DropdownIcon />
        </DescriptionBlock>
        <ColorBlock>
          <Box className='color' />
          <Box>Черный</Box>
          <DropdownIcon />
        </ColorBlock>
        <GrayRectsBlock>
          <Box className='rect' />
          <Box className='rect' />
          <Box className='rect' />
        </GrayRectsBlock>
      </StyledPhoneWrapper>
    </StyledContainer>
  );
};
