import { FC } from 'react';
import { COLORS } from 'utils';

interface IBottomIcon {
  color?: string;
  className?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
}

export const BottomIcon: FC<IBottomIcon> = ({
  color = COLORS.RED,
  className,
  style,
  onClick,
}) => {
  return (
    <svg
      width='10'
      height='16'
      viewBox='0 0 10 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      style={style}
      onClick={onClick}
    >
      <path
        d='M4.86589 3.3335L4.86589 11.6493M7.53255 9.95378L4.86589 12.6668L2.19922 9.95378'
        stroke={color}
        strokeWidth='1.6'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
