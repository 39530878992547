import * as Pages from 'pages';
import { ROLES } from 'utils';
import { IRoutes } from '../types';

export const Attributes: IRoutes = {
  attributes: {
    path: '/attributes',
    title: 'attributes',
    element: <Pages.Attributes />,
    roles: [ROLES.ADMINSTRATOR],
  },
};
