export const UserIcon = () => {
  return (
    <svg
      width='30'
      height='30'
      viewBox='0 0 30 30'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M15 3.75C12.2534 3.75 10 6.00338 10 8.75C10 11.4966 12.2534 13.75 15 13.75C17.7466 13.75 20 11.4966 20 8.75C20 6.00338 17.7466 3.75 15 3.75ZM15 6.25C16.3955 6.25 17.5 7.35448 17.5 8.75C17.5 10.1455 16.3955 11.25 15 11.25C13.6045 11.25 12.5 10.1455 12.5 8.75C12.5 7.35448 13.6045 6.25 15 6.25ZM15 17.5C12.8188 17.5 10.234 18.0122 8.06152 18.8696C6.97531 19.2983 5.99337 19.8076 5.20752 20.4614C4.42167 21.1153 3.75 21.9992 3.75 23.125V26.25H26.25V25V23.125C26.25 21.9992 25.5783 21.1153 24.7925 20.4614C24.0066 19.8076 23.0247 19.2983 21.9385 18.8696C19.766 18.0122 17.1812 17.5 15 17.5ZM15 20C16.7676 20 19.1826 20.4675 21.0229 21.1938C21.9431 21.557 22.7212 21.99 23.1934 22.3828C23.6655 22.7757 23.75 23.0433 23.75 23.125V23.75H6.25V23.125C6.25 23.0433 6.33448 22.7757 6.80664 22.3828C7.2788 21.99 8.05686 21.557 8.97705 21.1938C10.8174 20.4675 13.2324 20 15 20Z'
        fill='#0080FF'
      />
    </svg>
  );
};
