import { FC } from 'react';

import { Button, PageHeader, GlobalSearch } from 'UI';
import { PlusIcon } from 'assets';

interface IWorkoutsHeaderProps {
  openAddWorkoutModal: () => void;
}

export const WorkoutsHeader: FC<IWorkoutsHeaderProps> = ({
  openAddWorkoutModal,
}) => {
  return (
    <PageHeader title='Тренировки'>
      <GlobalSearch size='medium' />
      <Button
        variant='contained'
        startIcon={<PlusIcon />}
        onClick={openAddWorkoutModal}
      >
        Создать
      </Button>
    </PageHeader>
  );
};
