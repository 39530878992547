export const TimerIcon = () => {
  return (
    <svg
      width='30'
      height='31'
      viewBox='0 0 30 31'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M11.25 3H18.75M20 14L15 18M15 28C17.6522 28 20.1957 26.9464 22.0711 25.0711C23.9464 23.1957 25 20.6522 25 18C25 15.3478 23.9464 12.8043 22.0711 10.9289C20.1957 9.05357 17.6522 8 15 8C12.3478 8 9.8043 9.05357 7.92893 10.9289C6.05357 12.8043 5 15.3478 5 18C5 20.6522 6.05357 23.1957 7.92893 25.0711C9.8043 26.9464 12.3478 28 15 28Z'
        stroke='#0080FF'
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
