import { FC } from 'react';
import { styled } from '@mui/system';
import { FormControlLabel } from '@mui/material';
import { StyledSwitch } from 'UI';
import { COLORS } from 'utils';
import { IChangeSpaceSettingsReq } from 'types';

export const StyledSwitchFormControl = styled(FormControlLabel)({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  borderBottom: `1px solid ${COLORS.BLACK}`,
  margin: 0,
  paddingBottom: '0.375rem',
  '& .MuiFormControlLabel-label': {
    marginTop: '0.375rem',
    fontSize: '1rem',
    lineHeight: '1.5rem',
  },
});

interface ISpaceSettingsProps extends IChangeSpaceSettingsReq {
  handleFieldChange: <T extends keyof IChangeSpaceSettingsReq>(
    _fieldName: T,
    _newValue: IChangeSpaceSettingsReq[T],
  ) => void;
}

export const SpaceSettings: FC<ISpaceSettingsProps> = ({
  showcase_state,
  shop_state,
  handleFieldChange,
}) => {
  return (
    <>
      <StyledSwitchFormControl
        label='Витрина'
        labelPlacement='start'
        control={
          <StyledSwitch
            checked={showcase_state}
            onChange={() =>
              handleFieldChange('showcase_state', !showcase_state)
            }
          />
        }
      />
      <StyledSwitchFormControl
        label='Магазин'
        labelPlacement='start'
        control={
          <StyledSwitch
            checked={shop_state}
            onChange={() => handleFieldChange('shop_state', !shop_state)}
          />
        }
      />
    </>
  );
};
