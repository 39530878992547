import * as Pages from 'pages';
import { ROLES } from 'utils';
import { IRoutes } from '../types';

export const Banners: IRoutes = {
  banners: {
    path: '/banners',
    title: 'banners',
    element: <Pages.Banners />,
    roles: [ROLES.ADMINSTRATOR],
  },
};
