import { FC } from 'react';
import { PageHeader, GlobalSearch } from 'UI';

export const ModerationHeader: FC = () => {
  return (
    <PageHeader title='Модерация'>
      <GlobalSearch size='medium' />
    </PageHeader>
  );
};
