import { FC } from 'react';
import { COLORS } from 'utils';

interface IPlusIcon {
  color?: string;
  className?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
}

export const PlusIcon: FC<IPlusIcon> = ({
  className,
  color = COLORS.WHITE,
  onClick,
  ...rest
}) => (
  <svg
    width='25'
    height='24'
    viewBox='0 0 25 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
    onClick={onClick}
    {...rest}
  >
    <path
      d='M6.5 12H12.5M12.5 12H18.5M12.5 12V6M12.5 12V18'
      stroke={color || 'white'}
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
