import { FC, useState, useContext, useMemo } from 'react';
import { ROUTES } from 'app';
import { styled } from '@mui/system';
import { Grid } from '@mui/material';
import {
  useGetAnalyticsChallengesFinishGraph,
  useGetAnalyticsChallengesShareGraph,
  useGetAnalyticsChallengesTopList,
  useGetAnalyticsChallengesData,
  useGetAnalyticsChallengesAccepts,
  useGetAnalyticsChallengesRuns,
} from 'hooks';
import {
  AnalyticsInnerPageContainer,
  AnalyticsListCardLarge,
  AnalyticsChallengeShortCard,
  AnalyticsListCardLargeChallengeSubNavbar,
  AnalyticsLineChartCard,
  AnalyticsPieChartCard,
  AnalyticsHorizontalBarChart,
  AnalyticsChallengesDetailedCard,
} from 'components';
import { AnalyticsChallengesTopFilterParams } from 'types';
import { WinnerCupIcon } from 'assets';
import {
  COLORS,
  FilterContext,
  DEFAULT_CHALLENGES_RUN_CARD,
  DEFAULT_CHALLENGES_ACCEPT_CARD,
} from 'utils';

const StyledGrid = styled(Grid)({
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
});

const UNITY = {
  4: 'шагов',
  1: 'км',
  3: 'ккал',
};

const LINK_TAB = {
  4: 'steps',
  1: 'km',
  3: 'kcal',
};

export const ChallengesAnalyticsBody: FC = () => {
  const { startDate, endDate } = useContext(FilterContext);
  const [activeTabTopList, setActiveTabTopList] =
    useState<AnalyticsChallengesTopFilterParams>(4);

  const paramsToGetTopGraphs = useMemo(() => {
    return {
      start_date: startDate,
      end_date: endDate,
    };
  }, [startDate, endDate]);

  const paramsToGetTopList = useMemo(() => {
    return {
      start_date: startDate,
      end_date: endDate,
      type: activeTabTopList,
    };
  }, [startDate, endDate, activeTabTopList]);

  const {
    data: { countMarathons } = {
      countMarathons: {
        current_data: 0,
        difference: 0,
        percent: 0,
      },
      countTypes: [],
    },
  } = useGetAnalyticsChallengesData(paramsToGetTopGraphs);

  const {
    data: {
      data: shareData,
      count: shareTotal,
      difference: shareDifference,
    } = { data: [], count: 0, difference: 0 },
  } = useGetAnalyticsChallengesShareGraph(paramsToGetTopGraphs);

  const {
    data: {
      data: finishData,
      count: finishTotal,
      difference: finishDifference,
    } = { data: [], count: 0, difference: 0 },
  } = useGetAnalyticsChallengesFinishGraph(paramsToGetTopGraphs);

  const {
    data: { count: topListCount, data: { data: topListData } } = {
      count: 0,
      data: {
        data: [],
      },
    },
  } = useGetAnalyticsChallengesTopList(paramsToGetTopList);

  const {
    data: {
      private: privateAccepts,
      individual: individualAccepts,
      command: commandAccepts,
    } = {
      privateAccepts: DEFAULT_CHALLENGES_ACCEPT_CARD,
      individualAccepts: DEFAULT_CHALLENGES_ACCEPT_CARD,
      commandAccepts: DEFAULT_CHALLENGES_ACCEPT_CARD,
    },
  } = useGetAnalyticsChallengesAccepts(paramsToGetTopGraphs);

  const {
    data: {
      private: privateRuns,
      individual: individualRuns,
      command: commandRuns,
    } = {
      privateRuns: DEFAULT_CHALLENGES_RUN_CARD,
      individualRuns: DEFAULT_CHALLENGES_RUN_CARD,
      commandRuns: DEFAULT_CHALLENGES_RUN_CARD,
    },
  } = useGetAnalyticsChallengesRuns(paramsToGetTopGraphs);

  const finishedChallengesProps = useMemo(() => {
    return {
      title: 'Завершено вызовов',
      sections: [
        {
          label: 'Индивидуальные',
          value: privateRuns?.current_end_count || 0,
          color: '#00DB59',
        },
        {
          label: 'Пользовательские',
          value: individualRuns?.current_end_count || 0,
          color: '#FF0000',
        },
        {
          label: 'Командные',
          value: commandRuns?.current_end_count || 0,
          color: '#0080FF',
        },
      ],
      difference:
        (privateRuns?.current_end_count || 0) +
        (individualRuns?.current_end_count || 0) +
        (commandRuns?.current_end_count || 0) -
        (privateRuns?.prev_end_count || 0) +
        (individualRuns?.prev_end_count || 0) +
        (commandRuns?.prev_end_count || 0),
      sum:
        (privateRuns?.current_end_count || 0) +
        (individualRuns?.current_end_count || 0) +
        (commandRuns?.current_end_count || 0),
    };
  }, [privateRuns, individualRuns, commandRuns]);

  const pieChartProps = useMemo(() => {
    return {
      title: 'Запущенно вызовов',
      sections: [
        {
          label: 'Индивидуальные',
          value: individualRuns?.current_run_count || 0,
          color: '#00DB59',
        },
        {
          label: 'Пользовательские',
          value: privateRuns?.current_run_count || 0,
          color: '#FF0000',
        },
        {
          label: 'Командные',
          value: commandRuns?.current_run_count || 0,
          color: '#0080FF',
        },
      ],
      sum:
        (privateRuns?.current_run_count || 0) +
        (individualRuns?.current_run_count || 0) +
        (commandRuns?.current_run_count || 0),
    };
  }, [privateRuns, individualRuns, commandRuns]);

  const barChartProps = useMemo(() => {
    return {
      title: 'Вступили в вызовы',
      sections: [
        {
          label: 'Индивидуальные',
          value: privateAccepts?.current_accept_count || 0,
          color: '#00DB59',
        },
        {
          label: 'Пользовательские',
          value: individualAccepts?.current_accept_count || 0,
          color: '#FF0000',
        },
        {
          label: 'Командные',
          value: commandAccepts?.current_accept_count || 0,
          color: '#0080FF',
        },
      ],
      sum:
        (privateAccepts?.current_accept_count || 0) +
        (individualAccepts?.current_accept_count || 0) +
        (commandAccepts?.current_accept_count || 0),
    };
  }, [privateAccepts, individualAccepts, commandAccepts]);

  return (
    <AnalyticsInnerPageContainer>
      <Grid container spacing={4}>
        <StyledGrid item xs={4}>
          <AnalyticsChallengeShortCard
            title='Всего вызовы'
            icon={WinnerCupIcon}
            {...countMarathons}
          />
          <AnalyticsLineChartCard
            title='Поделились'
            value={shareTotal}
            changeInValue={shareDifference}
            xAxisData={shareData?.map(item => item.date) || []}
            yAxisData={shareData?.map(item => item.count) || []}
            color={COLORS.RED}
          />
          <AnalyticsLineChartCard
            title='Завершили'
            value={finishTotal}
            changeInValue={finishDifference}
            xAxisData={finishData?.map(item => item.date) || []}
            yAxisData={finishData?.map(item => item.count) || []}
            color={COLORS.BLUE}
          />
        </StyledGrid>
        <Grid item xs={4}>
          <AnalyticsListCardLarge
            title='Топ 5 участников'
            description={`${topListCount} участников`}
            unity={UNITY[activeTabTopList]}
            listItems={topListData}
            link={`${ROUTES.analytics.full}?sub=challenges&tab=${LINK_TAB[activeTabTopList]}`}
          >
            <AnalyticsListCardLargeChallengeSubNavbar
              activeTab={activeTabTopList}
              changeTab={setActiveTabTopList}
            />
          </AnalyticsListCardLarge>
        </Grid>
        <StyledGrid item xs={4}>
          <AnalyticsChallengesDetailedCard {...finishedChallengesProps} />
          <AnalyticsPieChartCard {...pieChartProps} />
          <AnalyticsHorizontalBarChart {...barChartProps} />
        </StyledGrid>
      </Grid>
    </AnalyticsInnerPageContainer>
  );
};
