import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  getCustomWorkouts,
  getCustomWorkout,
  getDefaultWorkouts,
  createCustomWorkout,
  updateCustomWorkout,
  deleteCustomWorkout,
  replaceWorkouts,
} from 'api';
import { IWorkoutGetParams, IWorkoutRes, IWorkoutType, IWorkout } from 'types';

export const useGetCustomWorkouts = (params: IWorkoutGetParams) => {
  const { page, search, column, sort_type } = params;
  return useQuery<IWorkoutRes, unknown>(
    ['customWorkouts', { page, search, column, sort_type }],
    async () => {
      const res = await getCustomWorkouts(params);
      return res;
    },
    {
      staleTime: Infinity,
      retry: false,
    },
  );
};

export const useGetCustomWorkout = (customWorkoutId: string | number) => {
  if (!customWorkoutId)
    return useQuery(
      ['customWorkouts', 'customWorkoutId', customWorkoutId],
      () => {
        return Promise.resolve(null);
      },
    );
  return useQuery<IWorkout, unknown>(
    ['customWorkouts', 'customWorkoutId', customWorkoutId],
    async () => {
      const res = await getCustomWorkout(customWorkoutId);
      return res;
    },
    {
      staleTime: Infinity,
      retry: false,
    },
  );
};

export const useGetDefaultWorkouts = () => {
  return useQuery<IWorkoutType[], unknown>(
    'defaultWorkouts',
    async () => {
      const res = await getDefaultWorkouts();
      return res;
    },
    {
      staleTime: Infinity,
      retry: false,
    },
  );
};

export const useCreateCustomWorkout = () => {
  const queryClient = useQueryClient();
  return useMutation(createCustomWorkout, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['customWorkouts'] });
    },
    onError: () => {},
  });
};

export const useUpdateCustomWorkout = () => {
  const queryClient = useQueryClient();
  return useMutation(updateCustomWorkout, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['customWorkouts'] });
    },
    onError: () => {},
  });
};

export const useDeleteCustomWorkout = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteCustomWorkout, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['customWorkouts'] });
    },
    onError: () => {},
  });
};

export const useReplaceWorkouts = () => {
  const queryClient = useQueryClient();
  return useMutation(replaceWorkouts, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['customWorkouts'] });
    },
    onError: () => {},
  });
};
