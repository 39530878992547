import { FC } from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/system';

const StyledContent = styled(Box)({
  display: 'flex',
  gap: '1.875rem',
  width: '100%',
});

interface IDrawerBodyProps {
  className?: string;
  children?: React.ReactNode;
}

export const DrawerBody: FC<IDrawerBodyProps> = ({ className, children }) => {
  return <StyledContent className={className}>{children}</StyledContent>;
};
