import { FC, useMemo, useState } from 'react';
import { CloseIcon, DeleteIcon, EditIcon, PlusIcon } from 'assets';
import {
  Drawer,
  DrawerHeader,
  ModalTitle,
  StyledFlexBox,
  StyledSwitch,
  Button,
  SearchInput,
} from 'UI';
import {
  useGetCategories,
  useCreateCategory,
  useUpdateCategory,
  useDeleteCategory,
  useSearchParams,
} from 'hooks';
import { COLORS } from 'utils';
import { Box } from '@mui/material';
import { ICategory, ICategoryReq } from 'types';
import {
  StyledContainer,
  CategoriesList,
  CategoryListItem,
} from './styledComponents';
import { EditCategoryComponent } from './EditCategoryComponent';

interface ICategoriesDrawerProps {
  open: boolean;
  onClose: () => void;
}

export const CategoriesDrawer: FC<ICategoriesDrawerProps> = ({
  open,
  onClose,
}) => {
  const [categoriesSwitch, setCategoriesSwitch] = useState<boolean>(true);
  const [searchText, setSearchText] = useState<string>('');
  const [showAddForm, setShowAddForm] = useState<boolean>(false);
  const [selectedCategory, setSelectedCategory] = useState<ICategoryReq | null>(
    null,
  );
  const [purpose, setPurpose] = useState<'add' | 'edit'>('add');
  const { searchParams } = useSearchParams();

  const getCategoriesParams = useMemo(() => {
    return {
      page: 1,
      search: searchText,
    };
  }, [searchText, searchParams]);

  const { data: categories } = useGetCategories(getCategoriesParams);
  const { mutateAsync: createCategory } = useCreateCategory();
  const { mutateAsync: updateCategory } = useUpdateCategory();
  const { mutateAsync: deleteCategory } = useDeleteCategory();

  const editIconHandler = (data: ICategory) => {
    setSelectedCategory({
      id: data.id,
      name: data.name,
      description: data.description,
      is_active: data.is_active,
      position: data.position,
    });
    setPurpose('edit');
    setShowAddForm(true);
  };
  const addButtonHandler = () => {
    if (showAddForm) {
      return;
    }
    setSelectedCategory(null);
    setPurpose('add');
    setShowAddForm(true);
  };
  const deleteIconHandler = (data: ICategory) => {
    deleteCategory(data.id).catch(() => {});
  };

  const saveChanges = () => {
    onClose();
  };

  const ignoreChanges = () => {
    onClose();
  };

  const searchCategory = (text: string) => {
    setSearchText(text);
  };

  const onSubmitAddEditForm = (data: ICategoryReq) => {
    if (purpose === 'edit') {
      updateCategory(data).catch(() => {});
      return;
    }
    createCategory(data).catch(() => {});
  };
  const onCloseAddEditForm = () => {
    setShowAddForm(false);
  };

  return (
    <Drawer open={open} onClose={ignoreChanges}>
      <DrawerHeader>
        <ModalTitle title='Категории' />
        <StyledFlexBox>
          <StyledSwitch
            checked={categoriesSwitch}
            onChange={() => setCategoriesSwitch(!categoriesSwitch)}
            className='switch'
          />
          <CloseIcon onClick={ignoreChanges} />
        </StyledFlexBox>
      </DrawerHeader>
      <StyledContainer>
        <SearchInput handleSearch={text => searchCategory(text)} size='large' />
        <CategoriesList>
          {showAddForm && (
            <EditCategoryComponent
              onClose={onCloseAddEditForm}
              onSubmit={values => onSubmitAddEditForm(values)}
              defaultValues={selectedCategory}
            />
          )}
          {categories?.data?.map(category => (
            <CategoryListItem key={category.id}>
              <Box>{category.name}</Box>
              <Box className='icons'>
                <EditIcon onClick={() => editIconHandler(category)} />
                <DeleteIcon onClick={() => deleteIconHandler(category)} />
              </Box>
            </CategoryListItem>
          ))}
        </CategoriesList>

        <Button
          color='customLightBlue'
          startIcon={<PlusIcon color={COLORS.BLUE} />}
          className='addButton'
          onClick={addButtonHandler}
          sx={showAddForm ? { opacity: 0.5, cursor: 'not-allowed' } : {}}
        >
          Добавить
        </Button>
      </StyledContainer>
      <StyledFlexBox className='buttons fullDivWidth'>
        <div />
        <Button
          variant='contained'
          size='large'
          color='primary'
          onClick={saveChanges}
        >
          Сохранить
        </Button>
      </StyledFlexBox>
    </Drawer>
  );
};
