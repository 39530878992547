import { Box, Paper, Table, TableContainer, TableRow } from '@mui/material';
import { styled } from '@mui/system';

export const StyledBox = styled(Box)({
  width: '100%',
  overflow: 'auto',
});

export const StyledPaper = styled(Paper)({
  width: '100%',
  marginBottom: 2,
});

export const StyledTableContainer = styled(TableContainer)({
  maxHeight: 'calc(100vh - 12rem)',
  minHeight: 'calc(100vh - 12rem)',
  '& .MuiTableSortLabel-icon': {
    position: 'absolute',
    right: '-1.5rem',
  },
});

export const StyledTable = styled(Table)({
  minWidth: '840px',
});

export const StyledTableRow = styled(TableRow)({
  '& span': {
    opacity: 0.5,
    fontSize: '0.875rem',
    fontWeight: 400,
    lineHeight: '1.375rem',
    fontFamily: '"SF Pro Display", sans-serif',
  },
  '& .editDeleteIcons': {
    visibility: 'hidden',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '0.9375rem',
    width: '100%',
    '& svg': {
      cursor: 'pointer',
      marginTop: '0.5rem',
    },
  },
  '&:hover': {
    '& .editDeleteIcons': {
      visibility: 'visible',
    },
  },
});
