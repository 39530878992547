import { FC } from 'react';

import { useDrawer } from 'hooks';
import {
  Layout,
  WorkersHeader,
  WorkersTable,
  AddEditWorkerDrawer,
  ViewWorkerDrawer,
} from 'components';

export const Workers: FC = () => {
  const {
    openAddDrawer,
    viewDrawerId,
    showViewDrawer,
    hideViewDrawer,
    editDrawerId,
    purpose,
    hideAddEditDrawer,
    showAddEditDrawer,
  } = useDrawer();

  return (
    <Layout withoutPB>
      <AddEditWorkerDrawer
        open={showAddEditDrawer}
        onClose={hideAddEditDrawer}
        purpose={purpose}
        id={editDrawerId}
      />
      <ViewWorkerDrawer
        open={showViewDrawer}
        id={viewDrawerId}
        onClose={hideViewDrawer}
      />
      <WorkersHeader openAddWorkerModal={openAddDrawer} />
      <WorkersTable />
    </Layout>
  );
};
