import { FC, useState, useMemo, MouseEvent } from 'react';
import { TableBody } from '@mui/material';

import { DeleteModal } from 'components';
import { Pagination } from 'UI';
import {
  useGetCustomWorkouts,
  useUpdateCustomWorkout,
  useDeleteCustomWorkout,
  useSearchParams,
  useReplaceWorkouts,
} from 'hooks';
import { IWorkout, TableRowsOrder, WorkoutSortParams } from 'types';

import {
  StyledBox,
  StyledPaper,
  StyledTableContainer,
  StyledTable,
} from './styledComponents';
import { WorkoutsTableHead } from './WorkoutsTableHead';
import { WorkoutsTableRow } from './WorkoutsTableRow';

export const WorkoutsTable: FC = () => {
  const [order, setOrder] = useState<TableRowsOrder>('asc');
  const [orderBy, setOrderBy] = useState<WorkoutSortParams>('position');
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [selectedWorkout, setSelectedWorkout] = useState<IWorkout | undefined>(
    undefined,
  );
  const { setParam, getParam, searchParams } = useSearchParams();

  const params = useMemo(() => {
    return {
      page: getParam('page'),
      search: getParam('search'),
      column: orderBy,
      sort_type: order,
    };
  }, [searchParams, order, orderBy]);

  const {
    data: { data: workouts, last_page: pageCount } = { data: [], last_page: 1 },
  } = useGetCustomWorkouts(params);
  const { mutateAsync: updateWorkout } = useUpdateCustomWorkout();
  const { mutateAsync: deleteWorkout } = useDeleteCustomWorkout();
  const { mutateAsync: replaceWorkouts } = useReplaceWorkouts();

  const handleRequestSort = (
    _event: MouseEvent<unknown>,
    property: WorkoutSortParams,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const updateRow = (row: IWorkout) => {
    updateWorkout({ ...row, is_active: !row.is_active, type: row.type.id });
  };

  const deleteButtonHandler = (row: IWorkout) => {
    setSelectedWorkout(row);
    setOpenDeleteModal(true);
  };

  const deleteRow = async () => {
    await deleteWorkout(selectedWorkout!.id);
    setOpenDeleteModal(false);
  };

  const cancelDeleteRow = () => {
    setOpenDeleteModal(false);
    setSelectedWorkout(undefined);
  };

  const openEditDrawer = (id: string | number) => {
    setParam('edit', id.toString());
  };

  const moveRow = (dragPosition: number, dropPosition: number) => {
    const draggedWorkout = workouts.find(
      workout => workout.position === dragPosition,
    );
    const droppedWorkout = workouts.find(
      workout => workout.position === dropPosition,
    );

    replaceWorkouts({
      trainings: [
        { id: draggedWorkout!.id, position: dropPosition },
        { id: droppedWorkout!.id, position: dragPosition },
      ],
    })
      .then(() => {})
      .catch(() => {});
  };

  return (
    <>
      <DeleteModal
        open={openDeleteModal}
        title='Удалить тренировку'
        description='Вы действительно хотите удалить тренировку'
        onConfirm={deleteRow}
        onClose={cancelDeleteRow}
      />
      <StyledBox>
        <StyledPaper>
          <StyledTableContainer>
            <StyledTable
              aria-labelledby='usersTable'
              size='medium'
              stickyHeader
            >
              <WorkoutsTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {workouts.map(row => {
                  return (
                    <WorkoutsTableRow
                      key={row.id}
                      row={row}
                      openEditDrawer={openEditDrawer}
                      deleteButtonHandler={deleteButtonHandler}
                      updateRow={updateRow}
                      moveRow={moveRow}
                    />
                  );
                })}
              </TableBody>
            </StyledTable>
          </StyledTableContainer>
          <Pagination count={pageCount} />
        </StyledPaper>
      </StyledBox>
    </>
  );
};
