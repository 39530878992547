import { FC, useEffect, useState } from 'react';
import { Box, TextField } from '@mui/material';
import { ICategoryReq } from 'types';
import { FormItem, StyledSwitch } from 'UI';
import { CheckIcon, CloseIcon } from 'assets';
import { EditCategoryContainer } from './styledComponents';

const DEFAULT_VALUES = {
  name: '',
  description: '',
  is_active: true,
  position: undefined,
};

interface IEditCategoryComponentProps {
  onClose: () => void;
  onSubmit: (_data: ICategoryReq) => void;
  defaultValues?: ICategoryReq | null;
}

export const EditCategoryComponent: FC<IEditCategoryComponentProps> = ({
  onClose,
  onSubmit,
  defaultValues,
}) => {
  const [categoryInfo, setCategoryInfo] =
    useState<ICategoryReq>(DEFAULT_VALUES);

  const handleFieldChange = <T extends keyof ICategoryReq>(
    fieldName: T,
    newValue: ICategoryReq[T],
  ) => {
    setCategoryInfo(prevCategoryInfo => ({
      ...prevCategoryInfo,
      [fieldName]: newValue,
    }));
  };

  const saveChanges = () => {
    onSubmit(categoryInfo);
    onClose();
  };

  const ignoreChanges = () => {
    onClose();
  };

  useEffect(() => {
    if (defaultValues?.id) {
      setCategoryInfo(defaultValues);
    } else {
      setCategoryInfo(DEFAULT_VALUES);
    }
  }, [defaultValues]);

  return (
    <EditCategoryContainer>
      <Box className='inputs'>
        <FormItem>
          <TextField
            variant='standard'
            color='primary'
            placeholder='Название категории'
            name='name'
            value={categoryInfo.name}
            onChange={e => handleFieldChange('name', e.target.value)}
          />
        </FormItem>
        <FormItem>
          <TextField
            variant='standard'
            color='primary'
            placeholder='Описание категории'
            name='description'
            value={categoryInfo.description}
            onChange={e => handleFieldChange('description', e.target.value)}
          />
        </FormItem>
        <FormItem>
          <TextField
            variant='standard'
            color='primary'
            placeholder='Позиция категории'
            name='position'
            value={categoryInfo.position}
            onChange={e =>
              handleFieldChange('position', e.target.value as unknown as number)
            }
          />
        </FormItem>
      </Box>
      <Box className='buttons'>
        <StyledSwitch
          checked={categoryInfo.is_active}
          onChange={() =>
            handleFieldChange('is_active', !categoryInfo.is_active)
          }
          className='switch'
        />
        <CloseIcon onClick={ignoreChanges} />
        <CheckIcon onClick={saveChanges} />
      </Box>
    </EditCategoryContainer>
  );
};
