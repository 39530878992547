import { FC, ChangeEvent, useState, useMemo, useEffect } from 'react';
import { Autocomplete, TextField } from '@mui/material';

interface ISearchSelectInputProps<T> {
  options: T[];
  value?: number | number[] | string | string[];
  onChange: (_value: IOption['value'][] | IOption['value']) => void;
  inputValue?: string;
  onInputChange?: (_value: string) => void;
  label?: string;
  multiple?: boolean;
  placeholder?: string;
  size?: 'small' | 'medium';
  variant?: 'outlined' | 'filled' | 'standard';
  fullWidth?: boolean;
  disabled?: boolean;
  disableClearable?: boolean;
}

interface IOption {
  label: string;
  value: number | string;
}

export const SearchSelectInput: FC<ISearchSelectInputProps<IOption>> = ({
  options,
  value,
  onChange,
  // inputValue,
  onInputChange,
  label,
  placeholder,
  disableClearable = false,
  fullWidth = true,
  variant = 'outlined',
  size = 'medium',
  multiple = false,
  disabled = false,
}) => {
  const defaultInnerValue = useMemo(() => {
    if (value || value === 0 || value === '0') {
      if (Array.isArray(value)) {
        return (
          options.filter(option =>
            value.includes(
              // @ts-ignore
              option.value,
            ),
          ) || []
        );
      }
      return (
        options.find(option => option.value.toString() === value.toString()) ||
        null
      );
    }
    return multiple ? [] : null;
  }, [value, options, multiple]);

  const [innerValue, setInnerValue] = useState<IOption | IOption[] | null>(
    defaultInnerValue,
  );

  useEffect(() => {
    setInnerValue(defaultInnerValue);
  }, [defaultInnerValue]);

  const handleChange = (
    _event: ChangeEvent<any>,
    newValue: IOption | IOption[] | null,
  ): void => {
    setInnerValue(newValue);
    if (newValue === null) {
      onChange(multiple ? [] : 0);
      return;
    }
    if (Array.isArray(newValue)) {
      const newIds: IOption['value'][] = newValue.map(newVal => newVal.value);
      onChange(newIds);
      return;
    }
    onChange(newValue.value);
  };

  const handleInputChange = (_event: ChangeEvent<any>, newValue: string) => {
    if (!onInputChange) return;
    onInputChange(newValue);
  };

  return (
    <Autocomplete
      multiple={multiple}
      options={options}
      value={innerValue}
      getOptionLabel={option => option.label}
      onChange={handleChange}
      // inputValue={inputValue}
      onInputChange={handleInputChange}
      fullWidth={fullWidth}
      size={size}
      disableClearable={disableClearable}
      disabled={disabled}
      renderInput={params => (
        <TextField
          {...params}
          variant={variant}
          label={label}
          placeholder={placeholder}
        />
      )}
    />
  );
};
