import { FC, ReactNode } from 'react';
import { styled } from '@mui/system';
import { Grid } from '@mui/material';

const StyledContainer = styled(Grid)({
  marginBottom: '1.25rem',
});

interface IAnalyticsCardsContainerProps {
  children: ReactNode;
}

export const AnalyticsCardsContainer: FC<IAnalyticsCardsContainerProps> = ({
  children,
}) => {
  return (
    <StyledContainer container spacing={4}>
      {children}
    </StyledContainer>
  );
};
