import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { FC } from 'react';
import { COLORS, isDateInPast } from 'utils';

const StatusBadgeComp = styled(Box)(
  ({ isActive, isPublished }: { isActive: boolean; isPublished: boolean }) => ({
    padding: '0.125rem 0.5rem',
    borderRadius: '1.25rem',
    backgroundColor: !isActive
      ? 'rgba(255, 0, 0, 0.03)'
      : isPublished
      ? 'rgba(52, 199, 89, 0.10)'
      : 'rgba(255, 142, 0, 0.10)',
    color: !isActive ? COLORS.RED : isPublished ? COLORS.GREEN : COLORS.ORANGE,
    fontSize: '0.75rem',
    lineHeight: '1.25rem',
    fontWeight: 500,
    display: 'block',
    marginBottom: '0.25rem',
    width: 'max-content',
  }),
);

interface IStatusBadgeProps {
  is_active: boolean;
  publishTime: string;
  style?: any;
}

const STATUS_TEXTS = {
  waiting: 'Ожидает публикации',
  published: 'Опубликовано',
  inActive: 'Неактивно',
};

export const StatusBadge: FC<IStatusBadgeProps> = ({
  is_active,
  publishTime,
  style,
}) => {
  const getStatusText = () => {
    if (!is_active) {
      return STATUS_TEXTS.inActive;
    }
    if (isDateInPast(publishTime)) {
      return STATUS_TEXTS.published;
    }
    return STATUS_TEXTS.waiting;
  };

  return (
    <StatusBadgeComp
      isActive={is_active}
      isPublished={isDateInPast(publishTime)}
      style={style}
    >
      {getStatusText()}
    </StatusBadgeComp>
  );
};
