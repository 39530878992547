import { useQuery, useMutation, useQueryClient } from 'react-query';
import {
  getQAs,
  getQA,
  createQA,
  updateQA,
  deleteQA,
  getInstructions,
  getInstruction,
  createInstruction,
  updateInstruction,
  deleteInstruction,
  getMeAndGtos,
  getMeAndGto,
  createMeAndGto,
  updateMeAndGto,
  deleteMeAndGto,
} from 'api';
import {
  IQuestionAnswer,
  IQuestionAnswerRes,
  IQuestionAnswerGetParams,
  IInstructionGetParams,
  IInstruction,
  IInstructionRes,
  IMeAndGtoGetParams,
  IMeAndGto,
  IMeAndGtoRes,
} from 'types';

export const useGetQAs = (params: IQuestionAnswerGetParams) => {
  const { page, search, sort_type } = params;
  return useQuery<IQuestionAnswerRes, unknown>(
    ['faqs', { page, search, sort_type }],
    async () => {
      const res = await getQAs(params);
      return res;
    },
    {
      staleTime: Infinity,
      retry: false,
    },
  );
};

export const useGetQA = (qaId: string | number) => {
  if (!qaId)
    return useQuery(['qas', 'qaId', qaId], () => {
      return Promise.resolve(null);
    });
  return useQuery<IQuestionAnswer>(
    ['faqs', 'qaId', qaId],
    async () => {
      const res = await getQA(qaId);
      return res;
    },
    {
      staleTime: Infinity,
      retry: false,
    },
  );
};

export const useCreateQA = () => {
  const queryClient = useQueryClient();
  return useMutation(createQA, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['faqs'] });
    },
    onError: () => {},
  });
};

export const useUpdateQA = () => {
  const queryClient = useQueryClient();
  return useMutation(updateQA, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['faqs'] });
    },
    onError: () => {},
  });
};

export const useDeleteQA = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteQA, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['faqs'] });
    },
    onError: () => {},
  });
};

export const useGetInstructions = (params: IInstructionGetParams) => {
  const { page, search, sort_type } = params;
  return useQuery<IInstructionRes, unknown>(
    ['instructions', { page, search, sort_type }],
    async () => {
      const res = await getInstructions(params);
      return res;
    },
    {
      staleTime: Infinity,
      retry: false,
    },
  );
};

export const useGetInstruction = (instructionId: string | number) => {
  if (!instructionId)
    return useQuery(['instructions', 'instructionId', instructionId], () => {
      return Promise.resolve(null);
    });
  return useQuery<IInstruction>(
    ['instructions', 'instructionId', instructionId],
    async () => {
      const res = await getInstruction(instructionId);
      return res;
    },
    {
      staleTime: Infinity,
      retry: false,
    },
  );
};

export const useCreateInstruction = () => {
  const queryClient = useQueryClient();
  return useMutation(createInstruction, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['instructions'] });
    },
    onError: () => {},
  });
};

export const useUpdateInstruction = () => {
  const queryClient = useQueryClient();
  return useMutation(updateInstruction, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['instructions'] });
    },
    onError: () => {},
  });
};

export const useDeleteInstruction = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteInstruction, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['instructions'] });
    },
    onError: () => {},
  });
};

export const useGetMeAndGtos = (params: IMeAndGtoGetParams) => {
  const { page, search, sort_type } = params;
  return useQuery<IMeAndGtoRes, unknown>(
    ['meAndGtos', { page, search, sort_type }],
    async () => {
      const res = await getMeAndGtos(params);
      return res;
    },
    {
      staleTime: Infinity,
      retry: false,
    },
  );
};

export const useGetMeAndGto = (meAndGtoId: string | number) => {
  if (!meAndGtoId)
    return useQuery(['meAndGtos', 'meAndGtoId', meAndGtoId], () => {
      return Promise.resolve(null);
    });
  return useQuery<IMeAndGto>(
    ['meAndGtos', 'meAndGtoId', meAndGtoId],
    async () => {
      const res = await getMeAndGto(meAndGtoId);
      return res;
    },
    {
      staleTime: Infinity,
      retry: false,
    },
  );
};

export const useCreateMeAndGto = () => {
  const queryClient = useQueryClient();
  return useMutation(createMeAndGto, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['meAndGtos'] });
    },
    onError: () => {},
  });
};

export const useUpdateMeAndGto = () => {
  const queryClient = useQueryClient();
  return useMutation(updateMeAndGto, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['meAndGtos'] });
    },
    onError: () => {},
  });
};

export const useDeleteMeAndGto = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteMeAndGto, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['meAndGtos'] });
    },
    onError: () => {},
  });
};
