import { FC, useState } from 'react';
import { NewsHeader, Layout, CategoriesDrawer, NewsTable } from 'components';

export const News: FC = () => {
  const [openCategoriesDrawer, setOpenCategoriesDrawer] =
    useState<boolean>(false);

  const showCategoriesDrawer = () => {
    setOpenCategoriesDrawer(true);
  };

  const hideCategoriesDrawer = () => {
    setOpenCategoriesDrawer(false);
  };
  return (
    <Layout withoutPB>
      <CategoriesDrawer
        open={openCategoriesDrawer}
        onClose={hideCategoriesDrawer}
      />
      <NewsHeader openCategoriesDrawer={showCategoriesDrawer} />
      <NewsTable />
    </Layout>
  );
};
