import { FC, useState, useMemo, useContext, MouseEvent } from 'react';
import { TableBody, TableCell } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { ROUTES, UserContext, UserContextType } from 'app';
import { EditIcon, DeleteIcon } from 'assets';
import { DEFAULT_PRODUCT_COVER_URL } from 'utils';
import { Pagination, StyledSwitch } from 'UI';
import {
  useDeleteShopProduct,
  useGetShopProducts,
  useSearchParams,
  useChangeStatus,
} from 'hooks';
import {
  ShopProductSortParams,
  IShopProductShort,
  TableRowsOrder,
} from 'types';
import { DeleteModal } from 'components';

import {
  StyledBox,
  StyledPaper,
  StyledTableContainer,
  StyledTable,
  StyledTableRow,
  StyledProductInfo,
  StyledAvatar,
  StyledProductName,
  StyledProductInfoAtModal,
} from './styledComponents';
import { ProductsTableHead } from './ProductsTableHead';

export const ProductsTable: FC = () => {
  const navigate = useNavigate();
  const { space_id } = useContext(UserContext) as UserContextType;
  const [order, setOrder] = useState<TableRowsOrder>('desc');
  const [orderBy, setOrderBy] = useState<ShopProductSortParams>('created_at');
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [selectedProduct, setSelectedProduct] = useState<
    IShopProductShort | undefined
  >(undefined);
  const { getParam, searchParams } = useSearchParams();

  const params = useMemo(() => {
    return {
      page: getParam('page') || 1,
      search: getParam('search'),
      category_id: getParam('category_id') || 1,
      column: orderBy,
      sort_type: order,
    };
  }, [searchParams, order, orderBy]);

  const {
    data: { data: productsList, last_page: pageCount } = {
      data: [],
      last_page: 1,
    },
  } = useGetShopProducts(params);
  const { mutateAsync: deleteProduct } = useDeleteShopProduct();
  const { mutateAsync: changeStatus } = useChangeStatus('product');

  const deleteButtonHandler = (row: IShopProductShort) => {
    setSelectedProduct(row);
    setOpenDeleteModal(true);
  };

  const deleteRow = () => {
    if (!selectedProduct?.category_id || !selectedProduct.id) return;
    deleteProduct({
      id: selectedProduct!.id,
      category_id: selectedProduct!.category_id,
    })
      .then(() => {})
      .catch(() => {});
    setOpenDeleteModal(false);
  };

  const cancelDeleteRow = () => {
    setOpenDeleteModal(false);
    setSelectedProduct(undefined);
  };

  const handleRequestSort = (
    _event: MouseEvent<unknown>,
    property: ShopProductSortParams,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const openEditDrawer = (
    id: string | number,
    category_id: string | number,
  ) => {
    navigate(
      `${ROUTES.shop.createProduct}?id=${id}&category_id=${category_id}`,
    );
  };

  const updateRow = (row: IShopProductShort) => {
    changeStatus({
      space_id: space_id!,
      model_name: 'product',
      model_id: row.id,
      state: !row.is_active,
    })
      .then(() => {})
      .catch(() => {});
  };

  return (
    <StyledBox>
      <DeleteModal
        open={openDeleteModal}
        title='Удалить товар'
        description='Вы действительно хотите удалить товар?'
        onConfirm={deleteRow}
        onClose={cancelDeleteRow}
      >
        <StyledProductInfoAtModal>
          <StyledAvatar>
            <img
              src={
                (selectedProduct?.cover as string | undefined) ||
                DEFAULT_PRODUCT_COVER_URL
              }
              alt={selectedProduct?.name}
            />
          </StyledAvatar>
          <StyledProductName>{selectedProduct?.name}</StyledProductName>
        </StyledProductInfoAtModal>
      </DeleteModal>
      <StyledPaper>
        <StyledTableContainer>
          <StyledTable
            aria-labelledby='productsTable'
            size='medium'
            stickyHeader
          >
            <ProductsTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {productsList.map(row => {
                return (
                  <StyledTableRow hover key={row.id}>
                    <TableCell
                      component='th'
                      id={row.id.toString()}
                      scope='row'
                      className='nameCell'
                    >
                      <StyledProductInfo
                        onClick={() => openEditDrawer(row.id, row.category_id)}
                      >
                        <StyledAvatar>
                          <img
                            src={row.cover as string | undefined}
                            alt={row.name}
                          />
                        </StyledAvatar>
                        <StyledProductName>{row.name}</StyledProductName>
                      </StyledProductInfo>
                    </TableCell>
                    <TableCell align='center'>
                      <span>{row.sku}</span>
                    </TableCell>
                    <TableCell align='center'>
                      <span>Категория: {row.category_id}</span>
                    </TableCell>
                    <TableCell align='center'>
                      <span>{row.price}</span>
                    </TableCell>
                    <TableCell align='center'>
                      <span>{row.amount}</span>
                    </TableCell>
                    <TableCell align='center'>
                      <span>{row.created_at}</span>
                    </TableCell>
                    <TableCell>
                      <div className='editDeleteIcons'>
                        <StyledSwitch
                          checked={row.is_active}
                          variant='customGray'
                          onChange={() => updateRow(row)}
                          style={{ opacity: 1 }}
                          className='switch'
                        />
                        <EditIcon
                          onClick={() =>
                            openEditDrawer(row.id, row.category_id)
                          }
                        />
                        <DeleteIcon onClick={() => deleteButtonHandler(row)} />
                      </div>
                    </TableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </StyledTable>
        </StyledTableContainer>
        <Pagination count={pageCount} />
      </StyledPaper>
    </StyledBox>
  );
};
