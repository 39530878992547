import { FC } from 'react';
import { styled } from '@mui/system';
import { Box, Paper } from '@mui/material';
import { IAnalyticsTopCard } from 'types';
import { COLORS, formatNumber } from 'utils';
import {
  CommentIcon,
  DownIcon,
  LikeIcon,
  NewsIcon,
  UpIcon,
  ViewIcon,
} from 'assets';

const StyledContainer = styled(Paper)({
  borderRadius: '0.5rem',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: COLORS.WHITE,
  fontFamily: '"SF Pro Display", sans-serif',
  height: '100%',
  '& .change': {
    fontSize: '0.875rem',
    fontWeight: 500,
    lineHeight: '1.375rem',
    display: 'flex',
    alignItems: 'center',
    '&.positive': {
      color: COLORS.GREEN,
    },
    '&.negative': {
      color: COLORS.RED,
    },
  },
  '& .hr': {
    width: '100%',
    height: '1px',
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
  },
});

const StyledHeader = styled(Box)({
  padding: '1.25rem',
  display: 'flex',
  flexDirection: 'column',
  gap: '0.625rem',
  borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
  '& .icon': {
    width: '3.75rem',
    height: '3.75rem',
    borderRadius: '50%',
    backgroundColor: 'rgba(0, 128, 255, 0.05)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  '& .value': {
    fontSize: '1.875rem',
    lineHeight: '2.375rem',
    fontWeight: 500,
    fontFamily: '"SF Pro Display", sans-serif',
  },
  '& .footer': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& span': {
      opacity: 0.5,
      fontSize: '0.875rem',
      fontWeight: 500,
      lineHeight: '1.375rem',
    },
  },
});

const StyledBody = styled(Box)({
  padding: '1rem 1.25rem',
  display: 'flex',
  flexDirection: 'column',
  gap: '0.625rem',
});

const StyledItem = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  '& > div': {
    display: 'flex',
    gap: '0.625rem',
    alignItems: 'center',
    '& .value': {
      fontSize: '1rem',
      fontWeight: 500,
      lineHeight: '1.5rem',
    },
    '& span': {
      fontSize: '0.875rem',
      fontWeight: 400,
      lineHeight: '1.375rem',
    },
  },
});

interface IAnalyticsNewsCardProps {
  news: IAnalyticsTopCard;
  likes: IAnalyticsTopCard;
  views: IAnalyticsTopCard;
  shares: IAnalyticsTopCard;
}

export const AnalyticsNewsCard: FC<IAnalyticsNewsCardProps> = ({
  news,
  likes,
  views,
  shares,
}) => {
  return (
    <StyledContainer>
      <StyledHeader>
        <Box className='icon'>
          <NewsIcon />
        </Box>
        <Box className='value'>{formatNumber(news.current_data)}</Box>
        <Box className='footer'>
          <span>Новостей</span>
          <Box
            className={`${
              news.difference > 0 ? 'positive' : 'negative'
            } change`}
          >
            {news.difference > 0 ? '+' : ''}
            {formatNumber(news.difference)}
            {news.difference > 0 ? (
              <UpIcon color={COLORS.GREEN} />
            ) : (
              <DownIcon color={COLORS.RED} />
            )}
          </Box>
        </Box>
      </StyledHeader>
      <StyledBody>
        <StyledItem>
          <Box>
            <LikeIcon />
            <span>Лайки</span>
          </Box>
          <Box>
            <Box className='value'>{likes.current_data}</Box>
            <Box
              className={`${
                likes.difference > 0 ? 'positive' : 'negative'
              } change`}
            >
              {likes.difference > 0 ? '+' : ''}
              {likes.difference}
              {likes.difference > 0 ? (
                <UpIcon color={COLORS.GREEN} />
              ) : (
                <DownIcon color={COLORS.RED} />
              )}
            </Box>
          </Box>
        </StyledItem>
        <Box className='hr' />
        <StyledItem>
          <Box>
            <ViewIcon />
            <span>Просмотры</span>
          </Box>
          <Box>
            <Box className='value'>{views.current_data}</Box>
            <Box
              className={`${
                views.difference > 0 ? 'positive' : 'negative'
              } change`}
            >
              {views.difference > 0 ? '+' : ''}
              {views.difference}
              {views.difference > 0 ? (
                <UpIcon color={COLORS.GREEN} />
              ) : (
                <DownIcon color={COLORS.RED} />
              )}
            </Box>
          </Box>
        </StyledItem>
        <Box className='hr' />
        <StyledItem>
          <Box>
            <CommentIcon />
            <span>Комментарии</span>
          </Box>
          <Box>
            <Box className='value'>{shares.current_data}</Box>
            <Box
              className={`${
                shares.difference > 0 ? 'positive' : 'negative'
              } change`}
            >
              {shares.difference > 0 ? '+' : ''}
              {shares.difference}
              {shares.difference > 0 ? (
                <UpIcon color={COLORS.GREEN} />
              ) : (
                <DownIcon color={COLORS.RED} />
              )}
            </Box>
          </Box>
        </StyledItem>
      </StyledBody>
    </StyledContainer>
  );
};
