import { FC, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { Layout } from 'components';
import { ROUTES } from 'app';

export const Services: FC = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  useEffect(() => {
    if (pathname === ROUTES.services.get) {
      navigate(ROUTES.services.instructions);
    }
  }, [pathname, navigate]);
  return <Layout withoutPB></Layout>;
};
