import { useQuery, useMutation, useQueryClient } from 'react-query';
import {
  getPartners,
  getPartner,
  createPartner,
  updatePartner,
  deletePartner,
} from 'api';
import { IPartnerRes, IPartner, IPartnerGetParams } from 'types';

export const useGetPartners = (params: IPartnerGetParams) => {
  const { page, search } = params;
  return useQuery<IPartnerRes, unknown>(
    ['partners', { page, search }],
    async () => {
      const res = await getPartners(params);
      return res;
    },
    {
      staleTime: Infinity,
      retry: false,
    },
  );
};

export const useGetPartner = (partnerId: string | number) => {
  if (!partnerId)
    return useQuery(['partners', 'partnerId', partnerId], () => {
      return Promise.resolve(null);
    });
  return useQuery<IPartner>(
    ['partners', 'partnerId', partnerId],
    async () => {
      const res = await getPartner(partnerId);
      return res;
    },
    {
      staleTime: Infinity,
      retry: false,
    },
  );
};

export const useCreatePartner = () => {
  const queryClient = useQueryClient();
  return useMutation(createPartner, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['partners'] });
    },
    onError: () => {},
  });
};

export const useUpdatePartner = () => {
  const queryClient = useQueryClient();
  return useMutation(updatePartner, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['partners'] });
    },
    onError: () => {},
  });
};

export const useDeletePartner = () => {
  const queryClient = useQueryClient();
  return useMutation(deletePartner, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['partners'] });
    },
    onError: () => {},
  });
};
