import { FC } from 'react';
import { useDrawer } from 'hooks';
import { OfferAndDiscountTable, OfferAndDiscountDrawer } from 'components';

export const OfferAndDiscounts: FC = () => {
  const { showAddEditDrawer, hideAddEditDrawer, editDrawerId } = useDrawer();

  return (
    <>
      <OfferAndDiscountDrawer
        open={showAddEditDrawer}
        onClose={hideAddEditDrawer}
        id={editDrawerId}
        purpose={editDrawerId === '0' ? 'add' : 'edit'}
      />
      <OfferAndDiscountTable type={0} />
    </>
  );
};
