import { FC, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/system';
import { Box } from '@mui/material';
import { COLORS } from 'utils';
import { LeftArrowIcon } from 'assets';
import { GlobalSearch, PageTitle } from 'UI';
import { AnalyticsFilter, CustomDateFilter } from 'components';
import { useSearchParams } from 'hooks';
import { ROUTES } from 'app';

const StyledLayout = styled(Box)({
  width: '100%',
  display: 'flex',
  gap: '1.25rem',
  alignItems: 'flex-start',
  marginBottom: '1.8125rem',
  justifyContent: 'space-between',
});

const StyledTitleBox = styled(Box)({
  display: 'flex',
  gap: '1.25rem',
  alignItems: 'center',
});

const StyledButton = styled(Box)({
  minWidth: '3.125rem',
  width: '3.125rem',
  height: '3.125rem',
  borderRadius: '50%',
  border: `1px solid ${COLORS.RED_2}`,
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const StyledTitle = styled(Box)({
  '& h1': {
    width: 'max-content',
    // maxWidth: '23rem',
  },
});

const StyledSearch = styled(Box)({
  minWidth: '18rem',
  '& > div': {
    width: '100%',
  },
});

const DateFilterContainer = styled(Box)({
  width: '17rem',
});

const TITLE: any = {
  users: {
    steps: 'Топ по шагам',
    km: 'Топ по расстоянию',
    kcal: 'Топ по калориям',
  },
  workouts: {
    training: 'Топ запущеных тренировок',
    space: 'Топ по пространствам',
  },
  challenges: {
    steps: 'Топ по шагам',
    km: 'Топ по расстоянию',
    kcal: 'Топ по калориям',
  },
  showcase: {
    discounts: 'Топ по скидкам',
    categories: 'Топ по категориям',
  },
};

interface IFullAnalyticsHeaderProps {}

export const FullAnalyticsHeader: FC<IFullAnalyticsHeaderProps> = () => {
  const navigate = useNavigate();
  const { getParam, searchParams } = useSearchParams();
  const fullAnalyticsTitle = useMemo(() => {
    const sub = getParam('sub');
    const tab = getParam('tab');
    return TITLE[sub][tab] || '';
  }, [searchParams]);

  const handleBack = () => {
    const sub = getParam('sub');
    switch (sub) {
      case 'users':
        navigate(ROUTES.analytics.users);
        break;
      case 'workouts':
        navigate(ROUTES.analytics.workouts);
        break;
      case 'challenges':
        navigate(ROUTES.analytics.challenges);
        break;
      case 'showcase':
        navigate(ROUTES.analytics.showcases);
        break;
      default:
        break;
    }
  };

  return (
    <StyledLayout>
      <StyledTitleBox>
        <StyledButton onClick={handleBack}>
          <LeftArrowIcon color={COLORS.RED_2} />
        </StyledButton>
        <StyledTitle>
          <PageTitle title={fullAnalyticsTitle} />
        </StyledTitle>
      </StyledTitleBox>
      <AnalyticsFilter />
      <StyledSearch>
        <GlobalSearch />
      </StyledSearch>
      <DateFilterContainer>
        <CustomDateFilter />
      </DateFilterContainer>

      {/* <Button
        variant='contained'
        color='customDarkerGray'
        onClick={downloadAsFile}
      >
        <DownloadIcon />
      </Button> */}
    </StyledLayout>
  );
};
