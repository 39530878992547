import { FC, useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { SelectInput } from 'UI';
import { useGetAllWorkers } from 'hooks';
import { IWorker } from 'types';
import { ROLES as ExistingRoles } from 'utils';

import {
  StyledContainer,
  StyledMainContent,
  StyledWorkersList,
  StyledWorkerItem,
} from './styledComponents';

const ROLES = {
  options: [
    {
      label: 'Администратор',
      value: ExistingRoles.ADMINSTRATOR,
    },
    {
      label: 'Менеджер',
      value: ExistingRoles.MODERATOR,
    },
    {
      label: 'Пользователь приложения',
      value: ExistingRoles.USER,
    },
  ],
  values: [
    {
      id: ExistingRoles.ADMINSTRATOR,
      text: 'Администратор',
    },
    {
      id: ExistingRoles.MODERATOR,
      text: 'Менеджер',
    },
    {
      id: ExistingRoles.USER,
      text: 'Пользователь приложения',
    },
  ],
};

interface IAddWorkerToSpaceProps {
  spaceId: string | number;
}

export const AddWorkerToSpace: FC<IAddWorkerToSpaceProps> = ({ spaceId }) => {
  if (!spaceId) return null;
  const { data: allWorkers } = useGetAllWorkers();
  const [selectedWorkers, setSelectedWorkers] = useState<IWorker[]>([]);

  useEffect(() => {
    if (allWorkers?.length > 0) {
      setSelectedWorkers(allWorkers);
    }
  }, [allWorkers]);

  return (
    <StyledContainer>
      <h2>Сотрудники пространства</h2>
      <StyledMainContent>
        {selectedWorkers.length > 0 && (
          <StyledWorkersList>
            {selectedWorkers.map(worker => (
              <StyledWorkerItem key={worker.id}>
                <Box className='name'>
                  {worker.firstname} {worker.lastname}
                </Box>
                <SelectInput
                  options={ROLES.options}
                  value={worker.role.id}
                  disabled
                  onChange={_newRoleId => {}}
                />
              </StyledWorkerItem>
            ))}
          </StyledWorkersList>
        )}
      </StyledMainContent>
    </StyledContainer>
  );
};
