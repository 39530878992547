import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { FC } from 'react';
import { COLORS } from 'utils';

export const StyledBadgeComp = styled(Box)(
  ({
    is_active,
    variant,
  }: {
    is_active: boolean;
    variant: 'primary' | 'secondary';
  }) => ({
    padding: '0.25rem 0.625rem',
    borderRadius: '6rem',
    backgroundColor:
      variant === 'primary' && is_active
        ? COLORS.GREEN
        : variant === 'primary' && !is_active
        ? 'rgba(255, 0, 0, 0.03)'
        : variant === 'secondary' && is_active
        ? 'rgba(66, 195, 79, 0.10)'
        : variant === 'secondary' && !is_active
        ? 'rgba(255, 0, 0, 0.03)'
        : 'rgba(255, 0, 0, 0.03)',
    color:
      variant === 'primary' && is_active
        ? COLORS.WHITE
        : variant === 'primary' && !is_active
        ? COLORS.RED
        : variant === 'secondary' && is_active
        ? COLORS.GREEN
        : variant === 'secondary' && !is_active
        ? COLORS.RED
        : COLORS.RED,
    fontSize: variant === 'primary' ? '0.625rem' : '0.75rem',
    lineHeight: variant === 'primary' ? '1.125rem' : '1.25rem',
    fontWeight: 600,
    display: 'inline-block',
    marginBottom: '1rem',
    maxWidth: 'max-content',
  }),
);

interface IStyledBadgeProps {
  is_active: boolean;
  activeText?: string;
  inActiveText?: string;
  style?: any;
  variant?: 'primary' | 'secondary';
}

export const StyledBadge: FC<IStyledBadgeProps> = ({
  is_active,
  activeText = 'Активный',
  inActiveText = 'Не активный',
  style,
  variant = 'primary',
}) => {
  return (
    <StyledBadgeComp variant={variant} is_active={is_active} style={style}>
      {is_active ? activeText : inActiveText}
    </StyledBadgeComp>
  );
};
