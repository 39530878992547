import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { COLORS } from 'utils';

export const StyledHeader = styled(Box)({
  width: '100%',
  height: '18.5rem',
  borderRadius: '0.5rem',
  position: 'relative',
  overflow: 'hidden',
  marginBottom: '1.25rem',
  '& .cover': {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
  },
  '& .meta': {
    position: 'absolute',
    left: '1.25rem',
    bottom: '1.25rem',
    width: 'calc(100% - 2.5rem)',
    display: 'flex',
    alignItems: 'end',
    justifyContent: 'space-between',
    color: COLORS.WHITE,
    fontFamily: '"SF Pro Display", sans-serif',
  },
  '& .info': {
    display: 'flex',
    gap: '0.625rem',
    alignItems: 'center',
  },
  '& .logo': {
    minWidth: '5rem',
    width: '5rem',
    height: '5rem',
    borderRadius: '50%',
    overflow: 'hidden',
    backgroundColor: COLORS.WHITE,
    objectFit: 'contain',
  },
  '& .name': {
    fontSize: '1.75rem',
    lineHeight: '2.125rem',
    fontWeight: 600,
  },
  '& .description': {
    fontSize: '1rem',
    lineHeight: '1.5rem',
    fontWeight: 500,
  },
});

export const StyledBody = styled(Box)({
  padding: '1.25rem 0 0',
  display: 'flex',
  flexWrap: 'wrap',
  gap: '1.25rem',
  '& .column': {
    width: 'calc((100% - 1.25rem) / 2)',
    display: 'flex',
    flexDirection: 'column',
    gap: '1.25rem',
  },
});

export const StyledCard = styled(Box)({
  width: '100%',
  borderRadius: '0.75rem',
  padding: '1rem 1.25rem',
  backgroundColor: COLORS.WHITE,
  border: `1px solid ${COLORS.WHITE}`,
  display: 'flex',
  flexDirection: 'column',
  gap: '0.25rem',
  '& .title': {
    fontSize: '2.125rem',
    lineHeight: '2.5rem',
    fontWeight: 600,
    fontFamily: '"SF Pro Display", sans-serif',
    opacity: 0.75,
  },
  '& > div': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  '& .value': {
    fontSize: '0.875rem',
    lineHeight: '1.5rem',
    fontWeight: 400,
    fontFamily: '"SF Pro Display", sans-serif',
    opacity: 0.5,
  },
  '& .adminsTitle': {
    fontSize: '1.25rem',
    fontWeight: 600,
    fontFamily: '"SF Pro Display", sans-serif',
  },
  '& .adminsList': {
    maxHeight: '15rem',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
  },
  '& .userItem': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '0.5rem',
    borderTop: `1px solid ${COLORS.GRAY}`,
    padding: '0.75rem 0',
    width: '100%',
    '& .userData': {
      display: 'flex',
      alignItems: 'center',
      gap: '0.625rem',
    },
    '& .photo': {
      minWidth: '2.5rem',
      width: '2.5rem',
      height: '2.5rem',
      borderRadius: '50%',
      overflow: 'hidden',
      backgroundColor: COLORS.WHITE,
      '& img': {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
      },
    },
    '& .meta': {
      display: 'flex',
      flexDirection: 'column',
      gap: '0.25rem',
    },
    '& .userRole': {
      textAlign: 'right',
    },
    '& .userRole, .name': {
      fontSize: '0.875rem',
      fontFamily: '"SF Pro Display", sans-serif',
    },
    '& .phone': {
      fontSize: '0.75rem',
      fontFamily: '"SF Pro Display", sans-serif',
      opacity: 0.5,
    },
  },
});
