import * as Pages from 'pages';
import { ROLES } from 'utils';
import { IRoutes } from '../types';

export const Groups: IRoutes = {
  groups: {
    path: '/groups',
    title: 'groups',
    element: <Pages.Groups />,
    roles: [ROLES.ADMINSTRATOR, ROLES.MODERATOR],
  },
};
