import { FC, useMemo } from 'react';
import { styled } from '@mui/system';
import { Box } from '@mui/material';
import { ROUTES } from 'app';
import { COLORS } from 'utils';
import {
  // SpacesIcon,
  UsersIcon,
  WorkoutsIcon,
  ChallengesIcon,
  NewsIcon,
  ShowcaseIcon,
  // ServicesIcon,
} from 'components/Sidebar/icons';
import { IIconProps } from 'components/Sidebar/icons/types';
import { useLocation, useNavigate } from 'react-router-dom';

const StyledContainer = styled(Box)({
  display: 'flex',
  width: '100%',
  marginBottom: '1.25rem',
});

const StyledMenuItem = styled(Box)({
  padding: '0.9375rem',
  width: '100%',
  backgroundColor: COLORS.WHITE,
  textAlign: 'center',
  cursor: 'pointer',
  '& svg': {
    width: '1.5rem',
    height: '1.5rem',
    marginBottom: '0.25rem',
  },
  '& .name': {
    fontSize: '0.875rem',
    fontFamily: '"SF Pro Display", sans-serif',
    lineHeight: '1.375rem',
    opacity: 0.5,
    fontWeight: 500,
    color: COLORS.BLACK,
  },
  '&.active': {
    backgroundColor: 'rgba(0, 128, 255, 0.03)',
    '& .name': {
      color: COLORS.BLUE,
      opacity: 1,
    },
  },
  '&:first-of-type': {
    borderRadius: '0.5rem 0 0 0.5rem',
  },
  '&:last-of-type': {
    borderRadius: '0 0.5rem 0.5rem 0',
  },
});

interface IMenuItem {
  title: string;
  path: string;
  icon: FC<IIconProps>;
}

export const AnalyticsInnerNavbar: FC = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const MENU_ITEMS: IMenuItem[] = useMemo(
    () => [
      // {
      //   title: 'Пространство',
      //   path: ROUTES.analytics.spaces,
      //   icon: SpacesIcon,
      // },
      {
        title: 'Пространство',
        path: ROUTES.analytics.users,
        icon: UsersIcon,
      },
      {
        title: 'Тренировки',
        path: ROUTES.analytics.workouts,
        icon: WorkoutsIcon,
      },
      {
        title: 'Вызовы',
        path: ROUTES.analytics.challenges,
        icon: ChallengesIcon,
      },
      {
        title: 'Новости',
        path: ROUTES.analytics.news,
        icon: NewsIcon,
      },
      {
        title: 'Витрина',
        path: ROUTES.analytics.showcases,
        icon: ShowcaseIcon,
      },
      // {
      //   title: 'Сервисы',
      //   path: ROUTES.analytics.services,
      //   icon: ServicesIcon,
      // },
    ],
    [],
  );

  const onMenuItemClick = (path: string) => {
    navigate(path);
  };

  return (
    <StyledContainer>
      {MENU_ITEMS.map(item => (
        <StyledMenuItem
          key={item.title}
          className={pathname === item.path ? 'active' : ''}
          onClick={() => onMenuItemClick(item.path)}
        >
          <item.icon
            activeColor={COLORS.BLUE}
            isActive={pathname === item.path}
          />
          <Box className='name'>{item.title}</Box>
        </StyledMenuItem>
        // <MenuItem key={path} title={title} path={path} icon={icon} />
      ))}
    </StyledContainer>
  );
};
