/* eslint-disable react/prop-types */
import { useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import EditorJS from '@editorjs/editorjs';

import { localization, tools } from './utils';

export const Editor = ({ rawData, saveRawData }) => {
  const { id } = useParams();
  const editorRef = useRef(null);
  const [editorData, setEditorData] = useState(rawData);

  useEffect(() => {
    if (id && !rawData.time) return;
    initEditor();
  }, [editorRef.current, rawData, id]);

  useEffect(() => {
    return () => {
      editorRef.current?.destroy();
      editorRef.current = null;
    };
  }, []);

  const initEditor = () => {
    if (editorRef.current) return;

    const editor = new EditorJS({
      holder: 'editorJsId',
      language: 'ru',
      tools: { ...tools },
      i18n: { ...localization },
      data: rawData,
      inlineToolbar: true,
      onReady: () => handleSaveHtml(),
      onChange: () => handleChange(),
    });
    setEditorData(rawData);

    editorRef.current = editor;
  };

  useEffect(() => {
    if (!editorData || !editorData.time) return;

    startOfWhile(() => handleSaveHtml(), 100);
  }, [editorData]);

  function startOfWhile(func, time) {
    const delayDebounceFn = setTimeout(func, time);

    return () => clearTimeout(delayDebounceFn);
  }

  const handleSaveHtml = () => {
    if (!editorRef.current?.save) return;

    editorRef.current
      ?.save()
      ?.then(outputData => {
        if (!outputData) return;

        saveRawData(outputData);
      })
      .catch(() => {});
  };

  const handleChange = () => {
    editorRef.current?.save().then(outputData => {
      setEditorData(outputData);
    });
  };

  return (
    <div className='container'>
      <div id='editorJsId'></div>
    </div>
  );
};
