import { FC } from 'react';
import { Drawer, Box } from '@mui/material';
import { styled } from '@mui/system';
import { COLORS } from 'utils';

interface IDrawer {
  open: boolean;
  onClose: () => void;
  style?: React.CSSProperties;
  variant?: 'permanent' | 'persistent' | 'temporary';
  children?: React.ReactNode;
  width?: string | number;
  maxWidth?: string | number;
  type?: 'gray' | 'white';
}

const StyledDrawerBox = styled(Box)(
  ({
    width,
    maxWidth,
    type,
  }: {
    width?: string | number;
    maxWidth: string | number;
    type?: 'gray' | 'white';
  }) => ({
    width,
    maxWidth,
    backgroundColor: type === 'gray' ? 'rgba(0,0,0,0.05)' : COLORS.WHITE,
    padding: '1.875rem',
    borderRadius: '1rem',
    height: '100%',
    position: 'relative',
    overflowY: 'auto',
    '& input[type=number]': {
      MozAppearance: 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
  }),
);

export const DrawerComponent: FC<IDrawer> = ({
  open,
  onClose,
  children,
  style,
  maxWidth = '45rem',
  width = '50vw',
  variant = 'temporary',
  type = 'white',
}) => {
  return (
    <Drawer
      disableEnforceFocus
      open={open}
      variant={variant}
      anchor='right'
      onClose={onClose}
    >
      <StyledDrawerBox
        type={type}
        width={width}
        maxWidth={maxWidth}
        style={style}
      >
        {children}
      </StyledDrawerBox>
    </Drawer>
  );
};
