import { FC } from 'react';
import { IIconProps } from './types';

export const SpacesIcon: FC<IIconProps> = ({ isActive, activeColor }) => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g>
        <path
          d='M21 12V3.6C21 3.44087 20.9368 3.28826 20.8243 3.17574C20.7117 3.06321 20.5591 3 20.4 3H12M21 12H12M21 12V20.4C21 20.5591 20.9368 20.7117 20.8243 20.8243C20.7117 20.9368 20.5591 21 20.4 21H12M12 12V3M12 12V21M12 12H3M12 3H3.6C3.44087 3 3.28826 3.06321 3.17574 3.17574C3.06321 3.28826 3 3.44087 3 3.6V12M12 21H3.6C3.44087 21 3.28826 20.9368 3.17574 20.8243C3.06321 20.7117 3 20.5591 3 20.4V12'
          stroke={isActive ? activeColor : '#C4C4C4'}
          strokeWidth='2'
        />
      </g>
    </svg>
  );
};
