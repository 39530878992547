import { FC, useState, useMemo, useContext } from 'react';
import { TableBody, TableCell, Box } from '@mui/material';

import { UserContext, UserContextType } from 'app';
import { EditIcon, DeleteIcon } from 'assets';
import { Pagination, StyledSwitch } from 'UI';
import {
  useGetStories,
  useDeleteStory,
  useSearchParams,
  useChangeStatus,
} from 'hooks';
import { IStoryShort } from 'types';
import { DeleteModal } from 'components';

import {
  StyledBox,
  StyledPaper,
  StyledTableContainer,
  StyledTable,
  StyledTableRow,
  StyledStoryInfo,
  StyledAvatar,
  StyledStoryInfoAtModal,
} from './styledComponents';
import { StoriesTableHead } from './StoriesTableHead';

const STATUS_TEXTS = {
  waiting: 'Ожидает публикации',
  published: 'Опубликовано',
  inActive: 'Неактивно',
};

export const StoriesTable: FC = () => {
  const { space_id } = useContext(UserContext) as UserContextType;
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [selectedStory, setSelectedStory] = useState<IStoryShort | undefined>(
    undefined,
  );
  const { setParam, getParam, searchParams } = useSearchParams();

  const params = useMemo(() => {
    return {
      page: getParam('page'),
      search: getParam('search'),
    };
  }, [searchParams]);

  const {
    data: { data: stories, last_page: pageCount } = {
      data: [],
      last_page: 1,
    },
  } = useGetStories(params);
  const { mutateAsync: deleteStory } = useDeleteStory();
  const { mutateAsync: changeStatus } = useChangeStatus('story');

  const updateRow = (row: IStoryShort) => {
    changeStatus({
      space_id: space_id!,
      model_name: 'story',
      model_id: row.id,
      state: !row.is_active,
    })
      .then(() => {})
      .catch(() => {});
  };

  const deleteButtonHandler = (row: IStoryShort) => {
    setSelectedStory(row);
    setOpenDeleteModal(true);
  };

  const deleteRow = async () => {
    await deleteStory(selectedStory!.id).catch(() => {});
    setOpenDeleteModal(false);
  };

  const cancelDeleteRow = () => {
    setOpenDeleteModal(false);
    setSelectedStory(undefined);
  };

  const openEditDrawer = (id: string | number) => {
    setParam('edit', id.toString());
  };

  const getStatusText = (row: IStoryShort) => {
    if (!row.is_active) {
      return STATUS_TEXTS.inActive;
    }
    if (row.is_published) {
      return STATUS_TEXTS.published;
    }
    return STATUS_TEXTS.waiting;
  };

  return (
    <StyledBox>
      <DeleteModal
        open={openDeleteModal}
        title='Удалить достижение'
        description='Вы действительно хотите удалить достижение?'
        onConfirm={deleteRow}
        onClose={cancelDeleteRow}
      >
        <StyledStoryInfoAtModal>
          <StyledAvatar>
            <img
              src={selectedStory?.cover as string | undefined}
              alt={selectedStory?.title}
            />
          </StyledAvatar>
          <Box className='name'>{selectedStory?.title}</Box>
        </StyledStoryInfoAtModal>
      </DeleteModal>
      <StyledPaper>
        <StyledTableContainer>
          <StyledTable
            aria-labelledby='storiesTable'
            size='medium'
            stickyHeader
          >
            <StoriesTableHead />
            <TableBody>
              {stories.map(row => {
                return (
                  <StyledTableRow
                    hover
                    key={row.id}
                    sx={{
                      backgroundColor: !row.is_active ? '#fff6f5' : '#ffffff',
                    }}
                  >
                    <TableCell align='center'>{row.position}</TableCell>
                    <TableCell
                      component='th'
                      id={row.id.toString()}
                      scope='row'
                    >
                      <StyledStoryInfo>
                        <StyledAvatar onClick={() => openEditDrawer(row.id)}>
                          <img
                            src={row.cover as string | undefined}
                            alt={row.title}
                          />
                        </StyledAvatar>
                        <Box
                          className='name'
                          onClick={() => openEditDrawer(row.id)}
                        >
                          {row.title}
                        </Box>
                      </StyledStoryInfo>
                    </TableCell>
                    <TableCell>
                      <span>{row.created_at}</span>
                    </TableCell>
                    <TableCell>
                      <span>{row.views_count}</span>
                    </TableCell>
                    <TableCell>
                      <span>{row.clicks_count}</span>
                    </TableCell>
                    <TableCell>
                      <span>{getStatusText(row)}</span>
                    </TableCell>
                    <TableCell>
                      <div className='editDeleteIcons'>
                        <StyledSwitch
                          checked={row.is_active}
                          variant='customGray'
                          onChange={() => updateRow(row)}
                          style={{ opacity: 1 }}
                        />
                        <EditIcon onClick={() => openEditDrawer(row.id)} />
                        <DeleteIcon onClick={() => deleteButtonHandler(row)} />
                      </div>
                    </TableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </StyledTable>
        </StyledTableContainer>
        <Pagination count={pageCount} />
      </StyledPaper>
    </StyledBox>
  );
};
