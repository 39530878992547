import { FC, CSSProperties } from 'react';
import { COLORS } from 'utils';

interface IDownloadIcon {
  color?: string;
  className?: string;
  style?: CSSProperties;
  onClick?: () => void;
  opacity?: number;
}

export const DownloadIcon: FC<IDownloadIcon> = ({
  className,
  color = COLORS.BLACK,
  opacity = 1,
  onClick,
  style,
}) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
    onClick={onClick}
    style={style}
  >
    <path
      opacity={opacity}
      d='M6 20H18M12 4V16M12 16L15.5 12.5M12 16L8.5 12.5'
      stroke={color}
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
