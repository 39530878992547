import { FC, useState, useEffect } from 'react';
import { Box, TextField } from '@mui/material';
import {
  useCreateShopAttribute,
  useUpdateShopAttribute,
  useDeleteShopAttribute,
  useGetShopAttribute,
} from 'hooks';
import {
  Drawer,
  ModalTitle,
  Button,
  StyledSwitch,
  DrawerHeader,
  FormItem,
  DrawerBody,
  DrawerFormBody,
  StyledFlexBox,
  StyledBadge,
} from 'UI';
import { CloseIcon, DeleteIcon, PlusIcon, UpIcon, DownIcon } from 'assets';
import { IShopAttributeReq, IShopAttributeValue } from 'types';
import { COLORS } from 'utils';
import { StyledAttributeValue } from './styledComponents';

const TRANSLATIONS = {
  add: {
    title: 'Новый атрибут',
  },
  edit: {
    title: 'Редактировать атрибут',
  },
};

const DEFAULT_VALUES: IShopAttributeReq = {
  name: '',
  is_active: true,
  description: '',
  values: [],
};
interface IAddEditAttributeDrawerProps {
  open: boolean;
  onClose: () => void;
  purpose?: 'add' | 'edit';
  id?: string | number;
}

export const AddEditAttributeDrawer: FC<IAddEditAttributeDrawerProps> = ({
  open,
  onClose,
  purpose = 'add',
  id = '',
}) => {
  const { data: shopAttributeData } = useGetShopAttribute(id);
  const [attributeInfo, setAttributeInfo] =
    useState<IShopAttributeReq>(DEFAULT_VALUES);

  const { mutateAsync: createAttribute } = useCreateShopAttribute();
  const { mutateAsync: updateAttribute } = useUpdateShopAttribute();
  const { mutateAsync: deleteAttribute } = useDeleteShopAttribute();

  useEffect(() => {
    if (purpose === 'edit') {
      setAttributeInfo({
        id: shopAttributeData?.id || 0,
        name: shopAttributeData?.name || '',
        is_active: !!shopAttributeData?.is_active,
        description: shopAttributeData?.description || '',
        values: shopAttributeData?.values || [],
      });
    } else {
      setAttributeInfo(DEFAULT_VALUES);
    }
  }, [shopAttributeData, purpose]);

  const handleFieldChange = <T extends keyof IShopAttributeReq>(
    fieldName: T,
    newValue: IShopAttributeReq[T],
  ) => {
    setAttributeInfo(prevAttributeInfo => ({
      ...prevAttributeInfo,
      [fieldName]: newValue,
    }));
  };

  const addValue = () => {
    setAttributeInfo(prevAttributeInfo => ({
      ...prevAttributeInfo,
      values: [
        ...prevAttributeInfo.values!,
        {
          name: '',
          value: '',
          position: prevAttributeInfo.values!.length + 1,
        },
      ],
    }));
  };

  const removeValue = (position: number) => {
    setAttributeInfo(prevAttributeInfo => ({
      ...prevAttributeInfo,
      values: prevAttributeInfo.values?.filter(
        value => value.position !== position,
      ),
    }));
  };

  const handleChangeValue = <T extends keyof IShopAttributeValue>(
    position: number,
    fieldName: T,
    newValue: IShopAttributeValue[T],
  ) => {
    setAttributeInfo(prevAttributeInfo => ({
      ...prevAttributeInfo,
      values: prevAttributeInfo.values?.map(value => {
        if (value.position === position) {
          return {
            ...value,
            [fieldName]: newValue,
          };
        }
        return value;
      }),
    }));
  };

  const moveValueUp = (position: number) => {
    const valueIndex = attributeInfo.values?.findIndex(
      value => value.position === position,
    );
    if (valueIndex === undefined || valueIndex === 0) return;
    const updatedValues = [...attributeInfo.values!];
    const value = updatedValues[valueIndex];
    updatedValues[valueIndex] = updatedValues[valueIndex - 1];
    updatedValues[valueIndex - 1] = value;
    setAttributeInfo(prevAttributeInfo => ({
      ...prevAttributeInfo,
      values: updatedValues,
    }));
  };

  const moveValueDown = (position: number) => {
    const valueIndex = attributeInfo.values?.findIndex(
      value => value.position === position,
    );
    if (
      valueIndex === undefined ||
      valueIndex === attributeInfo.values!.length - 1
    )
      return;
    const updatedValues = [...attributeInfo.values!];
    const value = updatedValues[valueIndex];
    updatedValues[valueIndex] = updatedValues[valueIndex + 1];
    updatedValues[valueIndex + 1] = value;
    setAttributeInfo(prevAttributeInfo => ({
      ...prevAttributeInfo,
      values: updatedValues,
    }));
  };

  const saveChanges = () => {
    const valuesWithCorrectPosition = attributeInfo.values?.map(
      (value, index) => ({
        ...value,
        position: index + 1,
      }),
    );
    const submittedAttributeInfo = {
      ...attributeInfo,
      values: valuesWithCorrectPosition,
    };
    if (purpose === 'add') {
      createAttribute(submittedAttributeInfo)
        .then(() => {
          onClose();
        })
        .catch(() => {});
    } else {
      updateAttribute(submittedAttributeInfo)
        .then(() => {
          onClose();
        })
        .catch(() => {});
    }
  };

  const ignoreChanges = () => {
    setAttributeInfo(DEFAULT_VALUES);
    onClose();
  };

  const deleteAttributeHandler = () => {
    deleteAttribute(attributeInfo.id!)
      .then(() => {
        onClose();
      })
      .catch(() => {});
  };

  return (
    <Drawer open={open} onClose={ignoreChanges}>
      <DrawerHeader>
        <StyledFlexBox>
          <ModalTitle title={TRANSLATIONS[purpose].title} />
          <StyledBadge
            is_active={attributeInfo.is_active}
            style={{ marginBottom: 0 }}
          />
        </StyledFlexBox>
        <StyledFlexBox>
          <StyledSwitch
            checked={attributeInfo.is_active}
            onChange={() =>
              handleFieldChange('is_active', !attributeInfo.is_active)
            }
            className='switch'
          />
          <CloseIcon onClick={ignoreChanges} />
        </StyledFlexBox>
      </DrawerHeader>
      <DrawerBody>
        <DrawerFormBody>
          <h2>О атрибут</h2>
          <FormItem label='Название атрибута'>
            <TextField
              variant='standard'
              color='primary'
              placeholder='Введите название'
              name='name'
              value={attributeInfo.name}
              onChange={e => handleFieldChange('name', e.target.value)}
            />
          </FormItem>
          <FormItem label='Описание'>
            <TextField
              variant='standard'
              color='primary'
              placeholder='Введите описание'
              name='description'
              value={attributeInfo.description}
              type='text'
              onChange={e => handleFieldChange('description', e.target.value)}
            />
          </FormItem>
          {attributeInfo.values?.map(({ name, value, position }) => (
            <StyledAttributeValue key={position}>
              <Box className='header'>
                <h3 className='title'>Значение {name}</h3>
                <Box className='iconButtons'>
                  <Button variant='text' onClick={() => moveValueUp(position)}>
                    <UpIcon />
                  </Button>
                  <Button
                    variant='text'
                    onClick={() => moveValueDown(position)}
                  >
                    <DownIcon />
                  </Button>
                  <Button variant='text' onClick={() => removeValue(position)}>
                    <DeleteIcon />
                  </Button>
                </Box>
              </Box>
              <FormItem label='Название'>
                <TextField
                  variant='standard'
                  color='primary'
                  placeholder='Введите название'
                  name='name'
                  value={name}
                  type='text'
                  onChange={e =>
                    handleChangeValue(position, 'name', e.target.value)
                  }
                />
              </FormItem>
              <FormItem label='Значение'>
                <TextField
                  variant='standard'
                  color='primary'
                  placeholder='Введите значение'
                  name='value'
                  value={value}
                  type='text'
                  onChange={e =>
                    handleChangeValue(position, 'value', e.target.value)
                  }
                />
              </FormItem>
            </StyledAttributeValue>
          ))}

          <Button
            startIcon={<PlusIcon color={COLORS.BLUE} />}
            color='customLightBlue'
            onClick={addValue}
          >
            Добавить значение
          </Button>
        </DrawerFormBody>
        <StyledFlexBox className='buttons'>
          {purpose === 'edit' && (
            <Button
              onClick={deleteAttributeHandler}
              variant='contained'
              size='large'
              color='customGray'
              startIcon={<DeleteIcon color={COLORS.RED} />}
              sx={{
                color: COLORS.RED,
              }}
            >
              Удалить
            </Button>
          )}
          {purpose === 'add' && (
            <Button
              onClick={ignoreChanges}
              variant='contained'
              size='large'
              color='customGray'
            >
              Отмена
            </Button>
          )}
          <Button
            variant='contained'
            size='large'
            color='primary'
            onClick={saveChanges}
          >
            Сохранить
          </Button>
        </StyledFlexBox>
      </DrawerBody>
    </Drawer>
  );
};
