import { FC, useEffect, useState, ChangeEvent } from 'react';

import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { CodeInput, Circle, PhoneInput } from 'components';
import { Button } from 'UI';
import { useGetSmsCode, useLogin } from 'hooks';
import { notify, getItem, getErrorMessage } from 'utils';

import classes from './Auth.module.scss';

const StyledButtonBox = styled(Box)({
  width: '100%',
  '& button': {
    width: '100%',
  },
});

export const AuthComponent: FC = () => {
  const [phoneInputValue, setPhoneInputValue] = useState<string>('7');
  const [codeInputValue, setCodeInputValue] = useState<string>('');
  const [showTimer, setShowTimer] = useState<boolean>(false);

  const {
    mutateAsync: login,
    error: loginError,
    isError: isLoginError,
  } = useLogin();
  const {
    mutateAsync: getSmsCode,
    error: smsError,
    isError: isSmsError,
  } = useGetSmsCode();

  useEffect(() => {
    const timerFromStorage = getItem('timer');
    if (timerFromStorage) {
      setShowTimer(Boolean(timerFromStorage));
      return;
    }
    setShowTimer(false);
  }, []);

  const getConfirmationCode = () => {
    const sentPhoneValue = removeWhiteSpaces(phoneInputValue);
    if (!/^(\+7)[0-9]{10}$/.test(sentPhoneValue)) {
      notify('Неверный формат номера телефона');
      return;
    }

    getSmsCode(sentPhoneValue)
      .then(() => {
        setShowTimer(true);
      })
      .catch(() => {});
  };

  const removeWhiteSpaces = (str: string) => str.replace(/\s/g, '');

  const sendConfirmationCode = () => {
    login({
      phone: removeWhiteSpaces(phoneInputValue),
      verification_code: Number(removeWhiteSpaces(codeInputValue)),
    }).catch(() => {});
  };

  const onPhoneInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPhoneInputValue(e.target.value);
  };

  const onCodeInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCodeInputValue(e.target.value);
  };

  const onTimerComplete = () => {
    setShowTimer(false);
  };

  return (
    <div className={classes.auth}>
      <Circle />
      <div className={classes.auth__card}>
        <h1 className={classes.auth__card_title}>Авторизация</h1>
        <PhoneInput
          isError={isSmsError}
          errorMessage={getErrorMessage(smsError)}
          onChange={onPhoneInputChange}
          value={phoneInputValue}
          showTimer={showTimer}
          onTimerComplete={onTimerComplete}
        />
        <CodeInput
          isError={isLoginError}
          errorMessage={getErrorMessage(loginError)}
          onChange={onCodeInputChange}
          value={codeInputValue}
        />
        <StyledButtonBox>
          {!showTimer ? (
            <Button size='large' onClick={getConfirmationCode}>
              Получить СМС
            </Button>
          ) : (
            <Button size='large' onClick={sendConfirmationCode}>
              Войти
            </Button>
          )}
        </StyledButtonBox>
      </div>
    </div>
  );
};
