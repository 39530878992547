import { IAnalyticsCommonGetParams, IAnalyticsUsersTopGetParams } from 'types';

import { methods, http } from '../config';

export const getAnalyticsUsersData = (params: IAnalyticsCommonGetParams) => {
  return http({
    method: methods.GET,
    url: '/statistics/users/data',
    params,
  });
};

export const getAnalyticsUsersTopLists = (
  params: IAnalyticsUsersTopGetParams,
) => {
  return http({
    method: methods.GET,
    url: '/statistics/users/top_lists',
    params,
  });
};

export const getAnalyticsUsersGender = (params: IAnalyticsCommonGetParams) => {
  return http({
    method: methods.GET,
    url: '/statistics/users/gender',
    params,
  });
};

export const getAnalyticsUsersAge = (params: IAnalyticsCommonGetParams) => {
  return http({
    method: methods.GET,
    url: '/statistics/users/birth',
    params,
  });
};

export const getAnalyticsUsersNews = (params: IAnalyticsCommonGetParams) => {
  return http({
    method: methods.GET,
    url: '/statistics/users/news',
    params,
  });
};

export const getAnalyticsUsersChallenges = (
  params: IAnalyticsCommonGetParams,
) => {
  return http({
    method: methods.GET,
    url: '/statistics/users/active_marathons',
    params,
  });
};

export const getAnalyticsUsersDownloadFile = (
  params: IAnalyticsCommonGetParams,
) => {
  return http({
    method: methods.GET,
    url: '/statistics/users/register',
    params,
  });
};
