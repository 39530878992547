import { FC } from 'react';
import { PageHeader, GlobalSearch, Button } from 'UI';
import { PlusIcon } from 'assets';

interface IStandardsHeaderProps {
  openParametersDrawer: () => void;
  openAddStandardDrawer: () => void;
}

export const StandardsHeader: FC<IStandardsHeaderProps> = ({
  openParametersDrawer,
  openAddStandardDrawer,
}) => {
  return (
    <PageHeader title='Нормативы'>
      <GlobalSearch size='medium' />
      <Button
        variant='contained'
        color='customDarkerGray'
        onClick={openParametersDrawer}
        className='withFixedWidth'
      >
        Параметры
      </Button>
      <Button
        variant='contained'
        onClick={openAddStandardDrawer}
        startIcon={<PlusIcon />}
        className='withFixedWidth'
      >
        Создать
      </Button>
    </PageHeader>
  );
};
