import { FC } from 'react';
import { TextField } from '@mui/material';
import {
  FormItem,
  NewsImageUpload,
  StyledFlexBox,
  StyledSwitch,
  Button,
  SearchSelectInput,
  NewsDateTimePicker,
  TagAdder,
} from 'UI';
import { INewsReq } from 'types';

import { MEDIA_TYPES } from 'utils';
import {
  StyledLayout,
  MainFormItems,
  StyledContainerBox,
  StyledSwitchFormControl,
} from './styledComponents';

export interface IParameterNews
  extends Omit<INewsReq, 'id' | 'content' | 'content_json'> {
  id?: number;
}

interface ICreateNewsSidebarProps {
  parameters: IParameterNews;

  categoryOptions: { label: string; value: number }[];
  spaceOptions: { label: string; value: number }[];
  groupOptions: { label: string; value: number }[];
  showGroups?: boolean;
  handleFieldChange: <T extends keyof IParameterNews>(
    _fieldName: T,
    _newValue: IParameterNews[T],
  ) => void;
  setShowGroups: (_newValue: boolean) => void;
  onSubmit: (_data: IParameterNews) => void;
  onCancel: () => void;
}

export const CreateNewsSidebar: FC<ICreateNewsSidebarProps> = ({
  parameters,
  categoryOptions = [],
  spaceOptions = [],
  groupOptions = [],
  showGroups = false,
  handleFieldChange,
  setShowGroups,
  onSubmit,
  onCancel,
}) => {
  const changeShowGroups = () => {
    setShowGroups(!showGroups);
    handleFieldChange('group_id', 0);
  };

  const saveChanges = () => {
    onSubmit(parameters);
  };
  const ignoreChanges = () => {
    onCancel();
  };

  return (
    <StyledLayout>
      <h2>Параметры</h2>
      <MainFormItems>
        <NewsImageUpload
          image={parameters.cover}
          setImage={newPhoto => handleFieldChange('cover', newPhoto)}
          imageFixedWidth={1125}
          imageFixedHeight={675}
          inputId='newsCover'
          title='Добавить обложку'
        />
        <NewsImageUpload
          image={parameters.cover_vertical}
          setImage={newPhoto => handleFieldChange('cover_vertical', newPhoto)}
          inputId='newsCoverMobile'
          imageFixedWidth={1080}
          imageFixedHeight={1920}
          mediaType={MEDIA_TYPES.NEWS_COVER_VERTICAL}
          title='Добавить обложку мобильной версии'
        />
        <FormItem label='Заголовок'>
          <TextField
            variant='standard'
            color='primary'
            placeholder='Введите текст'
            name='name'
            value={parameters.name}
            onChange={e => handleFieldChange('name', e.target.value)}
          />
        </FormItem>
        <FormItem label='Описание'>
          <TextField
            variant='standard'
            color='primary'
            placeholder='Впишите текст описания'
            name='description'
            value={parameters.description}
            onChange={e => handleFieldChange('description', e.target.value)}
          />
        </FormItem>
        <FormItem label='Категория'>
          {parameters.space_id && (
            <SearchSelectInput
              options={categoryOptions}
              onChange={newValue =>
                handleFieldChange('category_id', newValue as number)
              }
              value={parameters.category_id}
              placeholder='Без категории'
              variant='standard'
            />
          )}
        </FormItem>
        <TagAdder
          tags={parameters.tags}
          updateTags={newTags => handleFieldChange('tags', newTags)}
        />
        <NewsDateTimePicker
          dateLabel='Дата публикации'
          timeLabel='Время публикации'
          defaultValue={parameters.published_at}
          onChange={(newValue: string) =>
            handleFieldChange('published_at', newValue)
          }
        />
      </MainFormItems>
      <StyledContainerBox>
        <StyledSwitchFormControl
          label='Разрешить комментарии'
          labelPlacement='start'
          control={
            <StyledSwitch
              checked={parameters.comments_allowed}
              onChange={() =>
                handleFieldChange(
                  'comments_allowed',
                  !parameters.comments_allowed,
                )
              }
            />
          }
        />
        <StyledSwitchFormControl
          label='Отображать новость'
          labelPlacement='start'
          control={
            <StyledSwitch
              checked={parameters.is_active}
              onChange={() =>
                handleFieldChange('is_active', !parameters.is_active)
              }
            />
          }
        />
        <StyledSwitchFormControl
          label='Закрепить новость'
          labelPlacement='start'
          control={
            <StyledSwitch
              checked={parameters.is_pinned}
              onChange={() =>
                handleFieldChange('is_pinned', !parameters.is_pinned)
              }
            />
          }
        />
        <StyledSwitchFormControl
          label='Закрепить на главной'
          labelPlacement='start'
          control={
            <StyledSwitch
              checked={parameters.show_in_main}
              onChange={() =>
                handleFieldChange('show_in_main', !parameters.show_in_main)
              }
            />
          }
        />
        {parameters.show_in_main && (
          <FormItem label='Позиция на главной'>
            <TextField
              variant='standard'
              color='primary'
              placeholder='Введите позицию'
              name='position'
              value={parameters.position_in_main}
              onChange={e =>
                handleFieldChange(
                  'position_in_main',
                  e.target.value as unknown as number,
                )
              }
              sx={{ width: '100%' }}
            />
          </FormItem>
        )}
        <StyledSwitchFormControl
          label='Показать в слайдере'
          labelPlacement='start'
          control={
            <StyledSwitch
              checked={parameters.show_in_slider}
              onChange={() =>
                handleFieldChange('show_in_slider', !parameters.show_in_slider)
              }
            />
          }
        />
        {parameters.show_in_slider && (
          <FormItem label='Позиция в слайдере'>
            <TextField
              variant='standard'
              color='primary'
              placeholder='Введите позицию'
              name='position'
              value={parameters.position_in_slider}
              onChange={e =>
                handleFieldChange(
                  'position_in_slider',
                  e.target.value as unknown as number,
                )
              }
              sx={{ width: '100%' }}
            />
          </FormItem>
        )}
        <StyledSwitchFormControl
          label='Отправить пуш'
          labelPlacement='start'
          control={
            <StyledSwitch
              checked={parameters.has_push_notification}
              onChange={() =>
                handleFieldChange(
                  'has_push_notification',
                  !parameters.has_push_notification,
                )
              }
            />
          }
        />
        {parameters.has_push_notification && (
          <NewsDateTimePicker
            dateLabel='Дата отправить пуш'
            timeLabel='Время пуш уведомлений'
            defaultValue={parameters.pushed_at}
            onChange={(newValue: string) =>
              handleFieldChange('pushed_at', newValue)
            }
          />
        )}
      </StyledContainerBox>
      <StyledContainerBox>
        <FormItem label='Пространство'>
          <SearchSelectInput
            options={spaceOptions}
            onChange={newValue =>
              handleFieldChange('space_id', newValue as number)
            }
            value={parameters.space_id}
            placeholder='Выбрать пространство'
            variant='standard'
            disableClearable
          />
        </FormItem>
        <StyledSwitchFormControl
          label='Новость группы'
          labelPlacement='start'
          control={
            <StyledSwitch
              checked={showGroups}
              onChange={() => changeShowGroups()}
            />
          }
        />
        {showGroups && (
          <FormItem label='Группа'>
            <SearchSelectInput
              options={groupOptions}
              onChange={newValue =>
                handleFieldChange('group_id', newValue as number)
              }
              value={parameters.group_id}
              placeholder='Выбрать группу'
              variant='standard'
            />
          </FormItem>
        )}
      </StyledContainerBox>
      <StyledFlexBox>
        <Button
          onClick={ignoreChanges}
          variant='contained'
          size='large'
          color='customGray'
        >
          Удалить
        </Button>
        <Button
          variant='contained'
          size='large'
          color='primary'
          onClick={saveChanges}
        >
          Опубликовать
        </Button>
      </StyledFlexBox>
    </StyledLayout>
  );
};
