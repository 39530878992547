import { useQuery, useMutation, useQueryClient } from 'react-query';

import {
  getShopProducts,
  getShopProduct,
  createShopProduct,
  updateShopProduct,
  deleteShopProduct,
} from 'api';

import { IShopProduct, IShopProductReqParams, IShopProductRes } from 'types';

export const useGetShopProducts = (params: IShopProductReqParams) => {
  const { category_id, page, search, column, sort_type } = params;
  return useQuery<IShopProductRes, unknown>(
    ['shopProducts', { category_id, page, search, column, sort_type }],
    async () => {
      const res = await getShopProducts(params);
      return res;
    },
    {
      staleTime: 60 * 60 * 1000,
      retry: false,
    },
  );
};

export const useGetShopProduct = (params: IShopProductReqParams) => {
  const { category_id, id } = params;
  if (!id || id === '0' || !category_id) {
    return useQuery(['shopProducts', 'item', id], () => {
      return Promise.resolve(null);
    });
  }
  return useQuery<IShopProduct, unknown>(
    ['shopProducts', 'item', { category_id, id }],
    async () => {
      const res = await getShopProduct(params);
      return res;
    },
    {
      retry: false,
    },
  );
};

export const useCreateShopProduct = () => {
  const queryClient = useQueryClient();
  return useMutation(createShopProduct, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['shopProducts'] });
    },
    onError: () => {},
  });
};

export const useUpdateShopProduct = () => {
  const queryClient = useQueryClient();
  return useMutation(updateShopProduct, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['shopProducts'] });
    },
    onError: () => {},
  });
};

export const useDeleteShopProduct = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteShopProduct, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['shopProducts'] });
    },
    onError: () => {},
  });
};
