import { FC } from 'react';
import { Drawer } from 'UI';

interface IAddEditStandardDrawerProps {
  open: boolean;
  onClose: () => void;
  purpose?: 'add' | 'edit';
  id?: number | string;
}

export const AddEditStandardDrawer: FC<IAddEditStandardDrawerProps> = ({
  open,
  onClose,
  purpose = 'add',
  id = '',
}) => {
  return <Drawer open={open} onClose={onClose}></Drawer>;
};
