import { FC } from 'react';
import { styled } from '@mui/system';
import { Box } from '@mui/material';
import { Map, Placemark } from 'react-yandex-maps';

const StyledContainer = styled(Box)({});

interface IMapProps {
  location: number[];
  changeLocation: (_newLocation: number[]) => void;
}

export const MapComponent: FC<IMapProps> = ({ location, changeLocation }) => {
  const windowWidth = window.innerWidth;
  const changeLocationByDrag = (e: any) => {
    const coords = e.get('target').geometry.getCoordinates();
    changeLocation(coords);
  };

  const changeLocationByClick = (e: any) => {
    const coords = e.get('coords');
    changeLocation(coords);
  };

  return (
    <StyledContainer>
      <Map
        defaultState={{
          center: location || [55.75, 37.57],
          zoom: 15,
          controls: ['zoomControl'],
        }}
        modules={['control.ZoomControl']}
        width={0.415 * windowWidth}
        height={400}
        onClick={(e: any) => changeLocationByClick(e)}
      >
        {location && (
          <Placemark
            modules={['geoObject.addon.balloon', 'geoObject.addon.hint']}
            geometry={location}
            onDragEnd={(e: any) => changeLocationByDrag(e)}
            options={{
              iconColor: 'red',
              draggable: true,
            }}
          />
        )}
      </Map>
    </StyledContainer>
  );
};
