import * as Pages from 'pages';
import { ROLES } from 'utils';

import { IRoutes } from '../types';

export const Achievements: IRoutes = {
  achievements: {
    path: '/achievements',
    title: 'achievements',
    element: <Pages.Achievements />,
    roles: [ROLES.ADMINSTRATOR],
  },
};
