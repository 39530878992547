import { Box, FormControlLabel } from '@mui/material';
import { styled } from '@mui/system';
import { COLORS } from 'utils';

export const StyledContainer = styled(Box)({
  marginTop: '0.25rem',
  width: '100%',
  '& h2': {
    fontSize: '1.125rem',
    fontWeight: 600,
    fontFamily: '"SF Pro Display", sans-serif',
    lineHeight: '1.625rem',
    margin: '0 0 0.625rem',
  },
});

export const PopoverBox = styled(Box)({
  width: 'calc(50vw - 6.25rem)',
});

export const StyledMainContent = styled(Box)({
  border: `1px solid rgba(0,0,0,0.1)`,
  borderRadius: '0.5rem',
  '& .addButton': {
    width: 'calc(100% - 2.5rem)',
    margin: '1.25rem',
  },
});

export const StyledSwitchFormControl = styled(FormControlLabel)({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  borderBottom: `1px solid ${COLORS.BLACK}`,
  margin: 0,
  paddingBottom: '0.375rem',
  '& .MuiFormControlLabel-label': {
    marginTop: '0.375rem',
    fontSize: '1rem',
    lineHeight: '1.5rem',
  },
});

export const StyledTeamOptions = styled(Box)({
  maxHeight: '20rem',
  overflowY: 'auto',
  width: '100%',
});

export const StyledTeamOption = styled(Box)({
  display: 'flex',
  padding: '0.8125rem 0.9375rem',
  gap: '0.625rem',
  alignItems: 'center',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    '& .deleteButton': {
      visibility: 'visible',
    },
  },
  '& .imageBox': {
    minWidth: '3.125rem',
    height: '3.125rem',
    overflow: 'hidden',
    borderRadius: '50%',
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
    },
  },
  '& .name': {
    fontSize: '0.875rem',
    fontWeight: '600',
    fontFamily: '"SF Pro Display", sans-serif',
    lineHeight: '1.5rem',
    width: '100%',
  },
  '& p': {
    fontSize: '0.875rem',
    fontWeight: '400',
    fontFamily: '"SF Pro Display", sans-serif',
    lineHeight: '1.375rem',
    margin: 0,
    minWidth: '23%',
  },
  '& .membersCount': {
    // minWidth: '10%',
    textAlign: 'center',
  },
  '& .deleteButton': {
    visibility: 'hidden',
  },
});

export const StyledTeamsList = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '0.9375rem',
  maxHeight: '14rem',
  overflowY: 'auto',
});
