import { FC } from 'react';
import { COLORS } from 'utils';

interface ICommentIconProps {
  color?: string;
  className?: string;
  style?: React.CSSProperties;
  opacity?: number;
  onClick?: () => void;
}

export const CommentIconNews: FC<ICommentIconProps> = ({
  className,
  color = COLORS.BLACK,
  onClick,
  style,
  opacity = '0.5',
}) => {
  return (
    <svg
      width='22'
      height='22'
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      onClick={onClick}
      style={style}
    >
      <path
        d='M18.5477 14.3326C18.9982 13.3135 19.2484 12.186 19.2484 11C19.2484 6.44365 15.5551 2.75 10.9992 2.75C6.4433 2.75 2.75 6.44365 2.75 11C2.75 15.5563 6.4433 19.25 10.9992 19.25C12.466 19.25 13.8434 18.8671 15.0369 18.1959L19.25 19.2492L18.5477 14.3326Z'
        stroke={color}
        strokeOpacity={opacity}
        strokeWidth='1.83333'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
