import { FC, useState, useMemo, useEffect } from 'react';
import { TextField, FormControlLabel, Radio, Box } from '@mui/material';
import {
  useGetSpacesList,
  useGetWorker,
  useCreateWorker,
  useUpdateWorker,
  useGetRolesList,
} from 'hooks';
import {
  Drawer,
  ModalTitle,
  Button,
  ImageUpload,
  SearchSelectInput,
  DrawerHeader,
  FormItem,
  DrawerBody,
  DrawerFormBody,
  StyledFlexBox,
  StyledRadioGroup,
  DateInput,
} from 'UI';
import { DeleteUserModal } from 'components';
import { CloseIcon, PlusIcon, StarIcon, DeleteIcon } from 'assets';
import { IWorkerReq, IWorkerSpace } from 'types';
import {
  COLORS,
  MEDIA_TYPES,
  isStringNumber,
  isStringValidRussianPhoneNumber,
  notify,
} from 'utils';
import { StyledRoleBox } from './styledComponents';

const TRANSLATIONS = {
  add: {
    title: 'Новый сотрудник',
  },
  edit: {
    title: 'Редактировать сотрудника',
  },
};

const DEFAULT_VALUES = {
  firstname: '',
  lastname: '',
  middlename: '',
  photo: '',
  phone: '',
  email: '',
  sex: 0,
  birthday: '1990-01-01',
  spaces: [],
  coins_count: 0,
  points_count: 0,
};

const NUMBER_PARAMETERS = ['coins_count', 'points_count'];

interface IAddEditWorkerDrawerProps {
  open: boolean;
  onClose: () => void;
  purpose?: 'add' | 'edit';
  id?: number | string;
}

export const AddEditWorkerDrawer: FC<IAddEditWorkerDrawerProps> = ({
  open,
  onClose,
  purpose = 'add',
  id = '',
}) => {
  const { data: workerInfoData } = useGetWorker(id);
  const [workerInfo, setWorkerInfo] = useState<IWorkerReq>(DEFAULT_VALUES);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const { data: spacesList } = useGetSpacesList();
  const { data: rolesList } = useGetRolesList();
  const { mutateAsync: createWorker } = useCreateWorker();
  const { mutateAsync: updateWorker } = useUpdateWorker();

  useEffect(() => {
    if (purpose === 'edit') {
      setWorkerInfo({
        id: workerInfoData?.id,
        firstname: workerInfoData?.firstname || '',
        lastname: workerInfoData?.lastname || '',
        middlename: workerInfoData?.middlename || '',
        photo: workerInfoData?.photo || '',
        phone: workerInfoData?.phone || '',
        sex: workerInfoData?.sex?.id,
        email: workerInfoData?.email || '',
        birthday: workerInfoData?.birthday || '1990-01-01',
        coins_count: workerInfoData?.coins_count || 0,
        points_count: workerInfoData?.points_count || 0,
        spaces:
          workerInfoData?.spaces?.map(space => ({
            id: space.id,
            role: space.role.id,
          })) || [],
      });
    } else {
      setWorkerInfo(DEFAULT_VALUES);
    }
  }, [purpose, workerInfoData]);

  const spaceOptions = useMemo(() => {
    return (
      spacesList?.map(space => ({
        label: space.name,
        value: space.id,
      })) || []
    );
  }, [spacesList]);

  const roleOptions = useMemo(() => {
    return (
      rolesList?.map(role => ({
        label: role.name,
        value: role.id,
      })) || []
    );
  }, [rolesList]);

  const handleFieldChange = <T extends keyof IWorkerReq>(
    fieldName: T,
    newValue: IWorkerReq[T],
  ) => {
    if (NUMBER_PARAMETERS.includes(fieldName) && !isStringNumber(newValue)) {
      return;
    }
    setWorkerInfo(prevworkerInfo => ({
      ...prevworkerInfo,
      [fieldName]: newValue,
    }));
  };

  const getArrayOfUpdatedSpaces = (
    initialSpaces: IWorkerSpace[],
    newSpaces: IWorkerSpace[],
  ): Partial<IWorkerReq> => {
    const invitedSpaces: IWorkerSpace[] = [];
    const removedSpaces: number[] = [];

    newSpaces.forEach(newSpace => {
      if (!newSpace.id) return;
      // If newSpace does not exist in initialSpaces or if their roles do not match add it to invitedSpaces
      if (
        !initialSpaces.some(
          initialSpace => initialSpace.id === newSpace.id && initialSpace.role,
        ) ||
        // you may create another if for this case and add that space to both removed and invited spaces
        initialSpaces.some(
          initialSpace =>
            initialSpace.id === newSpace.id &&
            initialSpace.role !== newSpace.role,
        )
      ) {
        invitedSpaces.push(newSpace);
      }
    });

    initialSpaces.forEach(initialSpace => {
      if (!initialSpace.id) return;
      // If initialSpace does not exist in newSpaces add it to removedSpaces
      if (!newSpaces.some(newSpace => newSpace.id === initialSpace.id)) {
        removedSpaces.push(initialSpace.id);
      }
    });

    return {
      invited_spaces: invitedSpaces,
      removed_spaces: removedSpaces,
    };
  };

  const saveChanges = () => {
    if (!isStringValidRussianPhoneNumber(workerInfo.phone)) {
      notify('Неверный формат номера телефона');
      return;
    }
    const submittedValue = {
      ...workerInfo,
      ...getArrayOfUpdatedSpaces(
        workerInfoData?.spaces?.map(space => ({
          id: space.id,
          role: space.role.id,
        })) || [],
        workerInfo.spaces,
      ),
    };
    if (purpose === 'add') {
      createWorker(submittedValue)
        .then(() => {
          onClose();
        })
        .catch(() => {});
    } else {
      updateWorker(submittedValue)
        .then(() => {
          onClose();
        })
        .catch(() => {});
    }
  };

  const openDeleteModal = () => {
    setShowDeleteModal(true);
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const ignoreChanges = () => {
    setWorkerInfo(DEFAULT_VALUES);
    onClose();
  };

  const addRoleBox = () => {
    handleFieldChange('spaces', [...workerInfo.spaces, { id: 0, role: 0 }]);
  };

  const changeRole = (index: number, spaceId: number, role: number) => {
    const newSpaces = [...workerInfo.spaces];
    newSpaces[index] = {
      id: spaceId,
      role,
    };
    handleFieldChange('spaces', newSpaces);
  };

  return (
    <Drawer open={open} onClose={ignoreChanges}>
      <DeleteUserModal
        open={showDeleteModal}
        onClose={closeDeleteModal}
        id={workerInfo?.id}
        firstname={workerInfo?.firstname}
        lastname={workerInfo?.lastname}
        photo={workerInfo?.photo as string | undefined}
        phone={workerInfo?.phone}
        userType='worker'
      />
      <DrawerHeader>
        <ModalTitle title={TRANSLATIONS[purpose].title} />
        <CloseIcon onClick={ignoreChanges} />
      </DrawerHeader>
      <DrawerBody>
        <ImageUpload
          image={workerInfo.photo}
          setImage={(newPhoto: string) => handleFieldChange('photo', newPhoto)}
          inputId='workerPhoto'
          mediaType={MEDIA_TYPES.USER}
        />
        <DrawerFormBody>
          <h2>О сотруднике</h2>
          <FormItem label='Фамилия'>
            <TextField
              variant='standard'
              color='primary'
              placeholder='Введите фамилию'
              name='lastname'
              value={workerInfo.lastname}
              onChange={e => handleFieldChange('lastname', e.target.value)}
            />
          </FormItem>
          <FormItem label='Имя'>
            <TextField
              variant='standard'
              color='primary'
              placeholder='Введите имя'
              name='firstname'
              value={workerInfo.firstname}
              onChange={e => handleFieldChange('firstname', e.target.value)}
            />
          </FormItem>
          <FormItem label='Отчество'>
            <TextField
              variant='standard'
              color='primary'
              placeholder='Введите отчество'
              name='middlename'
              value={workerInfo.middlename}
              onChange={e => handleFieldChange('middlename', e.target.value)}
            />
          </FormItem>
          <FormItem label='Номер телефона'>
            <TextField
              variant='standard'
              color='primary'
              placeholder='Введите номер телефона'
              name='phone'
              value={workerInfo.phone}
              onChange={e => handleFieldChange('phone', e.target.value)}
            />
          </FormItem>
          <FormItem label='Эл-почта'>
            <TextField
              variant='standard'
              color='primary'
              placeholder='Введите эл-почту'
              name='email'
              value={workerInfo.email}
              type='email'
              autoComplete='none'
              onChange={e => handleFieldChange('email', e.target.value)}
            />
          </FormItem>
          <FormItem label='Дата рождения'>
            <DateInput
              key={workerInfo.birthday}
              stringValue={workerInfo.birthday}
              onChange={newVal => handleFieldChange('birthday', newVal)}
            />
          </FormItem>

          <FormItem label='Укажите пол'>
            <StyledRadioGroup
              row
              value={workerInfo.sex}
              onChange={e => handleFieldChange('sex', Number(e.target.value))}
            >
              <FormControlLabel
                value={1}
                control={<Radio />}
                label='Мужской'
                labelPlacement='start'
              />
              <FormControlLabel
                value={2}
                control={<Radio />}
                label='Женский'
                labelPlacement='start'
              />
            </StyledRadioGroup>
          </FormItem>
          {workerInfo.spaces.map((space, index) => (
            <StyledRoleBox>
              <h3>Роль {index + 1}</h3>
              <FormItem label='Пространство'>
                <SearchSelectInput
                  options={spaceOptions}
                  value={workerInfo.spaces[index].id}
                  onChange={newValue =>
                    changeRole(
                      index,
                      newValue as number,
                      workerInfo.spaces[index].role,
                    )
                  }
                  placeholder='Выберите пространство'
                  variant='standard'
                />
              </FormItem>
              <FormItem label='Роль'>
                <SearchSelectInput
                  options={roleOptions}
                  value={workerInfo.spaces[index].role}
                  onChange={newValue =>
                    changeRole(
                      index,
                      workerInfo.spaces[index].id,
                      newValue as number,
                    )
                  }
                  placeholder='Выберите роль'
                  variant='standard'
                />
              </FormItem>
            </StyledRoleBox>
          ))}

          <Button
            startIcon={<PlusIcon color={COLORS.BLUE} />}
            color='customLightBlue'
            onClick={addRoleBox}
          >
            Добавить роль
          </Button>

          <StyledFlexBox className='fullDivWidth'>
            {/* <FormItem label='Баллы монеты'>
              <TextField
                variant='standard'
                color='primary'
                placeholder='Укажите баллы'
                name='coins_count'
                value={workerInfo.coins_count}
                onChange={e =>
                  handleFieldChange(
                    'coins_count',
                    e.target.value as unknown as number,
                  )
                }
                InputProps={{
                  startAdornment: (
                    <MoneyIcon style={{ marginRight: '0.75rem' }} />
                  ),
                  sx: {},
                  endAdornment: (
                    <PlusIcon
                      color={COLORS.BLUE}
                      className='plusIcon'
                      onClick={() =>
                        handleFieldChange(
                          'coins_count',
                          workerInfo.coins_count
                            ? workerInfo.coins_count + 1
                            : 1,
                        )
                      }
                    />
                  ),
                }}
              />
            </FormItem> */}
            <FormItem label='Звезды'>
              <TextField
                variant='standard'
                color='primary'
                placeholder='Укажите звезды'
                name='points_count'
                value={workerInfo.points_count}
                onChange={e =>
                  handleFieldChange(
                    'points_count',
                    e.target.value as unknown as number,
                  )
                }
                InputProps={{
                  startAdornment: (
                    <StarIcon style={{ marginRight: '0.75rem' }} />
                  ),
                  endAdornment: (
                    <PlusIcon
                      color={COLORS.BLUE}
                      className='plusIcon'
                      onClick={() =>
                        handleFieldChange(
                          'points_count',
                          workerInfo.points_count
                            ? workerInfo.points_count + 1
                            : 1,
                        )
                      }
                    />
                  ),
                }}
              />
            </FormItem>
            <Box />
          </StyledFlexBox>
        </DrawerFormBody>
        <StyledFlexBox className='buttons'>
          <Button
            variant='contained'
            size='large'
            color='primary'
            onClick={saveChanges}
            sx={{ maxWidth: '50%', marginLeft: 'auto' }}
          >
            Сохранить
          </Button>
          {purpose === 'edit' && (
            <Button
              onClick={openDeleteModal}
              variant='contained'
              size='large'
              color='customGray'
              startIcon={<DeleteIcon color={COLORS.RED} />}
              sx={{
                color: COLORS.RED,
              }}
            >
              Удалить
            </Button>
          )}
        </StyledFlexBox>
      </DrawerBody>
    </Drawer>
  );
};
