import * as Pages from 'pages';
import { ROLES } from 'utils';
import { IRoutes } from '../types';

export const Analytics: IRoutes = {
  analytics: {
    path: '/analytics',
    title: 'analytics',
    element: <Pages.Analytics />,
    roles: [ROLES.ADMINSTRATOR, ROLES.MODERATOR],
    childRoute: {
      spaces: {
        path: 'spaces',
        title: 'spaces',
        element: <Pages.SpacesAnalytics />,
        index: true,
        roles: [ROLES.ADMINSTRATOR],
      },
      users: {
        path: 'users',
        title: 'users',
        element: <Pages.UsersAnalytics />,
        index: true,
        roles: [ROLES.ADMINSTRATOR],
      },
      challenges: {
        path: 'challenges',
        title: 'challenges',
        element: <Pages.ChallengesAnalytics />,
        roles: [ROLES.ADMINSTRATOR],
      },
      workouts: {
        path: 'workouts',
        title: 'workouts',
        element: <Pages.WorkoutsAnalytics />,
        roles: [ROLES.ADMINSTRATOR],
      },
      teams: {
        path: 'teams',
        title: 'teams',
        element: <Pages.TeamsAnalytics />,
        roles: [ROLES.ADMINSTRATOR],
      },
      news: {
        path: 'news',
        title: 'news',
        element: <Pages.NewsAnalytics />,
        roles: [ROLES.ADMINSTRATOR, ROLES.MODERATOR],
      },
      showcases: {
        path: 'showcases',
        title: 'showcases',
        element: <Pages.ShowcasesAnalytics />,
        roles: [ROLES.ADMINSTRATOR],
      },
      services: {
        path: 'services',
        title: 'services',
        element: <Pages.ServicesAnalytics />,
        roles: [ROLES.ADMINSTRATOR],
      },
    },
  },
};

export const FullAnalytics: IRoutes = {
  fullAnalytics: {
    path: '/full-analytics',
    title: 'fullAnalytics',
    element: <Pages.FullAnalytics />,
    roles: [ROLES.ADMINSTRATOR],
  },
};
