import { FC, useEffect, useState } from 'react';
import { useSearchParams } from 'hooks';

import { SearchInput } from 'UI';

interface IGlobalSearchProps {
  size?: 'small' | 'medium';
}

export const GlobalSearch: FC<IGlobalSearchProps> = ({ size = 'small' }) => {
  const [searchText, setSearchText] = useState('');
  const { getParam, setParams } = useSearchParams();

  const handleSearch = (value: string) => {
    setSearchText(value);
  };

  useEffect(() => {
    setParams({ search: searchText });
  }, [searchText]);

  return (
    <SearchInput
      handleSearch={handleSearch}
      size={size}
      defaultValue={getParam('search')}
    />
  );
};
