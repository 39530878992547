import { FC, Suspense, lazy } from 'react';
import { useDrawer } from 'hooks';
import { Loader } from 'UI';

const OfferAndDiscountTable = lazy(() =>
  import('components').then(module => ({
    default: module.OfferAndDiscountTable,
  })),
);
const OfferAndDiscountDrawer = lazy(() =>
  import('components').then(module => ({
    default: module.OfferAndDiscountDrawer,
  })),
);

export const Offers: FC = () => {
  const { showAddEditDrawer, hideAddEditDrawer, editDrawerId } = useDrawer();
  return (
    <Suspense fallback={<Loader />}>
      <OfferAndDiscountDrawer
        open={showAddEditDrawer}
        onClose={hideAddEditDrawer}
        id={editDrawerId}
        purpose={editDrawerId === '0' ? 'add' : 'edit'}
      />
      <OfferAndDiscountTable type={2} />
    </Suspense>
  );
};
