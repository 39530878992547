import { FC, MouseEvent } from 'react';
import { TableRow, TableCell, TableSortLabel } from '@mui/material';
import {
  IHeadCell,
  IRegion,
  ITableHeadPropsWithSort,
  RegionSortParams,
} from 'types';
import { RegionsSortColumnValues, COLORS } from 'utils';
import { StyledTableHead } from './styledComponents';

const headCells: readonly IHeadCell<IRegion>[] = [
  {
    id: 'id',
    label: 'ID',
    sortable: true,
  },
  {
    id: 'name',
    label: 'Название',
    sortable: true,
  },
  {
    id: 'region',
    label: 'Регион',
    sortable: false,
  },
  {
    id: 'created_at',
    label: 'Дата и время',
    sortable: true,
  },
];

export const RegionsTableHead: FC<
  ITableHeadPropsWithSort<RegionSortParams>
> = ({ onRequestSort, order, orderBy }) => {
  const isValidSortParam = (param: string): param is RegionSortParams => {
    return RegionsSortColumnValues.includes(param as RegionSortParams);
  };

  const createSortHandler =
    (property: keyof IRegion | 'actions') => (event: MouseEvent<unknown>) => {
      if (isValidSortParam(property)) {
        onRequestSort(event, property);
      }
    };
  return (
    <StyledTableHead>
      <TableRow>
        {headCells.map(headCell => {
          if (!headCell.sortable)
            return (
              <TableCell key={headCell.id} align='center' padding='normal'>
                {headCell.label}
              </TableCell>
            );
          return (
            <TableCell
              key={headCell.id}
              align='center'
              padding='normal'
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
                sx={{
                  '&.Mui-active': {
                    '& .MuiTableSortLabel-icon': {
                      color: orderBy === headCell.id ? COLORS.RED : undefined,
                    },
                  },
                  '& .MuiTableSortLabel-icon': {
                    opacity: 0.3,
                  },
                }}
              >
                {headCell.label}
              </TableSortLabel>
            </TableCell>
          );
        })}
      </TableRow>
    </StyledTableHead>
  );
};
