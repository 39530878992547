import { FC, useMemo } from 'react';
import { Grid } from '@mui/material';
import { IPartner } from 'types';
import { useGetPartners, useSearchParams } from 'hooks';
import { PartnerCard } from 'components';
import { CardsContainer, Pagination, PaginationContainer } from 'UI';

const MOCK_PARTNERS: IPartner[] = [
  {
    id: '1',
    name: 'OZON',
    logo: 'https://www.ozon.ru/multimedia/1024958875.jpg',
    category: 'Электорика',
    is_active: true,
    website: 'https://www.ozon.ru/',
    contract: 'https://www.ozon.ru/',
    locations: [],
  },
  {
    id: '2',
    name: 'Aviasales.ru',
    logo: 'https://www.ozon.ru/multimedia/1024958875.jpg',
    category: 'Сервисы',
    is_active: true,
    website: 'https://www.aviasales.ru/',
    contract: 'https://www.aviasales.ru/',
    locations: [],
  },
];

export const PartnersBody: FC = () => {
  const { searchParams, getParam } = useSearchParams();
  const params = useMemo(() => {
    return {
      page: getParam('page'),
      search: getParam('search'),
    };
  }, [searchParams]);

  const {
    data: { data: partners, last_page: pageCount } = {
      data: MOCK_PARTNERS,
      last_page: 1,
    },
  } = useGetPartners(params);

  return (
    <>
      <CardsContainer container spacing={3}>
        {partners?.map(partner => (
          <Grid item xs={4} key={partner.id}>
            <PartnerCard partner={partner} />
          </Grid>
        ))}
      </CardsContainer>
      <PaginationContainer item xs={12}>
        <Pagination count={pageCount} />
      </PaginationContainer>
    </>
  );
};
