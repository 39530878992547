import { useQuery } from 'react-query';
import {
  getAnalyticsWorkoutsData,
  getAnalyticsWorkoutsTopListTraining,
  getAnalyticsWorkoutsTopListSpace,
  getAnalyticsWorkoutsGender,
  getAnalyticsWorkoutsAge,
  getAnalyticsWorkoutsGraph,
} from 'api';
import {
  IAnalyticsCommonGetParams,
  IAnalyticsWorkoutsCardRes,
  IAnalyticsTopListGetParams,
  IAnalyticsTopListRes,
  IAnalyticsGenderRes,
  IAnalyticsAgeRes,
  IAnalyticsWorkoutsShareGraphRes,
} from 'types';

export const useGetAnalyticsWorkoutsData = (
  params: IAnalyticsCommonGetParams,
) => {
  const { start_date, end_date } = params;
  return useQuery<IAnalyticsWorkoutsCardRes, unknown>(
    ['analyticsWorkouts', 'data', { start_date, end_date }],
    async () => {
      const res = await getAnalyticsWorkoutsData(params);
      return res;
    },
    {
      staleTime: 24 * 60 * 60 * 1000,
      retry: false,
    },
  );
};

export const useGetAnalyticsWorkoutsTopListTraining = (
  params: IAnalyticsTopListGetParams,
) => {
  const { start_date, end_date, limit, order_type, order, page, search } =
    params;
  return useQuery<IAnalyticsTopListRes, unknown>(
    [
      'analyticsWorkouts',
      'topLists',
      'training',
      {
        start_date,
        end_date,
        limit,
        order_type,
        order,
        page,
        search,
      },
    ],
    async () => {
      const res = await getAnalyticsWorkoutsTopListTraining(params);
      return res;
    },
    {
      staleTime: 24 * 60 * 60 * 1000,
      retry: false,
    },
  );
};

export const useGetAnalyticsWorkoutsTopListSpace = (
  params: IAnalyticsTopListGetParams,
) => {
  const { start_date, end_date, limit, order_type, order, page, search } =
    params;
  return useQuery<IAnalyticsTopListRes, unknown>(
    [
      'analyticsWorkouts',
      'topLists',
      'space',
      {
        start_date,
        end_date,
        limit,
        order_type,
        order,
        page,
        search,
      },
    ],
    async () => {
      const res = await getAnalyticsWorkoutsTopListSpace(params);
      return res;
    },
    {
      staleTime: 24 * 60 * 60 * 1000,
      retry: false,
    },
  );
};

export const useGetAnalyticsWorkoutsGender = (
  params: IAnalyticsCommonGetParams,
) => {
  const { start_date, end_date } = params;
  return useQuery<IAnalyticsGenderRes, unknown>(
    ['analyticsWorkouts', 'gender', { start_date, end_date }],
    async () => {
      const res = await getAnalyticsWorkoutsGender(params);
      return res;
    },
    {
      staleTime: 24 * 60 * 60 * 1000,
      retry: false,
    },
  );
};

export const useGetAnalyticsWorkoutsAge = (
  params: IAnalyticsCommonGetParams,
) => {
  const { start_date, end_date } = params;
  return useQuery<IAnalyticsAgeRes, unknown>(
    ['analyticsWorkouts', 'age', { start_date, end_date }],
    async () => {
      const res = await getAnalyticsWorkoutsAge(params);
      return res;
    },
    {
      staleTime: 24 * 60 * 60 * 1000,
      retry: false,
    },
  );
};

export const useGetAnalyticsWorkoutsGraph = (
  params: IAnalyticsCommonGetParams,
) => {
  const { start_date, end_date } = params;
  return useQuery<IAnalyticsWorkoutsShareGraphRes, unknown>(
    ['analyticsWorkouts', 'graph', { start_date, end_date }],
    async () => {
      const res = await getAnalyticsWorkoutsGraph(params);
      return res;
    },
    {
      staleTime: 24 * 60 * 60 * 1000,
      retry: false,
    },
  );
};
