import { useMutation, useQueryClient } from 'react-query';

import { uploadMedia, changeStatus } from 'api';

export const useUploadMedia = () => {
  return useMutation(uploadMedia, {
    onSuccess: () => {},
    onError: () => {},
  });
};

export interface IChangeStatusModel {
  space: ['spaces'];
  group: ['groups'];
  team: ['teams'];
  news: ['news'];
  news_category: ['categories'];
  marathon_space: ['marathons'];
  achievement: ['achievements'];
  faq: ['faqs'];
  showcase_product: ['showcaseOfferDiscounts'];
  showcase_category: ['showcaseCategories'];
  instruction: ['instructions'];
  product_category: ['shopCategories'];
  product: ['shopProducts'];
  product_attribute: ['shopAttributes'];
  training_program: ['workoutsCalendar'];
  notification: ['notifications'];
  banner: ['banners'];
  meAndGto: ['meAndGto'];
  story: ['stories'];
}

const INVALIDATOR: IChangeStatusModel = {
  space: ['spaces'],
  group: ['groups'],
  team: ['teams'],
  news: ['news'],
  news_category: ['categories'],
  marathon_space: ['marathons'],
  achievement: ['achievements'],
  faq: ['faqs'],
  showcase_product: ['showcaseOfferDiscounts'],
  showcase_category: ['showcaseCategories'],
  instruction: ['instructions'],
  product_category: ['shopCategories'],
  product: ['shopProducts'],
  product_attribute: ['shopAttributes'],
  training_program: ['workoutsCalendar'],
  notification: ['notifications'],
  banner: ['banners'],
  meAndGto: ['meAndGto'],
  story: ['stories'],
};

export const useChangeStatus = (model_name: keyof IChangeStatusModel) => {
  const queryClient = useQueryClient();
  return useMutation(changeStatus, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: INVALIDATOR[model_name] });
    },
    onError: () => {},
  });
};
