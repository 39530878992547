import { FC } from 'react';
import { Button, PageHeader, GlobalSearch, FilterSpace } from 'UI';
import { PlusIcon } from 'assets';

interface IChallengesHeaderProps {
  openAddChallengeModal: () => void;
}

export const ChallengesHeader: FC<IChallengesHeaderProps> = ({
  openAddChallengeModal,
}) => {
  return (
    <PageHeader title='Вызовы'>
      {/* <FilterSpace size='small' /> */}
      <GlobalSearch />
      <Button
        variant='contained'
        startIcon={<PlusIcon />}
        onClick={openAddChallengeModal}
      >
        Добавить
      </Button>
    </PageHeader>
  );
};
