import { useQuery, useMutation, useQueryClient } from 'react-query';
import {
  getShowcaseCategories,
  getShowcaseCategory,
  createShowcaseCategory,
  updateShowcaseCategory,
  deleteShowcaseCategory,
  getShowcaseCategoriesList,
} from 'api';
import {
  IShowcaseCategoryRes,
  IShowcaseCategoryReqParams,
  IShowcaseCategory,
  IShowcaseCategoryListRes,
} from 'types';

export const useGetShowcaseCategories = (params: IShowcaseCategoryReqParams) => {
  const { page, search, column, sort_type } = params;
  return useQuery<IShowcaseCategoryRes, unknown>(
    ['showcaseCategories', { page, search, column, sort_type }],
    async () => {
      const res = await getShowcaseCategories(params);
      return res;
    },
    {
      retry: false,
      staleTime: Infinity,
    },
  );
};

export const useGetShowcaseCategoriesList = () => {
  return useQuery<IShowcaseCategoryListRes>(
    ['showcaseCategories', 'list'],
    async () => {
      const res = await getShowcaseCategoriesList();
      return res;
    },
    {
      retry: false,
      staleTime: Infinity,
    },
  );
};

export const useGetShowcaseCategory = (id: string | number) => {
  if (!id || id === '0')
    return useQuery(['showcaseCategories', 'categoryId', id], () => {
      return Promise.resolve(null);
    });
  return useQuery<IShowcaseCategory, unknown>(
    ['showcaseCategories', 'categoryId', id],
    async () => {
      const res = await getShowcaseCategory(id);
      return res;
    },
    {
      retry: false,
      staleTime: Infinity,
    },
  );
};

export const useCreateShowcaseCategory = () => {
  const queryClient = useQueryClient();
  return useMutation(createShowcaseCategory, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['showcaseCategories'] });
    },
    onError: () => {},
  });
};

export const useUpdateShowcaseCategory = () => {
  const queryClient = useQueryClient();
  return useMutation(updateShowcaseCategory, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['showcaseCategories'] });
    },
    onError: () => {},
  });
};

export const useDeleteShowcaseCategory = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteShowcaseCategory, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['showcaseCategories'] });
    },
    onError: () => {},
  });
};
