import { FC } from 'react';
import { Box, CircularProgress, CircularProgressProps } from '@mui/material';
import { styled } from '@mui/system';

const StyledContainer = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

interface ILoaderProps extends CircularProgressProps {
  size?: number;
}

export const MiniLoader: FC<ILoaderProps> = ({
  size = 12,
  color = 'primary',
}) => {
  return (
    <StyledContainer>
      <CircularProgress color={color} size={size} />
    </StyledContainer>
  );
};
