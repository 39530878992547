import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { COLORS } from 'utils';

export const StyledContainer = styled(Box)(({ bgImg }: { bgImg: string }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  gap: '0.9375rem',
  padding: '1.25rem',
  border: '1px solid transparent',
  borderRadius: '1rem',
  background: `url(${bgImg}) center/cover no-repeat`,
  height: '12rem',
  '& .buttons': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    visibility: 'hidden',
    '& .icons': {
      display: 'flex',
      alignItems: 'center',
      gap: '0.625rem',
      '& svg': {
        cursor: 'pointer',
      },
    },
    '& .switch': {
      marginTop: '-0.5rem',
      marginLeft: '-0.625rem',
    },
  },
  '&:hover': {
    '& .buttons': {
      visibility: 'visible',
    },
  },
}));

export const StyledHeader = styled(Box)({
  display: 'flex',
  gap: '0.9375rem',
  color: COLORS.WHITE,
  alignItems: 'center',
  '& .imageBox': {
    minWidth: '3.75rem',
    minHeight: '3.75rem',
    maxWidth: '3.75rem',
    maxHeight: '3.75rem',
    borderRadius: '0.5rem',
    overflow: 'hidden',
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
  },
  '& .title': {
    fontFamily: '"SF Pro Display", sans-serif',
    fontSize: '1.25rem',
    fontWeight: 600,
    lineHeight: '1.625rem',
  },
  '& .info': {
    fontFamily: '"SF Pro Display", sans-serif',
    fontSize: '0.75rem',
    opacity: 0.5,
    lineHeight: '1rem',
  },
});
