import { FC, useContext, useState, MouseEvent, useRef, useEffect } from 'react';
import { styled } from '@mui/system';
import {
  Box,
  TextField,
  Popover,
  IconButton,
  InputAdornment,
} from '@mui/material';
import { CalendarIcon } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import { COLORS, FilterContext, compareTwoDates, notify } from 'utils';
import { DateInput, Button } from 'UI';

const StyledContainer = styled(Box)({
  width: '100%',
  '& .outerInput': {
    backgroundColor: COLORS.WHITE,
    '& .MuiInputBase-root': {
      height: '2.75rem',
      width: '17rem',
      color: COLORS.BLACK,
      '& input': {
        WebkitTextFillColor: COLORS.BLACK,
      },
    },
    '& .MuiSvgIcon-root': {
      color: COLORS.BLUE,
    },
  },
});

const StyledPopoverContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  padding: '1rem',
  gap: '1rem',
});

const DateInputsContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
});

const ApplyButtonContainer = styled(Box)({
  '& button': {
    width: '100%',
  },
});

dayjs.extend(utc);
dayjs.extend(timezone);

export const CustomDateFilter: FC = () => {
  const { startDate, endDate, updateDays } = useContext(FilterContext);
  const inputButtonRef = useRef<HTMLButtonElement>(null);

  const changeDates = () => {
    if (!startDateTemp || !endDateTemp) {
      notify('Выберите даты', 'error');
      return;
    }
    if (compareTwoDates(startDateTemp, endDateTemp)) {
      notify('Дата начала не может быть больше даты окончания', 'error');
      return;
    }
    updateDays(startDateTemp, endDateTemp);
    setAnchorEl(null);
    setShowPopover(false);
  };

  useEffect(() => {
    setStartDateTemp(startDate);
    setEndDateTemp(endDate);
  }, [startDate, endDate]);

  const [startDateTemp, setStartDateTemp] = useState<string>();
  const [endDateTemp, setEndDateTemp] = useState<string>();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [showPopover, setShowPopover] = useState<boolean>(false);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setShowPopover(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setShowPopover(false);
  };

  return (
    <StyledContainer>
      <TextField
        className='outerInput'
        fullWidth
        variant='outlined'
        value={`${startDate} - ${endDate}`}
        disabled
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton ref={inputButtonRef} onClick={handleClick}>
                <CalendarIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Popover
        id={showPopover ? 'custom-input' : undefined}
        open={showPopover}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <StyledPopoverContainer>
          <DateInputsContainer>
            <DateInput
              stringValue={startDate}
              onChange={newValue => setStartDateTemp(newValue)}
            />
            <Box sx={{ mx: 1 }}> - </Box>
            <DateInput
              stringValue={endDate}
              onChange={newValue => setEndDateTemp(newValue)}
            />
          </DateInputsContainer>
          <ApplyButtonContainer>
            <Button color='customLightBlue' onClick={changeDates}>
              Применить
            </Button>
          </ApplyButtonContainer>
        </StyledPopoverContainer>
      </Popover>
    </StyledContainer>
  );
};
