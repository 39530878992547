import { FC } from 'react';

import { PageHeader, GlobalSearch, Button } from 'UI';
import { PlusIcon } from 'assets';

interface IUsersHeader {
  openAddUserModal: () => void;
}

export const UsersHeader: FC<IUsersHeader> = ({ openAddUserModal }) => {
  // const downloadUsers = () => {};
  // const downloadUsersExcel = () => {};
  return (
    <PageHeader title='Пользователи'>
      <GlobalSearch size='medium' />
      <Button
        variant='contained'
        onClick={openAddUserModal}
        startIcon={<PlusIcon />}
      >
        Добавить
      </Button>
      {/* <Button
        variant='contained'
        color='customDarkerGray'
        onClick={downloadUsersExcel}
      >
        <ExcelIcon />
      </Button>
      <Button
        variant='contained'
        color='customDarkerGray'
        onClick={downloadUsers}
      >
        <DownloadIcon />
      </Button> */}
    </PageHeader>
  );
};
