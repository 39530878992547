import { FC, useState, useEffect } from 'react';
import { Box, TextField } from '@mui/material';
import {
  Drawer,
  DrawerHeader,
  DrawerFormBody,
  ModalTitle,
  Button,
  StyledFlexBox,
  ImageInput,
  FormItem,
  SearchSelectInput,
} from 'UI';
import { CloseIcon } from 'assets';
import { IAchievementReq } from 'types';
import {
  useGetAchievement,
  useCreateAchievement,
  useUpdateAchievement,
} from 'hooks';
import { MEDIA_TYPES } from 'utils';
import {
  TRANSLATIONS,
  DEFAULT_VALUES,
  ACHIEVEMENT_TYPE_OPTIONS,
  ACHIEVEMENT_PERIOD_OPTIONS,
  ACHIEVEMENT_VALUE_LABELS,
} from './utils';

interface IAddEditAchievementDrawerProps {
  open: boolean;
  onClose: () => void;
  purpose?: 'add' | 'edit';
  id?: string;
}

export const AddEditAchievementDrawer: FC<IAddEditAchievementDrawerProps> = ({
  open,
  onClose,
  purpose = 'add',
  id = '',
}) => {
  const { data: achievementInfoData } = useGetAchievement(id);

  const [achievementInfo, setAchievementInfo] =
    useState<IAchievementReq>(DEFAULT_VALUES);

  const { mutateAsync: createAchievement } = useCreateAchievement();
  const { mutateAsync: updateAchievement } = useUpdateAchievement();

  useEffect(() => {
    if (purpose === 'edit') {
      setAchievementInfo({
        id: achievementInfoData?.id,
        name: achievementInfoData?.name || '',
        cover: achievementInfoData?.cover || '',
        description: achievementInfoData?.description || '',
        congrats_title: achievementInfoData?.congrats_title || '',
        congrats_text: achievementInfoData?.congrats_text || '',
        is_active: !!achievementInfoData?.is_active,
        needle_type: achievementInfoData?.needle_type || 0,
        needle_value: achievementInfoData?.needle_value || '',
        needle_period: achievementInfoData?.needle_period || 0,
      });
    } else {
      setAchievementInfo(DEFAULT_VALUES);
    }
  }, [purpose, achievementInfoData]);

  const handleFieldChange = <T extends keyof IAchievementReq>(
    fieldName: T,
    newValue: IAchievementReq[T],
  ) => {
    setAchievementInfo(prevAchievementInfo => ({
      ...prevAchievementInfo,
      [fieldName]: newValue,
    }));
  };

  const ignoreChanges = () => {
    setAchievementInfo(DEFAULT_VALUES);
    onClose();
  };
  const saveChanges = () => {
    if (purpose === 'add') {
      createAchievement(achievementInfo)
        .then(() => {
          setAchievementInfo(DEFAULT_VALUES);
          onClose();
        })
        .catch(() => {});
    } else {
      updateAchievement(achievementInfo)
        .then(() => {
          setAchievementInfo(DEFAULT_VALUES);
          onClose();
        })
        .catch(() => {});
    }
  };

  return (
    <Drawer open={open} onClose={ignoreChanges}>
      <DrawerHeader>
        <ModalTitle title={TRANSLATIONS[purpose].title} />
        <CloseIcon onClick={ignoreChanges} />
      </DrawerHeader>
      <DrawerFormBody>
        <StyledFlexBox>
          <ImageInput
            inputId='spaceLogo'
            title='Добавьте медаль'
            descriptionWithoutImage='Загрузите изображение
            (512x512 px, до 1 мб)'
            withDescription
            image={achievementInfo.cover}
            setImage={newImg => handleFieldChange('cover', newImg)}
            style={{ width: '60%' }}
            mediaType={MEDIA_TYPES.ACHIEVEMENT}
            imageFixedWidth={512}
            imageFixedHeight={512}
          />
        </StyledFlexBox>
        <h2>О достижении</h2>
        <FormItem label='Название'>
          <TextField
            variant='standard'
            color='primary'
            placeholder='Введите текст'
            name='name'
            value={achievementInfo.name}
            onChange={e => handleFieldChange('name', e.target.value)}
          />
        </FormItem>
        <FormItem label='Описание'>
          <TextField
            variant='standard'
            color='primary'
            placeholder='Введите описание'
            name='description'
            value={achievementInfo.description}
            onChange={e => handleFieldChange('description', e.target.value)}
          />
        </FormItem>
        <FormItem label='Заголовок при получении достижения'>
          <TextField
            variant='standard'
            color='primary'
            placeholder='Введите короткий текст'
            name='congrats_title'
            value={achievementInfo.congrats_title}
            onChange={e => handleFieldChange('congrats_title', e.target.value)}
          />
        </FormItem>
        <FormItem label='Текст при получении достижения'>
          <TextField
            variant='standard'
            color='primary'
            placeholder='Введите текст'
            name='congrats_text'
            value={achievementInfo.congrats_text}
            onChange={e => handleFieldChange('congrats_text', e.target.value)}
          />
        </FormItem>
        <h2>Параметры</h2>
        <FormItem label='Тип достижения'>
          <SearchSelectInput
            options={ACHIEVEMENT_TYPE_OPTIONS}
            value={achievementInfo.needle_type}
            onChange={newValue =>
              handleFieldChange('needle_type', newValue as number)
            }
            variant='standard'
            placeholder='Не выбрано'
          />
        </FormItem>
        {achievementInfo.needle_type ? (
          <FormItem
            label={
              ACHIEVEMENT_VALUE_LABELS[achievementInfo.needle_type] ||
              'Значение'
            }
          >
            <TextField
              variant='standard'
              color='primary'
              placeholder='0'
              name='needle_value'
              value={achievementInfo.needle_value}
              onChange={e => handleFieldChange('needle_value', e.target.value)}
            />
          </FormItem>
        ) : null}
        <FormItem label='Период выполнения'>
          <SearchSelectInput
            options={ACHIEVEMENT_PERIOD_OPTIONS}
            value={achievementInfo.needle_period}
            onChange={newValue =>
              handleFieldChange('needle_period', newValue as number)
            }
            variant='standard'
            placeholder='Не выбран'
          />
        </FormItem>
      </DrawerFormBody>
      <StyledFlexBox className='buttons fullDivWidth'>
        <Box />
        <Button variant='contained' size='large' onClick={saveChanges}>
          Сохранить
        </Button>
      </StyledFlexBox>
    </Drawer>
  );
};
