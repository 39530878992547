import { styled } from '@mui/system';
import { CardsContainer, PaginationContainer } from 'UI';

export const StyledPaginationContainer = styled(PaginationContainer)({
  position: 'absolute',
  bottom: 0,
  width: 'calc(100% - 68px)',
});

export const StyledCardsContainer = styled(CardsContainer)({
  height: 'auto',
  maxHeight: 'calc(100vh - 12.5rem)',
});
