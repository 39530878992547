import { FC } from 'react';
import { styled } from '@mui/system';
import { Paper, Box } from '@mui/material';
import { PieChart } from '@mui/x-charts';
import { COLORS } from 'utils';

const StyledContainer = styled(Paper)({
  width: '100%',
  borderRadius: '0.75rem',
  '& .header': {
    padding: '0.75rem 1.25rem',
    fontSize: '1.25rem',
    fontWeight: 500,
    fontFamily: '"SF Pro Display", sans-serif',
    lineHeight: '1.5rem',
    borderBottom: `1px solid rgba(0, 0, 0, 0.1)`,
  },
  '& .body': {
    padding: '1rem 1.25rem',
    '& .chartContainer': {
      margin: '0 auto 1rem',
      textAlign: 'center',
      width: '9rem',
      height: '9rem',
    },
    '& .noData': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '1rem',
      fontWeight: 600,
      fontFamily: '"SF Pro Display", sans-serif',
      height: '100%',
      color: COLORS.RED,
      textAlign: 'center',
    },
    '& .footer': {
      display: 'flex',
      flexDirection: 'column',
      gap: '0.5rem',
      '& .line': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flexEnd',
        fontSize: '1rem',
        lineHeight: '1.5rem',
        fontFamily: '"SF Pro Display", sans-serif',
        '& .title': {
          display: 'flex',
          gap: '0.5rem',
          alignItems: 'center',
        },
        '& .value': {
          fontWeight: 600,
        },
      },
    },
  },
});

const StyledDot = styled(Box)(({ color }: { color: string }) => ({
  width: '0.5rem',
  height: '0.5rem',
  borderRadius: '50%',
  backgroundColor: color,
}));

const StyledText = styled('text')({
  fill: 'rgba(0, 0, 0, 1)',
  textAnchor: 'middle',
  dominantBaseline: 'central',
  fontSize: '1.25rem',
  fontWeight: 500,
  lineHeight: '2.5rem',
  fontFamily: '"SF Pro Display", sans-serif',
});

interface IAnalyticsPieChartCardProps {
  title: string;
  sections: Array<IInfoSection>;
  sum: number;
}

interface IInfoSection {
  label: string;
  value: number;
  color: string;
}

const PieCenterLabel = ({ children }: { children: React.ReactNode }) => {
  return (
    <StyledText x={72} y={72}>
      {children}
    </StyledText>
  );
};

export const AnalyticsPieChartCard: FC<IAnalyticsPieChartCardProps> = ({
  title = '',
  sections = [],
  sum,
}) => {
  return (
    <StyledContainer>
      <Box className='header'>{title}</Box>
      <Box className='body'>
        <Box className='chartContainer'>
          {sections.length === 0 ? (
            <Box className='noData'>Нет данных</Box>
          ) : (
            <PieChart
              series={[
                {
                  type: 'pie',
                  data: sections,
                  innerRadius: 60,
                  outerRadius: 72,
                },
              ]}
              margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
              slotProps={{
                legend: { hidden: true },
              }}
            >
              <PieCenterLabel>{sum}</PieCenterLabel>
            </PieChart>
          )}
        </Box>
        <Box className='footer'>
          {sections.map(section => (
            <Box className='line' key={section.label}>
              <Box className='title'>
                <StyledDot color={section.color} />
                <Box>{section.label}</Box>
              </Box>
              <Box className='value'>{section.value}</Box>
            </Box>
          ))}
        </Box>
      </Box>
    </StyledContainer>
  );
};
