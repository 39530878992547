import { FC, useState, useEffect, useMemo } from 'react';
import { TextField } from '@mui/material';
import {
  useCreateCustomWorkout,
  useUpdateCustomWorkout,
  useGetDefaultWorkouts,
  useDeleteCustomWorkout,
  useGetCustomWorkout,
} from 'hooks';
import {
  Drawer,
  ModalTitle,
  Button,
  ImageUpload,
  SearchSelectInput,
  StyledSwitch,
  DrawerHeader,
  FormItem,
  DrawerBody,
  DrawerFormBody,
  StyledFlexBox,
} from 'UI';
import { CloseIcon, StarIcon, DeleteIcon } from 'assets';
import { IWorkoutReq, IWorkoutType } from 'types';
import { COLORS, MEDIA_TYPES } from 'utils';
import { StyledSwitchFormControl } from './styledComponents';

const TRANSLATIONS = {
  add: {
    title: 'Новая тренировка',
  },
  edit: {
    title: 'Редактировать тренировку',
  },
};

const DEFAULT_VALUES: IWorkoutReq = {
  icon: '',
  name: '',
  type: 0,
  is_active: true,
  is_only_indoor: true,
  position: 1,
  give_out_an_award: false,
  reward_type: 1,
  reward_points: 0,
  required_result: 0,
};
interface IAddEditWorkoutDrawerProps {
  open: boolean;
  onClose: () => void;
  purpose?: 'add' | 'edit';
  id?: string | number;
}

const TARGET_TYPE_OPTIONS = [
  { value: 1, label: 'Шаги' },
  { value: 2, label: 'Дистанция' },
  { value: 3, label: 'Калории' },
];

export const AddEditWorkoutDrawer: FC<IAddEditWorkoutDrawerProps> = ({
  open,
  onClose,
  purpose = 'add',
  id = '',
}) => {
  const { data: workoutInfoData } = useGetCustomWorkout(id);
  const [workoutInfo, setWorkoutInfo] = useState<IWorkoutReq>(DEFAULT_VALUES);

  const { mutateAsync: createWorkout } = useCreateCustomWorkout();
  const { mutateAsync: updateWorkout } = useUpdateCustomWorkout();
  const { mutateAsync: deleteWorkout } = useDeleteCustomWorkout();
  const { data: defaultWorkouts }: { data?: IWorkoutType[]; error: unknown } =
    useGetDefaultWorkouts();

  const options = useMemo(() => {
    if (!defaultWorkouts) {
      return [];
    }
    return defaultWorkouts.map(({ id: defWorkId, text }) => ({
      value: defWorkId,
      label: text,
    }));
  }, [defaultWorkouts]);

  useEffect(() => {
    if (purpose === 'edit') {
      setWorkoutInfo({
        id: workoutInfoData?.id || 0,
        icon: workoutInfoData?.icon || '',
        name: workoutInfoData?.name || '',
        is_active: !!workoutInfoData?.is_active,
        is_only_indoor: !!workoutInfoData?.is_only_indoor,
        position: workoutInfoData?.position || 1,
        type: workoutInfoData?.type?.id || 0,
        give_out_an_award: !!workoutInfoData?.give_out_an_award,
        reward_type: workoutInfoData?.reward_type || 1,
        reward_points: workoutInfoData?.reward_points || 0,
        required_result: workoutInfoData?.required_result || 0,
      });
    } else {
      setWorkoutInfo(DEFAULT_VALUES);
    }
  }, [workoutInfoData, purpose]);

  const handleFieldChange = <T extends keyof IWorkoutReq>(
    fieldName: T,
    newValue: IWorkoutReq[T],
  ) => {
    setWorkoutInfo(prevWorkoutInfo => ({
      ...prevWorkoutInfo,
      [fieldName]: newValue,
    }));
  };

  const saveChanges = () => {
    if (purpose === 'add') {
      createWorkout(workoutInfo)
        .then(() => {
          onClose();
        })
        .catch(() => {});
    } else {
      updateWorkout(workoutInfo)
        .then(() => {
          onClose();
        })
        .catch(() => {});
    }
  };

  const ignoreChanges = () => {
    setWorkoutInfo(DEFAULT_VALUES);
    onClose();
  };

  const deleteWorkoutHandler = () => {
    deleteWorkout(workoutInfo.id!)
      .then(() => {
        onClose();
      })
      .catch(() => {});
  };

  return (
    <Drawer open={open} onClose={ignoreChanges}>
      <DrawerHeader>
        <ModalTitle title={TRANSLATIONS[purpose].title} />
        <CloseIcon onClick={ignoreChanges} />
      </DrawerHeader>
      <DrawerBody>
        <ImageUpload
          image={workoutInfo.icon}
          setImage={(newIcon: string) => handleFieldChange('icon', newIcon)}
          inputId='workoutIcon'
          mediaType={MEDIA_TYPES.TRAINING}
        />
        <DrawerFormBody>
          <h2>О тренировке</h2>
          <FormItem label='Название тренировки'>
            <TextField
              variant='standard'
              color='primary'
              placeholder='Введите название'
              name='name'
              value={workoutInfo.name}
              onChange={e => handleFieldChange('name', e.target.value)}
            />
          </FormItem>
          <FormItem label='Позиция'>
            <TextField
              variant='standard'
              color='primary'
              placeholder='Укажите позицию'
              name='position'
              value={workoutInfo.position}
              type='number'
              onChange={e =>
                handleFieldChange(
                  'position',
                  e.target.value as unknown as number,
                )
              }
            />
          </FormItem>
          <FormItem label='Тип тренировки'>
            <SearchSelectInput
              options={options}
              value={workoutInfo.type}
              onChange={newValue =>
                handleFieldChange('type', newValue as number)
              }
              placeholder='Не выбран'
              variant='standard'
            />
          </FormItem>

          <StyledSwitchFormControl
            label='Тренировка в помещении'
            labelPlacement='start'
            sx={{ marginBottom: '1.25rem' }}
            control={
              <StyledSwitch
                checked={workoutInfo.is_only_indoor}
                onChange={() =>
                  handleFieldChange(
                    'is_only_indoor',
                    !workoutInfo.is_only_indoor,
                  )
                }
              />
            }
          />

          <h2>Награда</h2>
          <StyledSwitchFormControl
            label='Начислять за тренировку'
            labelPlacement='start'
            control={
              <StyledSwitch
                checked={workoutInfo.give_out_an_award}
                onChange={() =>
                  handleFieldChange(
                    'give_out_an_award',
                    !workoutInfo.give_out_an_award,
                  )
                }
              />
            }
          />
          {workoutInfo.give_out_an_award && (
            <StyledFlexBox className='fullDivWidth'>
              <FormItem label='Цель'>
                <TextField
                  variant='standard'
                  color='primary'
                  placeholder='10 000'
                  name='required_result'
                  value={workoutInfo.required_result}
                  type='number'
                  onChange={e =>
                    handleFieldChange(
                      'required_result',
                      e.target.value as unknown as number,
                    )
                  }
                />
              </FormItem>
              <FormItem label='&nbsp;'>
                <SearchSelectInput
                  options={TARGET_TYPE_OPTIONS}
                  value={workoutInfo.reward_type}
                  variant='standard'
                  placeholder='Шаги'
                  onChange={newValue =>
                    handleFieldChange('reward_type', newValue as number)
                  }
                />
              </FormItem>

              <FormItem label='Награда'>
                <TextField
                  variant='standard'
                  color='primary'
                  placeholder='0'
                  name='reward_points'
                  value={workoutInfo.reward_points}
                  type='number'
                  InputProps={{
                    startAdornment: (
                      <StarIcon style={{ marginRight: '0.75rem' }} />
                    ),
                  }}
                  onChange={e =>
                    handleFieldChange(
                      'reward_points',
                      e.target.value as unknown as number,
                    )
                  }
                />
              </FormItem>
            </StyledFlexBox>
          )}
        </DrawerFormBody>
        <StyledFlexBox className='buttons'>
          {purpose === 'edit' && (
            <Button
              onClick={deleteWorkoutHandler}
              variant='contained'
              size='large'
              color='customGray'
              startIcon={<DeleteIcon color={COLORS.RED} />}
              sx={{
                color: COLORS.RED,
              }}
            >
              Удалить
            </Button>
          )}
          {purpose === 'add' && (
            <Button
              onClick={ignoreChanges}
              variant='contained'
              size='large'
              color='customGray'
            >
              Отмена
            </Button>
          )}
          <Button
            variant='contained'
            size='large'
            color='primary'
            onClick={saveChanges}
          >
            Сохранить
          </Button>
        </StyledFlexBox>
      </DrawerBody>
    </Drawer>
  );
};
