import { FC } from 'react';
import { styled } from '@mui/system';
import { Box, Paper } from '@mui/material';
import { COLORS } from 'utils';
import { BottomIcon, UpIcon } from 'assets';

const StyledContainer = styled(Paper)({
  backgroundColor: COLORS.WHITE,
  width: '100%',
  padding: '1.25rem',
  display: 'flex',
  flexDirection: 'column',
  gap: '0.25rem',
  fontFamily: '"SF Pro Display", sans-serif',
  // marginTop: '1.25rem',
  '& .icon': {
    width: '3.75rem',
    height: '3.75rem',
    borderRadius: '50%',
    backgroundColor: 'rgba(0, 128, 255, 0.05)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  '& .title': {
    opacity: 0.5,
    fontSize: '0.875rem',
    lineHeight: '1.375rem',
  },
  '& .value': {
    fontSize: '2.125rem',
    lineHeight: '2.5rem',
    fontWeight: 600,
  },
  '& .bottom': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& .change': {
      fontSize: '0.875rem',
      lineHeight: '1.375rem',
      fontWeight: 600,
      display: 'flex',
      alignItems: 'center',
      '&.positive': {
        color: COLORS.GREEN,
      },
      '&.negative': {
        color: COLORS.RED,
      },
    },
  },
});

interface IAnalyticsGenderCardProps {
  icon: FC;
  title: string;
  current_data: number;
  difference: number;
}

export const AnalyticsChallengeShortCard: FC<IAnalyticsGenderCardProps> = ({
  icon: Icon,
  title,
  current_data,
  difference,
}) => {
  const isPositiveChange = difference > 0;
  return (
    <StyledContainer>
      <Box className='icon'>
        <Icon />
      </Box>
      <Box className='value'>{current_data}</Box>
      <Box className='bottom'>
        <Box className='title'>{title}</Box>
        <Box className={`${isPositiveChange ? 'positive' : 'negative'} change`}>
          {isPositiveChange && '+'}
          {difference}
          {isPositiveChange ? <UpIcon color={COLORS.GREEN} /> : <BottomIcon />}
        </Box>
      </Box>
    </StyledContainer>
  );
};
