import { FC } from 'react';
import {
  AnalyticsCardsContainer,
  AnalyticsInnerPageContainer,
} from 'components';

export const ServicesAnalyticsBody: FC = () => {
  return (
    <AnalyticsInnerPageContainer>
      <AnalyticsCardsContainer>1</AnalyticsCardsContainer>
      <AnalyticsCardsContainer>2</AnalyticsCardsContainer>
      <AnalyticsCardsContainer>3</AnalyticsCardsContainer>
    </AnalyticsInnerPageContainer>
  );
};
