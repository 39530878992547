import { FC, useMemo } from 'react';
import { styled } from '@mui/system';
import { Box, TextField, RadioGroup } from '@mui/material';
import {
  FormItem,
  StyledFlexBox,
  ImageInput,
  SearchSelectInput,
  Button,
} from 'UI';
import { COLORS, MEDIA_TYPES } from 'utils';
import {
  IShowcaseOfferDiscountSale,
  IShowcaseOfferDiscountSaleType,
} from 'types';

import { UploadDiscounts } from './OfferAndDiscountDrawer/components';

const StyledContainer = styled(Box)({
  padding: '1.25rem',
  borderRadius: '0.5rem',
  display: 'flex',
  flexDirection: 'column',
  gap: '0.625rem',
  border: `1px solid ${COLORS.BLACK}`,
  marginBottom: '1.25rem',
});

export const StyledRadioGroup = styled(RadioGroup)({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  gap: '1rem',
  alignItems: 'center',
  borderBottom: `1px solid rgba(0,0,0,0.5)`,
  '& label': {
    display: 'flex',
    justifyContent: 'space-between',
    width: 'calc(50% - 0.5rem)',
    margin: 0,
  },
});

interface ISaleCardProps extends IShowcaseOfferDiscountSale {
  handleFieldChange: <T extends keyof IShowcaseOfferDiscountSale>(
    _field: T,
    _value: IShowcaseOfferDiscountSale[T],
  ) => void;
  saveDiscountFile: (_file: File, _discId: string | number) => void;
  deleteDiscount: (_id: IShowcaseOfferDiscountSale['id']) => void;
}

export const SaleCard: FC<ISaleCardProps> = ({
  id,
  title,
  description,
  type,
  value,
  handleFieldChange,
  saveDiscountFile,
  deleteDiscount,
}) => {
  const typeOptions = useMemo(
    () => [
      {
        label: 'QR код',
        value: 'qr',
      },
      {
        label: 'Ссылка',
        value: 'link',
      },
      {
        label: 'Единый промокод',
        value: 'promocode_single',
      },
      {
        label: 'Уникальный промокод',
        value: 'promocode_unique',
      },
    ],
    [],
  );
  return (
    <StyledContainer>
      <FormItem label='Заголовок'>
        <TextField
          variant='standard'
          color='primary'
          placeholder='Введите заголовок'
          name='title'
          value={title}
          onChange={e => handleFieldChange('title', e.target.value)}
        />
      </FormItem>
      <FormItem label='Описание'>
        <TextField
          variant='standard'
          color='primary'
          placeholder='Введите описание'
          name='description'
          value={description}
          onChange={e => handleFieldChange('description', e.target.value)}
        />
      </FormItem>
      <FormItem label='Тип'>
        <SearchSelectInput
          options={typeOptions}
          value={type}
          onChange={newVal =>
            handleFieldChange('type', newVal as IShowcaseOfferDiscountSaleType)
          }
          placeholder='Выбрать тип скидку'
          variant='standard'
        />
      </FormItem>
      {type === 'qr' && (
        <StyledFlexBox>
          <ImageInput
            inputId={`promo_qr_${id}`}
            title='Добавьте QR код'
            descriptionWithoutImage='Загрузите изображение (512x512 px, до 1 мб)'
            withDescription
            image={value || ''}
            setImage={newImg => handleFieldChange('value', newImg)}
            mediaType={MEDIA_TYPES.SHOWCASE_QR}
            imageFixedWidth={512}
            imageFixedHeight={512}
          />
        </StyledFlexBox>
      )}
      {type === 'link' && (
        <FormItem label='Ссылка'>
          <TextField
            variant='standard'
            color='primary'
            placeholder='Введите ссылку'
            name='link'
            value={value}
            onChange={e => handleFieldChange('value', e.target.value)}
          />
        </FormItem>
      )}
      {type === 'promocode_single' && (
        <FormItem label='Промокод'>
          <TextField
            variant='standard'
            color='primary'
            placeholder='Введите промокод'
            name='promocode'
            value={value}
            onChange={e => handleFieldChange('value', e.target.value)}
          />
        </FormItem>
      )}
      {type === 'promocode_unique' && (
        <UploadDiscounts handleFileUpload={saveDiscountFile} id={id} />
      )}
      <Button
        variant='contained'
        color='secondary'
        onClick={() => deleteDiscount(id)}
      >
        Удалить
      </Button>
    </StyledContainer>
  );
};
