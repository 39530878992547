import { FC } from 'react';
import { IIconProps } from './types';

export const ShowcaseIcon: FC<IIconProps> = ({ isActive, activeColor }) => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g>
        <path
          d='M3 9V19C3 19.5304 3.21071 20.0391 3.58579 20.4142C3.96086 20.7893 4.46957 21 5 21H19C19.5304 21 20.0391 20.7893 20.4142 20.4142C20.7893 20.0391 21 19.5304 21 19V9'
          stroke={isActive ? activeColor : '#C4C4C4'}
          strokeWidth='2'
        />
        <path
          d='M20.4842 3H16.4922L16.9922 8C16.9922 8 17.9922 9 19.4922 9C20.2796 9.00104 21.0403 8.71441 21.6312 8.194C21.6936 8.13563 21.7402 8.0623 21.7664 7.98094C21.7927 7.89958 21.7978 7.81287 21.7812 7.729L21.0752 3.5C21.0516 3.36029 20.9793 3.23345 20.8711 3.14194C20.7629 3.05042 20.6259 3.00014 20.4842 3Z'
          stroke={isActive ? activeColor : '#C4C4C4'}
          strokeWidth='2'
        />
        <path
          d='M16.4922 3L16.9922 8C16.9922 8 15.9922 9 14.4922 9C12.9922 9 11.9922 8 11.9922 8V3H16.4922Z'
          stroke={isActive ? activeColor : '#C4C4C4'}
          strokeWidth='2'
        />
        <path
          d='M11.9922 3V8C11.9922 8 10.9922 9 9.49219 9C7.99219 9 6.99219 8 6.99219 8L7.49219 3H11.9922Z'
          stroke={isActive ? activeColor : '#C4C4C4'}
          strokeWidth='2'
        />
        <path
          d='M7.49333 3H3.50233C3.36033 2.99995 3.22291 3.05026 3.11451 3.142C3.00611 3.23373 2.93376 3.36094 2.91033 3.501L2.20533 7.73C2.17633 7.902 2.22533 8.079 2.35533 8.195C2.68333 8.485 3.41633 9.001 4.49333 9.001C5.99333 9.001 6.99333 8.001 6.99333 8.001L7.49333 3Z'
          stroke={isActive ? activeColor : '#C4C4C4'}
          strokeWidth='2'
        />
      </g>
    </svg>
  );
};
