import {
  IPaginatedReqParams,
  ICategoryReq,
  INewsReq,
  INewsReqParams,
  IGetNewsCommentsParams,
  IDeleteNewsCommentReq,
} from 'types';
import { http, methods } from './config';

export const getCategories = (params: IPaginatedReqParams) => {
  const config = {
    method: methods.get,
    url: `/news/category`,
    params,
  };
  return http(config);
};

export const getCategory = (id: string | number) => {
  const config = {
    method: methods.get,
    url: `/news/category/${id}`,
  };
  return http(config);
};

export const createCategory = (data: ICategoryReq) => {
  const config = {
    method: methods.post,
    url: `/news/category`,
    data,
  };
  return http(config);
};

export const updateCategory = (data: ICategoryReq) => {
  const config = {
    method: methods.put,
    url: `/news/category/${data.id}`,
    data,
  };
  return http(config);
};

export const deleteCategory = (id: number | string) => {
  const config = {
    method: methods.delete,
    url: `/news/category/${id}`,
  };
  return http(config);
};

export const getCategoriesList = (params: { space_id: string | number }) => {
  const config = {
    method: methods.get,
    url: `/list/news_category`,
    params,
  };
  return http(config);
};

export const getNews = (params: INewsReqParams) => {
  const config = {
    method: methods.get,
    url: `/news/category/${params.category_id}/item`,
    params,
  };
  return http(config);
};

export const getNewsItem = (params: INewsReqParams) => {
  const config = {
    method: methods.get,
    url: `/news/category/${params.category_id}/item/${params.id}`,
  };
  return http(config);
};

export const createNewsItem = (data: INewsReq) => {
  const config = {
    method: methods.post,
    url: `/news/category/${data.category_id}/item`,
    data,
  };
  return http(config);
};

export const updateNewsItem = (data: INewsReq) => {
  const config = {
    method: methods.put,
    url: `/news/category/${data.category_id}/item/${data.id}`,
    data,
  };
  return http(config);
};

export const deleteNewsItem = (params: INewsReqParams) => {
  const config = {
    method: methods.delete,
    url: `/news/category/${params.category_id}/item/${params.id}`,
  };
  return http(config);
};

export const getNewsComments = (params: IGetNewsCommentsParams) => {
  const config = {
    method: methods.get,
    url: `/news/comment/${params.news_id}`,
    params,
  };
  return http(config);
};

export const deleteNewsComment = (params: IDeleteNewsCommentReq) => {
  const config = {
    method: methods.delete,
    url: `/news/comment/${params.news_id}/${params.comment_id}`,
  };
  return http(config);
};

export const deleteNewsAllComments = (id: string | number) => {
  const config = {
    method: methods.delete,
    url: `/news/comment/${id}`,
  };
  return http(config);
};
