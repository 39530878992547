import {
  IAnalyticsChallengesTopGetParams,
  IAnalyticsCommonGetParams,
} from 'types';
import { http, methods } from '../config';

export const getAnalyticsChallengesData = (
  params: IAnalyticsCommonGetParams,
) => {
  return http({
    method: methods.GET,
    url: '/statistics/marathon/data',
    params,
  });
};

export const getAnalyticsChallengesFinishGraph = (
  params: IAnalyticsCommonGetParams,
) => {
  return http({
    method: methods.GET,
    url: '/statistics/marathon/finish_stats',
    params,
  });
};

export const getAnalyticsChallengesShareGraph = (
  params: IAnalyticsCommonGetParams,
) => {
  return http({
    method: methods.GET,
    url: '/statistics/marathon/shared_stats',
    params,
  });
};

export const getAnalyticsChallengesTopList = (
  params: IAnalyticsChallengesTopGetParams,
) => {
  return http({
    method: methods.GET,
    url: '/statistics/marathon/top_users',
    params,
  });
};

export const getAnalyticsChallengesRuns = (
  params: IAnalyticsCommonGetParams,
) => {
  return http({
    method: methods.GET,
    url: '/statistics/marathon/run_count',
    params,
  });
};

export const getAnalyticsChallengesAccepts = (
  params: IAnalyticsCommonGetParams,
) => {
  return http({
    method: methods.GET,
    url: '/statistics/marathon/accept_count',
    params,
  });
};
