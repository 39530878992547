import { FC } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box } from '@mui/material';
import { Drawer, DrawerHeader, ModalTitle, Button } from 'UI';
import { CloseIcon, RightIcon } from 'assets';
import { useGetGroup } from 'hooks';
import { ROUTES } from 'app';

import { StyledBody, StyledHeader, StyledCard } from './styledComponents';

interface IViewGroupDrawerProps {
  open: boolean;
  onClose: () => void;
  id: string | number;
}

export const ViewGroupDrawer: FC<IViewGroupDrawerProps> = ({
  open,
  onClose,
  id,
}) => {
  if (!id) return null;
  const location = useLocation();
  const navigate = useNavigate();
  const { data: groupInfo } = useGetGroup(id);

  const openEditDrawer = () => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('edit', id.toString());
    searchParams.delete('view');
    navigate(`?${searchParams.toString()}`);
  };

  const goToLink = (link: string) => {
    navigate(`${link}?group_id=${id}`);
  };

  return (
    <Drawer open={open} onClose={onClose} type='gray'>
      <DrawerHeader>
        <ModalTitle title='Просмотр гуппы' />
        <CloseIcon onClick={onClose} />
      </DrawerHeader>
      <StyledHeader>
        <img src={groupInfo?.logo} alt={groupInfo?.name} className='logo' />
        <Box>
          <Box className='name'>{groupInfo?.name}</Box>
          <Box className='description'>{groupInfo?.description}</Box>
        </Box>
      </StyledHeader>
      <StyledBody>
        <Box className='column'>
          <StyledCard>
            <Box className='title'>{groupInfo?.news_count}</Box>
            <div>
              <Box className='value'>Новостей</Box>
              <Button
                variant='text'
                endIcon={<RightIcon />}
                onClick={() => goToLink(ROUTES.news)}
              >
                Перейти
              </Button>
            </div>
          </StyledCard>
        </Box>
        <Box className='column'>
          <StyledCard>
            <Box className='title'>{groupInfo?.users_count}</Box>
            <div>
              <Box className='value'>Участников</Box>
              <Button
                variant='text'
                endIcon={<RightIcon />}
                onClick={() => goToLink(ROUTES.users)}
              >
                Перейти
              </Button>
            </div>
          </StyledCard>
        </Box>

        <Button
          color='customBlack'
          size='large'
          onClick={openEditDrawer}
          className='editButton'
        >
          Редактировать
        </Button>
      </StyledBody>
    </Drawer>
  );
};
