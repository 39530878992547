import { FC } from 'react';
import { styled } from '@mui/system';
import { Box, Paper } from '@mui/material';
import { COLORS } from 'utils';
import { BottomIcon, UpIcon } from 'assets';

const StyledContainer = styled(Paper)({
  backgroundColor: COLORS.WHITE,
  width: '100%',
  borderRadius: '0.75rem',
  '& .header': {
    padding: '1rem 1.25rem',
    borderBottom: `1px solid rgba(0, 0, 0, 0.1)`,
    display: 'flex',
    flexDirection: 'column',
    gap: '0.25rem',
    '& .value': {
      fontSize: '2.125rem',
      lineHeight: '2.5rem',
      fontWeight: 500,
      fontFamily: '"SF Pro Display", sans-serif',
    },
    '& .info': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      '& .title': {
        opacity: 0.5,
        fontSize: '0.875rem',
        lineHeight: '1.375rem',
        fontWeight: 500,
        fontFamily: '"SF Pro Display", sans-serif',
      },
      '& .change': {
        fontSize: '0.875rem',
        lineHeight: '1.375rem',
        fontWeight: 600,
        display: 'flex',
        alignItems: 'center',
        '&.positive': {
          color: COLORS.GREEN,
        },
        '&.negative': {
          color: COLORS.RED,
        },
      },
    },
  },
  '& .footer': {
    padding: '1rem 1.25rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '0.25rem',
    '& .item': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      fontSize: '0.875rem',
      lineHeight: '1.5rem',
      fontFamily: '"SF Pro Display", sans-serif',
      '& .label': {
        fontWeight: 500,
      },
      '& .value': {
        fontWeight: 600,
      },
    },
  },
});

interface IAnalyticsChallengeDetailedCardProps {
  title: string;
  sections: Array<IInfoSection>;
  difference: number;
  sum: number;
}

interface IInfoSection {
  label: string;
  value: number;
  color: string;
}

export const AnalyticsChallengesDetailedCard: FC<
  IAnalyticsChallengeDetailedCardProps
> = ({ title, sections, difference, sum }) => {
  const isPositiveChange = difference > 0;
  return (
    <StyledContainer>
      <Box className='header'>
        <Box className='value'>{sum}</Box>
        <Box className='info'>
          <Box className='title'>{title}</Box>
          <Box
            className={`${isPositiveChange ? 'positive' : 'negative'} change`}
          >
            {isPositiveChange && '+'}
            {difference}
            {isPositiveChange ? (
              <UpIcon color={COLORS.GREEN} />
            ) : (
              <BottomIcon />
            )}
          </Box>
        </Box>
      </Box>
      <Box className='footer'>
        {sections.map(section => (
          <Box key={section.label} className='item'>
            <Box className='label'>{section.label}</Box>
            <Box className='value'>{section.value}</Box>
          </Box>
        ))}
      </Box>
    </StyledContainer>
  );
};
