import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { COLORS } from 'utils';

export const StyledHeader = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  gap: '1rem',
  '& .logo': {
    width: '5rem',
    height: '5rem',
    borderRadius: '50%',
    overflow: 'hidden',
    backgroundColor: COLORS.WHITE,
    objectFit: 'contain',
  },
  '& .name': {
    fontSize: '1.25rem',
    lineHeight: '1.625rem',
    fontWeight: 600,
    fontFamily: '"SF Pro Display", sans-serif',
    textAlign: 'center',
  },
  '& .description': {
    fontSize: '1rem',
    lineHeight: '1.5rem',
    fontWeight: 500,
    fontFamily: '"SF Pro Display", sans-serif',
    textAlign: 'center',
    opacity: 0.5,
  },
});

export const StyledBody = styled(Box)({
  padding: '1.25rem 0',
  display: 'flex',
  flexWrap: 'wrap',
  gap: '1.25rem',
  '& .column': {
    width: 'calc((100% - 1.25rem) / 2)',
    display: 'flex',
    flexDirection: 'column',
    gap: '1.25rem',
  },
  '& .editButton': {
    position: 'absolute',
    bottom: '1.875rem',
    right: '1.875rem',
    width: 'calc(50% - 2.5rem)',
    maxWidth: '17rem',
  },
});

export const StyledCard = styled(Box)({
  width: '100%',
  borderRadius: '0.75rem',
  padding: '1rem 1.25rem',
  backgroundColor: COLORS.WHITE,
  border: `1px solid ${COLORS.WHITE}`,
  display: 'flex',
  flexDirection: 'column',
  gap: '0.25rem',
  '& .title': {
    fontSize: '2.125rem',
    lineHeight: '2.5rem',
    fontWeight: 600,
    fontFamily: '"SF Pro Display", sans-serif',
    opacity: 0.75,
  },
  '& > div': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  '& .value': {
    fontSize: '0.875rem',
    lineHeight: '1.5rem',
    fontWeight: 400,
    fontFamily: '"SF Pro Display", sans-serif',
    opacity: 0.5,
  },
});
