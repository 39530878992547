import { FC, useMemo, useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/system';

import { SearchSelectInput } from 'UI';
import { useGetShowcaseCategoriesList, useSearchParams } from 'hooks';
import { COLORS } from 'utils';

const StyledBox = styled(Box)({
  minWidth: '15rem',
  '& .MuiAutocomplete-root': {
    width: '100%',
    backgroundColor: COLORS.GRAY,
    height: '1.75rem',
    borderRadius: '0.5rem',
    '& .MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
      padding: '0 0.5rem',
    },
  },
  '& .MuiInputBase-root': {
    height: '1.75rem',
    fontSize: '0.875rem',
    borderRadius: '0.5rem',
    '& fieldset': {
      border: `1px solid ${COLORS.WHITE}`,
    },
    '&:hover fieldset': {
      border: `1px solid ${COLORS.WHITE}`,
    },
    '&.Mui-focused fieldset': {
      border: `1px solid ${COLORS.BLUE}`,
    },
  },
});

interface IFilterShowcaseCategoryProps {
  size?: 'small' | 'medium';
  className?: string;
}

export const FilterShowcaseCategory: FC<IFilterShowcaseCategoryProps> = ({
  size = 'small',
  className,
}) => {
  const { setParams } = useSearchParams();
  const [categoryId, setCategoryId] = useState<number>(0);

  const { data: categoriesList } = useGetShowcaseCategoriesList();

  const options = useMemo(() => {
    return [
      {
        label: 'Все категории',
        value: 0,
      },
      ...(categoriesList?.map(category => ({
        label: category.title,
        value: +category.id,
      })) || []),
    ];
  }, [categoriesList]);

  const changeCategoryHandler = (newValue: number) => {
    setCategoryId(newValue);
  };

  useEffect(() => {
    setParams({ category_id: categoryId.toString(), page: 1 });
  }, [categoryId]);


  return (
    <StyledBox className={className}>
      <SearchSelectInput
        options={options}
        onChange={newValue => changeCategoryHandler(newValue as number)}
        value={categoryId}
        size={size}
        placeholder='Все категории'
        variant='outlined'
        disableClearable
      />
    </StyledBox>
  );
};
