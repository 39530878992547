import { FC, useEffect, useState, useMemo, useContext } from 'react';
import { TextField } from '@mui/material';
import { UserContext, UserContextType } from 'app';
import { AddWorkerToSpace, LoadUsersToSpace, DeleteModal } from 'components';
import {
  Drawer,
  ModalTitle,
  StyledSwitch,
  StyledBadge,
  Button,
  ImageInput,
  SearchSelectInput,
  DrawerHeader,
  FormItem,
  DrawerFormBody,
  StyledFlexBox,
} from 'UI';
import { CloseIcon, DeleteIcon, EyeIcon } from 'assets';
import { ISpace, ISpaceReq, IUserSpace, IChangeSpaceSettingsReq } from 'types';
import { COLORS, MEDIA_TYPES } from 'utils';
import {
  useCreateSpace,
  useDeleteSpace,
  useGetCitiesList,
  useGetSpace,
  useUpdateSpace,
  useChangeSpaceSettings,
} from 'hooks';
import { PreviewDrawer } from './PreviewDrawer';
import { SpaceSettings } from '../SpaceSettings';
import { StyledChangedFlexBox } from './styledComponents';

const TRANSLATIONS = {
  add: {
    title: 'Создание пространства',
  },
  edit: {
    title: 'Редактирование пространства',
  },
  preview: {
    title: 'Превью',
  },
};

const DEFAULT_VALUES: ISpaceReq = {
  name: '',
  city_id: 0,
  logo: '',
  cover: '',
  description: '',
  is_active: true,
};

interface IAddEditSpaceDrawerProps {
  open: boolean;
  onClose: () => void;
  purpose?: 'add' | 'edit';
  id?: number | string;
}

export const AddEditSpaceDrawer: FC<IAddEditSpaceDrawerProps> = ({
  open,
  onClose,
  id = '',
  purpose = 'add',
}) => {
  const { updateUser, userData } = useContext(UserContext) as UserContextType;
  const { data: spaceInfoData } = useGetSpace(id);
  const [spaceInfo, setSpaceInfo] = useState<ISpaceReq>(DEFAULT_VALUES);
  const [citySearchValue, setCitySearchValue] = useState<string>('');
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showPreview, setShowPreview] = useState<boolean>(false);
  const [spaceSettings, setSpaceSettings] = useState<IChangeSpaceSettingsReq>({
    showcase_state: false,
    shop_state: false,
    space_id: id,
  });

  const { mutateAsync: createSpace } = useCreateSpace();
  const { mutateAsync: updateSpace } = useUpdateSpace();
  const { mutateAsync: deleteSpace } = useDeleteSpace();
  const { data: citiesList } = useGetCitiesList({ search: citySearchValue });
  const { mutateAsync: changeSpaceSettings } = useChangeSpaceSettings();

  const cityOptions = useMemo(() => {
    if (!citiesList) return [];
    return citiesList.map(city => {
      return {
        label: city.name,
        value: city.id,
      };
    });
  }, [citiesList]);

  useEffect(() => {
    if (purpose === 'edit') {
      setSpaceInfo({
        id: spaceInfoData?.id,
        name: spaceInfoData?.name || '',
        city_id: spaceInfoData?.city?.id || 0,
        logo: spaceInfoData?.logo || '',
        cover: spaceInfoData?.cover || '',
        description: spaceInfoData?.description || '',
        is_active: spaceInfoData?.is_active ?? true,
      });
      setSpaceSettings({
        showcase_state: spaceInfoData?.settings?.showcase_state ?? false,
        shop_state: spaceInfoData?.settings?.shop_state ?? false,
        space_id: id,
      });
      setCitySearchValue(spaceInfoData?.city?.name || '');
    } else {
      setSpaceInfo(DEFAULT_VALUES);
    }
  }, [spaceInfoData, purpose]);

  const handleFieldChange = <T extends keyof ISpaceReq>(
    fieldName: T,
    newValue: ISpaceReq[T],
  ) => {
    setSpaceInfo(prevSpaceInfo => ({
      ...prevSpaceInfo,
      [fieldName]: newValue,
    }));
  };

  const handleChangeSettings = <T extends keyof IChangeSpaceSettingsReq>(
    fieldName: T,
    newValue: IChangeSpaceSettingsReq[T],
  ) => {
    setSpaceSettings(prevSettings => ({
      ...prevSettings,
      [fieldName]: newValue,
    }));
  };

  const getUserSpaceFromCreatedSpace = (space: ISpace): IUserSpace => {
    return {
      id: space.id,
      name: space.name,
      logo: space.logo,
      cover: space.cover,
      description: space.description,
      is_active: space.is_active,
      role: {
        id: 1,
        text: 'Администратор пространства',
      },
    };
  };

  const findUpdatedSpaceFromUserDataAndUpdate = (spcId: string | number) => {
    const updatedSpace = userData?.spaces.find(space => space.id === spcId);
    if (!updatedSpace) return;
    updateUser({
      ...userData!,
      spaces: userData!.spaces.map(space => {
        if (space.id === spcId) {
          return {
            ...space,
            name: spaceInfo.name,
            logo: spaceInfo.logo,
            cover: spaceInfo.cover,
            description: spaceInfo.description,
            is_active: spaceInfo.is_active,
          };
        }
        return space;
      }),
    });
  };

  const saveChanges = () => {
    if (purpose === 'add') {
      createSpace(spaceInfo)
        .then((res: any) => {
          const newAddedSpace = getUserSpaceFromCreatedSpace(res.item);
          updateUser({
            ...userData!,
            spaces: [...userData!.spaces, newAddedSpace],
          });
          onClose();
        })
        .catch(() => {});
    } else {
      if (
        spaceInfoData?.settings.shop_state !== spaceSettings.shop_state ||
        spaceInfoData?.settings.showcase_state !== spaceSettings.showcase_state
      ) {
        changeSpaceSettings(spaceSettings)
          .then(() => {})
          .catch(() => {});
      }
      updateSpace(spaceInfo)
        .then(() => {
          findUpdatedSpaceFromUserDataAndUpdate(id);
          onClose();
        })
        .catch(() => {});
    }
  };

  const openDeleteModal = () => {
    setShowDeleteModal(true);
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const handleDeleteSpace = () => {
    deleteSpace(spaceInfo.id!)
      .then(() => {
        closeDeleteModal();
        onClose();
      })
      .catch(() => {});
  };

  const ignoreChanges = () => {
    setSpaceInfo(DEFAULT_VALUES);
    onClose();
  };

  const openPreviewDrawer = () => {
    setShowPreview(true);
  };

  const closePreviewDrawer = () => {
    setShowPreview(false);
  };

  return (
    <Drawer open={open} onClose={ignoreChanges}>
      <DeleteModal
        open={showDeleteModal}
        onConfirm={handleDeleteSpace}
        onClose={closeDeleteModal}
        title='Удалить пространство'
        description='Вы действительно хотите удалить пространство'
      />
      {!showPreview && (
        <>
          <DrawerHeader>
            <StyledFlexBox>
              <ModalTitle title={TRANSLATIONS[purpose].title} />
              <StyledBadge
                is_active={spaceInfo.is_active}
                style={{ marginBottom: 0 }}
                variant='secondary'
                activeText='Активная'
                inActiveText='Не активная'
              />
            </StyledFlexBox>
            <StyledFlexBox>
              <StyledSwitch
                checked={spaceInfo.is_active}
                onChange={() =>
                  handleFieldChange('is_active', !spaceInfo.is_active)
                }
                className='switch'
              />
              <CloseIcon onClick={ignoreChanges} />
            </StyledFlexBox>
          </DrawerHeader>

          <DrawerFormBody
            style={{ height: purpose === 'edit' ? 'calc(100vh - 13rem)' : '' }}
          >
            <StyledFlexBox>
              <ImageInput
                inputId='spaceLogo'
                title='Добавьте логотип'
                descriptionWithoutImage='Загрузите изображение (высота 512px, до 1 мб)'
                withDescription
                image={spaceInfo.logo}
                setImage={newImg => handleFieldChange('logo', newImg)}
                mediaType={MEDIA_TYPES.SPACE_LOGO}
                imageFixedWidth={-1}
                imageFixedHeight={512}
              />
              <ImageInput
                inputId='spaceCover'
                title='Добавьте обложку'
                descriptionWithoutImage='Загрузите изображение (1080x1080 px, до 1 мб)'
                withDescription
                image={spaceInfo.cover}
                setImage={newImg => handleFieldChange('cover', newImg)}
                mediaType={MEDIA_TYPES.SPACE_COVER}
                imageFixedWidth={1080}
                imageFixedHeight={1080}
              />
            </StyledFlexBox>
            <h2>О пространстве</h2>
            <FormItem label='Название'>
              <TextField
                variant='standard'
                color='primary'
                placeholder='Введите название'
                name='name'
                value={spaceInfo.name}
                onChange={e => handleFieldChange('name', e.target.value)}
              />
            </FormItem>
            <FormItem label='Описание'>
              <TextField
                variant='standard'
                color='primary'
                placeholder='Введите описание'
                name='description'
                value={spaceInfo.description}
                onChange={e => handleFieldChange('description', e.target.value)}
              />
            </FormItem>
            <FormItem label='Город'>
              <SearchSelectInput
                options={cityOptions}
                value={spaceInfo.city_id}
                onChange={newVal =>
                  handleFieldChange('city_id', newVal as number)
                }
                inputValue={citySearchValue}
                onInputChange={newVal => setCitySearchValue(newVal)}
                placeholder='Выбрать город'
                variant='standard'
              />
            </FormItem>
            {purpose === 'edit' && (
              <SpaceSettings
                showcase_state={spaceSettings.showcase_state}
                shop_state={spaceSettings.shop_state}
                handleFieldChange={handleChangeSettings}
                space_id={id}
              />
            )}
            {purpose === 'edit' && <LoadUsersToSpace spaceId={id.toString()} />}
            {purpose === 'edit' && <AddWorkerToSpace spaceId={id} />}
          </DrawerFormBody>
          <StyledChangedFlexBox className='buttons'>
            <StyledFlexBox>
              <Button
                startIcon={<EyeIcon />}
                onClick={openPreviewDrawer}
                color='customGray'
                size='large'
              >
                Превью
              </Button>
            </StyledFlexBox>
            <StyledFlexBox style={{ width: '100%' }}>
              {purpose === 'edit' && (
                <Button
                  onClick={openDeleteModal}
                  variant='contained'
                  size='large'
                  color='customGray'
                  startIcon={<DeleteIcon color={COLORS.RED} />}
                  sx={{
                    color: COLORS.RED,
                  }}
                >
                  Удалить
                </Button>
              )}
              {purpose === 'add' && (
                <Button
                  onClick={ignoreChanges}
                  variant='contained'
                  size='large'
                  color='customGray'
                >
                  Отмена
                </Button>
              )}
              <Button
                variant='contained'
                size='large'
                color='primary'
                onClick={saveChanges}
              >
                Сохранить
              </Button>
            </StyledFlexBox>
          </StyledChangedFlexBox>
        </>
      )}
      {showPreview && (
        <PreviewDrawer
          onClose={closePreviewDrawer}
          title={TRANSLATIONS.preview.title}
          logo={spaceInfo.logo}
          cover={spaceInfo.cover}
          name={spaceInfo.name || 'Название пространства'}
        />
      )}
    </Drawer>
  );
};
