import { FC, useMemo, useRef, useContext } from 'react';
import { styled } from '@mui/system';
import { Box } from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useUploadMedia } from 'hooks';
import { UserContext, UserContextType } from 'app';
import { MediaType } from 'types';
import { MEDIA_TYPES, notify } from 'utils';

const StyledContainer = styled(Box)({
  '& .ql-container': {
    minHeight: '7.5rem',
  },
});

type ToolbarOptions =
  | 'bold'
  | 'italic'
  | 'underline'
  | 'strike'
  | 'link'
  | 'image';

interface IHtmlEditorProps {
  value: string;
  onChange: (_data: string) => void;
  disabledToolbarOptions?: ToolbarOptions[];
  imageSize?: number;
  mediaType?: MediaType;
}
const DEFAULT_TOOLBAR_OPTIONS: ToolbarOptions[] = [
  'bold',
  'italic',
  'underline',
  'strike',
  'link',
  'image',
];

export const HtmlEditor: FC<IHtmlEditorProps> = ({
  value,
  onChange,
  disabledToolbarOptions,
  imageSize = 1 * 1024 * 1024,
  mediaType = MEDIA_TYPES.FAQ,
}) => {
  const { space_id } = useContext(UserContext) as UserContextType;
  const editorRef = useRef<ReactQuill>(null);
  const { mutateAsync: uploadMedia } = useUploadMedia();

  const saveToServer = async (file: any): Promise<string> => {
    const formData = new FormData();
    formData.append('image', file, file.name);
    formData.append('type', mediaType);
    formData.append('space_id', (space_id || 1).toString());
    const res = await uploadMedia({ data: formData });
    return res;
  };

  const editorChange = async (newValue: string) => {
    const imgTags = editorRef.current?.editor?.root?.querySelectorAll('img');
    if (imgTags && imgTags.length > 0) {
      imgTags.forEach(async imgTag => {
        const src = imgTag.getAttribute('src');
        if (src?.startsWith('data:image')) {
          const file = await fetch(src).then(res => res.blob());
          if (file.size > imageSize) {
            notify(
              'Размер файла превышает максимальный предел в 1МБ.',
              'error',
            );
            imgTag.remove(); // Remove the image from the editor
            return;
          }
          try {
            const imageUrl = await saveToServer(file);
            imgTag.setAttribute('src', imageUrl);
          } catch (_error) {
            imgTag.remove(); // Remove the image from the editor in case of error
          }
        }
      });
    }
    onChange(newValue);
  };

  const toolbarOptions = useMemo(() => {
    return DEFAULT_TOOLBAR_OPTIONS.filter(
      option => !disabledToolbarOptions?.includes(option),
    );
  }, [disabledToolbarOptions]);

  return (
    <StyledContainer>
      <ReactQuill
        ref={editorRef}
        theme='snow'
        value={value}
        onChange={editorChange}
        modules={{
          toolbar: [
            ...toolbarOptions,
            { list: 'ordered' },
            { list: 'bullet' },
            'clean',
          ],
        }}
      />
    </StyledContainer>
  );
};
