import { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { styled } from '@mui/system';
import { ButtonGroup } from '@mui/material';
import { COLORS } from 'utils';
import { Button } from 'UI';

const StyledButtonGroup = styled(ButtonGroup)({
  padding: '0.25rem',
  borderRadius: '0.5rem',
  width: '100%',
  minWidth: '30rem',
  backgroundColor: 'rgba(0, 0, 0, 0.05)',
  marginBottom: '1.25rem',
  '& button': {
    width: '100%',
    backgroundColor: 'transparent ',
    border: 'none !important',
    borderRadius: '0.25rem',
    '&.active': {
      border: '1px solid transparent',
      backgroundColor: COLORS.WHITE,
      borderRadius: '0.25rem !important',
    },
  },
});

interface IMenuItem {
  title: string;
  path: string;
}

interface IShowcaseInnerNavbarProps {
  menuItems: IMenuItem[];
}

export const ShowcasesInnerNavbar: FC<IShowcaseInnerNavbarProps> = ({
  menuItems,
}) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const onMenuItemClick = (path: string) => {
    if (pathname === path) return;
    navigate(path);
  };

  return (
    <StyledButtonGroup>
      {menuItems.map(({ title, path }) => (
        <Button
          color='customDarkerGray'
          className={pathname === path ? 'active' : ''}
          onClick={_e => onMenuItemClick(path)}
          key={title}
        >
          {title}
        </Button>
      ))}
    </StyledButtonGroup>
  );
};
