import * as Pages from 'pages';
import { ROLES } from 'utils';
import { IRoutes } from '../types';

export const Workouts: IRoutes = {
  workouts: {
    path: '/workouts',
    title: 'workouts',
    element: <Pages.Workouts />,
    roles: [ROLES.ADMINSTRATOR],
  },
};
