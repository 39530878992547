import { CSSProperties, FC } from 'react';
import { Box, RadioGroup } from '@mui/material';
import { styled } from '@mui/system';

const StyledFormSection = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
  width: '100%',
  position: 'relative',
  height: 'calc(100vh - 11rem)',
  overflowY: 'auto',
  overflowX: 'hidden',
  '& h2': {
    fontSize: '1.125rem',
    lineHeight: '1.625rem',
    fontWeight: 600,
    fontFamily: '"SF Pro Display", sans-serif',
    margin: '0 0 -0.5rem 0',
  },
  '& .MuiTextField-root': {
    width: '100%',
  },
});

export const StyledFlexBox = styled(Box)({
  display: 'flex',
  gap: '1rem',
  alignItems: 'center',
  '&.fullDivWidth': {
    '& > div': {
      width: '100%',
    },
  },
  '& > svg': {
    cursor: 'pointer',
  },
  '& .switch': {
    marginTop: '-0.5rem',
  },
  '&.buttons': {
    position: 'absolute',
    width: 'calc(100% - 3.75rem)',
    bottom: '1.875rem',
  },
  '& > button': {
    width: '100%',
  },
  '& .plusIcon': {
    width: '1.5rem',
    height: '1.5rem',
    cursor: 'pointer',
    borderRadius: '0.25rem',
    '&:hover': {
      backgroundColor: 'rgba(0, 128, 255, 0.1)',
    },
  },
});

export const StyledRadioGroup = styled(RadioGroup)({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  gap: '1rem',
  alignItems: 'center',
  borderBottom: `1px solid rgba(0,0,0,0.5)`,
  '& label': {
    display: 'flex',
    justifyContent: 'space-between',
    width: 'calc(50% - 0.5rem)',
    margin: 0,
  },
});

interface IDrawerFormBodyProps {
  children: React.ReactNode;
  style?: CSSProperties;
}

export const DrawerFormBody: FC<IDrawerFormBodyProps> = ({
  children,
  style,
}) => {
  return <StyledFormSection style={style}>{children}</StyledFormSection>;
};
