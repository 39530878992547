import { FC } from 'react';
import { Button, PageHeader, GlobalSearch } from 'UI';
import { PlusIcon } from 'assets';

interface IInstructionsHeaderProps {
  openAddInstructionDrawer: () => void;
}

export const InstructionsHeader: FC<IInstructionsHeaderProps> = ({
  openAddInstructionDrawer,
}) => {
  return (
    <PageHeader title='Как выполнять нормативы'>
      <GlobalSearch size='medium' />
      <Button
        variant='contained'
        onClick={openAddInstructionDrawer}
        startIcon={<PlusIcon />}
      >
        Создать
      </Button>
    </PageHeader>
  );
};
