import { styled } from '@mui/system';
import { Box } from '@mui/material';
import { COLORS } from 'utils';

export const GroupCardWrapper = styled(Box)({
  borderRadius: '0.5rem',
  backgroundColor: COLORS.WHITE,
  padding: '1.875rem',
  width: '100%',
  height: '100%',
  maxHeight: '20rem',
  display: 'flex',
  flexDirection: 'column',
  '& .groupMetaInfo': {
    width: '100%',
    marginBottom: '1rem',
    display: 'flex',
    gap: '0.625rem',
    justifyContent: 'space-between',
    '& .logoBox': {
      width: '5rem',
      height: '5rem',
      borderRadius: '50%',
      overflow: 'hidden',
      cursor: 'pointer',
      border: `1px solid rgba(0,0,0,0.1)`,
      '& img': {
        width: '100%',
        height: '100%',
        objectFit: 'contain',
      },
    },
    '& .groupInfo': {
      width: 'calc(100% - 5rem)',
      '& h2': {
        fontSize: '1.25rem',
        lineHeight: '1.75rem',
        fontWeight: 600,
        fontFamily: '"SF Pro Display", sans-serif',
        margin: '0 0 0.25rem',
        lineBreak: 'anywhere',
        cursor: 'pointer',
      },
      '& p': {
        opacity: 0.5,
        fontSize: '0.75rem',
        lineHeight: '1.125rem',
        fontFamily: '"SF Pro Display", sans-serif',
      },
    },
  },
  '& .editButton': {
    maxWidth: '11rem',
    height: '2.75rem',
  },
});

export const StyledHr = styled(Box)({
  width: '100%',
  height: '1px',
  backgroundColor: 'rgba(0,0,0,0.1)',
  marginBottom: '1rem',
  marginTop: 'auto',
});

export const StyledGroupInfo = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '1rem',
  fontFamily: '"SF Pro Display", sans-serif',
  marginBottom: '1rem',
  '& .spaceInfoItem': {
    width: '50%',
  },
  '& .label': {
    fontSize: '0.625rem',
    lineHeight: '1rem',
    fontWeight: 400,
    color: COLORS.BLACK,
    opacity: 0.5,
  },
  '& .value': {
    fontSize: '1rem',
    lineHeight: '1.5rem',
    fontWeight: 600,
    color: COLORS.BLACK,
  },
});
