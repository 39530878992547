import { FC, useState, useEffect } from 'react';
import { styled } from '@mui/system';
import dayjs, { Dayjs } from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { StyledFlexBox, FormItem } from 'UI';
import { COLORS } from 'utils';
import classes from 'UI/DateInput/DateInput.module.scss';

interface IDateTimeInputProps {
  dateLabel: string;
  timeLabel: string;
  defaultValue?: string | null;
  onChange: (_newValue: string) => void;
}

const StyledDatePicker = styled(DatePicker)({
  '& .MuiInputBase-root': {
    fontWeight: 600,
    fontSize: '0.875rem',
    lineHeight: '1.375rem',
    fontFamily: '"SF Pro Display", sans-serif',
    padding: '0.5rem 0',
    backgroundColor: COLORS.GRAY,
    flexDirection: 'row-reverse',
  },
  '& fieldset': {
    border: 'none',
  },
  '& input': {
    padding: '0 0 0 1rem',
  },
  '& button': {
    padding: 0,
  },
  '& svg': {
    color: COLORS.BLUE,
  },
});

const StyledTimePicker = styled(TimePicker)({
  '& .MuiInputBase-root': {
    fontWeight: 600,
    fontSize: '0.875rem',
    lineHeight: '1.375rem',
    fontFamily: '"SF Pro Display", sans-serif',
    padding: '0.5rem 0',
    backgroundColor: COLORS.GRAY,
    flexDirection: 'row-reverse',
  },
  '& fieldset': {
    border: 'none',
  },
  '& input': {
    padding: '0 0 0 1rem',
  },
  '& button': {
    padding: 0,
  },
  '& svg': {
    color: COLORS.BLUE,
  },
});

export const DateTimeInput: FC<IDateTimeInputProps> = ({
  dateLabel,
  timeLabel,
  defaultValue,
  onChange,
}) => {
  const [date, setDate] = useState<Dayjs | null>(null);
  const [time, setTime] = useState<Dayjs | null>(null);

  useEffect(() => {
    if (defaultValue) {
      setDate(dayjs(defaultValue));
      setTime(dayjs(defaultValue));
    }
  }, [defaultValue]);

  const changeDate = (val: Dayjs | null) => {
    setDate(val);
    let newValue = '';
    if (time) {
      newValue = [
        dayjs(val).format('YYYY-MM-DD'),
        dayjs(time).format('HH:mm:ss'),
      ].join(' ');
    } else {
      newValue = [dayjs(val).format('YYYY-MM-DD'), '12:00:00'].join(' ');
    }

    onChange(newValue);
  };

  const changeTime = (val: Dayjs | null) => {
    setTime(val);

    const newValue = [
      dayjs(date).format('YYYY-MM-DD'),
      dayjs(val).format('HH:mm:ss'),
    ].join(' ');
    onChange(newValue);
  };

  return (
    <StyledFlexBox className='fullDivWidth'>
      <FormItem label={dateLabel}>
        <StyledDatePicker
          value={date}
          format='DD MMMM YYYY'
          onChange={val => changeDate(val as Dayjs | null)}
          slotProps={{
            actionBar: {
              actions: ['cancel', 'accept'],
            },
            toolbar: {
              toolbarFormat: 'DD MMMM YYYY',
              toolbarPlaceholder: 'Календарь',
              hidden: false,
              className: classes.toolbar,
            },
            textField: {
              placeholder: 'Выберите дату',
            },
          }}
          localeText={{
            cancelButtonLabel: 'Закрыть',
            okButtonLabel: 'Сохранить',
          }}
          timezone='Europe/Moscow'
        />
      </FormItem>
      <FormItem label={timeLabel}>
        <StyledTimePicker
          value={time}
          onChange={val => changeTime(val as Dayjs | null)}
          format='HH:mm'
          views={['hours', 'minutes']}
          ampm={false}
          slotProps={{
            textField: {
              placeholder: 'Выберите времю',
            },
          }}
          disabled={!date}
        />
      </FormItem>
    </StyledFlexBox>
  );
};
