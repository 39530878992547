import {
  Box,
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { styled } from '@mui/system';

export const StyledBox = styled(Box)({
  width: '100%',
  overflow: 'auto',
});

export const StyledPaper = styled(Paper)({
  width: '100%',
  marginBottom: 2,
});

export const StyledTableContainer = styled(TableContainer)({
  maxHeight: 'calc(100vh - 12rem)',
  minHeight: 'calc(100vh - 12rem)',
});

export const StyledTable = styled(Table)({
  minWidth: '1200px',
});

export const StyledTableHead = styled(TableHead)({
  '& .minimizedWidth': {
    minWidth: '10rem',
  },
});

export const StyledTableRow = styled(TableRow)({
  '& span': {
    opacity: 0.5,
    fontSize: '0.875rem',
    fontWeight: 400,
    lineHeight: '1.375rem',
    fontFamily: '"SF Pro Display", sans-serif',
  },
  '& .user': {
    cursor: 'pointer',
  },
});
