import { FC, useEffect, useContext } from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';

import { AnalyticsHeader, AnalyticsInnerNavbar, Layout } from 'components';
import { ROUTES, UserContext, UserContextType } from 'app';

export const Analytics: FC = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { role_id } = useContext(UserContext) as UserContextType;

  useEffect(() => {
    if (pathname === ROUTES.analytics.get) {
      if (role_id === 2) {
        navigate(ROUTES.analytics.news);
        return;
      }
      navigate(ROUTES.analytics.users);
    }
  }, [pathname, role_id]);

  return (
    <Layout withoutPB>
      <AnalyticsHeader />
      {role_id !== 2 && <AnalyticsInnerNavbar />}
      <Outlet />
    </Layout>
  );
};
