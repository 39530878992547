import { IAppUserGetParams, IAppUserReq } from 'types';
import { http, methods } from './config';

export const getAppUsers = (params: IAppUserGetParams) => {
  const config = {
    method: methods.get,
    url: `/user/app`,
    params,
  };
  return http(config);
};

export const getAppUser = (userId: number | string) => {
  const config = {
    method: methods.get,
    url: `/user/app/${userId}`,
  };
  return http(config);
};

export const createAppUser = (data: IAppUserReq) => {
  const config = {
    method: methods.post,
    url: `/user/app`,
    data,
  };
  return http(config);
};

export const updateAppUser = (data: IAppUserReq) => {
  const config = {
    method: methods.put,
    url: `/user/app/${data.id}`,
    data,
  };
  return http(config);
};

export const deleteAppUser = (userId: number) => {
  const config = {
    method: methods.delete,
    url: `/user/app/${userId}`,
  };
  return http(config);
};
