import * as Pages from 'pages';
import { ROLES } from 'utils';
import { IRoutes } from '../types';

export const Workers: IRoutes = {
  workers: {
    path: '/workers',
    title: 'workers',
    element: <Pages.Workers />,
    roles: [ROLES.ADMINSTRATOR],
  },
};
