import {
  IQuestionAnswerReq,
  IQuestionAnswerGetParams,
  IInstructionReq,
  IInstructionGetParams,
  IMeAndGtoReq,
  IMeAndGtoGetParams,
} from 'types';
import { http, methods } from './config';

export const getQAs = (params: IQuestionAnswerGetParams) => {
  const config = {
    method: methods.get,
    url: `/faq`,
    params,
  };
  return http(config);
};

export const getQA = (qaId: string | number) => {
  const config = {
    method: methods.get,
    url: `/faq/${qaId}`,
  };
  return http(config);
};

export const createQA = (data: IQuestionAnswerReq) => {
  const config = {
    method: methods.post,
    url: `/faq`,
    data,
  };
  return http(config);
};

export const updateQA = (data: IQuestionAnswerReq) => {
  const config = {
    method: methods.put,
    url: `/faq/${data.id}`,
    data,
  };
  return http(config);
};

export const deleteQA = (qaId: number | string) => {
  const config = {
    method: methods.delete,
    url: `/faq/${qaId}`,
  };
  return http(config);
};

export const getInstructions = (params: IInstructionGetParams) => {
  const config = {
    method: methods.get,
    url: `/instruction`,
    params,
  };
  return http(config);
};

export const getInstruction = (instructionId: string | number) => {
  const config = {
    method: methods.get,
    url: `/instruction/${instructionId}`,
  };
  return http(config);
};

export const createInstruction = (data: IInstructionReq) => {
  const config = {
    method: methods.post,
    url: `/instruction`,
    data,
  };
  return http(config);
};

export const updateInstruction = (data: IInstructionReq) => {
  const config = {
    method: methods.put,
    url: `/instruction/${data.id}`,
    data,
  };
  return http(config);
};

export const deleteInstruction = (instructionId: number | string) => {
  const config = {
    method: methods.delete,
    url: `/instruction/${instructionId}`,
  };
  return http(config);
};

export const getMeAndGtos = (params: IMeAndGtoGetParams) => {
  const config = {
    method: methods.get,
    url: `/preparation`,
    params,
  };
  return http(config);
};

export const getMeAndGto = (meAndGtoId: string | number) => {
  const config = {
    method: methods.get,
    url: `/preparation/${meAndGtoId}`,
  };
  return http(config);
};

export const createMeAndGto = (data: IMeAndGtoReq) => {
  const config = {
    method: methods.post,
    url: `/preparation`,
    data,
  };
  return http(config);
};

export const updateMeAndGto = (data: IMeAndGtoReq) => {
  const config = {
    method: methods.put,
    url: `/preparation/${data.id}`,
    data,
  };
  return http(config);
};

export const deleteMeAndGto = (meAndGtoId: number | string) => {
  const config = {
    method: methods.delete,
    url: `/preparation/${meAndGtoId}`,
  };
  return http(config);
};
