import { styled } from '@mui/system';
import { Box } from '@mui/material';
import { COLORS } from 'utils';

export const StyledContainer = styled(Box)({
  position: 'relative',
  height: 'calc(100vh - 11.5rem)',
  overflowY: 'auto',
  border: '1px solid rgba(0,0,0,0.1)',
  borderRadius: '0.5rem',
  backgroundColor: COLORS.WHITE,
  '& .addButton': {
    position: 'absolute',
    bottom: '0.625rem',
    right: '0.625rem',
  },
});

export const CategoriesList = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  maxHeight: 'calc(100% - 7rem)',
  overflowY: 'auto',
});

export const CategoryListItem = styled(Box)({
  padding: '0.75rem 1rem',
  fontSize: '0.875rem',
  lineHeight: '1.5rem',
  fontFamily: '"SF Pro Display", sans-serif',
  color: COLORS.BLACK,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  '& .icons': {
    display: 'flex',
    alignItems: 'center',
    gap: '0.625rem',
    visibility: 'hidden',
    '& svg': {
      cursor: 'pointer',
    },
  },
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    '& .icons': {
      visibility: 'visible',
    },
  },
});

export const EditCategoryContainer = styled(Box)({
  display: 'flex',
  gap: '1rem',
  backgroundColor: 'rgba(0, 128, 255, 0.05)',
  padding: '0.75rem 1rem',
  alignItems: 'self-start',
  '& .inputs': {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.625rem',
    width: '100%',
    '& .MuiTextField-root': {
      width: '100%',
    },
  },
  '& .buttons': {
    display: 'flex',
    gap: '0.375rem',
    alignItems: 'center',
    width: 'max-content',
    '& .switch': {
      marginTop: '-0.5rem',
    },
    '& svg': {
      cursor: 'pointer',
    },
  },
});
