import { FC, useState, useMemo, useContext, MouseEvent } from 'react';
import { TableBody, TableCell } from '@mui/material';

import { EditIcon, DeleteIcon, CopyIcon } from 'assets';
import { Pagination, StyledSwitch } from 'UI';
import {
  useDeleteShowcaseOfferDiscount,
  useGetShowcaseOfferDiscounts,
  useSearchParams,
  useChangeStatus,
  useDuplicateShowcaseOfferDiscount,
} from 'hooks';
import {
  IShowcaseOfferDiscountShort,
  OfferAndDiscountsSortParams,
  TableRowsOrder,
} from 'types';
import { DeleteModal } from 'components';
import { UserContextType, UserContext } from 'app';

import { notify } from 'utils';
import {
  StyledBox,
  StyledPaper,
  StyledTableContainer,
  StyledTable,
  StyledTableRow,
  StyledProductInfo,
  StyledAvatar,
  StyledProductName,
  StyledProductInfoAtModal,
} from './styledComponents';
import { OfferAndDiscountTableHead } from './OfferAndDiscountTableHead';

interface ITableProps {
  type: number;
}

export const OfferAndDiscountTable: FC<ITableProps> = ({ type = 0 }) => {
  const { space_id } = useContext(UserContext) as UserContextType;
  const [order, setOrder] = useState<TableRowsOrder>('desc');
  const [orderBy, setOrderBy] =
    useState<OfferAndDiscountsSortParams>('created_at');
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [selectedProduct, setSelectedProduct] = useState<
    IShowcaseOfferDiscountShort | undefined
  >(undefined);
  const { getParam, setParams, searchParams } = useSearchParams();

  const params = useMemo(() => {
    return {
      page: getParam('page') || 1,
      search: getParam('search'),
      category_id: getParam('category_id') || 0,
      type,
      column: orderBy,
      sort_type: order,
    };
  }, [searchParams, order, orderBy]);

  const {
    data: { data: offerAndDiscountsList, last_page: pageCount } = {
      data: [],
      last_page: 1,
    },
  } = useGetShowcaseOfferDiscounts(params);
  const { mutateAsync: deleteProduct } = useDeleteShowcaseOfferDiscount();
  const { mutateAsync: duplicateProduct } = useDuplicateShowcaseOfferDiscount();
  const { mutateAsync: changeStatus } = useChangeStatus('showcase_product');

  const updateRow = (row: IShowcaseOfferDiscountShort) => {
    if (!row.id) return;
    changeStatus({
      space_id: space_id!,
      model_name: 'showcase_product',
      model_id: row.id,
      state: !row.is_active,
    })
      .then(() => {})
      .catch(() => {});
  };

  const deleteButtonHandler = (row: IShowcaseOfferDiscountShort) => {
    setSelectedProduct(row);
    setOpenDeleteModal(true);
  };

  const duplicateButtonHandler = (row: IShowcaseOfferDiscountShort) => {
    duplicateProduct({
      id: row.id,
      category_id: row.category_id,
    })
      .then(() => {
        notify('Скидка успешно дублирована', 'success');
      })
      .catch(() => {});
  };

  const deleteRow = () => {
    if (!selectedProduct?.category_id || !selectedProduct.id) return;
    deleteProduct({
      id: selectedProduct.id,
      category_id: selectedProduct.category_id,
    })
      .then(() => {})
      .catch(() => {});
    setOpenDeleteModal(false);
  };

  const cancelDeleteRow = () => {
    setOpenDeleteModal(false);
    setSelectedProduct(undefined);
  };

  const handleRequestSort = (
    _event: MouseEvent<unknown>,
    property: OfferAndDiscountsSortParams,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const openEditDrawer = (
    id: string | number,
    category_id: string | number,
  ) => {
    setParams({ edit: id.toString(), productCategory: category_id.toString() });
  };

  return (
    <StyledBox>
      <DeleteModal
        open={openDeleteModal}
        title='Удалить скидку'
        description='Вы действительно хотите удалить скидку?'
        onConfirm={deleteRow}
        onClose={cancelDeleteRow}
      >
        <StyledProductInfoAtModal>
          <StyledAvatar>
            <img
              src={selectedProduct?.cover_big as string | undefined}
              alt={selectedProduct?.title}
            />
          </StyledAvatar>
          <StyledProductName>{selectedProduct?.title}</StyledProductName>
        </StyledProductInfoAtModal>
      </DeleteModal>
      <StyledPaper>
        <StyledTableContainer>
          <StyledTable aria-labelledby='newsTable' size='medium' stickyHeader>
            <OfferAndDiscountTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {offerAndDiscountsList.map(row => {
                return (
                  <StyledTableRow
                    hover
                    key={row.id}
                    sx={{
                      backgroundColor: !row.is_active ? '#fff6f5' : '#ffffff',
                    }}
                  >
                    <TableCell
                      component='th'
                      id={row.id.toString()}
                      scope='row'
                      className='nameCell'
                    >
                      <StyledProductInfo
                        onClick={() => openEditDrawer(row.id, row.category_id)}
                      >
                        <StyledAvatar>
                          <img
                            src={row.cover_big as string | undefined}
                            alt={row.title}
                          />
                        </StyledAvatar>
                        <StyledProductName>{row.title}</StyledProductName>
                      </StyledProductInfo>
                    </TableCell>
                    <TableCell align='center'>
                      <span>{row.category.title}</span>
                    </TableCell>
                    {/* <TableCell align='center'>
                      {row.show_in_slider ? (
                        <span className='success'>
                          да &#40;{row.position_in_slider}&#41;
                        </span>
                      ) : (
                        <span className='failure'>нет</span>
                      )}
                    </TableCell> */}
                    <TableCell align='center'>
                      <span>{row.position_in_list}</span>
                    </TableCell>
                    <TableCell align='center'>
                      <span>{row.created_at}</span>
                    </TableCell>
                    <TableCell>
                      <div className='editDeleteIcons'>
                        <StyledSwitch
                          checked={row.is_active}
                          variant='customGray'
                          onChange={() => updateRow(row)}
                          style={{ opacity: 1 }}
                          className='switch'
                        />
                        <CopyIcon onClick={() => duplicateButtonHandler(row)} />
                        <EditIcon
                          onClick={() =>
                            openEditDrawer(row.id, row.category_id)
                          }
                        />
                        <DeleteIcon onClick={() => deleteButtonHandler(row)} />
                      </div>
                    </TableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </StyledTable>
        </StyledTableContainer>
        <Pagination count={pageCount} />
      </StyledPaper>
    </StyledBox>
  );
};
