import { FC, MouseEvent } from 'react';

interface IPaletteIcon {
  className?: string;
  style?: React.CSSProperties;
  onClick: (_e: MouseEvent<SVGElement>) => void;
}

export const PaletteIcon: FC<IPaletteIcon> = ({
  className,
  onClick,
  style,
}) => {
  return (
    <svg
      width='25'
      height='24'
      viewBox='0 0 25 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      onClick={e => onClick(e)}
      className={className}
      style={style}
    >
      <path
        d='M12.5006 5.9999V6.03313M6.53362 12.0002H6.50039M16.7433 7.75745L16.7199 7.78095M8.28157 16.2192L8.25807 16.2427M8.28157 7.78057L8.25807 7.75707M12.5004 21.5999C7.19846 21.5999 2.90039 17.3018 2.90039 11.9999C2.90039 6.69797 7.19846 2.3999 12.5004 2.3999C17.8023 2.3999 22.1004 6.69797 22.1004 11.9999C22.1004 13.614 20.609 14.6879 18.995 14.6879H18.3684C18.0448 14.6879 17.7257 14.7632 17.4364 14.9079C16.4069 15.4226 15.9897 16.6744 16.5044 17.7039C16.6491 17.9932 16.7244 18.3123 16.7244 18.6359V18.825C16.7244 19.9256 16.1068 20.9678 15.0454 21.2589C14.2349 21.4812 13.3815 21.5999 12.5004 21.5999Z'
        stroke='#007BFF'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
