import { FC, useMemo, useContext } from 'react';
import { Grid } from '@mui/material';
import {
  AnalyticsAgeCard,
  AnalyticsCard,
  AnalyticsCardsContainer,
  AnalyticsGenderCard,
  AnalyticsListCard,
  AnalyticsInnerPageContainer,
  AnalyticsChallengeShortCard,
  AnalyticsNewsCard,
} from 'components';
import { AnalyticsUsersTopFilterParams } from 'types';
import {
  UserIcon,
  PhoneIcon,
  IPhoneIcon,
  AndroidIcon,
  WinnerCupIcon,
} from 'assets';
import { ROUTES } from 'app';
import {
  useGetAnalyticsUsersAge,
  useGetAnalyticsUsersData,
  useGetAnalyticsUsersGender,
  useGetAnalyticsUsersTopLists,
  useGetAnalyticsUsersNews,
  useGetAnalyticsUsersChallenges,
} from 'hooks';
import { FilterContext, DEFAULT_CARD, DEFAULT_GENDER_CARD } from 'utils';

export const UsersAnalyticsBody: FC = () => {
  const { startDate, endDate } = useContext(FilterContext);

  const paramsToGetTopCards = useMemo(() => {
    return {
      start_date: startDate,
      end_date: endDate,
    };
  }, [startDate, endDate]);

  const paramsToGetTopListSteps = useMemo(() => {
    return {
      start_date: startDate,
      end_date: endDate,
      filter: 'steps' as AnalyticsUsersTopFilterParams,
    };
  }, [startDate, endDate]);

  const paramsToGetTopListKm = useMemo(() => {
    return {
      start_date: startDate,
      end_date: endDate,
      filter: 'km' as AnalyticsUsersTopFilterParams,
    };
  }, [startDate, endDate]);

  const paramsToGetTopListKcal = useMemo(() => {
    return {
      start_date: startDate,
      end_date: endDate,
      filter: 'kcal' as AnalyticsUsersTopFilterParams,
    };
  }, [startDate, endDate]);

  const {
    data: {
      users,
      devices: {
        all: all_devices,
        ios_count: ios_devices,
        android_count: android_devices,
      },
    } = {
      users: {
        total: 0,
        today: 0,
        yesterday: 0,
        percentage: 0,
      },
      devices: {
        all: DEFAULT_CARD,
        ios_count: DEFAULT_CARD,
        android_count: DEFAULT_CARD,
      },
    },
  } = useGetAnalyticsUsersData(paramsToGetTopCards);

  const {
    data: { male, female } = {
      male: DEFAULT_GENDER_CARD,
      female: DEFAULT_GENDER_CARD,
    },
  } = useGetAnalyticsUsersGender(paramsToGetTopCards);

  const {
    data: { news, likes, views, shares } = {
      news: DEFAULT_CARD,
      likes: DEFAULT_CARD,
      views: DEFAULT_CARD,
      shares: DEFAULT_CARD,
    },
  } = useGetAnalyticsUsersNews(paramsToGetTopCards);

  const {
    data: { current_active, yesterday_active } = {
      current_active: 0,
      yesterday_active: 0,
      percentage: 0,
    },
  } = useGetAnalyticsUsersChallenges(paramsToGetTopCards);

  const { data: { data: topListSteps } = { data: [] } } =
    useGetAnalyticsUsersTopLists(paramsToGetTopListSteps);
  const { data: { data: topListKm } = { data: [] } } =
    useGetAnalyticsUsersTopLists(paramsToGetTopListKm);
  const { data: { data: topListKcal } = { data: [] } } =
    useGetAnalyticsUsersTopLists(paramsToGetTopListKcal);

  const { data: ageCardItems = [] } =
    useGetAnalyticsUsersAge(paramsToGetTopCards);

  return (
    <AnalyticsInnerPageContainer>
      <AnalyticsCardsContainer>
        <Grid xs={6} lg={4} xl={3} item>
          <AnalyticsCard
            icon={UserIcon}
            title='Всего пользователей'
            current_data={users.total}
            difference={users.today}
            percent={users.percentage}
          />
        </Grid>
        <Grid xs={6} lg={4} xl={3} item>
          <AnalyticsCard
            icon={PhoneIcon}
            title='Всего устройств'
            {...all_devices}
          />
        </Grid>
        <Grid xs={6} lg={4} xl={3} item>
          <AnalyticsCard
            icon={IPhoneIcon}
            title='iOS устройств'
            {...ios_devices}
          />
        </Grid>
        <Grid xs={6} lg={4} xl={3} item>
          <AnalyticsCard
            icon={AndroidIcon}
            title='Android устройств'
            {...android_devices}
          />
        </Grid>
      </AnalyticsCardsContainer>
      <AnalyticsCardsContainer>
        <Grid xs={6} xl={4} item>
          <AnalyticsListCard
            title='Топ по шагам'
            link={`${ROUTES.analytics.full}?sub=users&tab=steps`}
            listItems={topListSteps}
          />
        </Grid>
        <Grid xs={6} xl={4} item>
          <AnalyticsListCard
            title='Топ по калориям'
            link={`${ROUTES.analytics.full}?sub=users&tab=kcal`}
            listItems={topListKcal}
          />
        </Grid>
        <Grid xs={6} xl={4} item>
          <AnalyticsListCard
            title='Топ по расстоянию'
            link={`${ROUTES.analytics.full}?sub=users&tab=km`}
            listItems={topListKm}
          />
        </Grid>
      </AnalyticsCardsContainer>
      <AnalyticsCardsContainer>
        <Grid xs={4} item className='twoMiniCardsContainer'>
          <AnalyticsGenderCard male={male} female={female} />
          <AnalyticsChallengeShortCard
            icon={WinnerCupIcon}
            title='Всего вызовы'
            current_data={current_active}
            difference={current_active - yesterday_active}
          />
        </Grid>
        <Grid xs={4} item>
          <AnalyticsAgeCard listItems={ageCardItems} />
        </Grid>
        <Grid xs={4} item>
          <AnalyticsNewsCard
            news={news}
            likes={likes}
            views={views}
            shares={shares}
          />
        </Grid>
      </AnalyticsCardsContainer>
    </AnalyticsInnerPageContainer>
  );
};
