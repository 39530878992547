import { FC, MouseEvent } from 'react';
import { TableHead, TableRow, TableCell, TableSortLabel } from '@mui/material';
import {
  IMeAndGto,
  IHeadCell,
  MeAndGtoSortParams,
  ITableHeadPropsWithSort,
} from 'types';
import { COLORS, MeAndGtoSortColumnValues } from 'utils';

const headCells: readonly IHeadCell<IMeAndGto>[] = [
  {
    id: 'title',
    label: 'Название',
    sortable: true,
  },
  {
    id: 'actions',
    label: '',
    sortable: false,
  },
];

export const MeAndGtoTableHead: FC<
  ITableHeadPropsWithSort<MeAndGtoSortParams>
> = ({ onRequestSort, order, orderBy }) => {
  const isValidSortParam = (param: string): param is MeAndGtoSortParams => {
    return MeAndGtoSortColumnValues.includes(param as MeAndGtoSortParams);
  };

  const createSortHandler =
    (property: keyof IMeAndGto | 'actions') => (event: MouseEvent<unknown>) => {
      if (isValidSortParam(property)) {
        onRequestSort(event, property);
      }
    };
  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => {
          if (!headCell.sortable)
            return (
              <TableCell key={headCell.id} align='center' padding='normal'>
                {headCell.label}
              </TableCell>
            );
          return (
            <TableCell
              key={headCell.id}
              padding='normal'
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
                sx={{
                  '&.Mui-active': {
                    '& .MuiTableSortLabel-icon': {
                      color: orderBy === headCell.id ? COLORS.RED : undefined,
                    },
                  },
                  '& .MuiTableSortLabel-icon': {
                    opacity: 0.3,
                  },
                }}
              >
                {headCell.label}
              </TableSortLabel>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};
