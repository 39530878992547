import { FC } from 'react';
import { Button, PageHeader } from 'UI';
import { useSearchParams } from 'hooks';
import { PlusIcon } from 'assets';

export const BannersHeader: FC = () => {
  const { setParam } = useSearchParams();

  return (
    <PageHeader title='Баннеры'>
      <Button
        variant='contained'
        startIcon={<PlusIcon />}
        onClick={() => setParam('edit', '0')}
      >
        Создать
      </Button>
    </PageHeader>
  );
};
