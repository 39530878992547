import {
  Box,
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { styled } from '@mui/system';

export const StyledBox = styled(Box)({
  width: '100%',
  overflow: 'auto',
});

export const StyledPaper = styled(Paper)({
  width: '100%',
  marginBottom: 2,
});

export const StyledTableContainer = styled(TableContainer)({
  maxHeight: 'calc(100vh - 17rem)',
  minHeight: 'calc(100vh - 17rem)',
  '& .MuiTableHead-root': {
    '& .MuiTableCell-root': {
      '&:nth-child(2)': {
        textAlign: 'left',
      },
    },
  },
});

export const StyledTable = styled(Table)({
  minWidth: '800px',
});

export const StyledTableHead = styled(TableHead)({
  '& .minimizedWidth': {
    minWidth: '10rem',
  },
});

export const StyledTableRow = styled(TableRow)({
  '& .MuiTableCell-root': {
    '&:first-child': {
      width: '10%',
    },
  },
  '& span': {
    opacity: 0.5,
    fontSize: '0.875rem',
    fontWeight: 400,
    lineHeight: '1.375rem',
    fontFamily: '"SF Pro Display", sans-serif',
  },
  '& .editDeleteIcons': {
    visibility: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    gap: '1.5rem',
    '& .switch': {
      marginTop: '-0.5rem',
    },
    '& svg': {
      cursor: 'pointer',
    },
  },
  '&:hover': {
    '& .editDeleteIcons': {
      visibility: 'visible',
    },
  },
});
