import { FC } from 'react';
import { Drawer } from 'UI';

interface IParametersDrawerProps {
  open: boolean;
  onClose: () => void;
}

export const ParametersDrawer: FC<IParametersDrawerProps> = ({
  open,
  onClose,
}) => {
  return <Drawer open={open} onClose={onClose}></Drawer>;
};
