import { FC, MouseEvent } from 'react';
import { TableHead, TableRow, TableCell, TableSortLabel } from '@mui/material';
import { AnalyticsTopListOrderParams, TableRowsOrder } from 'types';
import { COLORS } from 'utils';
import { useFullAnalytics } from 'hooks';

interface IFullAnalyticsTableHeadProps {
  onRequestSort: (
    _event: MouseEvent<unknown>,
    _property: AnalyticsTopListOrderParams,
  ) => void;
  order: TableRowsOrder;
  orderBy: string;
}

export const FullAnalyticsTableHead: FC<IFullAnalyticsTableHeadProps> = ({
  onRequestSort,
  order,
  orderBy,
}) => {
  const { columns } = useFullAnalytics();
  const createSortHandler =
    (property: AnalyticsTopListOrderParams | 'actions') =>
    (event: MouseEvent<unknown>) => {
      if (property === 'actions') return;
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {columns.map(headCell => {
          if (!headCell.sortable)
            return (
              <TableCell key={headCell.id} align='center' padding='normal'>
                {headCell.label}
              </TableCell>
            );
          return (
            <TableCell
              key={headCell.id}
              align='center'
              padding='normal'
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(
                  headCell.id as AnalyticsTopListOrderParams,
                )}
                sx={{
                  '&.Mui-active': {
                    '& .MuiTableSortLabel-icon': {
                      color: orderBy === headCell.id ? COLORS.RED : undefined,
                    },
                  },
                  '& .MuiTableSortLabel-icon': {
                    opacity: 0.3,
                  },
                }}
              >
                {headCell.label}
              </TableSortLabel>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};
