import { FC, useState, useEffect } from 'react';
import { styled } from '@mui/system';
import { TextField, FormControlLabel } from '@mui/material';
import {
  useCreateShowcaseCategory,
  useUpdateShowcaseCategory,
  useDeleteShowcaseCategory,
  useGetShowcaseCategory,
} from 'hooks';
import {
  Drawer,
  ModalTitle,
  Button,
  StyledSwitch,
  DrawerHeader,
  FormItem,
  DrawerBody,
  DrawerFormBody,
  StyledFlexBox,
} from 'UI';
import { CloseIcon, DeleteIcon } from 'assets';
import { IShowcaseCategoryReq } from 'types';
import { COLORS } from 'utils';

const StyledSwitchFormControl = styled(FormControlLabel)({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  borderBottom: `1px solid ${COLORS.BLACK}`,
  margin: 0,
  paddingBottom: '0.375rem',
  '& .MuiFormControlLabel-label': {
    marginTop: '0.375rem',
    fontSize: '1rem',
    lineHeight: '1.5rem',
  },
});

const TRANSLATIONS = {
  add: {
    title: 'Новая категория',
  },
  edit: {
    title: 'Редактировать категорию',
  },
};

const DEFAULT_VALUES: IShowcaseCategoryReq = {
  title: '',
  position: undefined,
  is_active: true,
};
interface IAddEditShowcaseCategoriesDrawerProps {
  open: boolean;
  onClose: () => void;
  purpose?: 'add' | 'edit';
  id?: string | number;
}

export const AddEditShowcaseCategoriesDrawer: FC<
  IAddEditShowcaseCategoriesDrawerProps
> = ({ open, onClose, purpose = 'add', id = '' }) => {
  const { data: showcaseCategoryInfoData } = useGetShowcaseCategory(id);
  const [showcaseCategoryInfo, setShowcaseCategoryInfo] =
    useState<IShowcaseCategoryReq>(DEFAULT_VALUES);

  const { mutateAsync: createShowcaseCategory } = useCreateShowcaseCategory();
  const { mutateAsync: updateShowcaseCategory } = useUpdateShowcaseCategory();
  const { mutateAsync: deleteShowcaseCategory } = useDeleteShowcaseCategory();

  useEffect(() => {
    if (purpose === 'edit') {
      setShowcaseCategoryInfo({
        id: showcaseCategoryInfoData?.id || 0,
        title: showcaseCategoryInfoData?.title || '',
        position: showcaseCategoryInfoData?.position || undefined,
        is_active: !!showcaseCategoryInfoData?.is_active,
      });
    } else {
      setShowcaseCategoryInfo(DEFAULT_VALUES);
    }
  }, [showcaseCategoryInfoData, purpose]);

  const handleFieldChange = <T extends keyof IShowcaseCategoryReq>(
    fieldName: T,
    newValue: IShowcaseCategoryReq[T],
  ) => {
    setShowcaseCategoryInfo(prevInfo => ({
      ...prevInfo,
      [fieldName]: newValue,
    }));
  };

  const saveChanges = () => {
    if (purpose === 'add') {
      createShowcaseCategory(showcaseCategoryInfo)
        .then(() => {
          onClose();
        })
        .catch(() => {});
    } else {
      updateShowcaseCategory(showcaseCategoryInfo)
        .then(() => {
          onClose();
        })
        .catch(() => {});
    }
  };

  const ignoreChanges = () => {
    setShowcaseCategoryInfo(DEFAULT_VALUES);
    onClose();
  };

  const deleteShowcaseCategoryHandler = () => {
    deleteShowcaseCategory(showcaseCategoryInfo.id!)
      .then(() => {
        onClose();
      })
      .catch(() => {});
  };

  return (
    <Drawer open={open} onClose={ignoreChanges}>
      <DrawerHeader>
        <ModalTitle title={TRANSLATIONS[purpose].title} />
        <CloseIcon onClick={ignoreChanges} />
      </DrawerHeader>
      <DrawerBody>
        <DrawerFormBody>
          {/* <h2></h2> */}
          <FormItem label='Название категория'>
            <TextField
              variant='standard'
              color='primary'
              placeholder='Введите название'
              name='title'
              value={showcaseCategoryInfo.title}
              onChange={e => handleFieldChange('title', e.target.value)}
            />
          </FormItem>
          <FormItem label='Позиция категории'>
            <TextField
              variant='standard'
              color='primary'
              placeholder='Введите позицию'
              name='position'
              value={showcaseCategoryInfo.position}
              onChange={e =>
                handleFieldChange(
                  'position',
                  e.target.value as unknown as number,
                )
              }
            />
          </FormItem>
          <StyledSwitchFormControl
            label='Активность'
            labelPlacement='start'
            sx={{ marginBottom: '1.25rem' }}
            control={
              <StyledSwitch
                checked={showcaseCategoryInfo.is_active}
                onChange={() =>
                  handleFieldChange(
                    'is_active',
                    !showcaseCategoryInfo.is_active,
                  )
                }
              />
            }
          />
        </DrawerFormBody>
        <StyledFlexBox className='buttons'>
          {purpose === 'edit' && (
            <Button
              onClick={deleteShowcaseCategoryHandler}
              variant='contained'
              size='large'
              color='customGray'
              startIcon={<DeleteIcon color={COLORS.RED} />}
              sx={{
                color: COLORS.RED,
              }}
            >
              Удалить
            </Button>
          )}
          {purpose === 'add' && (
            <Button
              onClick={ignoreChanges}
              variant='contained'
              size='large'
              color='customGray'
            >
              Отмена
            </Button>
          )}
          <Button
            variant='contained'
            size='large'
            color='primary'
            onClick={saveChanges}
          >
            Сохранить
          </Button>
        </StyledFlexBox>
      </DrawerBody>
    </Drawer>
  );
};
