import { FC, useState, useMemo, useEffect } from 'react';
import { TextField, FormControlLabel, Radio, Box } from '@mui/material';
import {
  useGetAppUser,
  useCreateAppUser,
  useGetSpacesList,
  useUpdateAppUser,
} from 'hooks';
import {
  Drawer,
  ModalTitle,
  Button,
  ImageUpload,
  SearchSelectInput,
  DrawerHeader,
  FormItem,
  DrawerBody,
  DrawerFormBody,
  StyledFlexBox,
  StyledRadioGroup,
  DateInput,
} from 'UI';
import { CloseIcon, PlusIcon, StarIcon, DeleteIcon } from 'assets';
import { IAppUserReq } from 'types';
import {
  COLORS,
  MEDIA_TYPES,
  isStringNumber,
  isStringValidRussianPhoneNumber,
  notify,
} from 'utils';
import { DeleteUserModal } from 'components';

const TRANSLATIONS = {
  add: {
    title: 'Новый пользователь',
  },
  edit: {
    title: 'Редактировать пользователя',
  },
};

const DEFAULT_VALUES = {
  firstname: '',
  lastname: '',
  middlename: '',
  photo: '',
  phone: '',
  email: '',
  weight: '',
  height: '',
  sex: 0,
  birthday: '1990-01-01',
  spaces: [],
  coins_count: 0,
  points_count: 0,
};

const NUMBER_PARAMETERS = ['weight', 'height', 'coins_count', 'points_count'];

interface IAddEditUserDrawerProps {
  open: boolean;
  onClose: () => void;
  purpose?: 'add' | 'edit';
  id?: number | string;
}

export const AddEditUserDrawer: FC<IAddEditUserDrawerProps> = ({
  open,
  onClose,
  purpose = 'add',
  id = '',
}) => {
  const { data: userInfoData } = useGetAppUser(id);
  const [userInfo, setUserInfo] = useState<IAppUserReq>(DEFAULT_VALUES);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const { data: spacesList } = useGetSpacesList();
  const { mutateAsync: createAppUser } = useCreateAppUser();
  const { mutateAsync: updateAppUser } = useUpdateAppUser();

  useEffect(() => {
    if (purpose === 'edit') {
      setUserInfo({
        id: userInfoData?.id,
        firstname: userInfoData?.firstname || '',
        lastname: userInfoData?.lastname || '',
        middlename: userInfoData?.middlename || '',
        photo: userInfoData?.photo || '',
        phone: userInfoData?.phone || '',
        weight: userInfoData?.weight,
        height: userInfoData?.height,
        sex: userInfoData?.sex?.id,
        email: userInfoData?.email || '',
        birthday: userInfoData?.birthday || '1990-01-01',
        coins_count: userInfoData?.coins_count || 0,
        points_count: userInfoData?.points_count || 0,
        spaces: userInfoData?.spaces?.map(space => space.id) || [],
      });
    } else {
      setUserInfo(DEFAULT_VALUES);
    }
  }, [purpose, userInfoData]);

  const options = useMemo(() => {
    return (
      spacesList?.map(space => ({
        label: space.name,
        value: space.id,
      })) || []
    );
  }, [spacesList]);

  const handleFieldChange = <T extends keyof IAppUserReq>(
    fieldName: T,
    newValue: IAppUserReq[T],
  ) => {
    if (NUMBER_PARAMETERS.includes(fieldName) && !isStringNumber(newValue)) {
      return;
    }
    setUserInfo(prevUserInfo => ({
      ...prevUserInfo,
      [fieldName]: newValue,
    }));
  };

  const getArrayOfUpdatedSpaces = (
    initialSpaces: number[],
    finalSpaces: number[],
  ): Partial<IAppUserReq> => {
    const invitedSpaces: number[] = [];
    const removedSpaces: number[] = [];

    // Compare initialSpaces with finalSpaces and create arrays of invited and removed spaces
    initialSpaces.forEach(space => {
      if (!finalSpaces.includes(space)) {
        removedSpaces.push(space);
      }
    });
    finalSpaces.forEach(space => {
      if (!initialSpaces.includes(space)) {
        invitedSpaces.push(space);
      }
    });

    return {
      invited_spaces: invitedSpaces,
      removed_spaces: removedSpaces,
    };
  };

  const saveChanges = () => {
    if (!isStringValidRussianPhoneNumber(userInfo.phone)) {
      notify('Неверный формат номера телефона');
      return;
    }
    const submittedValue = {
      ...userInfo,
      ...getArrayOfUpdatedSpaces(
        userInfoData?.spaces?.map(space => space.id) || [],
        userInfo.spaces,
      ),
    };
    if (purpose === 'add') {
      createAppUser(submittedValue)
        .then(() => {
          onClose();
        })
        .catch(() => {});
    } else {
      updateAppUser(submittedValue)
        .then(() => {
          onClose();
        })
        .catch(() => {});
    }
  };

  const openDeleteModal = () => {
    setShowDeleteModal(true);
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const ignoreChanges = () => {
    setUserInfo(DEFAULT_VALUES);
    onClose();
  };

  return (
    <Drawer open={open} onClose={ignoreChanges}>
      <DeleteUserModal
        open={showDeleteModal}
        onClose={closeDeleteModal}
        id={userInfo?.id}
        firstname={userInfo?.firstname}
        lastname={userInfo?.lastname}
        photo={userInfo?.photo as string | undefined}
        phone={userInfo?.phone}
        userType='user'
      />
      <DrawerHeader>
        <ModalTitle title={TRANSLATIONS[purpose].title} />
        <CloseIcon onClick={ignoreChanges} />
      </DrawerHeader>
      <DrawerBody>
        <ImageUpload
          image={userInfo.photo}
          setImage={(newPhoto: string) => handleFieldChange('photo', newPhoto)}
          inputId='userPhoto'
          mediaType={MEDIA_TYPES.USER}
        />
        <DrawerFormBody>
          <h2>О пользователе</h2>
          <FormItem label='Фамилия'>
            <TextField
              variant='standard'
              color='primary'
              placeholder='Введите фамилию'
              name='lastname'
              value={userInfo.lastname}
              onChange={e => handleFieldChange('lastname', e.target.value)}
            />
          </FormItem>
          <FormItem label='Имя'>
            <TextField
              variant='standard'
              color='primary'
              placeholder='Введите имя'
              name='firstname'
              value={userInfo.firstname}
              onChange={e => handleFieldChange('firstname', e.target.value)}
            />
          </FormItem>
          <FormItem label='Отчество'>
            <TextField
              variant='standard'
              color='primary'
              placeholder='Введите отчество'
              name='middlename'
              value={userInfo.middlename}
              onChange={e => handleFieldChange('middlename', e.target.value)}
            />
          </FormItem>
          <FormItem label='Номер телефона'>
            <TextField
              variant='standard'
              color='primary'
              placeholder='Введите номер телефона'
              name='phone'
              value={userInfo.phone}
              onChange={e => handleFieldChange('phone', e.target.value)}
            />
          </FormItem>
          <FormItem label='Эл-почта'>
            <TextField
              variant='standard'
              color='primary'
              placeholder='Введите эл-почту'
              name='email'
              value={userInfo.email}
              type='email'
              autoComplete='none'
              onChange={e => handleFieldChange('email', e.target.value)}
            />
          </FormItem>
          <FormItem label='Дата рождения'>
            <DateInput
              key={userInfo.birthday}
              stringValue={userInfo.birthday}
              onChange={newVal => handleFieldChange('birthday', newVal)}
            />
          </FormItem>

          <FormItem label='Укажите пол'>
            <StyledRadioGroup
              row
              value={userInfo.sex}
              onChange={e => handleFieldChange('sex', Number(e.target.value))}
            >
              <FormControlLabel
                value={1}
                control={<Radio />}
                label='Мужской'
                labelPlacement='start'
              />
              <FormControlLabel
                value={2}
                control={<Radio />}
                label='Женский'
                labelPlacement='start'
              />
            </StyledRadioGroup>
          </FormItem>
          <FormItem label='Пространство'>
            <SearchSelectInput
              options={options}
              value={userInfo.spaces}
              onChange={newValue =>
                handleFieldChange('spaces', newValue as number[])
              }
              placeholder='Выберите пространство'
              variant='standard'
              multiple
            />
          </FormItem>

          <StyledFlexBox className='fullDivWidth'>
            <FormItem label='Вес'>
              <TextField
                variant='standard'
                color='primary'
                placeholder='Укажите вес в кг'
                name='weight'
                value={userInfo.weight}
                onChange={e => handleFieldChange('weight', e.target.value)}
              />
            </FormItem>
            <FormItem label='Рост'>
              <TextField
                variant='standard'
                color='primary'
                placeholder='Укажите рост в см'
                name='height'
                value={userInfo.height}
                onChange={e => handleFieldChange('height', e.target.value)}
              />
            </FormItem>
          </StyledFlexBox>
          <StyledFlexBox className='fullDivWidth'>
            {/* <FormItem label='Баллы монеты'>
              <TextField
                variant='standard'
                color='primary'
                placeholder='Укажите баллы'
                name='coins_count'
                value={userInfo.coins_count}
                onChange={e =>
                  handleFieldChange(
                    'coins_count',
                    e.target.value as unknown as number,
                  )
                }
                InputProps={{
                  startAdornment: (
                    <MoneyIcon style={{ marginRight: '0.75rem' }} />
                  ),
                  sx: {},
                  endAdornment: (
                    <PlusIcon
                      color={COLORS.BLUE}
                      className='plusIcon'
                      onClick={() =>
                        handleFieldChange(
                          'coins_count',
                          userInfo.coins_count ? userInfo.coins_count + 1 : 1,
                        )
                      }
                    />
                  ),
                }}
              />
            </FormItem> */}
            <FormItem label='Звезды'>
              <TextField
                variant='standard'
                color='primary'
                placeholder='Укажите звезды'
                name='points_count'
                value={userInfo.points_count}
                onChange={e =>
                  handleFieldChange(
                    'points_count',
                    e.target.value as unknown as number,
                  )
                }
                InputProps={{
                  startAdornment: (
                    <StarIcon style={{ marginRight: '0.75rem' }} />
                  ),
                  endAdornment: (
                    <PlusIcon
                      color={COLORS.BLUE}
                      className='plusIcon'
                      onClick={() =>
                        handleFieldChange(
                          'points_count',
                          userInfo.points_count ? userInfo.points_count + 1 : 1,
                        )
                      }
                    />
                  ),
                }}
              />
            </FormItem>
            <Box />
          </StyledFlexBox>
        </DrawerFormBody>
        <StyledFlexBox className='buttons fullDivWidth'>
          {purpose === 'add' && <Box />}
          <Button
            variant='contained'
            size='large'
            color='primary'
            onClick={saveChanges}
          >
            Сохранить
          </Button>
          {purpose === 'edit' && (
            <Button
              onClick={openDeleteModal}
              variant='contained'
              size='large'
              color='customGray'
              startIcon={<DeleteIcon color={COLORS.RED} />}
              sx={{
                color: COLORS.RED,
              }}
            >
              Удалить
            </Button>
          )}
        </StyledFlexBox>
      </DrawerBody>
    </Drawer>
  );
};
