import { FC, useEffect, useState } from 'react';
import { Modal, Box } from '@mui/material';
import PhoneStatusBar from 'assets/images/PhoneStatusBar.png';
import { COLORS } from 'utils';
import { IStoryItem } from 'types';
import { CloseIcon } from 'assets';
import { Button } from 'UI';
import {
  StyledContainer,
  StyledPhoneContent,
  StyledPhoneWrapper,
} from './styledComponents';

interface IStoryPreviewModalProps {
  open: boolean;
  onClose: () => void;
  items: Array<IStoryItem>;
}

const STORY_TIME = 10000;

export const StoryPreviewModal: FC<IStoryPreviewModalProps> = ({
  open,
  onClose,
  items,
}) => {
  const [activeItemIndex, setActiveItemIndex] = useState<number>(0);
  const [timeItLived, setTimeItLived] = useState<number>(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveItemIndex(prevIndex => (prevIndex + 1) % items.length);
      setTimeItLived(0);
    }, STORY_TIME); // Change every 10 seconds

    return () => clearInterval(interval);
  }, [items.length]);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeItLived(prev => prev + 100);
    }, 100); // Update every 1ms

    return () => clearInterval(timer);
  }, [activeItemIndex]);

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'ArrowRight') {
      setActiveItemIndex(prevIndex => (prevIndex + 1) % items.length); // Right arrow - go to the next item
      setTimeItLived(0); // Reset time when switching manually
    } else if (event.key === 'ArrowLeft') {
      setActiveItemIndex(prevIndex =>
        prevIndex === 0 ? items.length - 1 : prevIndex - 1,
      ); // Left arrow - go to the previous item
      setTimeItLived(0); // Reset time when switching manually
    }
  };

  // Listen for keydown events
  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [items.length]);

  const getGradientPercentage = () => {
    const percentage = Math.min((timeItLived / STORY_TIME) * 100, 100);
    return `${percentage}%`;
  };

  const changeActiveItem = (index: number) => {
    setActiveItemIndex(index);
    setTimeItLived(0);
  };

  const isMediaVideo = (media: string) => {
    return media.endsWith('.mp4');
  };

  return (
    <Modal open={open} onClose={onClose}>
      <StyledContainer>
        <StyledPhoneWrapper>
          <StyledPhoneContent>
            <Box className='statusBar'>
              <img src={PhoneStatusBar} alt='Phone Status Bar' />
            </Box>
            <Box className='blocks'>
              {items.map((item, index) => (
                <Box
                  key={item.id}
                  className='block'
                  onClick={() => changeActiveItem(index)}
                  sx={{
                    background:
                      index === activeItemIndex
                        ? `linear-gradient(to right, ${
                            COLORS.RED
                          } ${getGradientPercentage()}, rgba(0, 0, 0, 0.2) ${getGradientPercentage()})`
                        : undefined,
                  }}
                />
              ))}
            </Box>
            <Box className='closeIcon'>
              <CloseIcon color={COLORS.WHITE} />
            </Box>
            {items[activeItemIndex]?.media && (
              <Box className='media'>
                {isMediaVideo(items[activeItemIndex].media) ? (
                  // eslint-disable-next-line jsx-a11y/media-has-caption
                  <video autoPlay>
                    <source
                      src={items[activeItemIndex].media}
                      type='video/mp4'
                    />
                    Your browser does not support the video tag.
                  </video>
                ) : (
                  <img src={items[activeItemIndex]?.media} alt='Story Media' />
                )}
              </Box>
            )}
            <Box className='buttonBox'>
              {items[activeItemIndex]?.button_text && (
                <Button variant='contained' color='secondaryRed2'>
                  {items[activeItemIndex].button_text}
                </Button>
              )}
            </Box>

            <Box className='homeIndicator' />
          </StyledPhoneContent>
        </StyledPhoneWrapper>
      </StyledContainer>
    </Modal>
  );
};
