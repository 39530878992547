export const FinishIcon = () => {
  return (
    <svg
      width='30'
      height='30'
      viewBox='0 0 30 30'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6.25 18.75L7.4375 5.6825C7.45436 5.49594 7.54042 5.32244 7.67875 5.19614C7.81709 5.06983 7.99768 4.99986 8.185 5H25.4288C25.533 4.99992 25.6361 5.02157 25.7315 5.06357C25.8269 5.10556 25.9125 5.16698 25.9828 5.2439C26.0531 5.32082 26.1067 5.41155 26.14 5.51031C26.1733 5.60907 26.1856 5.7137 26.1763 5.8175L25.0625 18.0675C25.0456 18.2541 24.9596 18.4276 24.8212 18.5539C24.6829 18.6802 24.5023 18.7501 24.315 18.75H6.25ZM6.25 18.75L5.5 26.25'
        stroke='#0080FF'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M11.2857 9L12 5H7.71429L7 9H11.2857Z' fill='#0080FF' />
      <path d='M14.2857 14L15 9H10.7143L10 14H14.2857Z' fill='#0080FF' />
      <path d='M9.42857 19L10 14H6.57143L6 19H9.42857Z' fill='#0080FF' />
      <path d='M19.2857 9L20 5H15.7143L15 9H19.2857Z' fill='#0080FF' />
      <path d='M22.2857 14L23 9H18.7143L18 14H22.2857Z' fill='#0080FF' />
      <path d='M17.2857 19L18 14H13.7143L13 19H17.2857Z' fill='#0080FF' />
      <path d='M26.4286 9L27 5H23.5714L23 9H26.4286Z' fill='#0080FF' />
      <path d='M25.2857 19L26 14H21.7143L21 19H25.2857Z' fill='#0080FF' />
    </svg>
  );
};
