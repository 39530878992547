import { FC, useState, useMemo, useContext, MouseEvent } from 'react';
import { TableBody, TableCell, Box } from '@mui/material';

import { UserContext, UserContextType } from 'app';
import { EditIcon, DeleteIcon } from 'assets';
import { Pagination, StyledSwitch } from 'UI';
import {
  useDeleteMeAndGto,
  useSearchParams,
  useChangeStatus,
  useGetMeAndGtos,
} from 'hooks';
import { TableRowsOrder, MeAndGtoSortParams, IMeAndGto } from 'types';
import { DeleteModal } from 'components';

import {
  StyledBox,
  StyledPaper,
  StyledTableContainer,
  StyledTable,
  StyledTableRow,
  StyledMeAndGtoInfo,
  StyledIcon,
} from './styledComponents';
import { MeAndGtoTableHead } from './MeAndGtoTableHead';

export const MeAndGtoTable: FC = () => {
  const { space_id } = useContext(UserContext) as UserContextType;
  const [order, setOrder] = useState<TableRowsOrder>('asc');
  const [orderBy, setOrderBy] = useState<MeAndGtoSortParams>('title');
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [selectedMeAndGto, setSelectedMeAndGto] = useState<
    IMeAndGto | undefined
  >(undefined);
  const { setParam, getParam, searchParams } = useSearchParams();

  const params = useMemo(() => {
    return {
      page: getParam('page'),
      search: getParam('search'),
      column: orderBy,
      sort_type: order,
    };
  }, [searchParams, orderBy, order]);

  const {
    data: { data: meAndGtos, last_page: pageCount } = {
      data: [],
      last_page: 1,
    },
  } = useGetMeAndGtos(params);
  const { mutateAsync: deleteMeAndGto } = useDeleteMeAndGto();
  const { mutateAsync: changeStatus } = useChangeStatus('instruction');

  const updateRow = (row: IMeAndGto) => {
    changeStatus({
      model_name: 'instruction',
      model_id: row.id,
      state: !row.is_active,
      space_id: space_id!,
    })
      .then(() => {})
      .catch(() => {});
  };

  const deleteButtonHandler = (row: IMeAndGto) => {
    setSelectedMeAndGto(row);
    setOpenDeleteModal(true);
  };

  const deleteRow = async () => {
    await deleteMeAndGto(selectedMeAndGto!.id).catch(() => {});
    setOpenDeleteModal(false);
  };

  const cancelDeleteRow = () => {
    setOpenDeleteModal(false);
    setSelectedMeAndGto(undefined);
  };

  const handleRequestSort = (
    _event: MouseEvent<unknown>,
    property: MeAndGtoSortParams,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const openEditDrawer = (id: string | number) => {
    setParam('edit', id.toString());
  };

  return (
    <StyledBox>
      <DeleteModal
        open={openDeleteModal}
        title='Удалить вопрос'
        description='Вы действительно хотите удалить вопрос?'
        onConfirm={deleteRow}
        onClose={cancelDeleteRow}
      />
      <StyledPaper>
        <StyledTableContainer>
          <StyledTable
            aria-labelledby='meAndGtoTable'
            size='medium'
            stickyHeader
          >
            <MeAndGtoTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {meAndGtos.map(row => {
                return (
                  <StyledTableRow hover key={row.id}>
                    <TableCell
                      component='th'
                      id={row.id.toString()}
                      scope='row'
                    >
                      <StyledMeAndGtoInfo
                        onClick={() => openEditDrawer(row.id)}
                      >
                        <StyledIcon>
                          <img src={row.cover} alt={row.title} />
                        </StyledIcon>
                        <Box className='name'>{row.title}</Box>
                      </StyledMeAndGtoInfo>
                    </TableCell>
                    <TableCell>
                      <div className='editDeleteIcons'>
                        <StyledSwitch
                          checked={row.is_active}
                          variant='customGray'
                          onChange={() => updateRow(row)}
                          style={{ opacity: 1 }}
                          className='switch'
                        />
                        <EditIcon onClick={() => openEditDrawer(row.id)} />
                        <DeleteIcon onClick={() => deleteButtonHandler(row)} />
                      </div>
                    </TableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </StyledTable>
        </StyledTableContainer>
        <Pagination count={pageCount} />
      </StyledPaper>
    </StyledBox>
  );
};
