import { Box, Paper, Table, TableContainer, TableRow } from '@mui/material';
import { styled } from '@mui/system';

export const StyledBox = styled(Box)({
  width: '100%',
  overflow: 'auto',
});

export const StyledPaper = styled(Paper)({
  width: '100%',
  marginBottom: 2,
});

export const StyledTableContainer = styled(TableContainer)({
  maxHeight: 'calc(100vh - 12rem)',
  minHeight: 'calc(100vh - 12rem)',
});

export const StyledTable = styled(Table)({
  minWidth: '840px',
  '& th': {
    textAlign: 'left',
  },
});

export const StyledTableRow = styled(TableRow)({
  '& span': {
    opacity: 0.5,
    fontSize: '0.75rem',
    fontWeight: 400,
    lineHeight: '1.125rem',
    fontFamily: '"SF Pro Display", sans-serif',
  },
  '& .editDeleteIcons': {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    gap: '1.5rem',
    '& svg': {
      cursor: 'pointer',
      marginTop: '0.5rem',
    },
  },
});

export const StyledWorkoutInfo = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '0.875rem',
  cursor: 'pointer',
  '& .name': {
    fontSize: '0.875rem',
    lineheight: '1.375rem',
    fontFamily: '"SF Pro Display", sans-serif',
    fontWeight: 600,
  },
});

export const StyledAvatar = styled(Box)({
  width: '3.125rem',
  height: '3.125rem',
  borderRadius: '0.5rem',
  backgroundColor: 'rgba(0,0,0,0.05)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'hidden',
  '& img': {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    objectPosition: 'center',
  },
});
