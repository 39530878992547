import { useQuery } from 'react-query';
import {
  getAnalyticsUsersAge,
  getAnalyticsUsersData,
  getAnalyticsUsersGender,
  getAnalyticsUsersNews,
  getAnalyticsUsersTopLists,
  getAnalyticsUsersChallenges,
  getAnalyticsUsersDownloadFile,
} from 'api';
import {
  IAnalyticsCommonGetParams,
  IAnalyticsUsersCardRes,
  IAnalyticsUsersTopGetParams,
  IAnalyticsGenderRes,
  IAnalyticsAgeRes,
  IAnalyticsUsersNewsRes,
  IAnalyticsUsersChallengesRes,
  IAnalyticsUsersTopRes,
} from 'types';

export const useGetAnalyticsUsersData = (params: IAnalyticsCommonGetParams) => {
  const { start_date, end_date } = params;
  return useQuery<IAnalyticsUsersCardRes, unknown>(
    ['analyticsUsers', 'data', { start_date, end_date }],
    async () => {
      const res = await getAnalyticsUsersData(params);
      return res;
    },
    {
      staleTime: 24 * 60 * 60 * 1000,
      retry: false,
    },
  );
};

export const useGetAnalyticsUsersTopLists = (
  params: IAnalyticsUsersTopGetParams,
) => {
  const {
    start_date,
    end_date,
    filter,
    limit,
    order_type,
    order,
    page,
    search,
  } = params;
  return useQuery<IAnalyticsUsersTopRes, unknown>(
    [
      'analyticsUsers',
      'topLists',
      {
        start_date,
        end_date,
        filter,
        limit,
        order_type,
        order,
        page,
        search,
      },
    ],
    async () => {
      const res = await getAnalyticsUsersTopLists(params);
      return res;
    },
    {
      staleTime: 24 * 60 * 60 * 1000,
      retry: false,
    },
  );
};

export const useGetAnalyticsUsersGender = (
  params: IAnalyticsCommonGetParams,
) => {
  const { start_date, end_date } = params;
  return useQuery<IAnalyticsGenderRes, unknown>(
    ['analyticsUsers', 'gender', { start_date, end_date }],
    async () => {
      const res = await getAnalyticsUsersGender(params);
      return res;
    },
    {
      staleTime: 24 * 60 * 60 * 1000,
      retry: false,
    },
  );
};

export const useGetAnalyticsUsersAge = (params: IAnalyticsCommonGetParams) => {
  const { start_date, end_date } = params;
  return useQuery<IAnalyticsAgeRes, unknown>(
    ['analyticsUsers', 'age', { start_date, end_date }],
    async () => {
      const res = await getAnalyticsUsersAge(params);
      return res;
    },
    {
      staleTime: 24 * 60 * 60 * 1000,
      retry: false,
    },
  );
};

export const useGetAnalyticsUsersNews = (params: IAnalyticsCommonGetParams) => {
  const { start_date, end_date } = params;
  return useQuery<IAnalyticsUsersNewsRes, unknown>(
    ['analyticsUsers', 'news', { start_date, end_date }],
    async () => {
      const res = await getAnalyticsUsersNews(params);
      return res;
    },
    {
      staleTime: 24 * 60 * 60 * 1000,
      retry: false,
    },
  );
};

export const useGetAnalyticsUsersChallenges = (
  params: IAnalyticsCommonGetParams,
) => {
  const { start_date, end_date } = params;
  return useQuery<IAnalyticsUsersChallengesRes, unknown>(
    ['analyticsUsers', 'challenges', { start_date, end_date }],
    async () => {
      const res = await getAnalyticsUsersChallenges(params);
      return res;
    },
    {
      staleTime: 24 * 60 * 60 * 1000,
      retry: false,
    },
  );
};

export const useGetAnalyticsUsersDownloadFile = (
  params: IAnalyticsCommonGetParams,
) => {
  const { start_date, end_date } = params;
  return useQuery(
    ['analyticsUsers', 'file', { start_date, end_date }],
    async () => {
      const res = await getAnalyticsUsersDownloadFile(params);
      return res;
    },
  );
};
