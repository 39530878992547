import { FC, useState, useMemo, MouseEvent, useEffect } from 'react';
import { Box, Popover } from '@mui/material';
import { useGetAppUsers } from 'hooks';
import { SearchInput, Button, UserImage } from 'UI';
import { IAppUser, IModifiedUser } from 'types';
import { PlusIcon, DeleteIcon } from 'assets';
import { COLORS } from 'utils';

import {
  StyledContainer,
  StyledHeader,
  StyledMainContent,
  StyledUserOptions,
  StyledUserOption,
  StyledUsersList,
  StyledUserItem,
  StyledButtonBox,
} from './styledComponents';

interface IAddUserToGroupProps {
  users: IModifiedUser[];
  spaceId?: string | number;
  changeUsers: (_userIds: number[]) => void;
}

export const AddUserToGroup: FC<IAddUserToGroupProps> = ({
  users,
  spaceId,
  changeUsers,
}) => {
  if (!spaceId) return null;
  const [showSearchComponent, setShowSearchComponent] =
    useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [searchUserValue, setSearchUserValue] = useState<string>('');
  const paramsToGetUsers = useMemo(() => {
    return {
      search: searchUserValue,
      space_id: spaceId,
    };
  }, [searchUserValue, spaceId]);
  const { data: { data: usersList } = { data: [] } } =
    useGetAppUsers(paramsToGetUsers);

  const [localSearchValue, setLocalSearchValue] = useState<string>('');
  const [selectedUsers, setSelectedUsers] = useState<IModifiedUser[]>([]);

  useEffect(() => {
    setSelectedUsers(users);
  }, [users]);

  useEffect(() => {
    changeUsers(selectedUsers.map(u => u.id));
  }, [selectedUsers]);

  const openSearchComponent = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setShowSearchComponent(true);
  };

  const hideSearchComponent = () => {
    setShowSearchComponent(false);
    setAnchorEl(null);
  };

  const handleWorkerSearch = (search: string) => {
    setSearchUserValue(search);
  };

  const addUserHandler = (user: IAppUser) => {
    hideSearchComponent();
    if (selectedUsers.includes(user)) {
      return;
    }
    setSelectedUsers([
      ...selectedUsers,
      {
        id: user.id,
        firstname: user.firstname,
        lastname: user.lastname,
        photo: user.photo,
      },
    ]);
  };

  const handleLocalSearch = (value: string) => {
    setLocalSearchValue(value);
  };

  const visibleUsers = useMemo(() => {
    return selectedUsers.filter(user =>
      user.firstname?.includes(localSearchValue),
    );
  }, [localSearchValue, selectedUsers]);

  const removeUserHandler = (user: IModifiedUser) => {
    setSelectedUsers(prev => prev.filter(item => item.id !== user.id));
  };

  return (
    <StyledContainer>
      <StyledHeader>
        <h2>Участники</h2>
        {selectedUsers.length > 0 && (
          <SearchInput handleSearch={handleLocalSearch} size='medium' />
        )}
      </StyledHeader>
      <StyledMainContent>
        <Popover
          id={showSearchComponent ? 'searchWorkerPopover' : undefined}
          open={showSearchComponent}
          anchorEl={anchorEl}
          onClose={hideSearchComponent}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <Box>
            <StyledUserOptions>
              {usersList.map(user => {
                return (
                  <StyledUserOption
                    key={user.id}
                    onClick={() => addUserHandler(user)}
                  >
                    <Box className='imageBox'>
                      <UserImage
                        src={user.photo as string | undefined}
                        alt={user.firstname}
                        firstName={user.firstname}
                        lastName={user.lastname}
                        width='3.125rem'
                        height='3.125rem'
                      />
                    </Box>
                    <p>
                      {user.lastname} {user.firstname}
                    </p>
                  </StyledUserOption>
                );
              })}
            </StyledUserOptions>

            <SearchInput
              handleSearch={search => handleWorkerSearch(search)}
              size='large'
            />
          </Box>
        </Popover>
        <StyledUsersList>
          {visibleUsers.map(user => (
            <StyledUserItem>
              <Box className='userMeta'>
                <Box className='imageBox'>
                  <UserImage
                    src={user.photo as string | undefined}
                    alt={user.firstname}
                    firstName={user.firstname}
                    lastName={user.lastname}
                    width='3.125rem'
                    height='3.125rem'
                  />
                </Box>
                {user.firstname} {user.lastname}
              </Box>
              <Button
                color='customWhite'
                onClick={() => removeUserHandler(user)}
              >
                <DeleteIcon color={COLORS.RED} />
              </Button>
            </StyledUserItem>
          ))}
        </StyledUsersList>
        <StyledButtonBox>
          <Button
            startIcon={<PlusIcon color={COLORS.BLUE} />}
            onClick={e => openSearchComponent(e)}
            color='customLightBlue'
          >
            Добавить
          </Button>
        </StyledButtonBox>
      </StyledMainContent>
    </StyledContainer>
  );
};
