import { FC, useState, useMemo, useContext, MouseEvent } from 'react';
import { TableBody, TableCell } from '@mui/material';

import { EditIcon, DeleteIcon } from 'assets';
import { UserContext, UserContextType } from 'app';
import { DeleteModal } from 'components';
import { Pagination, StyledSwitch } from 'UI';
import {
  useGetShopAttributes,
  useDeleteShopAttribute,
  useSearchParams,
  useChangeStatus,
} from 'hooks';
import { IShopAttribute, TableRowsOrder } from 'types';
import { getComparator } from 'utils';

import {
  StyledBox,
  StyledPaper,
  StyledTableContainer,
  StyledTable,
  StyledTableRow,
} from './styledComponents';
import { AttributesTableHead } from './AttributesTableHead';

export const AttributesTable: FC = () => {
  const { space_id } = useContext(UserContext) as UserContextType;
  const [order, setOrder] = useState<TableRowsOrder>('asc');
  const [orderBy, setOrderBy] = useState<keyof IShopAttribute>('id');
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [selectedAttribute, setSelectedAttribute] = useState<
    IShopAttribute | undefined
  >(undefined);
  const { setParam, getParam, searchParams } = useSearchParams();

  const params = useMemo(() => {
    return {
      page: getParam('page'),
      search: getParam('search'),
    };
  }, [searchParams]);

  const {
    data: { data: attributes, last_page: pageCount } = {
      data: [],
      last_page: 1,
    },
  } = useGetShopAttributes(params);
  const { mutateAsync: deleteAttribute } = useDeleteShopAttribute();
  const { mutateAsync: changeStatus } = useChangeStatus('product_attribute');

  const updateRow = (row: IShopAttribute) => {
    if (!row.id) return;
    changeStatus({
      space_id: space_id!,
      model_name: 'product_attribute',
      model_id: row.id,
      state: !row.is_active,
    })
      .then(() => {})
      .catch(() => {});
  };

  const handleRequestSort = (
    _event: MouseEvent<unknown>,
    property: keyof IShopAttribute,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const visibleRows = useMemo(() => {
    if (!attributes) return [];
    return attributes.sort(getComparator(order, orderBy));
  }, [order, orderBy, attributes]);

  const deleteButtonHandler = (row: IShopAttribute) => {
    setSelectedAttribute(row);
    setOpenDeleteModal(true);
  };

  const deleteRow = async () => {
    await deleteAttribute(selectedAttribute!.id);
    setOpenDeleteModal(false);
  };

  const cancelDeleteRow = () => {
    setOpenDeleteModal(false);
    setSelectedAttribute(undefined);
  };

  const openEditDrawer = (id: string | number) => {
    setParam('edit', id.toString());
  };

  return (
    <>
      <DeleteModal
        open={openDeleteModal}
        title='Удалить атрибут'
        description='Вы действительно хотите удалить атрибут'
        onConfirm={deleteRow}
        onClose={cancelDeleteRow}
      />
      <StyledBox>
        <StyledPaper>
          <StyledTableContainer>
            <StyledTable
              aria-labelledby='attributesTable'
              size='medium'
              stickyHeader
            >
              <AttributesTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {visibleRows.map((row, index) => {
                  return (
                    <StyledTableRow hover key={row.id}>
                      <TableCell
                        component='th'
                        id={row.id?.toString()}
                        scope='row'
                        style={{ textAlign: 'center' }}
                      >
                        <span>{index + 1}</span>
                      </TableCell>
                      <TableCell>
                        <span>{row.name}</span>
                      </TableCell>
                      <TableCell>
                        <div className='editDeleteIcons'>
                          <StyledSwitch
                            checked={row.is_active}
                            variant='customGray'
                            onChange={() => updateRow(row)}
                            style={{ opacity: 1 }}
                          />
                          <EditIcon onClick={() => openEditDrawer(row.id)} />
                          <DeleteIcon
                            onClick={() => deleteButtonHandler(row)}
                          />
                        </div>
                      </TableCell>
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </StyledTable>
          </StyledTableContainer>
          <Pagination count={pageCount} />
        </StyledPaper>
      </StyledBox>
    </>
  );
};
