import { FC, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box } from '@mui/material';
import {
  Button,
  Drawer,
  DrawerHeader,
  ModalTitle,
  StyledBadge,
  StyledFlexBox,
} from 'UI';
import { useDrawer, useGetMarathon } from 'hooks';
import {
  COLORS,
  CREATE_CHALLENGE_DRAWER_STEPS,
  convertDateToDottedValue,
} from 'utils';
import { IMarathonParticipantTeam, IMarathonParticipantUser } from 'types';
import { CloseIcon, EditIcon, RightIcon } from 'assets';
import {
  StyledBody,
  StyledLabel,
  StyledMiniCard,
  StyledCard,
  StyledHeader,
} from './styledComponents';
import { WinnerInfoUser, WinnerInfoTeam } from './components';

interface IViewChallengeDrawerProps {
  open: boolean;
  onClose: () => void;
  id: number | string;
}

export const ViewChallengeDrawer: FC<IViewChallengeDrawerProps> = ({
  open,
  onClose,
  id,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { changeSubDrawer } = useDrawer();
  const { data: marathonInfo } = useGetMarathon(id);
  const isIndividualChallenge = useMemo(
    () => marathonInfo?.type === 2,
    [marathonInfo],
  );
  if (!marathonInfo) return null;

  const goToPrizes = () => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('edit', id.toString());
    searchParams.set('step', CREATE_CHALLENGE_DRAWER_STEPS.ADD_PRIZES);
    searchParams.delete('view');
    navigate(`?${searchParams.toString()}`);
  };

  const openEditDrawer = () => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('edit', id.toString());
    searchParams.set('step', CREATE_CHALLENGE_DRAWER_STEPS.MAIN_INFO);
    searchParams.delete('view');
    navigate(`?${searchParams.toString()}`);
  };

  const openSubDrawer = (subDrawerName: string) => {
    changeSubDrawer(subDrawerName);
  };

  const calculateLengthDays = () => {
    const startDate = new Date(marathonInfo?.start_date as string);
    const endDate = new Date(marathonInfo?.end_date as string);
    const diffTime = Math.abs(endDate.getTime() - startDate.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  };

  const calculateNumberOfTrainings = () => {
    let numberOfTrainings = 0;
    marathonInfo.calendar?.forEach(day => {
      numberOfTrainings += day.items.length;
    });
    return numberOfTrainings;
  };

  return (
    <Drawer open={open} onClose={onClose} width='60vw' type='gray'>
      <DrawerHeader>
        <StyledFlexBox>
          <ModalTitle title='Просмотр вызова' />
          <StyledBadge
            is_active={marathonInfo?.is_active || false}
            style={{ marginBottom: 0 }}
          />
        </StyledFlexBox>
        <CloseIcon onClick={onClose} />
      </DrawerHeader>
      <StyledHeader bgImg={marathonInfo?.cover}>
        <Box className='meta'>
          <Box>
            <Box className='name'>{marathonInfo?.name}</Box>
            {/* <Box className='description'>{marathonInfo?.body}</Box> */}
          </Box>
          <Box className='row'>
            <Box className='description'>
              с {convertDateToDottedValue(marathonInfo.start_date)} до{' '}
              {convertDateToDottedValue(marathonInfo.end_date)}
            </Box>
            <Button
              color='customBlack'
              startIcon={<EditIcon color={COLORS.WHITE} />}
              size='large'
              onClick={openEditDrawer}
            >
              Редактировать
            </Button>
          </Box>
        </Box>
      </StyledHeader>
      <StyledBody>
        <Box className='row'>
          <Box className='column'>
            <StyledLabel>Возраст</StyledLabel>
            <StyledMiniCard>
              от {marathonInfo?.min_age} до {marathonInfo?.max_age} лет
            </StyledMiniCard>
          </Box>
          <Box className='column'>
            <StyledLabel>Тип вызова</StyledLabel>
            <StyledMiniCard>
              {isIndividualChallenge ? 'Общедоступный' : 'Командный'}
            </StyledMiniCard>
          </Box>
        </Box>
        <Box className='row'>
          <Box className='column'>
            <StyledCard>
              <Box className='value'>{calculateLengthDays()} дней</Box>
              <div className='flex'>
                <Box className='title'>
                  {calculateNumberOfTrainings()} тренировок
                </Box>
                <Button
                  variant='text'
                  endIcon={<RightIcon />}
                  onClick={() =>
                    openSubDrawer(CREATE_CHALLENGE_DRAWER_STEPS.VIEW_TRAININGS)
                  }
                >
                  Перейти
                </Button>
              </div>
            </StyledCard>
          </Box>
          <Box className='column'>
            <StyledCard>
              <Box className='value'>{marathonInfo?.members_count}</Box>
              <div className='flex'>
                <Box className='title'>Участника</Box>
                <Button
                  variant='text'
                  endIcon={<RightIcon />}
                  onClick={() =>
                    openSubDrawer(CREATE_CHALLENGE_DRAWER_STEPS.VIEW_USERS)
                  }
                >
                  Перейти
                </Button>
              </div>
            </StyledCard>
          </Box>
        </Box>
        <Box className='row'>
          <Box className='column'>
            <StyledCard>
              <Box className='bigCardTitle flex'>
                <Box>Призы</Box>
                <Button
                  variant='text'
                  endIcon={<RightIcon />}
                  onClick={() => goToPrizes()}
                >
                  Перейти
                </Button>
              </Box>
              <Box className='prizesList'>
                {marathonInfo.trophies?.map(prize => (
                  <Box className='prize' key={prize.id}>
                    <img
                      src={prize.cover}
                      alt={`${prize.place_in_the_ladder}-приз`}
                    />
                  </Box>
                ))}
              </Box>
            </StyledCard>
          </Box>
          <Box className='column'>
            <StyledCard>
              <Box className='bigCardTitle'>Победители</Box>
              <Box className='winnersList'>
                {marathonInfo.winners?.length ? (
                  marathonInfo.winners?.map(winner => (
                    <Box className='winner' key={winner.id}>
                      {isIndividualChallenge ? (
                        <WinnerInfoUser
                          winner={winner as IMarathonParticipantUser}
                        />
                      ) : (
                        <WinnerInfoTeam
                          winner={winner as IMarathonParticipantTeam}
                        />
                      )}
                      <Box className={`place place-${winner.winning_place}`}>
                        {winner.winning_place} место
                      </Box>
                    </Box>
                  ))
                ) : (
                  <Box className='noWinners'>Не назначены</Box>
                )}
              </Box>
              <Button
                onClick={() =>
                  openSubDrawer(CREATE_CHALLENGE_DRAWER_STEPS.VIEW_USERS)
                }
              >
                Посмотреть все
              </Button>
            </StyledCard>
          </Box>
        </Box>
        <Box className='row'>
          <Box className='column'>
            <StyledCard>
              <Box className='bigCardTitle'>Спонсоры</Box>
              <Box className='winnersList'>
                {marathonInfo.sponsors.map((sponsor: any) => (
                  <Box className='winner' key={sponsor.id}>
                    <Box className='winnerInfo'>
                      <Box className='photo'>
                        <img src={sponsor.cover} alt={sponsor.name} />
                      </Box>
                      <Box className='name'>{sponsor.name}</Box>
                    </Box>
                  </Box>
                ))}
              </Box>
            </StyledCard>
          </Box>
        </Box>
      </StyledBody>
    </Drawer>
  );
};
