import { FC, useMemo, useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/system';

import { SearchSelectInput } from 'UI';
import { useGetShopAttributesList, useSearchParams } from 'hooks';
import { COLORS } from 'utils';

const StyledBox = styled(Box)({
  minWidth: '15rem',
  '& .MuiAutocomplete-root': {
    width: '100%',
    backgroundColor: COLORS.WHITE,
    borderRadius: '0.5rem',
  },
  '& .MuiInputBase-root': {
    height: '2.75rem',
    borderRadius: '0.5rem',
    '& fieldset': {
      border: `1px solid ${COLORS.WHITE}`,
    },
    '&:hover fieldset': {
      border: `1px solid ${COLORS.WHITE}`,
    },
    '&.Mui-focused fieldset': {
      border: `1px solid ${COLORS.BLUE}`,
    },
  },
});

interface IAttributesFilterProps {
  size?: 'small' | 'medium';
  className?: string;
}

export const AttributesFilter: FC<IAttributesFilterProps> = ({
  size = 'small',
  className,
}) => {
  const { setParams, getParam } = useSearchParams();
  const [attributeId, setAttributeId] = useState<number>(
    +getParam('attribute_id') || 0,
  );

  const { data: attributesList } = useGetShopAttributesList();

  const options = useMemo(() => {
    if (!attributesList || !attributesList.length) {
      return [
        {
          label: 'Все атрибуты',
          value: 0,
        },
      ];
    }
    return [
      {
        label: 'Все атрибуты',
        value: 0,
      },
      ...attributesList.map(attribute => ({
        label: attribute.name,
        value: +attribute.id,
      })),
    ];
  }, [attributesList]);

  const changeAttributeHandler = (newValue: number) => {
    setAttributeId(newValue);
  };

  useEffect(() => {
    setParams({ attribute_id: attributeId.toString(), page: 1 });
  }, [attributeId]);

  useEffect(() => {}, []);

  return (
    <StyledBox className={className}>
      <SearchSelectInput
        options={options}
        onChange={newValue => changeAttributeHandler(newValue as number)}
        value={attributeId}
        size={size}
        placeholder='Все атрибуты'
        variant='outlined'
        disableClearable
      />
    </StyledBox>
  );
};
