import { FC, useMemo, useState, useContext, MouseEvent } from 'react';
import { TableBody, TableCell } from '@mui/material';

import { UserContext, UserContextType } from 'app';
import { DeleteModal } from 'components';
import { Pagination, StyledSwitch } from 'UI';
import { EditIcon, DeleteIcon } from 'assets';
import {
  useGetShowcaseCategories,
  useDeleteShowcaseCategory,
  useSearchParams,
  useChangeStatus,
} from 'hooks';
import {
  IShowcaseCategory,
  ShowcaseCategorySortParams,
  TableRowsOrder,
} from 'types';

import {
  StyledBox,
  StyledPaper,
  StyledTableContainer,
  StyledTable,
  StyledTableRow,
} from './styledComponents';
import { ShowcaseCategoriesTableHead } from './ShowcaseCategoriesTableHead';

export const ShowcaseCategoriesTable: FC = () => {
  const { space_id } = useContext(UserContext) as UserContextType;
  const [order, setOrder] = useState<TableRowsOrder>('desc');
  const [orderBy, setOrderBy] = useState<ShowcaseCategorySortParams>('title');
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [selectedCategory, setSelectedCategory] = useState<
    IShowcaseCategory | undefined
  >(undefined);

  const { getParam, setParam, searchParams } = useSearchParams();
  const { mutateAsync: deleteShowcaseCategory } = useDeleteShowcaseCategory();
  const { mutateAsync: changeStatus } = useChangeStatus('showcase_category');

  const params = useMemo(() => {
    return {
      page: getParam('page'),
      search: getParam('search'),
      column: orderBy,
      sort_type: order,
    };
  }, [searchParams, orderBy, order]);

  const {
    data: { data: events, last_page: pageCount } = {
      data: [],
      last_page: 1,
    },
  } = useGetShowcaseCategories(params);

  const updateRow = (row: IShowcaseCategory) => {
    if (!row.id) return;
    changeStatus({
      space_id: space_id!,
      model_name: 'showcase_category',
      model_id: row.id,
      state: !row.is_active,
    })
      .then(() => {})
      .catch(() => {});
  };

  const handleRequestSort = (
    _event: MouseEvent<unknown>,
    property: ShowcaseCategorySortParams,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const openEditDrawer = (id: string | number) => {
    setParam('edit', id.toString());
  };

  const deleteButtonHandler = (row: IShowcaseCategory) => {
    setSelectedCategory(row);
    setOpenDeleteModal(true);
  };

  const deleteRow = () => {
    deleteShowcaseCategory(selectedCategory!.id)
      .then(() => {})
      .catch(() => {})
      .finally(() => {
        setOpenDeleteModal(false);
      });
  };

  const cancelDeleteRow = () => {
    setOpenDeleteModal(false);
    setSelectedCategory(undefined);
  };

  return (
    <>
      <DeleteModal
        open={openDeleteModal}
        title='Удалить категорию'
        description='Вы действительно хотите удалить категорию?'
        onConfirm={deleteRow}
        onClose={cancelDeleteRow}
      />
      <StyledBox>
        <StyledPaper>
          <StyledTableContainer>
            <StyledTable
              aria-labelledby='showcaseCategoriesTable'
              size='medium'
              stickyHeader
            >
              <ShowcaseCategoriesTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {events.map(row => {
                  return (
                    <StyledTableRow
                      hover
                      key={row.id}
                      sx={{
                        backgroundColor: !row.is_active ? '#fff6f5' : '#ffffff',
                      }}
                    >
                      <TableCell
                        component='th'
                        id={row.id.toString()}
                        scope='row'
                        align='center'
                      >
                        {row.id}
                      </TableCell>
                      <TableCell>
                        <span>{row.title}</span>
                      </TableCell>
                      <TableCell align='center'>
                        <span>{row.position}</span>
                      </TableCell>
                      <TableCell>
                        <div className='editDeleteIcons'>
                          <StyledSwitch
                            checked={row.is_active}
                            variant='customGray'
                            onChange={() => updateRow(row)}
                            style={{ opacity: 1 }}
                            className='switch'
                          />
                          <EditIcon onClick={() => openEditDrawer(row.id)} />
                          <DeleteIcon
                            onClick={() => deleteButtonHandler(row)}
                          />
                        </div>
                      </TableCell>
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </StyledTable>
          </StyledTableContainer>
          <Pagination count={pageCount} />
        </StyledPaper>
      </StyledBox>
    </>
  );
};
