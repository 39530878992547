import { FC, useState } from 'react';
import ImagePlaceholderVertical from 'assets/images/input_image_placeholder_vertical.png';
import { IStoryItem } from 'types';
import { useDrawer } from 'hooks';
import { AddEditStoryItemDrawer } from 'components';

import { SettingsIcon, CloseIcon } from 'assets';
import { COLORS } from 'utils';
import {
  StyledContainer,
  StyledStoryItem,
  StyledItemAdder,
} from './styledComponents';

interface IStoryItemsProps {
  items: Array<IStoryItem>;
  addItem: (_item: IStoryItem) => void;
  editItem: (_item: IStoryItem) => void;
  removeItem: (_id: string) => void;
}

const DEFAULT_ITEM: IStoryItem = {
  media: '',
  button_text: '',
  transition_type: 'none',
  transition_target: 0,
};

export const StoryItems: FC<IStoryItemsProps> = ({
  items,
  addItem,
  editItem,
  removeItem,
}) => {
  const { changeSubDrawer, subDrawer, hideSubDrawer, subPurpose } = useDrawer();
  const [currentItem, setCurrentItem] = useState<IStoryItem>(DEFAULT_ITEM);

  const openEditItemDrawer = (selectedItemToEdit: IStoryItem) => {
    setCurrentItem(selectedItemToEdit);
    changeSubDrawer('storyItem', selectedItemToEdit.id!.toString());
  };

  const openAddItemDrawer = () => {
    const minimumId = Math.min(...items.map(item => item.id as number));
    setCurrentItem({ ...DEFAULT_ITEM, id: minimumId < 0 ? minimumId - 1 : -1 });
    changeSubDrawer('storyItem', '0');
  };

  const removeStoryItem = (id: string) => {
    removeItem(id);
  };

  return (
    <StyledContainer>
      {items.map(item => (
        <StyledStoryItem key={item.id}>
          {item.media?.endsWith('.mp4') ? (
            // eslint-disable-next-line jsx-a11y/media-has-caption
            <video controls width={80} height={144}>
              <source src={item.media} type='video/mp4' />
              Your browser does not support the video tag.
            </video>
          ) : (
            <img src={item.media} alt='story-media' />
          )}

          <div className='bgBox' />
          <CloseIcon
            onClick={() => removeStoryItem(item.id!.toString())}
            color={COLORS.RED_2}
            className='deleteIcon'
          />
          <SettingsIcon
            color={COLORS.BLUE}
            className='editIcon'
            onClick={() => openEditItemDrawer(item)}
          />
        </StyledStoryItem>
      ))}
      <StyledItemAdder>
        <img
          src={ImagePlaceholderVertical}
          alt='placeholder'
          onClick={openAddItemDrawer}
        />
      </StyledItemAdder>
      <AddEditStoryItemDrawer
        open={subDrawer === 'storyItem'}
        onClose={hideSubDrawer}
        purpose={subPurpose}
        addItem={addItem}
        editItem={editItem}
        // FIXME:
        item={currentItem}
      />
    </StyledContainer>
  );
};
