import { IStoryReq, IStoryGetParams } from 'types';
import { http, methods } from './config';

export const getStories = (params: IStoryGetParams) => {
  const config = {
    method: methods.get,
    url: `/story`,
    params,
  };
  return http(config);
};

export const getStory = (storyId: string | number) => {
  const config = {
    method: methods.get,
    url: `/story/${storyId}`,
  };
  return http(config);
};

export const createStory = (data: IStoryReq) => {
  const config = {
    method: methods.post,
    url: `/story`,
    data,
  };
  return http(config);
};

export const updateStory = (data: IStoryReq) => {
  const config = {
    method: methods.put,
    url: `/story/${data.id}`,
    data,
  };
  return http(config);
};

export const deleteStory = (storyId: number | string) => {
  const config = {
    method: methods.delete,
    url: `/story/${storyId}`,
  };
  return http(config);
};
