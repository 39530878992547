import { FC, useState, useEffect } from 'react';
import { styled } from '@mui/system';
import { TextField, FormControlLabel } from '@mui/material';
import {
  Drawer,
  DrawerHeader,
  DrawerFormBody,
  ModalTitle,
  Button,
  StyledFlexBox,
  FormItem,
  StyledSwitch,
  ImageInput,
  HtmlEditor,
  VideoUpload,
} from 'UI';
import { CloseIcon } from 'assets';
import { COLORS, MEDIA_TYPES } from 'utils';
import { IInstructionReq } from 'types';
import {
  useCreateInstruction,
  useUpdateInstruction,
  useGetInstruction,
} from 'hooks';

const StyledSwitchFormControl = styled(FormControlLabel)({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  borderBottom: `1px solid ${COLORS.BLACK}`,
  margin: 0,
  paddingBottom: '0.375rem',
  '& .MuiFormControlLabel-label': {
    marginTop: '0.375rem',
    fontSize: '1rem',
    lineHeight: '1.5rem',
  },
});

const TRANSLATIONS = {
  add: {
    title: 'Новая инструкция',
  },
  edit: {
    title: 'Редактировать инструкцию',
  },
};

const DEFAULT_VALUES = {
  title: '',
  content: '',
  link: '',
  cover: '',
  // banner: '',
  is_active: true,
};

interface IAddEditInstructionDrawerProps {
  open: boolean;
  onClose: () => void;
  purpose: 'add' | 'edit';
  id: string;
}

export const AddEditInstructionDrawer: FC<IAddEditInstructionDrawerProps> = ({
  open,
  onClose,
  purpose = 'add',
  id = '',
}) => {
  const { data: instructionInfoData } = useGetInstruction(id);
  const [instructionInfo, setInstructionInfo] =
    useState<IInstructionReq>(DEFAULT_VALUES);

  const { mutateAsync: createInstruction } = useCreateInstruction();
  const { mutateAsync: updateInstruction } = useUpdateInstruction();

  useEffect(() => {
    if (purpose === 'edit') {
      setInstructionInfo({
        id: instructionInfoData?.id,
        title: instructionInfoData?.title || '',
        content: instructionInfoData?.content || '',
        link: instructionInfoData?.link || '',
        cover: instructionInfoData?.cover || '',
        // banner: instructionInfoData?.banner || '',
        is_active: !!instructionInfoData?.is_active,
      });
    } else {
      setInstructionInfo(DEFAULT_VALUES);
    }
  }, [purpose, instructionInfoData]);

  const handleFieldChange = <T extends keyof IInstructionReq>(
    fieldName: T,
    newValue: IInstructionReq[T],
  ) => {
    setInstructionInfo(prevInstructionInfo => ({
      ...prevInstructionInfo,
      [fieldName]: newValue,
    }));
  };

  const ignoreChanges = () => {
    setInstructionInfo(DEFAULT_VALUES);
    onClose();
  };

  const saveChanges = () => {
    if (purpose === 'add') {
      createInstruction(instructionInfo)
        .then(() => {
          onClose();
        })
        .catch(() => {});
    } else {
      updateInstruction(instructionInfo)
        .then(() => {
          onClose();
        })
        .catch(() => {});
    }
  };

  return (
    <Drawer open={open} onClose={ignoreChanges}>
      <DrawerHeader>
        <ModalTitle title={TRANSLATIONS[purpose].title} />
        <CloseIcon onClick={ignoreChanges} />
      </DrawerHeader>
      <DrawerFormBody>
        <h2>О вопросе</h2>
        <StyledFlexBox>
          <ImageInput
            inputId='instructionCover'
            title='Добавьте обложку'
            descriptionWithoutImage='Загрузите обложку (1125x675 px, до 1 мб)'
            withDescription
            image={instructionInfo.cover}
            setImage={newImg => handleFieldChange('cover', newImg)}
            mediaType={MEDIA_TYPES.INSTRUCTION_COVER}
            imageFixedWidth={1125}
            imageFixedHeight={675}
          />
          {/* <ImageInput
            inputId='instructionBanner'
            title='Добавьте баннер'
            descriptionWithoutImage='Загрузите баннер (512x512 px, до 1 мб)'
            withDescription
            image={instructionInfo.banner}
            setImage={newImg => handleFieldChange('banner', newImg)}
            mediaType={MEDIA_TYPES.INSTRUCTION_BANNER}
            imageFixedWidth={512}
            imageFixedHeight={512}
          /> */}
        </StyledFlexBox>
        <VideoUpload
          mediaType={MEDIA_TYPES.INSTRUCTION_VIDEO}
          saveVideoLink={(newValue: string) =>
            handleFieldChange('link', newValue)
          }
          videoSrc={instructionInfo.link}
        />
        <FormItem label='Название'>
          <TextField
            variant='standard'
            color='primary'
            placeholder='Введите название'
            name='title'
            value={instructionInfo.title}
            onChange={e => handleFieldChange('title', e.target.value)}
          />
        </FormItem>
        {/* <FormItem label='Ссылка'>
          <TextField
            variant='standard'
            color='primary'
            placeholder='Введите ссылку'
            name='link'
            value={instructionInfo.link}
            onChange={e => handleFieldChange('link', e.target.value)}
          />
        </FormItem> */}
        <FormItem label='Описание'>
          <HtmlEditor
            value={instructionInfo.content}
            onChange={newValue => handleFieldChange('content', newValue)}
            disabledToolbarOptions={['link']}
          />
        </FormItem>
        <StyledSwitchFormControl
          label='Использовать обложку в слайдере'
          labelPlacement='start'
          sx={{ marginBottom: '1.25rem' }}
          control={
            <StyledSwitch
              checked={instructionInfo.is_active}
              onChange={() =>
                handleFieldChange('is_active', !instructionInfo.is_active)
              }
            />
          }
        />
      </DrawerFormBody>
      <StyledFlexBox className='buttons fullDivWidth'>
        <Button
          onClick={ignoreChanges}
          variant='contained'
          size='large'
          color='customGray'
        >
          Отмена
        </Button>
        <Button variant='contained' size='large' onClick={saveChanges}>
          Сохранить
        </Button>
      </StyledFlexBox>
    </Drawer>
  );
};
