import { styled } from '@mui/system';
import { Box } from '@mui/material';
import { COLORS } from 'utils';

export const StyledContainer = styled(Box)({
  border: `1px solid rgba(0,0,0,0.1)`,
  borderRadius: '0.5rem',
  backgroundColor: COLORS.WHITE,
  '& .lists': {
    height: 'calc(100vh - 14rem)',
    overflowY: 'auto',
  },
  '& .bottomButton': {
    position: 'absolute',
    bottom: '1.875rem',
    right: '1.875rem',
    width: '15rem',
    marginLeft: 'auto',
    '& button': {
      width: '100%',
    },
  },
});

export const StyledHeader = styled(Box)({
  padding: '0.625rem',
  fontWeight: 600,
  fontSize: '1rem',
  lineHeight: '1.5rem',
  fontFamily: '"SF Pro Display", sans-serif',
  borderBottom: `1px solid rgba(0,0,0,0.1)`,
  borderTop: `1px solid rgba(0,0,0,0.1)`,
  marginTop: '-1px',
});

export const StyledUsersList = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  '& .participantsPaginationButton': {
    width: '15rem',
    margin: '1rem auto 2rem',
  },
});

export const StyledUserItem = styled(Box)({
  padding: '1rem 1.25rem',
  border: `1px solid rgba(0,0,0,0.1)`,
  display: 'flex',
  flexDirection: 'column',
  // alignItems: 'center',
  // gap: '1.875rem',
  '& .userInfo': {
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    width: '100%',
    paddingBottom: '0.625rem',
    '& .photo': {
      width: '3.125rem',
      height: '3.125rem',
      borderRadius: '50%',
      overflow: 'hidden',
      '& img': {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        objectPosition: 'center',
      },
    },
    '& .meta': {
      display: 'flex',
      flexDirection: 'column',
      '& div': {
        fontSize: '0.875rem',
        lineHeight: '1.375rem',
        fontFamily: '"SF Pro Display", sans-serif',
      },
      '& .name': {
        fontWeight: 600,
        width: 'max-content',
      },
      '& .phone': {
        opacity: 0.5,
        fontWeight: 400,
      },
    },
  },
  '& .place': {
    fontSize: '0.75rem',
    lineHeight: '1.125rem',
    fontFamily: '"SF Pro Display", sans-serif',
    fontWeight: 600,
    padding: '0.1875rem 0.125rem',
    borderRadius: '0.75rem',
    width: '1.5rem',
    height: '1.5rem',
    textAlign: 'center',
    '&-1': {
      backgroundColor: COLORS.GOLDEN,
    },
    '&-2': {
      backgroundColor: COLORS.SILVER,
    },
    '&-3': {
      backgroundColor: COLORS.BRONZE,
    },
  },
  '& .userStats': {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: '0.625rem',
    borderTop: `1px solid rgba(0,0,0,0.1)`,
    '& .stats': {
      fontWeight: 500,
      fontSize: '0.875rem',
      lineHeight: '1rem',
      fontFamily: '"SF Pro Display", sans-serif',
      opacity: 0.5,
      width: '100%',
    },
  },
  '& .buttonBox': {
    width: '35%',
    visibility: 'hidden',
    '& button': {
      width: '100%',
    },
  },
  '&:hover': {
    backgroundColor: 'rgba(0,0,0,0.05)',
    '& .buttonBox': {
      visibility: 'visible',
    },
  },
});

export const StyledWinOptions = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: COLORS.WHITE,
  borderRadius: '0.5rem',
  overflow: 'hidden',
  border: `1px solid rgba(0,0,0,0.1)`,
  width: '10rem',
  '& .option': {
    padding: '0.8125rem 0.9375rem',
    borderBottom: `1px solid rgba(0,0,0,0.1)`,
    fontSize: '1rem',
    lineHeight: '1.5rem',
    fontFamily: '"SF Pro Display", sans-serif',
    cursor: 'pointer',
    '&:last-child': {
      borderBottom: 'none',
    },
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.05)',
    },
  },
});

export const StyledNoPlacesLeft = styled(Box)({
  color: COLORS.RED,
  padding: '0.625rem 1rem',
});
