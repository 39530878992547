import { FC, useEffect, useState } from 'react';
import {
  Drawer,
  ModalTitle,
  StyledSwitch,
  Button,
  DrawerHeader,
  DrawerFormBody,
  StyledFlexBox,
} from 'UI';
import { CloseIcon, PlusIcon } from 'assets';
import {
  IShowcaseOfferDiscountBranch,
  IShowcaseOfferDiscountSale,
  IShowcaseOfferDiscountReq,
} from 'types';
import { COLORS, notify } from 'utils';
import {
  useCreateShowcaseOfferDiscount,
  useGetShowcaseOfferDiscount,
  useSearchParams,
  useUpdateShowcaseOfferDiscount,
  useUploadShowcaseBranchesFile,
  useUploadShowcaseFile,
} from 'hooks';
import { BranchCard, SaleCard } from 'components';
import {
  TRANSLATIONS,
  DEFAULT_VALUES,
  DEFAULT_BRANCH,
  DEFAULT_DISCOUNT,
} from './constants';
import {
  DrawerFormFields,
  DrawerFooter,
  DrawerTopSection,
  UploadBranches,
} from './components';
import { IOfferAndDiscountDrawerProps, IDiscountsFile } from './types';
import {
  preProcessDiscountsAndBranches,
  preProcessDiscountFiles,
  handleChange,
} from './utils';

export const OfferAndDiscountDrawer: FC<IOfferAndDiscountDrawerProps> = ({
  open,
  onClose,
  id = '',
  purpose = 'add',
}) => {
  const { getParam } = useSearchParams();
  const params = { id, category_id: getParam('productCategory') };
  const { data: productInfoData } = useGetShowcaseOfferDiscount(params);
  const [productInfo, setProductInfo] =
    useState<IShowcaseOfferDiscountReq>(DEFAULT_VALUES);

  const [branchesFile, setBranchesFile] = useState<File | null>(null);
  const [discountsFiles, setDiscountsFiles] = useState<IDiscountsFile[]>([]);

  const { mutateAsync: createProduct, isLoading: loadingCreate } =
    useCreateShowcaseOfferDiscount();
  const { mutateAsync: updateProduct, isLoading: loadingUpdate } =
    useUpdateShowcaseOfferDiscount();

  const {
    mutateAsync: uploadShowcaseBranchesFile,
    isLoading: loadingBranchFile,
  } = useUploadShowcaseBranchesFile();
  const { mutateAsync: uploadShowcaseFile, isLoading: loadingShowcaseFile } =
    useUploadShowcaseFile();

  useEffect(() => {
    if (purpose === 'edit') {
      setProductInfo({
        id: productInfoData?.id,
        category_id: productInfoData?.category_id || 0,
        new_category_id: 0,
        required_level: productInfoData?.required_level || 0,
        title: productInfoData?.title || '',
        description_html: productInfoData?.description_html || '',
        description_json: productInfoData?.description_json || '',
        // tags: productInfoData?.tags || [],
        cover_big: productInfoData?.cover_big || '',
        cover_mini: productInfoData?.cover_mini || '',
        is_active: !!productInfoData?.is_active,
        show_in_slider: false,
        position_in_slider: -1,
        // show_in_slider: !!productInfoData?.show_in_slider,
        // position_in_slider: productInfoData?.position_in_slider || 0,
        position_in_list: productInfoData?.position_in_list || 0,
        uri: productInfoData?.uri || '',
        type: productInfoData?.type || 0,
        price: productInfoData?.price || 0,
        discount_description: productInfoData?.discount_description || '',
        branches: productInfoData?.branches || [],
        discounts: productInfoData?.discounts || [],
        started_at: productInfoData?.started_at || '',
        expired_at: productInfoData?.expired_at || '',
        is_indefinite: !!productInfoData?.is_indefinite,
      });
    } else {
      setProductInfo(DEFAULT_VALUES);
    }
  }, [productInfoData, purpose]);

  const handleFieldChange = <T extends keyof IShowcaseOfferDiscountReq>(
    fieldName: T,
    newValue: IShowcaseOfferDiscountReq[T],
  ) => {
    handleChange(fieldName, newValue, setProductInfo, purpose);
  };

  const handleChangeBranches = <T extends keyof IShowcaseOfferDiscountBranch>(
    branchId: IShowcaseOfferDiscountBranch['id'],
    fieldName: T,
    newValue: IShowcaseOfferDiscountBranch[T],
  ) => {
    const branches = [...productInfo.branches];
    const requiredBranch = branches.find(branch => branch.id === branchId);
    if (!requiredBranch || !requiredBranch.id) return;
    requiredBranch[fieldName] = newValue;
    handleFieldChange('branches', branches);
  };

  const handleChangeDiscounts = <T extends keyof IShowcaseOfferDiscountSale>(
    disId: string | number,
    fieldName: T,
    newValue: IShowcaseOfferDiscountSale[T],
  ) => {
    const discounts = [...productInfo.discounts];
    const discount = discounts.find(dis => dis.id === disId);
    if (!discount || !discount.id) return;
    if (fieldName === 'type') {
      discount.value = '';
    }
    discount[fieldName] = newValue;
    handleFieldChange('discounts', discounts);
  };

  const saveChanges = () => {
    if (!productInfo.type) {
      notify('Выберите тип', 'error');
      return;
    }
    preProcessDiscountFiles(productInfo, discountsFiles, setDiscountsFiles);

    if (purpose === 'add') {
      createProduct(productInfo)
        .then(res => {
          const showcaseId = res?.item?.id;
          const discounts = res?.item?.discounts;
          Promise.all([
            uploadBranchesFile(showcaseId),
            uploadDiscountsFile(showcaseId, discounts),
          ]).then(() => onClose());
        })
        .catch(() => {});
    } else {
      const processedProduct = preProcessDiscountsAndBranches(productInfo);
      updateProduct(processedProduct)
        .then(res => {
          const showcaseId = res?.item?.id;
          const discounts = res?.item?.discounts;
          Promise.all([
            uploadBranchesFile(showcaseId),
            uploadDiscountsFile(showcaseId, discounts),
          ]).then(() => onClose());
        })
        .catch(() => {});
    }
  };

  const addBranch = () => {
    const branches = [...productInfo.branches];
    const minId = Math.min(...productInfo.branches.map(branch => +branch.id));
    if (minId > 0) {
      branches.push({
        ...DEFAULT_BRANCH,
        id: -1,
      });
      handleFieldChange('branches', branches);
      return;
    }
    branches.push({
      ...DEFAULT_BRANCH,
      id: minId - 1,
    });
    handleFieldChange('branches', branches);
  };

  const removeBranch = (branchId: IShowcaseOfferDiscountBranch['id']) => {
    const branches = [...productInfo.branches];
    const branchIndex = branches.findIndex(branch => branch.id === branchId);
    if (branchIndex === -1) return;
    branches.splice(branchIndex, 1);
    handleFieldChange('branches', branches);
  };

  const addDiscount = () => {
    const discounts = [...productInfo.discounts];
    const minId = Math.min(...productInfo.discounts.map(branch => +branch.id));
    if (minId > 0) {
      discounts.push({
        ...DEFAULT_DISCOUNT,
        id: -1,
      });
      handleFieldChange('discounts', discounts);
      return;
    }
    discounts.push({
      ...DEFAULT_DISCOUNT,
      id: minId - 1,
    });
    handleFieldChange('discounts', discounts);
  };

  const removeDiscount = (discountId: IShowcaseOfferDiscountSale['id']) => {
    const discounts = [...productInfo.discounts];
    const discountIndex = discounts.findIndex(
      branch => branch.id === discountId,
    );
    if (discountIndex === -1) return;
    discounts.splice(discountIndex, 1);
    handleFieldChange('discounts', discounts);
  };

  const saveBranchFile = (file: File) => {
    setBranchesFile(file);
  };

  const saveDiscountFile = (file: File, discountId: string | number) => {
    const isExist = discountsFiles.find(
      discountFile => discountFile.discount_id === discountId,
    );
    if (isExist) {
      const newFiles = discountsFiles.map(discountFile =>
        discountFile.discount_id === discountId
          ? { file, discount_id: discountId }
          : discountFile,
      );
      setDiscountsFiles(newFiles);
    } else {
      setDiscountsFiles([...discountsFiles, { file, discount_id: discountId }]);
    }
  };

  const uploadBranchesFile = (showcaseId: string) => {
    if (!branchesFile || !showcaseId) return;
    const formData = new FormData();
    formData.append('showcase_id', showcaseId);
    formData.append('branch_file', branchesFile);
    uploadShowcaseBranchesFile(formData)
      .then(() => {})
      .catch(() => {});
  };

  const uploadDiscountsFile = (
    showcaseId: string,
    discounts: IShowcaseOfferDiscountSale[],
  ) => {
    if (!discountsFiles.length) return;
    const formDatas = discountsFiles.map(discountFile => {
      let discId = +discountFile.discount_id;
      if (discId <= 0) {
        discId = +discounts[discountFile.index!].id;
      }
      const formData = new FormData();
      formData.append('showcase_id', showcaseId);
      formData.append('showcase_discount_id', discId.toString());
      formData.append('discount_file', discountFile.file!);
      return formData;
    });
    return Promise.all(formDatas.map(formData => uploadShowcaseFile(formData)))
      .then(() => {})
      .catch(() => {});
  };

  const ignoreChanges = () => {
    setProductInfo(DEFAULT_VALUES);
    onClose();
  };

  return (
    <Drawer open={open} onClose={ignoreChanges}>
      <DrawerHeader>
        <StyledFlexBox>
          <ModalTitle title={TRANSLATIONS[productInfo.type][purpose].title} />
        </StyledFlexBox>
        <StyledFlexBox>
          <StyledSwitch
            checked={productInfo.is_active}
            onChange={() =>
              handleFieldChange('is_active', !productInfo.is_active)
            }
            className='switch'
          />
          <CloseIcon onClick={ignoreChanges} />
        </StyledFlexBox>
      </DrawerHeader>

      <DrawerFormBody>
        <DrawerTopSection
          cover_big={productInfo.cover_big}
          cover_mini={productInfo.cover_mini}
          handleFieldChange={handleFieldChange}
        />
        <DrawerFormFields
          productInfo={productInfo}
          handleFieldChange={handleFieldChange}
        />
        <h2>Скидки</h2>
        {productInfo.discounts?.map(discount => (
          <SaleCard
            key={discount.id}
            {...discount}
            handleFieldChange={(field, value) =>
              handleChangeDiscounts(discount.id, field, value)
            }
            saveDiscountFile={saveDiscountFile}
            deleteDiscount={removeDiscount}
          />
        ))}
        <Button
          startIcon={<PlusIcon color={COLORS.BLUE} />}
          color='customLightBlue'
          size='large'
          onClick={addDiscount}
        >
          Добавить скидку
        </Button>
        <h2>Филиалы</h2>
        {productInfo.branches?.map(branch => (
          <BranchCard
            key={branch.id}
            {...branch}
            handleFieldChange={(branchId, field, value) =>
              handleChangeBranches(branchId, field, value)
            }
            deleteBranch={branchId => removeBranch(branchId)}
          />
        ))}
        <Button
          startIcon={<PlusIcon color={COLORS.BLUE} />}
          color='customLightBlue'
          size='large'
          onClick={addBranch}
        >
          Добавить филиал
        </Button>
        <UploadBranches handleFileUpload={saveBranchFile} />
      </DrawerFormBody>
      <DrawerFooter
        purpose={purpose}
        isLoading={
          loadingCreate ||
          loadingUpdate ||
          loadingBranchFile ||
          loadingShowcaseFile
        }
        productInfo={productInfo}
        saveChanges={saveChanges}
        ignoreChanges={ignoreChanges}
        onClose={onClose}
      />
    </Drawer>
  );
};
