import { MediaType, MediaTypeKeys } from 'types';

export interface IColorPalette {
  RED: string;
  RED_2: string;
  BLUE: string;
  LIGHT_BLUE: string;
  GREEN: string;
  BLACK: string;
  WHITE: string;
  GOLDEN: string;
  SILVER: string;
  BRONZE: string;
  GRAY: string;
  DISABLED_GRAY: string;
  ORANGE: string;
  MUTED: string;
}

export const COLORS: IColorPalette = {
  RED: '#EB5858',
  RED_2: '#CE3548',
  BLUE: '#0080ff',
  LIGHT_BLUE: 'rgba(0, 113, 218, 0.05)',
  GREEN: '#00AB07',
  BLACK: '#000000',
  WHITE: '#ffffff',
  GOLDEN: '#ffd600',
  SILVER: '#d9d9d9',
  BRONZE: '#e3cec1',
  GRAY: '#F5F5F5',
  DISABLED_GRAY: 'rgba(0,0,0,0.2)',
  ORANGE: '#FF8E00',
  MUTED: '#A0A0A0',
};

export const CREATE_CHALLENGE_DRAWER_STEPS = {
  MAIN_INFO: 'main-info',
  ADD_TRAININGS: 'add-trainings',
  ADD_PRIZES: 'add-prizes',
  ADD_SPONSORS: 'add-sponsors',
  CREATE_PRIZE: 'create-prize',
  CREATE_ACHIEVEMENT: 'create-achievement',
  CREATE_SPONSOR: 'create-sponsor',
  VIEW_TRAININGS: 'view-trainings',
  VIEW_USERS: 'view-users',
  VIEW_PRIZES: 'view-prizes',
};

export const MEDIA_TYPES = {
  MARATHON: 'marathon',
  NEWS: 'news',
  SPONSOR_LOGO: 'sponsor_logo',
  SPONSOR_COVER: 'sponsor_cover',
  TROPHY_COVER: 'trophy_cover',
  TROPHY_ITEM_COVER: 'trophy_item_cover',
  SPACE_LOGO: 'space_logo',
  SPACE_COVER: 'space_cover',
  NEWS_COVER: 'news_cover',
  NEWS_CONTENT: 'news_content',
  GROUP_LOGO: 'group_logo',
  GROUP_COVER: 'group_cover',
  TEAM_LOGO: 'team',
  USER: 'user',
  TRAINING: 'training',
  PROGRAM_ICON: 'program_icon',
  PROGRAM_COVER: 'program_cover',
  ACHIEVEMENT: 'achievement',
  FAQ: 'faq',
  SHOWCASE_COVER_BIG: 'showcase_cover_big',
  SHOWCASE_COVER_MINI: 'showcase_cover_mini',
  SHOWCASE_QR: 'showcase_qr',
  PRODUCT_COVER: 'product_cover',
  PRODUCT_IMAGE: 'product_image',
  INSTRUCTION_COVER: 'instruction_cover',
  INSTRUCTION_BANNER: 'instruction_banner',
  INSTRUCTION_VIDEO: 'instruction_video',
  ME_AND_GTO_COVER: 'me_and_gto_cover',
  ME_AND_GTO_VIDEO: 'me_and_gto_video',
  BANNERS: 'banners',
  NEWS_COVER_VERTICAL: 'news_cover_vertical',
  SHOWCASE_CONTENT: 'showcase_content',
  MARATHON_CONTENT: 'marathon_content',
  STORIES_COVER: 'stories_cover',
  STORIES_SLIDE_IMAGE: 'stories_slide_image',
  STORIES_SLIDE_VIDEO: 'stories_slide_video',
} as Record<MediaTypeKeys, MediaType>;

export const PRIZE_TYPES = ['Приз', 'Достижение', 'Награда', 'Награда'];

export const PRIZ_POINTS_URL = process.env.REACT_APP_PRIZ_POINTS_URL;

export const PRIZ_COINS_URL = process.env.REACT_APP_PRIZ_COINS_URL;

export const DEFAULT_PRODUCT_COVER_URL =
  'https://storage.yandexcloud.net/gto-files/storage/product_no_image_gray.png';

export type USERS_ROLES = 'ADMINSTRATOR' | 'MODERATOR' | 'USER';

export const ROLES: Record<USERS_ROLES, number> = {
  ADMINSTRATOR: 1,
  MODERATOR: 2,
  USER: 3,
};

export const WORKOUTS_CALENDAR_DAY_LIMIT = 15;
