export const LinkClickIcon = () => {
  return (
    <svg
      width='31'
      height='30'
      viewBox='0 0 31 30'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M18.25 14.9975C18.25 11.8825 15.3538 8.75 11.8213 8.75H9.67875C6.12875 8.75 3.25 11.5475 3.25 14.9975C3.25 17.97 5.3875 20.4575 8.25 21.0888C8.71922 21.1921 9.19829 21.244 9.67875 21.2438'
        stroke='#0080FF'
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.25 14.9975C13.25 18.1113 16.1462 21.2438 19.6787 21.2438H21.8213C25.3713 21.2438 28.25 18.4475 28.25 14.9975C28.25 12.0238 26.1125 9.535 23.25 8.905C22.7808 8.80172 22.3017 8.74975 21.8213 8.75'
        stroke='#0080FF'
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
