import { FC, useState, useEffect } from 'react';
import { styled } from '@mui/system';
import { TextField, FormControlLabel } from '@mui/material';
import {
  Drawer,
  DrawerHeader,
  DrawerFormBody,
  ModalTitle,
  Button,
  StyledFlexBox,
  FormItem,
  StyledSwitch,
  ImageInput,
  HtmlEditor,
  VideoUpload,
} from 'UI';
import { CloseIcon } from 'assets';
import { COLORS, MEDIA_TYPES } from 'utils';
import { IMeAndGtoReq } from 'types';
import { useCreateMeAndGto, useUpdateMeAndGto, useGetMeAndGto } from 'hooks';

const StyledSwitchFormControl = styled(FormControlLabel)({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  borderBottom: `1px solid ${COLORS.BLACK}`,
  margin: 0,
  paddingBottom: '0.375rem',
  '& .MuiFormControlLabel-label': {
    marginTop: '0.375rem',
    fontSize: '1rem',
    lineHeight: '1.5rem',
  },
});

const TRANSLATIONS = {
  add: {
    title: 'Новый "Как подготовиться к нормативам"',
  },
  edit: {
    title: 'Редактировать "Как подготовиться к нормативам"',
  },
};

const DEFAULT_VALUES = {
  title: '',
  content: '',
  link: '',
  cover: '',
  is_active: true,
};

interface IAddEditMeAndGtoDrawerProps {
  open: boolean;
  onClose: () => void;
  purpose: 'add' | 'edit';
  id: string;
}

export const AddEditMeAndGtoDrawer: FC<IAddEditMeAndGtoDrawerProps> = ({
  open,
  onClose,
  purpose = 'add',
  id = '',
}) => {
  const { data: meAndGtoInfoData } = useGetMeAndGto(id);
  const [meAndGtoInfo, setMeAndGtoInfo] =
    useState<IMeAndGtoReq>(DEFAULT_VALUES);

  const { mutateAsync: createMeAndGto } = useCreateMeAndGto();
  const { mutateAsync: updateMeAndGto } = useUpdateMeAndGto();

  useEffect(() => {
    if (purpose === 'edit') {
      setMeAndGtoInfo({
        id: meAndGtoInfoData?.id,
        title: meAndGtoInfoData?.title || '',
        content: meAndGtoInfoData?.content || '',
        link: meAndGtoInfoData?.link || '',
        cover: meAndGtoInfoData?.cover || '',
        is_active: !!meAndGtoInfoData?.is_active,
      });
    } else {
      setMeAndGtoInfo(DEFAULT_VALUES);
    }
  }, [purpose, meAndGtoInfoData]);

  const handleFieldChange = <T extends keyof IMeAndGtoReq>(
    fieldName: T,
    newValue: IMeAndGtoReq[T],
  ) => {
    setMeAndGtoInfo(prevMeAndGtoInfo => ({
      ...prevMeAndGtoInfo,
      [fieldName]: newValue,
    }));
  };

  const ignoreChanges = () => {
    setMeAndGtoInfo(DEFAULT_VALUES);
    onClose();
  };

  const saveChanges = () => {
    if (purpose === 'add') {
      createMeAndGto(meAndGtoInfo)
        .then(() => {
          onClose();
        })
        .catch(() => {});
    } else {
      updateMeAndGto(meAndGtoInfo)
        .then(() => {
          onClose();
        })
        .catch(() => {});
    }
  };

  return (
    <Drawer open={open} onClose={ignoreChanges}>
      <DrawerHeader>
        <ModalTitle title={TRANSLATIONS[purpose].title} />
        <CloseIcon onClick={ignoreChanges} />
      </DrawerHeader>
      <DrawerFormBody>
        <h2>О вопросе</h2>
        <StyledFlexBox>
          <ImageInput
            inputId='meAndGtoCover'
            title='Добавьте обложку'
            descriptionWithoutImage='Загрузите обложку (1125x675 px, до 1 мб)'
            withDescription
            image={meAndGtoInfo.cover}
            setImage={newImg => handleFieldChange('cover', newImg)}
            mediaType={MEDIA_TYPES.INSTRUCTION_COVER}
            imageFixedWidth={1125}
            imageFixedHeight={675}
          />
        </StyledFlexBox>
        <VideoUpload
          mediaType={MEDIA_TYPES.INSTRUCTION_VIDEO}
          saveVideoLink={(newValue: string) =>
            handleFieldChange('link', newValue)
          }
          videoSrc={meAndGtoInfo.link}
        />
        <FormItem label='Название'>
          <TextField
            variant='standard'
            color='primary'
            placeholder='Введите название'
            name='title'
            value={meAndGtoInfo.title}
            onChange={e => handleFieldChange('title', e.target.value)}
          />
        </FormItem>
        <FormItem label='Описание'>
          <HtmlEditor
            value={meAndGtoInfo.content}
            onChange={newValue => handleFieldChange('content', newValue)}
            disabledToolbarOptions={['link']}
          />
        </FormItem>
        <StyledSwitchFormControl
          label='Использовать обложку в слайдере'
          labelPlacement='start'
          sx={{ marginBottom: '1.25rem' }}
          control={
            <StyledSwitch
              checked={meAndGtoInfo.is_active}
              onChange={() =>
                handleFieldChange('is_active', !meAndGtoInfo.is_active)
              }
            />
          }
        />
      </DrawerFormBody>
      <StyledFlexBox className='buttons fullDivWidth'>
        <Button
          onClick={ignoreChanges}
          variant='contained'
          size='large'
          color='customGray'
        >
          Отмена
        </Button>
        <Button variant='contained' size='large' onClick={saveChanges}>
          Сохранить
        </Button>
      </StyledFlexBox>
    </Drawer>
  );
};
