import { FC, useMemo, MouseEvent } from 'react';
import { styled } from '@mui/system';
import { Box, TextField } from '@mui/material';
import { PlusIcon } from 'assets';
import { COLORS, MEDIA_TYPES } from 'utils';
import {
  FormItem,
  MultiImageUploader,
  StyledFlexBox,
  Button,
  FullColorPicker,
} from 'UI';
import { IProductAttributeValue, IProductImage } from 'types';

const StyledContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  padding: '1.875rem',
  border: `1px solid ${COLORS.BLACK}`,
  borderRadius: '0.5rem',
  gap: '1rem',
  '& .title': {
    fontSize: '1rem',
    fontFamily: '"SF Pro Display", sans-serif',
    fontWeight: 600,
    color: COLORS.BLACK,
  },
  '& .paletteIcon': {
    cursor: 'pointer',
  },
  '& .row': {
    display: 'flex',
    gap: '1rem',
    width: '100%',
    '& .column': {
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem',
      width: '50%',
    },
  },
});

interface IAttributeCardProps {
  index: number;
  images: Array<IProductImage>;
  values: Array<IProductAttributeValue>;
  addImage: (_attrIndex: number, _newImages: string[]) => void;
  removeImage: (_attrIndex: number, _newImg: string) => void;
  handleChangeValueParameter: <T extends keyof IProductAttributeValue>(
    _attrIndex: number,
    _valIndex: number,
    _fieldName: T,
    _newValue: IProductAttributeValue[T],
  ) => void;
  openPopover: (_e: MouseEvent<HTMLButtonElement>, _attrIndex: number) => void;
}

export const AttributeCard: FC<IAttributeCardProps> = ({
  index,
  images,
  values,
  addImage,
  removeImage,
  handleChangeValueParameter,
  openPopover,
}) => {
  const multiUploaderImages: string[] = useMemo(() => {
    return images?.map(image => image.url) || [];
  }, [images]);

  return (
    <StyledContainer>
      <Box className='title'>Атрибуты №{index + 1}</Box>
      <MultiImageUploader
        images={multiUploaderImages}
        addImage={newImgs => addImage(index, newImgs)}
        removeImage={removedImg => removeImage(index, removedImg)}
        inputId={`shopProductImages-${index}`}
        mediaType={MEDIA_TYPES.PRODUCT_IMAGE}
      />
      <Box className='row'>
        <Box className='column'>
          {values
            .filter(val => val.type === 1)
            .map((val, valueIndex) => (
              <FullColorPicker
                color={val.value}
                changeColor={newColor =>
                  handleChangeValueParameter(
                    index,
                    valueIndex,
                    'value',
                    newColor,
                  )
                }
              />
            ))}
        </Box>
        <Box className='column'>
          {values
            .filter(val => val.type === 2)
            .map((val, valueIndex) => (
              <FormItem label={val.title || 'Свойство'}>
                <TextField
                  variant='standard'
                  color='primary'
                  placeholder='Введите значение атрибута'
                  name='value'
                  value={val.value}
                  onChange={e =>
                    handleChangeValueParameter(
                      index,
                      valueIndex + 1,
                      'value',
                      e.target.value,
                    )
                  }
                />
              </FormItem>
            ))}
        </Box>
      </Box>
      <StyledFlexBox className='fullDivWidth'>
        <Box />
        <Button
          startIcon={<PlusIcon color={COLORS.BLUE} />}
          color='customLightBlue'
          className='addButton'
          onClick={e => openPopover(e, index)}
        >
          Добавить свойство
        </Button>
      </StyledFlexBox>
    </StyledContainer>
  );
};
