import { FC, ReactNode } from 'react';
import { styled } from '@mui/system';
import {
  Avatar,
  Box,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
} from '@mui/material';
import {
  IAnalyticsTopListCard,
  AnalyticsChallengesTopFilterParams,
} from 'types';
import { COLORS, formatNumber } from 'utils';
import { Button } from 'UI';
import { CaloriesIcon, DistanceIcon, RightIcon, StepsIcon } from 'assets';

const StyledContainer = styled(Paper)({
  backgroundColor: COLORS.WHITE,
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  '& .header': {
    padding: '1rem 1.25rem',
    fontFamily: '"SF Pro Display", sans-serif',
    '& h4': {
      fontSize: '1.25rem',
      fontWeight: 600,
      marginBottom: '0.25rem',
    },
    '& p': {
      fontSize: '0.875rem',
      color: COLORS.MUTED,
      lineHeight: '1.4375rem',
      fontWeight: 500,
    },
    borderBottom: `1px solid rgba(0,0,0,0.1)`,
  },
  '& .list': {
    padding: '0 1.25rem',
  },
  '& .listItem': {
    padding: '0.75rem',
    justifyContent: 'space-between',
    '&:not(:last-of-type)': {
      borderBottom: '1px solid rgba(0,0,0,0.05)',
    },
    '& .info': {
      display: 'flex',
      alignItems: 'center',
      gap: '0.625rem',
      '& div': {
        margin: 0,
      },
    },
    '& .avatar': {
      minWidth: 'auto',
    },
    '& .name': {
      fontSize: '0.875rem',
      fontWeight: 500,
      fontFamily: '"SF Pro Display", sans-serif',
    },
    '& .value': {
      fontSize: '0.75rem',
      color: COLORS.MUTED,
      fontWeight: 400,
    },
    '& .place': {
      borderRadius: '6.25rem',
      padding: '0.25rem 0.75rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: '0.75rem',
      fontWeight: 600,
      fontFamily: '"SF Pro Display", sans-serif',
      minWidth: '4.5rem',
      '&-1': {
        backgroundColor: COLORS.GOLDEN,
      },
      '&-2': {
        backgroundColor: COLORS.SILVER,
      },
      '&-3': {
        backgroundColor: COLORS.BRONZE,
      },
      '&-4': {
        backgroundColor: COLORS.GRAY,
      },
      '&-5': {
        backgroundColor: COLORS.GRAY,
      },
    },
  },
  '& .bottomButton': {
    '& a': {
      padding: '1.25rem',
      width: '100%',
      display: 'flex',
    },
  },
});

const SubNavbarContainer = styled(Box)({
  paddingTop: '0.5rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  gap: '1rem',
  '& button': {
    padding: '0.5rem 0.625rem',
    width: '100%',
    border: '1px solid rgba(0, 0, 0, 0.05)',
    '&.active': {
      backgroundColor: '#0000000D',
    },
  },
});

interface IAnalyticsListCardLargeProps {
  title: string;
  description: string;
  listItems: IAnalyticsTopListCard[];
  link: string;
  unity: string;
  children?: ReactNode;
}

export const AnalyticsListCardLarge: FC<IAnalyticsListCardLargeProps> = ({
  title,
  description,
  listItems,
  link,
  unity,
  children,
}) => {
  return (
    <StyledContainer>
      <Box className='header'>
        <h4>{title}</h4>
        <p>{description}</p>
        <Box>{children}</Box>
      </Box>
      <List className='list'>
        {listItems.map((item, index) => (
          <ListItem key={item.name} className='listItem'>
            <Box className='info'>
              <ListItemAvatar className='avatar'>
                <Avatar alt={item.name} src={item.photo} />
              </ListItemAvatar>
              <Box>
                <ListItemText className='name'>{item.name}</ListItemText>
                <ListItemText className='value'>
                  {formatNumber(item.value)} {unity}
                </ListItemText>
              </Box>
            </Box>
            <Box className={`place place-${index + 1}`}>{index + 1} место</Box>
          </ListItem>
        ))}
      </List>
      <Box className='bottomButton'>
        <Button
          href={link}
          variant='text'
          color='primary'
          endIcon={<RightIcon />}
        >
          Посмотреть все
        </Button>
      </Box>
    </StyledContainer>
  );
};

interface IAnalyticsListCardLargeChallengeSubNavbarProps {
  activeTab: number;
  changeTab: (_tabIndex: AnalyticsChallengesTopFilterParams) => void;
}

export const AnalyticsListCardLargeChallengeSubNavbar: FC<
  IAnalyticsListCardLargeChallengeSubNavbarProps
> = ({ activeTab, changeTab }) => {
  return (
    <SubNavbarContainer>
      <Button
        className={activeTab === 4 ? 'active' : ''}
        color='customWhite'
        startIcon={<StepsIcon />}
        onClick={() => changeTab(4)}
      >
        Шаги
      </Button>
      <Button
        className={activeTab === 3 ? 'active' : ''}
        color='customWhite'
        startIcon={<CaloriesIcon />}
        onClick={() => changeTab(3)}
      >
        Калории
      </Button>
      <Button
        className={activeTab === 1 ? 'active' : ''}
        color='customWhite'
        startIcon={<DistanceIcon />}
        onClick={() => changeTab(1)}
      >
        Км
      </Button>
    </SubNavbarContainer>
  );
};
