import { FC } from 'react';
import { PageHeader, GlobalSearch } from 'UI';

interface IEventLogHeaderProps {}

export const EventLogHeader: FC<IEventLogHeaderProps> = () => {
  return (
    <PageHeader title='Лог событий'>
      <GlobalSearch size='medium' />
    </PageHeader>
  );
};
