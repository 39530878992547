import { FC, ReactNode } from 'react';
import { styled } from '@mui/system';
import { Box } from '@mui/material';

const StyledLayout = styled(Box)({
  width: '100%',
  minHeight: '100vh',
  padding: '2.5rem 3.75rem',
});

interface IFullAnalyticsLayoutProps {
  children: ReactNode;
}

export const FullAnalyticsLayout: FC<IFullAnalyticsLayoutProps> = ({
  children,
}) => {
  return <StyledLayout>{children}</StyledLayout>;
};
