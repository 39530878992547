import { FC, MouseEvent } from 'react';
import { TableHead, TableRow, TableCell, TableSortLabel } from '@mui/material';
import {
  IAppUser,
  IHeadCell,
  ITableHeadPropsWithSort,
  UserSortParams,
} from 'types';
import { COLORS, UsersSortColumnValues } from 'utils';

const headCells: readonly IHeadCell<IAppUser>[] = [
  {
    id: 'firstname',
    label: 'Пользователь',
    sortable: true,
  },
  {
    id: 'created_at',
    label: 'Дата создания',
    sortable: true,
  },
  {
    id: 'points_count',
    label: 'Баллы',
    sortable: false,
  },
  {
    id: 'actions',
    label: 'Управление',
    sortable: false,
  },
];

export const UsersTableHead: FC<ITableHeadPropsWithSort<UserSortParams>> = ({
  onRequestSort,
  order,
  orderBy,
}) => {
  const isValidSortParam = (param: string): param is UserSortParams => {
    return UsersSortColumnValues.includes(param as UserSortParams);
  };

  const createSortHandler =
    (property: keyof IAppUser | 'actions') => (event: MouseEvent<unknown>) => {
      if (isValidSortParam(property)) {
        onRequestSort(event, property);
      }
    };
  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => {
          if (!headCell.sortable)
            return (
              <TableCell key={headCell.id} align='center' padding='normal'>
                {headCell.label}
              </TableCell>
            );
          return (
            <TableCell
              key={headCell.id}
              align='center'
              padding='normal'
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
                sx={{
                  '&.Mui-active': {
                    '& .MuiTableSortLabel-icon': {
                      color: orderBy === headCell.id ? COLORS.RED : undefined,
                    },
                  },
                  '& .MuiTableSortLabel-icon': {
                    opacity: 0.3,
                  },
                }}
              >
                {headCell.label}
              </TableSortLabel>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};
