import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { COLORS } from 'utils';

export const StyledHeader = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  gap: '1rem',
  '& img': {
    width: '5rem',
    height: '5rem',
    borderRadius: '50%',
    overflow: 'hidden',
    backgroundColor: COLORS.WHITE,
    objectFit: 'contain',
  },
  '& .name': {
    fontSize: '1.25rem',
    lineHeight: '1.625rem',
    fontWeight: 600,
    fontFamily: '"SF Pro Display", sans-serif',
    textAlign: 'center',
  },
  '& .role': {
    fontSize: '1rem',
    lineHeight: '1.5rem',
    fontWeight: 500,
    fontFamily: '"SF Pro Display", sans-serif',
    textAlign: 'center',
    opacity: 0.5,
  },
});

export const StyledBody = styled(Box)({
  padding: '1.25rem 0',
  display: 'flex',
  flexWrap: 'wrap',
  gap: '1.25rem',
  '& .column': {
    width: 'calc((100% - 1.25rem) / 2)',
    display: 'flex',
    flexDirection: 'column',
    gap: '1.25rem',
  },
  '& .row': {
    width: '100%',
    display: 'flex',
    gap: '1.25rem',
  },
  '& .line': {
    border: `1px solid rgba(0, 0, 0, 0.10);`,
    width: '100%',
  },
});

export const StyledLabel = styled(Box)({
  fontSize: '0.75rem',
  fontFamily: '"SF Pro Display", sans-serif',
  lineHeight: '1.25rem',
  opacity: 0.5,
});

export const StyledMiniCard = styled(Box)({
  padding: '0.75rem 1rem',
  backgroundColor: COLORS.WHITE,
  borderRadius: '0.5rem',
  width: '100%',
  fontSize: '1rem',
  fontFamily: '"SF Pro Display", sans-serif',
  lineHeight: '1.625rem',
  fontWeight: 500,
  display: 'flex',
  alignItems: 'center',
  gap: '0.5rem',
  minHeight: '3.125rem',
});

export const StyledCard = styled(Box)({
  width: '100%',
  borderRadius: '0.5rem',
  padding: '0.75rem 1rem',
  backgroundColor: COLORS.WHITE,
  border: `1px solid ${COLORS.WHITE}`,
  display: 'flex',
  flexDirection: 'column',
  gap: '0.25rem',
  '& .title': {
    fontSize: '0.75rem',
    lineHeight: '1.25rem',
    fontWeight: 500,
    fontFamily: '"SF Pro Display", sans-serif',
    opacity: 0.5,
  },
  '& .value': {
    fontSize: '1rem',
    lineHeight: '1.5rem',
    fontWeight: 500,
    fontFamily: '"SF Pro Display", sans-serif',
  },
});
