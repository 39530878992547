import { FC, useState, useEffect } from 'react';
import {
  Drawer,
  DrawerHeader,
  ModalTitle,
  StyledFlexBox,
  Button,
  FormItem,
  DrawerFormBody,
  DateInput,
} from 'UI';
import {
  IMarathonSpaceCalendar,
  IMarathonSpaceCalendarItem,
  IWorkout,
} from 'types';
import { isStringNumber } from 'utils';
import { useGetMarathon, useUpdateMarathonCalendar } from 'hooks';
import { CloseIcon } from 'assets';
import { CalendarItem } from './CalendarItem';

const DEFAULT_TRAINING_ITEM = {
  type: 1,
  value: 0,
  points: 0,
  position: 1,
  description: '',
};

const NUMBER_PARAMETERS = ['points', 'value'];

interface ICreateChallengeAddTrainingsProps {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
  goPreviousStep: () => void;
  marathonId: string;
}

export const CreateChallengeAddTrainings: FC<
  ICreateChallengeAddTrainingsProps
> = ({ open, onClose, onSubmit, goPreviousStep, marathonId }) => {
  // main calendar object, that all work will be done on
  const [calendarItems, setCalendarItems] = useState<IMarathonSpaceCalendar[]>(
    [],
  );
  const { data: marathonInfo } = useGetMarathon(marathonId);

  const { mutateAsync: updateCalendar } = useUpdateMarathonCalendar();

  useEffect(() => {
    setCalendarItems(marathonInfo?.calendar ?? []);
    return () => {
      setCalendarItems([]);
    };
  }, [marathonId, marathonInfo]);

  const moveUpCalendarItem = (day_in_calendar?: string) => {
    const updatedCalendar = [...calendarItems];
    const goingUpItem = updatedCalendar.find(
      calItem => calItem.day_in_calendar === day_in_calendar,
    );
    if (!goingUpItem) return;
    const goingDownItem =
      updatedCalendar[updatedCalendar.indexOf(goingUpItem) - 1];
    if (goingUpItem && goingDownItem) {
      const copyOfGoingUpItem = { ...goingUpItem };
      goingUpItem.points = goingDownItem.points;
      goingUpItem.items = [...goingDownItem.items];
      goingDownItem.points = copyOfGoingUpItem.points;
      goingDownItem.items = [...copyOfGoingUpItem.items];
      setCalendarItems(updatedCalendar);
    }
  };

  const moveDownCalendarItem = (day_in_calendar?: string) => {
    const updatedCalendar = [...calendarItems];
    const goingDownItem = updatedCalendar.find(
      calItem => calItem.day_in_calendar === day_in_calendar,
    );
    if (!goingDownItem) return;
    const goingUpItem =
      updatedCalendar[updatedCalendar.indexOf(goingDownItem) + 1];
    if (goingUpItem && goingDownItem) {
      const copyOfGoingUpItem = { ...goingUpItem };
      goingUpItem.points = goingDownItem.points;
      goingUpItem.items = [...goingDownItem.items];
      goingDownItem.points = copyOfGoingUpItem.points;
      goingDownItem.items = [...copyOfGoingUpItem.items];
      setCalendarItems(updatedCalendar);
    }
  };

  const addTraining = (day_in_calendar: string, training: IWorkout) => {
    const updatedCalendar = [...calendarItems];
    const updatedDayItem = updatedCalendar.find(
      calItem => calItem.day_in_calendar === day_in_calendar,
    );
    if (updatedDayItem) {
      updatedDayItem.items.push({
        ...DEFAULT_TRAINING_ITEM,
        training,
      });
      setCalendarItems(updatedCalendar);
    }
  };

  const deleteTraining = (
    day_in_calendar: string,
    trainingId: number,
    position: number,
  ) => {
    const updatedCalendar = [...calendarItems];
    const updatedDayItem = updatedCalendar.find(
      calItem => calItem.day_in_calendar === day_in_calendar,
    );
    if (updatedDayItem) {
      const elementHaveToBeRemoved = updatedDayItem.items[position];
      if (elementHaveToBeRemoved.training.id === trainingId) {
        updatedDayItem.items.splice(position, 1);
        updatedDayItem.points -= +elementHaveToBeRemoved.points;
      }

      setCalendarItems(updatedCalendar);
    }
  };

  const changeTrainingParameter = <T extends keyof IMarathonSpaceCalendarItem>(
    day_in_calendar: string,
    trainingIndex: number,
    fieldName: T,
    newValue: IMarathonSpaceCalendarItem[T],
  ) => {
    const updatedCalendar = [...calendarItems];
    const updatedDayItem = updatedCalendar.find(
      calItem => calItem.day_in_calendar === day_in_calendar,
    );
    if (updatedDayItem) {
      if (NUMBER_PARAMETERS.includes(fieldName) && !isStringNumber(newValue)) {
        return;
      }
      // Updates the points of the day
      if (fieldName === 'points') {
        updatedDayItem.points =
          updatedDayItem.points +
          +newValue! -
          +updatedDayItem.items[trainingIndex].points;
      }
      updatedDayItem.items[trainingIndex][fieldName] = newValue;
      setCalendarItems(updatedCalendar);
    }
  };

  const ignoreChanges = () => {
    onClose();
  };

  const saveChanges = () => {
    const submittedCalendarItems = calendarItems.map(calItem => {
      return {
        ...calItem,
        items: calItem.items.map((item, index) => {
          return {
            position: index,
            training_id: item.training.id!,
            ...item,
          };
        }),
      };
    });
    updateCalendar({
      marathon_id: marathonId,
      data: submittedCalendarItems,
    })
      .then(() => {
        onSubmit();
      })
      .catch(() => {});
  };

  return (
    <Drawer maxWidth='50vw' open={open} onClose={ignoreChanges}>
      <DrawerHeader>
        <ModalTitle title='Календарь вызова' />
        <CloseIcon onClick={ignoreChanges} />
      </DrawerHeader>
      <DrawerFormBody>
        <h2>Период вызова</h2>
        <StyledFlexBox className='fullDivWidth'>
          <FormItem label='Дата начала'>
            <DateInput
              key={marathonInfo?.start_date}
              stringValue={marathonInfo?.start_date}
              format='YYYY-MM-DD'
              readOnly
            />
          </FormItem>
          <FormItem label='Дата завершения'>
            <DateInput
              key={marathonInfo?.end_date}
              stringValue={marathonInfo?.end_date}
              format='YYYY-MM-DD'
              readOnly
            />
          </FormItem>
        </StyledFlexBox>
        <h2>Задания</h2>
        {calendarItems.map(item => (
          <CalendarItem
            key={item.day_index || item.points}
            moveUp={() => moveUpCalendarItem(item.day_in_calendar)}
            moveDown={() => moveDownCalendarItem(item.day_in_calendar)}
            addTraining={addTraining}
            deleteTraining={deleteTraining}
            handleChangeParameter={changeTrainingParameter}
            {...item}
          />
        ))}
      </DrawerFormBody>
      <StyledFlexBox className='buttons fullDivWidth'>
        <Button
          variant='contained'
          size='large'
          onClick={goPreviousStep}
          color='customGray'
        >
          Назад
        </Button>
        <Button
          variant='contained'
          size='large'
          color='primary'
          onClick={saveChanges}
        >
          Далее
        </Button>
      </StyledFlexBox>
    </Drawer>
  );
};
