import * as Pages from 'pages';
import { ROLES } from 'utils';
import { IRoutes } from '../types';

export const Spaces: IRoutes = {
  spaces: {
    path: '/spaces',
    title: 'spaces',
    element: <Pages.Spaces />,
    roles: [ROLES.ADMINSTRATOR],
  },
};
