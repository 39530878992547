import { FC, useState, useMemo, MouseEvent, useContext } from 'react';
import { TableBody, TableCell, Box } from '@mui/material';

import { UserContext, UserContextType } from 'app';
import { EditIcon, DeleteIcon } from 'assets';
import { Pagination, StyledSwitch } from 'UI';
import {
  useGetAchievements,
  useDeleteAchievement,
  useSearchParams,
  useChangeStatus,
} from 'hooks';
import { IAchievement, TableRowsOrder, AchievementSortParams } from 'types';
import { DeleteModal, ACHIEVEMENT_TYPE_OPTIONS } from 'components';

import {
  StyledBox,
  StyledPaper,
  StyledTableContainer,
  StyledTable,
  StyledTableRow,
  StyledAchievementInfo,
  StyledAvatar,
  StyledAchievementInfoAtModal,
} from './styledComponents';
import { AchievementsTableHead } from './AchievementsTableHead';

export const AchievementsTable: FC = () => {
  const { space_id } = useContext(UserContext) as UserContextType;
  const [order, setOrder] = useState<TableRowsOrder>('desc');
  const [orderBy, setOrderBy] = useState<AchievementSortParams>('created_at');
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [selectedAchievement, setSelectedAchievement] = useState<
    IAchievement | undefined
  >(undefined);
  const { setParam, getParam, searchParams } = useSearchParams();

  const params = useMemo(() => {
    return {
      page: getParam('page'),
      search: getParam('search'),
      column: orderBy,
      sort_type: order,
    };
  }, [searchParams, orderBy, order]);

  const {
    data: { data: achievements, last_page: pageCount } = {
      data: [],
      last_page: 1,
    },
  } = useGetAchievements(params);
  const { mutateAsync: deleteAchievement } = useDeleteAchievement();
  const { mutateAsync: changeStatus } = useChangeStatus('achievement');

  const updateRow = (row: IAchievement) => {
    changeStatus({
      space_id: space_id!,
      model_name: 'achievement',
      model_id: row.id,
      state: !row.is_active,
    })
      .then(() => {})
      .catch(() => {});
  };

  const deleteButtonHandler = (row: IAchievement) => {
    setSelectedAchievement(row);
    setOpenDeleteModal(true);
  };

  const deleteRow = async () => {
    await deleteAchievement(selectedAchievement!.id).catch(() => {});
    setOpenDeleteModal(false);
  };

  const cancelDeleteRow = () => {
    setOpenDeleteModal(false);
    setSelectedAchievement(undefined);
  };

  const handleRequestSort = (
    _event: MouseEvent<unknown>,
    property: AchievementSortParams,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const openEditDrawer = (id: string | number) => {
    setParam('edit', id.toString());
  };

  return (
    <StyledBox>
      <DeleteModal
        open={openDeleteModal}
        title='Удалить достижение'
        description='Вы действительно хотите удалить достижение?'
        onConfirm={deleteRow}
        onClose={cancelDeleteRow}
      >
        <StyledAchievementInfoAtModal>
          <StyledAvatar>
            <img
              src={selectedAchievement?.cover as string | undefined}
              alt={selectedAchievement?.name}
            />
          </StyledAvatar>
          <Box className='name'>{selectedAchievement?.name}</Box>
        </StyledAchievementInfoAtModal>
      </DeleteModal>
      <StyledPaper>
        <StyledTableContainer>
          <StyledTable
            aria-labelledby='achievementsTable'
            size='medium'
            stickyHeader
          >
            <AchievementsTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {achievements.map(row => {
                return (
                  <StyledTableRow hover key={row.id}>
                    <TableCell
                      component='th'
                      id={row.id.toString()}
                      scope='row'
                    >
                      <StyledAchievementInfo>
                        <StyledAvatar onClick={() => openEditDrawer(row.id)}>
                          <img
                            src={row.cover as string | undefined}
                            alt={row.name}
                          />
                        </StyledAvatar>
                        <Box
                          className='name'
                          onClick={() => openEditDrawer(row.id)}
                        >
                          {row.name}
                        </Box>
                      </StyledAchievementInfo>
                    </TableCell>
                    <TableCell>
                      <span>
                        {
                          ACHIEVEMENT_TYPE_OPTIONS.find(type => {
                            return type.value === row.needle_type;
                          })?.label
                        }
                      </span>
                    </TableCell>
                    <TableCell>
                      <span>{row.give_count}</span>
                    </TableCell>
                    <TableCell>
                      <span>{row.share_count}</span>
                    </TableCell>
                    <TableCell>
                      <span>{row.created_at}</span>
                    </TableCell>
                    <TableCell>
                      <div className='editDeleteIcons'>
                        <StyledSwitch
                          checked={row.is_active}
                          variant='customGray'
                          onChange={() => updateRow(row)}
                          style={{ opacity: 1 }}
                        />
                        <EditIcon onClick={() => openEditDrawer(row.id)} />
                        <DeleteIcon onClick={() => deleteButtonHandler(row)} />
                      </div>
                    </TableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </StyledTable>
        </StyledTableContainer>
        <Pagination count={pageCount} />
      </StyledPaper>
    </StyledBox>
  );
};
