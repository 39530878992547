import { FC, useState, MouseEvent, useMemo } from 'react';
import { Box, Popover, TextField } from '@mui/material';
import { useGetCustomWorkouts } from 'hooks';
import {
  IWorkout,
  IMarathonSpaceCalendar,
  IMarathonSpaceCalendarItem,
} from 'types';
import { COLORS } from 'utils';
import { DeleteIcon, DownIcon, PlusIcon, UpIcon } from 'assets';
import { Button, FormItem, SelectInput, SearchInput } from 'UI';
import {
  StyledContainer,
  StyledDayHeader,
  StyledTrainingItem,
  StyledPopoverBox,
  StyledWorkoutOption,
} from './styledComponents';

const TARGET_TYPE_OPTIONS = [
  { value: 1, label: 'Дистанция' },
  { value: 2, label: 'Время' },
  { value: 3, label: 'Калории' },
  { value: 4, label: 'Шаги' },
];

interface ICalendarItemProps extends IMarathonSpaceCalendar {
  moveUp: () => void;
  moveDown: () => void;
  addTraining: (__day_in_calendar: string, _training: IWorkout) => void;
  deleteTraining: (
    __day_in_calendar: string,
    _trainingId: number,
    _index: number,
  ) => void;
  handleChangeParameter: <T extends keyof IMarathonSpaceCalendarItem>(
    _day_in_calendar: string,
    _trainingId: number,
    _parameter: T,
    _value: IMarathonSpaceCalendarItem[T],
  ) => void;
}

export const CalendarItem: FC<ICalendarItemProps> = ({
  day_index,
  day_in_calendar,
  points,
  items,
  moveUp,
  moveDown,
  addTraining,
  deleteTraining,
  handleChangeParameter,
}) => {
  const [showSearchComponent, setShowSearchComponent] =
    useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [searchText, setSearchText] = useState<string>('');

  const params = useMemo(() => {
    return {
      page: 1,
      search: searchText,
      column: 'name' as const,
      sort_type: 'asc',
    };
  }, [searchText]);

  const { data: customWorkouts } = useGetCustomWorkouts(params);

  const openSearchComponent = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setShowSearchComponent(true);
  };

  const hideSearchComponent = () => {
    setShowSearchComponent(false);
    setAnchorEl(null);
  };

  const handleSearch = (search: string) => {
    setSearchText(search);
  };

  return (
    <StyledContainer>
      <Popover
        id={showSearchComponent ? 'searchWorkoutPopover' : undefined}
        open={showSearchComponent}
        anchorEl={anchorEl}
        onClose={hideSearchComponent}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <StyledPopoverBox>
          <SearchInput
            handleSearch={search => handleSearch(search)}
            size='large'
          />
          {customWorkouts?.data.map(workout => (
            <StyledWorkoutOption
              key={workout.id}
              onClick={() => {
                addTraining(day_in_calendar, workout);
                hideSearchComponent();
              }}
            >
              <Box className='icon'>
                <img src={workout.icon} alt={workout.name} />
              </Box>
              <Box className='name'>{workout.name}</Box>
            </StyledWorkoutOption>
          ))}
        </StyledPopoverBox>
      </Popover>
      <StyledDayHeader>
        <Box>
          <Box className='title'>День {day_index}</Box>
          <Box className='info'>Сумма очков за день: {points}</Box>
        </Box>
        <Box className='iconButtons'>
          <Button variant='text' onClick={moveUp}>
            <UpIcon />
          </Button>
          <Button variant='text' onClick={moveDown}>
            <DownIcon />
          </Button>
        </Box>
      </StyledDayHeader>
      <Box className='subTitle'>Тренировки</Box>
      {items?.map((item, index) => (
        <StyledTrainingItem key={item.id}>
          <Box className='mainInfo'>
            <Box className='icon'>
              <img src={item.training?.icon} alt={item.training?.name} />
            </Box>
            <Box className='title'>{item.training?.name}</Box>
            <SelectInput
              options={TARGET_TYPE_OPTIONS}
              value={item.type}
              onChange={newValue =>
                handleChangeParameter(
                  day_in_calendar,
                  index,
                  'type',
                  newValue as number,
                )
              }
              variant='outlined'
              placeholder='Не выбран'
              size='small'
            />
            <TextField
              variant='outlined'
              color='primary'
              placeholder='500'
              name='value'
              size='small'
              value={item.value}
              onChange={e =>
                handleChangeParameter(
                  day_in_calendar,
                  index,
                  'value',
                  e.target.value as unknown as number,
                )
              }
            />
            <Button
              variant='text'
              onClick={() =>
                deleteTraining(day_in_calendar, item.training.id!, index)
              }
            >
              <DeleteIcon color={COLORS.RED} />
            </Button>
          </Box>
          {/* <FormItem label='Описание задания'>
            <TextField
              variant='standard'
              color='primary'
              placeholder='Введите описание задания'
              name='description'
              value={item.description}
              onChange={e =>
                handleChangeParameter(
                  day_in_calendar,
                  index,
                  'description',
                  e.target.value,
                )
              }
            />
          </FormItem> */}
          <FormItem label='Очки за выполнение тренировки'>
            <TextField
              variant='standard'
              color='primary'
              placeholder='Введите очки'
              name='points'
              value={item.points}
              style={{ width: '12rem' }}
              onChange={e =>
                handleChangeParameter(
                  day_in_calendar,
                  index,
                  'points',
                  e.target.value as unknown as number,
                )
              }
            />
          </FormItem>
        </StyledTrainingItem>
      ))}
      <Box className='addButtonBox'>
        <Button
          color='customLightBlue'
          startIcon={<PlusIcon color={COLORS.BLUE} />}
          onClick={e => openSearchComponent(e)}
        >
          Добавить тренировку
        </Button>
      </Box>
    </StyledContainer>
  );
};
