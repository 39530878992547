import { FC, useState } from 'react';

import { Button } from 'UI';
import { PlusIcon } from 'assets';
import {
  StyledFooter,
  StyledLeftSide,
  StyledTitle,
  StyledValue,
  StyledRightSide,
  StyledBalanceBox,
  StyledBalance,
} from './styledComponents';
import { FillBalanceModal } from '../FillBalanceModal';

interface IUsersFooter {
  stars: number;
}

export const UsersFooter: FC<IUsersFooter> = ({ stars }) => {
  const [openModal, setOpenModal] = useState<boolean>(false);

  const closeFillBalanceModal = () => {
    setOpenModal(false);
  };

  const changeBalance = (_value: number) => {
    setOpenModal(false);
  };

  return (
    <StyledFooter>
      <FillBalanceModal
        open={openModal}
        onClose={closeFillBalanceModal}
        onConfirm={changeBalance}
      />
      <StyledLeftSide>
        <StyledTitle>Баллы</StyledTitle>
        <StyledValue>Внутренняя валюта площадки</StyledValue>
      </StyledLeftSide>
      <StyledRightSide>
        <StyledBalanceBox>
          <StyledBalance>
            <StyledValue>Звезды</StyledValue>
            <StyledTitle>{stars}</StyledTitle>
          </StyledBalance>
        </StyledBalanceBox>

        <Button
          color='customBlack'
          onClick={() => setOpenModal(true)}
          startIcon={<PlusIcon />}
        >
          Пополнить баланс
        </Button>
      </StyledRightSide>
    </StyledFooter>
  );
};
