import { FC, useState, useMemo, MouseEvent } from 'react';
import { TableBody, TableCell } from '@mui/material';

import { EditIcon, DeleteIcon } from 'assets';
import { convertUsersDateString } from 'utils';
import { Pagination, UserImage } from 'UI';
import { useGetWorkers, useSearchParams } from 'hooks';
import { IWorker, TableRowsOrder, WorkerSortParams } from 'types';
import { DeleteUserModal } from 'components';

import {
  StyledBox,
  StyledPaper,
  StyledTableContainer,
  StyledTable,
  StyledTableRow,
  StyledUserInfo,
  StyledAvatar,
  StyledUserMeta,
} from './styledComponents';
import { WorkersTableHead } from './WorkersTableHead';

export const WorkersTable: FC = () => {
  const [order, setOrder] = useState<TableRowsOrder>('desc');
  const [orderBy, setOrderBy] = useState<WorkerSortParams>('created_at');
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [selectedWorker, setSelectedWorker] = useState<IWorker | undefined>(
    undefined,
  );
  const { setParam, getParam, searchParams } = useSearchParams();

  const params = useMemo(() => {
    return {
      page: getParam('page'),
      search: getParam('search'),
      column: orderBy,
      sort_type: order,
    };
  }, [searchParams, orderBy, order]);

  const {
    data: { data: workers, last_page: pageCount } = { data: [], last_page: 1 },
  } = useGetWorkers(params);

  const openDeleteModal = (row: IWorker) => {
    setSelectedWorker(row);
    setShowDeleteModal(true);
  };

  const closeDeleteModal = () => {
    setSelectedWorker(undefined);
    setShowDeleteModal(false);
  };

  const handleRequestSort = (
    _event: MouseEvent<unknown>,
    property: WorkerSortParams,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const openViewDrawer = (id: string | number) => {
    setParam('view', id.toString());
  };

  const openEditDrawer = (id: string | number) => {
    setParam('edit', id.toString());
  };

  return (
    <StyledBox>
      <DeleteUserModal
        open={showDeleteModal}
        onClose={closeDeleteModal}
        id={selectedWorker?.id}
        firstname={selectedWorker?.firstname}
        lastname={selectedWorker?.lastname}
        photo={selectedWorker?.photo as string | undefined}
        phone={selectedWorker?.phone}
        userType='worker'
      />
      <StyledPaper>
        <StyledTableContainer>
          <StyledTable
            aria-labelledby='workersTable'
            size='medium'
            stickyHeader
          >
            <WorkersTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {workers.map(row => {
                return (
                  <StyledTableRow hover key={row.id}>
                    <TableCell
                      component='th'
                      id={row.id.toString()}
                      scope='row'
                    >
                      <StyledUserInfo>
                        <StyledAvatar onClick={() => openViewDrawer(row.id)}>
                          <UserImage
                            src={row.photo as string | undefined}
                            alt={row.firstname}
                            firstName={row.firstname}
                            lastName={row.lastname}
                            width='3.125rem'
                            height='3.125rem'
                          />
                        </StyledAvatar>
                        <StyledUserMeta onClick={() => openViewDrawer(row.id)}>
                          <div className='name'>
                            {row.firstname} {row.lastname}
                          </div>
                          <div className='phone'>{row.phone}</div>
                        </StyledUserMeta>
                      </StyledUserInfo>
                    </TableCell>
                    <TableCell align='center'>
                      <span>
                        {
                          row.spaces?.reduce((min, current) =>
                            current.role?.id < min.role.id ? current : min,
                          )?.role.text
                        }
                      </span>
                    </TableCell>
                    <TableCell align='center'>
                      <span>{convertUsersDateString(row.created_at)}</span>
                    </TableCell>
                    <TableCell align='center'>
                      <span>
                        {convertUsersDateString(row.last_activity_at)}
                      </span>
                    </TableCell>

                    <TableCell>
                      <div className='editDeleteIcons'>
                        <EditIcon onClick={() => openEditDrawer(row.id)} />
                        <DeleteIcon onClick={() => openDeleteModal(row)} />
                      </div>
                    </TableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </StyledTable>
        </StyledTableContainer>
        <Pagination count={pageCount} />
      </StyledPaper>
    </StyledBox>
  );
};
