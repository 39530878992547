import { styled } from '@mui/system';
import { FormControlLabel, Box } from '@mui/material';
import { COLORS } from 'utils';

export const StyledSwitchFormControl = styled(FormControlLabel)({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  borderBottom: `1px solid ${COLORS.BLACK}`,
  margin: 0,
  paddingBottom: '0.375rem',
  '& .MuiFormControlLabel-label': {
    marginTop: '0.375rem',
    fontSize: '1rem',
    lineHeight: '1.5rem',
  },
});

export const StyledContainer = styled(Box)({
  width: '100%',
  padding: '1.25rem',
  border: '1px solid rgba(0, 0, 0, 0.1)',
  borderRadius: '0.5rem',
  '& .selectButton': {
    width: '400px',
  },
});

export const StyledSearchComponent = styled(Box)({
  height: '20rem',
  overflowY: 'auto',
  width: '30rem',
  '& .paginationButton': {
    width: '100%',
    padding: '0.75rem',
  },
});

export const StyledSelectProductItem = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '1rem',
  padding: '0.75rem 1rem',
  '& .imageBox': {
    width: '3.125rem',
    height: '3.125rem',
    minWidth: '3.125rem',
    overflow: 'hidden',
    borderRadius: '0.5rem',
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
    },
  },
  '& .name': {
    fontWeight: 600,
    fontSize: '1rem',
    lineHeight: '1.5rem',
    color: COLORS.BLACK,
  },
  '&:hover': {
    cursor: 'pointer',
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
  },
});

export const StyledSelectedProduct = styled(Box)({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  '& .data': {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
    '& .imageBox': {
      width: '3.125rem',
      height: '3.125rem',
      minWidth: '3.125rem',
      overflow: 'hidden',
      borderRadius: '0.5rem',
      '& img': {
        width: '100%',
        height: '100%',
        objectFit: 'contain',
      },
    },
    '& .name': {
      fontWeight: 600,
      fontSize: '1rem',
      lineHeight: '1.5rem',
      color: COLORS.BLACK,
    },
  },
  '& svg': {
    cursor: 'pointer',
    minWidth: '1.5rem',
  },
});
