import { FC, CSSProperties } from 'react';
import { COLORS } from 'utils';

interface IAndroidAppIcon {
  color?: string;
  className?: string;
  style?: CSSProperties;
  onClick?: () => void;
}

export const AndroidAppIcon: FC<IAndroidAppIcon> = ({
  color = COLORS.BLACK,
  className,
  style,
  onClick,
}) => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 31 30'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      style={style}
      onClick={onClick}
    >
      <path
        d='M10.125 1.25C9.965 1.25 9.80498 1.31123 9.68311 1.43311C9.43936 1.67686 9.43936 2.07314 9.68311 2.31689L11.3213 3.95508C9.46139 5.3199 8.25 7.51594 8.25 10V21.25C8.25 21.94 8.81 22.5 9.5 22.5H10.75V26.875C10.75 27.91 11.59 28.75 12.625 28.75C13.66 28.75 14.5 27.91 14.5 26.875V22.5H17V26.875C17 27.91 17.84 28.75 18.875 28.75C19.91 28.75 20.75 27.91 20.75 26.875V22.5H22C22.69 22.5 23.25 21.94 23.25 21.25V10C23.25 7.51594 22.0386 5.3199 20.1787 3.95508L21.8169 2.31689C22.0606 2.07189 22.0606 1.67811 21.8169 1.43311C21.5731 1.18936 21.1769 1.18936 20.9331 1.43311L19.0801 3.28613C18.0758 2.78695 16.9477 2.5 15.75 2.5C14.5523 2.5 13.4242 2.78695 12.4199 3.28613L10.5669 1.43311C10.445 1.31123 10.285 1.25 10.125 1.25ZM15.75 5C18.5075 5 20.75 7.2425 20.75 10H10.75C10.75 7.2425 12.9925 5 15.75 5ZM13.25 7.5V8.75H14.5V7.5H13.25ZM17 7.5V8.75H18.25V7.5H17ZM5.75 11.25C5.06 11.25 4.5 11.81 4.5 12.5V20C4.5 20.69 5.06 21.25 5.75 21.25C6.44 21.25 7 20.69 7 20V12.5C7 11.81 6.44 11.25 5.75 11.25ZM25.75 11.25C25.06 11.25 24.5 11.81 24.5 12.5V20C24.5 20.69 25.06 21.25 25.75 21.25C26.44 21.25 27 20.69 27 20V12.5C27 11.81 26.44 11.25 25.75 11.25ZM10.75 12.5H20.75V20H14.5H10.75V12.5Z'
        fill={color}
      />
    </svg>
  );
};
