export const LikeIcon = () => {
  return (
    <svg
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g opacity='0.5'>
        <path
          d='M12.354 15.0001H3.075C2.95565 15.0001 2.84119 14.9527 2.7568 14.8683C2.67241 14.7839 2.625 14.6695 2.625 14.5501V7.20013C2.625 7.08078 2.67241 6.96632 2.7568 6.88193C2.84119 6.79754 2.95565 6.75013 3.075 6.75013H5.151C5.41003 6.75013 5.66464 6.68305 5.89005 6.55543C6.11546 6.42781 6.30398 6.24399 6.43725 6.02188L8.46975 2.63413C8.62304 2.37827 8.86453 2.18723 9.1488 2.09694C9.43307 2.00665 9.74054 2.02334 10.0134 2.14386C10.2862 2.26438 10.5056 2.48043 10.6303 2.75138C10.755 3.02233 10.7764 3.3295 10.6905 3.61513L9.924 6.17113C9.90391 6.23826 9.89978 6.30915 9.91193 6.37817C9.92408 6.44718 9.95218 6.5124 9.99399 6.56863C10.0358 6.62486 10.0902 6.67055 10.1528 6.70206C10.2153 6.73357 10.2844 6.75003 10.3545 6.75013H13.7858C14.0164 6.75008 14.244 6.80323 14.4507 6.90544C14.6575 7.00764 14.8379 7.15616 14.978 7.33944C15.118 7.52272 15.2139 7.73583 15.2582 7.9622C15.3024 8.18856 15.2939 8.42209 15.2333 8.64463L13.8007 13.8946C13.7143 14.2119 13.5259 14.492 13.2646 14.6918C13.0033 14.8915 12.6836 14.9999 12.3547 15.0001H12.354Z'
          stroke='black'
          strokeWidth='2'
          strokeLinecap='round'
        />
        <path
          d='M5.25 15V6.75'
          stroke='black'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
    </svg>
  );
};
