import { FC } from 'react';
import { styled } from '@mui/system';
import { Paper, Box } from '@mui/material';
import { COLORS, formatNumber } from 'utils';

const StyledContainer = styled(Paper)({
  width: '100%',
  padding: '1.25rem',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
  gap: '0.625rem',
  fontFamily: '"SF Pro Display", sans-serif',
  '& .icon': {
    width: '3.75rem',
    height: '3.75rem',
    borderRadius: '50%',
    backgroundColor: 'rgba(0, 128, 255, 0.05)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  '& .title': {
    opacity: 0.5,
    fontSize: '1rem',
    lineHeight: '1.5rem',
  },
  '& .value': {
    fontSize: '1.875rem',
    lineHeight: '2.25rem',
    fontWeight: 700,
  },
  '& .bottom': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& .change': {
      fontSize: '1rem',
      fontWeight: 700,
      lineHeight: '1.5rem',
      '&.positive': {
        color: COLORS.GREEN,
      },
      '&.negative': {
        color: COLORS.RED,
      },
    },
    '& .percent': {
      fontSize: '1rem',
      fontWeight: 700,
      lineHeight: '1.5rem',
      opacity: 0.3,
    },
  },
});

interface IAnalyticsCardProps {
  icon: FC;
  title: string;
  current_data: number;
  difference: number;
  percent: number;
}

export const AnalyticsCard: FC<IAnalyticsCardProps> = ({
  icon: Icon,
  title,
  current_data = 0,
  difference = 0,
  percent = 0,
}) => {
  const isPositiveChange = difference > 0;
  return (
    <StyledContainer>
      <Box className='icon'>
        <Icon />
      </Box>
      <Box>
        <Box className='title'>{title}</Box>
        <Box className='value'>{formatNumber(current_data)}</Box>
      </Box>
      <Box className='bottom'>
        <Box className={`${isPositiveChange ? 'positive' : 'negative'} change`}>
          {isPositiveChange ? '+' : ''}
          {formatNumber(difference)}
        </Box>
        <Box className='percent'>{formatNumber(percent)}%</Box>
      </Box>
    </StyledContainer>
  );
};
