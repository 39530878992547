import { useQuery } from 'react-query';
import {
  getAnalyticsNewsData,
  getAnalyticsNewsGraph,
  getAnalyticsNewsDownloadFile,
} from 'api';
import {
  IAnalyticsCommonGetParams,
  IAnalyticsNewsCardRes,
  IAnalyticsNewsGraphGetParams,
  IAnalyticsGraphRes,
} from 'types';

export const useGetAnalyticsNewsData = (params: IAnalyticsCommonGetParams) => {
  const { start_date, end_date } = params;
  return useQuery<IAnalyticsNewsCardRes, unknown>(
    ['analyticsNews', 'data', { start_date, end_date }],
    async () => {
      const res = await getAnalyticsNewsData(params);
      return res;
    },
    {
      staleTime: 24 * 60 * 60 * 1000,
      retry: false,
    },
  );
};

export const useGetAnalyticsNewsGraph = (
  params: IAnalyticsNewsGraphGetParams,
) => {
  const { start_date, end_date, type } = params;
  return useQuery<IAnalyticsGraphRes, unknown>(
    ['analyticsNews', 'graph', { start_date, end_date, type }],
    async () => {
      const res = await getAnalyticsNewsGraph(params);
      return res;
    },
    {
      staleTime: 24 * 60 * 60 * 1000,
      retry: false,
    },
  );
};

export const useGetAnalyticsNewsDownloadFile = (
  params: IAnalyticsCommonGetParams,
) => {
  const { start_date, end_date } = params;
  return useQuery(['analytics', 'news', { start_date, end_date }], async () => {
    const res = await getAnalyticsNewsDownloadFile(params);
    return res;
  });
};
