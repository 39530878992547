import { FC } from 'react';

import {
  Layout,
  EventLogHeader,
  EventLogsTable,
  ViewUserDrawer,
} from 'components';
import { useDrawer } from 'hooks';

export const EventLog: FC = () => {
  const { viewDrawerId, showViewDrawer, hideViewDrawer } = useDrawer();
  return (
    <Layout withoutPB>
      <ViewUserDrawer
        open={showViewDrawer}
        id={viewDrawerId}
        onClose={hideViewDrawer}
        hideEditButton
      />
      <EventLogHeader />
      <EventLogsTable />
    </Layout>
  );
};
