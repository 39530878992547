export const CaloriesIcon = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        width='24'
        height='24'
        rx='12'
        fill='url(#paint0_linear_3391_54685)'
      />
      <g clipPath='url(#clip0_3391_54685)'>
        <path
          d='M13.0758 6.24023C10.6207 7.65478 10.9158 11.6402 10.9158 11.6402C10.9158 11.6402 9.83578 11.2802 9.83578 9.66024C8.54727 10.4074 7.67578 11.8435 7.67578 13.4402C7.67578 15.8261 9.60991 17.7602 11.9958 17.7602C14.3817 17.7602 16.3158 15.8261 16.3158 13.4402C16.3158 9.93024 13.0758 9.21023 13.0758 6.24023V6.24023ZM12.3753 16.2717C11.5072 16.4881 10.6279 15.9599 10.4115 15.0917C10.195 14.2236 10.7233 13.3444 11.5914 13.1279C13.6873 12.6054 13.9499 11.4268 13.9499 11.4268C13.9499 11.4268 14.9951 15.6185 12.3753 16.2717Z'
          fill='white'
        />
      </g>
      <defs>
        <linearGradient
          id='paint0_linear_3391_54685'
          x1='0'
          y1='0'
          x2='24'
          y2='24'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FF0054' />
          <stop offset='1' stopColor='#FF8E00' />
        </linearGradient>
        <clipPath id='clip0_3391_54685'>
          <rect
            width='11.52'
            height='11.52'
            fill='white'
            transform='translate(6.23828 6.24023)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};
