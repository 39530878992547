import { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { useGetAppUser } from 'hooks';
import { Button, Drawer, DrawerHeader, ModalTitle, UserImage } from 'UI';
import {
  CloseIcon,
  StarIcon,
  AppleIcon,
  AndroidAppIcon,
  UserLevelIcon,
  StepsIcon,
  CaloriesIcon,
  DistanceIcon,
} from 'assets';
import {
  COLORS,
  formatPhoneNumber,
  formatBirthdayToShowAtProfile,
  formatNumber,
} from 'utils';

import {
  StyledBody,
  StyledHeader,
  StyledLabel,
  StyledMiniCard,
  StyledUserActivity,
} from './styledComponents';

const OS_TYPES = [
  {
    name: 'Android',
    icon: <AndroidAppIcon color={COLORS.BLACK} />,
  },
  {
    name: 'iOS',
    icon: <AppleIcon />,
  },
];

interface IViewUserDrawerProps {
  open: boolean;
  onClose: () => void;
  id: string | number;
  hideEditButton?: boolean;
}

export const ViewUserDrawer: FC<IViewUserDrawerProps> = ({
  open,
  onClose,
  id,
  hideEditButton = false,
}) => {
  if (!id) return null;
  const location = useLocation();
  const navigate = useNavigate();
  const { data: userInfo } = useGetAppUser(id);
  const openEditDrawer = () => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('edit', id.toString());
    searchParams.delete('view');
    navigate(`?${searchParams.toString()}`);
  };
  return (
    <Drawer open={open} onClose={onClose} type='gray'>
      <DrawerHeader>
        <ModalTitle title='Профиль' />
        <CloseIcon onClick={onClose} />
      </DrawerHeader>
      <StyledHeader>
        <UserImage
          src={userInfo?.photo as string | undefined}
          alt={userInfo?.firstname}
          firstName={userInfo?.firstname}
          lastName={userInfo?.lastname}
          width='5rem'
          height='5rem'
        />
        <Box>
          <Box className='name'>
            {userInfo?.firstname} {userInfo?.lastname}
          </Box>
          <Box className='role'>{userInfo?.role?.text}</Box>
        </Box>
        <Box className='coinsBox'>
          <Box className='coins'>
            <StarIcon color={COLORS.BLUE} opacity='1' />
            <Box className='value'>{userInfo?.points_count}</Box>
          </Box>
          {/* <Box className='coins'>
            <MoneyIcon color={COLORS.BLUE} opacity='1' />
            <Box className='value'>{userInfo?.coins_count}</Box>
          </Box> */}
        </Box>
      </StyledHeader>
      <StyledUserActivity>
        <Box className='levelBox'>
          <UserLevelIcon />
          <Box className='badge'>{userInfo?.rating.text}</Box>
          <Box className='level'>Уровень {userInfo?.rating.level}</Box>
        </Box>
        <Box className='activityBox'>
          <Box className='activity'>
            <Box className='title'>
              <StepsIcon />
              <span>Шаги</span>
            </Box>
            <Box className='value'>
              {formatNumber(userInfo?.activities_summary.steps)}
            </Box>
          </Box>
          <Box className='activity'>
            <Box className='title'>
              <CaloriesIcon />
              <span>Калории</span>
            </Box>
            <Box className='value'>
              {formatNumber(userInfo?.activities_summary.kcal)}
            </Box>
          </Box>
          <Box className='activity'>
            <Box className='title'>
              <DistanceIcon />
              <span>Км</span>
            </Box>
            <Box className='value'>
              {formatNumber(userInfo?.activities_summary.distance)}
            </Box>
          </Box>
        </Box>
      </StyledUserActivity>
      <StyledBody>
        <Box className='column'>
          <Box>
            <StyledLabel>Номер телефона</StyledLabel>
            <StyledMiniCard>
              {formatPhoneNumber(userInfo?.phone)}
            </StyledMiniCard>
          </Box>
          <Box>
            <StyledLabel>Город</StyledLabel>
            <StyledMiniCard>Москва</StyledMiniCard>
          </Box>
          <Box>
            <StyledLabel>Вес</StyledLabel>
            <StyledMiniCard>{userInfo?.weight} кг</StyledMiniCard>
          </Box>
          {userInfo?.device?.os_type && (
            <Box>
              <StyledLabel>Устройство</StyledLabel>
              <StyledMiniCard>
                {OS_TYPES[userInfo.device.os_type - 1].icon}{' '}
                {OS_TYPES[userInfo.device.os_type - 1]?.name}{' '}
                {userInfo.device.app_version}
              </StyledMiniCard>
            </Box>
          )}
        </Box>
        <Box className='column'>
          <Box>
            <StyledLabel>Эл-почта</StyledLabel>
            <StyledMiniCard>{userInfo?.email}</StyledMiniCard>
          </Box>
          <Box>
            <StyledLabel>Дата рождения</StyledLabel>
            <StyledMiniCard>
              {formatBirthdayToShowAtProfile(userInfo?.birthday)}
            </StyledMiniCard>
          </Box>
          <Box>
            <StyledLabel>Рост</StyledLabel>
            <StyledMiniCard>{userInfo?.height} см</StyledMiniCard>
          </Box>
          <Box>
            <StyledLabel>Связь с аккаунтом ГТО</StyledLabel>
            <StyledMiniCard>
              {userInfo?.has_connect_with_gto ? 'Есть' : 'Отсутствует'}
            </StyledMiniCard>
          </Box>
        </Box>
        {!hideEditButton && (
          <Button
            color='customBlack'
            size='large'
            onClick={openEditDrawer}
            className='editButton'
          >
            Редактировать
          </Button>
        )}
      </StyledBody>
    </Drawer>
  );
};
