import { FC, ChangeEvent, useState } from 'react';
import { styled } from '@mui/system';
import { Box } from '@mui/material';
import { Button } from 'UI';
import { DownloadIcon, AttachIcon } from 'assets';
import { COLORS, fileDownloadByLink } from 'utils';
import { useGetUsersFileSample, useUploadUsersFile } from 'hooks';

const StyledContainer = styled(Box)({
  padding: '0.25rem 0',
  '& h2': {
    fontSize: '1.125rem',
    fontWeight: 600,
    lineHeight: '1.625rem',
    margin: '0 0 0.625rem',
  },
  '& .buttons': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '1rem',
    width: '100%',
    overflow: 'auto',
    '& button': {
      width: '100%',
    },
  },
  '& .error': {
    color: COLORS.RED,
    backgroundColor: 'rgba(235, 51, 51, 0.05)',
    padding: '0.625rem',
    width: 'calc((100% - 1rem) / 2)',
    marginLeft: 'auto',
    marginTop: '0.625rem',
    textAlign: 'center',
    borderRadius: '0.25rem',
  },
});

interface ILoadUsersToSpaceProps {
  spaceId: string;
}

export const LoadUsersToSpace: FC<ILoadUsersToSpaceProps> = ({ spaceId }) => {
  const [fileName, setFileName] = useState<string>('');
  const { data: sampleUsersFileLink } = useGetUsersFileSample();
  const { mutateAsync: uploadUsersFile } = useUploadUsersFile();

  const downloadSample = () => {
    fileDownloadByLink(sampleUsersFileLink, 'sample.csv');
  };

  const uploadUsers = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('dump', file);
    formData.append('space_id', spaceId);
    uploadUsersFile(formData)
      .then(() => {
        setFileName(file.name);
      })
      .catch(() => {});
  };

  const openFileUploadModal = () => {
    document.getElementById('upload-users')?.click();
  };

  return (
    <StyledContainer>
      <h2>Загрузка пользователей</h2>
      <Box className='buttons'>
        <Button
          variant='contained'
          color='customGray'
          size='large'
          startIcon={<DownloadIcon color={COLORS.BLUE} />}
          onClick={downloadSample}
        >
          Скачать пример
        </Button>
        <Button
          variant='contained'
          color='customLightBlue'
          size='large'
          startIcon={<AttachIcon color={COLORS.BLUE} />}
          onClick={openFileUploadModal}
        >
          {fileName || 'Загрузить'}
        </Button>
      </Box>
      <input
        id='upload-users'
        type='file'
        style={{ display: 'none' }}
        onChange={e => uploadUsers(e)}
        accept='.xlsx, .xls, .csv'
      />
    </StyledContainer>
  );
};
