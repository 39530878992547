import * as Pages from 'pages';
import { ROLES } from 'utils';
import { IRoutes } from '../types';

export const Standards: IRoutes = {
  standards: {
    path: '/standards',
    title: 'standards',
    element: <Pages.Standards />,
    roles: [ROLES.ADMINSTRATOR],
  },
};
