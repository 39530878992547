import { FC } from 'react';
import { INews } from 'types';
import {
  NewsLayout,
  NewsSubPagesHeader,
  NewsPreviewComponent,
} from 'components';

interface IShowNewsBodyProps {
  data?: INews;
}

export const ShowNewsBody: FC<IShowNewsBodyProps> = ({ data }) => {
  if (!data?.id) return null;

  return (
    <NewsLayout>
      <NewsSubPagesHeader title='Просмотр новости' />
      <NewsPreviewComponent data={data} />
    </NewsLayout>
  );
};
