import { FC } from 'react';
import { ImageInput, StyledFlexBox } from 'UI';
import { MEDIA_TYPES } from 'utils';
import { IShowcaseOfferDiscountReq } from 'types';

interface IDrawerTopSectionProps {
  cover_big: string;
  cover_mini: string;
  handleFieldChange: <T extends keyof IShowcaseOfferDiscountReq>(
    _field: T,
    _value: IShowcaseOfferDiscountReq[T],
  ) => void;
}

export const DrawerTopSection: FC<IDrawerTopSectionProps> = ({
  cover_big,
  cover_mini,
  handleFieldChange,
}) => {
  return (
    <StyledFlexBox>
      <ImageInput
        inputId='coverBig'
        title='Добавьте обложку'
        descriptionWithoutImage='Загрузите изображение (1280x720 px, до 1 мб)'
        withDescription
        image={cover_big}
        setImage={newImg => handleFieldChange('cover_big', newImg)}
        mediaType={MEDIA_TYPES.SHOWCASE_COVER_BIG}
        imageFixedWidth={1280}
        imageFixedHeight={720}
        placeholderSize='big'
      />
      {/* <ImageInput
        inputId='coverMini'
        title='Добавьте обложку для карточки'
        descriptionWithoutImage='Загрузите изображение (512x512 px, до 1 мб)'
        withDescription
        image={cover_mini}
        setImage={newImg => handleFieldChange('cover_mini', newImg)}
        mediaType={MEDIA_TYPES.SHOWCASE_COVER_MINI}
        imageFixedWidth={512}
        imageFixedHeight={512}
        // placeholderSize='big'
        style={{ alignItems: 'center' }}
      /> */}
    </StyledFlexBox>
  );
};
