import { Box, Paper, Table, TableContainer, TableRow } from '@mui/material';
import { styled } from '@mui/system';

export const StyledBox = styled(Box)({
  width: '100%',
  overflow: 'auto',
});

export const StyledPaper = styled(Paper)({
  width: '100%',
  marginBottom: 2,
});

export const StyledTableContainer = styled(TableContainer)({
  maxHeight: 'calc(100vh - 17rem)',
  minHeight: 'calc(100vh - 17rem)',
});

export const StyledTable = styled(Table)({
  minWidth: '840px',
});

export const StyledTableRow = styled(TableRow)({
  '& .faqQuestion': {
    width: '75%',
    cursor: 'pointer',
  },
  '& span': {
    opacity: 0.5,
    fontSize: '0.75rem',
    fontWeight: 400,
    lineHeight: '1.125rem',
    fontFamily: '"SF Pro Display", sans-serif',
  },
  '& .editDeleteIcons': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '1rem',
    '& svg': {
      cursor: 'pointer',
    },
    '& .switch': {
      marginTop: '-0.5rem',
    },
  },
});
