import { FC, useState, useMemo, MouseEvent } from 'react';
import { Box, Popover } from '@mui/material';
import { useGetAppUsers } from 'hooks';
import { SearchInput, Button, UserImage } from 'UI';
import { IAppUser, ITeamMemberShort, ITeamMember } from 'types';
import { PlusIcon, DeleteIcon, TeamCaptainIcon } from 'assets';
import { COLORS } from 'utils';

import {
  StyledContainer,
  StyledHeader,
  StyledMainContent,
  StyledUserOptions,
  StyledUserOption,
  StyledUsersList,
  StyledUserItem,
  StyledButtonBox,
} from './styledComponents';

interface IAddUserToTeamProps {
  spaceId?: string | number;
  members: ITeamMember[];
  changeMembers: (_members: ITeamMember[]) => void;
  changeCaptain: (_captain: ITeamMemberShort) => void;
}

export const AddUserToTeam: FC<IAddUserToTeamProps> = ({
  spaceId,
  members,
  changeMembers,
  changeCaptain,
}) => {
  if (!spaceId) return null;
  const [showSearchComponent, setShowSearchComponent] =
    useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [searchUserValue, setSearchUserValue] = useState<string>('');
  const paramsToGetUsers = useMemo(() => {
    return {
      search: searchUserValue,
      space_id: spaceId,
    };
  }, [searchUserValue, spaceId]);
  const { data: { data: usersList } = { data: [] } } =
    useGetAppUsers(paramsToGetUsers);

  const [localSearchValue, setLocalSearchValue] = useState<string>('');

  const openSearchComponent = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setShowSearchComponent(true);
  };

  const hideSearchComponent = () => {
    setShowSearchComponent(false);
    setAnchorEl(null);
  };

  const handleWorkerSearch = (search: string) => {
    setSearchUserValue(search);
  };

  const addUserHandler = (user: IAppUser) => {
    const modifiedMember = {
      id: user.id,
      is_leader: false,
      photo: user.photo,
      firstname: user.firstname,
      lastname: user.lastname,
    };
    hideSearchComponent();
    if (members.find(member => member.id === user.id)) return;
    changeMembers([...members, modifiedMember]);
  };

  const handleLocalSearch = (value: string) => {
    setLocalSearchValue(value);
  };

  const visibleMembers = useMemo(() => {
    if (!localSearchValue) return members;
    return members.filter(
      member =>
        member.firstname
          .toLowerCase()
          .includes(localSearchValue.toLowerCase()) ||
        member.lastname.toLowerCase().includes(localSearchValue.toLowerCase()),
    );
  }, [localSearchValue, members]);

  const removeUserHandler = (user: ITeamMember) => {
    changeMembers(members.filter(member => member.id !== user.id));
  };

  const changeCaptainHandler = (user: ITeamMember) => {
    changeCaptain({ id: user.id, is_leader: true });
  };

  return (
    <StyledContainer>
      <StyledHeader>
        <h2>Участники</h2>
        {members.length > 0 && (
          <SearchInput handleSearch={handleLocalSearch} size='medium' />
        )}
      </StyledHeader>
      <StyledMainContent>
        <Popover
          id={showSearchComponent ? 'searchWorkerPopover' : undefined}
          open={showSearchComponent}
          anchorEl={anchorEl}
          onClose={hideSearchComponent}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <Box>
            <StyledUserOptions>
              {usersList.map(user => {
                return (
                  <StyledUserOption
                    key={user.id}
                    onClick={() => addUserHandler(user)}
                  >
                    <Box className='imageBox'>
                      <UserImage
                        src={user.photo as string | undefined}
                        alt={user.firstname}
                        firstName={user.firstname}
                        lastName={user.lastname}
                        width='3.125rem'
                        height='3.125rem'
                      />
                    </Box>
                    <p>
                      {user.lastname} {user.firstname}
                    </p>
                  </StyledUserOption>
                );
              })}
            </StyledUserOptions>

            <SearchInput
              handleSearch={search => handleWorkerSearch(search)}
              size='large'
            />
          </Box>
        </Popover>
        <StyledUsersList>
          {visibleMembers.map(user => (
            <StyledUserItem>
              <Box className='userMeta'>
                {user.is_leader && <TeamCaptainIcon />}
                <Box className='imageBox'>
                  <UserImage
                    src={user.photo as string | undefined}
                    alt={user.firstname}
                    firstName={user.firstname}
                    lastName={user.lastname}
                    width='3.125rem'
                    height='3.125rem'
                  />
                </Box>
                {user.firstname} {user.lastname}
              </Box>
              {!user.is_leader && (
                <Button
                  onClick={() => changeCaptainHandler(user)}
                  color='customLightBlue'
                  className='button'
                >
                  Сделать капитаном
                </Button>
              )}
              <Button
                color='customWhite'
                onClick={() => removeUserHandler(user)}
                className='button'
              >
                <DeleteIcon color={COLORS.RED} />
              </Button>
            </StyledUserItem>
          ))}
        </StyledUsersList>
        <StyledButtonBox>
          <Button
            startIcon={<PlusIcon color={COLORS.BLUE} />}
            onClick={e => openSearchComponent(e)}
            color='customLightBlue'
          >
            Добавить
          </Button>
        </StyledButtonBox>
      </StyledMainContent>
    </StyledContainer>
  );
};
