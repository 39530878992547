import { styled } from '@mui/system';
import { Box } from '@mui/material';
import { FullAnalyticsTable } from 'components';
import { useFullAnalytics } from 'hooks';

const StyledContainer = styled(Box)({
  width: '100%',
  padding: '0 10rem',
  height: '100%',
});

export const FullAnalyticsBody = () => {
  const { data, pageCount, changeParams } = useFullAnalytics();
  return (
    <StyledContainer>
      <FullAnalyticsTable
        data={data}
        pageCount={pageCount}
        // @ts-ignore
        changeParams={changeParams}
      />
    </StyledContainer>
  );
};
