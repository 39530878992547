import { FC, useEffect, useState } from 'react';
import { TextField } from '@mui/material';
import {
  DeleteModal,
  StyledSwitchFormControl,
  WorkoutsCalendarItem,
} from 'components';
import {
  useGetWorkoutsCalendar,
  useCreateWorkoutsCalendar,
  useDeleteWorkoutsCalendar,
  useUpdateWorkoutsCalendar,
} from 'hooks';
import {
  Drawer,
  DrawerHeader,
  DrawerFormBody,
  StyledFlexBox,
  ModalTitle,
  ImageInput,
  FormItem,
  Button,
  StyledSwitch,
  SearchSelectInput,
} from 'UI';
import { CloseIcon, DeleteIcon, PlusIcon } from 'assets';
import {
  MEDIA_TYPES,
  COLORS,
  WORKOUTS_CALENDAR_DAY_LIMIT,
  notify,
} from 'utils';
import { IWorkoutCalendarItem, IWorkoutsCalendarReq } from 'types';

const TRANSLATIONS = {
  add: {
    title: 'Новый цель',
  },
  edit: {
    title: 'Редактировать цель',
  },
};

const DEFAULT_VALUES: IWorkoutsCalendarReq = {
  name: '',
  icon: '',
  cover: '',
  description: '',
  level: 0,
  is_active: true,
  items: [],
  reward_points: '',
};

const LEVEL_OPTIONS = [
  { value: 1, label: 'Золотой знак' },
  { value: 2, label: 'Серебряный знак' },
  { value: 3, label: 'Бронзовый знак' },
];

interface IAddEditWorkoutsCalendarDrawerProps {
  open: boolean;
  onClose: () => void;
  purpose?: 'add' | 'edit';
  id?: number | string;
}

export const AddEditWorkoutsCalendarDrawer: FC<
  IAddEditWorkoutsCalendarDrawerProps
> = ({ open, onClose, purpose = 'add', id = '' }) => {
  const { data: workoutsCalendarInfoData } = useGetWorkoutsCalendar(id);
  const [workoutsCalendarInfo, setWorkoutsCalendarInfo] =
    useState<IWorkoutsCalendarReq>(DEFAULT_VALUES);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const { mutateAsync: createWorkoutsCalendar } = useCreateWorkoutsCalendar();
  const { mutateAsync: updateWorkoutsCalendar } = useUpdateWorkoutsCalendar();
  const { mutateAsync: deleteWorkoutsCalendar } = useDeleteWorkoutsCalendar();

  useEffect(() => {
    if (purpose === 'edit' && workoutsCalendarInfoData) {
      setWorkoutsCalendarInfo(workoutsCalendarInfoData);
    }
    return () => {
      setWorkoutsCalendarInfo(DEFAULT_VALUES);
    };
  }, [purpose, workoutsCalendarInfoData]);

  const handleFieldChange = <T extends keyof IWorkoutsCalendarReq>(
    fieldName: T,
    newValue: IWorkoutsCalendarReq[T],
  ) => {
    setWorkoutsCalendarInfo(prevInfo => ({
      ...prevInfo,
      [fieldName]: newValue,
    }));
  };

  const handleChangeItemParameter = <T extends keyof IWorkoutCalendarItem>(
    itemId: number,
    parameter: T,
    value: IWorkoutCalendarItem[T],
  ) => {
    const updatedCalendar = [...workoutsCalendarInfo.items];
    const updatedItem = updatedCalendar?.find(item => item.id === itemId);
    if (updatedItem) {
      updatedItem[parameter] = value;
      setWorkoutsCalendarInfo(prevInfo => ({
        ...prevInfo,
        items: updatedCalendar,
      }));
    }
  };

  const moveUpCalendarItem = (itemId: number) => {
    const updatedCalendar = [...workoutsCalendarInfo.items];
    const updatedItemIndex = updatedCalendar.findIndex(
      item => item.id === itemId,
    );
    if (updatedItemIndex > 0) {
      const copyOfGoingUpItem = { ...updatedCalendar[updatedItemIndex - 1] };
      updatedCalendar[updatedItemIndex - 1] = updatedCalendar[updatedItemIndex];
      updatedCalendar[updatedItemIndex] = copyOfGoingUpItem;
      setWorkoutsCalendarInfo(prevInfo => ({
        ...prevInfo,
        items: updatedCalendar,
      }));
    }
  };

  const moveDownCalendarItem = (itemId: number) => {
    const updatedCalendar = [...workoutsCalendarInfo.items];
    const updatedItemIndex = updatedCalendar.findIndex(
      item => item.id === itemId,
    );
    if (updatedItemIndex < updatedCalendar.length - 1) {
      const copyOfGoingDownItem = { ...updatedCalendar[updatedItemIndex + 1] };
      updatedCalendar[updatedItemIndex + 1] = updatedCalendar[updatedItemIndex];
      updatedCalendar[updatedItemIndex] = copyOfGoingDownItem;
      setWorkoutsCalendarInfo(prevInfo => ({
        ...prevInfo,
        items: updatedCalendar,
      }));
    }
  };

  const addDayToCalendar = () => {
    const updatedCalendar = [...workoutsCalendarInfo.items];
    if (updatedCalendar.length === WORKOUTS_CALENDAR_DAY_LIMIT) {
      return;
    }
    setWorkoutsCalendarInfo(prevInfo => ({
      ...prevInfo,
      items: [
        ...updatedCalendar,
        getDefaultNewCalendarItem(updatedCalendar.length),
      ],
    }));
  };

  const getDefaultNewCalendarItem = (length: number) => {
    return {
      id: length + 1,
      day_index: length + 1,
      name: '',
      description: '',
      type_id: 0,
      needle_type: 1,
      needle_value: undefined,
      needle_time: undefined,
    };
  };

  const openDeleteModal = () => {
    setShowDeleteModal(true);
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const handleDeleteWorkoutsCalendar = () => {
    deleteWorkoutsCalendar(workoutsCalendarInfo.id!)
      .then(() => {
        closeDeleteModal();
        onClose();
      })
      .catch();
  };

  const ignoreChanges = () => {
    setWorkoutsCalendarInfo(DEFAULT_VALUES);
    onClose();
  };

  const saveChanges = () => {
    if (workoutsCalendarInfo.items?.length !== WORKOUTS_CALENDAR_DAY_LIMIT) {
      notify('Необходимо заполнить 15 дней', 'error');
      return;
    }
    if (purpose === 'add') {
      createWorkoutsCalendar(workoutsCalendarInfo)
        .then(() => {
          onClose();
        })
        .catch(() => {});
    } else {
      updateWorkoutsCalendar(workoutsCalendarInfo)
        .then(() => {
          onClose();
        })
        .catch(() => {});
    }
  };

  return (
    <Drawer open={open} onClose={ignoreChanges}>
      <DeleteModal
        open={showDeleteModal}
        onConfirm={handleDeleteWorkoutsCalendar}
        onClose={closeDeleteModal}
        title='Удалить цель'
        description='Вы действительно хотите удалить цель?'
      />
      <DrawerHeader>
        <ModalTitle title={TRANSLATIONS[purpose].title} />
        <CloseIcon onClick={ignoreChanges} />
      </DrawerHeader>
      <DrawerFormBody>
        <StyledFlexBox>
          <ImageInput
            inputId='workoutsCalendarLogo'
            title='Добавьте иконку'
            descriptionWithoutImage='Загрузите изображение (512x512 px, до 1 мб)'
            withDescription
            image={workoutsCalendarInfo?.icon}
            setImage={newImg => handleFieldChange('icon', newImg)}
            mediaType={MEDIA_TYPES.PROGRAM_ICON}
            imageFixedWidth={512}
            imageFixedHeight={512}
          />
          <ImageInput
            inputId='workoutsCalendarCover'
            title='Добавьте обложку'
            descriptionWithoutImage='Загрузите изображение (903x540 px, до 1 мб)'
            withDescription
            image={workoutsCalendarInfo?.cover}
            setImage={newImg => handleFieldChange('cover', newImg)}
            mediaType={MEDIA_TYPES.PROGRAM_COVER}
            imageFixedWidth={903}
            imageFixedHeight={540}
          />
        </StyledFlexBox>
        <h2>О цели</h2>
        <FormItem label='Название цели'>
          <TextField
            variant='standard'
            color='primary'
            placeholder='Впишите название'
            value={workoutsCalendarInfo.name}
            onChange={e => handleFieldChange('name', e.target.value)}
            name='name'
          />
        </FormItem>
        <FormItem label='Описание цели'>
          <TextField
            variant='standard'
            color='primary'
            placeholder='Впишите описание'
            value={workoutsCalendarInfo.description}
            onChange={e => handleFieldChange('description', e.target.value)}
            name='description'
          />
        </FormItem>
        <FormItem label='Уровень'>
          <SearchSelectInput
            options={LEVEL_OPTIONS}
            value={workoutsCalendarInfo.level}
            onChange={newVal =>
              handleFieldChange('level', newVal as unknown as number)
            }
            variant='standard'
            placeholder='Не выбран'
            size='small'
          />
        </FormItem>
        <FormItem label='Баллы за выполнение'>
          <TextField
            variant='standard'
            color='primary'
            placeholder='Введите баллы'
            value={workoutsCalendarInfo.reward_points}
            onChange={e => handleFieldChange('reward_points', e.target.value)}
            name='reward_points'
          />
        </FormItem>
        <StyledSwitchFormControl
          label='Активная цель'
          labelPlacement='start'
          control={
            <StyledSwitch
              checked={workoutsCalendarInfo.is_active}
              onChange={() =>
                handleFieldChange('is_active', !workoutsCalendarInfo.is_active)
              }
            />
          }
        />
        {workoutsCalendarInfo.items?.map((item, index) => (
          <WorkoutsCalendarItem
            key={item.id}
            moveUp={() => moveUpCalendarItem(item.id!)}
            moveDown={() => moveDownCalendarItem(item.id!)}
            handleChangeParameter={(parameter, value) =>
              handleChangeItemParameter(item.id, parameter, value)
            }
            {...item}
            day_index={index + 1}
          />
        ))}
        <Button
          startIcon={<PlusIcon color={COLORS.BLUE} />}
          color='customLightBlue'
          onClick={addDayToCalendar}
        >
          Добавить день
        </Button>
      </DrawerFormBody>
      <StyledFlexBox className='buttons'>
        {purpose === 'edit' ? (
          <Button
            onClick={openDeleteModal}
            variant='contained'
            size='large'
            color='customGray'
            startIcon={<DeleteIcon color={COLORS.RED} />}
            sx={{
              color: COLORS.RED,
            }}
          >
            Удалить
          </Button>
        ) : (
          <Button
            variant='contained'
            size='large'
            onClick={ignoreChanges}
            color='customGray'
          >
            Отмена
          </Button>
        )}

        <Button
          variant='contained'
          size='large'
          color='primary'
          onClick={saveChanges}
        >
          Сохранить
        </Button>
      </StyledFlexBox>
    </Drawer>
  );
};
