import { useQuery } from 'react-query';
import {
  getAnalyticsChallengesData,
  getAnalyticsChallengesFinishGraph,
  getAnalyticsChallengesShareGraph,
  getAnalyticsChallengesTopList,
  getAnalyticsChallengesRuns,
  getAnalyticsChallengesAccepts,
} from 'api';

import {
  IAnalyticsChallengesDataRes,
  IAnalyticsCommonGetParams,
  IAnalyticsGraphRes,
  IAnalyticsChallengesTopGetParams,
  IAnalyticsChallengesTopRes,
  IAnalyticsChallengesRunsRes,
  IAnalyticsChallengesAcceptsRes,
} from 'types';

export const useGetAnalyticsChallengesData = (
  params: IAnalyticsCommonGetParams,
) => {
  const { start_date, end_date } = params;
  return useQuery<IAnalyticsChallengesDataRes, unknown>(
    ['analyticsChallenges', 'data', { start_date, end_date }],
    async () => {
      const res = await getAnalyticsChallengesData(params);
      return res;
    },
    {
      staleTime: 24 * 60 * 60 * 1000,
      retry: false,
    },
  );
};

export const useGetAnalyticsChallengesFinishGraph = (
  params: IAnalyticsCommonGetParams,
) => {
  const { start_date, end_date } = params;
  return useQuery<IAnalyticsGraphRes, unknown>(
    ['analyticsChallenges', 'finishGraph', { start_date, end_date }],
    async () => {
      const res = await getAnalyticsChallengesFinishGraph(params);
      return res;
    },
    {
      staleTime: 24 * 60 * 60 * 1000,
      retry: false,
    },
  );
};

export const useGetAnalyticsChallengesShareGraph = (
  params: IAnalyticsCommonGetParams,
) => {
  const { start_date, end_date } = params;
  return useQuery<IAnalyticsGraphRes, unknown>(
    ['analyticsChallenges', 'shareGraph', { start_date, end_date }],
    async () => {
      const res = await getAnalyticsChallengesShareGraph(params);
      return res;
    },
    {
      staleTime: 24 * 60 * 60 * 1000,
      retry: false,
    },
  );
};

export const useGetAnalyticsChallengesTopList = (
  params: IAnalyticsChallengesTopGetParams,
) => {
  const { start_date, end_date, type, limit, order_type, order, page, search } =
    params;
  return useQuery<IAnalyticsChallengesTopRes, unknown>(
    [
      'analyticsChallenges',
      'topLists',
      {
        start_date,
        end_date,
        type,
        limit,
        order_type,
        order,
        page,
        search,
      },
    ],
    async () => {
      const res = await getAnalyticsChallengesTopList(params);
      return res;
    },
    {
      staleTime: 24 * 60 * 60 * 1000,
      retry: false,
    },
  );
};

export const useGetAnalyticsChallengesRuns = (
  params: IAnalyticsCommonGetParams,
) => {
  const { start_date, end_date } = params;
  return useQuery<IAnalyticsChallengesRunsRes, unknown>(
    ['analyticsChallenges', 'runs', { start_date, end_date }],
    async () => {
      const res = await getAnalyticsChallengesRuns(params);
      return res;
    },
    {
      staleTime: 24 * 60 * 60 * 1000,
      retry: false,
    },
  );
};

export const useGetAnalyticsChallengesAccepts = (
  params: IAnalyticsCommonGetParams,
) => {
  const { start_date, end_date } = params;
  return useQuery<IAnalyticsChallengesAcceptsRes, unknown>(
    ['analyticsChallenges', 'accepts', { start_date, end_date }],
    async () => {
      const res = await getAnalyticsChallengesAccepts(params);
      return res;
    },
    {
      staleTime: 24 * 60 * 60 * 1000,
      retry: false,
    },
  );
};
