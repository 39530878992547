import { FC } from 'react';
import { IIconProps } from './types';

export const ModerationIcon: FC<IIconProps> = ({ isActive, activeColor }) => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8.12039 12.0006C8.12039 13.304 7.06378 14.3606 5.76039 14.3606V16.3606C8.16835 16.3606 10.1204 14.4086 10.1204 12.0006H8.12039ZM5.76039 14.3606C4.457 14.3606 3.40039 13.304 3.40039 12.0006H1.40039C1.40039 14.4086 3.35243 16.3606 5.76039 16.3606V14.3606ZM3.40039 12.0006C3.40039 10.6972 4.457 9.64062 5.76039 9.64062V7.64062C3.35243 7.64062 1.40039 9.59266 1.40039 12.0006H3.40039ZM5.76039 9.64062C7.06378 9.64062 8.12039 10.6972 8.12039 12.0006H10.1204C10.1204 9.59266 8.16835 7.64062 5.76039 7.64062V9.64062ZM9.95553 11.7814C10.3651 11.0733 11.1281 10.6006 12.0004 10.6006V8.60062C10.3853 8.60062 8.97667 9.4793 8.22431 10.7799L9.95553 11.7814ZM12.0004 10.6006C12.8727 10.6006 13.6357 11.0733 14.0453 11.7814L15.7765 10.7799C15.0242 9.4793 13.6155 8.60062 12.0004 8.60062V10.6006ZM20.6004 12.0006C20.6004 13.304 19.5438 14.3606 18.2404 14.3606V16.3606C20.6483 16.3606 22.6004 14.4086 22.6004 12.0006H20.6004ZM18.2404 14.3606C16.937 14.3606 15.8804 13.304 15.8804 12.0006H13.8804C13.8804 14.4086 15.8324 16.3606 18.2404 16.3606V14.3606ZM15.8804 12.0006C15.8804 10.6972 16.937 9.64062 18.2404 9.64062V7.64062C15.8324 7.64062 13.8804 9.59266 13.8804 12.0006H15.8804ZM18.2404 9.64062C19.5438 9.64062 20.6004 10.6972 20.6004 12.0006H22.6004C22.6004 9.59266 20.6483 7.64062 18.2404 7.64062V9.64062Z'
        fill={isActive ? activeColor : '#C4C4C4'}
      />
    </svg>
  );
};
