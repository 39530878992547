import { IShopProductReqParams, IShopProductReq } from 'types';
import { http, methods } from '../config';

export const getShopProducts = (params: IShopProductReqParams) => {
  const config = {
    method: methods.get,
    url: `/shop/category/${params.category_id}/item`,
    params,
  };
  return http(config);
};

export const getShopProduct = (params: IShopProductReqParams) => {
  const config = {
    method: methods.get,
    url: `/shop/category/${params.category_id}/item/${params.id}`,
  };
  return http(config);
};

export const createShopProduct = (data: IShopProductReq) => {
  const config = {
    method: methods.post,
    url: `/shop/category/${data.category_id}/item`,
    data,
  };
  return http(config);
};

export const updateShopProduct = (data: IShopProductReq) => {
  const config = {
    method: methods.put,
    url: `/shop/category/${data.category_id}/item/${data.id}`,
    data,
  };
  return http(config);
};

export const deleteShopProduct = (params: IShopProductReqParams) => {
  const config = {
    method: methods.delete,
    url: `/shop/category/${params.category_id}/item/${params.id}`,
  };
  return http(config);
};
