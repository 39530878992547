import { FC } from 'react';
// import { useSearchParams, useGetLevels } from 'hooks';
import { Grid } from '@mui/material';
import { LevelCard } from 'components';
import { CardsContainer, Pagination, PaginationContainer } from 'UI';
import { ILevel } from 'types';

const MOCK_LEVELS: ILevel[] = [
  {
    id: 1,
    name: 'Стартер',
    cover: 'https://picsum.photos/200',
    is_active: true,
    balls: 300,
    parameters: [],
  },
  {
    id: 1,
    name: 'Тренер',
    cover: 'https://picsum.photos/201',
    is_active: true,
    balls: 500,
    parameters: [],
  },
  {
    id: 1,
    name: 'Чемпион',
    cover: 'https://picsum.photos/202',
    is_active: true,
    balls: 600,
    parameters: [],
  },
];
const MOCK_PAGE_COUNT = 1;

export const LevelsBody: FC = () => {
  // const { searchParams, getParam } = useSearchParams();
  // const params = useMemo(() => {
  //   return { page: getParam('page') };
  // }, [searchParams]);
  // const {
  //   data: { data: levels, last_page: pageCount } = { data: [], last_page: 1 },
  // } = useGetLevels(params);

  return (
    <>
      <CardsContainer container spacing={3}>
        {MOCK_LEVELS?.map((level, index) => (
          <Grid item xs={6} key={level.id}>
            <LevelCard level={level} position={index + 1} />
          </Grid>
        ))}
      </CardsContainer>
      <PaginationContainer item xs={12}>
        <Pagination count={MOCK_PAGE_COUNT} />
      </PaginationContainer>
    </>
  );
};
