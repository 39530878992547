import { FC, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { DeletePartnerModal } from 'components';
import { IPartner } from 'types';
import { StyledSwitch } from 'UI';
import { COLORS } from 'utils';
import { DeleteIcon, EditIcon } from 'assets';
import { CardBody, CardHeader, CardContainer } from './styledComponents';

interface IPartnerCardProps {
  partner: IPartner;
}

export const PartnerCard: FC<IPartnerCardProps> = ({ partner }) => {
  const { id, name, logo, category, is_active } = partner;
  const location = useLocation();
  const navigate = useNavigate();

  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const changeStatus = () => {};

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const openDeleteModal = () => {
    setShowDeleteModal(true);
  };

  const openEditDrawer = () => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('edit', id.toString());
    navigate(`?${searchParams.toString()}`);
  };

  return (
    <CardContainer>
      <DeletePartnerModal
        open={showDeleteModal}
        onClose={closeDeleteModal}
        id={id}
        name={name}
        logo={logo}
      />
      <CardHeader>
        <Box className='imageBox'>
          <img src={logo} alt={name} />
        </Box>
        <Box className='infoBox'>
          <h2>{name}</h2>
          <span>{category}</span>
        </Box>
      </CardHeader>
      <CardBody>
        <Box className='buttons'>
          <EditIcon onClick={openEditDrawer} color={COLORS.BLACK} />
          <DeleteIcon onClick={openDeleteModal} color={COLORS.BLACK} />
        </Box>
        <StyledSwitch
          checked={is_active}
          onChange={changeStatus}
          className='switch'
          variant='customGray'
        />
      </CardBody>
    </CardContainer>
  );
};
