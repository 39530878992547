import { FC } from 'react';
import { Button, PageHeader, GlobalSearch } from 'UI';
import { PlusIcon } from 'assets';

interface IRegionsHeaderProps {
  openAddRegionDrawer: () => void;
}

export const RegionsHeader: FC<IRegionsHeaderProps> = ({
  openAddRegionDrawer,
}) => {
  return (
    <PageHeader title='Регионы'>
      <GlobalSearch size='medium' />
      <Button
        variant='contained'
        onClick={openAddRegionDrawer}
        startIcon={<PlusIcon />}
      >
        Добавить
      </Button>
    </PageHeader>
  );
};
