import { IRegionGetParams, IRegionReq } from 'types';

import { http, methods } from './config';

export const getCities = (params: IRegionGetParams) => {
  const config = {
    method: methods.get,
    url: `/region/city`,
    params,
  };
  return http(config);
};

export const getCity = (cityId: number | string) => {
  const config = {
    method: methods.get,
    url: `/region/city/${cityId}`,
  };
  return http(config);
};

export const createCity = (data: IRegionReq) => {
  const config = {
    method: methods.post,
    url: `/region/city`,
    data,
  };
  return http(config);
};

export const updateCity = (data: IRegionReq) => {
  const config = {
    method: methods.put,
    url: `/region/city/${data.city_id}`,
    data,
  };
  return http(config);
};

export const getCitiesList = (params: { search?: string }) => {
  const config = {
    method: methods.get,
    url: `/search/city`,
    params,
  };
  return http(config);
};

export const getRegionsList = (params: { search?: string }) => {
  const config = {
    method: methods.get,
    url: `/search/region`,
    params,
  };
  return http(config);
};
