import * as Pages from 'pages';
import { ROLES } from 'utils';
import { IRoutes } from '../types';

export const Moderation: IRoutes = {
  moderation: {
    path: '/moderation',
    title: 'moderation',
    element: <Pages.Moderation />,
    roles: [ROLES.ADMINSTRATOR, ROLES.MODERATOR],
  },
};
