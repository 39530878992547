import { FC } from 'react';
import { COLORS } from 'utils';

interface ILoadingIconProps {
  color?: string;
}

export const LoadingIcon: FC<ILoadingIconProps> = ({
  color = COLORS.BLACK,
}) => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g opacity='0.5'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M12 2C12.5523 2 13 2.44772 13 3V7.11429C13 7.66657 12.5523 8.11429 12 8.11429C11.4477 8.11429 11 7.66657 11 7.11429V3C11 2.44772 11.4477 2 12 2ZM4.9283 4.9288C5.31882 4.53827 5.95199 4.53827 6.34251 4.9288L9.25175 7.83804C9.64227 8.22856 9.64227 8.86173 9.25175 9.25225C8.86123 9.64278 8.22806 9.64278 7.83754 9.25225L4.9283 6.34301C4.53777 5.95249 4.53777 5.31932 4.9283 4.9288ZM19.0714 4.92911C19.4619 5.31963 19.4619 5.9528 19.0714 6.34332L16.1622 9.25256C15.7716 9.64309 15.1385 9.64309 14.7479 9.25256C14.3574 8.86204 14.3574 8.22887 14.7479 7.83835L17.6572 4.92911C18.0477 4.53859 18.6809 4.53859 19.0714 4.92911ZM2 12C2 11.4477 2.44772 11 3 11H7.11429C7.66657 11 8.11429 11.4477 8.11429 12C8.11429 12.5523 7.66657 13 7.11429 13H3C2.44772 13 2 12.5523 2 12ZM15.8857 12C15.8857 11.4477 16.3334 11 16.8857 11H21C21.5523 11 22 11.4477 22 12C22 12.5523 21.5523 13 21 13H16.8857C16.3334 13 15.8857 12.5523 15.8857 12ZM14.7479 14.7474C15.1385 14.3569 15.7716 14.3569 16.1622 14.7474L19.0714 17.6567C19.4619 18.0472 19.4619 18.6804 19.0714 19.0709C18.6809 19.4614 18.0477 19.4614 17.6572 19.0709L14.7479 16.1617C14.3574 15.7711 14.3574 15.138 14.7479 14.7474ZM9.25175 14.7477C9.64227 15.1383 9.64227 15.7714 9.25175 16.162L6.34251 19.0712C5.95199 19.4617 5.31882 19.4617 4.9283 19.0712C4.53777 18.6807 4.53777 18.0475 4.9283 17.657L7.83754 14.7477C8.22806 14.3572 8.86123 14.3572 9.25175 14.7477ZM12 15.8857C12.5523 15.8857 13 16.3334 13 16.8857V21C13 21.5523 12.5523 22 12 22C11.4477 22 11 21.5523 11 21V16.8857C11 16.3334 11.4477 15.8857 12 15.8857Z'
          fill={color}
        />
      </g>
    </svg>
  );
};
