import { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import IconGto from 'assets/images/gto_spaces.jpg';

import { ISpace } from 'types';
import { Button, StyledBadge, StyledSwitch } from 'UI';
import { EditIcon } from 'assets';
import { useChangeStatus } from 'hooks';
import {
  SpaceCardWrapper,
  StyledHr,
  StyledSpaceInfo,
} from './styledComponents';

interface ISpaceCardProps {
  space: ISpace;
}

export const SpaceCard: FC<ISpaceCardProps> = ({ space }) => {
  const { id, name, logo, is_active, users_count, groups_count } = space;
  const { mutateAsync: changeStatus } = useChangeStatus('space');
  const location = useLocation();
  const navigate = useNavigate();

  const logoSpace = id === 1 ? IconGto : logo

  const changeActiveStatus = () => {
    changeStatus({
      space_id: id,
      model_name: 'space',
      model_id: id,
      state: !is_active,
    }).catch(() => {});
  };

  const openEditDrawer = () => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('edit', id.toString());
    navigate(`?${searchParams.toString()}`);
  };

  const openViewDrawer = () => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('view', id.toString());
    navigate(`?${searchParams.toString()}`);
  };

  return (
    <SpaceCardWrapper>
      <div className='logoBox'>
        <div className='logo' onClick={openViewDrawer}>
          <img src={logoSpace} alt='logo' />
        </div>
        <StyledSwitch
          checked={is_active}
          onChange={changeActiveStatus}
          disabled={id === 1}
        />
      </div>
      <h2 onClick={openViewDrawer}>{name}</h2>
      <StyledBadge is_active={is_active} />
      <StyledHr />
      <StyledSpaceInfo>
        <div className='spaceInfoItem'>
          <div className='label'>Группы</div>
          <div className='value'>{groups_count}</div>
        </div>
        <div className='spaceInfoItem'>
          <div className='label'>Команды</div>
          <div className='value'>{groups_count}</div>
        </div>
        <div className='spaceInfoItem'>
          <div className='label'>Пользователи</div>
          <div className='value'>{users_count}</div>
        </div>
      </StyledSpaceInfo>
      <Button
        variant='contained'
        color='customGray'
        size='large'
        startIcon={<EditIcon />}
        onClick={openEditDrawer}
      >
        Редактировать
      </Button>
    </SpaceCardWrapper>
  );
};
