import { FC } from 'react';
import { COLORS } from 'utils';

interface IVideoIconProps {
  className?: string;
  onClick?: () => void;
  color?: string;
}

export const VideoIcon: FC<IVideoIconProps> = ({
  className,
  onClick,
  color = COLORS.BLUE,
}) => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      onClick={onClick}
    >
      <path
        d='M21 3.6V20.4C21 20.7314 20.7314 21 20.4 21H3.6C3.26863 21 3 20.7314 3 20.4V3.6C3 3.26863 3.26863 3 3.6 3H20.4C20.7314 3 21 3.26863 21 3.6Z'
        stroke={color}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.89768 8.51354C9.49769 8.28497 9 8.57378 9 9.03448V14.9667C9 15.4274 9.49769 15.7162 9.89768 15.4876L15.0883 12.5215C15.4914 12.2912 15.4914 11.71 15.0883 11.4797L9.89768 8.51354Z'
        stroke={color}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
