import { FC } from 'react';
import { IIconProps } from './types';

export const WorkoutsCalendarIcon: FC<IIconProps> = ({
  isActive,
  activeColor,
}) => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12 2C6.477 2 2 6.477 2 12C2 17.523 6.477 22 12 22C17.523 22 22 17.523 22 12C22 10.313 21.5778 8.72408 20.8398 7.33008L19.3438 8.82812C19.7647 9.80113 20 10.872 20 12C20 16.418 16.418 20 12 20C7.582 20 4 16.418 4 12C4 7.582 7.582 4 12 4C13.8481 4 15.5432 4.63544 16.8965 5.68945L15.4688 7.11719C14.4895 6.41757 13.2961 6 12 6C8.686 6 6 8.686 6 12C6 15.314 8.686 18 12 18C15.314 18 18 15.314 18 12C18 11.443 17.9174 10.9055 17.7754 10.3945L15.9805 12.1895C15.8785 14.3085 14.144 16 12 16C9.791 16 8 14.209 8 12C8 9.791 9.791 8 12 8C12.7422 8 13.4328 8.20657 14.0254 8.56055L12.5156 10.0703C12.3475 10.0245 12.1742 10.0009 12 10C11.4696 10 10.9609 10.2107 10.5858 10.5858C10.2107 10.9609 10 11.4696 10 12C10 12.5304 10.2107 13.0391 10.5858 13.4142C10.9609 13.7893 11.4696 14 12 14C12.5304 14 13.0391 13.7893 13.4142 13.4142C13.7893 13.0391 14 12.5304 14 12C13.9999 11.8252 13.9769 11.6512 13.9316 11.4824L22 3.41406L20.5859 2L18.3223 4.26367C16.5981 2.85325 14.4009 2 12 2Z'
        fill={isActive ? activeColor : '#C4C4C4'}
      />
    </svg>
  );
};
