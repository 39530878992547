import { FC, useContext, useMemo } from 'react';
import { Grid } from '@mui/material';
import {
  AnalyticsCard,
  AnalyticsCardsContainer,
  AnalyticsInnerPageContainer,
  AnalyticsLineChartCard,
} from 'components';
import {
  NewsIcon,
  LinkClickIcon,
  NotificationRingIcon,
  NotificationIcon,
  AndroidIcon,
  IPhoneIcon,
} from 'assets';
import { COLORS, FilterContext, DEFAULT_CARD } from 'utils';
import { useGetAnalyticsNewsData, useGetAnalyticsNewsGraph } from 'hooks';
import { AnalyticsNewsGraphType } from 'types';

export const NewsAnalyticsBody: FC = () => {
  const { startDate, endDate } = useContext(FilterContext);

  const paramsToGetAnalytics = useMemo(() => {
    return {
      start_date: startDate,
      end_date: endDate,
    };
  }, [startDate, endDate]);

  const paramsToGetGraphShare = useMemo(() => {
    return {
      start_date: startDate,
      end_date: endDate,
      type: 'shared' as AnalyticsNewsGraphType,
    };
  }, [startDate, endDate]);

  const paramsToGetGraphLike = useMemo(() => {
    return {
      start_date: startDate,
      end_date: endDate,
      type: 'liked' as AnalyticsNewsGraphType,
    };
  }, [startDate, endDate]);

  const paramsToGetGraphView = useMemo(() => {
    return {
      start_date: startDate,
      end_date: endDate,
      type: 'viewed' as AnalyticsNewsGraphType,
    };
  }, [startDate, endDate]);

  const {
    data: {
      news,
      push_count,
      all_devices,
      ios_devices,
      android_devices,
      shared_news,
    } = {
      news: DEFAULT_CARD,
      push_count: DEFAULT_CARD,
      all_devices: DEFAULT_CARD,
      ios_devices: DEFAULT_CARD,
      android_devices: DEFAULT_CARD,
      shared_news: DEFAULT_CARD,
    },
  } = useGetAnalyticsNewsData(paramsToGetAnalytics);

  const {
    data: {
      data: shareData,
      count: shareTotal,
      difference: shareDifference,
    } = { data: [], count: 0, difference: 0 },
  } = useGetAnalyticsNewsGraph(paramsToGetGraphShare);

  const {
    data: { data: likeData, count: likeTotal, difference: likeDifference } = {
      data: [],
      count: 0,
      difference: 0,
    },
  } = useGetAnalyticsNewsGraph(paramsToGetGraphLike);

  const {
    data: { data: viewData, count: viewTotal, difference: viewDifference } = {
      data: [],
      count: 0,
      difference: 0,
    },
  } = useGetAnalyticsNewsGraph(paramsToGetGraphView);

  return (
    <AnalyticsInnerPageContainer>
      <AnalyticsCardsContainer>
        <Grid xs={6} lg={4} item>
          <AnalyticsCard icon={NewsIcon} title='Новостей' {...news} />
        </Grid>
        <Grid xs={6} lg={4} item>
          <AnalyticsCard
            icon={LinkClickIcon}
            title='Переходы по ссылке'
            {...shared_news}
          />
        </Grid>
        <Grid xs={6} lg={4} item>
          <AnalyticsCard
            icon={NotificationRingIcon}
            title='Отправлено пушы'
            {...push_count}
          />
        </Grid>
      </AnalyticsCardsContainer>
      <AnalyticsCardsContainer>
        <Grid xs={6} lg={4} item>
          <AnalyticsCard
            icon={NotificationIcon}
            title='Переходы на пуш'
            {...all_devices}
          />
        </Grid>
        <Grid xs={6} lg={4} item>
          <AnalyticsCard
            icon={IPhoneIcon}
            title='Переходы на пуш iOS'
            {...ios_devices}
          />
        </Grid>
        <Grid xs={6} lg={4} item>
          <AnalyticsCard
            icon={AndroidIcon}
            title='Переходы на пуш Android'
            {...android_devices}
          />
        </Grid>
      </AnalyticsCardsContainer>
      <AnalyticsCardsContainer>
        <Grid xs={6} lg={4} item>
          <AnalyticsLineChartCard
            title='Поделились'
            value={shareTotal}
            changeInValue={shareDifference}
            xAxisData={shareData?.map(item => item.date) || []}
            yAxisData={shareData?.map(item => item.count) || []}
            color={COLORS.RED}
          />
        </Grid>
        <Grid xs={6} lg={4} item>
          <AnalyticsLineChartCard
            title='Просмотры'
            value={viewTotal}
            changeInValue={viewDifference}
            xAxisData={viewData?.map(item => item.date) || []}
            yAxisData={viewData?.map(item => item.count) || []}
            color={COLORS.GREEN}
          />
        </Grid>
        <Grid xs={6} lg={4} item>
          <AnalyticsLineChartCard
            title='Лайки'
            value={likeTotal}
            changeInValue={likeDifference}
            xAxisData={likeData?.map(item => item.date) || []}
            yAxisData={likeData?.map(item => item.count) || []}
            color={COLORS.GOLDEN}
          />
        </Grid>
      </AnalyticsCardsContainer>
    </AnalyticsInnerPageContainer>
  );
};
