import { FC } from 'react';
import { styled } from '@mui/system';
import { Box, LinearProgress, Paper } from '@mui/material';
import { COLORS, formatNumber } from 'utils';

const StyledContainer = styled(Paper)({
  backgroundColor: COLORS.WHITE,
  width: '100%',
  padding: '1.25rem',
  display: 'flex',
  flexDirection: 'column',
  gap: '1.25rem',
  marginBottom: '1.25rem',
  height: '100%',
  '&.fullHeight': {
    height: '100%',
    justifyContent: 'space-between',
  },
  '& .header': {
    fontSize: '1.875rem',
    fontWeight: 600,
    lineHeight: '2.375rem',
    fontFamily: '"SF Pro Display", sans-serif',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  '& .linearProgress': {
    backgroundColor: COLORS.RED,
    height: '0.5rem',
    borderRadius: '0.3125rem',
    '& .MuiLinearProgress-bar': {
      borderRadius: '0.3125rem',
    },
  },
  '& .footer': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: '0.875rem',
    lineHeight: '1.375rem',
    fontFamily: '"SF Pro Display", sans-serif',
    '& .title': {
      opacity: 0.5,
      fontWeight: 400,
    },
    '& .value': {
      fontWeight: 600,
    },
  },
});

interface IAnalyticsGenderCardProps {
  male: {
    value: number;
    percent: number;
  };
  female: {
    value: number;
    percent: number;
  };
  fullHeight?: boolean;
}

export const AnalyticsGenderCard: FC<IAnalyticsGenderCardProps> = ({
  male,
  female,
  fullHeight = false,
}) => {
  return (
    <StyledContainer className={fullHeight ? 'fullHeight' : ''}>
      <Box className='header'>
        <Box>{formatNumber(male.value)}</Box>
        <Box>{formatNumber(female.value)}</Box>
      </Box>
      <LinearProgress
        className='linearProgress'
        color='primary'
        variant='determinate'
        value={male.percent}
      />
      <Box className='footer'>
        <Box>
          <Box className='title'>Мужчины</Box>
          <Box className='value'>{male.percent}%</Box>
        </Box>
        <Box>
          <Box className='title'>Женщины</Box>
          <Box className='value'>{female.percent}%</Box>
        </Box>
      </Box>
    </StyledContainer>
  );
};
