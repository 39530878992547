/* eslint-disable react/prop-types */
import { useState, useEffect, useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES, UserContext } from 'app';

import { Box } from '@mui/material';
import { CreateNewsSidebar, NewsPreviewComponent } from 'components';
import { COLORS, prepareNewsDateFormat } from 'utils';
import { Button } from 'UI';
import { EyeIcon, EditIcon } from 'assets';
import {
  useCreateNewsItem,
  useUpdateNewsItem,
  useGetCategoriesList,
  useGetSpacesList,
  useGetGroupsList,
} from 'hooks';
import {
  StyledBody,
  StyledEditorWrapper,
  EditorHeader,
  StyledPreview,
} from './styledComponents';
import { Editor } from './Editor';
import { EditorJsToHtml } from './utils';

const DEFAULT_SIDEBAR_VALUES = {
  category_id: 0,
  name: '',
  space_id: 0,
  group_id: 0,
  description: '',
  is_active: true,
  comments_allowed: true,
  has_push_notification: false,
  published_at: new Date().toISOString(),
  pushed_at: new Date().toISOString(),
  tags: [],
  cover: '',
  show_in_slider: false,
  position_in_slider: 0,
  is_pinned: false,
};

export const CreateNewsBody = ({
  defaultContent,
  defaultContentJson,
  defaultSidebarValues,
  purpose,
}) => {
  const { space_id } = useContext(UserContext);
  const navigate = useNavigate();
  const [rawData, setRawData] = useState({});
  const [previewHtml, setPreviewHtml] = useState('');
  const [showPreview, setShowPreview] = useState(false);
  const [parameters, setParameters] = useState(DEFAULT_SIDEBAR_VALUES);
  const [showGroups, setShowGroups] = useState(false);
  const { data: categories } = useGetCategoriesList(parameters.space_id);
  const { data: spacesList } = useGetSpacesList();
  const { data: groups } = useGetGroupsList(parameters.space_id);

  const { mutateAsync: createNews } = useCreateNewsItem();
  const { mutateAsync: updateNews } = useUpdateNewsItem();

  const categoryOptions = useMemo(() => {
    return (
      categories?.map(category => ({
        label: category.name,
        value: category.id,
      })) || []
    );
  }, [categories]);

  const spaceOptions = useMemo(() => {
    return (
      spacesList?.map(space => ({
        label: space.name,
        value: space.id,
      })) || []
    );
  }, [spacesList]);

  const groupOptions = useMemo(() => {
    return (
      groups?.map(group => ({
        label: group.name,
        value: group.id,
      })) || []
    );
  }, [groups]);

  useEffect(() => {
    if (defaultSidebarValues?.id && purpose === 'edit') {
      setParameters(prevInfo => ({
        ...prevInfo,
        ...defaultSidebarValues,
        space_id: defaultSidebarValues.space?.id || space_id,
      }));
      setShowGroups(!!defaultSidebarValues?.group_id);
    } else {
      setParameters(prevInfo => ({
        ...prevInfo,
        space_id: defaultSidebarValues.space?.id || space_id,
      }));
    }
  }, [defaultSidebarValues, space_id]);

  useEffect(() => {
    if (defaultContentJson?.time) {
      setRawData(defaultContentJson);
      setPreviewHtml(defaultContent);
    }
  }, [defaultContentJson, defaultContent]);

  const handleFieldChange = (fieldName, newValue) => {
    if (fieldName === 'category_id' && purpose === 'edit') {
      setParameters(prevInfo => ({
        ...prevInfo,
        new_category_id: newValue,
      }));
      return;
    }
    if (fieldName === 'space_id') {
      setParameters(prevInfo => ({
        ...prevInfo,
        category_id: 0,
        group_id: 0,
      }));
    }
    setParameters(prevInfo => ({
      ...prevInfo,
      [fieldName]: newValue,
    }));
  };

  const submitNews = () => {
    if (purpose === 'edit') {
      updateNews({
        ...parameters,
        content_json: JSON.stringify(rawData),
        content: previewHtml,
      })
        .then(() => {
          goToDefault();
          navigate(ROUTES.news);
        })
        .catch(() => {});
      return;
    }
    createNews({
      ...parameters,
      pushed_at: prepareNewsDateFormat(parameters.pushed_at),
      published_at: prepareNewsDateFormat(parameters.published_at),
      content_json: JSON.stringify(rawData),
      content: previewHtml,
    })
      .then(() => {
        goToDefault();
        navigate(ROUTES.news);
      })
      .catch(() => {});
  };

  const cancelNews = () => {
    goToDefault();
    navigate(ROUTES.news);
  };

  const goToDefault = () => {
    setRawData({});
    setPreviewHtml('');
    setShowPreview(false);
    setParameters(DEFAULT_SIDEBAR_VALUES);
  };

  const saveRawData = data => {
    setRawData(data);
    setPreviewHtml(EditorJsToHtml(data));
  };

  const openPreview = () => {
    setShowPreview(true);
  };

  const closePreview = () => {
    setShowPreview(false);
  };

  return !showPreview ? (
    <StyledBody>
      <CreateNewsSidebar
        parameters={parameters}
        categoryOptions={categoryOptions}
        spaceOptions={spaceOptions}
        groupOptions={groupOptions}
        showGroups={showGroups}
        handleFieldChange={handleFieldChange}
        setShowGroups={setShowGroups}
        onSubmit={submitNews}
        onCancel={cancelNews}
      />
      <StyledEditorWrapper>
        <EditorHeader>
          <Box className='title'>Редактор</Box>
          <Button
            className='button'
            variant='text'
            startIcon={<EyeIcon color={COLORS.BLUE} />}
            onClick={() => openPreview()}
          >
            Просмотр
          </Button>
        </EditorHeader>

        <Editor rawData={rawData} saveRawData={saveRawData} />
      </StyledEditorWrapper>
    </StyledBody>
  ) : (
    <StyledPreview>
      <Box className='header'>
        <Box className='title'>Просмотр</Box>
        <Box className='buttons'>
          <Button
            className='button'
            variant='text'
            startIcon={<EditIcon color={COLORS.BLUE} />}
            onClick={() => closePreview()}
          >
            Редактировать
          </Button>
        </Box>
      </Box>
      <NewsPreviewComponent data={{ ...parameters, content: previewHtml }} />
    </StyledPreview>
  );
};
