import { FC, ReactNode } from 'react';
import { styled } from '@mui/system';
import { Box } from '@mui/material';
import { Modal, ModalFooter, ModalTitle } from 'UI';

const StyledModalContent = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '1.25rem',
  width: '100%',
});

const StyledTextBox = styled(Box)({
  fontFamily: '"SF Pro Display", sans-serif',
  fontSize: '0.75rem',
  lineHeight: '1.25rem',
  marginTop: '-1rem',
  textAlign: 'center',
  opacity: 0.5,
});

interface IDeleteModalProps {
  open: boolean;
  title: string;
  description?: string;
  onClose: () => void;
  onConfirm: () => void;
  children?: ReactNode;
}

export const DeleteModal: FC<IDeleteModalProps> = ({
  open,
  title,
  description,
  onClose,
  onConfirm,
  children,
}) => {
  return (
    <Modal open={open} onClose={onClose}>
      <StyledModalContent>
        <ModalTitle title={title} style={{ textAlign: 'center' }} />
        <StyledTextBox>{description}</StyledTextBox>
        {children}
        <ModalFooter
          onCancel={onClose}
          onConfirm={onConfirm}
          confirmButtonText='Удалить'
          cancelButtonText='Отменить'
          confirmButtonColor='secondaryRed2'
          buttonSize='large'
        />
      </StyledModalContent>
    </Modal>
  );
};
