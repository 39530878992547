import { FC, useContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Popover } from '@mui/material';
import { UserContext, UserContextType, ROUTES } from 'app';
import { Button } from 'UI';
import {
  getAnalyticsNewsDownloadFile,
  getAnalyticsUsersDownloadFile,
  getAnalyticsShowcasePartnersDownloadFile,
  getAnalyticsShowcaseClientsDownloadFile,
} from 'api';
import { FilterContext, fileDownloadByLink } from 'utils';
import { DoneIcon, DownloadIcon, LoadingIcon } from 'assets';

import { StyledPopoverContainer } from './styledComponents';
import {
  IFiles,
  DEFAULT_FILES,
  FILE_ROUTES,
  FileRoutes,
  IReportDownloadProps,
} from './utils';

export const ReportDownload: FC<IReportDownloadProps> = ({
  showPopover,
  anchorEl,
  handleClose,
}) => {
  const { pathname } = useLocation();
  const { space_id } = useContext(UserContext) as UserContextType;
  const { startDate, endDate } = useContext(FilterContext);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loadingWhich, setLoadingWhich] = useState<FileRoutes | null>(null);
  const [fileLinks, setFileLinks] = useState<IFiles>(DEFAULT_FILES);

  useEffect(() => {
    setFileLinks(DEFAULT_FILES);
  }, [startDate, endDate, space_id]);

  const saveFileLink = (file: string, key: FileRoutes) => {
    setFileLinks(prev => ({
      ...prev,
      [key]: file,
    }));
  };

  const prepareBtnClickHandler = (optionalKey?: FileRoutes) => {
    if (pathname === ROUTES.analytics.showcases) {
      if (optionalKey === FILE_ROUTES.SHOWCASE_PARTNERS) {
        prepareButtonHandler(FILE_ROUTES.SHOWCASE_PARTNERS);
      }
      if (optionalKey === FILE_ROUTES.SHOWCASE_CLIENTS) {
        prepareButtonHandler(FILE_ROUTES.SHOWCASE_CLIENTS);
      }
      return;
    }
    if (pathname === ROUTES.analytics.users) {
      prepareButtonHandler(FILE_ROUTES.USERS);
      return;
    }
    if (pathname === ROUTES.analytics.news) {
      prepareButtonHandler(FILE_ROUTES.NEWS);
    }
  };

  const downloadBtnClickHandler = (optionalKey?: FileRoutes) => {
    const key = optionalKey || (pathname.split('/').pop() as FileRoutes);
    if (fileLinks[key]) {
      if (key === FILE_ROUTES.USERS) {
        fileDownloadByLink(
          fileLinks[key],
          `REGISTER_STAT_${startDate}_${endDate}.xlsx`,
        );
      }
      fileDownloadByLink(
        fileLinks[key],
        `${space_id}_${startDate}_${endDate}.xlsx`,
      );
    }
  };

  const getPrepareFunction = (key: FileRoutes) => {
    switch (key) {
      case FILE_ROUTES.SHOWCASE_PARTNERS:
        return getAnalyticsShowcasePartnersDownloadFile;
      case FILE_ROUTES.SHOWCASE_CLIENTS:
        return getAnalyticsShowcaseClientsDownloadFile;
      case FILE_ROUTES.USERS:
        return getAnalyticsUsersDownloadFile;
      case FILE_ROUTES.NEWS:
        return getAnalyticsNewsDownloadFile;
      default:
        return null;
    }
  };

  const prepareButtonHandler = (key: FileRoutes) => {
    const prepareFunction = getPrepareFunction(key);
    if (!prepareFunction) {
      return;
    }
    setIsLoading(true);
    setLoadingWhich(key);
    prepareFunction({ start_date: startDate, end_date: endDate })
      .then(res => {
        saveFileLink(res, key);
      })
      .catch(() => {})
      .finally(() => {
        setIsLoading(false);
        setLoadingWhich(null);
      });
  };

  const doesFileExist = (optionalKey?: FileRoutes) => {
    const key = optionalKey || (pathname.split('/').pop() as FileRoutes);
    return !!fileLinks[key];
  };

  const prepareButtonConfig = (optionalKey?: FileRoutes) => {
    const key = optionalKey || (pathname.split('/').pop() as FileRoutes);
    return {
      variant: 'contained' as const,
      color: 'customWhite' as const,
      size: 'medium' as const,
      onClick: () => prepareBtnClickHandler(key),
      endIcon:
        isLoading && loadingWhich === key ? (
          <LoadingIcon />
        ) : doesFileExist(key) ? (
          <DoneIcon />
        ) : null,
    };
  };

  const downloadButtonConfig = (optionalKey?: FileRoutes) => {
    const key = optionalKey || (pathname.split('/').pop() as FileRoutes);
    return {
      variant: 'contained' as const,
      color: 'customWhite' as const,
      onClick: () => downloadBtnClickHandler(key),
      startIcon: <DownloadIcon opacity={!doesFileExist(key) ? 0.5 : 1} />,
      disabled: !doesFileExist(key),
    };
  };

  return (
    <Popover
      id={showPopover ? 'downloadReportPopover' : undefined}
      open={showPopover}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      {pathname === ROUTES.analytics.showcases ? (
        <StyledPopoverContainer>
          <Button {...prepareButtonConfig(FILE_ROUTES.SHOWCASE_PARTNERS)}>
            Подготовить отчет о партнерах
          </Button>
          <Button {...downloadButtonConfig(FILE_ROUTES.SHOWCASE_PARTNERS)}>
            Скачать отчет о партнерах
          </Button>
          <Button {...prepareButtonConfig(FILE_ROUTES.SHOWCASE_CLIENTS)}>
            Подготовить отчет о клиентах
          </Button>
          <Button {...downloadButtonConfig(FILE_ROUTES.SHOWCASE_CLIENTS)}>
            Скачать отчето клиентах
          </Button>
        </StyledPopoverContainer>
      ) : (
        <StyledPopoverContainer>
          <Button {...prepareButtonConfig()}>Подготовить отчет</Button>
          <Button {...downloadButtonConfig()}>Скачать отчет</Button>
        </StyledPopoverContainer>
      )}
    </Popover>
  );
};
