import * as Pages from 'pages';
import { ROLES } from 'utils';
import { IRoutes } from '../types';

export const Stories: IRoutes = {
  stories: {
    path: '/stories',
    title: 'stories',
    element: <Pages.Stories />,
    roles: [ROLES.ADMINSTRATOR],
  },
};
