import { FC, useMemo } from 'react';
import { Grid } from '@mui/material';
import { useSearchParams, useGetWorkoutsCalendars } from 'hooks';
import { WorkoutsCalendarCard } from 'components';
import { Pagination } from 'UI';
import {
  StyledCardsContainer,
  StyledPaginationContainer,
} from './styledComponents';

export const WorkoutsCalendarBody: FC = () => {
  const { searchParams, getParam } = useSearchParams();
  const params = useMemo(() => {
    return {
      page: getParam('page'),
      search: getParam('search'),
    };
  }, [searchParams]);
  const {
    data: { data: workoutsCalendars, last_page: pageCount } = {
      data: [],
      last_page: 1,
    },
  } = useGetWorkoutsCalendars(params);
  return (
    <>
      <StyledCardsContainer container spacing={3}>
        {workoutsCalendars?.map(item => (
          <Grid item xs={6} lg={4} key={item.id}>
            <WorkoutsCalendarCard {...item} />
          </Grid>
        ))}
      </StyledCardsContainer>
      <StyledPaginationContainer item xs={12}>
        <Pagination count={pageCount} />
      </StyledPaginationContainer>
    </>
  );
};
