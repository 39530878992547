import { FC, useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/system';
import { Box, TextField, Popover } from '@mui/material';
import {
  FormItem,
  StyledFlexBox,
  Button,
  SearchSelectInput,
  ImageInput,
  StyledSwitch,
} from 'UI';
import { COLORS, MEDIA_TYPES, DEFAULT_PRODUCT_COVER_URL } from 'utils';
import {
  IProductAttribute,
  IProductAttributeValue,
  IShopProductReq,
} from 'types';
import { PercentIcon2 as PercentIcon, PlusIcon, StarIcon } from 'assets';
import {
  useCreateShopProduct,
  useGetShopProduct,
  useUpdateShopProduct,
  useGetShopCategoriesList,
  useSearchParams,
} from 'hooks';
import { CreateProductPhone, StyledSwitchFormControl } from 'components';
import { ROUTES } from 'app';
import { AttributeCard } from './AttributeCard';

const StyledMainContainer = styled(Box)({
  display: 'flex',
  gap: '1.25rem',
  paddingTop: '1.25rem',
  width: '100%',
  alignItems: 'top',
});

const StyledContainer = styled(Box)({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '0.9375rem',
  padding: '1.25rem',
  borderRadius: '0.5rem',
  backgroundColor: COLORS.WHITE,
  height: 'calc(100vh - 8rem)',
  overflowY: 'auto',
  '& .MuiTextField-root': {
    width: '100%',
  },
  '& h2': {
    fontSize: '1.125rem',
    lineHeight: '1.625rem',
    fontWeight: 600,
    fontFamily: '"SF Pro Display", sans-serif',
    margin: '0',
  },
  '& .addButton': {
    maxWidth: '18rem',
  },
});

const StyledPopoverContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '0.9375rem',
  padding: '1.25rem',
  backgroundColor: COLORS.WHITE,
  border: `1px solid rgba(0, 0, 0, 0.1)`,
  borderRadius: '0.5rem',
  minWidth: '20rem',
  '& .MuiTextField-root': {
    width: '100%',
  },
});

const DEFAULT_VALUES: IShopProductReq = {
  name: '',
  description: '',
  cover: DEFAULT_PRODUCT_COVER_URL,
  is_active: false,
  show_in_slider: false,
  position_in_slider: 0,
  position_in_list: 0,
  amount: 0,
  price: 0,
  discount: 0,
  sku: '',
  attributes: [],
};

const DEFAULT_ATTRIBUTE: IProductAttribute = {
  id: 0,
  images: [],
  values: [
    {
      id: 0,
      type: 1,
      title: 'Цвет',
      value: '',
    },
  ],
};

export const CreateProductForm: FC = () => {
  const { getParam, searchParams } = useSearchParams();
  const id = useMemo(() => +getParam('id'), [searchParams]);
  const category_id = useMemo(() => +getParam('category_id'), [searchParams]);
  const navigate = useNavigate();
  const { data: productInfoData } = useGetShopProduct({ id, category_id });
  const [productInfo, setProductInfo] =
    useState<IShopProductReq>(DEFAULT_VALUES);

  const [showAddAttributeValuePopover, setShowAddAttributeValuePopover] =
    useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [attributeIndex, setAttributeIndex] = useState<number>(0);
  const [attributeValueName, setAttributeValueName] = useState<string>('');

  const { mutateAsync: createShopProduct } = useCreateShopProduct();
  const { mutateAsync: updateShopProduct } = useUpdateShopProduct();
  const { data: categoriesList } = useGetShopCategoriesList();

  const categoryOptions = useMemo(() => {
    return (
      categoriesList?.map(category => ({
        label: category.name,
        value: +category.id,
      })) || []
    );
  }, [categoriesList]);

  useEffect(() => {
    if (id && category_id) {
      setProductInfo({
        id: productInfoData?.id || 0,
        name: productInfoData?.name || '',
        description: productInfoData?.description || '',
        cover: productInfoData?.cover || '',
        is_active: productInfoData?.is_active || false,
        show_in_slider: productInfoData?.show_in_slider || false,
        position_in_slider: productInfoData?.position_in_slider || 0,
        position_in_list: productInfoData?.position_in_list || 0,
        amount: productInfoData?.amount || 0,
        price: productInfoData?.price || 0,
        discount: productInfoData?.discount || 0,
        sku: productInfoData?.sku || '',
        category_id: productInfoData?.category?.id || 0,
        new_category_id: 0,
        attributes: productInfoData?.attributes || [],
      });
    }
  }, [productInfoData, id, category_id]);

  const handleFieldChange = <T extends keyof IShopProductReq>(
    fieldName: T,
    newValue: IShopProductReq[T],
  ) => {
    if (fieldName === 'category_id') {
      setProductInfo(prevProdInfo => ({
        ...prevProdInfo,
        new_category_id: newValue as number,
        [fieldName]: newValue as number,
      }));
      return;
    }
    setProductInfo(prevProdInfo => ({
      ...prevProdInfo,
      [fieldName]: newValue,
    }));
  };

  const addAttribute = () => {
    setProductInfo(prevProdInfo => ({
      ...prevProdInfo,
      attributes: [...prevProdInfo.attributes, DEFAULT_ATTRIBUTE],
    }));
  };

  const addImageToAttribute = (attrIndex: number, images: string[]) => {
    const updatedAttributes = [...productInfo.attributes];
    const updatedAttribute = { ...updatedAttributes[attrIndex] };
    updatedAttribute.images = [
      ...updatedAttribute.images,
      ...images.map((img, ind) => ({ id: -ind, url: img })),
    ];
    updatedAttributes[attrIndex] = { ...updatedAttribute };
    setProductInfo(prevProdInfo => ({
      ...prevProdInfo,
      attributes: updatedAttributes,
    }));
  };

  const removeImageFromAttribute = (attrIndex: number, image: string) => {
    const updatedAttributes = [...productInfo.attributes];
    const updatedAttribute = { ...updatedAttributes[attrIndex] };
    updatedAttribute.images = updatedAttribute.images.filter(
      img => img.url !== image,
    );
    updatedAttributes[attrIndex] = { ...updatedAttribute };
    setProductInfo(prevProdInfo => ({
      ...prevProdInfo,
      attributes: updatedAttributes,
    }));
  };

  const addAttributeValue = (attrIndex: number) => {
    const updatedAttributes = [...productInfo.attributes];
    const updatedAttribute = { ...updatedAttributes[attrIndex] };
    updatedAttribute.values = [
      ...updatedAttribute.values,
      {
        id: 0,
        type: 2,
        title: attributeValueName,
        value: '',
      },
    ];

    updatedAttributes[attrIndex] = { ...updatedAttribute };
    setProductInfo(prevProdInfo => ({
      ...prevProdInfo,
      attributes: updatedAttributes,
    }));
    closePopover();
  };

  const handleChangeValueParameter = <T extends keyof IProductAttributeValue>(
    attrIndex: number,
    valIndex: number,
    fieldName: T,
    newValue: IProductAttributeValue[T],
  ) => {
    const updatedAttributes = [...productInfo.attributes];
    const updatedAttribute = { ...updatedAttributes[attrIndex] };
    const updatedValues = [...updatedAttribute.values];
    const updatedValue = { ...updatedValues[valIndex] };
    updatedValue[fieldName] = newValue;
    updatedValues[valIndex] = { ...updatedValue };
    updatedAttribute.values = updatedValues;
    updatedAttributes[attrIndex] = { ...updatedAttribute };
    setProductInfo(prevProdInfo => ({
      ...prevProdInfo,
      attributes: updatedAttributes,
    }));
  };

  const openPopover = (
    event: React.MouseEvent<HTMLElement>,
    attrIndex: number,
  ) => {
    setAttributeIndex(attrIndex);
    setAnchorEl(event.currentTarget);
    setShowAddAttributeValuePopover(true);
  };

  const closePopover = () => {
    setAttributeIndex(0);
    setAttributeValueName('');
    setAnchorEl(null);
    setShowAddAttributeValuePopover(false);
  };

  const saveChanges = () => {
    if (id && category_id) {
      updateShopProduct(productInfo)
        .then(() => {
          closePage();
        })
        .catch(() => {});
    } else {
      createShopProduct(productInfo)
        .then(() => {
          closePage();
        })
        .catch(() => {});
    }
  };

  const ignoreChanges = () => {
    closePage();
  };

  const closePage = () => {
    setProductInfo(DEFAULT_VALUES);
    navigate(ROUTES.shop.products);
  };

  return (
    <StyledMainContainer>
      <Popover
        id={
          showAddAttributeValuePopover
            ? 'showAddAttributeValuePopover'
            : undefined
        }
        open={showAddAttributeValuePopover}
        anchorEl={anchorEl}
        onClose={closePopover}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <StyledPopoverContainer>
          <FormItem label='Название'>
            <TextField
              variant='standard'
              color='primary'
              placeholder='Введите название свойства'
              name='name'
              value={attributeValueName}
              onChange={e => setAttributeValueName(e.target.value)}
            />
          </FormItem>
          <Button
            startIcon={<PlusIcon color={COLORS.WHITE} />}
            onClick={() => addAttributeValue(attributeIndex)}
          >
            Добавить
          </Button>
        </StyledPopoverContainer>
      </Popover>
      <StyledContainer>
        <ImageInput
          inputId='spaceLogo'
          title='Добавьте обложку товара'
          descriptionWithoutImage='Загрузите изображение (1080x1080 px, до 1 мб)'
          withDescription
          image={productInfo.cover}
          setImage={newImg => handleFieldChange('cover', newImg)}
          mediaType={MEDIA_TYPES.PRODUCT_COVER}
          imageFixedWidth={1080}
          imageFixedHeight={1080}
        />
        <FormItem label='Название'>
          <TextField
            variant='standard'
            color='primary'
            placeholder='Введите название товара'
            name='name'
            value={productInfo.name}
            onChange={e => handleFieldChange('name', e.target.value)}
          />
        </FormItem>
        <FormItem label='Описание'>
          <TextField
            variant='standard'
            color='primary'
            placeholder='Введите текст описания'
            name='description'
            value={productInfo.description}
            onChange={e => handleFieldChange('description', e.target.value)}
          />
        </FormItem>
        <FormItem label='Категория'>
          <SearchSelectInput
            options={categoryOptions}
            value={productInfo.category_id}
            onChange={newValue =>
              handleFieldChange('category_id', newValue as number)
            }
            placeholder='Выбрать категорию'
            variant='standard'
          />
        </FormItem>
        <StyledFlexBox className='fullDivWidth'>
          <FormItem label='Цена'>
            <TextField
              variant='standard'
              color='primary'
              placeholder='Введите цену'
              name='price'
              value={productInfo.price}
              onChange={e =>
                handleFieldChange('price', e.target.value as unknown as number)
              }
              InputProps={{
                startAdornment: <StarIcon style={{ marginRight: '0.75rem' }} />,
              }}
            />
          </FormItem>
          <FormItem label='Скидка'>
            <TextField
              variant='standard'
              color='primary'
              placeholder='Введите скидку'
              name='discount'
              value={productInfo.discount}
              onChange={e =>
                handleFieldChange(
                  'discount',
                  e.target.value as unknown as number,
                )
              }
              InputProps={{
                startAdornment: (
                  <PercentIcon style={{ marginRight: '0.75rem' }} />
                ),
              }}
            />
          </FormItem>
        </StyledFlexBox>
        <StyledFlexBox className='fullDivWidth'>
          <FormItem label='SKU (Артикул)'>
            <TextField
              variant='standard'
              color='primary'
              placeholder='Введите артикул'
              name='sku'
              value={productInfo.sku}
              onChange={e => handleFieldChange('sku', e.target.value)}
            />
          </FormItem>
          <FormItem label='Количество'>
            <TextField
              variant='standard'
              color='primary'
              placeholder='Введите количество'
              name='amount'
              value={productInfo.amount}
              onChange={e =>
                handleFieldChange('amount', e.target.value as unknown as number)
              }
            />
          </FormItem>
        </StyledFlexBox>
        <StyledFlexBox className='fullDivWidth'>
          <StyledSwitchFormControl
            label='Активный'
            labelPlacement='start'
            control={
              <StyledSwitch
                checked={productInfo.is_active}
                onChange={() =>
                  handleFieldChange('is_active', !productInfo.is_active)
                }
              />
            }
          />
          <StyledSwitchFormControl
            label='Показывать в слайдере'
            labelPlacement='start'
            control={
              <StyledSwitch
                checked={productInfo.show_in_slider}
                onChange={() =>
                  handleFieldChange(
                    'show_in_slider',
                    !productInfo.show_in_slider,
                  )
                }
              />
            }
          />
        </StyledFlexBox>
        <StyledFlexBox className='fullDivWidth'>
          <FormItem label='Позиция в листе'>
            <TextField
              variant='standard'
              color='primary'
              placeholder='Введите позицию в листе'
              name='position_in_list'
              value={productInfo.position_in_list}
              onChange={e =>
                handleFieldChange(
                  'position_in_list',
                  e.target.value as unknown as number,
                )
              }
            />
          </FormItem>
          <FormItem label='Позиция в слайдере'>
            <TextField
              variant='standard'
              color='primary'
              placeholder='Введите позицию в слайдере'
              name='position_in_slider'
              value={productInfo.position_in_slider}
              onChange={e =>
                handleFieldChange(
                  'position_in_slider',
                  e.target.value as unknown as number,
                )
              }
            />
          </FormItem>
        </StyledFlexBox>

        <h2>Атрибуты</h2>
        {productInfo.attributes?.map((attr, index) => (
          <AttributeCard
            index={index}
            images={attr.images}
            values={attr.values}
            addImage={addImageToAttribute}
            removeImage={removeImageFromAttribute}
            handleChangeValueParameter={handleChangeValueParameter}
            openPopover={openPopover}
          />
        ))}
        <Button
          startIcon={<PlusIcon color={COLORS.BLUE} />}
          color='customLightBlue'
          onClick={addAttribute}
          className='addButton'
        >
          Добавить атрибут
        </Button>
        <StyledFlexBox>
          <Button
            onClick={ignoreChanges}
            variant='contained'
            size='large'
            color='customGray'
          >
            Отмена
          </Button>
          <Button
            variant='contained'
            size='large'
            color='primary'
            onClick={saveChanges}
          >
            Сохранить
          </Button>
        </StyledFlexBox>
      </StyledContainer>
      <CreateProductPhone
        cover={productInfo.cover}
        name={productInfo.name}
        categoryName={
          categoryOptions.find(cat => cat.value === productInfo.category_id)
            ?.label
        }
        price={productInfo.price}
      />
    </StyledMainContainer>
  );
};
