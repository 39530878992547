import { FC } from 'react';
import { Button, PageHeader, GlobalSearch } from 'UI';
import { PlusIcon } from 'assets';

interface IAchievementsHeaderProps {
  openAddAchievementModal: () => void;
}

export const AchievementsHeader: FC<IAchievementsHeaderProps> = ({
  openAddAchievementModal,
}) => {
  return (
    <PageHeader title='Достижения'>
      <GlobalSearch />
      <Button
        variant='contained'
        startIcon={<PlusIcon />}
        onClick={openAddAchievementModal}
      >
        Создать
      </Button>
    </PageHeader>
  );
};
