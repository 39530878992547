import { styled } from '@mui/system';
import { Box } from '@mui/material';
import { COLORS } from 'utils';

export const ChallengeCardContainer = styled(Box)(
  ({ bgImg }: { bgImg: string }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    borderRadius: '0.5rem',
    position: 'relative',
    padding: '1rem',
    color: COLORS.WHITE,
    minHeight: '12rem',
    background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.4)), url(${bgImg}) center/cover no-repeat`,
    '&:hover': {
      '& .buttons': {
        visibility: 'visible',
      },
    },
  }),
);

export const ChallengeCardHeader = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  '& .buttons': {
    display: 'flex',
    alignItems: 'center',
    gap: '0.625rem',
    visibility: 'hidden',
    '& svg': {
      cursor: 'pointer',
    },
    '& .switch': {
      marginTop: '-0.5rem',
      marginLeft: '-0.625rem',
    },
  },
});

export const ChallengeCardBody = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '0.125rem',
  fontFamily: '"SF Pro Text", sans-serif',
  '& .title': {
    fontSize: '1.25rem',
    lineHeight: '1.5rem',
    fontWeight: 600,
    cursor: 'pointer',
  },
  '& .info': {
    fontSize: '0.75rem',
    lineHeight: '1.25rem',
    fontWeight: 400,
  },
});
