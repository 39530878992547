import { FC } from 'react';
import { COLORS } from 'utils';

interface IUpIcon {
  color?: string;
  className?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
}

export const UpIcon: FC<IUpIcon> = ({
  color = COLORS.BLACK,
  className,
  style,
  onClick,
}) => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      style={style}
      onClick={onClick}
    >
      <g>
        <path
          d='M12 18L12 5.5M12 5.5L6 11.5M12 5.5L18 11.5'
          stroke={color}
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
    </svg>
  );
};
