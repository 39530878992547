import { FC } from 'react';
import { COLORS } from 'utils';

interface ILeftArrowIcon {
  color?: string;
  className?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
}

export const LeftArrowIcon: FC<ILeftArrowIcon> = ({
  color = COLORS.BLACK,
  className,
  style,
  onClick,
}) => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      style={style}
      onClick={onClick}
    >
      <path
        d='M18.5 12H6M6 12L12 6M6 12L12 18'
        stroke={color}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
