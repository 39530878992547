import { FC, useState, MouseEvent, useMemo } from 'react';
import { Box, TextField, Popover } from '@mui/material';
import { useGetAchievements } from 'hooks';
import { Button, FormItem, ImageInput, SearchInput, StyledFlexBox } from 'UI';
import {
  DeleteIcon,
  DownIcon,
  EditIcon,
  UpIcon,
  PlusIcon,
  MoneyIcon,
  StarIcon,
} from 'assets';
import { AchievementSortParams, IAchievement, IPrize, IPrizeItem } from 'types';
import {
  COLORS,
  MEDIA_TYPES,
  PRIZE_TYPES,
  PRIZ_POINTS_URL,
  isStringNumber,
} from 'utils';
import {
  StyledButtonsContainer,
  StyledHeader,
  StyledPlaceItem,
  StyledPopoverRewardContainer,
  StyledPrizeItem,
  StyledPrizesContainer,
  StyledPopoverAchievementContainer,
} from './styledComponents';

const DEFAULT_REWARD: IPrizeItem = {
  id: 0,
  name: 'Баллы',
  description: '',
  cover: PRIZ_POINTS_URL!,
  type: 3,
};

interface IPlaceItemProps {
  placeInfo: IPrize;
  deletePlace: (_placeIndex: number) => void;
  movePlacePrizesUp: (_placeIndex: number) => void;
  movePlacePrizesDown: (_placeIndex: number) => void;
  openPrizeDrawer: (_placeIndex: number, _editingItem?: IPrizeItem) => void;
  openAchievementDrawer: (
    _placeIndex: number,
    _editingItem?: IPrizeItem,
  ) => void;
  deletePrize: (_placeIndex: number, _prizeIndex: number) => void;
  addPrize: (
    _prize: IPrizeItem,
    _placeIndex?: number,
    _prizeIndex?: number,
  ) => void;
  handleParameterChange: <T extends keyof IPrize>(
    _placeIndex: number,
    _fieldName: T,
    _value: IPrize[T],
  ) => void;
}

export const PlaceItem: FC<IPlaceItemProps> = ({
  placeInfo,
  deletePlace,
  movePlacePrizesUp,
  movePlacePrizesDown,
  openPrizeDrawer,
  openAchievementDrawer,
  deletePrize,
  addPrize,
  handleParameterChange,
}) => {
  const [showAddReward, setShowAddReward] = useState<boolean>(false);
  const [showAddAchievement, setShowAddAchievement] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const [reward, setReward] = useState<IPrizeItem>(DEFAULT_REWARD);

  const [searchAchievement, setSearchAchievement] = useState<string>('');

  const params = useMemo(() => {
    return {
      page: 1,
      search: searchAchievement,
      column: 'name' as AchievementSortParams,
      sort_type: 'desc',
    };
  }, [searchAchievement]);

  const { data: achievements } = useGetAchievements(params);

  // const changeRewardType = () => {
  //   if (reward.type === 3) {
  //     setReward({ ...reward, type: 4, name: 'Монеты', cover: PRIZ_COINS_URL });
  //   } else {
  //     setReward({ ...reward, type: 3, name: 'Баллы', cover: PRIZ_POINTS_URL });
  //   }
  // };

  const openAddReward = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setShowAddReward(true);
  };

  const hideAddReward = () => {
    setReward(DEFAULT_REWARD);
    setShowAddReward(false);
    setAnchorEl(null);
  };

  const addReward = () => {
    addPrize(reward, placeInfo.place_in_the_ladder, reward?.position);
    hideAddReward();
  };

  const editReward = (
    prize: IPrizeItem,
    type: 1 | 2 | 3 | 4,
    e: MouseEvent<HTMLButtonElement>,
  ) => {
    if (type === 3 || type === 4) {
      setAnchorEl(e.currentTarget);
      setShowAddReward(true);
      setReward(prize);
      return;
    }
    if (type === 1) {
      openPrizeDrawer(placeInfo.place_in_the_ladder, prize);
    }
    if (type === 2) {
      openAchievementDrawer(placeInfo.place_in_the_ladder, prize);
    }
  };

  const openAddAchievement = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setShowAddAchievement(true);
  };

  const hideAddAchievement = () => {
    setShowAddAchievement(false);
    setAnchorEl(null);
  };

  const addAchievement = (achievement: IAchievement) => {
    addPrize(
      {
        id: achievement.id,
        name: achievement.name,
        description: achievement.description,
        cover: achievement.cover,
        type: 2,
      },
      placeInfo.place_in_the_ladder,
      reward?.position,
    );
    hideAddAchievement();
  };

  const handleSearchAchievement = (value: string) => {
    setSearchAchievement(value);
  };

  const openAddAchievementDrawer = () => {
    hideAddAchievement();
    openAchievementDrawer(placeInfo.place_in_the_ladder);
  };

  return (
    <StyledPlaceItem>
      <Popover
        id={showAddReward ? 'addReward' : undefined}
        open={showAddReward}
        anchorEl={anchorEl}
        onClose={hideAddReward}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <StyledPopoverRewardContainer>
          <Box className='title'>Награда</Box>
          <Box className='body'>
            {/* <Box className='subTitleBox' onClick={() => changeRewardType()}>
              {reward.type === 3 ? <MoneyIcon /> : <StarIcon />}
              <Box className='subtitle'>
                {reward.type === 3 ? 'Монеты' : 'Баллы'}
              </Box>
            </Box> */}
            <StyledFlexBox>
              <Box className='label'>
                {reward.type === 3 ? (
                  <StarIcon color={COLORS.BLUE} opacity='1' />
                ) : (
                  <MoneyIcon color={COLORS.BLUE} opacity='1' />
                )}
                <Box className='text'>
                  {reward.type === 3 ? 'Баллы' : 'Монеты'}
                </Box>
              </Box>
              <TextField
                variant='outlined'
                color='primary'
                placeholder='50'
                name='description'
                size='small'
                value={reward.description}
                onChange={e => {
                  if (!isStringNumber(e.target.value)) return;
                  setReward({ ...reward, description: e.target.value });
                }}
              />
            </StyledFlexBox>
          </Box>
          <Box className='footer'>
            <Button onClick={addReward}>Добавить</Button>
          </Box>
        </StyledPopoverRewardContainer>
      </Popover>
      <Popover
        id={showAddAchievement ? 'addAchievement' : undefined}
        open={showAddAchievement}
        anchorEl={anchorEl}
        onClose={hideAddAchievement}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <StyledPopoverAchievementContainer>
          <SearchInput handleSearch={handleSearchAchievement} />
          {achievements?.data?.map(achievement => (
            <Box
              className='item'
              key={achievement.id}
              onClick={() => addAchievement(achievement)}
            >
              <Box className='imageBox'>
                <img src={achievement.cover} alt={achievement.name} />
              </Box>
              <Box className='name'>{achievement.name}</Box>
            </Box>
          ))}
          <Box className='buttonBox'>
            <Button
              onClick={openAddAchievementDrawer}
              variant='text'
              color='primary'
              startIcon={<PlusIcon color={COLORS.BLUE} />}
            >
              Создать
            </Button>
          </Box>
        </StyledPopoverAchievementContainer>
      </Popover>
      <StyledHeader>
        <Box className='title'>
          {placeInfo.type === 1
            ? `${placeInfo.place_in_the_ladder}  место`
            : 'За участие'}
        </Box>
        <Box className='iconButtons'>
          <Button
            variant='text'
            onClick={() => movePlacePrizesUp(placeInfo.place_in_the_ladder)}
          >
            <UpIcon />
          </Button>
          <Button
            variant='text'
            onClick={() => movePlacePrizesDown(placeInfo.place_in_the_ladder)}
          >
            <DownIcon />
          </Button>
          <Button
            variant='text'
            onClick={() => deletePlace(placeInfo.place_in_the_ladder)}
          >
            <DeleteIcon />
          </Button>
        </Box>
      </StyledHeader>
      <StyledFlexBox style={{ justifyContent: 'space-between' }}>
        <ImageInput
          inputId={`placeCover-${placeInfo.place_in_the_ladder}`}
          title='Добавьте обложку'
          descriptionWithoutImage='Загрузите изображение (1125x675 px, до 1 мб)'
          withDescription
          image={placeInfo.cover}
          setImage={newImg => {
            handleParameterChange(
              placeInfo.place_in_the_ladder,
              'cover',
              newImg,
            );
          }}
          mediaType={MEDIA_TYPES.TROPHY_COVER}
          imageFixedHeight={675}
          imageFixedWidth={1125}
        />
        <FormItem label='Необходимо набрать'>
          <TextField
            variant='standard'
            color='primary'
            placeholder='Впишите очки'
            name='required_points'
            value={placeInfo.required_points}
            onChange={e => {
              handleParameterChange(
                placeInfo.place_in_the_ladder,
                'required_points',
                e.target.value as unknown as number,
              );
            }}
          />
        </FormItem>
      </StyledFlexBox>
      <StyledPrizesContainer>
        {placeInfo.items.map((prize, prizeIndex) => (
          <StyledPrizeItem key={prize.position}>
            <Box className='image'>
              <img src={prize.cover} alt={prize.name} />
            </Box>
            <Box className='title'>{prize.name}</Box>
            <Box className='info'>{PRIZE_TYPES[prize.type - 1]}</Box>
            <Box className='editDeleteIcons'>
              <Button
                variant='text'
                onClick={e => editReward(prize, prize.type, e)}
              >
                <EditIcon />
              </Button>
              <Button
                variant='text'
                onClick={() =>
                  deletePrize(placeInfo.place_in_the_ladder, prizeIndex)
                }
              >
                <DeleteIcon />
              </Button>
            </Box>
          </StyledPrizeItem>
        ))}
      </StyledPrizesContainer>
      <StyledButtonsContainer>
        <Button
          variant='contained'
          size='large'
          color='customLightBlue'
          startIcon={<PlusIcon color={COLORS.BLUE} />}
          onClick={() => openPrizeDrawer(placeInfo.place_in_the_ladder)}
        >
          Добавить приз
        </Button>
        <Button
          variant='contained'
          size='large'
          color='customLightBlue'
          startIcon={<PlusIcon color={COLORS.BLUE} />}
          onClick={openAddAchievement}
        >
          Достижение
        </Button>
        <Button
          variant='contained'
          size='large'
          color='customLightBlue'
          startIcon={<PlusIcon color={COLORS.BLUE} />}
          onClick={openAddReward}
        >
          Награда
        </Button>
      </StyledButtonsContainer>
    </StyledPlaceItem>
  );
};
