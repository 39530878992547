import { useQuery, useMutation, useQueryClient } from 'react-query';
import {
  getCategories,
  getCategory,
  createCategory,
  updateCategory,
  deleteCategory,
  getCategoriesList,
  getNews,
  getNewsItem,
  createNewsItem,
  updateNewsItem,
  deleteNewsItem,
  getNewsComments,
  deleteNewsComment,
  deleteNewsAllComments,
} from 'api';
import {
  ICategoryGetParams,
  ICategoryRes,
  ICategory,
  INewsReqParams,
  INewsRes,
  INews,
  ICategoryListRes,
  IGetNewsCommentsParams,
  IGetCommentsRes,
} from 'types';

export const useGetCategories = (params: ICategoryGetParams) => {
  const { page, search } = params;
  return useQuery<ICategoryRes, unknown>(
    ['categories', { page, search }],
    async () => {
      const res = await getCategories(params);
      return res;
    },
    {
      retry: false,
      staleTime: Infinity,
    },
  );
};

export const useGetCategory = (id: string | number) => {
  return useQuery<ICategory, unknown>(
    ['categories', 'categoryId', id],
    async () => {
      const res = await getCategory(id);
      return res;
    },
    {
      retry: false,
      staleTime: Infinity,
    },
  );
};

export const useCreateCategory = () => {
  const queryClient = useQueryClient();
  return useMutation(createCategory, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['categories'] });
    },
    onError: () => {},
  });
};

export const useUpdateCategory = () => {
  const queryClient = useQueryClient();
  return useMutation(updateCategory, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['categories'] });
    },
    onError: () => {},
  });
};

export const useDeleteCategory = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteCategory, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['categories'] });
    },
    onError: () => {},
  });
};

export const useGetCategoriesList = (space_id: string | number) => {
  if (!space_id)
    return useQuery(['categories', 'list', space_id], () => {
      return Promise.resolve(null);
    });

  return useQuery<ICategoryListRes>(
    ['categories', 'list', space_id],
    async () => {
      const res = await getCategoriesList({ space_id });
      return res;
    },
    {
      retry: false,
      staleTime: Infinity,
    },
  );
};

export const useGetNews = (params: INewsReqParams) => {
  const { group_id, category_id, page, search, column, sort_type } = params;
  return useQuery<INewsRes, unknown>(
    ['news', { group_id, category_id, page, search, column, sort_type }],
    async () => {
      const res = await getNews(params);
      return res;
    },
    {
      staleTime: 60 * 1000,
      retry: false,
    },
  );
};

export const useGetNewsItem = (params: INewsReqParams) => {
  const { category_id, id } = params;
  return useQuery<INews, unknown>(
    ['news', 'item', { category_id, id }],
    async () => {
      const res = await getNewsItem(params);
      return res;
    },
    {
      retry: false,
      staleTime: Infinity,
    },
  );
};

export const useCreateNewsItem = () => {
  const queryClient = useQueryClient();
  return useMutation(createNewsItem, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['news'] });
    },
    onError: () => {},
  });
};

export const useUpdateNewsItem = () => {
  const queryClient = useQueryClient();
  return useMutation(updateNewsItem, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['news'] });
    },
    onError: () => {},
  });
};

export const useDeleteNewsItem = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteNewsItem, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['news'] });
    },
    onError: () => {},
  });
};

export const useGetNewsComments = (params: IGetNewsCommentsParams) => {
  const { page, search, news_id, column, sort_type } = params;
  return useQuery<IGetCommentsRes, unknown>(
    ['newsComments', { page, search, news_id, column, sort_type }],
    async () => {
      const res = await getNewsComments(params);
      return res;
    },
    {
      staleTime: 60 * 1000, // 1 minute
    },
  );
};

export const useDeleteNewsComment = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteNewsComment, {
    onSuccess: () => {
      queryClient.invalidateQueries([
        { queryKey: ['newsComments'] },
        { queryKey: ['news', 'item'] },
      ]);
    },
    onError: () => {},
  });
};

export const useDeleteNewsAllComments = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteNewsAllComments, {
    onSuccess: () => {
      queryClient.invalidateQueries([
        { queryKey: ['newsComments'] },
        { queryKey: ['news', 'item'] },
      ]);
    },
    onError: () => {},
  });
};
