import { FC, useState, useMemo } from 'react';
import { TextField } from '@mui/material';
import { FormItem, Pagination } from 'UI';
import {
  useSearchParams,
  useGetCensoredWords,
  useCreateCensoredWord,
  useDeleteCensoredWord,
} from 'hooks';

import {
  StyledContainer,
  StyledHeader,
  StyledTitle,
  StyledQuantity,
  StyledBody,
  StyledTagsBox,
  StyledTag,
  StyledPaginationBox,
} from './styledComponents';
import { TagDeleteIcon } from './TagDeleteIcon';

export const Moderations: FC = () => {
  const { getParam } = useSearchParams();
  const [tagText, setTagText] = useState<string>('');

  const params = useMemo(() => {
    return {
      page: getParam('page'),
      search: getParam('search'),
    };
  }, []);

  const {
    data: { data: censoredWordsList, last_page: pageCount } = {
      data: [],
      last_page: 1,
    },
  } = useGetCensoredWords(params);

  const { mutateAsync: createCensoredWord } = useCreateCensoredWord();
  const { mutateAsync: deleteCensoredWord } = useDeleteCensoredWord();

  const addTag = (key: string) => {
    if (key === 'Enter') {
      createCensoredWord({ text: tagText })
        .then(() => {
          setTagText('');
        })
        .catch(() => {});
    }
  };
  const removeTag = (id: string | number) => {
    deleteCensoredWord(id)
      .then(() => {})
      .catch(() => {});
  };

  return (
    <StyledContainer>
      <StyledHeader>
        <StyledTitle>Запрещенные слова</StyledTitle>
        <StyledQuantity>{censoredWordsList.length}</StyledQuantity>
      </StyledHeader>
      <StyledBody>
        <FormItem label='Запрещенные слова'>
          <TextField
            variant='standard'
            color='primary'
            placeholder='Впишите ключевое слово и нажмите Enter'
            name='tag'
            value={tagText}
            onChange={e => setTagText(e.target.value)}
            onKeyUp={e => addTag(e.key)}
          />
        </FormItem>
        <StyledTagsBox>
          {censoredWordsList?.map(word => (
            <StyledTag
              key={word.id}
              label={word.text}
              onDelete={() => removeTag(word.id)}
              deleteIcon={<TagDeleteIcon />}
            />
          ))}
        </StyledTagsBox>
        <StyledPaginationBox>
          <Pagination count={pageCount} />
        </StyledPaginationBox>
      </StyledBody>
    </StyledContainer>
  );
};
