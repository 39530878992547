import { FC } from 'react';
import { COLORS } from 'utils';

interface IStarIcon {
  color?: string;
  className?: string;
  style?: React.CSSProperties;
  opacity?: string;
  onClick?: () => void;
}

export const StarIcon: FC<IStarIcon> = ({
  className,
  color = COLORS.BLACK,
  onClick,
  opacity = '0.2',
  ...rest
}) => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      onClick={onClick}
      {...rest}
    >
      <path
        d='M7.15489 6.86296L9.31989 2.50296C9.38291 2.37678 9.47984 2.27065 9.5998 2.19648C9.71976 2.1223 9.85802 2.08301 9.99906 2.08301C10.1401 2.08301 10.2784 2.1223 10.3983 2.19648C10.5183 2.27065 10.6152 2.37678 10.6782 2.50296L12.8432 6.86296L17.6832 7.56629C17.8228 7.58565 17.9542 7.64387 18.0623 7.7343C18.1704 7.82473 18.2509 7.94374 18.2946 8.07773C18.3383 8.21172 18.3435 8.3553 18.3095 8.49209C18.2755 8.62887 18.2037 8.75334 18.1024 8.85129L14.6007 12.243L15.4274 17.0346C15.5332 17.6496 14.8832 18.118 14.3274 17.828L9.99906 15.5646L5.66989 17.828C5.11489 18.1188 4.46489 17.6496 4.57073 17.0338L5.39739 12.2421L1.89573 8.85046C1.79488 8.75244 1.72356 8.62809 1.68986 8.49155C1.65617 8.35502 1.66145 8.21176 1.70512 8.07808C1.74878 7.9444 1.82907 7.82564 1.93687 7.73532C2.04466 7.645 2.17563 7.58672 2.31489 7.56713L7.15489 6.86296Z'
        stroke={color}
        strokeOpacity={opacity}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
