import { FC } from 'react';
import {
  Layout,
  RegionsHeader,
  RegionsTable,
  AddEditRegionDrawer,
} from 'components';
import { useDrawer } from 'hooks';

export const Regions: FC = () => {
  const {
    openAddDrawer,
    editDrawerId,
    purpose,
    hideAddEditDrawer,
    showAddEditDrawer,
  } = useDrawer();

  return (
    <Layout withoutPB>
      <AddEditRegionDrawer
        open={showAddEditDrawer}
        onClose={hideAddEditDrawer}
        purpose={purpose}
        id={editDrawerId}
      />
      <RegionsHeader openAddRegionDrawer={openAddDrawer} />
      <RegionsTable />
    </Layout>
  );
};
