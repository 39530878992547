import { FC, MouseEvent } from 'react';
import { IMarathonParticipantTeam } from 'types';
import { Box } from '@mui/material';
import { UserImage, Button } from 'UI';
import { StyledUserItem } from '../styledComponents';

interface ISectionTeamsProps {
  participants: IMarathonParticipantTeam[];
  openWinPopover: (
    _e: MouseEvent<HTMLButtonElement>,
    _id: string | number,
  ) => void;
}

export const SectionTeams: FC<ISectionTeamsProps> = ({
  participants,
  openWinPopover,
}) => {
  return (
    <>
      {participants.map((participant, index) => (
        <StyledUserItem key={participant.id}>
          <Box className='userInfo'>
            <Box className='place'>{index + 1}</Box>
            <Box className='photo'>
              <UserImage
                src={participant.logo}
                alt={participant.team_name}
                firstName={participant.team_name}
                width='3.125rem'
                height='3.125rem'
              />
            </Box>
            <Box className='meta'>
              <Box className='name'>{participant.team_name}</Box>
            </Box>
          </Box>
          <Box className='ball'>{participant.points} очков</Box>
          {/* <Box className='buttonBox'>
            <Button
              color='customLightBlue'
              onClick={e => openWinPopover(e, participant.id)}
            >
              Победил
            </Button>
          </Box> */}
        </StyledUserItem>
      ))}
    </>
  );
};
