import { useEffect, useState } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { IWorker, IWorkerGetParams, IWorkerRes, IRole } from 'types';
import {
  createWorker,
  deleteWorker,
  getWorker,
  getWorkers,
  updateWorker,
  getRolesList,
} from 'api';

export const useGetWorkers = (params: IWorkerGetParams) => {
  const { page, search, column, sort_type } = params;
  return useQuery<IWorkerRes, unknown>(
    ['workers', { page, search, column, sort_type }],
    async () => {
      const res = await getWorkers(params);
      return res;
    },
    {
      staleTime: Infinity,
      retry: false,
    },
  );
};

export const useGetAllWorkers = () => {
  const [workers, setWorkers] = useState<IWorker[]>([]);

  const getAllWorkers = async (pageCount: number) => {
    const queries = [];
    for (let i = 1; i <= pageCount; i++) {
      queries.push(getWorkers({ page: i }));
    }
    const res = await Promise.all(queries);
    const data = res.reduce((acc, item) => {
      return [...acc, ...item.data];
    }, []);
    setWorkers(data);
  };

  const fetchWorkers = async () => {
    const res = await getWorkers({ page: 1 });
    getAllWorkers(res.last_page);
  };

  useEffect(() => {
    fetchWorkers();
  }, []);

  return {
    total: workers.length,
    data: workers,
  };
};

export const useGetWorker = (workerId: number | string) => {
  if (!workerId)
    return useQuery(['workers', 'workerId', workerId], () => {
      return Promise.resolve(null);
    });
  return useQuery<IWorker>(
    ['workers', 'workerId', workerId],
    async () => {
      const res = await getWorker(workerId);
      return res;
    },
    {
      staleTime: 1000 * 60 * 10,
      retry: false,
    },
  );
};

export const useCreateWorker = () => {
  const queryClient = useQueryClient();
  return useMutation(createWorker, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['workers'] });
    },
    onError: () => {},
  });
};

export const useUpdateWorker = () => {
  const queryClient = useQueryClient();
  return useMutation(updateWorker, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['workers'] });
    },
    onError: () => {},
  });
};

export const useDeleteWorker = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteWorker, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['workers'] });
    },
    onError: () => {},
  });
};

export const useGetRolesList = () => {
  return useQuery<IRole[]>(
    ['roles'],
    async () => {
      const res = await getRolesList();
      return res;
    },
    {
      staleTime: Infinity,
      retry: false,
    },
  );
};
