import { FC } from 'react';
import { Button, PageHeader, GlobalSearch } from 'UI';
import { PlusIcon } from 'assets';

interface IQAHeaderProps {
  openAddQADrawer: () => void;
}

export const QAHeader: FC<IQAHeaderProps> = ({ openAddQADrawer }) => {
  return (
    <PageHeader title='Вопрос-ответ'>
      <GlobalSearch size='medium' />
      <Button
        variant='contained'
        onClick={openAddQADrawer}
        startIcon={<PlusIcon />}
      >
        Создать
      </Button>
    </PageHeader>
  );
};
