import { FC, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useSearchParams, useGetNewsItem } from 'hooks';
import { NewsSubPagesHeader, NewsLayout, CreateNewsBody } from 'components';

export const EditNews: FC = () => {
  const { id } = useParams();
  const { getParam, searchParams } = useSearchParams();

  if (!id) return null;

  const params = useMemo(() => {
    return {
      id,
      category_id: getParam('categoryId'),
    };
  }, [searchParams]);

  const { data: newsData } = useGetNewsItem(params);

  const defaultContent = useMemo(() => {
    return newsData?.content || '';
  }, [newsData]);

  const defaultContentJson = useMemo(() => {
    return JSON.parse(newsData?.content_json || '{}');
  }, [newsData]);

  const defaultSidebarValues = useMemo(() => {
    return {
      id: newsData?.id,
      category_id: newsData?.category?.id,
      name: newsData?.name,
      space_id: newsData?.space?.id,
      group_id: newsData?.group?.id,
      description: newsData?.description,
      is_active: newsData?.is_active,
      comments_allowed: newsData?.comments_allowed,
      has_push_notification: newsData?.has_push_notification,
      pushed_at: newsData?.pushed_at,
      published_at: newsData?.published_at,
      tags: newsData?.tags,
      cover: newsData?.cover,
      cover_vertical: newsData?.cover_vertical,
      show_in_slider: newsData?.show_in_slider || false,
      position_in_slider: newsData?.position_in_slider || 0,
      show_in_main: newsData?.show_in_main || false,
      position_in_main: newsData?.position_in_main || 0,
      is_pinned: newsData?.is_pinned,
    };
  }, [newsData]);

  return (
    <NewsLayout>
      <NewsSubPagesHeader title='Редактировать новость' />
      <CreateNewsBody
        defaultContent={defaultContent}
        defaultContentJson={defaultContentJson}
        defaultSidebarValues={defaultSidebarValues}
        purpose='edit'
      />
    </NewsLayout>
  );
};
