import { styled } from '@mui/system';
import { Box, FormControlLabel } from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { COLORS } from 'utils';

export const StyledSwitchFormControl = styled(FormControlLabel)({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  borderBottom: `1px solid ${COLORS.BLACK}`,
  margin: 0,
  paddingBottom: '0.375rem',
  '& .MuiFormControlLabel-label': {
    marginTop: '0.375rem',
    fontSize: '1rem',
    lineHeight: '1.5rem',
  },
});

export const StyledContainer = styled(Box)({
  padding: '0.625rem 0',
  display: 'flex',
  gap: '0.625rem',
  width: '100%',
  flexWrap: 'wrap',
});

export const StyledStoryItem = styled(Box)({
  width: '5rem',
  height: '9rem',
  borderRadius: '0.5rem',
  overflow: 'hidden',
  position: 'relative',
  '& img': {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  '& video': {
    objectFit: 'cover',
  },
  '& .deleteIcon': {
    visibility: 'hidden',
    position: 'absolute',
    top: '0.25rem',
    right: '0.25rem',
    cursor: 'pointer',
    transition: 'transform 0.3s',
    '&:hover': {
      transform: 'scale(1.2)',
    },
  },
  '& .editIcon': {
    visibility: 'hidden',
    position: 'absolute',
    top: '50%',
    left: '50%',
    cursor: 'pointer',
    transform: 'translate(-50%, -50%)',
  },
  '& .bgBox': {
    backgroundColor: 'rgba(255, 255, 255, 0.65)',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    visibility: 'hidden',
  },
  '&:hover': {
    '& .bgBox': {
      visibility: 'visible',
    },
    '& .deleteIcon': {
      visibility: 'visible',
    },
    '& .editIcon': {
      visibility: 'visible',
    },
  },
});

export const StyledItemAdder = styled(Box)({
  cursor: 'pointer',
  '& img': {
    width: '5rem',
    height: '9rem',
  },
});

export const StyledTimePicker = styled(TimePicker)({
  '& .MuiInputBase-root': {
    fontWeight: 600,
    fontSize: '0.875rem',
    lineHeight: '1.375rem',
    fontFamily: '"SF Pro Display", sans-serif',
    padding: '0.5rem 0',
    backgroundColor: COLORS.GRAY,
    flexDirection: 'row-reverse',
  },
  '& fieldset': {
    border: 'none',
  },
  '& input': {
    padding: '0 0 0 1rem',
  },
  '& button': {
    padding: 0,
  },
  '& svg': {
    color: COLORS.BLUE,
  },
});
