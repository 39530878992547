import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { useDrawer, useSearchParams } from 'hooks';
import {
  ChallengesBody,
  ChallengesHeader,
  Layout,
  ViewChallengeDrawer,
  ViewChallengeTrainingsDrawer,
  ViewChallengeUsersDrawer,
  CreateChallengeMainInfo,
  CreateChallengAddSponsors,
  CreateChallengeAddTrainings,
  CreateChallengeAddPrizes,
} from 'components';
import { CREATE_CHALLENGE_DRAWER_STEPS } from 'utils';

export const Challenges: FC = () => {
  const {
    openAddDrawer,
    viewDrawerId,
    showViewDrawer,
    hideViewDrawer,
    purpose,
    editDrawerId,
    showAddEditDrawer,
    subDrawer,
    hideSubDrawer,
    drawerStep,
    changeDrawerStep,
    hideDrawerStep,
  } = useDrawer();
  const { setParams } = useSearchParams();
  const navigate = useNavigate();

  const openAddChallenge = () => {
    openAddDrawer();
    changeDrawerStep(CREATE_CHALLENGE_DRAWER_STEPS.MAIN_INFO);
  };

  const closeAddChallenge = () => {
    // TODO: remove all data there
    hideDrawerStep();
  };

  const goPreviousStep = () => {
    navigate(-1);
  };

  return (
    <Layout withoutPB>
      <CreateChallengeMainInfo
        open={
          showAddEditDrawer &&
          drawerStep === CREATE_CHALLENGE_DRAWER_STEPS.MAIN_INFO
        }
        purpose={purpose}
        onSubmit={(id: number) => {
          setParams({
            edit: id,
            step: CREATE_CHALLENGE_DRAWER_STEPS.ADD_TRAININGS,
          });
        }}
        onClose={closeAddChallenge}
        id={editDrawerId}
      />
      <CreateChallengeAddTrainings
        marathonId={editDrawerId}
        open={
          showAddEditDrawer &&
          drawerStep === CREATE_CHALLENGE_DRAWER_STEPS.ADD_TRAININGS
        }
        goPreviousStep={goPreviousStep}
        onSubmit={() => {
          changeDrawerStep(CREATE_CHALLENGE_DRAWER_STEPS.ADD_PRIZES);
        }}
        onClose={closeAddChallenge}
      />
      <CreateChallengeAddPrizes
        marathonId={editDrawerId}
        open={
          showAddEditDrawer &&
          drawerStep === CREATE_CHALLENGE_DRAWER_STEPS.ADD_PRIZES
        }
        goPreviousStep={goPreviousStep}
        onSubmit={() => {
          changeDrawerStep(CREATE_CHALLENGE_DRAWER_STEPS.ADD_SPONSORS);
        }}
        onClose={closeAddChallenge}
      />
      <CreateChallengAddSponsors
        marathonId={editDrawerId}
        open={
          showAddEditDrawer &&
          drawerStep === CREATE_CHALLENGE_DRAWER_STEPS.ADD_SPONSORS
        }
        goPreviousStep={goPreviousStep}
        onSubmit={closeAddChallenge}
        onClose={closeAddChallenge}
      />
      {viewDrawerId && (
        <>
          <ViewChallengeDrawer
            open={
              showViewDrawer &&
              !(
                subDrawer === CREATE_CHALLENGE_DRAWER_STEPS.VIEW_USERS ||
                subDrawer === CREATE_CHALLENGE_DRAWER_STEPS.VIEW_TRAININGS
              )
            }
            id={viewDrawerId}
            onClose={hideViewDrawer}
          />
          <ViewChallengeTrainingsDrawer
            open={subDrawer === CREATE_CHALLENGE_DRAWER_STEPS.VIEW_TRAININGS}
            id={viewDrawerId}
            onClose={hideSubDrawer}
          />
          <ViewChallengeUsersDrawer
            open={subDrawer === CREATE_CHALLENGE_DRAWER_STEPS.VIEW_USERS}
            id={viewDrawerId}
            onClose={hideSubDrawer}
          />
        </>
      )}
      <ChallengesHeader openAddChallengeModal={openAddChallenge} />
      <ChallengesBody />
    </Layout>
  );
};
