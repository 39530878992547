import { FC, useState, useEffect } from 'react';
import { TextField, Box } from '@mui/material';
import {
  Drawer,
  DrawerHeader,
  ModalTitle,
  StyledFlexBox,
  Button,
  ImageInput,
  DrawerFormBody,
  FormItem,
} from 'UI';
import { IPrizeItem } from 'types';
import { LeftArrowIcon } from 'assets';
import { MEDIA_TYPES } from 'utils';

const DEFAULT_VALUES: IPrizeItem = {
  id: 0,
  name: '',
  description: '',
  cover: '',
  type: 2,
  position: 0,
};

const TRANSLATIONS = {
  add: {
    title: 'Добавить достижение',
  },
  edit: {
    title: 'Редактировать достижение',
  },
};

interface ICreateAchievementForChallengeProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (_prize: IPrizeItem) => void;
  editingItem?: IPrizeItem;
  purpose?: 'add' | 'edit';
}

export const CreateAchievementForChallenge: FC<
  ICreateAchievementForChallengeProps
> = ({ open, onClose, onSubmit, editingItem = undefined, purpose = 'add' }) => {
  const [achievementInfo, setAchievementInfo] =
    useState<IPrizeItem>(DEFAULT_VALUES);

  useEffect(() => {
    if (editingItem) {
      setAchievementInfo(editingItem);
    }
  }, [editingItem]);

  const handleFieldChange = <T extends keyof IPrizeItem>(
    fieldName: T,
    newValue: IPrizeItem[T],
  ) => {
    setAchievementInfo(prevAchievementInfo => ({
      ...prevAchievementInfo,
      [fieldName]: newValue,
    }));
  };

  const saveChanges = () => {
    if (
      !achievementInfo.name ||
      !achievementInfo.description ||
      !achievementInfo.cover
    )
      return;
    onSubmit(achievementInfo);
    setAchievementInfo(DEFAULT_VALUES);
  };

  const ignoreChanges = () => {
    setAchievementInfo(DEFAULT_VALUES);
    onClose();
  };

  return (
    <Drawer open={open} onClose={ignoreChanges}>
      <DrawerHeader>
        <ModalTitle title={TRANSLATIONS[purpose].title} />
        <LeftArrowIcon onClick={ignoreChanges} />
      </DrawerHeader>
      <DrawerFormBody>
        <StyledFlexBox>
          <ImageInput
            inputId='achievementCover'
            title='Добавьте обложку'
            descriptionWithoutImage='Загрузите изображение (1080x1080 px, до 1 мб)'
            withDescription
            image={achievementInfo.cover}
            setImage={newImg => handleFieldChange('cover', newImg)}
            style={{ width: '60%' }}
            mediaType={MEDIA_TYPES.TROPHY_ITEM_COVER}
            imageFixedHeight={1080}
            imageFixedWidth={1080}
          />
        </StyledFlexBox>
        <FormItem label='Название'>
          <TextField
            variant='standard'
            color='primary'
            placeholder='Введите название'
            name='title'
            value={achievementInfo.name}
            onChange={e => handleFieldChange('name', e.target.value)}
          />
        </FormItem>
        <FormItem label='Описание'>
          <TextField
            variant='standard'
            color='primary'
            placeholder='Введите описание'
            name='description'
            value={achievementInfo.description}
            onChange={e => handleFieldChange('description', e.target.value)}
          />
        </FormItem>
      </DrawerFormBody>
      <StyledFlexBox className='buttons fullDivWidth'>
        <Box />
        <Button
          variant='contained'
          size='large'
          color='primary'
          onClick={saveChanges}
        >
          Добавить
        </Button>
      </StyledFlexBox>
    </Drawer>
  );
};
