import { FC, useState, useMemo, MouseEvent } from 'react';
import { TableBody, TableCell, Box } from '@mui/material';

import { EditIcon, DeleteIcon } from 'assets';
import { Pagination, StyledSwitch } from 'UI';
import {
  useGetTeams,
  useDeleteTeam,
  useSearchParams,
  useChangeStatus,
} from 'hooks';
import { ITeam, TableRowsOrder, TeamSortParams } from 'types';
import { DeleteModal } from 'components';

import {
  StyledBox,
  StyledPaper,
  StyledTableContainer,
  StyledTable,
  StyledTableRow,
  StyledTeamInfo,
  StyledAvatar,
  StyledTeamInfoAtModal,
} from './styledComponents';
import { TeamsTableHead } from './TeamsTableHead';

export const TeamsTable: FC = () => {
  const [order, setOrder] = useState<TableRowsOrder>('desc');
  const [orderBy, setOrderBy] = useState<TeamSortParams>('created_at');
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [selectedTeam, setSelectedTeam] = useState<ITeam | undefined>(
    undefined,
  );
  const { setParam, getParam, searchParams } = useSearchParams();

  const params = useMemo(() => {
    return {
      page: getParam('page'),
      search: getParam('search'),
      column: orderBy,
      sort_type: order,
    };
  }, [searchParams, orderBy, order]);

  const {
    data: { data: teams, last_page: pageCount } = { data: [], last_page: 1 },
  } = useGetTeams(params);
  const { mutateAsync: deleteTeam } = useDeleteTeam();
  const { mutateAsync: changeStatus } = useChangeStatus('team');

  const updateRow = (row: ITeam) => {
    changeStatus({
      space_id: row.space.id!,
      model_name: 'team',
      model_id: row.id,
      state: !row.is_active,
    })
      .then(() => {})
      .catch(() => {});
  };

  const deleteButtonHandler = (row: ITeam) => {
    setSelectedTeam(row);
    setOpenDeleteModal(true);
  };

  const deleteRow = async () => {
    await deleteTeam(selectedTeam!.id).catch(() => {});
    setOpenDeleteModal(false);
  };

  const cancelDeleteRow = () => {
    setOpenDeleteModal(false);
    setSelectedTeam(undefined);
  };

  const handleRequestSort = (
    _event: MouseEvent<unknown>,
    property: TeamSortParams,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const openViewDrawer = (id: string | number) => {
    setParam('view', id.toString());
  };

  const openEditDrawer = (id: string | number) => {
    setParam('edit', id.toString());
  };

  return (
    <StyledBox>
      <DeleteModal
        open={openDeleteModal}
        title='Удалить команду'
        description='Вы действительно хотите удалить команду?'
        onConfirm={deleteRow}
        onClose={cancelDeleteRow}
      >
        <StyledTeamInfoAtModal>
          <StyledAvatar>
            <img
              src={selectedTeam?.logo as string | undefined}
              alt={selectedTeam?.name}
            />
          </StyledAvatar>
          <Box className='name'>{selectedTeam?.name}</Box>
        </StyledTeamInfoAtModal>
      </DeleteModal>
      <StyledPaper>
        <StyledTableContainer>
          <StyledTable aria-labelledby='teamsTable' size='medium' stickyHeader>
            <TeamsTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {teams.map(row => {
                return (
                  <StyledTableRow hover key={row.id}>
                    <TableCell
                      component='th'
                      id={row.id.toString()}
                      scope='row'
                    >
                      <StyledTeamInfo>
                        <StyledAvatar onClick={() => openViewDrawer(row.id)}>
                          <img
                            src={row.logo as string | undefined}
                            alt={row.name}
                          />
                        </StyledAvatar>
                        <Box
                          className='name'
                          onClick={() => openViewDrawer(row.id)}
                        >
                          {row.name}
                        </Box>
                      </StyledTeamInfo>
                    </TableCell>
                    <TableCell align='center'>
                      <span>{row.members_count}</span>
                    </TableCell>
                    <TableCell align='center'>
                      <span>{row.steps_count}</span>
                    </TableCell>
                    <TableCell align='center'>
                      <span>{row.kcal_count}</span>
                    </TableCell>
                    <TableCell align='center'>
                      <span>{row.distance_count}</span>
                    </TableCell>
                    <TableCell align='center'>
                      <span>{row.created_at}</span>
                    </TableCell>
                    <TableCell>
                      <div className='editDeleteIcons'>
                        <StyledSwitch
                          checked={row.is_active}
                          variant='customGray'
                          onChange={() => updateRow(row)}
                          style={{ opacity: 1 }}
                        />
                        <EditIcon onClick={() => openEditDrawer(row.id)} />
                        <DeleteIcon onClick={() => deleteButtonHandler(row)} />
                      </div>
                    </TableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </StyledTable>
        </StyledTableContainer>
        <Pagination count={pageCount} />
      </StyledPaper>
    </StyledBox>
  );
};
