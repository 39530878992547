import { styled } from '@mui/system';
import { Box } from '@mui/material';
import { COLORS } from 'utils';

export const StyledBody = styled(Box)({
  width: '100%',
  padding: '0 10rem',
  display: 'flex',
  gap: '0.5rem',
  alignItems: 'flex-start',
});

export const StyledEditorWrapper = styled(Box)({
  width: 'calc(100% - 23rem)',
  height: '100%',
  minHeight: '20rem',
  backgroundColor: COLORS.WHITE,
  borderRadius: '0.5rem',
  '& .container': {
    position: 'relative',

    '& .codex-editor': {
      '& path, rect, line, circle': {
        stroke: `${COLORS.BLUE} !important`,
        color: `${COLORS.BLUE} !important`,
      },

      '& .colorPlugin': {
        '& svg': {
          fill: `${COLORS.BLUE} !important`,
        },
      },
    },
    '& .ce-editorjsColumns_col': {
      border: '1px solid #c6d2e1',
      borderRadius: '8px',

      '& svg': {
        fill: COLORS.BLUE,
      },
    },
    '& .ce-toolbar__plus svg, .ce-toolbar__settings-btn svg': {
      width: 'auto',
      height: 'auto',
    },

    '& .ce-toolbar': {
      display: 'block',
      zIndex: 1,
    },

    '& .ce-block': {
      wordWrap: 'break-word',
      paddingTop: '35px',
    },

    '& .ce-toolbar__actions': {
      right: '100%',
    },

    '& .ce-editorjsColumns_col .ce-toolbar__actions': {
      right: '-5px',
    },

    '& .cdx-search-field__icon svg': {
      width: '14px',
      height: '14px',
    },

    '& .image-tool__tune': {
      display: 'none',
    },

    '& .cdx-attaches--with-file .cdx-attaches__file-icon-background': {
      width: '42px',
    },

    '& .cdx-attaches--with-file .cdx-attaches__file-icon-label': {
      top: '10px',
    },

    '& .cdxcarousel-wrapper': {
      marginBottom: '70px',
    },

    '& .image-tool': {
      maxWidth: '400px',

      '&.image-tool--stretched': {
        maxWidth: '100%',
      },
    },

    '& .cdxcarousel-block': {
      minWidth: 'calc(100% / 3) !important',
    },

    '& .cdxcarousel-list': {
      background: 'rgba(0, 0, 0, 0.03)',
      padding: '20px',
      position: 'relative',

      '& .column__setting__wrapper': {
        top: '100%',
        left: 0,
        width: '100%',
        minHeight: '54px',
        background: 'rgba(0, 0, 0, 0.1)',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        padding: '8px',

        '& .column__setting': {
          position: 'relative',
          top: 'auto !important',
          bottom: 'auto !important',
          left: 'auto !important',
          right: 'auto !important',
        },

        '& .cdxcarousel-rightBtn': {
          marginLeft: '10px',
        },
      },
    },

    '& .ce-toolbar__settings-btn': {
      '& path': {
        stroke: COLORS.BLACK,
      },
    },

    '& .ce-popover__item .ce-popover__item-icon': {
      '& svg': {
        fill: 'transparent',
      },
    },

    '& .ce-popover': {
      width: '270px',
    },

    '& .editor-columns-blocks': {
      borderLeft: '1px solid rgba(0, 0, 0, 0.1)',
      borderRight: '1px solid rgba(0, 0, 0, 0.1)',
      display: 'flex',
      justifyContent: 'space-around',
      padding: '0',
    },

    '& .editor-columns-block': {
      border: 0,
      borderLeft: '1px solid rgba(0, 0, 0, 0.1)',
      borderRight: '1px solid rgba(0, 0, 0, 0.1)',
      width: '100%',
      height: '100%',
      minHeight: '100px',
      transition: 'all 0.3s',
      padding: '11px',

      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.03)',
      },

      '& > div': {
        height: '100%',
        minHeight: '100px',
      },

      '&.w-50': {
        maxWidth: '50%',
      },

      '&.w-33': {
        maxWidth: '33.3333%',
      },

      '&.w-25': {
        maxWidth: '25%',
      },

      '& .ce-block__content': {
        '& > div': {
          wordBreak: 'break-word',
        },
      },
    },

    '& .codex-editor__redactor': {
      marginRight: 0,
    },

    '& .tc-wrap': {
      overflow: 'auto',
    },

    '& .tc-table': {
      minWidth: '700px',
    },

    '& .tc-add-row, .tc-add-column': {
      zIndex: 1,
      '& svg': {
        background: COLORS.WHITE,
      },
    },

    '& .ck-reset_all, .ck-widget__selection-handle': {
      display: 'none',
    },

    '& .ck-editor__editable.ck-focused': {
      border: 'none !important',
      boxShadow: 'none !important',
    },

    '& .table.ck-widget.ck-widget_with-selection-handle.ck-widget_selected': {
      margin: '0 !important',
    },

    '& .table.ck-widget': {
      overflow: 'auto',
    },

    '& [data-item-name=withBorder], [data-item-name=withBackground]': {
      display: 'none',
    },

    '& .ck-content .table table td, .ck-content .table table th': {
      minWidth: '200px',
    },

    '& .cdx-collapse-toggle::before': {
      borderLeft: `6px solid ${COLORS.BLUE}`,
    },

    '& .cdx-collapse-title': {
      width: '100%',
      textOverflow: 'ellipsis',
    },

    '& .cdx-collapse-content': {
      overflowY: 'auto',
    },

    '& custom-section': {
      position: 'relative',
      height: 'auto',
      inset: 'auto',
    },
  },
});

export const StyledPreview = styled(Box)({
  width: '375px',
  margin: '0 auto',
  height: '100%',
  backgroundColor: '#201c1c',
  borderRadius: '0.5rem',
  overflow: 'hidden',
  '& .header': {
    backgroundColor: COLORS.WHITE,
    borderBottom: `1px solid rgba(0, 0, 0, 0.2)`,
    padding: '0 0.625rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: '0.75rem',
    lineHeight: '1.25rem',
    fontFamily: '"SF Pro Display", sans-serif',
    color: 'rgba(0, 0, 0, 0.5)',
    '& .title': {
      width: '100%',
    },
    '& .buttons': {
      display: 'flex',
      alignItems: 'center',
      '& .button': {
        borderLeft: `1px solid rgba(0, 0, 0, 0.2)`,
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        fontSize: '0.75rem',
        lineHeight: '1.25rem',
        fontFamily: '"SF Pro Display", sans-serif',
        color: 'rgba(0, 0, 0, 0.5)',
        width: '7rem',
        paddingLeft: '1rem',
      },
    },
  },
});

export const EditorHeader = styled(Box)({
  borderBottom: `1px solid rgba(0, 0, 0, 0.2)`,
  padding: '0.5rem 0.625rem',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  fontSize: '0.75rem',
  lineHeight: '1.25rem',
  fontFamily: '"SF Pro Display", sans-serif',
  color: 'rgba(0, 0, 0, 0.5)',
  '& .title': {
    width: '100%',
    borderRight: `1px solid rgba(0, 0, 0, 0.2)`,
  },
  '& .button': {
    fontSize: '0.75rem',
    lineHeight: '1.25rem',
    fontFamily: '"SF Pro Display", sans-serif',
    color: 'rgba(0, 0, 0, 0.5)',
    width: '7rem',
    marginLeft: '0.5rem',
  },
});
