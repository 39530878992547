import { FC, useState } from 'react';
import { Box } from '@mui/material';
import { COLORS, fileDownloadByLink } from 'utils';
import { useGetShowcaseBranchesFileSample } from 'hooks';
import { Button } from 'UI';
import { DownloadIcon, AttachIcon } from 'assets';

import { StyledFileUploadContainer } from '../styledComponents';

interface IUploadBranchesProps {
  handleFileUpload: (_file: File) => void;
}

export const UploadBranches: FC<IUploadBranchesProps> = ({
  handleFileUpload,
}) => {
  const [branchFileName, setBranchFileName] = useState<string>('');
  const { data: sampleShowcaseBranchesFileLink } =
    useGetShowcaseBranchesFileSample();

  const openBranchFileUploadModal = () => {
    document.getElementById('upload-branch')?.click();
  };

  const downloadBranchesSample = () => {
    fileDownloadByLink(sampleShowcaseBranchesFileLink, 'sample.csv');
  };

  const uploadBranchFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }
    const uploadedFile = e.target.files[0];
    handleFileUpload(uploadedFile);
    setBranchFileName(uploadedFile.name);
  };

  return (
    <StyledFileUploadContainer>
      <h2>Загрузка адресов</h2>
      <Box className='buttons'>
        <Button
          variant='contained'
          color='customGray'
          size='large'
          startIcon={<DownloadIcon color={COLORS.BLUE} />}
          onClick={downloadBranchesSample}
        >
          Скачать пример
        </Button>
        <Button
          variant='contained'
          color='customLightBlue'
          size='large'
          startIcon={<AttachIcon color={COLORS.BLUE} />}
          onClick={openBranchFileUploadModal}
        >
          {branchFileName || 'Загрузить'}
        </Button>
      </Box>
      <input
        id='upload-branch'
        type='file'
        style={{ display: 'none' }}
        onChange={e => uploadBranchFile(e)}
        accept='.xlsx, .xls, .csv'
      />
    </StyledFileUploadContainer>
  );
};
