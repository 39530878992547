import { FC, useContext, useMemo } from 'react';
import { Grid } from '@mui/material';
import {
  AnalyticsCard,
  AnalyticsCardsContainer,
  AnalyticsListCard,
  AnalyticsGenderCard,
  AnalyticsInnerPageContainer,
} from 'components';
import { FinishIcon, PercentIcon } from 'assets';
import {
  useGetAnalyticsShowcaseGender,
  useGetAnalyticsShowcaseData,
  useGetAnalyticsShowcaseTopDiscounts,
  useGetAnalyticsShowcaseTopCategories,
} from 'hooks';
import { ROUTES } from 'app';
import { FilterContext, DEFAULT_CARD, DEFAULT_GENDER_CARD } from 'utils';

export const ShowcasesAnalyticsBody: FC = () => {
  const { startDate, endDate } = useContext(FilterContext);

  const paramsToGetAnalytics = useMemo(() => {
    return {
      start_date: startDate,
      end_date: endDate,
    };
  }, [startDate, endDate]);

  const {
    data: { offers, discounts } = {
      offers: DEFAULT_CARD,
      discounts: DEFAULT_CARD,
    },
  } = useGetAnalyticsShowcaseData(paramsToGetAnalytics);

  const {
    data: { male, female } = {
      male: DEFAULT_GENDER_CARD,
      female: DEFAULT_GENDER_CARD,
    },
  } = useGetAnalyticsShowcaseGender(paramsToGetAnalytics);

  const { data: { data: topDiscounts } = { data: [] } } =
    useGetAnalyticsShowcaseTopDiscounts(paramsToGetAnalytics);

  const { data: { data: topCategories } = { data: [] } } =
    useGetAnalyticsShowcaseTopCategories(paramsToGetAnalytics);

  return (
    <AnalyticsInnerPageContainer>
      <AnalyticsCardsContainer>
        <Grid xs={6} lg={4} item>
          <AnalyticsCard
            icon={PercentIcon}
            title='Скидками воспользовались'
            {...discounts}
          />
        </Grid>
        <Grid xs={6} lg={4} item>
          <AnalyticsCard
            icon={FinishIcon}
            title='Предложениями воспользовались'
            {...offers}
          />
        </Grid>
        <Grid xs={6} lg={4} item>
          <AnalyticsGenderCard male={male} female={female} fullHeight />
        </Grid>
      </AnalyticsCardsContainer>
      <AnalyticsCardsContainer>
        <Grid xs={6} item>
          <AnalyticsListCard
            title='Топ 5 популярных скидок'
            link={`${ROUTES.analytics.full}?sub=showcase&tab=discounts`}
            listItems={topDiscounts}
          />
        </Grid>
        <Grid xs={6} item>
          <AnalyticsListCard
            title='Самая популярная категория'
            link={`${ROUTES.analytics.full}?sub=showcase&tab=categories`}
            listItems={topCategories}
          />
        </Grid>
      </AnalyticsCardsContainer>
    </AnalyticsInnerPageContainer>
  );
};
