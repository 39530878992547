import { IGroupReq, IGroupGetParams } from 'types';
import { http, methods } from './config';

export const getGroups = (params: IGroupGetParams) => {
  const config = {
    method: methods.get,
    url: `/group`,
    params,
  };
  return http(config);
};

export const getGroup = (groupId: string | number) => {
  const config = {
    method: methods.get,
    url: `/group/${groupId}`,
  };
  return http(config);
};

export const createGroup = (data: IGroupReq) => {
  const config = {
    method: methods.post,
    url: `/group`,
    data,
  };
  return http(config);
};

export const updateGroup = (data: IGroupReq) => {
  const config = {
    method: methods.put,
    url: `/group/${data.id}`,
    data,
  };
  return http(config);
};

export const deleteGroup = (groupId: number | string) => {
  const config = {
    method: methods.delete,
    url: `/group/${groupId}`,
  };
  return http(config);
};

export const getGroupsList = (params: { space_id: string | number }) => {
  const config = {
    method: methods.get,
    url: `/list/group`,
    params,
  };
  return http(config);
};
