import { FC, useMemo } from 'react';
import { Box } from '@mui/material';
import { IMarathonParticipantTeam } from 'types';
import { UserImage, Button } from 'UI';
import { StyledUserItem, StyledUsersList } from '../styledComponents';

interface IWinnerTeamsProps {
  winners: IMarathonParticipantTeam[];
  removeFromWinners?: (_id: string | number) => void;
}

export const WinnerTeams: FC<IWinnerTeamsProps> = ({
  winners,
  removeFromWinners,
}) => {
  const sortedWinners = useMemo(
    () => winners.sort((a, b) => a.winning_place - b.winning_place),
    [winners],
  );
  return (
    <StyledUsersList>
      {sortedWinners.map(winner => (
        <StyledUserItem key={winner.id}>
          <Box className='userInfo'>
            <Box className={`place place-${winner.winning_place}`}>
              {winner.winning_place}
            </Box>
            <Box className='photo'>
              <UserImage
                src={winner.logo}
                alt={winner.team_name}
                firstName={winner.team_name}
                width='3.125rem'
                height='3.125rem'
              />
            </Box>
            <Box className='meta'>
              <Box className='name'>{winner.team_name}</Box>
            </Box>
          </Box>
          <Box className='ball'>{winner.points} очков</Box>
          {/* {removeFromWinners && (
            <Box className='buttonBox'>
              <Button
                color='customGray'
                onClick={() => removeFromWinners(winner.id)}
              >
                Убрать
              </Button>
            </Box>
          )} */}
        </StyledUserItem>
      ))}
    </StyledUsersList>
  );
};
