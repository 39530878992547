import { FC } from 'react';
import { Box } from '@mui/material';
import { INews } from 'types';
import { AntennaIcon, WifiIcon, BatteryIcon, LeftArrowIcon } from 'assets';
import { COLORS, convertNewsDateString } from 'utils';
import LikeImage from 'assets/icons/showNewsIcons/like.svg';
import ViewImage from 'assets/icons/showNewsIcons/eye.svg';
import CommentImage from 'assets/icons/showNewsIcons/comment.svg';
import ClockImage from 'assets/icons/showNewsIcons/clock.svg';

import {
  StyledBody,
  StyledCover,
  UpperBlock,
  UpperBlockIcons,
  StyledTitle,
  StyledNewsBody,
  StyledStatisticsBox,
  StyledStatistics,
  StyledMetaBox,
  StyledCategoryBadge,
  StyledTagBadge,
  StyledNewsContent,
} from './styledComponents';

import { sanitizeAndPrepareHtml } from '../CreateNewsBody/utils';

interface INewsPreviewComponentProps {
  data: Partial<INews>;
}

export const NewsPreviewComponent: FC<INewsPreviewComponentProps> = ({
  data,
}) => {
  const readyHtml = sanitizeAndPrepareHtml(data.content);
  return (
    <StyledBody className='newsPreview'>
      <StyledCover>
        <UpperBlock>
          <Box>9:41</Box>
          <UpperBlockIcons>
            <AntennaIcon />
            <WifiIcon />
            <BatteryIcon />
          </UpperBlockIcons>
        </UpperBlock>
        <button className='backButton' type='button'>
          <LeftArrowIcon color={COLORS.WHITE} />
        </button>
        <img src={data.cover} alt={data.name} />
        <StyledTitle>{data.name}</StyledTitle>
      </StyledCover>
      <StyledNewsBody>
        {data.published_at && (
          <StyledStatisticsBox>
            <Box className='publishTime'>
              <img src={ClockImage} alt='publish time' />
              <p>{convertNewsDateString(data.published_at)}</p>
            </Box>
            <Box className='stats'>
              <StyledStatistics>
                <img src={LikeImage} alt='likes' />
                <p>{data.shares_count}</p>
              </StyledStatistics>
              <StyledStatistics>
                <img src={CommentImage} alt='comments' />
                <p>{data.comments_count}</p>
              </StyledStatistics>
              <StyledStatistics>
                <img src={ViewImage} alt='views' />
                <p>{data.views_count}</p>
              </StyledStatistics>
            </Box>
          </StyledStatisticsBox>
        )}
        <StyledMetaBox>
          <StyledCategoryBadge>{data.category?.name}</StyledCategoryBadge>
          {data.tags?.map(tag => (
            <StyledTagBadge key={tag}>{tag}</StyledTagBadge>
          ))}
        </StyledMetaBox>
        <StyledNewsContent
          dangerouslySetInnerHTML={{
            __html: readyHtml,
          }}
        />
      </StyledNewsBody>
    </StyledBody>
  );
};
