import { FC } from 'react';
import { TableHead, TableRow, TableCell } from '@mui/material';
import { IStoryShort, IHeadCell } from 'types';

const headCells: readonly IHeadCell<IStoryShort>[] = [
  {
    id: 'position',
    label: 'Порядок',
    sortable: false,
  },
  {
    id: 'title',
    label: 'Заголовок',
    sortable: false,
  },
  {
    id: 'created_at',
    label: 'Дата создания',
    sortable: false,
  },
  {
    id: 'views_count',
    label: 'Просмотров',
    sortable: false,
  },
  {
    id: 'clicks_count',
    label: 'Переходов',
    sortable: false,
  },
  {
    id: 'is_published',
    label: 'Статус',
    sortable: false,
  },
  {
    id: 'actions',
    label: 'Управление',
    sortable: false,
  },
];

export const StoriesTableHead: FC = () => {
  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => {
          return (
            <TableCell
              key={headCell.id}
              padding='normal'
              className={headCell.className}
            >
              {headCell.label}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};
