import { IShopCategoryReq, IPaginatedReqParams } from 'types';
import { http, methods } from '../config';

export const getShopCategories = (params: IPaginatedReqParams) => {
  const config = {
    method: methods.get,
    url: `/shop/category`,
    params,
  };
  return http(config);
};

export const getShopCategory = (id: string | number) => {
  const config = {
    method: methods.get,
    url: `/shop/category/${id}`,
  };
  return http(config);
};

export const createShopCategory = (data: IShopCategoryReq) => {
  const config = {
    method: methods.post,
    url: `/shop/category`,
    data,
  };
  return http(config);
};

export const updateShopCategory = (data: IShopCategoryReq) => {
  const config = {
    method: methods.put,
    url: `/shop/category/${data.id}`,
    data,
  };
  return http(config);
};

export const deleteShopCategory = (id: number | string) => {
  const config = {
    method: methods.delete,
    url: `/shop/category/${id}`,
  };
  return http(config);
};

export const getShopCategoriesList = () => {
  const config = {
    method: methods.get,
    url: `/list/product_category`,
  };
  return http(config);
};
