import { FC, useContext, useMemo } from 'react';
import { styled } from '@mui/system';
import { Box } from '@mui/material';
import { UserContext, UserContextType, ROUTES } from 'app';
import { SearchSelectInput } from 'UI';
import { COLORS, notify } from 'utils';
import { useChangeSpace, useGetSpacesList } from 'hooks';
import { useNavigate } from 'react-router-dom';

const StyledContainer = styled(Box)({
  borderRadius: '0.5rem',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: COLORS.GRAY,
  margin: '0 1rem 0.9375rem',
  '& .title': {
    textAlign: 'center',
    fontFamily: '"SF Pro Display", sans-serif',
    fontSize: '1rem',
    fontWeight: 500,
    lineHeight: '1.5rem',
  },
  '& .MuiAutocomplete-root': {
    borderRadius: '0.25rem',
  },
  '& .MuiFormControl-root': {
    borderRadius: '0.25rem',
  },
  '& div': {
    border: 'none',
    backgroundColor: COLORS.GRAY,
  },
  '& *': {
    border: 'none!important',
  },
});

export const SpacePicker: FC = () => {
  const navigate = useNavigate();
  const { mutateAsync: changeSpace } = useChangeSpace();
  const { updateSpace, userData, space_id } = useContext(
    UserContext,
  ) as UserContextType;
  const { data: spacesList } = useGetSpacesList();

  const changeSpaceHandler = (newSpaceId: string | number) => {
    const newSpace = userData?.spaces.find(space => space.id === newSpaceId);
    if (!newSpace) return;
    changeSpace({ space_id: newSpaceId })
      .then(() => {
        updateSpace(newSpace);
        navigate(ROUTES.home);
      })
      .catch(() => {
        notify('Не удалось сменить пространство', 'error');
      });
  };

  const spaceOptions = useMemo(() => {
    return (
      spacesList?.map(space => ({
        value: space.id,
        label: space.name,
      })) || []
    );
  }, [spacesList]);

  return (
    <StyledContainer>
      {/* <p className='title'>Пространства</p> */}
      <SearchSelectInput
        options={spaceOptions}
        onChange={e => changeSpaceHandler(e as string)}
        value={space_id!}
        disableClearable
        variant='outlined'
      />
    </StyledContainer>
  );
};
