import { styled } from '@mui/system';
import { Box, FormControlLabel } from '@mui/material';
import { COLORS } from 'utils';

export const StyledLayout = styled(Box)({
  width: '22.5rem',
  padding: '1.875rem',
  backgroundColor: COLORS.WHITE,
  display: 'flex',
  flexDirection: 'column',
  gap: '1.875rem',
  borderRadius: '0.5rem',
  '& h2': {
    fontSize: '1.5rem',
    fontWeight: 600,
    lineHeight: '2rem',
    fontFamily: '"SF Pro Display", sans-serif',
    margin: 0,
    color: COLORS.BLACK,
  },
});

export const MainFormItems = styled(Box)({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '0.75rem',
  '& .MuiTextField-root': {
    width: '100%',
  },
});

export const StyledContainerBox = styled(Box)({
  display: 'flex',
  gap: '0.5rem',
  flexDirection: 'column',
});

export const StyledSwitchFormControl = styled(FormControlLabel)({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  margin: 0,
  '& .MuiFormControlLabel-label': {
    marginTop: '0.375rem',
    fontSize: '1rem',
    lineHeight: '1.5rem',
    fontFamily: '"SF Pro Display", sans-serif',
  },
});
