import * as Pages from 'pages';
import { ROLES } from 'utils';
import { IRoutes } from '../types';

export const News: IRoutes = {
  news: {
    path: '/news',
    title: 'news',
    element: <Pages.News />,
    roles: [ROLES.ADMINSTRATOR, ROLES.MODERATOR],
  },
  createNews: {
    path: '/news/create',
    title: 'create news',
    element: <Pages.CreateNews />,
    roles: [ROLES.ADMINSTRATOR, ROLES.MODERATOR],
  },
  showNewsItem: {
    path: '/news/show/:id',
    title: 'show news item',
    element: <Pages.ShowNewsItem />,
    roles: [ROLES.ADMINSTRATOR, ROLES.MODERATOR],
  },
  editNewsItem: {
    path: '/news/edit/:id',
    title: 'edit news item',
    element: <Pages.EditNews />,
    roles: [ROLES.ADMINSTRATOR, ROLES.MODERATOR],
  },
  showNewsComments: {
    path: '/news/comments/:id',
    title: 'show news comments',
    element: <Pages.ShowNewsComments />,
    roles: [ROLES.ADMINSTRATOR, ROLES.MODERATOR],
  },
};
