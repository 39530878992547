import { FC, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { ShowNewsCommentsBody } from 'components';
import { useSearchParams, useGetNewsItem } from 'hooks';

export const ShowNewsComments: FC = () => {
  const { id } = useParams();
  const { getParam, searchParams } = useSearchParams();
  if (!id) return null;
  const params = useMemo(() => {
    return {
      id,
      category_id: getParam('categoryId'),
    };
  }, [searchParams]);

  const { data: newsData } = useGetNewsItem(params);

  return <ShowNewsCommentsBody data={newsData} />;
};
