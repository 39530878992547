import { FC } from 'react';
import { COLORS } from 'utils';

interface ISettingsIcon {
  color?: string;
  className?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
}

export const SettingsIcon: FC<ISettingsIcon> = ({
  className,
  color = COLORS.BLUE,
  onClick,
  ...rest
}) => (
  <svg
    width='25'
    height='24'
    viewBox='0 0 25 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
    onClick={onClick}
    {...rest}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M14.1763 4.31627C13.7488 2.56124 11.2512 2.56124 10.8237 4.31627C10.7599 4.57999 10.6347 4.82492 10.4583 5.03112C10.2819 5.23732 10.0594 5.39897 9.80874 5.50291C9.5581 5.60684 9.28646 5.65014 9.01592 5.62927C8.74538 5.60839 8.4836 5.52394 8.25187 5.38279C6.70832 4.44227 4.94201 6.20855 5.88254 7.75207C6.49006 8.74884 5.95117 10.0494 4.81713 10.325C3.06096 10.7514 3.06096 13.25 4.81713 13.6753C5.08093 13.7392 5.3259 13.8645 5.53211 14.041C5.73831 14.2175 5.89991 14.4402 6.00375 14.691C6.1076 14.9418 6.15074 15.2135 6.12968 15.4841C6.10862 15.7547 6.02394 16.0165 5.88254 16.2482C4.94201 17.7917 6.70832 19.558 8.25187 18.6175C8.48356 18.4761 8.74536 18.3914 9.01597 18.3704C9.28658 18.3493 9.55834 18.3924 9.80912 18.4963C10.0599 18.6001 10.2826 18.7617 10.4591 18.9679C10.6356 19.1741 10.7609 19.4191 10.8248 19.6829C11.2512 21.439 13.7499 21.439 14.1752 19.6829C14.2393 19.4192 14.3647 19.1744 14.5413 18.9684C14.7178 18.7623 14.9405 18.6008 15.1912 18.497C15.4419 18.3932 15.7135 18.35 15.9841 18.3709C16.2546 18.3919 16.5164 18.4764 16.7481 18.6175C18.2917 19.558 20.058 17.7917 19.1175 16.2482C18.9763 16.0165 18.8918 15.7547 18.8709 15.4842C18.85 15.2136 18.8932 14.942 18.997 14.6913C19.1008 14.4406 19.2623 14.2179 19.4683 14.0414C19.6744 13.8648 19.9192 13.7394 20.1829 13.6753C21.939 13.2489 21.939 10.7502 20.1829 10.325C19.9191 10.2611 19.6741 10.1358 19.4679 9.95928C19.2617 9.78278 19.1001 9.56007 18.9962 9.3093C18.8924 9.05853 18.8493 8.78677 18.8703 8.51617C18.8914 8.24556 18.9761 7.98376 19.1175 7.75207C20.058 6.20855 18.2917 4.44227 16.7481 5.38279C16.5164 5.52418 16.2546 5.60886 15.984 5.62992C15.7134 5.65098 15.4417 5.60784 15.1909 5.504C14.9401 5.40016 14.7174 5.23856 14.5409 5.03236C14.3644 4.82616 14.2391 4.58119 14.1752 4.3174L14.1763 4.31627Z'
      stroke={color}
      strokeWidth='2'
    />
    <path
      d='M14.5 12C14.5 13.1046 13.6046 14 12.5 14C11.3954 14 10.5 13.1046 10.5 12C10.5 10.8954 11.3954 10 12.5 10C13.6046 10 14.5 10.8954 14.5 12Z'
      stroke={color}
      strokeWidth='2'
    />
  </svg>
);
