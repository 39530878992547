import { IChangeStatusReq } from 'types';
import { http, methods } from './config';

interface IUploadMediaReq {
  data: FormData;
  getPercentageValue?: (_value: number) => void;
}

export const uploadMedia = ({ data, getPercentageValue }: IUploadMediaReq) => {
  const config = {
    method: methods.post,
    url: `/upload/media`,
    data,
    onUploadProgress: (progressEvent: any) => {
      const percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total,
      );
      if (getPercentageValue) {
        getPercentageValue(percentCompleted);
      }
    },
  };
  return http(config);
};

export const changeStatus = (data: IChangeStatusReq) => {
  const config = {
    method: methods.patch,
    url: `/change_state`,
    data,
  };
  return http(config);
};
