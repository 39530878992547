import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { YMaps } from 'react-yandex-maps';
import { QueryClient, QueryClientProvider } from 'react-query';
import { App } from './app';

import 'notyf/notyf.min.css';
import './styles/global.scss';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <ErrorBoundary>
    <QueryClientProvider client={queryClient}>
      <YMaps>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </YMaps>
    </QueryClientProvider>
  </ErrorBoundary>,
);
