import { FC } from 'react';
import { IIconProps } from './types';

export const WorkersIcon: FC<IIconProps> = ({ isActive, activeColor }) => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M19.2 17L21 7L14.7 10L12 7L9.3 10L3 7L4.8 17H19.2Z'
        stroke={isActive ? activeColor : '#C4C4C4'}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

//
