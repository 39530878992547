import { FC } from 'react';
import { TableRow, TableCell } from '@mui/material';
import { IShopCategory, IHeadCell } from 'types';

import { StyledTableHead } from './styledComponents';

const headCells: readonly IHeadCell<IShopCategory>[] = [
  {
    id: 'id',
    label: 'ID',
    sortable: true,
  },
  {
    id: 'name',
    label: 'Название',
    sortable: true,
  },
  {
    id: 'actions',
    label: 'Управление',
    sortable: false,
  },
];

export const ShopCategoriesTableHead: FC = () => {
  return (
    <StyledTableHead>
      <TableRow>
        {headCells.map(headCell => {
          return (
            <TableCell key={headCell.id} align='center' padding='normal'>
              {headCell.label}
            </TableCell>
          );
        })}
      </TableRow>
    </StyledTableHead>
  );
};
