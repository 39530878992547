import { FC } from 'react';
import { COLORS } from 'utils';

interface IDeleteIcon {
  color?: string;
  className?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
}

export const DeleteIcon: FC<IDeleteIcon> = ({
  className,
  color = COLORS.BLACK,
  ...rest
}) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
    {...rest}
  >
    <path
      d='M20 9L18.005 20.346C17.9236 20.8094 17.6815 21.2292 17.3212 21.5317C16.9609 21.8342 16.5055 22 16.035 22H7.965C7.49454 22 7.03913 21.8342 6.67882 21.5317C6.31852 21.2292 6.07639 20.8094 5.995 20.346L4 9M21 6H15.375M15.375 6V4C15.375 3.46957 15.1643 2.96086 14.7892 2.58579C14.4141 2.21071 13.9054 2 13.375 2H10.625C10.0946 2 9.58586 2.21071 9.21079 2.58579C8.83571 2.96086 8.625 3.46957 8.625 4V6M15.375 6H8.625M3 6H8.625'
      stroke={color}
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
