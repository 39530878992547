import { FC, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { styled } from '@mui/system';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { COLORS } from 'utils';

import classNames from './DateInput.module.scss';

const StyledDatePicker = styled(DatePicker)({
  '& .MuiInputBase-root': {
    fontWeight: 600,
    fontSize: '0.875rem',
    lineHeight: '1.375rem',
    fontFamily: '"SF Pro Display", sans-serif',
    padding: '0.5rem 0.625rem 0.5rem 0',
    backgroundColor: COLORS.GRAY,
    flexDirection: 'row-reverse',
  },
  '& fieldset': {
    border: 'none',
  },
  '& input': {
    padding: '0 0 0 1rem',
  },
  '& svg': {
    color: COLORS.BLUE,
  },
});

interface IDateInputProps {
  format?: string;
  closedFormat?: string;
  onChange?: (_newValue: string) => void;
  stringValue?: string | null;
  readOnly?: boolean;
  placeholder?: string;
  [key: string]: any;
}

dayjs.extend(utc);
dayjs.extend(timezone);

export const DateInput: FC<IDateInputProps> = ({
  stringValue,
  onChange,
  format = 'YYYY-MM-DD',
  closedFormat = 'DD MMMM YYYY',
  readOnly = false,
  placeholder = 'Выберите дату',
  ...rest
}) => {
  const [innerValue, setInnerValue] = useState<Dayjs | null>(
    stringValue ? dayjs.tz(stringValue) : null,
  );

  const changeHandler = (newValue: Dayjs | null) => {
    if (newValue && onChange) {
      setInnerValue(newValue);
      onChange(newValue.format(format));
    }
  };

  return (
    <StyledDatePicker
      value={innerValue}
      format={closedFormat}
      onChange={newValue => changeHandler(newValue as Dayjs | null)}
      readOnly={readOnly}
      slotProps={{
        actionBar: {
          actions: ['cancel', 'accept'],
        },
        toolbar: {
          toolbarFormat: 'DD MMMM YYYY',
          toolbarPlaceholder: 'Календарь',
          hidden: false,
          className: classNames.toolbar,
        },
        textField: {
          placeholder,
        },
      }}
      localeText={{
        cancelButtonLabel: 'Закрыть',
        okButtonLabel: 'Сохранить',
      }}
      timezone='Europe/Moscow'
      {...rest}
    />
  );
};
