import {
  FC,
  ChangeEvent,
  useState,
  CSSProperties,
  useContext,
  useEffect,
} from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { UserContext, UserContextType } from 'app';
import ImagePlaceholderVertical from 'assets/images/input_image_placeholder_vertical.png';
import { CloseIcon } from 'assets';
import { COLORS, MEDIA_TYPES, notify } from 'utils';
import { useUploadMedia } from 'hooks';
import { MediaType } from 'types';
import { CircularProgress } from 'UI';

const StyledContainer = styled(Box)(
  ({ style, disabled }: { style: any; disabled: boolean }) => ({
    ...style,
    display: 'flex',
    gap: '0.625rem',
    '& .imgContainer': {
      position: 'relative',
      '&:hover': {
        '& .removeBox': {
          visibility: 'visible',
        },
        '& .bgBox': {
          visibility: 'visible',
        },
      },
      '& .removeBox': {
        position: 'absolute',
        top: '0.5rem',
        right: '0.5rem',
        width: '1.5rem',
        height: '1.5rem',
        cursor: 'pointer',
        visibility: 'hidden',
      },
      '& .bgBox': {
        backgroundColor: 'rgba(255, 255, 255, 0.65)',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        visibility: 'hidden',
      },
    },
    '& img': {
      width: '5rem',
      height: '9rem',
      objectPosition: 'center',
      cursor: disabled ? 'not-allowed' : 'pointer',
    },
    '& video': {
      objectFit: 'cover',
    },
    '& .loadingBox': {
      width: '5rem',
      height: '9rem',
    },
  }),
);

const StyledContentBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '0.25rem',
  width: '100%',
  justifyContent: 'center',
  '& .title': {
    fontWeight: 600,
    fontFamily: '"SF Pro Display", sans-serif',
    fontSize: '1rem',
    lineHeight: '1.5rem',
  },
  '& .description': {
    fontFamily: '"SF Pro Display", sans-serif',
    fontSize: '0.875rem',
    lineHeight: '1.375rem',
    fontWeight: 400,
    opacity: 0.5,
  },
});

export interface VideoImageUploadProps {
  media: string;
  saveMediaLink: (_link: string) => void;
  inputId: string;
  title?: string;
  description?: string;
  videoMediaType?: MediaType;
  width?: number;
  height?: number;
  videoSize?: number;
  imageMediaType?: MediaType;
  imageSize?: number;
  imageWidth?: number;
  imageHeight?: number;
  disabled?: boolean;
  style?: CSSProperties;
}

export const VideoImageUpload: FC<VideoImageUploadProps> = ({
  media,
  saveMediaLink,
  inputId,
  title,
  description,
  videoMediaType = MEDIA_TYPES.STORIES_SLIDE_VIDEO,
  width = 80,
  height = 144,
  videoSize = 10 * 1024 * 1024,
  imageSize = 1 * 1024 * 1024,
  imageMediaType = MEDIA_TYPES.STORIES_SLIDE_IMAGE,
  imageWidth = 1080,
  imageHeight = 1920,
  disabled = false,
  style,
}) => {
  const { space_id } = useContext(UserContext) as UserContextType;
  const [progress, setProgress] = useState<number>(0);
  const [mediaName, setMediaName] = useState<string>('');
  const [mediaType, setMediaType] = useState<'video' | 'img' | 'none'>('none');

  const { isLoading, mutateAsync: uploadMedia } = useUploadMedia();

  useEffect(() => {
    if (media) {
      if (media.endsWith('.mp4')) {
        setMediaType('video');
      } else {
        setMediaType('img');
      }
    }
  }, [media]);

  const handleOpenFileUploadModal = () => {
    if (disabled) {
      notify('Элемент заблокирован', 'error');
      return;
    }
    document.getElementById(inputId)?.click();
  };

  const convertSizeToText = (size: number): string => {
    if (size >= 1048576) {
      const sizeInMB = size / 1048576;
      return `${sizeInMB.toFixed(1)} МБ`;
    }
    const sizeInKB = size / 1024;
    return `${sizeInKB.toFixed(0)} КБ`;
  };

  const changeProgress = (val: number) => {
    setProgress(val);
  };

  const handleUploadMedia = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }
    const file = e.target.files[0];
    if (!file) {
      return;
    }
    checkFileType(file);
  };

  const checkFileType = (file: File) => {
    const videoTypes = ['video/mp4'];
    const imageTypes = ['image/jpeg', 'image/png', 'image/jpg'];

    if (videoTypes.includes(file.type)) {
      setMediaType('video');
      handleUploadVideo(file);
    } else if (imageTypes.includes(file.type)) {
      setMediaType('img');
      handleUploadImage(file);
    }
  };

  const handleUploadImage = (file: File) => {
    if (file.size > imageSize) {
      notify(
        `Размер файла превышает максимальный предел в ${convertSizeToText(
          imageSize,
        )}.`,
        'error',
      );
      return;
    }
    const reader = new FileReader();
    reader.onloadend = () => {
      const img = new Image();
      img.src = reader.result as string;

      img.onload = () => {
        // Check the image dimensions
        if (imageWidth > 0) {
          if (img.width !== imageWidth || img.height !== imageHeight) {
            notify(
              `Высота или ширина картинки не соответствуют требуемому стандарту (${imageWidth}x${imageHeight})`,
              'error',
            );
            setMediaType('none');
            return;
          }
        } else if (imageWidth === -1) {
          if (img.height !== imageHeight) {
            notify(
              `Высота картинки не соответствуют требуемому стандарту ${imageHeight}px`,
              'error',
            );
            setMediaType('none');
            return;
          }
        }

        const formData = new FormData();
        formData.append('image', file, file.name);
        formData.append('type', imageMediaType);
        formData.append('space_id', (space_id || 1).toString());
        uploadMedia({ data: formData, getPercentageValue: changeProgress })
          .then(res => {
            saveMediaLink(res);
            setMediaName(file.name);
          })
          .catch(() => {
            setMediaType('none');
            document.getElementById(inputId)?.setAttribute('value', '');
          });
      };
    };
    reader.readAsDataURL(file);
  };

  const handleUploadVideo = (file: File) => {
    if (file.size > videoSize) {
      notify(
        `Размер файла превышает максимальный предел в ${convertSizeToText(
          videoSize,
        )}.`,
        'error',
      );
      setMediaType('none');
      return;
    }
    const formData = new FormData();
    formData.append('video', file);
    formData.append('type', videoMediaType);
    formData.append('space_id', (space_id || 1).toString());
    uploadMedia({ data: formData, getPercentageValue: changeProgress })
      .then(res => {
        saveMediaLink(res);
      })
      .catch(() => {
        setMediaType('none');
        document.getElementById(inputId)?.setAttribute('value', '');
      })
      .finally(() => {
        setProgress(0);
      });
  };

  const handleRemoveMedia = () => {
    saveMediaLink('');
    setMediaName('');
    const input = document.getElementById(inputId) as HTMLInputElement;
    if (input) {
      input.value = '';
    }
  };

  return (
    <>
      <StyledContainer style={style} disabled={disabled}>
        {media ? (
          <Box className='imgContainer'>
            {mediaType === 'img' && (
              <img
                src={media}
                alt={mediaName}
                onClick={handleOpenFileUploadModal}
              />
            )}
            {mediaType === 'video' && (
              // eslint-disable-next-line jsx-a11y/media-has-caption
              <video controls width={width} height={height}>
                <source src={media} type='video/mp4' />
                Your browser does not support the video tag.
              </video>
            )}
            <div className='bgBox' />
            <Box className='removeBox'>
              <CloseIcon
                color={COLORS.RED_2}
                className='deleteIcon'
                onClick={handleRemoveMedia}
              />
            </Box>
          </Box>
        ) : (
          <Box className='loadingBox'>
            {isLoading ? (
              <CircularProgress value={progress} />
            ) : (
              <img
                src={ImagePlaceholderVertical}
                alt='placeholder'
                onClick={handleOpenFileUploadModal}
              />
            )}
          </Box>
        )}
        <StyledContentBox>
          <p className='title'>{title}</p>
          <p className='description'>{description}</p>
        </StyledContentBox>
      </StyledContainer>
      <input
        id={inputId}
        type='file'
        style={{ display: 'none' }}
        onChange={e => handleUploadMedia(e)}
      />
    </>
  );
};
