import { FC, useEffect, useMemo } from 'react';
import { useNavigate, useLocation, Outlet } from 'react-router-dom';

import { Layout, ShopHeader, ShowcasesInnerNavbar } from 'components';
import { ROUTES } from 'app';

export const Shop: FC = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const SHOP_INNER_NAVBAR_MENU_ITEMS = useMemo(
    () => [
      // {
      //   title: 'Атрибуты',
      //   path: ROUTES.shop.attributes,
      // },
      {
        title: 'Товары',
        path: ROUTES.shop.products,
      },
      {
        title: 'Категории',
        path: ROUTES.shop.categories,
      },
    ],
    [],
  );

  useEffect(() => {
    if (pathname === ROUTES.shop.get) {
      navigate(ROUTES.shop.products);
    }
  }, [pathname, navigate]);

  return (
    <Layout>
      <ShopHeader />
      <ShowcasesInnerNavbar menuItems={SHOP_INNER_NAVBAR_MENU_ITEMS} />
      <Outlet />
    </Layout>
  );
};
