import { ICensoredWordGetParams, ICensoredWordReq } from 'types';
import { http, methods } from './config';

export const getCensoredWords = (params: ICensoredWordGetParams) => {
  const config = {
    method: methods.get,
    url: `/censored/word`,
    params,
  };
  return http(config);
};

export const getCensoredWord = (wordId: string | number) => {
  const config = {
    method: methods.get,
    url: `/censored/word/${wordId}`,
  };
  return http(config);
};

export const createCensoredWord = (data: ICensoredWordReq) => {
  const config = {
    method: methods.post,
    url: `/censored/word`,
    data,
  };
  return http(config);
};

export const updateCensoredWord = (data: ICensoredWordReq) => {
  const config = {
    method: methods.put,
    url: `/censored/word/${data.id}`,
    data,
  };
  return http(config);
};

export const deleteCensoredWord = (wordId: number | string) => {
  const config = {
    method: methods.delete,
    url: `/censored/word/${wordId}`,
  };
  return http(config);
};
