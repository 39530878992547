import { FC, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { ruRU } from '@mui/x-date-pickers';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import 'dayjs/locale/ru';

import { muiTheme, setItem, getItem, FilterDatesProvider } from 'utils';

import { Router, ROUTES } from './routes';
import { UserProvider } from './UserContext';

export const App: FC = () => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const token = getItem('token');

  useEffect(() => {
    window.scroll(0, 0);
    if (pathname === '/') {
      if (!token || token === 'null') {
        navigate(ROUTES.auth);
        return;
      }
      navigate(ROUTES.home);
    }
  }, [pathname]);

  useEffect(() => {
    if (!token || token === 'null') return;
    const lastRoute = getItem('lastRoute') || ROUTES.initialRoute;
    navigate(lastRoute);
  }, []);

  useEffect(() => {
    setItem('lastRoute', `${pathname}${search}`);
  }, [pathname, search]);

  dayjs.locale('ru');

  return (
    <ThemeProvider theme={muiTheme}>
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        localeText={
          ruRU.components.MuiLocalizationProvider.defaultProps.localeText
        }
      >
        <DndProvider backend={HTML5Backend}>
          <UserProvider>
            <FilterDatesProvider>
              <Router />
            </FilterDatesProvider>
          </UserProvider>
        </DndProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
};
