import * as Pages from 'pages';
import { ROLES } from 'utils';
import { IRoutes } from '../types';

export const Showcases: IRoutes = {
  showcases: {
    path: '/showcases',
    title: 'showcases',
    element: <Pages.Showcases />,
    roles: [ROLES.ADMINSTRATOR],
    childRoute: {
      offerAndDiscounts: {
        path: 'offer-and-discounts',
        title: 'offerAndDiscounts',
        element: <Pages.OfferAndDiscounts />,
        roles: [ROLES.ADMINSTRATOR],
        index: true,
      },
      discounts: {
        path: 'discounts',
        title: 'discounts',
        element: <Pages.Discounts />,
        roles: [ROLES.ADMINSTRATOR],
        index: true,
      },
      offers: {
        path: 'offers',
        title: 'offers',
        element: <Pages.Offers />,
        roles: [ROLES.ADMINSTRATOR],
      },
      categories: {
        path: 'categories',
        title: 'categories',
        element: <Pages.ShowcaseCategories />,
        roles: [ROLES.ADMINSTRATOR],
      },
    },
  },
};
