import { Box, Paper, Table, TableContainer, TableRow } from '@mui/material';
import { styled } from '@mui/system';
import { COLORS } from 'utils';

export const StyledBox = styled(Box)({
  width: '100%',
  overflow: 'auto',
});

export const StyledPaper = styled(Paper)({
  width: '100%',
  marginBottom: 2,
});

export const StyledTableContainer = styled(TableContainer)({
  maxHeight: 'calc(100vh - 12rem)',
  minHeight: 'calc(100vh - 12rem)',
});

export const StyledTable = styled(Table)({
  minWidth: '1040px',
});

export const StyledTableRow = styled(TableRow)({
  '& span': {
    opacity: 0.5,
    fontSize: '0.875rem',
    fontWeight: 400,
    lineHeight: '1rem',
    fontFamily: '"SF Pro Display", sans-serif',
  },
  '& .nameCell': {
    width: '30%',
  },
  '& .success': {
    color: COLORS.GREEN,
    fontWeight: 600,
  },
  '& .failure': {
    color: COLORS.RED,
    fontWeight: 600,
  },
  '& .editDeleteIcons': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    gap: '0.5rem',
    '& .switch': {
      marginTop: '-0.5rem',
    },
    '& svg': {
      cursor: 'pointer',
    },
  },
});

export const StyledProductInfoAtModal = styled(Box)({
  display: 'flex',
  gap: '0.625rem',
  border: `1px solid ${COLORS.GRAY}`,
  padding: '0.625rem',
  borderRadius: '0.5rem',
});

export const StyledProductInfo = styled(Box)({
  display: 'flex',
  gap: '0.625rem',
  alignItems: 'center',
});

export const StyledAvatar = styled(Box)({
  minWidth: '5rem',
  maxWidth: '5rem',
  height: '3.125rem',
  borderRadius: '0.5rem',
  overflow: 'hidden',
  cursor: 'pointer',
  '& img': {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
  },
});

export const StyledProductName = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  cursor: 'pointer',
  fontSize: '0.875rem',
  fontFamily: '"SF Pro Display", sans-serif',
  fontWeight: 600,
  minWidth: '10rem',
});
