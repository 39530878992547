import { FC } from 'react';

import { Layout, Moderations, ModerationHeader } from 'components';

export const Moderation: FC = () => {
  return (
    <Layout withoutPB>
      <ModerationHeader />
      <Moderations />
    </Layout>
  );
};
