import * as Pages from 'pages';
import { ROLES } from 'utils';
import { IRoutes } from '../types';

export const Shop: IRoutes = {
  shop: {
    path: '/shop',
    title: 'shop',
    element: <Pages.Shop />,
    roles: [ROLES.ADMINSTRATOR],
    childRoute: {
      products: {
        path: 'products',
        title: 'products',
        element: <Pages.Products />,
        roles: [ROLES.ADMINSTRATOR],
        index: true,
      },
      categories: {
        path: 'categories',
        title: 'categories',
        element: <Pages.ShopCategories />,
        roles: [ROLES.ADMINSTRATOR],
        index: true,
      },
    },
  },
  createEditProduct: {
    path: '/shop/products/edit',
    title: 'create product',
    element: <Pages.CreateEditProduct />,
    roles: [ROLES.ADMINSTRATOR],
    index: true,
  },
};
