import { FC, useMemo } from 'react';
import { styled } from '@mui/system';
import { Box } from '@mui/material';
import { isDateInFuture, isDateInPast, COLORS } from 'utils';

const StyledBadge = styled(Box)(({ status }: { status: Status }) => ({
  padding: '0.1875rem 0.625rem',
  borderRadius: '6.25rem',
  backgroundColor: TOOLBOX[status].bgColor,
  fontSize: '0.75rem',
  fontWeight: 600,
  lineHeight: '1.125rem',
  fontFamily: '"SF Pro Text", sans-serif',
}));

const TOOLBOX: Record<
  Status,
  {
    text: string;
    bgColor: string;
  }
> = {
  future: {
    text: 'Предстоящий',
    bgColor: COLORS.ORANGE,
  },
  past: {
    text: 'Завершенный',
    bgColor: COLORS.BLUE,
  },
  active: {
    text: 'Активный',
    bgColor: COLORS.GREEN,
  },
  inActive: {
    text: 'Неактивный',
    bgColor: COLORS.RED,
  },
};

interface IChallengeStatusBadgeProps {
  startDate: string;
  endDate: string;
  isActive: boolean;
}

type Status = 'future' | 'past' | 'active' | 'inActive';

export const ChallengeStatusBadge: FC<IChallengeStatusBadgeProps> = ({
  startDate,
  endDate,
  isActive,
}) => {
  const status: Status = useMemo(() => {
    if (!isActive) {
      return 'inActive';
    }
    if (isDateInFuture(startDate)) {
      return 'future';
    }
    if (isDateInPast(endDate)) {
      return 'past';
    }
    return 'active';
  }, [isActive, startDate, endDate]);
  return <StyledBadge status={status}>{TOOLBOX[status].text}</StyledBadge>;
};
