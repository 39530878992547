import { FC, ReactNode } from 'react';

import { Sidebar } from 'components';

import classes from './Layout.module.scss';

interface ILayout {
  children?: ReactNode;
  className?: string;
  withoutPB?: boolean;
}

export const Layout: FC<ILayout> = ({ children, className, withoutPB }) => {
  return (
    <div className={classes.layout}>
      <Sidebar />
      <div className={`${classes.layout__content} ${withoutPB&&classes.layout__content_withoutPB} ${className}`}>
        {children}
      </div>
    </div>
  );
};
