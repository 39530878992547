import { IShowcaseCategoryReq, IShowcaseCategoryReqParams } from 'types';
import { http, methods } from '../config';

export const getShowcaseCategories = (params: IShowcaseCategoryReqParams) => {
  const config = {
    method: methods.get,
    url: `/shop/showcase/category`,
    params,
  };
  return http(config);
};

export const getShowcaseCategory = (id: string | number) => {
  const config = {
    method: methods.get,
    url: `/shop/showcase/category/${id}`,
  };
  return http(config);
};

export const createShowcaseCategory = (data: IShowcaseCategoryReq) => {
  const config = {
    method: methods.post,
    url: `/shop/showcase/category`,
    data,
  };
  return http(config);
};

export const updateShowcaseCategory = (data: IShowcaseCategoryReq) => {
  const config = {
    method: methods.put,
    url: `/shop/showcase/category/${data.id}`,
    data,
  };
  return http(config);
};

export const deleteShowcaseCategory = (id: number | string) => {
  const config = {
    method: methods.delete,
    url: `/shop/showcase/category/${id}`,
  };
  return http(config);
};

export const getShowcaseCategoriesList = () => {
  const config = {
    method: methods.get,
    url: `/list/showcase_category`,
  };
  return http(config);
};
