import { Component } from 'react';
import { Button } from 'UI';
import classNames from 'classnames';

import Styles from './ErrorBoundary.module.scss';

interface IErrorBoundaryProps {
  children?: React.ReactNode;
}

class ErrorBoundary extends Component<IErrorBoundaryProps> {
  state = {
    hasError: false,
    errorMessage: '',
  };

  static getDerivedStateFromError(error: any) {
    return { hasError: true, errorMessage: error.message };
  }

  handleButtonClick = () => {
    window.location.href = '/';
  };

  render() {
    const {
      state: { hasError, errorMessage },
      props: { children },
    } = this;

    if (hasError) {
      return (
        <div className={classNames('container', Styles.errorBoundary)}>
          <p>Что-то пошло не так</p>
          <code>{errorMessage}</code>
          <Button onClick={this.handleButtonClick} className={Styles.button}>Перейти на главную</Button>
        </div>
      );
    }
    return children;
  }
}

export default ErrorBoundary;
