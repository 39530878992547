import { ILoginData, IChangeSpaceReq } from 'types';
import { http, methods } from './config';

export const getSmsCode = (phone: string) => {
  const config = {
    method: methods.post,
    url: `/auth/send_sms`,
    data: { phone },
  };
  return http(config);
};

export const login = (data: ILoginData) => {
  const config = {
    method: methods.post,
    url: `/auth/sign_in`,
    data,
  };
  return http(config);
};

export const logout = () => {
  const config = {
    method: methods.put,
    url: `/logout`,
  };
  return http(config);
};

export const changeSpace = (data: IChangeSpaceReq) => {
  const config = {
    method: methods.patch,
    url: `/user/change_space`,
    data,
  };
  return http(config);
};

export const getCurrentUser = () => {
  const config = {
    method: methods.get,
    url: `/user/current`,
  };
  return http(config);
};
