import { useQuery, useMutation, useQueryClient } from 'react-query';
import {
  getAppUsers,
  getAppUser,
  createAppUser,
  updateAppUser,
  deleteAppUser,
} from 'api';
import { IAppUser, IAppUserGetParams, IAppUserRes } from 'types';

export const useGetAppUsers = (params: IAppUserGetParams) => {
  const { page, search, space_id, column, sort_type } = params;
  return useQuery<IAppUserRes, unknown>(
    ['appUsers', { page, search, space_id, column, sort_type }],
    async () => {
      const res = await getAppUsers(params);
      return res;
    },
    {
      staleTime: 1000 * 60 * 10, // 10 minutes
      retry: false,
    },
  );
};

export const useGetAppUser = (appUserId: number | string) => {
  if (!appUserId)
    return useQuery(['appUsers', 'appUserId', appUserId], () => {
      return Promise.resolve(null);
    });
  return useQuery<IAppUser>(
    ['appUsers', 'appUserId', appUserId],
    async () => {
      const res = await getAppUser(appUserId);
      return res;
    },
    {
      staleTime: 1000 * 60 * 10, // 10 minutes
      retry: false,
    },
  );
};

export const useCreateAppUser = () => {
  const queryClient = useQueryClient();
  return useMutation(createAppUser, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['appUsers'] });
    },
    onError: () => {},
  });
};

export const useUpdateAppUser = () => {
  const queryClient = useQueryClient();
  return useMutation(updateAppUser, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['appUsers'] });
    },
    onError: () => {},
  });
};

export const useDeleteAppUser = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteAppUser, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['appUsers'] });
    },
    onError: () => {},
  });
};
