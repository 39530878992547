export const NotificationIcon = () => {
  return (
    <svg
      width='30'
      height='30'
      viewBox='0 0 30 30'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M22.6675 13.75C23.3938 20.4688 26.25 22.5 26.25 22.5H3.75C3.75 22.5 7.5 19.8337 7.5 10.5C7.5 8.37875 8.29 6.34375 9.69625 4.84375C11.1038 3.34375 13.0125 2.5 15 2.5C15.4213 2.5 15.84 2.5375 16.25 2.6125M17.1625 26.25C16.943 26.6292 16.6276 26.9439 16.2481 27.1628C15.8685 27.3816 15.4381 27.4968 15 27.4968C14.5619 27.4968 14.1315 27.3816 13.7519 27.1628C13.3724 26.9439 13.057 26.6292 12.8375 26.25M23.75 10C24.7446 10 25.6984 9.60491 26.4017 8.90165C27.1049 8.19839 27.5 7.24456 27.5 6.25C27.5 5.25544 27.1049 4.30161 26.4017 3.59835C25.6984 2.89509 24.7446 2.5 23.75 2.5C22.7554 2.5 21.8016 2.89509 21.0983 3.59835C20.3951 4.30161 20 5.25544 20 6.25C20 7.24456 20.3951 8.19839 21.0983 8.90165C21.8016 9.60491 22.7554 10 23.75 10Z'
        stroke='#0080FF'
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
