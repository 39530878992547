import { FC } from 'react';
import { COLORS } from 'utils';

interface IBatteryIconProps {
  color?: string;
}

export const BatteryIcon: FC<IBatteryIconProps> = ({
  color = COLORS.WHITE,
}) => {
  return (
    <svg
      width='18'
      height='9'
      viewBox='0 0 18 9'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        opacity='0.35'
        x='0.646396'
        y='1.2023'
        width='15.008'
        height='7.3849'
        rx='1.54845'
        stroke={color}
        strokeWidth='0.714668'
      />
      <path
        opacity='0.4'
        d='M16.7266 3.46484V6.32352C17.3017 6.0814 17.6757 5.51818 17.6757 4.89418C17.6757 4.27018 17.3017 3.70696 16.7266 3.46484Z'
        fill={color}
      />
      <rect
        x='1.71875'
        y='2.27441'
        width='12.864'
        height='5.2409'
        rx='0.952891'
        fill={color}
      />
    </svg>
  );
};
