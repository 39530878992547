import { useQuery, useMutation, useQueryClient } from 'react-query';

import {
  getShowcaseOfferDiscounts,
  getShowcaseOfferDiscount,
  createShowcaseOfferDiscount,
  updateShowcaseOfferDiscount,
  deleteShowcaseOfferDiscount,
  uploadShowcaseFile,
  uploadShowcaseBranchesFile,
  getShowcaseBranchesFileSample,
  duplicateShowcaseOfferDiscount,
} from 'api';

import {
  IShowcaseOfferDiscount,
  IShowcaseOfferDiscountReqParams,
  IShowcaseOfferDiscountRes,
} from 'types';

export const useGetShowcaseOfferDiscounts = (
  params: IShowcaseOfferDiscountReqParams,
) => {
  const { category_id, page, search, type, column, sort_type } = params;
  return useQuery<IShowcaseOfferDiscountRes, unknown>(
    [
      'showcaseOfferDiscounts',
      { category_id, page, search, type, column, sort_type },
    ],
    async () => {
      const res = await getShowcaseOfferDiscounts(params);
      return res;
    },
    {
      staleTime: Infinity,
      retry: false,
    },
  );
};

export const useGetShowcaseOfferDiscount = (
  params: IShowcaseOfferDiscountReqParams,
) => {
  const { category_id, id } = params;
  if (!id || id === '0' || !category_id) {
    return useQuery(['showcaseOfferDiscounts', 'item', id], () => {
      return Promise.resolve(null);
    });
  }
  return useQuery<IShowcaseOfferDiscount, unknown>(
    ['showcaseOfferDiscounts', 'item', { category_id, id }],
    async () => {
      const res = await getShowcaseOfferDiscount(params);
      return res;
    },
    {
      retry: false,
      staleTime: Infinity,
    },
  );
};

export const useCreateShowcaseOfferDiscount = () => {
  const queryClient = useQueryClient();
  return useMutation(createShowcaseOfferDiscount, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['showcaseOfferDiscounts'] });
    },
    onError: () => {},
  });
};

export const useUpdateShowcaseOfferDiscount = () => {
  const queryClient = useQueryClient();
  return useMutation(updateShowcaseOfferDiscount, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['showcaseOfferDiscounts'] });
    },
    onError: () => {},
  });
};

export const useDeleteShowcaseOfferDiscount = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteShowcaseOfferDiscount, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['showcaseOfferDiscounts'] });
    },
    onError: () => {},
  });
};

export const useUploadShowcaseFile = () => {
  return useMutation(uploadShowcaseFile, {
    onSuccess: () => {},
    onError: () => {},
  });
};

export const useGetShowcaseBranchesFileSample = () => {
  return useQuery(
    ['showcase', 'file', 'address', 'sample'],
    async () => {
      const res = await getShowcaseBranchesFileSample();
      return res;
    },
    {
      staleTime: Infinity,
      retry: false,
    },
  );
};

export const useUploadShowcaseBranchesFile = () => {
  return useMutation(uploadShowcaseBranchesFile, {
    onSuccess: () => {},
    onError: () => {},
  });
};

export const useDuplicateShowcaseOfferDiscount = () => {
  const queryClient = useQueryClient();
  return useMutation(duplicateShowcaseOfferDiscount, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['showcaseOfferDiscounts'] });
    },
    onError: () => {},
  });
};
