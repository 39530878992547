import { FC, useState, useMemo, MouseEvent } from 'react';
import { TableBody, TableCell } from '@mui/material';
import { useGetShopAttribute, useSearchParams } from 'hooks';
import { IShopAttributeValue, TableRowsOrder } from 'types';
import { getComparator } from 'utils';

import {
  StyledBox,
  StyledPaper,
  StyledTableContainer,
  StyledTable,
  StyledTableRow,
} from './styledComponents';
import { AttributeValuesTableHead } from './AttributeValuesTableHead';

export const AttributeValuesTable: FC = () => {
  const [order, setOrder] = useState<TableRowsOrder>('asc');
  const [orderBy, setOrderBy] = useState<keyof IShopAttributeValue>('position');
  const { getParam } = useSearchParams();
  if (!getParam('attribute_id')) return null;

  const { data } = useGetShopAttribute(getParam('attribute_id'));

  const handleRequestSort = (
    _event: MouseEvent<unknown>,
    property: keyof IShopAttributeValue,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const visibleRows = useMemo(() => {
    if (!data?.values) return [];
    return data.values.sort(getComparator(order, orderBy));
  }, [order, orderBy, data]);

  return (
    <StyledBox>
      <StyledPaper>
        <StyledTableContainer>
          <StyledTable
            aria-labelledby='attributeValuesTable'
            size='medium'
            stickyHeader
          >
            <AttributeValuesTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {visibleRows.map(row => {
                return (
                  <StyledTableRow hover key={row.id}>
                    <TableCell
                      component='th'
                      id={row.id?.toString()}
                      scope='row'
                      //   style={{ textAlign: 'center' }}
                    >
                      <span>{row.position}</span>
                    </TableCell>
                    <TableCell>
                      <span>{row.name}</span>
                    </TableCell>

                    <TableCell>
                      <span>{row.value}</span>
                    </TableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </StyledTable>
        </StyledTableContainer>
      </StyledPaper>
    </StyledBox>
  );
};
