import { FC, useState, useEffect } from 'react';
import { Box, TextField } from '@mui/material';
import {
  Drawer,
  DrawerHeader,
  DrawerFormBody,
  ModalTitle,
  Button,
  StyledFlexBox,
  FormItem,
} from 'UI';
import { CloseIcon } from 'assets';
import { INotificationReq } from 'types';
import {
  useGetNotification,
  useCreateNotification,
  useUpdateNotification,
} from 'hooks';

const TRANSLATIONS = {
  add: {
    title: 'Новое уведомление',
  },
  edit: {
    title: 'Редактирование уведомления',
  },
};

const DEFAULT_VALUES = {
  title: '',
  text: '',
  is_active: true,
};

interface IAddEditNotificationDrawerProps {
  open: boolean;
  onClose: () => void;
  purpose: 'add' | 'edit';
  id: string;
}

export const AddEditNotificationDrawer: FC<IAddEditNotificationDrawerProps> = ({
  open,
  onClose,
  purpose = 'add',
  id = '',
}) => {
  const { data: notificationInfoData } = useGetNotification(id);
  const [notificationInfo, setNotificationInfo] =
    useState<INotificationReq>(DEFAULT_VALUES);

  const { mutateAsync: createNotification } = useCreateNotification();
  const { mutateAsync: updateNotification } = useUpdateNotification();

  useEffect(() => {
    if (purpose === 'edit') {
      setNotificationInfo({
        id: notificationInfoData?.id,
        title: notificationInfoData?.title || '',
        text: notificationInfoData?.text || '',
        is_active: !!notificationInfoData?.is_active,
      });
    } else {
      setNotificationInfo(DEFAULT_VALUES);
    }
  }, [purpose, notificationInfoData]);

  const handleFieldChange = <T extends keyof INotificationReq>(
    fieldName: T,
    newValue: INotificationReq[T],
  ) => {
    setNotificationInfo(prevNotificationInfo => ({
      ...prevNotificationInfo,
      [fieldName]: newValue,
    }));
  };

  const ignoreChanges = () => {
    setNotificationInfo(DEFAULT_VALUES);
    onClose();
  };
  const saveChanges = () => {
    if (purpose === 'add') {
      createNotification(notificationInfo)
        .then(() => {
          onClose();
        })
        .catch(() => {});
    } else {
      updateNotification(notificationInfo)
        .then(() => {
          onClose();
        })
        .catch(() => {});
    }
  };

  return (
    <Drawer open={open} onClose={ignoreChanges}>
      <DrawerHeader>
        <ModalTitle title={TRANSLATIONS[purpose].title} />
        <CloseIcon onClick={ignoreChanges} />
      </DrawerHeader>
      <DrawerFormBody>
        <h2>Основная информация</h2>
        <FormItem label='Заголовок'>
          <TextField
            variant='standard'
            color='primary'
            placeholder='Введите заголовок'
            name='title'
            value={notificationInfo.title}
            onChange={e => handleFieldChange('title', e.target.value)}
          />
        </FormItem>
        <FormItem label='Описание'>
          <TextField
            variant='standard'
            color='primary'
            placeholder='Введите описание'
            name='description'
            value={notificationInfo.text}
            onChange={e => handleFieldChange('text', e.target.value)}
          />
        </FormItem>
      </DrawerFormBody>
      <StyledFlexBox className='buttons fullDivWidth'>
        <Box />
        <Button variant='contained' size='large' onClick={saveChanges}>
          Сохранить
        </Button>
      </StyledFlexBox>
    </Drawer>
  );
};
