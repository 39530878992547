export const DistanceIcon = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g filter='url(#filter0_b_3391_54681)'>
        <path
          d='M12 24C18.5838 24 24 18.5838 24 12C24 5.41625 18.5717 0 11.988 0C5.40421 0 0 5.41625 0 12C0 18.5838 5.41625 24 12 24Z'
          fill='url(#paint0_linear_3391_54681)'
        />
      </g>
      <g filter='url(#filter1_b_3391_54681)'>
        <path
          d='M7.09008 12.9849C6.84229 12.7371 6.82924 12.3719 7.10312 12.0981C7.20093 12.0002 7.33787 11.9155 7.50089 11.8437L15.2151 8.48549C15.6585 8.28986 16.0106 8.32899 16.2649 8.5833C16.5193 8.83762 16.5584 9.18974 16.3628 9.63316L13.0045 17.3474C12.9328 17.5104 12.848 17.6473 12.7502 17.7451C12.4763 18.019 12.1177 18.0125 11.8634 17.7582C11.7329 17.6277 11.6416 17.4191 11.6482 17.1648L11.6482 13.5131C11.6482 13.3957 11.6286 13.324 11.5764 13.2718C11.5243 13.2196 11.446 13.1936 11.3352 13.2001L7.68348 13.2001C7.42264 13.2001 7.2205 13.1153 7.09008 12.9849Z'
          fill='white'
        />
      </g>
      <defs>
        <filter
          id='filter0_b_3391_54681'
          x='-4.8'
          y='-4.8'
          width='33.6'
          height='33.6'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feGaussianBlur in='BackgroundImageFix' stdDeviation='2.4' />
          <feComposite
            in2='SourceAlpha'
            operator='in'
            result='effect1_backgroundBlur_3391_54681'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_backgroundBlur_3391_54681'
            result='shape'
          />
        </filter>
        <filter
          id='filter1_b_3391_54681'
          x='2.10234'
          y='3.56182'
          width='19.1859'
          height='19.1879'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feGaussianBlur in='BackgroundImageFix' stdDeviation='2.4' />
          <feComposite
            in2='SourceAlpha'
            operator='in'
            result='effect1_backgroundBlur_3391_54681'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_backgroundBlur_3391_54681'
            result='shape'
          />
        </filter>
        <linearGradient
          id='paint0_linear_3391_54681'
          x1='6.24'
          y1='11.52'
          x2='18.0091'
          y2='11.2535'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#0080FF' />
          <stop offset='1' stopColor='#1DCCFD' />
        </linearGradient>
      </defs>
    </svg>
  );
};
