import { useQuery, useMutation, useQueryClient } from 'react-query';
import {
  getCensoredWords,
  getCensoredWord,
  createCensoredWord,
  updateCensoredWord,
  deleteCensoredWord,
} from 'api';
import { ICensoredWordRes, ICensoredWord, ICensoredWordGetParams } from 'types';

export const useGetCensoredWords = (params: ICensoredWordGetParams) => {
  const { page, search } = params;
  return useQuery<ICensoredWordRes, unknown>(
    ['censoredWords', { page, search }],
    async () => {
      const res = await getCensoredWords(params);
      return res;
    },
    {
      staleTime: Infinity,
      retry: false,
    },
  );
};

export const useGetCensoredWord = (wordId: string | number) => {
  if (!wordId)
    return useQuery(['censoredWords', 'wordId', wordId], () => {
      return Promise.resolve(null);
    });
  return useQuery<ICensoredWord>(
    ['censoredWords', 'wordId', wordId],
    async () => {
      const res = await getCensoredWord(wordId);
      return res;
    },
    {
      staleTime: Infinity,
      retry: false,
    },
  );
};

export const useCreateCensoredWord = () => {
  const queryClient = useQueryClient();
  return useMutation(createCensoredWord, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['censoredWords'] });
    },
    onError: () => {},
  });
};

export const useUpdateCensoredWord = () => {
  const queryClient = useQueryClient();
  return useMutation(updateCensoredWord, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['censoredWords'] });
    },
    onError: () => {},
  });
};

export const useDeleteCensoredWord = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteCensoredWord, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['censoredWords'] });
    },
    onError: () => {},
  });
};
