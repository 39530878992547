import { styled } from '@mui/system';
import { Box } from '@mui/material';
import { COLORS } from 'utils';

export const StyledNewsCommentsLayout = styled(Box)({
  display: 'flex',
  width: '100%',
  borderRadius: '1rem',
  overflow: 'hidden',
  backgroundColor: COLORS.WHITE,
  '& .newsPreviewBox': {
    height: 'calc(100vh - 10rem)',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  '& .newsPreview': {
    margin: 0,
  },
});

export const StyledNewsCommentsTable = styled(Box)({
  backgroundColor: COLORS.WHITE,
  width: 'calc(100% - 375px)',
  padding: '2rem',
});

export const StyledCommentsTableHeader = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '1.25rem',
  '& .data': {
    display: 'flex',
    alignItems: 'center',
    gap: '0.75rem',
  },
  '& .title': {
    fontSize: '1.125rem',
    lineHeight: '1.625rem',
    fontWeight: 700,
    fontFamily: '"SF Pro Display", sans-serif',
    color: COLORS.BLACK,
  },
  '& .commentsCount': {
    display: 'flex',
    gap: '0.5rem',
    alignItems: 'center',
    '& p': {
      fontSize: '1rem',
      fontWeight: 500,
      lineHeight: '1.25rem',
    },
  },
  '& .deleteAllCommentsButton': {
    color: COLORS.RED_2,
  },
});
