import { FC } from 'react';
import { styled } from '@mui/system';
import { Box } from '@mui/material';
import { DeleteModal } from 'components';
import { useDeletePartner } from 'hooks';
import { COLORS } from 'utils';

export const StyledPartnerInfoAtModal = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  fontWeight: 600,
  gap: '0.625rem',
  border: `1px solid ${COLORS.GRAY}`,
  padding: '0.625rem',
  borderRadius: '0.5rem',
  '& .name': {},
});

export const StyledLogo = styled(Box)({
  width: '3.125rem',
  height: '3.125rem',
  borderRadius: '50%',
  overflow: 'hidden',
  cursor: 'pointer',
  '& img': {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
  },
});

interface IDeletePartnerModalProps {
  open: boolean;
  onClose: () => void;
  id?: string | number;
  name?: string;
  logo?: string;
}

export const DeletePartnerModal: FC<IDeletePartnerModalProps> = ({
  open,
  onClose,
  id = '',
  name = '',
  logo = '',
}) => {
  const { mutateAsync: deletePartner } = useDeletePartner();

  const handleDeletePartner = () => {
    deletePartner(id)
      .then(() => {
        onClose();
      })
      .catch(() => {});
  };

  return (
    <DeleteModal
      open={open}
      onConfirm={handleDeletePartner}
      onClose={onClose}
      title='Удалить партнера'
      description='Вы действительно хотите удалить партнера'
    >
      <StyledPartnerInfoAtModal>
        <StyledLogo>
          <img src={logo} alt={name} />
        </StyledLogo>
        <Box className='name'>{name}</Box>
      </StyledPartnerInfoAtModal>
    </DeleteModal>
  );
};
