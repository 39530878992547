import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/system';
import { Box } from '@mui/material';
import { COLORS } from 'utils';
import { LeftArrowIcon } from 'assets';
import { GlobalSearch, PageTitle } from 'UI';
import { ROUTES } from 'app';

const StyledLayout = styled(Box)({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '1.8125rem',
});

const StyledLeftSide = styled(Box)({
  display: 'flex',
  gap: '1.25rem',
  alignItems: 'center',
});

const StyledButton = styled(Box)({
  width: '3.125rem',
  height: '3.125rem',
  borderRadius: '50%',
  border: `1px solid ${COLORS.RED_2}`,
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

interface INewsSubPagesHeaderProps {
  title?: string;
  withSearch?: boolean;
}

export const NewsSubPagesHeader: FC<INewsSubPagesHeaderProps> = ({
  title = 'Создать новость',
  withSearch,
}) => {
  const navigate = useNavigate();
  const handleBack = () => {
    navigate(ROUTES.news);
  };
  return (
    <StyledLayout>
      <StyledLeftSide>
        <StyledButton onClick={handleBack}>
          <LeftArrowIcon color={COLORS.RED_2} />
        </StyledButton>
        <PageTitle title={title} />
      </StyledLeftSide>
      {withSearch && <GlobalSearch />}
    </StyledLayout>
  );
};
