import { FC } from 'react';
import { useDrawer } from 'hooks';
import {
  Layout,
  PartnersHeader,
  AddEditPartnerDrawer,
  PartnersBody,
} from 'components';

export const Partners: FC = () => {
  const {
    openAddDrawer,
    editDrawerId,
    purpose,
    hideAddEditDrawer,
    showAddEditDrawer,
  } = useDrawer();
  return (
    <Layout withoutPB>
      <AddEditPartnerDrawer
        open={showAddEditDrawer}
        onClose={hideAddEditDrawer}
        purpose={purpose}
        id={editDrawerId}
      />
      <PartnersHeader openAddPartnerModal={openAddDrawer} />
      <PartnersBody />
    </Layout>
  );
};
