import { FC } from 'react';
import { COLORS } from 'utils';

interface IAntennaIconProps {
  color?: string;
}

export const AntennaIcon: FC<IAntennaIconProps> = ({
  color = COLORS.WHITE,
}) => {
  return (
    <svg
      width='13'
      height='8'
      viewBox='0 0 13 8'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.4684 0.0830078H10.7537C10.359 0.0830078 10.0391 0.402976 10.0391 0.797676V6.99147C10.0391 7.38617 10.359 7.70613 10.7537 7.70613H11.4684C11.8631 7.70613 12.1831 7.38617 12.1831 6.99147V0.797676C12.1831 0.402976 11.8631 0.0830078 11.4684 0.0830078ZM7.41975 1.75055H8.13441C8.52911 1.75055 8.84908 2.07052 8.84908 2.46522V6.99145C8.84908 7.38615 8.52911 7.70612 8.13441 7.70612H7.41975C7.02505 7.70612 6.70508 7.38615 6.70508 6.99145V2.46522C6.70508 2.07052 7.02505 1.75055 7.41975 1.75055ZM4.79945 3.41809H4.08478C3.69008 3.41809 3.37012 3.73806 3.37012 4.13276V6.99143C3.37012 7.38613 3.69008 7.7061 4.08478 7.7061H4.79945C5.19415 7.7061 5.51412 7.38613 5.51412 6.99143V4.13276C5.51412 3.73806 5.19415 3.41809 4.79945 3.41809ZM1.46449 4.84802H0.749824C0.355124 4.84802 0.0351562 5.16799 0.0351562 5.56269V6.99203C0.0351562 7.38673 0.355124 7.70669 0.749824 7.70669H1.46449C1.85919 7.70669 2.17916 7.38673 2.17916 6.99203V5.56269C2.17916 5.16799 1.85919 4.84802 1.46449 4.84802Z'
        fill={color}
      />
    </svg>
  );
};
