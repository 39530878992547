import { FC, useMemo } from 'react';
import { Box, Popover } from '@mui/material';
import { StyledWinOptions, StyledNoPlacesLeft } from '../styledComponents';

interface IPlacePopoverProps {
  showPopover: boolean;
  anchorEl: HTMLButtonElement | null;
  availablePlaces: number[];
  hideWinPopover: () => void;
  addToWinners: (_place: number) => void;
}

export const PlacePopover: FC<IPlacePopoverProps> = ({
  showPopover,
  anchorEl,
  availablePlaces,
  hideWinPopover,
  addToWinners,
}) => {
  const sortedPlaces = useMemo(() => {
    // Sort places in ascending order,remove 0 and return
    return availablePlaces.sort((a, b) => a - b).filter(place => place !== 0);
  }, [availablePlaces]);
  return (
    <Popover
      id='winPopover'
      open={showPopover}
      onClose={hideWinPopover}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      style={{ borderRadius: '0.5rem' }}
    >
      <Box>
        {sortedPlaces.length > 0 ? (
          <StyledWinOptions>
            {sortedPlaces.map(place => (
              <Box
                className='option'
                onClick={() => addToWinners(place)}
                key={place}
              >
                {place} место
              </Box>
            ))}
          </StyledWinOptions>
        ) : (
          <StyledNoPlacesLeft>Все призы разыграны</StyledNoPlacesLeft>
        )}
      </Box>
    </Popover>
  );
};
