import { FC } from 'react';
import { UserImage } from 'UI';

import {
  StyledBox,
  StyledCompanyInfo,
  StyledUserInfo,
  StyledUserName,
  StyledUserRole,
} from './styledComponents';

interface IUserInfo {
  companyName?: string;
  companyImageUrl?: string;
  userImageUrl?: string;
  userRole?: string;
  firstName?: string;
  lastName?: string;
}

export const UserInfo: FC<IUserInfo> = ({
  companyName = '',
  companyImageUrl = '',
  userImageUrl = '',
  userRole = '',
  firstName = '',
  lastName = '',
}) => {
  return (
    <StyledBox>
      <StyledCompanyInfo>
        <img src={companyImageUrl} alt={companyName} />
        <p>{companyName}</p>
      </StyledCompanyInfo>
      <StyledUserInfo>
        <UserImage
          src={userImageUrl}
          alt={`${firstName[0]} ${lastName[0]}`}
          width='2.5rem'
          height='2.5rem'
          firstName={firstName}
          lastName={lastName}
          className='image'
        />
        <div className='placeholder'>
          <StyledUserName>
            {firstName} {lastName}
          </StyledUserName>
          <StyledUserRole>{userRole}</StyledUserRole>
        </div>
      </StyledUserInfo>
    </StyledBox>
  );
};
