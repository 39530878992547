import { useQuery, useMutation, useQueryClient } from 'react-query';
import { IStory, IStoryGetParams, IStoriesRes } from 'types';
import {
  getStories,
  getStory,
  createStory,
  updateStory,
  deleteStory,
} from 'api';

export const useGetStories = (params: IStoryGetParams) => {
  const { page, search } = params;
  return useQuery<IStoriesRes, unknown>(
    ['stories', { page, search }],
    async () => {
      const res = await getStories(params);
      return res;
    },
    {
      staleTime: 60 * 60 * 1000,
      retry: false,
    },
  );
};

export const useGetStory = (storyId: string | number) => {
  if (!storyId)
    return useQuery(['stories', 'storyId', storyId], () => {
      return Promise.resolve(null);
    });
  return useQuery<IStory>(
    ['stories', 'storyId', storyId],
    async () => {
      const res = await getStory(storyId);
      return res;
    },
    {
      staleTime: 60 * 60 * 1000,
      retry: false,
    },
  );
};

export const useCreateStory = () => {
  const queryClient = useQueryClient();
  return useMutation(createStory, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['stories'] });
    },
    onError: () => {},
  });
};

export const useUpdateStory = () => {
  const queryClient = useQueryClient();
  return useMutation(updateStory, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['stories'] });
    },
    onError: () => {},
  });
};

export const useDeleteStory = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteStory, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['stories'] });
    },
    onError: () => {},
  });
};
