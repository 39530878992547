import { Box, Table, TableContainer, TableRow } from '@mui/material';
import { styled } from '@mui/system';

export const StyledBox = styled(Box)({
  width: '100%',
  overflow: 'auto',
});

export const StyledTableContainer = styled(TableContainer)({
  border: '1px solid rgba(224, 224, 224, 1)',
  borderRadius: '0.5rem',
  maxHeight: 'calc(100vh - 21rem)',
  minHeight: 'calc(100vh - 21rem)',
  '& .MuiTableSortLabel-root': {
    width: '100%',
  },
});

export const StyledTable = styled(Table)({
  '& .MuiTableCell-head': {
    '&:nth-of-type(1)': {
      width: '20%',
    },
    '&:nth-of-type(2)': {
      width: '70%',
    },
  },
});

export const StyledTableRow = styled(TableRow)({
  '& .mainInfo': {
    '& .meta': {
      display: 'flex',
      gap: '0.5rem',
      marginBottom: '0.375rem',
    },
    '& .name': {
      fontWeight: 500,
      fontSize: '1rem',
      lineHeight: '1.5rem',
      fontFamily: '"SF Pro Display", sans-serif',
      width: '22%',
      margin: 0,
    },
    '& .publishDate': {
      fontWeight: 400,
      fontSize: '0.75rem',
      lineHeight: '1.5rem',
      fontFamily: '"SF Pro Display", sans-serif',
      opacity: 0.5,
    },
    '& .comment': {
      fontWeight: 400,
      fontSize: '0.875rem',
      lineHeight: '1rem',
      fontFamily: '"SF Pro Display", sans-serif',
    },
  },
  '& .buttonCell': {
    width: '3.5rem',
  },
  '& .deleteIcon': {
    cursor: 'pointer',
    visibility: 'hidden',
  },
  '&:hover': {
    '& .deleteIcon': {
      visibility: 'visible',
    },
  },
});
