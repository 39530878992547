import {
  FullAnalyticsBody,
  FullAnalyticsHeader,
  FullAnalyticsLayout,
} from 'components';
import { FC } from 'react';

export const FullAnalytics: FC = () => {
  return (
    <FullAnalyticsLayout>
      <FullAnalyticsHeader />
      <FullAnalyticsBody />
    </FullAnalyticsLayout>
  );
};
