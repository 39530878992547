import { FC, useState } from 'react';
import { Button, StyledFlexBox } from 'UI';
import { COLORS } from 'utils';
import { DeleteIcon } from 'assets';
import { IShowcaseOfferDiscountReq } from 'types';
import { useDeleteShowcaseOfferDiscount } from 'hooks';
import { DeleteModal } from 'components';

interface IDrawerFooterProps {
  purpose: 'add' | 'edit';
  isLoading?: boolean;
  productInfo: IShowcaseOfferDiscountReq;
  saveChanges: () => void;
  ignoreChanges: () => void;
  onClose: () => void;
}

export const DrawerFooter: FC<IDrawerFooterProps> = ({
  purpose,
  isLoading,
  productInfo,
  saveChanges,
  ignoreChanges,
  onClose,
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const { mutateAsync: deleteProduct } = useDeleteShowcaseOfferDiscount();

  const openDeleteModal = () => {
    setShowDeleteModal(true);
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  };
  const handleDeleteProduct = () => {
    deleteProduct({ id: productInfo.id!, category_id: productInfo.category_id })
      .then(() => {
        closeDeleteModal();
        onClose();
      })
      .catch(() => {});
  };
  return (
    <>
      <DeleteModal
        open={showDeleteModal}
        onConfirm={handleDeleteProduct}
        onClose={closeDeleteModal}
        title='Удалить предложение'
        description='Вы действительно хотите удалить предложение?'
      />

      <StyledFlexBox className='buttons'>
        {purpose === 'edit' ? (
          <Button
            onClick={openDeleteModal}
            variant='contained'
            size='large'
            color='customGray'
            startIcon={<DeleteIcon color={COLORS.RED} />}
            sx={{
              color: COLORS.RED,
            }}
          >
            Удалить
          </Button>
        ) : (
          <Button
            onClick={ignoreChanges}
            variant='contained'
            size='large'
            color='customGray'
          >
            Отмена
          </Button>
        )}
        <Button
          variant='contained'
          size='large'
          color='primary'
          onClick={saveChanges}
          isLoading={isLoading}
        >
          Сохранить
        </Button>
      </StyledFlexBox>
    </>
  );
};
