import { FC } from 'react';
import { PlusIcon } from 'assets';
import { useSearchParams } from 'hooks';
import { PageHeader, Button, GlobalSearch } from 'UI';

export const ShowcasesHeader: FC = () => {
  const { setParam } = useSearchParams();

  return (
    <PageHeader title='Витрина'>
      <GlobalSearch size='medium' />
      <Button
        variant='contained'
        startIcon={<PlusIcon />}
        onClick={() => setParam('edit', '0')}
      >
        Добавить
      </Button>
    </PageHeader>
  );
};
