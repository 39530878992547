import { FC } from 'react';
import { COLORS } from 'utils';

interface ICheckIcon {
  color?: string;
  className?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
}

export const CheckIcon: FC<ICheckIcon> = ({
  className,
  color = COLORS.BLUE,
  onClick,
  style,
}) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
    onClick={onClick}
    style={style}
  >
    <path
      d='M5 13L9 17L19 7'
      stroke={color}
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
