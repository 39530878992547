import { useQuery, useMutation, useQueryClient } from 'react-query';
import {
  getSponsors,
  getSponsor,
  createSponsor,
  updateSponsor,
  deleteSponsor,
} from 'api';
import { ISponsorRes, ISponsorGetParams, ISponsor } from 'types';

export const useGetSponsors = (params: ISponsorGetParams) => {
  const { marathon_id, space_id } = params;
  return useQuery<ISponsorRes, unknown>(
    ['sponsors', { marathon_id, space_id }],
    async () => {
      const res = await getSponsors(params);
      return res;
    },
    {
      staleTime: Infinity,
      retry: false,
    },
  );
};

export const useGetSponsor = (sponsorId: string) => {
  if (!sponsorId)
    return useQuery(['sponsors', 'sponsorId', sponsorId], () => {
      return Promise.resolve(null);
    });
  return useQuery<ISponsor>(
    ['sponsors', 'sponsorId', sponsorId],
    async () => {
      const res = await getSponsor(sponsorId);
      return res;
    },
    {
      staleTime: Infinity,
      retry: false,
    },
  );
};

export const useCreateSponsor = () => {
  const queryClient = useQueryClient();
  return useMutation(createSponsor, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['sponsors'] });
      queryClient.invalidateQueries({ queryKey: ['marathons'] });
    },
    onError: () => {},
  });
};

export const useUpdateSponsor = () => {
  const queryClient = useQueryClient();
  return useMutation(updateSponsor, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['sponsors'] });
      queryClient.invalidateQueries({ queryKey: ['marathons'] });
    },
    onError: () => {},
  });
};

export const useDeleteSponsor = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteSponsor, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['sponsors'] });
      queryClient.invalidateQueries({ queryKey: ['marathons'] });
    },
    onError: () => {},
  });
};
