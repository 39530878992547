import { FC, MouseEvent } from 'react';
import { TableHead, TableRow, TableCell, TableSortLabel } from '@mui/material';
import {
  INewsShort,
  IHeadCell,
  NewsSortParams,
  ITableHeadPropsWithSort,
} from 'types';
import { COLORS, NewsSortColumnValues } from 'utils';

const headCells: readonly IHeadCell<INewsShort>[] = [
  {
    id: 'name',
    label: 'Название',
    sortable: true,
  },
  {
    id: 'created_at',
    label: 'Дата создания',
    sortable: true,
  },
  {
    id: 'category',
    label: 'Категория',
    sortable: false,
  },
  {
    id: 'views_count',
    label: 'Просмотры',
    sortable: true,
  },
  {
    id: 'comments_count',
    label: 'Комментарии',
    sortable: true,
  },
  {
    id: 'published_at',
    label: 'Статус',
    sortable: true,
  },
  {
    id: 'actions',
    label: 'Управление',
    sortable: false,
  },
];

export const NewsTableHead: FC<ITableHeadPropsWithSort<NewsSortParams>> = ({
  onRequestSort,
  order,
  orderBy,
}) => {
  const isValidSortParam = (param: string): param is NewsSortParams => {
    return NewsSortColumnValues.includes(param as NewsSortParams);
  };

  const createSortHandler =
    (property: keyof INewsShort | 'actions') =>
    (event: MouseEvent<unknown>) => {
      if (isValidSortParam(property)) {
        onRequestSort(event, property);
      }
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => {
          if (!headCell.sortable)
            return (
              <TableCell key={headCell.id} padding='normal'>
                {headCell.label}
              </TableCell>
            );
          return (
            <TableCell
              key={headCell.id}
              padding='normal'
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
                sx={{
                  '&.Mui-active': {
                    '& .MuiTableSortLabel-icon': {
                      color: orderBy === headCell.id ? COLORS.RED : undefined,
                    },
                  },
                  '& .MuiTableSortLabel-icon': {
                    opacity: 0.3,
                  },
                  fontSize: '0.75rem',
                  lineHeight: '1.125rem',
                }}
              >
                {headCell.label}
              </TableSortLabel>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};
