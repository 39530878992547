import { IAnalyticsCommonGetParams, IAnalyticsNewsGraphGetParams } from 'types';

import { methods, http } from '../config';

export const getAnalyticsNewsData = (params: IAnalyticsCommonGetParams) => {
  return http({
    method: methods.GET,
    url: '/statistics/news/data',
    params,
  });
};

export const getAnalyticsNewsGraph = (params: IAnalyticsNewsGraphGetParams) => {
  return http({
    method: methods.GET,
    url: '/statistics/news/stats',
    params,
  });
};

export const getAnalyticsNewsDownloadFile = (
  params: IAnalyticsCommonGetParams,
) => {
  return http({
    method: methods.GET,
    url: '/statistics/news/get_xls',
    params,
  });
};
