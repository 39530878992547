import {
  IAnalyticsWorkoutsTopCardPeriodData,
  IAnalyticsChallengesRunsCard,
  IAnalyticsChallengesAcceptsCard,
} from 'types';

export const DEFAULT_CARD = {
  current_data: 0,
  difference: 0,
  percent: 0,
};

export const DEFAULT_GENDER_CARD = {
  gender: '',
  value: 0,
  percent: 0,
};

export const DEFAULT_WORKOUTS_CARD: IAnalyticsWorkoutsTopCardPeriodData = {
  total_count: 0,
  success_count: 0,
  percent: 0,
};
export const DEFAULT_CHALLENGES_RUN_CARD: IAnalyticsChallengesRunsCard = {
  total_count: 0,
  active_count: 0,
  current_run_count: 0,
  current_end_count: 0,
  prev_run_count: 0,
  prev_end_count: 0,
};

export const DEFAULT_CHALLENGES_ACCEPT_CARD: IAnalyticsChallengesAcceptsCard = {
  current_accept_count: 0,
  prev_accept_count: 0,
};
