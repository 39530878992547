import {
  NewsSortParams,
  TeamSortParams,
  OfferAndDiscountsSortParams,
  ShopProductSortParams,
  AchievementSortParams,
  RegionSortParams,
  InstructionSortParams,
  MeAndGtoSortParams,
  QASortParams,
  WorkerSortParams,
  UserSortParams,
  WorkoutSortParams,
  BannerSortParams,
  NewsCommentSortParams,
  ShowcaseCategorySortParams,
} from 'types';

export const NewsSortColumnValues: Array<NewsSortParams> = [
  'name',
  'published_at',
  'views_count',
  'created_at',
];

export const TeamsSortColumnValues: Array<TeamSortParams> = [
  'members_count',
  'steps_count',
  'kcal_count',
  'distance_count',
  'created_at',
];

export const OfferAndDiscountsSortColumnValues: Array<OfferAndDiscountsSortParams> =
  ['title', 'position_in_list', 'created_at'];

export const ShopProductSortColumnValues: Array<ShopProductSortParams> = [
  'name',
  'sku',
  'price',
  'amount',
  'created_at',
];

export const AchievementsSortColumnValues: Array<AchievementSortParams> = [
  'give_count',
  'share_count',
  'needle_value',
  'name',
  'created_at',
];

export const RegionsSortColumnValues: Array<RegionSortParams> = [
  'id',
  'name',
  'created_at',
];

export const InstructionsSortColumnValues: Array<InstructionSortParams> = [
  'title',
];

export const MeAndGtoSortColumnValues: Array<MeAndGtoSortParams> = ['title'];

export const QASortColumnValues: Array<QASortParams> = ['question'];

export const WorkersSortColumnValues: Array<WorkerSortParams> = [
  'firstname',
  'last_activity_at',
  'created_at',
];

export const UsersSortColumnValues: Array<UserSortParams> = [
  'firstname',
  'created_at',
];

export const WorkoutsSortColumnValues: Array<WorkoutSortParams> = [
  'position',
  'name',
  'created_at',
];

export const BannerSortColumnValues: Array<BannerSortParams> = [
  'title',
  'position',
  'created_at',
];

export const NewsCommentSortColumnValues: Array<NewsCommentSortParams> = [
  'created_at',
  'author',
];

export const ShowcaseCategorySortColumnValues: Array<ShowcaseCategorySortParams> =
  ['title', 'position'];
