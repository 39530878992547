import { FC, useState } from 'react';
import { HexColorPicker } from 'react-colorful';
import { styled } from '@mui/system';
import { Box } from '@mui/material';
import { COLORS } from 'utils';
import { Button } from 'UI';

const StyledContainer = styled(Box)({
  padding: '1rem',
  borderRadius: '1rem',
  display: 'flex',
  flexDirection: 'column',
  gap: '0.625rem',
  '& .value': {
    display: 'flex',
    gap: '0.5rem',
    alignItems: 'center',
    '& > span': {
      color: COLORS.BLACK,
      fontWeight: 500,
      fontFamily: '"SF Pro Display", sans-serif',
      fontSize: '1rem',
      lineHeight: '1.5rem',
    },
    '& > span:first-of-type': {
      fontWeight: 600,
    },
  },
});

interface IColorPickerProps {
  color: string;
  saveColor: (_color: string) => void;
}

export const ColorPicker: FC<IColorPickerProps> = ({ color, saveColor }) => {
  const [pickedColor, setPickedColor] = useState<string>(color);
  return (
    <StyledContainer>
      <HexColorPicker color={pickedColor} onChange={setPickedColor} />
      <Box className='value'>
        <span>HEX</span>
        <span>{pickedColor}</span>
      </Box>
      <Button
        variant='contained'
        size='large'
        color='primary'
        onClick={() => saveColor(pickedColor)}
      >
        Сохранить
      </Button>
    </StyledContainer>
  );
};
