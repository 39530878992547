import { IAchievementReq, IAchievementGetParams } from 'types';
import { http, methods } from './config';

export const getAchievements = (params: IAchievementGetParams) => {
  const config = {
    method: methods.get,
    url: `/achievement`,
    params,
  };
  return http(config);
};

export const getAchievement = (achievementId: string | number) => {
  const config = {
    method: methods.get,
    url: `/achievement/${achievementId}`,
  };
  return http(config);
};

export const createAchievement = (data: IAchievementReq) => {
  const config = {
    method: methods.post,
    url: `/achievement`,
    data,
  };
  return http(config);
};

export const updateAchievement = (data: IAchievementReq) => {
  const config = {
    method: methods.put,
    url: `/achievement/${data.id}`,
    data,
  };
  return http(config);
};

export const deleteAchievement = (achievementId: number | string) => {
  const config = {
    method: methods.delete,
    url: `/achievement/${achievementId}`,
  };
  return http(config);
};
