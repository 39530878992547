import { FC, CSSProperties } from 'react';
import { COLORS } from 'utils';

interface IAttachIcon {
  color?: string;
  className?: string;
  style?: CSSProperties;
  onClick?: () => void;
}

export const AttachIcon: FC<IAttachIcon> = ({
  color = COLORS.BLUE,
  className,
  style,
  onClick,
}) => {
  return (
    <svg
      width='25'
      height='25'
      viewBox='0 0 25 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      onClick={onClick}
      style={style}
    >
      <g clipPath='url(#clip0_4658_81731)'>
        <path
          d='M21.6766 12.162L12.4866 21.352C11.3608 22.4778 9.8338 23.1103 8.24162 23.1103C6.64943 23.1103 5.12246 22.4778 3.99662 21.352C2.87077 20.2261 2.23828 18.6992 2.23828 17.107C2.23828 15.5148 2.87077 13.9878 3.99662 12.862L13.1866 3.67198C13.9372 2.92142 14.9552 2.49976 16.0166 2.49976C17.0781 2.49976 18.0961 2.92142 18.8466 3.67198C19.5972 4.42254 20.0188 5.44052 20.0188 6.50198C20.0188 7.56344 19.5972 8.58142 18.8466 9.33198L9.64662 18.522C9.4608 18.7078 9.2402 18.8552 8.99741 18.9558C8.75462 19.0563 8.49441 19.1081 8.23162 19.1081C7.96883 19.1081 7.70861 19.0563 7.46583 18.9558C7.22304 18.8552 7.00244 18.7078 6.81662 18.522C6.6308 18.3362 6.4834 18.1156 6.38283 17.8728C6.28227 17.63 6.23051 17.3698 6.23051 17.107C6.23051 16.8442 6.28227 16.584 6.38283 16.3412C6.4834 16.0984 6.6308 15.8778 6.81662 15.692L15.3066 7.21198'
          stroke={color}
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_4658_81731'>
          <rect
            width='24'
            height='24'
            fill='white'
            transform='translate(0.238281 0.5)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};
