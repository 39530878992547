import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { COLORS } from 'utils';

export const StyledPlaceItem = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '1.25rem',
  border: `1px solid ${COLORS.BLACK}`,
  borderRadius: '0.5rem',
  padding: '1.25rem',
});

export const StyledHeader = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  '& .title': {
    fontSize: '1.125rem',
    fontWeight: 600,
    lineHeight: '1.625rem',
    fontFamily: '"SF Pro Display", sans-serif',
  },
  '& .iconButtons': {
    display: 'flex',
    alignItems: 'center',
    gap: 0,
  },
});

export const StyledPrizesContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
});

export const StyledPrizeItem = styled(Box)({
  display: 'flex',
  padding: '0.75rem',
  gap: '1rem',
  alignItems: 'center',
  backgroundColor: COLORS.WHITE,
  fontSize: '0.875rem',
  lineHeight: '1.5rem',
  fontFamily: '"SF Pro Display", sans-serif',
  '& .image': {
    minWidth: '2.75rem',
    height: '2.75rem',
    borderRadius: '0.25rem',
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
  },
  '& .title': {
    fontWeight: 600,
    width: 'calc((100% - 6.5rem) * 0.7)',
  },
  '& .info': {
    fontWeight: 500,
    opacity: 0.5,
    width: 'calc((100% - 6.5rem) * 0.3)',
  },
  '& .editDeleteIcons': {
    visibility: 'hidden',
    display: 'flex',
    alignItems: 'center',
  },
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    '& .editDeleteIcons': {
      visibility: 'visible',
    },
  },
});

export const StyledButtonsContainer = styled(Box)({
  display: 'flex',
  gap: '0.625rem',
  alignItems: 'center',
  '& button': {
    width: '100%',
  },
});

export const StyledPopoverRewardContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '0.9375rem',
  padding: '1.25rem',
  backgroundColor: COLORS.WHITE,
  border: `1px solid rgba(0, 0, 0, 0.1)`,
  borderRadius: '0.5rem',
  '& .title': {
    fontSize: '1.125rem',
    fontWeight: 600,
    lineHeight: '1.625rem',
    fontFamily: '"SF Pro Display", sans-serif',
  },
  '& .body': {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.625rem',
    '& .subTitleBox': {
      display: 'flex',
      alignItems: 'center',
      gap: '0.625rem',
    },
    '& .subtitle': {
      fontSize: '1rem',
      fontWeight: 500,
      lineHeight: '1.5rem',
      fontFamily: '"SF Pro Display", sans-serif',
      opacity: 0.5,
    },
    '& .label': {
      borderRadius: '0.25rem',
      padding: '0.4375rem 0.625rem',
      backgroundColor: 'rgba(0, 0, 0, 0.05)',
      display: 'flex',
      alignItems: 'center',
      gap: '0.625rem',
      fontSize: '1rem',
      lineHeight: '1.5rem',
      fontFamily: '"SF Pro Display", sans-serif',
      fontWeight: 600,
      width: '7.5rem',
    },
    '& .MuiFormControl-root': {
      width: '6.625rem',
    },
  },
  '& .footer': {
    width: '100%',
    '& button': {
      width: '100%',
    },
  },
});

export const StyledPopoverAchievementContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  minWidth: '16rem',
  '& .item': {
    cursor: 'pointer',
    padding: '0.5rem 0.75rem',
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    borderTop: `1px solid rgba(0, 0, 0, 0.1)`,
    transition: 'all 0.3s ease',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.05)',
    },
    '& .imageBox': {
      minWidth: '2.25rem',
      minHeight: '2.25rem',
      width: '2.25rem',
      height: '2.25rem',
      '& img': {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
      },
    },
    '& .name': {
      fontSize: '0.875rem',
      fontWeight: 400,
      lineHeight: '1.5rem',
      fontFamily: '"SF Pro Display", sans-serif',
    },
  },
  '& .buttonBox': {
    '& button': {
      width: '100%',
      display: 'flex',
      padding: '0.625rem 0.75rem',
    },
  },
});
