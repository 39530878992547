import { FC } from 'react';
import { COLORS } from 'utils';

interface ITagDeleteIconProps {
  color?: string;
  onClick?: () => void;
}

export const TagDeleteIcon: FC<ITagDeleteIconProps> = ({
  color = COLORS.WHITE,
  onClick,
}) => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className='MuiChip-deleteIcon'
      onClick={onClick}
    >
      <path
        d='M16 8L8 16M16 16L8 8'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
      />
    </svg>
  );
};
