import { FC, MouseEvent } from 'react';
import { TableHead, TableRow, TableCell, TableSortLabel } from '@mui/material';
import { COLORS } from 'utils';
import { IShopAttribute, IHeadCell, ITableHeadProps } from 'types';

const headCells: readonly IHeadCell<IShopAttribute>[] = [
  {
    id: 'id',
    label: '№',
    sortable: true,
  },
  {
    id: 'name',
    label: 'Название',
    sortable: true,
  },
  {
    id: 'actions',
    label: 'Управление',
    sortable: false,
  },
];

export const AttributesTableHead: FC<ITableHeadProps<IShopAttribute>> = ({
  onRequestSort,
  order,
  orderBy,
}) => {
  const createSortHandler =
    (property: keyof IShopAttribute | 'actions') =>
    (event: MouseEvent<unknown>) => {
      if (property === 'actions') return;
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => {
          if (!headCell.sortable) {
            return (
              <TableCell key={headCell.id} align='center' padding='normal'>
                {headCell.label}
              </TableCell>
            );
          }

          return (
            <TableCell
              key={headCell.id}
              align='center'
              padding='normal'
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
                sx={{
                  '&.Mui-active': {
                    '& .MuiTableSortLabel-icon': {
                      color: orderBy === headCell.id ? COLORS.RED : undefined,
                    },
                  },
                  '& .MuiTableSortLabel-icon': {
                    opacity: 0.3,
                  },
                }}
              >
                {headCell.label}
              </TableSortLabel>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};
