import { Box } from '@mui/material';
import { styled } from '@mui/system';

import { COLORS } from 'utils';

const MENU_HEIGHT = `calc(100% - 8rem)`;

export const StyledSidebarBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  position: 'relative',
  height: '100vh',
  width: '20rem',
  background: COLORS.WHITE,
  padding: '2rem 0.5rem',
});

export const StyledMenuBox = styled(Box)`
  height: ${MENU_HEIGHT};
  overflow: auto;
`;

export const StyledLogoutBox = styled(Box)({
  paddingTop: '2rem',
  width: '100%',
  height: '4rem',
  display: 'flex',
  alignItems: 'end',
  '& > button': {
    width: '100%',
    border: `1px solid rgba(0,0,0, 0.5)`,
  },
});
