import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { COLORS } from 'utils';

export const StyledContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '0.9375rem',
  padding: '1.25rem',
  border: `1px solid ${COLORS.BLACK}`,
  borderRadius: '0.5rem',
});

export const StyledDayHeader = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  fontFamily: '"SF Pro Display", sans-serif',
  '& .title': {
    fontSize: '1.125rem',
    fontWeight: 600,
    lineHeight: '1.625rem',
  },
  '& .iconButtons': {
    display: 'flex',
    alignItems: 'center',
  },
});

export const StyledDayFooter = styled(Box)({
  display: 'flex',
  gap: '0.625rem',
  alignItems: 'center',
  '& > div': {
    width: '100%',
  },
});
