import { useQuery, useMutation, useQueryClient } from 'react-query';
import { ITeamFull, ITeamGetParams, ITeamsRes } from 'types';
import { getTeams, getTeam, createTeam, updateTeam, deleteTeam } from 'api';

export const useGetTeams = (params: ITeamGetParams) => {
  const { page, search, space_id, column, sort_type } = params;
  return useQuery<ITeamsRes, unknown>(
    ['teams', { page, search, space_id, column, sort_type }],
    async () => {
      const res = await getTeams(params);
      return res;
    },
    {
      staleTime: Infinity,
      retry: false,
    },
  );
};

export const useGetTeam = (teamId: string | number) => {
  if (!teamId)
    return useQuery(['teams', 'teamId', teamId], () => {
      return Promise.resolve(null);
    });
  return useQuery<ITeamFull>(
    ['teams', 'teamId', teamId],
    async () => {
      const res = await getTeam(teamId);
      return res;
    },
    {
      staleTime: 1000 * 60 * 10, // 10 minutes
      retry: false,
    },
  );
};

export const useCreateTeam = () => {
  const queryClient = useQueryClient();
  return useMutation(createTeam, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['teams'] });
    },
    onError: () => {},
  });
};

export const useUpdateTeam = () => {
  const queryClient = useQueryClient();
  return useMutation(updateTeam, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['teams'] });
    },
    onError: () => {},
  });
};

export const useDeleteTeam = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteTeam, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['teams'] });
    },
    onError: () => {},
  });
};
