import { styled } from '@mui/system';
import { Box } from '@mui/material';
import { COLORS } from 'utils';

export const StyledSponsorsContainer = styled(Box)({
  padding: '1.25rem',
  borderRadius: '0.5rem',
  border: '1px solid rgba(0,0,0,0.1)',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: COLORS.WHITE,
  maxHeight: 'calc(100vh - 11rem)',
  '& .addButton': {
    width: '100%',
    margin: '1.25rem 0 0',
  },
});

export const StyledSponsor = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '0.75rem 1rem',
  borderRadius: '0.25rem',
  borderBottom: '1px solid rgba(0,0,0,0.1)',
  '&:hover': {
    backgroundColor: 'rgba(0,0,0,0.05)',
    '& .editDeleteIcons': {
      visibility: 'visible',
    },
  },
  '& .photo': {
    minWidth: '2.75rem',
    height: '2.75rem',
    borderRadius: '50%',
    overflow: 'hidden',
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
  },
  '& .name': {},
  '& .editDeleteIcons': {
    visibility: 'hidden',
    display: 'flex',
    gap: '0.625rem',
  },
});
