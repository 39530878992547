import { styled } from '@mui/system';
import { Box } from '@mui/material';
import { COLORS } from 'utils';

export const CardContainer = styled(Box)({
  width: '100%',
  padding: '1.875rem',
  display: 'flex',
  flexDirection: 'column',
  gap: '0.9375rem',
  borderRadius: '0.5rem',
  backgroundColor: COLORS.WHITE,
});

export const CardHeader = styled(Box)({
  display: 'flex',
  gap: '0.625rem',
  alignItems: 'center',
  '& .imageBox': {
    minWidth: '3.75rem',
    maxWidth: '3.75rem',
    height: '3.75rem',
    borderRadius: '50%',
    overflow: 'hidden',
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
  },
  '& .infoBox': {
    display: 'flex',
    flexDirection: 'column',
    color: COLORS.BLACK,
    '& h2': {
      fontSize: '1.25rem',
      fontWeight: 600,
      lineHeight: '1.75rem',
      fontFamily: '"SF Pro Display", sans-serif',
    },
    '& span': {
      fontSize: '0.75rem',
      fontWeight: 400,
      lineHeight: '1.125rem',
      fontFamily: '"SF Pro Display", sans-serif',
      opacity: 0.5,
    },
  },
});

export const CardBody = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  '& .buttons': {
    display: 'flex',
    gap: '0.625rem',
    '& > svg': {
      cursor: 'pointer',
    },
  },
  '& .switch': {
    marginTop: '-0.5rem',
  },
});
