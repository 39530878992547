import { styled } from '@mui/system';
import { Box } from '@mui/material';
import { COLORS } from 'utils';

export const StyledContainer = styled(Box)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '20rem',
  height: '42.5rem',
});

export const StyledPhoneWrapper = styled(Box)({
  width: '20rem',
  height: '42.5rem',
  borderRadius: '1.25rem',
  border: `11px solid ${COLORS.BLACK}`,
});

export const StyledPhoneContent = styled(Box)({
  borderRadius: '0.25rem',
  overflow: 'hidden',
  width: '100%',
  height: '100%',
  position: 'relative',
  backgroundColor: 'rgba(0, 0, 0, 0.6)',
  '& .statusBar': {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
  },
  '& .blocks': {
    position: 'absolute',
    top: '2.5rem',
    left: 0,
    width: '100%',
    display: 'flex',
    gap: '0.5rem',
    justifyContent: 'space-between',
    padding: '0 0.75rem',
    '& .block': {
      width: '100%',
      height: '0.25rem',
      borderRadius: '1.25rem',
      backgroundColor: 'rgba(255, 255, 255, 0.2)',
      cursor: 'pointer',
      zIndex: 2,
    },
  },
  '& .closeIcon': {
    position: 'absolute',
    top: '4rem',
    right: '0.75rem',
    width: '2rem',
    height: '2rem',
    borderRadius: '50%',
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  '& .media': {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
    '& video': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
  },
  '& .buttonBox': {
    position: 'absolute',
    bottom: '2.5rem',
    padding: '0 1.25rem',
    width: '100%',
    '& button': {
      width: '100%',
      fontSize: '0.75rem',
      height: '2.5rem',
      cursor: 'default',
      '&:hover': {
        backgroundColor: COLORS.RED_2,
      },
    },
  },
  '& .homeIndicator': {
    position: 'absolute',
    bottom: '0.5rem',
    left: '50%',
    transform: 'translateX(-50%)',
    height: '0.375rem',
    backgroundColor: COLORS.WHITE,
    width: '6.5rem',
    borderRadius: '0.5rem',
  },
});
