import { FC } from 'react';

import { useDrawer } from 'hooks';
import {
  AddEditStoryDrawer,
  Layout,
  StoriesHeader,
  StoriesTable,
} from 'components';

export const Stories: FC = () => {
  const {
    openAddDrawer,
    showAddEditDrawer,
    hideAddEditDrawer,
    editDrawerId,
    purpose,
  } = useDrawer();

  return (
    <Layout withoutPB>
      <AddEditStoryDrawer
        open={showAddEditDrawer}
        onClose={hideAddEditDrawer}
        purpose={purpose}
        id={editDrawerId}
      />

      <StoriesHeader openAddStoryModal={openAddDrawer} />
      <StoriesTable />
    </Layout>
  );
};
