import { FC, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSearchParams } from 'hooks';
import { Button, PageHeader, GlobalSearch } from 'UI';
import { PlusIcon } from 'assets';
import { ROUTES } from 'app';

import { AttributesFilter } from './AttributesFilter';

export const ShopHeader: FC = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { setParam } = useSearchParams();

  const showAttributeFilter = useMemo(() => {
    return pathname === ROUTES.shop.attributes;
  }, [pathname]);

  const createButtonHandler = () => {
    if (pathname === ROUTES.shop.products) {
      navigate(`${ROUTES.shop.createProduct}?id=0&category_id=0`);
      return;
    }
    setParam('edit', '0');
  };

  return (
    <PageHeader title='Магазин'>
      {showAttributeFilter && <AttributesFilter />}
      <GlobalSearch />
      <Button
        variant='contained'
        startIcon={<PlusIcon />}
        onClick={() => createButtonHandler()}
      >
        Создать
      </Button>
    </PageHeader>
  );
};
