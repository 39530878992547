export const NewsIcon = () => {
  return (
    <svg
      width='30'
      height='30'
      viewBox='0 0 30 30'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M21.5 8.7525H27V22.75C27 24.2688 25.7688 25.5 24.25 25.5V25.5C22.7312 25.5 21.5 24.2688 21.5 22.75V3.75H2.25V20.25C2.25 23.1495 4.6005 25.5 7.5 25.5H18.0625H24.9375'
        stroke='#0080FF'
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.375 9.1875H17.375'
        stroke='#0080FF'
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.375 14.625H17.375'
        stroke='#0080FF'
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.375 20.0625H17.375'
        stroke='#0080FF'
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
