import { FC, useEffect, useState, useMemo } from 'react';
import { styled } from '@mui/system';
import { TextField, FormControlLabel } from '@mui/material';
import {
  Drawer,
  ModalTitle,
  StyledSwitch,
  StyledBadge,
  Button,
  SearchSelectInput,
  DrawerHeader,
  FormItem,
  DrawerBody,
  DrawerFormBody,
  StyledFlexBox,
  ImageInput,
  FullColorPicker,
} from 'UI';
import { AddUserToGroup, DeleteModal } from 'components';
import { CloseIcon, DeleteIcon } from 'assets';
import { IGroupReq } from 'types';
import { COLORS, MEDIA_TYPES, notify } from 'utils';
import {
  useCreateGroup,
  useGetSpacesList,
  useUpdateGroup,
  useDeleteGroup,
  useGetGroup,
} from 'hooks';

export const StyledSwitchFormControl = styled(FormControlLabel)({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  borderBottom: `1px solid rgba(0,0,0,0.42)`,
  margin: 0,
  paddingBottom: '0.375rem',
  '& .MuiFormControlLabel-label': {
    marginTop: '0.375rem',
    fontSize: '1rem',
    lineHeight: '1.5rem',
    opacity: 0.5,
  },
});

const TRANSLATIONS = {
  add: {
    title: 'Создание группы',
  },
  edit: {
    title: 'Редактировать группу',
  },
};

const DEFAULT_VALUES = {
  name: '',
  logo: '',
  cover: '',
  color: '',
  description: '',
  is_active: true,
  space_id: 0,
  users: [],
  defaultUsers: [],
  is_public: false,
};

interface IAddEditGroupDrawerProps {
  open: boolean;
  onClose: () => void;
  purpose?: 'add' | 'edit';
  id?: string | number;
}

export const AddEditGroupDrawer: FC<IAddEditGroupDrawerProps> = ({
  open,
  onClose,
  purpose = 'add',
  id = '',
}) => {
  const { data: groupInfoData } = useGetGroup(id);
  const [groupInfo, setGroupInfo] = useState<IGroupReq>(DEFAULT_VALUES);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const { data: spacesList } = useGetSpacesList();
  const { mutateAsync: createGroup } = useCreateGroup();
  const { mutateAsync: updateGroup } = useUpdateGroup();
  const { mutateAsync: deleteGroup } = useDeleteGroup();

  const options = useMemo(() => {
    return (
      spacesList?.map(space => ({
        label: space.name,
        value: space.id,
      })) || []
    );
  }, [spacesList]);

  useEffect(() => {
    if (purpose === 'edit') {
      setGroupInfo({
        id: groupInfoData?.id,
        name: groupInfoData?.name || '',
        logo: groupInfoData?.logo || '',
        cover: groupInfoData?.cover || '',
        color: groupInfoData?.color || '',
        description: groupInfoData?.description || '',
        is_active: !!groupInfoData?.is_active,
        space_id: groupInfoData?.space?.id || 0,
        users: groupInfoData?.users?.map(user => user.id) || [],
        defaultUsers: groupInfoData?.users || [],
        is_public: groupInfoData?.is_public || false,
      });
    } else {
      setGroupInfo(DEFAULT_VALUES);
    }
  }, [purpose, groupInfoData]);

  const handleFieldChange = <T extends keyof IGroupReq>(
    fieldName: T,
    newValue: IGroupReq[T],
  ) => {
    setGroupInfo(prevGroupInfo => ({
      ...prevGroupInfo,
      [fieldName]: newValue,
    }));
  };

  const saveChanges = () => {
    if (!groupInfo.cover && !groupInfo.color) {
      notify('Пожалуйста, добавьте обложку или цвет', 'error');
      return;
    }
    if (purpose === 'add') {
      createGroup(groupInfo)
        .then(() => {
          onClose();
        })
        .catch(() => {});
    } else {
      updateGroup(groupInfo)
        .then(() => {
          onClose();
        })
        .catch(() => {});
    }
  };

  const openDeleteModal = () => {
    setShowDeleteModal(true);
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const handleDeleteGroup = () => {
    deleteGroup(groupInfo.id!)
      .then(() => {
        closeDeleteModal();
        onClose();
      })
      .catch(() => {});
  };

  const ignoreChanges = () => {
    setGroupInfo(DEFAULT_VALUES);
    onClose();
  };

  const changeUsers = (newUsers: number[]) => {
    handleFieldChange('users', newUsers);
  };

  return (
    <Drawer open={open} onClose={ignoreChanges}>
      <DeleteModal
        open={showDeleteModal}
        onConfirm={handleDeleteGroup}
        onClose={closeDeleteModal}
        title='Удалить группу'
        description='Вы действительно хотите удалить группу'
      />

      <DrawerHeader>
        <StyledFlexBox>
          <ModalTitle title={TRANSLATIONS[purpose].title} />
          {purpose === 'edit' && (
            <StyledBadge
              is_active={groupInfo.is_active}
              style={{ marginBottom: 0 }}
              variant='secondary'
            />
          )}
        </StyledFlexBox>
        <StyledFlexBox>
          <StyledSwitch
            checked={groupInfo.is_active}
            onChange={() =>
              handleFieldChange('is_active', !groupInfo.is_active)
            }
            className='switch'
          />
          <CloseIcon onClick={ignoreChanges} />
        </StyledFlexBox>
      </DrawerHeader>
      <DrawerBody>
        <DrawerFormBody>
          <StyledFlexBox>
            <ImageInput
              inputId='groupLogo'
              title='Добавьте логотип'
              descriptionWithoutImage='Загрузите изображение (высота 512px, до 1 мб)'
              withDescription
              image={groupInfo.logo}
              setImage={newImg => handleFieldChange('logo', newImg)}
              mediaType={MEDIA_TYPES.GROUP_LOGO}
              imageFixedWidth={-1}
              imageFixedHeight={512}
            />
            <ImageInput
              inputId='groupCover'
              title='Добавьте обложку'
              descriptionWithoutImage='Загрузите изображение (540x740 px, до 1 мб)'
              withDescription
              image={groupInfo.cover || ''}
              setImage={newImg => handleFieldChange('cover', newImg)}
              mediaType={MEDIA_TYPES.GROUP_COVER}
              imageFixedWidth={540}
              imageFixedHeight={740}
              disabled={!!groupInfo.color}
            />
          </StyledFlexBox>
          <h2>О группе</h2>
          <FormItem label='Название группы'>
            <TextField
              variant='standard'
              color='primary'
              placeholder='Введите название'
              name='name'
              value={groupInfo.name}
              onChange={e => handleFieldChange('name', e.target.value)}
            />
          </FormItem>
          <FormItem label='Описание группы'>
            <TextField
              variant='standard'
              color='primary'
              placeholder='Введите короткий текст'
              name='description'
              value={groupInfo.description}
              onChange={e => handleFieldChange('description', e.target.value)}
            />
          </FormItem>
          <FullColorPicker
            changeColor={newColor => handleFieldChange('color', newColor)}
            color={groupInfo.color || ''}
            disabled={!!groupInfo.cover}
          />
          <StyledSwitchFormControl
            label='Общедоступная группа'
            labelPlacement='start'
            control={
              <StyledSwitch
                checked={groupInfo.is_public}
                onChange={() =>
                  handleFieldChange('is_public', !groupInfo.is_public)
                }
              />
            }
          />
          <FormItem label='Пространство'>
            <SearchSelectInput
              options={options}
              value={groupInfo.space_id}
              onChange={newValue =>
                handleFieldChange('space_id', newValue as number)
              }
              placeholder='Выберите пространство'
              variant='standard'
            />
          </FormItem>
          <AddUserToGroup
            spaceId={groupInfo.space_id}
            users={groupInfo.defaultUsers}
            changeUsers={newIds => changeUsers(newIds)}
          />
        </DrawerFormBody>
        <StyledFlexBox className='buttons'>
          {purpose === 'edit' && (
            <Button
              onClick={openDeleteModal}
              variant='contained'
              size='large'
              color='customGray'
              startIcon={<DeleteIcon color={COLORS.RED} />}
              sx={{
                color: COLORS.RED,
              }}
            >
              Удалить
            </Button>
          )}
          {purpose === 'add' && (
            <Button
              onClick={ignoreChanges}
              variant='contained'
              size='large'
              color='customGray'
            >
              Отмена
            </Button>
          )}
          <Button
            variant='contained'
            size='large'
            color='primary'
            onClick={saveChanges}
          >
            Сохранить
          </Button>
        </StyledFlexBox>
      </DrawerBody>
    </Drawer>
  );
};
