import { FC, useMemo, useState, MouseEvent } from 'react';
import { TableBody, TableCell } from '@mui/material';

import { Pagination } from 'UI';
import { useGetCities, useSearchParams } from 'hooks';
import { TableRowsOrder, RegionSortParams } from 'types';

import {
  StyledBox,
  StyledPaper,
  StyledTableContainer,
  StyledTable,
  StyledTableRow,
} from './styledComponents';
import { RegionsTableHead } from './RegionsTableHead';

export const RegionsTable: FC = () => {
  const [order, setOrder] = useState<TableRowsOrder>('desc');
  const [orderBy, setOrderBy] = useState<RegionSortParams>('created_at');
  const { getParam, searchParams, setParam } = useSearchParams();

  const params = useMemo(() => {
    return {
      page: getParam('page'),
      search: getParam('search'),
      column: orderBy,
      sort_type: order,
    };
  }, [searchParams, order, orderBy]);

  const {
    data: { data: events, last_page: pageCount } = {
      data: [],
      last_page: 1,
    },
  } = useGetCities(params);

  const handleRequestSort = (
    _event: MouseEvent<unknown>,
    property: RegionSortParams,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const openEditDrawer = (id: number) => {
    setParam('edit', id.toString());
  };

  return (
    <StyledBox>
      <StyledPaper>
        <StyledTableContainer>
          <StyledTable
            aria-labelledby='achievementsTable'
            size='medium'
            stickyHeader
          >
            <RegionsTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {events.map(row => {
                return (
                  <StyledTableRow
                    hover
                    key={row.id}
                    onClick={() => openEditDrawer(row.id)}
                  >
                    <TableCell
                      component='th'
                      align='center'
                      id={row.id.toString()}
                      scope='row'
                    >
                      {row.id}
                    </TableCell>
                    <TableCell align='center'>
                      <span>{row.name}</span>
                    </TableCell>
                    <TableCell align='center'>
                      <span>{row.region.name}</span>
                    </TableCell>
                    <TableCell align='center'>
                      <span>{row.created_at}</span>
                    </TableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </StyledTable>
        </StyledTableContainer>
        <Pagination count={pageCount} />
      </StyledPaper>
    </StyledBox>
  );
};
