import { useQuery, useMutation, useQueryClient } from 'react-query';
import { IAchievement, IAchievementGetParams, IAchievementsRes } from 'types';
import {
  getAchievements,
  getAchievement,
  createAchievement,
  updateAchievement,
  deleteAchievement,
} from 'api';

export const useGetAchievements = (params: IAchievementGetParams) => {
  const { page, search, column, sort_type } = params;
  return useQuery<IAchievementsRes, unknown>(
    ['achievements', { page, search, column, sort_type }],
    async () => {
      const res = await getAchievements(params);
      return res;
    },
    {
      staleTime: Infinity,
      retry: false,
    },
  );
};

export const useGetAchievement = (achievementId: string | number) => {
  if (!achievementId)
    return useQuery(['achievements', 'achievementId', achievementId], () => {
      return Promise.resolve(null);
    });
  return useQuery<IAchievement>(
    ['achievements', 'achievementId', achievementId],
    async () => {
      const res = await getAchievement(achievementId);
      return res;
    },
    {
      staleTime: Infinity,
      retry: false,
    },
  );
};

export const useCreateAchievement = () => {
  const queryClient = useQueryClient();
  return useMutation(createAchievement, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['achievements'] });
    },
    onError: () => {},
  });
};

export const useUpdateAchievement = () => {
  const queryClient = useQueryClient();
  return useMutation(updateAchievement, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['achievements'] });
    },
    onError: () => {},
  });
};

export const useDeleteAchievement = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteAchievement, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['achievements'] });
    },
    onError: () => {},
  });
};
