import { FC, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import type { Identifier } from 'dnd-core';
import { TableCell } from '@mui/material';
import { StyledSwitch } from 'UI';
import { EditIcon, DeleteIcon } from 'assets';
import { IWorkout } from 'types';

import {
  StyledTableRow,
  StyledWorkoutInfo,
  StyledAvatar,
} from './styledComponents';

interface IWorkoutsTableRowProps {
  row: IWorkout;
  openEditDrawer: (_id: string | number) => void;
  deleteButtonHandler: (_row: IWorkout) => void;
  updateRow: (_row: IWorkout) => void;
  moveRow: (_dragIndex: number, _dropIndex: number) => void;
}

interface DragItem {
  index: number;
  id: string;
  type: string;
}

export const ItemTypes = {
  CARD: 'card',
};

export const WorkoutsTableRow: FC<IWorkoutsTableRowProps> = ({
  row,
  openEditDrawer,
  deleteButtonHandler,
  updateRow,
  moveRow,
}) => {
  const ref = useRef<any>(null);
  const [{ handlerId }, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null }
  >({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    drop(item) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const dropIndex = row.position;

      // Don't replace items with themselves
      if (dragIndex === dropIndex) {
        return;
      }

      // Determine rectangle on screen
      // const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      // const hoverMiddleY =
      // (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      // const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      // const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      // if (
      //   dragIndex < dropIndex &&
      //   hoverClientY < hoverMiddleY &&
      //   dragIndex + 1 !== dropIndex
      // ) {
      //   dropIndex -= 1;
      //   return;
      // }

      // Dragging upwards
      // if (
      //   dragIndex > dropIndex &&
      //   hoverClientY > hoverMiddleY &&
      //   dragIndex !== dropIndex + 1
      // ) {
      //   dropIndex += 1;
      //   return;
      // }

      // Time to actually perform the action
      moveRow(dragIndex, dropIndex);
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: () => {
      return { id: row.id, index: row.position };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));
  const opacity = isDragging ? 0.3 : 1;
  const cursor = isDragging ? 'grabbing' : 'grab';

  return (
    <StyledTableRow
      style={{ opacity, cursor }}
      hover
      key={row.id}
      ref={ref}
      data-handler-id={handlerId}
    >
      <TableCell
        component='th'
        id={row.id?.toString()}
        scope='row'
        style={{ paddingLeft: '3rem' }}
      >
        <span>{row.position}</span>
      </TableCell>
      <TableCell>
        <StyledWorkoutInfo onClick={() => openEditDrawer(row.id)}>
          <StyledAvatar>
            <img src={row.icon} alt={row.name} />
          </StyledAvatar>
          <div className='name'>{row.name}</div>
        </StyledWorkoutInfo>
      </TableCell>
      <TableCell>
        <span>{row.type.text}</span>
      </TableCell>
      <TableCell>
        <span>{row.is_only_indoor ? 'Зал' : 'Зал + Улица'}</span>
      </TableCell>
      <TableCell>
        <span>{row.created_at}</span>
      </TableCell>
      <TableCell>
        <div className='editDeleteIcons'>
          <StyledSwitch
            checked={row.is_active}
            variant='customGray'
            onChange={() => updateRow(row)}
            style={{ opacity: 1 }}
          />
          <EditIcon onClick={() => openEditDrawer(row.id)} />
          <DeleteIcon onClick={() => deleteButtonHandler(row)} />
        </div>
      </TableCell>
    </StyledTableRow>
  );
};
