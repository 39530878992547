import * as Pages from 'pages';
import { ROLES } from 'utils';
import { IRoutes } from '../types';

export const Challenges: IRoutes = {
  challenges: {
    path: '/challenges',
    title: 'challenges',
    element: <Pages.Challenges />,
    roles: [ROLES.ADMINSTRATOR],
  },
};
