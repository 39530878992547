import * as Pages from 'pages';
import { ROLES } from 'utils';
import { IRoutes } from '../types';

export const Partners: IRoutes = {
  partners: {
    path: '/partners',
    title: 'partners',
    element: <Pages.Partners />,
    roles: [ROLES.ADMINSTRATOR],
  },
};
