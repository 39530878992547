import { IShopAttributeReq, IPaginatedReqParams } from 'types';
import { http, methods } from '../config';

export const getShopAttributes = (params: IPaginatedReqParams) => {
  const config = {
    method: methods.get,
    url: `/shop/attribute`,
    params,
  };
  return http(config);
};

export const getShopAttribute = (id: string | number) => {
  const config = {
    method: methods.get,
    url: `/shop/attribute/${id}`,
  };
  return http(config);
};

export const createShopAttribute = (data: IShopAttributeReq) => {
  const config = {
    method: methods.post,
    url: `/shop/attribute`,
    data,
  };
  return http(config);
};

export const updateShopAttribute = (data: IShopAttributeReq) => {
  const config = {
    method: methods.put,
    url: `/shop/attribute/${data.id}`,
    data,
  };
  return http(config);
};

export const deleteShopAttribute = (id: number | string) => {
  const config = {
    method: methods.delete,
    url: `/shop/attribute/${id}`,
  };
  return http(config);
};

export const getShopAttributesList = () => {
  const config = {
    method: methods.get,
    url: `/list/product_attribute`,
  };
  return http(config);
};
