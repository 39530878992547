import { useQuery } from 'react-query';
import { getCheckboxLogs } from 'api';
import { ICheckboxLogsGetParams, ICheckboxLogsRes } from 'types';

export const useGetCheckboxLogs = (params: ICheckboxLogsGetParams) => {
  const { page, search } = params;
  return useQuery<ICheckboxLogsRes, unknown>(
    [
      'checkboxLogs',
      {
        page,
        search,
      },
    ],
    async () => {
      const res = await getCheckboxLogs(params);
      return res;
    },
    {
      staleTime: 10 * 60 * 1000,
      retry: false,
    },
  );
};
