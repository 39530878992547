import { Box, Paper, Table, TableContainer, TableRow } from '@mui/material';
import { styled } from '@mui/system';
import { COLORS } from 'utils';

export const StyledBox = styled(Box)({
  width: '100%',
  overflow: 'auto',
});

export const StyledPaper = styled(Paper)({
  width: '100%',
  marginBottom: 2,
});

export const StyledTableContainer = styled(TableContainer)({
  maxHeight: 'calc(100vh - 13.5rem)',
  minHeight: 'calc(100vh - 13.5rem)',
  '&.subFilterActive': {
    maxHeight: 'calc(100vh - 17rem)',
    minHeight: 'calc(100vh - 17rem)',
  },
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  '& .MuiTableHead-root': {
    '& .MuiTableCell-root': {
      '&:first-child': {
        width: '10%',
      },
      '&:nth-child(2)': {
        paddingLeft: '5rem',
        textAlign: 'left',
        width: '30%',
      },
      '&:nth-child(3)': {
        textAlign: 'left',
      },
      '&:last-child': {
        textAlign: 'right',
        paddingRight: '2rem',
      },
    },
  },
  '& .MuiTableSortLabel-icon': {
    position: 'absolute',
    right: '-1.5rem',
  },
});

export const StyledTable = styled(Table)({
  minWidth: '640px',
});

export const StyledTableRow = styled(TableRow)({
  '& .MuiTableCell-root': {
    '&:nth-of-type(2)': {
      paddingLeft: '2.5rem',
    },
    '&:last-child': {
      paddingRight: '2rem',
    },
  },
  '& span': {
    opacity: 0.5,
    fontSize: '0.75rem',
    fontWeight: 400,
    lineHeight: '1rem',
    fontFamily: '"SF Pro Display", sans-serif',
  },
  '& .value': {
    fontSize: '1.25rem',
    fontWeight: 600,
    lineHeight: '2.25rem',
    opacity: 1,
  },
  '& .place': {
    margin: '0 auto',
    fontWeight: 600,
    opacity: 1,
    borderRadius: '50%',
    width: '1.375rem',
    height: '1.375rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&-1': {
      backgroundColor: COLORS.GOLDEN,
    },
    '&-2': {
      backgroundColor: COLORS.SILVER,
    },
    '&-3': {
      backgroundColor: COLORS.BRONZE,
    },
  },
});

export const StyledUserInfo = styled(Box)({
  display: 'flex',
  gap: '0.625rem',
});

export const StyledAvatar = styled(Box)({
  width: '3.125rem',
  height: '3.125rem',
  borderRadius: '50%',
  overflow: 'hidden',
  cursor: 'pointer',
  '& img': {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
  },
});

export const StyledUserMeta = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  cursor: 'pointer',
  '& div': {
    fontSize: '0.875rem',
    fontFamily: '"SF Pro Display", sans-serif',
  },
  '& .name': {
    fontWeight: 600,
    width: 'max-content',
  },
  '& .phone': {
    opacity: 0.5,
    fontWeight: 400,
  },
});
