import {
  IShowcaseOfferDiscountReq,
  IShowcaseOfferDiscountBranch,
  IShowcaseOfferDiscountSale,
} from 'types';

export const TRANSLATIONS: any = {
  0: {
    add: {
      title: 'Новый продукт',
    },
    edit: {
      title: 'Редактирование продукта',
    },
    subtitle: {
      title: 'О продукте',
    },
  },
  1: {
    add: {
      title: 'Новая скидка',
    },
    edit: {
      title: 'Редактирование скидки',
    },
    subtitle: {
      title: 'О скидке',
    },
  },
  2: {
    add: {
      title: 'Новое предложение',
    },
    edit: {
      title: 'Редактировать предложение',
    },
    subtitle: {
      title: 'О предложении',
    },
  },
};

export const DEFAULT_BRANCH: IShowcaseOfferDiscountBranch = {
  id: 0,
  city_name: '',
  location: [55.751574, 37.573856],
  address: '',
  phone: '',
  work_time: '',
};

export const DEFAULT_DISCOUNT: IShowcaseOfferDiscountSale = {
  id: 0,
  title: '',
  description: '',
  type: undefined,
  value: '',
};

export const DEFAULT_VALUES: IShowcaseOfferDiscountReq = {
  category_id: 0,
  new_category_id: 0,
  required_level: 0,
  title: '',
  description_json: '',
  description_html: '',
  // tags: [],
  cover_big: '',
  cover_mini: '',
  is_active: true,
  show_in_slider: false,
  position_in_slider: -1,
  position_in_list: 0,
  branches: [],
  discounts: [],
  uri: '',
  type: 0,
  price: 0,
  discount_description: '',
  started_at: '',
  expired_at: '',
  is_indefinite: false,
};

export const TYPE_OPTIONS = [
  {
    label: 'Предложение',
    value: 2,
  },
  {
    label: 'Скидка',
    value: 1,
  },
];
