import { FC, useState, useEffect } from 'react';
import { Box, TextField } from '@mui/material';
import {
  Drawer,
  ModalTitle,
  Button,
  ImageInput,
  DrawerHeader,
  FormItem,
  DrawerFormBody,
  StyledFlexBox,
} from 'UI';
import { DeleteModal } from 'components';
import { LeftArrowIcon, DeleteIcon } from 'assets';
import { ISponsorReq } from 'types';
import {
  useGetSponsor,
  useCreateSponsor,
  useUpdateSponsor,
  useDeleteSponsor,
} from 'hooks';
import { COLORS, MEDIA_TYPES } from 'utils';

const TRANSLATIONS = {
  add: {
    title: 'Добавить спонсора',
  },
  edit: {
    title: 'Редактировать спонсора',
  },
};

const DEFAULT_VALUES: ISponsorReq = {
  name: '',
  description: '',
  logo: '',
  cover: '',
  link_vk: '',
  link_ok: '',
  link_site: '',
  marathon_id: 0,
};

interface ICreateSponsorForChallengeProps {
  open: boolean;
  onClose: () => void;
  purpose?: 'add' | 'edit';
  marathonId?: number | string;
  id?: string;
}

export const CreateSponsorForChallenge: FC<ICreateSponsorForChallengeProps> = ({
  open,
  onClose,
  id = '',
  purpose = 'add',
  marathonId = '',
}) => {
  const { data: sponsorInfoData } = useGetSponsor(id);
  const [sponsorInfo, setSponsorInfo] = useState<ISponsorReq>(DEFAULT_VALUES);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const { mutateAsync: createSponsor } = useCreateSponsor();
  const { mutateAsync: updateSponsor } = useUpdateSponsor();
  const { mutateAsync: deleteSponsor } = useDeleteSponsor();

  useEffect(() => {
    if (purpose === 'edit') {
      setSponsorInfo({
        id: sponsorInfoData?.id,
        name: sponsorInfoData?.name || '',
        description: sponsorInfoData?.description || '',
        logo: sponsorInfoData?.logo || '',
        cover: sponsorInfoData?.cover || '',
        link_vk: sponsorInfoData?.link_vk || '',
        link_ok: sponsorInfoData?.link_ok || '',
        link_site: sponsorInfoData?.link_site || '',
        marathon_id: marathonId,
      });
    } else {
      setSponsorInfo({ ...DEFAULT_VALUES, marathon_id: marathonId });
    }
  }, [sponsorInfoData, purpose]);

  const handleFieldChange = <T extends keyof ISponsorReq>(
    fieldName: T,
    newValue: ISponsorReq[T],
  ) => {
    setSponsorInfo(prevSponsorInfo => ({
      ...prevSponsorInfo,
      [fieldName]: newValue,
    }));
  };

  const openDeleteModal = () => {
    setShowDeleteModal(true);
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const handleDeleteSponsor = () => {
    onClose();
    deleteSponsor(sponsorInfo.id!)
      .then(() => {
        closeDeleteModal();
      })
      .catch(() => {});
  };

  const saveChanges = () => {
    if (purpose === 'add') {
      createSponsor(sponsorInfo)
        .then(() => {
          setSponsorInfo(DEFAULT_VALUES);
          onClose();
        })
        .catch(() => {});
    } else {
      updateSponsor(sponsorInfo)
        .then(() => {
          setSponsorInfo(DEFAULT_VALUES);
          onClose();
        })
        .catch(() => {});
    }
  };

  const ignoreChanges = () => {
    setSponsorInfo(DEFAULT_VALUES);
    onClose();
  };
  return (
    <Drawer open={open} onClose={ignoreChanges}>
      <DeleteModal
        open={showDeleteModal}
        onConfirm={handleDeleteSponsor}
        onClose={closeDeleteModal}
        title='Удалить cпонсора'
        description='Вы действительно хотите удалить cпонсора?'
      />
      <DrawerHeader>
        <ModalTitle title={TRANSLATIONS[purpose].title} />
        <LeftArrowIcon onClick={ignoreChanges} />
      </DrawerHeader>
      <DrawerFormBody>
        <StyledFlexBox>
          <ImageInput
            inputId='sponsorLogo'
            title='Добавьте логотип'
            descriptionWithoutImage='Загрузите изображение (512x512 px, до 1 мб)'
            withDescription
            image={sponsorInfo.logo}
            setImage={newImg => handleFieldChange('logo', newImg)}
            mediaType={MEDIA_TYPES.SPONSOR_LOGO}
            imageFixedHeight={512}
            imageFixedWidth={512}
          />
          <ImageInput
            inputId='sponsorCover'
            title='Добавьте обложку'
            descriptionWithoutImage='Загрузите изображение (1125x675 px, до 1 мб)'
            withDescription
            image={sponsorInfo.cover}
            setImage={newImg => handleFieldChange('cover', newImg)}
            mediaType={MEDIA_TYPES.SPONSOR_COVER}
            imageFixedHeight={675}
            imageFixedWidth={1125}
          />
        </StyledFlexBox>
        <h2>О спонсоре</h2>
        <FormItem label='Название'>
          <TextField
            variant='standard'
            color='primary'
            placeholder='Введите название'
            name='name'
            value={sponsorInfo.name}
            onChange={e => handleFieldChange('name', e.target.value)}
          />
        </FormItem>
        <FormItem label='Описание'>
          <TextField
            variant='standard'
            color='primary'
            placeholder='Введите описание'
            name='description'
            value={sponsorInfo.description}
            onChange={e => handleFieldChange('description', e.target.value)}
          />
        </FormItem>
        <h2>Соц сети</h2>
        <FormItem label='Ссылка на Вконтакте'>
          <TextField
            variant='standard'
            color='primary'
            placeholder='Вставьте ссылку'
            name='link_vk'
            value={sponsorInfo.link_vk}
            onChange={e => handleFieldChange('link_vk', e.target.value)}
          />
        </FormItem>
        <FormItem label='Ссылка на Одноклассники'>
          <TextField
            variant='standard'
            color='primary'
            placeholder='Вставьте ссылку'
            name='link_ok'
            value={sponsorInfo.link_ok}
            onChange={e => handleFieldChange('link_ok', e.target.value)}
          />
        </FormItem>
        <FormItem label='Ссылка на сайт'>
          <TextField
            variant='standard'
            color='primary'
            placeholder='Вставьте ссылку'
            name='link_site'
            value={sponsorInfo.link_site}
            onChange={e => handleFieldChange('link_site', e.target.value)}
          />
        </FormItem>
      </DrawerFormBody>
      <StyledFlexBox className='buttons fullDivWidth'>
        {purpose === 'edit' ? (
          <Button
            onClick={openDeleteModal}
            variant='contained'
            size='large'
            color='customGray'
            startIcon={<DeleteIcon color={COLORS.RED} />}
            sx={{
              color: COLORS.RED,
            }}
          >
            Удалить
          </Button>
        ) : (
          <Box />
        )}
        <Button
          variant='contained'
          size='large'
          color='primary'
          onClick={saveChanges}
        >
          Создать
        </Button>
      </StyledFlexBox>
    </Drawer>
  );
};
