import { FC, Suspense, lazy } from 'react';
import { useDrawer } from 'hooks';
import { Loader } from 'UI';

const ShowcaseCategoriesTable = lazy(() =>
  import('components').then(module => ({
    default: module.ShowcaseCategoriesTable,
  })),
);
const AddEditShowcaseCategoriesDrawer = lazy(() =>
  import('components').then(module => ({
    default: module.AddEditShowcaseCategoriesDrawer,
  })),
);

export const ShowcaseCategories: FC = () => {
  const { showAddEditDrawer, hideAddEditDrawer, editDrawerId } =
    useDrawer();
  return (
    <Suspense fallback={<Loader />}>
      <AddEditShowcaseCategoriesDrawer
        open={showAddEditDrawer}
        onClose={hideAddEditDrawer}
        id={editDrawerId}
        purpose={editDrawerId === '0' ? 'add' : 'edit'}
      />
      <ShowcaseCategoriesTable />
    </Suspense>
  );
};
