import { FC } from 'react';
import { styled } from '@mui/system';
import { Paper, Box } from '@mui/material';
import { LineChart } from '@mui/x-charts/LineChart';
import { COLORS } from 'utils';
import { BottomIcon, UpIcon } from 'assets';

const StyledContainer = styled(Paper)({
  width: '100%',
  padding: '1rem 1.25rem',
  backgroundColor: COLORS.WHITE,
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'space-between',
  gap: '1rem',
  '& .header': {
    fontSize: '1.25rem',
    fontWeight: 600,
    fontFamily: '"SF Pro Display", sans-serif',
  },
  '& .chartContainer': {
    minHeight: '12.5rem',
    maxHeight: '15rem',
    '& .MuiChartsAxis-tickContainer': {
      display: 'none',
      '&:first-of-type': {
        display: 'inline',
      },
      '&:last-of-type': {
        display: 'inline',
        '& .MuiChartsAxis-tickLabel': {
          transform: 'translateX(-2rem)',
        },
      },
    },
    '& .noData': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '1rem',
      fontWeight: 600,
      fontFamily: '"SF Pro Display", sans-serif',
      height: '100%',
      color: COLORS.RED,
      textAlign: 'center',
    },
  },
  '& .footer': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'end',
    fontSize: '1rem',
    lineHeight: '1.5rem',
    '& .title': {
      display: 'flex',
      gap: '0.5rem',
      alignItems: 'center',
    },
    '& .value': {
      display: 'flex',
      flexDirection: 'column',
      gap: '0.25rem',
      '& .change': {
        fontSize: '0.875rem',
        fontWeight: 500,
        lineHeight: '1.375rem',
        fontFamily: '"SF Pro Display", sans-serif',
        display: 'flex',
        alignItems: 'center',
        gap: '0.25rem',
        '& .negative': {
          color: COLORS.RED,
        },
        '& .positive': {
          color: COLORS.GREEN,
        },
      },
    },
  },
});

const StyledDot = styled(Box)(({ color }: { color: string }) => ({
  width: '0.5rem',
  height: '0.5rem',
  borderRadius: '50%',
  backgroundColor: color,
}));

interface IAnalyticsLineChartCardProps {
  title: string;
  value: number;
  changeInValue?: number;
  xAxisData?: string[];
  yAxisData?: number[];
  color: string;
}

export const AnalyticsLineChartCard: FC<IAnalyticsLineChartCardProps> = ({
  title,
  value,
  changeInValue,
  xAxisData = [],
  yAxisData = [],
  color,
}) => {
  const isNegative = changeInValue && changeInValue < 0;
  // Yes, I know this is cringe, but backend is sending a value in a format that I don't want
  const xAxisValues =
    xAxisData?.length === 0
      ? []
      : xAxisData?.length === 1
      ? ['0', xAxisData[0], '0']
      : xAxisData;

  const yAxisValues =
    yAxisData?.length === 0
      ? []
      : yAxisData?.length === 1
      ? [0, yAxisData[0], 0]
      : yAxisData;

  return (
    <StyledContainer>
      <Box className='header'>{title}</Box>
      <Box className='chartContainer'>
        {xAxisData?.length > 0 ? (
          <LineChart
            margin={{ top: 20, bottom: 20, left: 40, right: 10 }}
            xAxis={[
              {
                scaleType: 'point',
                data: xAxisValues,
              },
            ]}
            yAxis={[{ data: yAxisValues }]}
            series={[{ curve: 'linear', data: yAxisValues }]}
            colors={[color]}
          />
        ) : (
          <Box className='noData'>Нет данных для отображения</Box>
        )}
      </Box>
      <Box className='footer'>
        <Box className='title'>
          <StyledDot color={color} />
          <Box>{title}</Box>
        </Box>
        <Box className='value'>
          {changeInValue && (
            <Box className={`${isNegative ? 'negative' : 'positive'} change`}>
              {changeInValue}{' '}
              {isNegative ? <BottomIcon /> : <UpIcon color={COLORS.GREEN} />}
            </Box>
          )}
          <Box>{value}</Box>
        </Box>
      </Box>
    </StyledContainer>
  );
};
