import * as Pages from 'pages';
import { ROLES } from 'utils';
import { IRoutes } from '../types';

export const Users: IRoutes = {
  users: {
    path: '/users',
    title: 'users',
    element: <Pages.Users />,
    roles: [ROLES.ADMINSTRATOR],
  },
};
