import {
  IBannerReq,
  IBannerGetParams,
  IBannersSortReq,
  ISearchSomethingForBannersGetParams
} from 'types';
import { http, methods } from './config';

export const getBanners = (params: IBannerGetParams) => {
  const config = {
    method: methods.get,
    url: `/banner`,
    params,
  };
  return http(config);
};

export const getBanner = (bannerId: string | number) => {
  const config = {
    method: methods.get,
    url: `/banner/${bannerId}`,
  };
  return http(config);
};

export const createBanner = (data: IBannerReq) => {
  const config = {
    method: methods.post,
    url: `/banner`,
    data,
  };
  return http(config);
};

export const updateBanner = (data: IBannerReq) => {
  const config = {
    method: methods.patch,
    url: `/banner/${data.id}`,
    data,
  };
  return http(config);
};

export const deleteBanner = (bannerId: number | string) => {
  const config = {
    method: methods.delete,
    url: `/banner/${bannerId}`,
  };
  return http(config);
};

export const updateBannerPosition = (data: IBannersSortReq) => {
  const config = {
    method: methods.post,
    url: `/banner/update_positions`,
    data,
  };
  return http(config);
};

export const searchProductsForBanners = (
  params: ISearchSomethingForBannersGetParams,
) => {
  const config = {
    method: methods.get,
    url: `/banner/search/showcases`,
    params,
  };
  return http(config);
};

export const searchMarathonSpacesForBanners = (
  params: ISearchSomethingForBannersGetParams,
) => {
  const config = {
    method: methods.get,
    url: `/banner/search/marathon-spaces`,
    params,
  };
  return http(config);
};

export const searchNewsForBanners = (
  params: ISearchSomethingForBannersGetParams,
) => {
  const config = {
    method: methods.get,
    url: `/banner/search/news`,
    params,
  };
  return http(config);
};
