import { CSSProperties, FC, ReactNode } from 'react';
import { Box } from '@mui/material';
import { PageTitle } from 'UI';

import { StyledContainer } from './styledComponents';

interface IPageHeader {
  title: string;
  children?: ReactNode;
  hasMiddleComponent?: boolean;
  middleComponent?: ReactNode;
  style?: CSSProperties;
}

export const PageHeader: FC<IPageHeader> = ({
  title,
  hasMiddleComponent,
  middleComponent,
  children,
  style,
}) => {
  return (
    <StyledContainer style={style}>
      <PageTitle title={title} />
      {hasMiddleComponent && <Box>{middleComponent}</Box>}
      <Box className='controls'>{children}</Box>
    </StyledContainer>
  );
};
