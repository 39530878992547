import { Box, Paper, Table, TableContainer, TableRow } from '@mui/material';
import { styled } from '@mui/system';
import { COLORS } from 'utils';

export const StyledBox = styled(Box)({
  width: '100%',
  overflow: 'auto',
});

export const StyledPaper = styled(Paper)({
  width: '100%',
  marginBottom: 2,
});

export const StyledTableContainer = styled(TableContainer)({
  maxHeight: 'calc(100vh - 12rem)',
  minHeight: 'calc(100vh - 12rem)',
  '& .MuiTableSortLabel-root': {
    width: '100%',
  },
  '& .MuiTableSortLabel-icon': {
    position: 'absolute',
    right: '0',
  },
});

export const StyledTable = styled(Table)({
  minWidth: '1040px',
});

export const StyledTableRow = styled(TableRow)({
  '& .MuiTableCell-root': {
    '&:first-child': {
      width: '25%',
    },
  },
  '& a': {
    textDecoration: 'none',
  },
  '& .comments': {
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    cursor: 'pointer',
  },
  '& span': {
    opacity: 0.5,
    fontSize: '0.75rem',
    fontWeight: 400,
    lineHeight: '1rem',
    fontFamily: '"SF Pro Display", sans-serif',
  },
  '& .editDeleteIcons': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    gap: '0.125rem',
    '& svg': {
      cursor: 'pointer',
      marginTop: '0.5rem',
    },
  },
});

export const StyledNewsInfoAtModal = styled(Box)({
  display: 'flex',
  gap: '0.625rem',
  border: `1px solid ${COLORS.GRAY}`,
  padding: '0.625rem',
  borderRadius: '0.5rem',
});

export const StyledNewsInfo = styled(Box)({
  display: 'flex',
  gap: '0.625rem',
  alignItems: 'center',
});

export const StyledAvatar = styled(Box)({
  minWidth: '5rem',
  maxWidth: '5rem',
  height: '3.125rem',
  borderRadius: '0.5rem',
  overflow: 'hidden',
  cursor: 'pointer',
  '& img': {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
  },
});

export const StyledNewsName = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  cursor: 'pointer',
  fontSize: '0.875rem',
  fontFamily: '"SF Pro Display", sans-serif',
  fontWeight: 600,
  minWidth: '10rem',
  //   width: 'max-content',
});
