import { useQuery, useMutation, useQueryClient } from 'react-query';
import {
  getLevelsList,
  getLevels,
  getLevel,
  createLevel,
  updateLevel,
  deleteLevel,
} from 'api';
import { ILevelListItem, ILevel, ILevelRes, ILevelGetParams } from 'types';

export const useGetLevelsList = () => {
  return useQuery<ILevelListItem[], unknown>(
    ['levels', 'list'],
    async () => {
      const res = await getLevelsList();
      return res;
    },
    {
      staleTime: Infinity,
      retry: false,
    },
  );
};

export const useGetLevels = (params: ILevelGetParams) => {
  const { page } = params;
  return useQuery<ILevelRes, unknown>(
    ['levels', { page }],
    async () => {
      const res = await getLevels(params);
      return res;
    },
    {
      staleTime: Infinity,
      retry: false,
    },
  );
};

export const useGetLevel = (levelId: string | number) => {
  if (!levelId)
    return useQuery(['levels', 'levelId', levelId], () => {
      return Promise.resolve(null);
    });
  return useQuery<ILevel>(
    ['levels', 'levelId', levelId],
    async () => {
      const res = await getLevel(levelId);
      return res;
    },
    {
      staleTime: Infinity,
      retry: false,
    },
  );
};

export const useCreateLevel = () => {
  const queryClient = useQueryClient();
  return useMutation(createLevel, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['levels'] });
    },
    onError: () => {},
  });
};

export const useUpdateLevel = () => {
  const queryClient = useQueryClient();
  return useMutation(updateLevel, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['levels'] });
    },
    onError: () => {},
  });
};

export const useDeleteLevel = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteLevel, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['levels'] });
    },
    onError: () => {},
  });
};
