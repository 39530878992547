import { FC } from 'react';
import { useDrawer } from 'hooks';
import {
  AddEditGroupDrawer,
  GroupsBody,
  GroupsHeader,
  Layout,
  ViewGroupDrawer,
} from 'components';

export const Groups: FC = () => {
  const {
    openAddDrawer,
    viewDrawerId,
    showViewDrawer,
    hideViewDrawer,
    editDrawerId,
    purpose,
    hideAddEditDrawer,
    showAddEditDrawer,
  } = useDrawer();

  return (
    <Layout withoutPB>
      <AddEditGroupDrawer
        open={showAddEditDrawer}
        onClose={hideAddEditDrawer}
        purpose={purpose}
        id={editDrawerId}
      />
      <ViewGroupDrawer
        open={showViewDrawer}
        id={viewDrawerId}
        onClose={hideViewDrawer}
      />
      <GroupsHeader openAddGroupModal={openAddDrawer} />
      <GroupsBody />
    </Layout>
  );
};
