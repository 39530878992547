import { FC, useState, useEffect } from 'react';
import { TextField, Box } from '@mui/material';
import {
  Drawer,
  DrawerHeader,
  DrawerFormBody,
  ModalTitle,
  Button,
  StyledFlexBox,
  FormItem,
  ImageInput,
  StyledSwitch,
  DateTimeInput,
} from 'UI';
import { CloseIcon } from 'assets';
import { IStoryReq, IStoryItem } from 'types';
import { useCreateStory, useUpdateStory, useGetStory } from 'hooks';
import { MEDIA_TYPES } from 'utils';
import { StoryPreviewModal } from 'components';
import { StoryItems } from './StoryItems';
import { StyledSwitchFormControl } from './styledComponents';

const TRANSLATIONS = {
  add: {
    title: 'Новый сторис',
  },
  edit: {
    title: 'Редактировать сторис',
  },
};

const DEFAULT_VALUES: IStoryReq = {
  title: '',
  cover: '',
  is_active: true,
  position: 0,
  published_at: null,
  unpublished_at: null,
  items: [],
};

interface IAddEditStoryDrawerProps {
  open: boolean;
  onClose: () => void;
  purpose?: 'add' | 'edit';
  id?: string;
}

export const AddEditStoryDrawer: FC<IAddEditStoryDrawerProps> = ({
  open,
  onClose,
  purpose = 'add',
  id = '',
}) => {
  const { data: storyInfoData } = useGetStory(id);

  const [showPreview, setShowPreview] = useState<boolean>(false);
  const [storyInfo, setStoryInfo] = useState<IStoryReq>(DEFAULT_VALUES);

  const { mutateAsync: createStory } = useCreateStory();
  const { mutateAsync: updateStory } = useUpdateStory();

  useEffect(() => {
    if (purpose === 'edit') {
      setStoryInfo({
        id: storyInfoData?.id,
        position: storyInfoData?.position || 0,
        title: storyInfoData?.title || '',
        cover: storyInfoData?.cover || '',
        is_active: !!storyInfoData?.is_active,
        published_at: storyInfoData?.published_at,
        unpublished_at: storyInfoData?.unpublished_at,
        items: storyInfoData?.items || [],
      });
    } else {
      setStoryInfo(DEFAULT_VALUES);
    }
  }, [purpose, storyInfoData]);

  const handleFieldChange = <T extends keyof IStoryReq>(
    fieldName: T,
    newValue: IStoryReq[T],
  ) => {
    setStoryInfo(prevstoryInfo => ({
      ...prevstoryInfo,
      [fieldName]: newValue,
    }));
  };

  const addItem = (newItem: IStoryItem) => {
    setStoryInfo({
      ...storyInfo,
      items: [...storyInfo.items, newItem],
    });
  };

  const editItem = (editedItem: IStoryItem) => {
    const updatedItems = [...storyInfo.items];
    const updatedElIndex = updatedItems.findIndex(
      el => el.id === editedItem.id,
    );
    updatedItems[updatedElIndex] = editedItem;
    setStoryInfo({
      ...storyInfo,
      items: updatedItems,
    });
  };

  const removeItem = (itemId: string) => {
    const updatedItems = [...storyInfo.items]?.filter(
      el => el.id?.toString() !== itemId.toString(),
    );
    setStoryInfo({
      ...storyInfo,
      items: updatedItems,
    });
  };

  const ignoreChanges = () => {
    setStoryInfo(DEFAULT_VALUES);
    onClose();
  };
  const saveChanges = () => {
    if (purpose === 'add') {
      createStory({
        ...storyInfo,
      })
        .then(() => {
          setStoryInfo(DEFAULT_VALUES);
          onClose();
        })
        .catch(() => {});
    } else {
      const itemsWithNewIds = storyInfo.items.map(el => {
        if (+el!.id! <= 0) {
          return {
            ...el,
            id: 0,
          };
        }
        return el;
      });
      updateStory({ ...storyInfo, items: itemsWithNewIds })
        .then(() => {
          setStoryInfo(DEFAULT_VALUES);
          onClose();
        })
        .catch(() => {});
    }
  };

  const openPreview = () => {
    setShowPreview(true);
  };

  const closePreview = () => {
    setShowPreview(false);
  };

  return (
    <Drawer open={open} onClose={ignoreChanges}>
      <StoryPreviewModal
        open={showPreview}
        onClose={closePreview}
        items={storyInfo.items}
      />
      <DrawerHeader>
        <ModalTitle title={TRANSLATIONS[purpose].title} />
        <CloseIcon onClick={ignoreChanges} />
      </DrawerHeader>
      <DrawerFormBody>
        <h2>Обложка</h2>
        <StyledFlexBox className='fullDivWidth'>
          <ImageInput
            inputId='storyCover'
            title='Добавьте обложку'
            descriptionWithoutImage='Загрузите изображение (1080 x 1920 px, до 1 мб)'
            withDescription
            image={storyInfo.cover}
            setImage={newImg => handleFieldChange('cover', newImg)}
            mediaType={MEDIA_TYPES.STORIES_COVER}
            imageFixedWidth={1080}
            imageFixedHeight={1920}
            placeholderSize='vertical'
          />
          <Box />
        </StyledFlexBox>
        <h2>Сторисы</h2>
        <StoryItems
          items={storyInfo.items}
          addItem={addItem}
          editItem={editItem}
          removeItem={removeItem}
        />
        <h2>О сторисе</h2>
        <FormItem label='Название'>
          <TextField
            variant='standard'
            color='primary'
            placeholder='Введите название'
            name='title'
            value={storyInfo.title}
            onChange={e => handleFieldChange('title', e.target.value)}
          />
        </FormItem>
        <FormItem label='Позиция'>
          <TextField
            variant='standard'
            color='primary'
            placeholder='Введите позицию'
            name='title'
            value={storyInfo.position}
            onChange={e =>
              handleFieldChange('position', e.target.value as unknown as number)
            }
          />
        </FormItem>

        <h2>Отложенная публикация</h2>
        <DateTimeInput
          dateLabel='Дата отправки'
          timeLabel='Время отправки'
          defaultValue={storyInfo.published_at}
          onChange={val => handleFieldChange('published_at', val)}
        />
        <h2>Дата снятия с публикации</h2>
        <DateTimeInput
          dateLabel='Дата снятия'
          timeLabel='Время снятия'
          defaultValue={storyInfo.unpublished_at}
          onChange={val => handleFieldChange('unpublished_at', val)}
        />

        <StyledSwitchFormControl
          label='Статус'
          labelPlacement='start'
          sx={{ marginBottom: '1.25rem' }}
          control={
            <StyledSwitch
              checked={storyInfo.is_active}
              onChange={() =>
                handleFieldChange('is_active', !storyInfo.is_active)
              }
            />
          }
        />
      </DrawerFormBody>
      <StyledFlexBox className='buttons fullDivWidth'>
        <Button onClick={openPreview} color='customGray' size='large'>
          Предпросмотр
        </Button>
        <Button variant='contained' size='large' onClick={saveChanges}>
          Сохранить
        </Button>
      </StyledFlexBox>
    </Drawer>
  );
};
