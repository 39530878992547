import { FC, useContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { UserContext, UserContextType } from 'app';
import { IMarathonSpaceListItem } from 'types';
import { useChangeStatus, useDeleteMarathon } from 'hooks';
import { DeleteModal } from 'components';
import { EditIcon, DeleteIcon } from 'assets';
import { StyledSwitch } from 'UI';
import {
  COLORS,
  CREATE_CHALLENGE_DRAWER_STEPS,
  convertDateToDottedValue,
  notify,
} from 'utils';
import {
  ChallengeCardContainer,
  ChallengeCardHeader,
  ChallengeCardBody,
} from './styledComponents';
import { ChallengeStatusBadge } from './StyledBadge';

interface IMarathonCardProps {
  marathon: IMarathonSpaceListItem;
}

export const ChallengeCard: FC<IMarathonCardProps> = ({ marathon }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { space_id } = useContext(UserContext) as UserContextType;
  const { mutateAsync: changeStatus } = useChangeStatus('marathon_space');
  const { mutateAsync: deleteChallenge } = useDeleteMarathon();
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const openDeleteModal = () => {
    setShowDeleteModal(true);
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const handleDeleteChallenge = () => {
    deleteChallenge(marathon.id!)
      .then(() => {
        closeDeleteModal();
      })
      .catch(() => {});
  };

  const switchHandler = () => {
    changeStatus({
      model_name: 'marathon_space',
      model_id: marathon.id,
      state: !marathon.is_active,
      space_id: space_id!,
    })
      .then(() => {
        notify('Статус успешно изменен', 'success');
      })
      .catch(() => {});
  };

  const openEditDrawer = () => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('edit', marathon.id.toString());
    searchParams.set('step', CREATE_CHALLENGE_DRAWER_STEPS.MAIN_INFO);
    navigate(`?${searchParams.toString()}`);
  };

  const openViewDrawer = () => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('view', marathon.id.toString());
    navigate(`?${searchParams.toString()}`);
  };

  return (
    <ChallengeCardContainer bgImg={marathon.cover}>
      <DeleteModal
        open={showDeleteModal}
        onConfirm={handleDeleteChallenge}
        onClose={closeDeleteModal}
        title='Удалить вызов'
        description='Вы действительно хотите удалить вызов?'
      />
      <ChallengeCardHeader>
        <ChallengeStatusBadge
          startDate={marathon.start_date}
          endDate={marathon.end_date}
          isActive={marathon.is_active}
        />
        <Box className='buttons'>
          <EditIcon onClick={openEditDrawer} color={COLORS.WHITE} />
          <DeleteIcon onClick={openDeleteModal} color={COLORS.WHITE} />
          <StyledSwitch
            checked={marathon.is_active}
            onChange={switchHandler}
            variant='customWhite'
            className='switch'
          />
        </Box>
      </ChallengeCardHeader>
      <ChallengeCardBody>
        <Box className='title' onClick={openViewDrawer}>
          {marathon.name}
        </Box>
        <Box className='info'>
          с {convertDateToDottedValue(marathon.start_date)} до{' '}
          {convertDateToDottedValue(marathon.end_date)}
        </Box>
        <Box className='info'>
          Количество участников: {marathon.members_count}
        </Box>
      </ChallengeCardBody>
    </ChallengeCardContainer>
  );
};
