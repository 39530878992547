import { FC } from 'react';
import { PageHeader, GlobalSearch, Button } from 'UI';
import { PlusIcon } from 'assets';
import { ROUTES } from 'app';
import { FilterCategory } from '../NewsTable/FilterCategory';
import { FilterGroup } from '../NewsTable/FilterGroup';

interface INewsHeaderProps {
  openCategoriesDrawer: () => void;
}

export const NewsHeader: FC<INewsHeaderProps> = ({ openCategoriesDrawer }) => {
  return (
    <PageHeader title='Новости'>
      <GlobalSearch size='medium' />
      <FilterCategory />
      <FilterGroup />
      <Button
        variant='contained'
        color='customDarkerGray'
        onClick={openCategoriesDrawer}
        className='withFixedWidth'
      >
        Категории
      </Button>
      <Button
        variant='contained'
        href={ROUTES.createNews}
        startIcon={<PlusIcon />}
        className='withFixedWidth'
      >
        Добавить
      </Button>
    </PageHeader>
  );
};
