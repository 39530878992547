import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { COLORS } from 'utils';

export const StyledFooter = styled(Box)({
  width: 'calc(100% - 4rem)',
  backgroundColor: COLORS.WHITE,
  display: 'flex',
  borderRadius: '0.5rem',
  position: 'absolute',
  bottom: '1rem',
  height: '5.25rem',
});

export const StyledLeftSide = styled(Box)({
  padding: '0.5rem 1.25rem',
  borderRight: `1px solid ${COLORS.GRAY}`,
  width: '16rem',
});

export const StyledRightSide = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '1.25rem',
  width: '100%',
});

export const StyledBalanceBox = styled(Box)({
  display: 'flex',
  gap: '1rem',
});

export const StyledBalance = styled(Box)({
  width: '8rem',
});

export const StyledTitle = styled(Box)({
  fontSize: '1rem',
  fontWeight: 600,
  lineHeight: '1.5rem',
  color: COLORS.BLACK,
  marginBottom: '0.25rem',
});

export const StyledValue = styled(Box)({
  fontSize: '0.75rem',
  fontWeight: 400,
  lineHeight: '1rem',
  color: COLORS.BLACK,
  opacity: 0.5,
  whiteSpace: 'nowrap',
});
