import { FC } from 'react';

import { PageHeader, GlobalSearch, Button } from 'UI';
import { PlusIcon } from 'assets';

interface IWorkersHeader {
  openAddWorkerModal: () => void;
}

export const WorkersHeader: FC<IWorkersHeader> = ({ openAddWorkerModal }) => {
  // const downloadWorkers = () => {};
  return (
    <PageHeader title='Сотрудники'>
      <GlobalSearch size='medium' />
      <Button
        variant='contained'
        onClick={openAddWorkerModal}
        startIcon={<PlusIcon />}
      >
        Добавить
      </Button>
      {/* <Button
        variant='contained'
        color='customDarkerGray'
        onClick={downloadWorkers}
      >
        <DownloadIcon />
      </Button> */}
    </PageHeader>
  );
};
