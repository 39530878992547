import {
  IShowcaseOfferDiscountReqParams,
  IShowcaseOfferDiscountReq,
  IShowcaseOfferDiscountDownloadUnusedPromocodesFileReqParams,
} from 'types';
import { http, methods } from '../config';

export const getShowcaseOfferDiscounts = (
  params: IShowcaseOfferDiscountReqParams,
) => {
  const config = {
    method: methods.get,
    url: `/shop/showcase/category/${params.category_id}/item`,
    params,
  };
  return http(config);
};

export const getShowcaseOfferDiscount = (
  params: IShowcaseOfferDiscountReqParams,
) => {
  const config = {
    method: methods.get,
    url: `/shop/showcase/category/${params.category_id}/item/${params.id}`,
  };
  return http(config);
};

export const createShowcaseOfferDiscount = (
  data: IShowcaseOfferDiscountReq,
) => {
  const config = {
    method: methods.post,
    url: `/shop/showcase/category/${data.category_id}/item`,
    data,
  };
  return http(config);
};

export const updateShowcaseOfferDiscount = (
  data: IShowcaseOfferDiscountReq,
) => {
  const config = {
    method: methods.put,
    url: `/shop/showcase/category/${data.category_id}/item/${data.id}`,
    data,
  };
  return http(config);
};

export const deleteShowcaseOfferDiscount = (
  params: IShowcaseOfferDiscountReqParams,
) => {
  const config = {
    method: methods.delete,
    url: `/shop/showcase/category/${params.category_id}/item/${params.id}`,
  };
  return http(config);
};

export const uploadShowcaseFile = (data: FormData) => {
  const config = {
    method: methods.post,
    url: `/upload/showcase/discount`,
    data,
  };
  return http(config);
};

export const getShowcaseUnusedPromocodesFile = (
  params: IShowcaseOfferDiscountDownloadUnusedPromocodesFileReqParams,
) => {
  const config = {
    method: methods.get,
    url: `/upload/showcase/discount`,
    params,
  };
  return http(config);
};

export const uploadShowcaseBranchesFile = (data: FormData) => {
  const config = {
    method: methods.post,
    url: `/upload/showcase/branch`,
    data,
  };
  return http(config);
};

export const getShowcaseBranchesFileSample = () => {
  const config = {
    method: methods.get,
    url: `/upload/showcase/branch`,
  };
  return http(config);
};

export const duplicateShowcaseOfferDiscount = (
  params: IShowcaseOfferDiscountReqParams,
) => {
  const config = {
    method: methods.post,
    url: `/shop/showcase/category/${params.category_id}/item/${params.id}/duplicate`,
  };
  return http(config);
};
