import { FC } from 'react';
import { styled } from '@mui/system';
import { Box } from '@mui/material';
import { Button, DrawerHeader, ModalTitle, StyledFlexBox } from 'UI';
import {
  AntennaIcon,
  BatteryIcon,
  CaloryIcon,
  LeftArrowIcon,
  PreviewFooter,
  RightIcon,
  StepsIcon,
  WifiIcon,
} from 'assets';
import { COLORS } from 'utils';

const StyledPhoneWrapper = styled(Box)({
  width: '18rem',
  height: '38rem',
  borderRadius: '1.25rem',
  border: `11px solid ${COLORS.BLACK}`,
  margin: '1rem auto',
});

const PhoneHeader = styled(Box)(({ bgImg }: { bgImg: string }) => ({
  backgroundImage: `url(${bgImg}), linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1))`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  // backgroundColor: '',
}));

const UpperBlock = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  color: COLORS.WHITE,
  padding: '0.5rem 1rem',
  fontSize: '0.67rem',
  fontFamily: '"SF Pro Text"',
  fontWeight: 600,
  letterSpacing: '-0.01338rem',
});

const UpperBlockIcons = styled(Box)({
  display: 'flex',
  gap: '0.25rem',
});

const NameBlock = styled(Box)({
  display: 'flex',
  padding: '0.25rem 1rem',
  alignItems: 'center',
  gap: '0.5rem',
  color: COLORS.WHITE,
  fontSize: '0.65rem',
  fontFamily: '"SF Pro Display"',
  fontWeight: 500,
  lineHeight: '0.98rem',
  '& .imageBox': {
    minWidth: '2rem',
    width: '2rem',
    height: '2rem',
    borderRadius: '50%',
    overflow: 'hidden',
    backgroundColor: '#D9D9D9',
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
  },
});

const CardsBlock = styled(Box)({
  padding: '1rem 0 0.75rem 1rem',
  '& .title': {
    fontSize: '1.07rem',
    color: COLORS.WHITE,
    fontFamily: '"SF Pro Display", sans-serif',
    fontWeight: 700,
    lineHeight: '1.34rem',
    marginBottom: '0.7rem',
  },
  '& .cards': {
    display: 'flex',
    gap: '0.5rem',
    overflow: 'hidden',
    flexWrap: 'nowrap',
    '& .card': {
      minWidth: '8rem',
      padding: '0.71rem',
      backgroundColor: 'rgba(255, 255, 255, 0.70)',
      borderRadius: '0.36rem',
      '& .cardHeader': {
        display: 'flex',
        alignItems: 'center',
        gap: '0.5rem',
        marginBottom: '0.5rem',
        '& .title': {
          fontSize: '0.625rem',
          fontFamily: '"SF Pro Display", sans-serif',
          fontWeight: 800,
          color: COLORS.BLACK,
          textTransform: 'uppercase',
          margin: 0,
          lineHeight: '0.9rem',
        },
      },
      '& .blocks': {
        fontSize: '1.6rem',
        fontFamily: '"SF Pro Display", sans-serif',
        lineHeight: '1.9rem',
        fontWeight: 700,
        color: COLORS.BLACK,
      },
      '& .text': {
        fontSize: '0.536rem',
        lineHeight: '0.71rem',
        opacity: 0.5,
        fontWeight: 500,
      },
    },
  },
});

const PhoneBody = styled(Box)({
  '& .title': {
    fontSize: '0.9rem',
    fontFamily: '"SF Pro Display", sans-serif',
    lineHeight: '1.16rem',
    fontWeight: 600,
    marginTop: '1.07rem',
    marginBottom: '0.71rem',
    marginLeft: '1rem',
  },
});

const LentaSection = styled(Box)({
  display: 'flex',
  gap: '0.5rem',
  overflow: 'hidden',
  paddingLeft: '1rem',
  '& .card': {
    minWidth: '13rem',
    height: '8rem',
    backgroundColor: 'rgba(0, 0, 0, 0.10)',
    borderRadius: '0.35rem',
  },
});

const GroupsHeader = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  '& .button': {
    cursor: 'default',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
});

const GroupsSection = styled(Box)({
  display: 'flex',
  gap: '0.5rem',
  overflow: 'hidden',
  paddingLeft: '1rem',
  height: '5rem',
  '& .card': {
    minWidth: '6.5rem',
    height: '9rem',
    backgroundColor: 'rgba(0, 0, 0, 0.10)',
    borderRadius: '0.35rem',
    position: 'relative',
    '& .pic': {
      borderRadius: '50%',
      width: '1.6rem',
      height: '1.6rem',
      backgroundColor: COLORS.WHITE,
      position: 'absolute',
      top: '0.89rem',
      left: '0.89rem',
    },
  },
});

interface IPreviewDrawerProps {
  onClose: () => void;
  title: string;
  logo: string;
  cover: string;
  name: string;
}

export const PreviewDrawer: FC<IPreviewDrawerProps> = ({
  onClose,
  title,
  logo,
  cover,
  name,
}) => {
  return (
    <>
      <DrawerHeader>
        <StyledFlexBox>
          <LeftArrowIcon onClick={onClose} />
          <ModalTitle title={title} />
        </StyledFlexBox>
      </DrawerHeader>
      <StyledPhoneWrapper>
        <PhoneHeader bgImg={cover}>
          <UpperBlock>
            <Box>9:41</Box>
            <UpperBlockIcons>
              <AntennaIcon />
              <WifiIcon />
              <BatteryIcon />
            </UpperBlockIcons>
          </UpperBlock>
          <NameBlock>
            <Box className='imageBox'>
              {logo && <img src={logo} alt={name} />}
            </Box>
            <Box>{name}</Box>
          </NameBlock>
          <CardsBlock>
            <Box className='title'>Активность</Box>
            <Box className='cards'>
              <Box className='card'>
                <Box className='cardHeader'>
                  <StepsIcon />
                  <Box className='title'>Посещения</Box>
                </Box>
                <Box className='blocks'>- - - -</Box>
                <Box className='text'>шагов за сегодня</Box>
              </Box>
              <Box className='card'>
                <Box className='cardHeader'>
                  <CaloryIcon />
                  <Box className='title'>Калории</Box>
                </Box>
                <Box className='blocks'>- - - -</Box>
                <Box className='text'>калорий за сегодня</Box>
              </Box>
            </Box>
          </CardsBlock>
        </PhoneHeader>
        <PhoneBody>
          <Box className='title'>Лента</Box>
          <LentaSection>
            <Box className='card' />
            <Box className='card' />
          </LentaSection>
          <GroupsHeader>
            <Box className='title'>Группы</Box>
            <Button
              variant='text'
              color='primary'
              size='small'
              className='button'
              disableRipple
              endIcon={<RightIcon />}
            >
              Все группы
            </Button>
          </GroupsHeader>
          <GroupsSection>
            <Box className='card'>
              <Box className='pic' />
            </Box>
            <Box className='card'>
              <Box className='pic' />
            </Box>
            <Box className='card'>
              <Box className='pic' />
            </Box>
          </GroupsSection>
          <PreviewFooter />
        </PhoneBody>
      </StyledPhoneWrapper>
    </>
  );
};
