import { FC, useState, MouseEvent, useMemo, useEffect } from 'react';
import { Box, Popover } from '@mui/material';
import { useGetTeams } from 'hooks';
import { Button, SearchInput } from 'UI';
import { DeleteIcon, PlusIcon } from 'assets';
import { COLORS } from 'utils';
import { IMarathonParticipantTeam, ITeam } from 'types';

import {
  StyledContainer,
  StyledMainContent,
  StyledTeamOptions,
  StyledTeamOption,
  StyledTeamsList,
  PopoverBox,
} from './styledComponents';

interface IAddTeamToChallengeProps {
  allTeams: IMarathonParticipantTeam[];
  spaceId: number;
  addTeamToChallenge: (_id: number) => void;
  removeTeamFromChallenge: (_id: number) => void;
}

const convertTeam = (team: ITeam): IMarathonParticipantTeam => {
  return {
    id: team.id,
    is_winner: false,
    is_banned: false,
    winning_place: 0,
    points: 0,
    accepted_at: '',
    team_name: team.name,
    logo: team.logo,
    description: team.description,
    ban_reason: '',
    members_count: team.members_count,
  };
};

export const AddTeamToChallenge: FC<IAddTeamToChallengeProps> = ({
  allTeams = [],
  spaceId,
  addTeamToChallenge,
  removeTeamFromChallenge,
}) => {
  const [showSearchComponent, setShowSearchComponent] =
    useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [searchTeamValue, setSearchTeamValue] = useState<string>('');
  const [selectedTeams, setSelectedTeams] =
    useState<IMarathonParticipantTeam[]>(allTeams);

  const getTeamsParams = useMemo(() => {
    return {
      search: searchTeamValue,
      space_id: spaceId,
      column: 'members_count' as 'members_count',
      sort_type: 'desc',
      page: 1,
    };
  }, [searchTeamValue]);

  const { data: { data: teams } = { data: [] } } = useGetTeams(getTeamsParams);

  useEffect(() => {
    setSelectedTeams(allTeams);
  }, [allTeams]);

  const openSearchComponent = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setShowSearchComponent(true);
  };

  const hideSearchComponent = () => {
    setShowSearchComponent(false);
    setAnchorEl(null);
  };

  const handleTeamSearch = (search: string) => {
    setSearchTeamValue(search);
  };

  const selectTeam = (team: ITeam) => {
    hideSearchComponent();
    if (
      selectedTeams.findIndex(selectedTeam => selectedTeam.id === team.id) !==
      -1
    ) {
      return;
    }
    setSelectedTeams([...selectedTeams, convertTeam(team)]);
    addTeamToChallenge(team.id);
  };

  const removeTeam = (team: IMarathonParticipantTeam) => {
    const updatedTeams = selectedTeams?.filter(
      selectedTeam => selectedTeam.id !== team.id,
    );
    setSelectedTeams(updatedTeams);
    removeTeamFromChallenge(team.id);
  };

  return (
    <StyledContainer>
      <h2>Команды</h2>
      <StyledMainContent>
        <Popover
          id={showSearchComponent ? 'searchTeamPopover' : undefined}
          open={showSearchComponent}
          anchorEl={anchorEl}
          onClose={hideSearchComponent}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <PopoverBox>
            <StyledTeamOptions>
              {teams?.map(team => {
                return (
                  <StyledTeamOption
                    key={team.id}
                    onClick={() => selectTeam(team)}
                  >
                    <Box className='imageBox'>
                      <img
                        src={team.logo as string | undefined}
                        alt={team.name}
                      />
                    </Box>
                    <Box className='name'>{team.name}</Box>
                    <p>{team.space?.name}</p>
                    <p>{team.members_count}</p>
                  </StyledTeamOption>
                );
              })}
            </StyledTeamOptions>

            <SearchInput
              handleSearch={search => handleTeamSearch(search)}
              size='large'
            />
          </PopoverBox>
        </Popover>
        {selectedTeams.length > 0 && (
          <StyledTeamsList>
            {selectedTeams.map(team => (
              <StyledTeamOption>
                <Box className='imageBox'>
                  <img
                    src={team.logo as string | undefined}
                    alt={team.team_name}
                  />
                </Box>
                <Box className='name'>{team.team_name}</Box>
                {/* <p>{team.description}</p> */}
                <p className='membersCount'>{team.members_count}</p>
                <Button
                  color='customWhite'
                  onClick={() => removeTeam(team)}
                  variant='text'
                  className='deleteButton'
                >
                  <DeleteIcon color={COLORS.BLACK} />
                </Button>
              </StyledTeamOption>
            ))}
          </StyledTeamsList>
        )}
        <Button
          startIcon={<PlusIcon color={COLORS.BLUE} />}
          onClick={e => openSearchComponent(e)}
          color='customLightBlue'
          className='addButton'
          size='large'
        >
          Добавить команду
        </Button>
      </StyledMainContent>
    </StyledContainer>
  );
};
