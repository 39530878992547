import { FC } from 'react';
import { NewsSubPagesHeader, NewsLayout, CreateNewsBody } from 'components';

export const CreateNews: FC = () => {
  return (
    <NewsLayout>
      <NewsSubPagesHeader />
      <CreateNewsBody
        defaultSidebarValues={{}}
        defaultContentJson={{}}
        defaultContent=''
        purpose='add'
      />
    </NewsLayout>
  );
};
