import { FC } from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { COLORS } from 'utils';

const StyledAvatarPlaceHolder = styled(Box)(
  ({ width, height }: { width?: string; height?: string }) => ({
    width,
    height,
    borderRadius: '50%',
    backgroundColor: COLORS.GRAY,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }),
);

interface IUserImageProps {
  src?: string;
  alt?: string;
  className?: string;
  firstName?: string;
  lastName?: string;
  width?: string;
  height?: string;
}

export const UserImage: FC<IUserImageProps> = ({
  src,
  alt,
  className = '',
  firstName = '',
  lastName = '',
  width = '2.5rem',
  height = '2.5rem',
}) => {
  return src ? (
    <img src={src} alt={alt} />
  ) : (
    <StyledAvatarPlaceHolder
      width={width}
      height={height}
      className={className}
    >
      {firstName[0]} {lastName[0]}
    </StyledAvatarPlaceHolder>
  );
};
