import { FC } from 'react';
import { Button, PageHeader, GlobalSearch } from 'UI';
import { PlusIcon } from 'assets';

interface INotificationsHeaderProps {
  openAddNotificationDrawer: () => void;
}

export const NotificationsHeader: FC<INotificationsHeaderProps> = ({
  openAddNotificationDrawer,
}) => {
  return (
    <PageHeader title='Уведомления'>
      <GlobalSearch size='medium' />
      <Button
        variant='contained'
        onClick={openAddNotificationDrawer}
        startIcon={<PlusIcon />}
      >
        Добавить
      </Button>
    </PageHeader>
  );
};
