import { ILevelReq, ILevelGetParams } from 'types';
import { http, methods } from './config';

export const getLevelsList = () => {
  const config = {
    method: methods.get,
    url: `/list/level`,
  };
  return http(config);
};

export const getLevels = (params: ILevelGetParams) => {
  const config = {
    method: methods.get,
    url: `/level`,
    params,
  };
  return http(config);
};

export const getLevel = (levelId: string | number) => {
  const config = {
    method: methods.get,
    url: `/level/${levelId}`,
  };
  return http(config);
};

export const createLevel = (data: ILevelReq) => {
  const config = {
    method: methods.post,
    url: `/level`,
    data,
  };
  return http(config);
};

export const updateLevel = (data: ILevelReq) => {
  const config = {
    method: methods.put,
    url: `/level/${data.id}`,
    data,
  };
  return http(config);
};

export const deleteLevel = (levelId: number | string) => {
  const config = {
    method: methods.delete,
    url: `/level/${levelId}`,
  };
  return http(config);
};
