import { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { useGetWorker } from 'hooks';
import { Button, Drawer, DrawerHeader, ModalTitle, UserImage } from 'UI';
import { CloseIcon } from 'assets';
import { formatPhoneNumber, formatBirthdayToShowAtProfile } from 'utils';

import {
  StyledBody,
  StyledCard,
  StyledHeader,
  StyledLabel,
  StyledMiniCard,
} from './styledComponents';

interface IViewWorkerDrawerProps {
  open: boolean;
  onClose: () => void;
  id: string | number;
}

export const ViewWorkerDrawer: FC<IViewWorkerDrawerProps> = ({
  open,
  onClose,
  id,
}) => {
  if (!id) return null;
  const location = useLocation();
  const navigate = useNavigate();
  const { data: workerInfo } = useGetWorker(id);
  const openEditDrawer = () => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('edit', id.toString());
    searchParams.delete('view');
    navigate(`?${searchParams.toString()}`);
  };
  return (
    <Drawer open={open} onClose={onClose} type='gray'>
      <DrawerHeader>
        <ModalTitle title='Профиль' />
        <CloseIcon onClick={onClose} />
      </DrawerHeader>
      <StyledHeader>
        <UserImage
          src={workerInfo?.photo as string | undefined}
          alt={workerInfo?.firstname}
          firstName={workerInfo?.firstname}
          lastName={workerInfo?.lastname}
          width='5rem'
          height='5rem'
        />
        <Box>
          <Box className='name'>
            {workerInfo?.firstname} {workerInfo?.lastname}
          </Box>
          <Box className='role'>{workerInfo?.role?.text}</Box>
        </Box>
      </StyledHeader>
      <StyledBody>
        <Box className='row'>
          <Box className='column'>
            <Box>
              <StyledLabel>Номер телефона</StyledLabel>
              <StyledMiniCard>
                {formatPhoneNumber(workerInfo?.phone)}
              </StyledMiniCard>
            </Box>
          </Box>
        </Box>
        <Box className='row'>
          <Box className='column'>
            <Box>
              <StyledLabel>Город</StyledLabel>
              {/* TODO: no city in workers */}
              <StyledMiniCard>Москва</StyledMiniCard>
            </Box>
          </Box>
          <Box className='column'>
            <Box>
              <StyledLabel>Дата рождения</StyledLabel>
              <StyledMiniCard>
                {formatBirthdayToShowAtProfile(workerInfo?.birthday)}
              </StyledMiniCard>
            </Box>
          </Box>
        </Box>
        <Box className='line' />
        {workerInfo?.spaces?.map((space, index) => (
          <Box className='column'>
            <Box>
              <StyledLabel>Роль {index + 1}</StyledLabel>
              <StyledCard>
                <Box className='title'>{space.role.text}</Box>
                <Box className='value'>{space.name}</Box>
              </StyledCard>
            </Box>
          </Box>
        ))}
        <Box className='row'>
          <Box className='column'></Box>
          <Box className='column'>
            <Button color='customBlack' size='large' onClick={openEditDrawer}>
              Редактировать
            </Button>
          </Box>
        </Box>
      </StyledBody>
    </Drawer>
  );
};
