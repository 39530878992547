import { FC } from 'react';
import { styled } from '@mui/system';
import {
  LinearProgress,
  Box,
  List,
  ListItem,
  ListItemText,
  Paper,
} from '@mui/material';
import { COLORS, formatNumber } from 'utils';
import { IAnalyticsAgeCard } from 'types';

const StyledContainer = styled(Paper)({
  backgroundColor: COLORS.WHITE,
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  '& .header': {
    padding: '0.8125rem 1.25rem',
    fontSize: '1.25rem',
    fontFamily: '"SF Pro Display", sans-serif',
    fontWeight: 600,
    borderBottom: `1px solid rgba(0,0,0,0.1)`,
  },
  '& .list': {
    padding: '1rem 1.25rem',
  },
  '& .listItem': {
    display: 'block',
    padding: '0 0 0.375rem',
  },
  '& .linearProgress': {
    backgroundColor: 'transparent',
    width: '100%',
    borderRadius: '0.3125rem',
    height: '0.5rem',
    '& .MuiLinearProgress-bar': {
      borderRadius: '0.3125rem',
    },
  },
  '& .info': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    fontFamily: '"SF Pro Display", sans-serif',
    '& .title span': {
      fontSize: '0.875rem',
      lineHeight: '1.375rem',
      fontWeight: 500,
    },
    '& .value': {
      textAlign: 'right',
      '& span': {
        fontWeight: 600,
        fontSize: '1rem',
        lineHeight: '1.5rem',
      },
    },
  },
});

interface IAnalyticsAgeCardProps {
  listItems: Array<IAnalyticsAgeCard>;
}

export const AnalyticsAgeCard: FC<IAnalyticsAgeCardProps> = ({ listItems }) => {
  return (
    <StyledContainer>
      <Box className='header'>Возраст</Box>
      <List className='list'>
        {listItems?.map(item => (
          <ListItem key={item.title} className='listItem'>
            <LinearProgress
              variant='determinate'
              value={item.percent}
              className='linearProgress'
            />
            <Box className='info'>
              <ListItemText className='title'>{item.title}</ListItemText>
              <ListItemText className='value'>
                {formatNumber(item.value)}
              </ListItemText>
            </Box>
          </ListItem>
        ))}
      </List>
    </StyledContainer>
  );
};
