import { useQuery, useMutation, useQueryClient } from 'react-query';
import {
  getSpaces,
  getSpace,
  createSpace,
  updateSpace,
  deleteSpace,
  getSpacesList,
  getUsersFileSample,
  uploadUsersFile,
  changeSpaceSettings,
} from 'api';
import { ISpaceListItem, ISpace, ISpacesRes, IPaginatedReqParams } from 'types';

export const useGetSpaces = (params: IPaginatedReqParams) => {
  const { page, search } = params;
  return useQuery<ISpacesRes, unknown>(
    ['spaces', { page, search }],
    async () => {
      const res = await getSpaces(params);
      return res;
    },
    {
      staleTime: Infinity,
      retry: false,
    },
  );
};

export const useGetSpace = (spaceId: number | string) => {
  if (!spaceId)
    return useQuery(['spaces', 'spaceId', spaceId], () => {
      return Promise.resolve(null);
    });
  return useQuery<ISpace>(
    ['spaces', 'spaceId', spaceId],
    async () => {
      const res = await getSpace(spaceId);
      return res;
    },
    {
      staleTime: Infinity,
      retry: false,
    },
  );
};

export const useCreateSpace = () => {
  const queryClient = useQueryClient();
  return useMutation(createSpace, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['spaces'] });
    },
    onError: () => {},
  });
};

export const useUpdateSpace = () => {
  const queryClient = useQueryClient();
  return useMutation(updateSpace, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['spaces'] });
    },
    onError: () => {},
  });
};

export const useDeleteSpace = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteSpace, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['spaces'] });
    },
    onError: () => {},
  });
};

export const useGetSpacesList = () => {
  return useQuery<ISpaceListItem[]>(
    ['spaces', 'list'],
    async () => {
      const res = await getSpacesList();
      return res;
    },
    {
      staleTime: Infinity,
      retry: false,
    },
  );
};

export const useGetUsersFileSample = () => {
  return useQuery(
    ['spaces', 'users', 'file', 'sample'],
    async () => {
      const res = await getUsersFileSample();
      return res;
    },
    {
      staleTime: Infinity,
      retry: false,
    },
  );
};

export const useUploadUsersFile = () => {
  return useMutation(uploadUsersFile, {
    onSuccess: () => {},
    onError: () => {},
  });
};

export const useChangeSpaceSettings = () => {
  const queryClient = useQueryClient();
  return useMutation(changeSpaceSettings, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['spaces'] });
    },
    onError: () => {},
  });
};
