import { styled } from '@mui/system';
import { FormControlLabel, Box } from '@mui/material';
import { COLORS } from 'utils';

export const StyledSwitchFormControl = styled(FormControlLabel)({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  borderBottom: `1px solid ${COLORS.BLACK}`,
  margin: 0,
  paddingBottom: '0.375rem',
  '& .MuiFormControlLabel-label': {
    marginTop: '0.375rem',
    fontSize: '1rem',
    lineHeight: '1.5rem',
  },
});

export const StyledAttributeValue = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '1.25rem',
  width: '100%',
  border: `1px solid ${COLORS.BLACK}`,
  margin: 0,
  padding: '1.25rem',
  borderRadius: '0.5rem',
  '& .header': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& .title': {
      fontSize: '1.125rem',
      fontWeight: 600,
      lineHeight: '1.625rem',
      fontFamily: '"SF Pro Display", sans-serif',
    },
    '& .iconButtons': {
      display: 'flex',
      alignItems: 'center',
      gap: 0,
    },
  },
});
