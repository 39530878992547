import { FC, useState, MouseEvent } from 'react';
import { TableBody, TableCell } from '@mui/material';

import { formatNumber } from 'utils';
import { Pagination, UserImage } from 'UI';
import {
  IAnalyticsFullTableRow,
  TableRowsOrder,
  IAnalyticsTopListGetParams,
  AnalyticsTopListOrderParams,
} from 'types';

import { useFullAnalytics, useSearchParams } from 'hooks';
import {
  StyledBox,
  StyledPaper,
  StyledTableContainer,
  StyledTable,
  StyledTableRow,
  StyledUserInfo,
  StyledAvatar,
  StyledUserMeta,
} from './styledComponents';
import { FullAnalyticsTableHead } from './FullAnalyticsTableHead';

interface IFullAnalyticsTableProps {
  data: IAnalyticsFullTableRow[];
  pageCount: number;
  changeParams: <T extends keyof IAnalyticsTopListGetParams>(
    _fieldName: T,
    _value: IAnalyticsTopListGetParams[T],
  ) => void;
}

export const FullAnalyticsTable: FC<IFullAnalyticsTableProps> = ({
  data = [],
  pageCount,
  changeParams,
}) => {
  const [order, setOrder] = useState<TableRowsOrder>('asc');
  const [orderBy, setOrderBy] = useState<keyof IAnalyticsFullTableRow>('id');
  const { columns } = useFullAnalytics();
  const { getParam } = useSearchParams();

  const page = +getParam('page');

  const handleRequestSort = (
    _event: MouseEvent<unknown>,
    property: AnalyticsTopListOrderParams,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    changeParams('order_type', isAsc ? 'desc' : 'asc');
    changeParams('order', property);
  };

  return (
    <StyledBox>
      <StyledPaper>
        <StyledTableContainer id='fullAnalyticsTableContainer'>
          <StyledTable
            aria-labelledby='FullAnalyticsTable'
            size='medium'
            stickyHeader
          >
            <FullAnalyticsTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {data.map((row, ind) => {
                const place = (page-1) * 15 + ind + 1;
                return (
                  <StyledTableRow hover key={row.id}>
                    <TableCell align='center'>
                      <span className={`place place-${place}`}>{place}</span>
                    </TableCell>
                    <TableCell
                      component='th'
                      id={row.id.toString()}
                      scope='row'
                    >
                      <StyledUserInfo>
                        <StyledAvatar>
                          <UserImage
                            src={row.photo as string | undefined}
                            alt={row.name}
                            firstName={row.name?.split(' ')[0]}
                            lastName={row.name?.split(' ')[1]}
                            width='3.125rem'
                            height='3.125rem'
                          />
                        </StyledAvatar>
                        <StyledUserMeta>
                          <div className='name'>{row.name}</div>
                          <div className='phone'>{row.phone}</div>
                        </StyledUserMeta>
                      </StyledUserInfo>
                    </TableCell>
                    {columns.some(el => el.id === 'space_name') && (
                      <TableCell align='left'>
                        <span>{row.space_name}</span>
                      </TableCell>
                    )}
                    <TableCell align='right'>
                      <span className='value'>{formatNumber(row.value)}</span>
                    </TableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </StyledTable>
        </StyledTableContainer>
        <Pagination count={pageCount} />
      </StyledPaper>
    </StyledBox>
  );
};
