import { FC, useState, useContext } from 'react';
import { Box } from '@mui/material';
import { useDrawer, useDeleteWorkoutsCalendar, useChangeStatus } from 'hooks';
import { UserContext, UserContextType } from 'app';
import { IWorkoutsCalendarShort } from 'types';
import { EditIcon, DeleteIcon } from 'assets';
import { DeleteModal } from 'components';
import { COLORS } from 'utils';
import { StyledSwitch } from 'UI';

import { StyledContainer, StyledHeader } from './styledComponents';

interface IWorkoutsCalendarCardProps extends IWorkoutsCalendarShort {}

export const WorkoutsCalendarCard: FC<IWorkoutsCalendarCardProps> = ({
  id,
  name,
  description,
  icon,
  cover,
  is_active,
}) => {
  const { changeEditDrawer } = useDrawer();
  const { space_id } = useContext(UserContext) as UserContextType;
  const { mutateAsync: changeStatus } = useChangeStatus('training_program');
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const { mutateAsync: deleteWorkoutsCalendar } = useDeleteWorkoutsCalendar();

  const openEditDrawer = () => {
    changeEditDrawer(id);
  };

  const openDeleteModal = () => {
    setShowDeleteModal(true);
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const switchHandler = () => {
    changeStatus({
      space_id: space_id!,
      model_name: 'training_program',
      model_id: id,
      state: !is_active,
    })
      .then(() => {})
      .catch(() => {});
  };

  const handleDeleteWorkoutsCalendar = () => {
    deleteWorkoutsCalendar(id)
      .then(() => {
        closeDeleteModal();
      })
      .catch();
  };

  return (
    <StyledContainer bgImg={cover}>
      <DeleteModal
        open={showDeleteModal}
        onConfirm={handleDeleteWorkoutsCalendar}
        onClose={closeDeleteModal}
        title='Удалить цель'
        description='Вы действительно хотите удалить цель?'
      />
      <StyledHeader>
        <Box className='imageBox'>
          <img src={icon} alt={name} />
        </Box>
        <Box>
          <p className='info'>{description}</p>
          <h3 className='title'>{name}</h3>
        </Box>
      </StyledHeader>
      <Box className='buttons'>
        <Box className='icons'>
          <EditIcon onClick={openEditDrawer} color={COLORS.WHITE} />
          <DeleteIcon onClick={openDeleteModal} color={COLORS.WHITE} />
        </Box>
        <StyledSwitch
          checked={is_active}
          onChange={switchHandler}
          variant='customWhite'
          className='switch'
        />
      </Box>
    </StyledContainer>
  );
};
