import { styled } from '@mui/system';
import { Box } from '@mui/material';
import { COLORS } from 'utils';

export const StyledBody = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '0.9375rem',
});

export const StyledMetaInfo = styled(Box)({
  display: 'flex',
  gap: '0.9375rem',
  alignItems: 'center',
  '& .column': {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.25rem',
    width: '100%',
    fontFamily: '"SF Pro Display", sans-serif',
    '& .label': {
      fontSize: '0.875rem',
      fontWeight: 500,
      lineHeight: '1.5rem',
      opacity: 0.5,
    },
    '& .value': {
      fontSize: '1.25rem',
      fontWeight: 600,
    },
  },
});

export const StyledDayCard = styled(Box)({
  backgroundColor: COLORS.WHITE,
  borderRadius: '0.5rem',
  width: '100%',
  '& .header': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '1rem 1.25rem',
    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
    fontFamily: '"SF Pro Display", sans-serif',
    '& .title': {
      fontSize: '1.25rem',
      fontWeight: 600,
      opacity: 1,
    },
    '& .prize': {
      fontSize: '0.75rem',
      fontWeight: 500,
      lineHeight: '1.25rem',
      opacity: 0.5,
    },
  },
  '& .item': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0.75rem 1.25rem',
    borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
    '& .meta': {
      display: 'flex',
      gap: '0.625rem',
      alignItems: 'center',
      fontSize: '0.875rem',
      fontWeight: 600,
      lineHeight: '1.5rem',
      fontFamily: '"SF Pro Display", sans-serif',
    },
    '& .icon': {
      backgroundColor: 'rgba(0, 0, 0, 0.05)',
      width: '2.75rem',
      height: '2.75rem',
      borderRadius: '0.5rem',
      padding: '0.25rem',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginRight: '0.375rem',
      '& img': {
        width: '100%',
        height: '100%',
        objectFit: 'contain',
      },
    },
    '& .price': {
      backgroundColor: COLORS.GOLDEN,
      borderRadius: '1rem',
      padding: '0.25rem 0.625rem',
      fontSize: '0.875rem',
      fontWeight: 600,
      lineHeight: '1.375rem',
      fontFamily: '"SF Pro Display", sans-serif',
    },
  },
});
